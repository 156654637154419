import createMockToken from './create-mock-token'
import type { MockToken } from './create-mock-token'
import { isStubMode, isE2eMode } from './auth-mode'

const isLoggedIn = () => window.localStorage.getItem('loggedIn') === 'true'

const setLoggedIn = (loggedIn: boolean) => {
  window.localStorage.setItem('loggedIn', String(loggedIn))
}

const getMockToken = (): MockToken =>
  JSON.parse(window.localStorage.getItem('mockTokenInfo') || '{}')

const populateMockToken = async () => {
  const mockToken = await createMockToken()
  window.localStorage.setItem('mockTokenInfo', JSON.stringify(mockToken))
}

const clearMockToken = () => {
  window.localStorage.removeItem('mockTokenInfo')
}

export const initialise = async () => true

export const register = async () => {
  setLoggedIn(true)
  await populateMockToken()
  window.location.reload()
}

export const login = async () => {
  setLoggedIn(true)

  if (!getMockToken().accessToken) {
    await populateMockToken()

    if (isE2eMode) {
      window.location.reload()
    }
  }
}

export const logout = async () => {
  setLoggedIn(false)
  clearMockToken()
}

export const getAuthToken = async () =>
  isLoggedIn() ? getMockToken().accessToken : null

export const getUser = async () =>
  isLoggedIn()
    ? {
        'https://babylonhealth.com/user': getMockToken().userId,
      }
    : null

export const hasClinicalScope = async () => {}

export const handleRedirectCallback = async () => ({
  appState: {
    targetUrl: '/',
    registration: false,
  },
})

export const getAuth0Client = () => null

if (
  isStubMode &&
  window.localStorage &&
  window.localStorage.getItem('loggedIn') !== 'false'
) {
  login()
}

const cypressAppActions = window as any

cypressAppActions.auth = {
  initialise,
  register,
  login,
  logout,
  getAuthToken,
  getMockToken, // Cypress specific convenience function
  getUser,
  hasClinicalScope,
  handleRedirectCallback,
  getAuth0Client,
}
