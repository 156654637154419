import React from 'react'
import { Text } from '@babylon/medkit'
import { ArrowDown } from '@babylon/icons'
import FullBleedSection from '@/components/FullBleedSection/FullBleedSection'
import styles from './Accordion.module.scss'

interface AccordionItem {
  icon: React.ElementType
  title: string
  description: string
}

interface AccordionProps {
  sectionTitle: string
  items: AccordionItem[]
}

const Accordion: React.FC<AccordionProps> = ({ items, sectionTitle }) => (
  <FullBleedSection title={sectionTitle}>
    <div className={styles.accordion}>
      {items.map((item, index) => {
        const Icon = item.icon

        return (
          <details key={index} className={styles.details}>
            <summary className={styles.summary}>
              <div className={styles.summaryContent}>
                <Icon />
                <Text variant="body" className={styles.heading}>
                  {item.title}
                </Text>
              </div>
              <div>
                <ArrowDown className={styles.arrowIcon} />
              </div>
            </summary>
            <div className={styles.detailsContent}>
              <Text variant="body">{item.description}</Text>
            </div>
          </details>
        )
      })}
    </div>
  </FullBleedSection>
)

export default Accordion
