import { Step } from '../types'

const calculateCompletionPercentageByStep = (
  currentStep: Step,
  allSteps: Step[]
): number => {
  if (!currentStep || !allSteps || allSteps.length === 0) {
    return 0 // Handle edge cases where there are no steps or current step is missing
  }

  const currentIndex = allSteps.findIndex((step) => step.id === currentStep.id)

  if (currentIndex === -1) {
    return 0 // Current step not found in the list
  }

  const completedSteps = allSteps.slice(0, currentIndex + 1) // Include current step and all previous steps
  const totalSteps = allSteps.length
  const percentage = (completedSteps.length / totalSteps) * 100

  return percentage
}

export default calculateCompletionPercentageByStep
