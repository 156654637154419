import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useWeightLossRefillHomeInfo } from '@/redux/selectors'
import { login as loginAction } from '@/components/Authentication/actions'
import WeightLossImage from '@/assets/images/weight-loss_clinician-call.jpg'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import { refillRoutes } from '../routes'
import messages from '../messages'

const cx = classNames.bind(styles)

const RefillCheckInHomePage = () => {
  const t = useFormatMessage()
  const dispatch = useDispatch()
  const history = useHistory()
  const announcement = useWeightLossRefillHomeInfo()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const handleClick = () => {
    if (isAuthenticated) {
      return history.push(`${refillRoutes.questionnaire}?check-in=true`)
    }

    return dispatch(loginAction())
  }

  return (
    <Template
      title={t(messages.refillCheckInHomePageTitle)}
      hasAppLayout={isAuthenticated}
    >
      <h2>{t(messages.refillCheckInHomePageHeading)}</h2>
      <p>{t(messages.refillCheckInHomePageDescription)}</p>
      <p>{t(messages.refillCheckInHomePageDescription2)}</p>
      {announcement && <Announcement content={announcement} />}
      <img
        className={cx('setup-confirmation-image')}
        src={WeightLossImage}
        alt={t(messages.setupNextStepsPageImageDescription)}
      />
      <Button onClick={handleClick}>
        {t(
          isAuthenticated
            ? messages.refillCheckInHomePageCta
            : messages.checkInStartupHomePageCta1
        )}
      </Button>
    </Template>
  )
}

export default RefillCheckInHomePage
