import { routeConfig as getBookAppointmentRoutes } from '@/components/Appointments/BookAppointment/routes'
import { RouteConfig } from './routeConfig'

export enum RouteId {
  AppointmentPay = 'appointmentPay',
  AppointmentPlans = 'appointmentPlans',
  AppointmentBooked = 'appointmentBooked',
  ConsultationFeedback = 'consultationFeedback',
  AppointmentSlotTimedOut = 'appointmentSlotTimedOut',
}

const DEFAULT_ROOT_PATH = '/consultations'

const getRouteConfig = (routePrefix?: string) => {
  const ROOT_PATH = routePrefix
    ? `/${routePrefix}${DEFAULT_ROOT_PATH}`
    : DEFAULT_ROOT_PATH
  const routeConfig: RouteConfig = {
    id: 'appointments',
    route: ROOT_PATH,
    getUrl: () => ROOT_PATH,
    getRedirectUrl: () => `${ROOT_PATH}/new`,
    breadcrumb: {
      isExcluded: true,
    },
    isSkipped: () => false,

    children: [
      ...getBookAppointmentRoutes(routePrefix),
      {
        id: RouteId.AppointmentPay,
        route: `${ROOT_PATH}/:appointmentId/payment/:planId?`,
        getUrl: ({ appointmentId, planId = '' }) =>
          `${ROOT_PATH}/${appointmentId}/payment/${planId}?`,
        breadcrumb: {
          isExcluded: true,
        },
        isSkipped: () => false,
      },
      {
        id: RouteId.AppointmentPlans,
        route: `${ROOT_PATH}/plans/:appointmentId`,
        getUrl: ({ appointmentId }) => `${ROOT_PATH}/plans/${appointmentId}`,
        breadcrumb: {
          isWide: true,
          path: [RouteId.AppointmentPay],
        },
        isSkipped: () => false,
      },
      {
        id: RouteId.AppointmentBooked,
        route: `${ROOT_PATH}/:appointmentId/success`,
        getUrl: ({ appointmentId }) => `${ROOT_PATH}/${appointmentId}/success`,
        breadcrumb: {
          isExcluded: true,
        },
        isSkipped: () => false,
      },
      {
        id: RouteId.ConsultationFeedback,
        route: `${ROOT_PATH}/:consultationId([0-9]+)?/feedback`,
        getUrl: ({ consultationId }) =>
          `${ROOT_PATH}/${consultationId}/feedback`,
        breadcrumb: {
          isWide: true,
        },
        isSkipped: () => false,
      },
      {
        id: RouteId.AppointmentSlotTimedOut,
        route: `${ROOT_PATH}/timed-out`,
        getUrl: () => `${ROOT_PATH}/timed-out`,
        breadcrumb: {
          isExcluded: true,
        },
        isSkipped: () => false,
      },
    ],
  }

  return routeConfig
}

export default getRouteConfig
