import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import generalMessages from '@/App.messages'
import { useLogo } from '@/redux/selectors'
import styles from './LandingHeader.module.scss'
import Logo from '../Logo'
import WebViewMode from '@/utils/WebViewMode'

const LandingHeader = () => {
  const logo = useLogo()
  const translate = useFormatMessage()
  const isWebViewMode = WebViewMode.isWebViewMode()

  if (isWebViewMode) {
    return null
  }

  return (
    <>
      <header className={styles.loginHeader} data-testid="landing-header">
        <div className={styles.logoContainer}>
          <a
            className={styles.logo}
            title={translate(generalMessages.ctaHome)}
            href="/"
          >
            <Logo name={logo} className={styles[`${logo}-logo`]} />
          </a>
        </div>
      </header>
      {/*<AnnouncementBanner />*/}
    </>
  )
}

export default LandingHeader
