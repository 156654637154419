import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { IMedkitComponent, Text } from '..'
import { cardTheme } from './theme'

export const Card: IMedkitComponent<IProps, typeof cardTheme> = ({
  thumbnail,
  title,
  subTitle,
  image,
  vertical,
  interactive,
  className,
  children,
  callToAction,
  ...props
}) => (
  <section className={cn(styles.Card, className)} {...props}>
    <div
      className={cn(styles.Card_layout, {
        [styles.Card_vertical]: vertical,
        [styles.Card_interactive]: interactive,
      })}
    >
      {thumbnail && <div className={styles.Card__thumbnail}>{thumbnail}</div>}
      {!thumbnail && image && (
        <div
          className={styles.Card__image}
          style={{ backgroundImage: `url("${image}")` }}
        />
      )}
      <div className={styles.Card__content}>
        {title && (
          <Text variant="body" className={styles.Card__title}>
            {title}
          </Text>
        )}
        {subTitle && (
          <Text variant="h5" tag="p" className={styles.Card__subTitle}>
            {subTitle}
          </Text>
        )}
        {children}
      </div>
    </div>
    {callToAction && (
      <div className={styles.Card_callToAction}>{callToAction}</div>
    )}
  </section>
)

Card.theme = cardTheme

interface IProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactChild | React.ReactChild[]
  callToAction?: JSX.Element
  thumbnail?: React.ReactNode
  subTitle?: string
  /** Optional image to add to card */
  image?: string
  /** Display image on top */
  vertical?: boolean
  interactive?: boolean
  /** Card title */
  title?: string
}

export default Card
