import React, { useState, useEffect } from 'react'
import cn from 'classnames/bind'
import { useIntl } from 'react-intl'
import { Text } from '@babylon/medkit'

import { Portal } from '@/components/Modal'
import IncomingCallModal from './IncomingCallModal'
import VideoControls from './VideoControls'
import MediaPermissionsModal from './MediaPermissionsModal'
import { useVideoCallState } from '../selectors'
import styles from '../VideoCall.module.scss'
import messages from '../VideoCall.messages'

export default ({
  isAccessDialogOpened,
  isMediaAccessGiven,
  consultant,
  answerCall,
  declineCall,
  hangUp,
  toggleVideo,
  toggleAudio,
  videoEnabled,
  audioEnabled,
}) => {
  const intl = useIntl()
  const [mounted, setMounted] = useState(false)
  const accessPemissionsNotSet = isMediaAccessGiven === null
  const accessDenied = isMediaAccessGiven === false
  const accessGranted = !accessDenied && !accessPemissionsNotSet
  const [incomingCall, inCall] = useVideoCallState()

  /**
   * The isAccessDialogOpened prop comes directly from opentok but it is slow and
   * on Chrome it briefly flashes as `true` even if the dialog doesnt display.
   * So we use it as a fallback on Safari and older browsers where
   * `navigator.permissions.query` is not available and the flash does not happen anyway
   */

  const isPrompting = async (isAccessDialogOpened) => {
    if (!isAccessDialogOpened) {
      return false
    }

    try {
      const [cameraStatus, micStatus] = await Promise.all([
        navigator.permissions.query({ name: 'camera' }),
        navigator.permissions.query({ name: 'microphone' }),
      ])

      return cameraStatus.state === 'prompt' || micStatus.state === 'prompt'
    } catch (err) {
      return true
    }
  }

  useEffect(() => {
    if (inCall && !accessGranted) {
      isPrompting(isAccessDialogOpened).then((prompting) => {
        if (prompting || !accessGranted) {
          setMounted(true)
        }
      })
    } else {
      setMounted(false)
    }
  }, [isAccessDialogOpened, inCall, accessGranted])

  return (
    <>
      <IncomingCallModal
        consultant={consultant}
        onClose={hangUp}
        answer={answerCall}
        decline={declineCall}
        showIncomingCall={incomingCall}
      />

      <Portal enableScroll={!inCall}>
        {mounted && (
          <MediaPermissionsModal
            onClose={() => setMounted(false)}
            accessDenied={accessDenied}
          />
        )}
        <div
          id="callContainer"
          className={cn(styles.callContainer, { noTouch: !inCall })}
        >
          <Text className="sr-only" variant="body" tag="h1">
            {intl.formatMessage(messages.videoCallTitle)}
          </Text>

          {/** Live Consultation code */}
          {inCall && (
            <VideoControls
              toggleAudio={toggleAudio}
              toggleVideo={toggleVideo}
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
              hangUp={hangUp}
            />
          )}
        </div>
      </Portal>
    </>
  )
}
