import React from 'react'
import cn from 'classnames'

import { IMedkitComponent } from '..'
import { toggleTheme } from './theme'
import { IToggleSwitchProps } from './types'

import styles from './index.module.scss'

// https://www.smashingmagazine.com/2017/09/building-inclusive-toggle-buttons/

export const ToggleSwitch: IMedkitComponent<
  IToggleSwitchProps,
  typeof toggleTheme
> = ({
  label,
  disabled,
  onClick = () => {},
  checked = false,
  className,
  ...props
}) => (
  <button
    {...props}
    role="switch"
    aria-checked={checked}
    aria-label={label}
    disabled={disabled}
    onClick={onClick}
    type="button"
    className={cn(
      styles.toggle,
      {
        [styles.toggle_disabled]: disabled,
      },
      className
    )}
  >
    <span className={styles.toggle__switch} />
  </button>
)

ToggleSwitch.theme = toggleTheme

export default ToggleSwitch
