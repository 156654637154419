import React from 'react'
import cx from 'classnames/bind'
import styles from './VideoPermissionsCard.module.scss'

// TODO: Moved this component into Medkit
export default ({ caption, icon, errorStyle }) => (
  <div
    className={cx(
      styles.videoCardPermissions,
      errorStyle && styles['videoCardPermissions--error']
    )}
  >
    <span className={styles.videoCardPermissions__icon}>{icon}</span>
    <div className={styles.videoCardPermissions__captionBox}>
      <span className={styles.videoCardPermissions__captionText}>
        {caption}
      </span>
    </div>
  </div>
)
