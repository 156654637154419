import { deliveryMethods } from '@/components/DeliveryMethodList'

const allPrescriptionFufillmentOptions = Object.values(deliveryMethods)
  .map((method) => method.urlFragment)
  .join('|')

const deliveryPrescriptionFufillmentOptions = Object.values(deliveryMethods)
  .reduce(
    (acc, method) =>
      method.type === 'delivery' ? acc.concat(method.urlFragment) : acc,
    []
  )
  .join('|')

const ACCOUNT_ROOT = '/account'
const CLINICAL_RECORDS_ROOT = '/clinical-records'
const CLINICAL_RECORDS_ROUTE = '/clinical-records/:userId([0-9]+)?'

const clinicalRecords = {
  id: 'clinicalRecords',
  route: CLINICAL_RECORDS_ROUTE,
  getUrl: ({ pageUserId = '' }) => `${ACCOUNT_ROOT}/${pageUserId}`,
  redirect: ACCOUNT_ROOT,
  children: [
    {
      id: 'profile',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/profile`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/profile`,
    },
    {
      id: 'allergies',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/allergies`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/allergies`,
    },
    {
      id: 'medications',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/medications`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/medications`,
    },

    {
      id: 'prescription',
      route: `${CLINICAL_RECORDS_ROOT}/prescriptions/:prescriptionId`,
      getUrl: ({ prescriptionId }) =>
        `${CLINICAL_RECORDS_ROOT}/prescriptions/${prescriptionId}`,
    },
    {
      id: 'prescriptionHomeAddressInput',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/:deliveryMethod(${allPrescriptionFufillmentOptions})/address`,
      getUrl: ({ prescriptionId, action, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/${action}/address`,
      breadcrumb: {
        path: ['prescription'],
      },
    },

    {
      id: 'prescriptionDeliveryOptions',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/:action(${deliveryPrescriptionFufillmentOptions})`,
      getUrl: ({ prescriptionId, action, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/${action}`,
      breadcrumb: {
        path: ['prescription'],
      },
    },
    // This route must be passed with state or it will redirect.
    {
      id: 'prescriptionDeliveryReview',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/delivery-review`,
      getUrl: ({ prescriptionId, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/delivery-review`,
      breadcrumb: {
        path: ['prescription', 'prescriptionDeliveryOptions'],
      },
    },
    {
      id: 'prescriptionDeliveryConfirmation',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/delivery-confirmation`,
      getUrl: ({ prescriptionId, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/delivery-confirmation`,
    },

    {
      id: 'prescriptionCollectionChoosePharmacy',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/pharmacy-delivery`,
      getUrl: ({ prescriptionId, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/pharmacy-delivery`,
      breadcrumb: {
        path: ['prescription'],
      },
    },
    {
      id: 'prescriptionCollectionFindPharmacy',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/find-pharmacy`,
      getUrl: ({ prescriptionId, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/find-pharmacy`,
      breadcrumb: {
        path: ['prescription'],
        isCompact: true,
        isExtraWide: true,
      },
      layout: {
        vFlush: true,
      },
    },
    {
      id: 'prescriptionCollectionReview',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/collection-review`,
      getUrl: ({ prescriptionId, pageUserId, pharmacyReference }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/collection-review?pharmacy=${pharmacyReference}`,
      breadcrumb: {
        path: ['prescription'],
      },
    },
    {
      id: 'prescriptionCollectionConfirmation',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/prescriptions/:prescriptionId/collection-confirmation`,
      getUrl: ({ prescriptionId, pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/prescriptions/${prescriptionId}/collection-confirmation`,
    },

    {
      id: 'appointments',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/appointments`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/appointments`,
    },
    {
      id: 'appointment',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}`,
      breadcrumb: {
        path: ['appointments'],
      },
    },
    {
      id: 'appointmentConsultantNotes',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/consultant-notes`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/consultant-notes`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentReferrals',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/referrals`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/referrals`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentArchives',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/archives`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/archives`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentPrescriptions',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/prescription`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/prescription`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentCheckIn',
      route: `${CLINICAL_RECORDS_ROOT}/:appointmentId/check-in`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/${appointmentId}/check-in`,
      breadcrumb: {
        isExcluded: true,
      },
    },
    {
      id: 'appointmentLocation',
      route: `${CLINICAL_RECORDS_ROOT}/:appointmentId/location`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/${appointmentId}/location`,
      breadcrumb: {
        isExcluded: true,
      },
    },
    {
      id: 'appointmentWaitingRoom',
      route: `${CLINICAL_RECORDS_ROOT}/:appointmentId/waiting-room`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/${appointmentId}/waiting-room`,
      breadcrumb: {
        isExcluded: true,
      },
    },
    {
      id: 'pharmacies',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/pharmacies`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/pharmacies`,
      conditions: ['enable_see_pharmacies'],
    },
    {
      id: 'pharmacyAdd',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/pharmacies/add`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/pharmacies/add`,
      breadcrumb: {
        path: ['pharmacies'],
        isCompact: true,
        isExtraWide: true,
      },
      layout: {
        vFlush: true,
      },
      conditions: ['show_pharmacy'],
    },
    {
      id: 'pharmacy',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/pharmacies/:pharmacyId`,
      getUrl: ({ pageUserId, pharmacyId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/pharmacies/${pharmacyId}`,
      breadcrumb: {
        path: ['pharmacies'],
      },
      conditions: ['enable_see_pharmacies'],
    },
    {
      id: 'medicalHistory',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/medical-history`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/medical-history`,
      conditions: ['show_medical_history'],
    },
    {
      id: 'gpDetails',
      route: `${CLINICAL_RECORDS_ROOT}/:userId/gp-details`,
      getUrl: ({ pageUserId }) =>
        `${CLINICAL_RECORDS_ROOT}/${pageUserId}/gp-details`,
      conditions: ['show_gp_details'],
    },
    {
      id: 'conversationHistory',
      route: `${CLINICAL_RECORDS_ROOT}/chat-history`,
      getUrl: () => `${CLINICAL_RECORDS_ROOT}/chat-history`,
      breadcrumb: {
        alwaysVisible: true,
        isCompact: true,
        isExtraWide: true,
      },
      layout: {
        vFlush: true,
        hFlush: true,
      },
    },
    {
      id: 'conversationDetail',
      /* use the same React key as conversationHistory,
      so we don't get a remount for the same component (ConversationsPage) */
      key: 'conversationHistory',
      route: `${CLINICAL_RECORDS_ROOT}/chat-history/:conversationId`,
      getUrl: ({ conversationId, elementId }) =>
        `${CLINICAL_RECORDS_ROOT}/chat-history/${conversationId}${
          elementId ? `?elementId=${elementId}` : ''
        }`,
      breadcrumb: {
        path: ['conversationHistory'],
        alwaysVisible: true,
        isCompact: true,
        isExtraWide: true,
      },
      layout: {
        vFlush: true,
        hFlush: true,
      },
    },
    {
      id: 'pgmReport',
      route: `${CLINICAL_RECORDS_ROOT}/report/:conversationId`,
      getUrl: ({ conversationId }) =>
        `${CLINICAL_RECORDS_ROOT}/report/${conversationId}`,
    },
  ],
}

export default clinicalRecords
