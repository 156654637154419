import React from 'react'
import classNames from 'classnames'

import styles from './LoadingBlock.module.scss'

const LoadingBlock = ({ className }) => (
  <div className={classNames(styles.block, className)} aria-busy />
)

export default React.memo(LoadingBlock)
