import React from 'react'
import { find, pipe, prop } from 'ramda'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { branch, compose, renderComponent, withProps } from 'recompose'
import { AppointmentInvite } from '@babylon/consumer-api/types'

import { RouteId } from '../types'
import { reduxState } from '@/redux'

import { getRouteUrl } from '../routes'
import { selectBookingInvitation } from '@/redux/selectors'
import { InitialState as ReducerInitialState } from '../reducer'

type BookingChoice = ReducerInitialState['bookingChoices']

type Step = {
  stepNumber: number
  routeId: RouteId
  skip?: (invite: AppointmentInvite) => boolean
  choiceKeys: {
    face_to_face: ('medium' | 'clinic' | 'consultant' | 'time')[] | false
    voice: ('medium' | 'consultant' | 'time')[] | false
    video: ('medium' | 'consultant' | 'time')[] | false
  }
}

// linear steps and the choices made within them, earliest first
const STEPS: Array<Step> = [
  {
    stepNumber: 1,
    routeId: RouteId.BookBegin,
    choiceKeys: {
      face_to_face: [],
      voice: [],
      video: [],
    },
  },
  {
    stepNumber: 2,
    routeId: RouteId.BookSelectMedium,
    choiceKeys: {
      face_to_face: [],
      voice: [],
      video: [],
    },
    skip: (invite) => invite.allowed_mediums.length === 1,
  },
  {
    stepNumber: 3,
    routeId: RouteId.BookSelectClinicianType,
    choiceKeys: {
      face_to_face: [],
      voice: [],
      video: [],
    },
    skip: (invite) =>
      !invite.allowed_clinician_types ||
      invite.allowed_clinician_types.length === 1,
  },
  {
    stepNumber: 4,
    routeId: RouteId.BookSelectLocation,
    choiceKeys: {
      face_to_face: ['clinic'],
      voice: false,
      video: false,
    },
  },
  {
    stepNumber: 5,
    routeId: RouteId.BookSelectSlot,
    choiceKeys: {
      face_to_face: ['consultant', 'time'],
      voice: [],
      video: [],
    },
  },
  {
    stepNumber: 6,
    routeId: RouteId.BookReview,
    choiceKeys: {
      face_to_face: [],
      voice: [],
      video: [],
    },
  },
]

const getStepNumberByRouteId = (routeId: RouteId): Step['stepNumber'] =>
  STEPS.find((step) => step.routeId === routeId)!.stepNumber

const isStepInFlow = (step: Step, medium?: BookingChoice['medium']) =>
  !medium || step.choiceKeys[medium] !== false

const getRelativeSteps = (
  currentStepNumber: number,
  callback: (a: Step, b: Step) => boolean
) => {
  const step = STEPS.find((item) => item.stepNumber === currentStepNumber)

  if (!step) {
    return []
  }

  const state = reduxState.store?.getState()
  let invite

  if (state) {
    invite = selectBookingInvitation(state).currentInvite
  }

  return STEPS.filter((comparedStep) => {
    const shouldSkip = Boolean(invite && comparedStep.skip?.(invite))
    const isInFlow = callback(step, comparedStep)

    return !shouldSkip && isInFlow
  }).sort((a, b) => a.stepNumber - b.stepNumber)
}

// where the current step number < another step number, the other step number is a next step
const getNextSteps = (
  currentStepNumber: number,
  medium?: BookingChoice['medium']
) =>
  getRelativeSteps(currentStepNumber, (currentStep, compared) => {
    const isCurrentStepLower = currentStep.stepNumber < compared.stepNumber
    const isComparedStepInFlow = isStepInFlow(compared, medium)

    return isCurrentStepLower && isComparedStepInFlow
  })

const getNextStep = (
  currentStepNumber: number,
  medium?: BookingChoice['medium']
) => {
  const steps = getNextSteps(currentStepNumber, medium)
  return steps.length ? steps[0] : undefined
}

// where the current step number > another step number, the other step number is a previous step
const getPreviousSteps = (
  currentStepNumber,
  medium?: BookingChoice['medium']
) =>
  getRelativeSteps(currentStepNumber, (currentStep, compared) => {
    const isCurrentStepHigher = currentStep.stepNumber > compared.stepNumber
    const isComparedStepInFlow = isStepInFlow(compared, medium)

    return isCurrentStepHigher && isComparedStepInFlow
  })

const getPreviousStep = (
  currentStepNumber,
  medium?: BookingChoice['medium']
) => {
  const steps = getPreviousSteps(currentStepNumber, medium)
  return steps.length ? steps[steps.length - 1] : undefined
}

const getUnfufilledStep = (bookingChoices, stepNumber) =>
  pipe(
    getPreviousSteps,
    // get the first unfufilled step, if any
    find(isStepUnfufilled(bookingChoices))
  )(stepNumber, bookingChoices.medium)

const isStepUnfufilled = (bookingChoices: BookingChoice) => (step: Step) => {
  const hasChoiceDependency = Object.values(step.choiceKeys).some(
    (value) => Array.isArray(value) && value.length > 0
  )
  const { medium } = bookingChoices

  if (!hasChoiceDependency) {
    return false
  }

  if (!medium) {
    return true
  }

  const choiceKeys = step.choiceKeys[medium]

  // `false` means the step is not part of the booking flow
  // we can safely say the step is fufilled
  if (choiceKeys === false) {
    return false
  }

  const missingStep = choiceKeys.find(
    (key) => bookingChoices[key] === undefined
  )
  return typeof missingStep === 'string'
}

const validatePreviousStepsFufilled = (stepNumber) =>
  compose(
    withProps(
      ({ bookingChoices }) =>
        ({
          unfufilledStep: getUnfufilledStep(bookingChoices, stepNumber),
        } as any)
    ),
    branch(
      prop('unfufilledStep'),
      renderComponent(({ inviteId, unfufilledStep }: any) => (
        <Redirect to={getRouteUrl(unfufilledStep.routeId, { inviteId })} />
      ))
    )
  )

const provideRelativeRouteIds = (currentStepNumber) =>
  withProps(
    ({ bookingChoices }) =>
      ({
        nextRouteId: getNextStep(currentStepNumber, bookingChoices.medium)
          ?.routeId,
        previousRouteId: getPreviousStep(
          currentStepNumber,
          bookingChoices.medium
        )?.routeId,
      } as any)
  )

const withSteps = ({ stepNumber } = {} as any) =>
  compose(
    connect((state) => ({
      bookingChoices: selectBookingInvitation(state).bookingChoices,
    })),
    validatePreviousStepsFufilled(stepNumber),
    provideRelativeRouteIds(stepNumber),
    branch(
      ({ bookingChoices }: any) => {
        const hasMedium = !!bookingChoices.medium
        const isMediumStep =
          stepNumber === getStepNumberByRouteId(RouteId.BookSelectMedium)

        return !hasMedium && !isMediumStep
      },
      renderComponent(({ inviteId }: any) => (
        <Redirect to={getRouteUrl(RouteId.BookBegin, { inviteId })} />
      ))
    )
  )

export {
  getNextSteps,
  getNextStep,
  getPreviousSteps,
  getPreviousStep,
  getUnfufilledStep,
  isStepUnfufilled,
  provideRelativeRouteIds,
  validatePreviousStepsFufilled,
  getStepNumberByRouteId,
}

export default withSteps
