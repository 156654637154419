import React from 'react'
import classNames from 'classnames/bind'
import { withProps } from 'recompose'
import PropTypes from 'prop-types'

import styles from './infoCard.module.scss'

const cx = classNames.bind(styles)

const enhance = withProps(({ titleLevel }) => ({
  TitleElement: titleLevel,
}))

const InfoCard = enhance(({ TitleElement, description, isBusy, title }) => (
  <div
    className={cx('container', {
      'container--busy': isBusy,
    })}
    aria-live="polite"
    aria-busy={isBusy}
  >
    {title && <TitleElement className={styles.title}>{title}</TitleElement>}
    {description && <p className={styles.description}>{description}</p>}
  </div>
))

InfoCard.propTypes = {
  isBusy: PropTypes.bool,
  title: PropTypes.string,
  titleLevel: PropTypes.string,
  description: PropTypes.string,
}

InfoCard.defaultProps = {
  isBusy: false,
  titleLevel: 'h3',
}

export default InfoCard
