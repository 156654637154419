import React from 'react'
import { shape, string, bool } from 'prop-types'
import classNames from 'classnames/bind'

import styles from './LinkButton.module.scss'

const cx = classNames.bind(styles)

const LinkButton = ({
  children,
  className,
  style,
  href,
  target,
  onClick,
  isNeutral,
  ...rest
}) => (
  <span
    style={style}
    className={cx(
      'LinkButton',
      { [styles.LinkButton_neutral]: isNeutral },
      className
    )}
  >
    <a
      className={styles.LinkButton__anchor}
      href={href || undefined}
      target={target}
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  </span>
)

LinkButton.defaultProps = {
  title: undefined,
  style: {},
  href: '',
  target: '',
  className: null,
}

LinkButton.propTypes = {
  style: shape({}),
  href: string,
  target: string,
  className: string,
  isNeutral: bool,
}

export default React.memo(LinkButton)
