import React, { FormEvent, forwardRef, useState } from 'react'
import TextInput from '@/programmes/components/FormV2/components/TextInput/TextInputOnly'
import Spinner from '@/programmes/components/Spinner'
import SearchResult from '../SearchResult'
import { SearchItem } from '../../utils'
import styles from './SearchInput.module.scss'

type Props = {
  label: string
  onSelect: (searchItem: SearchItem) => void
  onSearch: (searchValue: string) => Promise<SearchItem[] | undefined>
  disabled?: boolean
  searchItems?: SearchItem[]
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ label, onSelect, onSearch, disabled, searchItems }: Props, ref) => {
    const [loading, setLoading] = useState(false)

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
      setLoading(true)
      onSearch(e.currentTarget.value).then(() => setLoading(false))
    }

    return (
      <div className={styles.searchInput}>
        <label htmlFor={`searchInput-${label}`}>{label}</label>
        <div className={styles.inputWrapper}>
          <TextInput
            id={`searchInput-${label}`}
            ref={ref}
            disabled={disabled}
            onChange={handleChange}
          />
          {loading && <Spinner colour="#000" />}
        </div>
        {searchItems && (
          <div>
            {searchItems.map((searchItem) => (
              <SearchResult
                key={`${searchItem.displayName} - ${searchItem.postcode}`}
                result={searchItem.displayName}
                onClick={() => onSelect(searchItem)}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
)

export default SearchInput
