import { defineMessages } from 'react-intl'

export default defineMessages({
  setupEligibilityQuestionnairePageTitle: {
    id: 'UtiTest.SetupQuestionnairePage.pageTitle',
    defaultMessage: 'UTI Test - Eligibility',
  },
  activationReturnHomeLabel: {
    id: 'UtiTest.activationCode.return-home.label',
    defaultMessage: 'Return home',
  },
  activationInvalidSexLabel: {
    id: 'UtiTest.activationCode.invalid-sex.label',
    defaultMessage:
      'This test is only available for individuals who were assigned female at birth. If this is incorrect, please update your {link} and return to this page.',
  },
  activationCodeLabel: {
    id: 'UtiTest.activationCode.label',
    defaultMessage: 'Please enter your access code',
  },
  activationCodeHint: {
    id: 'UtiTest.activationCode.hint',
    defaultMessage:
      'Your access code is located on your test box, under the QR code.',
  },
  activationCodeSubmit: {
    id: 'UtiTest.activationCode.submit',
    defaultMessage: 'Submit',
  },
  activationCodePlaceholder: {
    id: 'UtiTest.activationCode.placeholder',
    defaultMessage: 'UTIXXXXXX',
  },
  setupConfirmationPageCta: {
    id: 'UtiTest.SetupConfirmationPage.cta',
    defaultMessage: 'Done',
  },
  setupConfirmationPageTitle: {
    id: 'UtiTest.CheckInStartupConfirmationPage.pageTitle',
    defaultMessage: 'UTI Test - Activation',
  },
  setupConfirmationPageHeading: {
    id: 'UtiTest.CheckInStartupConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  setupConfirmationPageDescription: {
    id: 'UtiTest.CheckInStartupConfirmationPage.description',
    defaultMessage: 'Thank you for testing with eMed',
  },
  setupConfirmationPageCard1Title: {
    id: 'UtiTest.SetupConfirmation.Card1.title',
    defaultMessage: 'Thank you for testing with eMed',
  },
  setupConfirmationPageCard1Description: {
    id: 'UtiTest.SetupConfirmation.Card1.description',
    defaultMessage:
      'A clinician will now review your results and if needed, prescribe medication.',
  },
  setupConfirmationPageCard1ImageDescription: {
    id: 'UtiTest.SetupConfirmation.Card1.imageDescription',
    defaultMessage: 'Video icon',
  },
  setupConfirmationPageCard2Title: {
    id: 'UtiTest.SetupConfirmation.Card2.title',
    defaultMessage: 'Do you have the app?',
  },
  setupConfirmationPageCard2Description: {
    id: 'UtiTest.SetupConfirmation.Card2.description',
    defaultMessage:
      'Download the app to view your results and manage prescriptions.',
  },
  setupActivationPageTitle: {
    id: 'UtiTest.setupActivation.pageTitle',
    defaultMessage: 'Activate your test',
  },
  setupActivationPageHeading: {
    id: 'UtiTest.setupActivation.heading',
    defaultMessage: "You're about to activate this test for: ",
  },
  setupHomePageTitle: {
    id: 'UtiTest.setupHomePage.pageTitle',
    defaultMessage: 'eMed® UTI Test - Setup',
  },
  setupHomePageComingSoonHeading: {
    id: 'UtiTest.setupHomePage.coming-soon.heading',
    defaultMessage: 'Coming soon: SheMed® UTI Test-to-Treat',
  },
  setupHomePageHeading: {
    id: 'UtiTest.setupHomePage.heading',
    defaultMessage: 'Welcome to your Telehealth experience!',
  },
  setupHomePageDescription1: {
    id: 'UtiTest.setupHomePage.description1',
    defaultMessage:
      "We're here to help through the process of collecting your sample and taking your test.",
  },
  setupHomePageCta: {
    id: 'UtiTest.setupHomePage.cta',
    defaultMessage: 'Start your journey',
  },
  timeAndPlaceTitle: {
    id: 'UtiTest.SetupHome.Card1.title',
    defaultMessage: 'Create a private space',
  },
  timeAndPlaceDescription: {
    id: 'UtiTest.SetupHome.Card1.description',
    defaultMessage:
      'Set aside 5-10 minutes in a private space, preferably a bathroom, for getting yourself ready, collecting your sample, and submitting your results.',
  },
  deviceTitle: {
    id: 'UtiTest.SetupHome.Card7.title',
    defaultMessage: 'Device requirements',
  },
  deviceDescription: {
    id: 'UtiTest.SetupHome.Card7.description',
    defaultMessage:
      'Use a portable device you can take with you while you collect your sample.',
  },
  postTestExpectationsTitle: {
    id: 'UtiTest.SetupHome.Card8.title',
    defaultMessage: 'Post-test expectations',
  },
  postTestExpectationsDescription: {
    id: 'UtiTest.SetupHome.Card8.description',
    defaultMessage:
      'Instant test results after submitting your sample. Clinician review and medication prescription within 2 hours (After 10pm, expect results the next morning).',
  },
  medicationDeliveryTitle: {
    id: 'UtiTest.SetupHome.Card9.title',
    defaultMessage: 'Medication delivery',
  },
  medicationDeliveryDescription: {
    id: 'UtiTest.SetupHome.Card9.description',
    defaultMessage:
      'If prescribed, medication can be delivered to your home the same or next day.',
  },
  setupIneligiblePageTitle: {
    id: 'UtiTest.Ineligible.pageTitle',
    defaultMessage: 'UTI Test - Activation',
  },
  setupIneligiblePageHeading: {
    id: 'UtiTest.Ineligible.heading',
    defaultMessage: 'Sorry',
  },
  setupIneligiblePageDescription: {
    id: 'UtiTest.Ineligible.description',
    defaultMessage: `Based on your answers, it looks like you don't qualify to use SheMed® UTI Test-to-Treat at this time.

    If you'd like to talk to someone, have questions, or need assistance, please contact our team <a>https://support.babylonhealth.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d|here</a>.

    If you are pregnant, and are experiencing UTI or any other symptoms, it is important to address them as soon as possible.

    Please book an appointment with one of our clinicians who will be happy to help.

    You can keep your testing kit for future use.`,
  },
  setupIneligiblePageBookAppointmentCta: {
    id: 'UtiTest.Ineligible.bookAppointmentCta',
    defaultMessage: 'Book appointment',
  },
  setupIneligiblePageCta: {
    id: 'UtiTest.Ineligible.cta',
    defaultMessage: 'Back to homepage',
  },
})
