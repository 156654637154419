const precentageOff = 50

const PROMO_CODE = {
  id: 123,
  code: 'MY-PROMO-CODE',
  description: `${precentageOff}% off for 2 appointments`,
  duration: 2,
  expires_on: '2019-07-25',
  is_credit_card_required: false,
  logo:
    'https://app-uk.dev.babylontech.co.uk/system/promotions/logos/000/000/001/original/152-iPad_Copy.png?1475578991',
  name: `UK ${precentageOff}% Off PAYG 2 Appointments`,
  percent_off: precentageOff,
  promotion_type: 'consultation',
}

const MEMBERSHIP_CODE = {
  id: 1,
  code: 'MY-MEMBERSHIP-CODE',
  description: '',
  consumer_network: {
    id: 3,
    uuid: '27d9ba42-44c2-4eec-9780-d068209a7526',
    name: 'MY AWESOME MEMBERSHIP COMPANY',
    insurer_logo:
      'https://app-uk.dev.babylontech.co.uk/asset_redirect?url=https%3A%2F%2Fdev-uk-storage-gateway-nfs.s3.eu-west-2.amazonaws.com%2Fdev-uk%2Fupload%2Fcore-ruby%2Fimages%2Fqa%2Finsurers%2F3%2Finsurer_logo%2Fwide%2Ftest_%25281%2529.jpg%3FX-Amz-Algorithm%3DAWS4-HMAC-SHA256%26X-Amz-Credential%3DAKIAQ5ZNSHDVIWYQR5GS%252F20200622%252Feu-west-2%252Fs3%252Faws4_request%26X-Amz-Date%3D20200622T132110Z%26X-Amz-Expires%3D60%26X-Amz-SignedHeaders%3Dhost%26X-Amz-Signature%3Dabe736fa26486a3d7f3bb1197d9fe445d94ed3382bfa7d9a6a5459cc1eaf327b',
    terms_and_conditions_url: 'https://www.babylonhealth.com/terms-mobile',
    faq_url: 'https://support.babylonhealth.com',
    consultation_faq_url:
      'https://support.gpathand.babylon-health.com/cspgpathand?id=csp_kb_category&kb_category=20a9307247aaa11067070b55d36d435d',
    patient_queue_faq_link:
      'https://support.gpathand.babylon-health.com/cspgpathand?id=csp_kb_article_view&sysparm_article=KB0010310',
    partner_id: null,
    plan_id: null,
  },
}

const MEMBERSHIP_CODE_VBC = {
  id: 321,
  code: '30',
  description: '',
  consumer_network: {
    id: 4,
    uuid: '27d9ba42-44c2-4eec-9780-d068209a7526',
    name: 'VBC',
    insurer_logo:
      'https://app-uk.dev.babylontech.co.uk/asset_redirect?url=https%3A%2F%2Fdev-uk-storage-gateway-nfs.s3.eu-west-2.amazonaws.com%2Fdev-uk%2Fupload%2Fcore-ruby%2Fimages%2Fqa%2Finsurers%2F3%2Finsurer_logo%2Fwide%2Ftest_%25281%2529.jpg%3FX-Amz-Algorithm%3DAWS4-HMAC-SHA256%26X-Amz-Credential%3DAKIAQ5ZNSHDVIWYQR5GS%252F20200622%252Feu-west-2%252Fs3%252Faws4_request%26X-Amz-Date%3D20200622T132110Z%26X-Amz-Expires%3D60%26X-Amz-SignedHeaders%3Dhost%26X-Amz-Signature%3Dabe736fa26486a3d7f3bb1197d9fe445d94ed3382bfa7d9a6a5459cc1eaf327b',
    terms_and_conditions_url: 'https://www.babylonhealth.com/terms-mobile',
    faq_url: 'https://support.babylonhealth.com',
    consultation_faq_url:
      'https://support.gpathand.babylon-health.com/cspgpathand?id=csp_kb_category&kb_category=20a9307247aaa11067070b55d36d435d',
    patient_queue_faq_link:
      'https://support.gpathand.babylon-health.com/cspgpathand?id=csp_kb_article_view&sysparm_article=KB0010310',
    partner_id: null,
    plan_id: null,
  },
}

const INVALID_MEMBERSHIP_CODE = {
  code: 'INVALID-MEMBERSHIP-CODE',
}

export {
  PROMO_CODE,
  MEMBERSHIP_CODE,
  INVALID_MEMBERSHIP_CODE,
  MEMBERSHIP_CODE_VBC,
}
