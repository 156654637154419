import React from 'react'
import styles from './RenderInterstitial.module.scss'
import { Interstitial } from '../../types'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface Props {
  interstitial: Interstitial
}

const RenderInterstitial: React.FC<Props> = ({ interstitial }) => (
  <div className={styles.containerV1}>
    {interstitial.title && (
      <h1
        className={styles.titleV1}
        id={`${STEP_ELEMENT_ID_PREFIX}-title-${interstitial.id}`}
      >
        {interstitial.title}
      </h1>
    )}

    {interstitial.body &&
      (!Array.isArray(interstitial.body) ? (
        <span
          className={styles.subTextV1}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: interstitial.body }}
          id={`${STEP_ELEMENT_ID_PREFIX}-body-${interstitial.id}`}
        />
      ) : (
        interstitial.body.map((paragraph, index) => (
          <span
            key={`paragraph-${index}`}
            className={styles.subTextV1}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: paragraph }}
            id={`${STEP_ELEMENT_ID_PREFIX}-body-${interstitial.id}-${index}`}
          />
        ))
      ))}
  </div>
)

export default RenderInterstitial
