import { prop } from 'ramda'
import { injectIntl } from 'react-intl'
import { compose, withProps } from 'recompose'

import { withPageTitle } from '@/wrappers'

import Error from './Error'

const enhance = compose(
  injectIntl,
  withPageTitle(prop('title')),
  withProps(({ isNested }) => ({
    TitleElement: isNested ? 'h2' : 'h1',
  }))
)

export default enhance(Error)
