import React, { ReactNode } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import qs from 'qs'
import cn from 'classnames/bind'
import { useVideoCallWithToken } from '@/redux/selectors'
import { VideoCallWithToken } from '@/components/VideoCall'

import LandingHeader from '@/components/LandingHeader'
import appStyles from '@/App.module.scss'
import styles from './ConsultationPage.module.scss'

const cx = cn.bind(styles)

interface ConsultationProps {
  children: ReactNode
}

const ConsultationPage = ({ children }: ConsultationProps) => {
  const { search } = useLocation()
  const history = useHistory()
  const token = qs.parse(search, { ignoreQueryPrefix: true }).token

  if (!useVideoCallWithToken() || !token) {
    return <Redirect to="/" />
  }

  return (
    <>
      <LandingHeader />
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <section className={cx('consultationPage', 'Content--form')}>
            <VideoCallWithToken
              token={token}
              onFail={() => <Redirect to="/consultation/error" />}
              onConsultationEnded={() =>
                history.push(`/consultation/post?token=${token}`)
              }
            />
            {children}
          </section>
        </div>
      </main>
    </>
  )
}

export default ConsultationPage
