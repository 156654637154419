import { init } from '@sentry/browser'
import { setEnabled, isEnabled } from './info'

const initSentry = (options) => {
  setEnabled(true)
  init(options)
}

export { isEnabled }
export { initSentry }

export {
  default as logException,
  logExceptionWithReportDialog,
} from './logException'
export { default as logMessage } from './logMessage'
