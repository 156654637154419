import {
  VIDEOCALL_INCALL_STARTED,
  VIDEOCALL_INCALL_ENDED,
  VIDEOCALL_INCOMING_SHOW,
  VIDEOCALL_INCOMING_HIDE,
  VIDEOCALL_DROPPED,
} from './actionTypes'

const initialState = {
  inCall: false,
  incomingCall: false,
}

const videoCall = (state = initialState, action) => {
  switch (action.type) {
    case VIDEOCALL_INCALL_STARTED:
      return {
        ...state,
        inCall: true,
        incomingCall: false,
      }
    case VIDEOCALL_INCALL_ENDED:
      return {
        ...state,
        inCall: false,
      }
    case VIDEOCALL_INCOMING_SHOW:
      return {
        ...state,
        incomingCall: true,
      }
    case VIDEOCALL_INCOMING_HIDE:
      return {
        ...state,
        incomingCall: false,
      }
    case VIDEOCALL_DROPPED:
      return {
        ...state,
        incomingCall: false,
        inCall: false,
      }
    default:
      return state
  }
}

export default videoCall
