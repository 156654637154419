import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { useQuery } from '@apollo/react-hooks'
import { Patient } from '@babylon/consumer-api/types'
import UserQuery from '@/queries/Patient'
import WeightLossImage from '@/assets/images/weight-loss_gym.jpg'
import {
  useWeightLossSetupEnabled,
  useWeightLossSetupHomeInfo,
  useWeightLossEnabledForGPAtHand,
} from '@/redux/selectors'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import { setupRoutes } from '../routes'
import messages from '../messages'
import { onboardingSteps } from './steps'
import Loading from '@/components/Loading'

const cx = classNames.bind(styles)
interface UserQueryResult {
  patient: Patient
}

const SetupHomePage = () => {
  const history = useHistory()
  const t = useFormatMessage()
  const announcement = useWeightLossSetupHomeInfo()
  const userId = useSelector((state: any) => state.auth?.user?.id)
  const isAuthenticated = userId !== undefined && userId !== null
  const weightLossSetupEnabled = useWeightLossSetupEnabled()
  const weightLossEnabledForGPAtHand = useWeightLossEnabledForGPAtHand()

  const { data: userData, loading } = useQuery<UserQueryResult>(UserQuery, {
    variables: { id: userId },
    skip: !isAuthenticated,
  })

  useEffect(() => {
    const redirectIfOnGPAtHand = () => {
      if (
        !weightLossEnabledForGPAtHand &&
        userData?.patient?.consumer_networks?.some((network) =>
          network?.name.toLowerCase().includes('gp at hand')
        )
      ) {
        history.push(setupRoutes.errorIneligible)
      }
    }

    redirectIfOnGPAtHand()
  }, [history, userData, weightLossEnabledForGPAtHand])

  const handleClick = () => history.push(setupRoutes.questionnaire)

  if (loading) {
    return <Loading />
  }

  return (
    <Template
      title={t(messages.setupHomePageTitle)}
      hasAppLayout={isAuthenticated}
      steps={onboardingSteps}
    >
      <Spacer bottom={2}>
        <h2 className={cx('heading')}>{t(messages.setupHomePageHeading)}</h2>
      </Spacer>
      <p className={cx('description')}>
        {t(messages.setupHomePageDescription)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupHomePageDescription2)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupHomePageDescription3)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupHomePageDescription4)}
      </p>
      <Spacer top={2} bottom={2}>
        {announcement && <Announcement content={announcement} />}
        <div className={cx('cta')}>
          <Button onClick={handleClick} disabled={!weightLossSetupEnabled}>
            {t(messages.setupHomePageCta1)}
          </Button>
        </div>
        <p className={cx('footnote')}>
          <FormattedMessage
            {...messages.setupHomePagePrivacy}
            values={{
              a: (chunks) => (
                <a href="/privacy" target="blank">
                  {chunks}
                </a>
              ),
            }}
          />{' '}
          {t(messages.setupHomePageFootnote)}
        </p>
      </Spacer>
      <img
        className={cx('home-image')}
        src={WeightLossImage}
        alt={t(messages.setupHomePageImage)}
      />
    </Template>
  )
}

export default SetupHomePage
