import { defaultDateFormat } from './DateInput'

const isValidDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map(Number)

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false
  }

  if (year <= 0 || month <= 0 || day <= 0) {
    return false
  }

  // Basic month check
  if (month > 12) {
    return false
  }

  // Basic day check
  if (day > 31) {
    return false
  }

  const utcTimestamp = Date.UTC(year, month - 1, day)
  const date = new Date(utcTimestamp)

  const isSameYear = date.getUTCFullYear() === year
  // Months start from 0
  const isSameMonth = date.getUTCMonth() + 1 === month
  const isSameDay = date.getUTCDate() === day

  return isSameYear && isSameMonth && isSameDay
}

const getISOFormattedDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map(Number)
  const utcTimestamp = Date.UTC(year, month - 1, day)

  return new Date(utcTimestamp).toISOString().split('T')[0]
}

// Regex to check the dateFormat matches the expected pattern
const dateFormatRegex = RegExp('(DD|MM|YYYY)/(DD|MM|YYYY)/(DD|MM|YYYY)')
const isValidDateFormat = (dateFormat: string) =>
  dateFormatRegex.test(dateFormat)

// Returns an array with the fields order
const getFieldsOrder = (dateFormat: string | undefined) => {
  const dateFormatUppercase = dateFormat?.toUpperCase() || defaultDateFormat
  const validDateFormat = isValidDateFormat(dateFormatUppercase)
    ? dateFormatUppercase
    : defaultDateFormat
  return validDateFormat.split('/')
}

export { isValidDate, getISOFormattedDate, getFieldsOrder }
