import {
  FORM_ERROR,
  RESET_FORM,
  RESET_SINGLE_FORM,
  REGISTER_FORM,
  RESET_POSTCODE_ERRORS,
} from './constants'

export const displayFormErrors = (name, errors) => ({
  type: FORM_ERROR,
  name,
  errors,
})

export const resetFormErrors = () => ({ type: RESET_FORM })

export const resetSingleForm = (name) => ({
  type: RESET_SINGLE_FORM,
  name,
})

export const registerActiveForm = (name) => ({
  type: REGISTER_FORM,
  name,
})

export const resetPostcodeErrors = (name) => ({
  type: RESET_POSTCODE_ERRORS,
  name,
})
