import { defineMessages } from 'react-intl'

export default defineMessages({
  dayLabel: {
    id: 'dateInput.dayLabel',
    defaultMessage: 'Day',
  },
  monthLabel: {
    id: 'dateInput.monthLabel',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'dateInput.yearLabel',
    defaultMessage: 'Year',
  },
  errorMessage: {
    id: 'dateInput.errorMessage',
    defaultMessage: 'Please enter a valid date',
  },
})
