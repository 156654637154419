import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { Text } from '@babylon/medkit'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import styles from '../index.module.scss'
import messages from '../messages'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'
import useGoToBookAppointment from '@/programmes/hooks/useGoToBookAppointment'

const intlOptions = {
  a: (chunks: string[]) => {
    const [href, text] = chunks[0].split('|')

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {text || href}
      </a>
    )
  },
}

const SetupIneligible = () => {
  const t = useFormatMessage()
  const history = useHistory()

  return (
    <Template title={t(messages.setupIneligiblePageTitle)} hasAppLayout>
      <h3>{t(messages.setupIneligiblePageHeading)}</h3>
      <div className={styles.cta}>
        <Text variant="body" className={styles.text}>
          {t(messages.setupIneligiblePageDescription, intlOptions)}
        </Text>
      </div>
      <Button onClick={useGoToBookAppointment(UTI_TEST.routes.ineligible.path)}>
        {t(messages.setupIneligiblePageBookAppointmentCta)}
      </Button>
      <Button secondary onClick={() => history.push('/')}>
        {t(messages.setupIneligiblePageCta)}
      </Button>
    </Template>
  )
}

export default SetupIneligible
