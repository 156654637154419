import { defineMessages } from 'react-intl'

export default defineMessages({
  newAppointment: {
    id: 'Navigation.newAppointment',
    defaultMessage: 'Book Appointment',
  },
  notifications: {
    id: 'Navigation.notifications',
    defaultMessage: 'Notifications',
  },
  notificationsUnread: {
    id: 'Navigation.notificationsUnread',
    defaultMessage: 'You have new notifications',
  },
  help: {
    id: 'Navigation.help',
    defaultMessage: 'Help',
  },
  account: {
    id: 'Navigation.account',
    defaultMessage: 'Account',
  },
  inviteFriends: {
    id: 'Navigation.inviteFriends',
    defaultMessage: 'Invite Friends',
  },
  logout: {
    id: 'Navigation.logout',
    defaultMessage: 'Logout',
  },
  supportChat: {
    id: 'Navigation.supportChat',
    defaultMessage: 'Chat with us',
  },
  supportCenter: {
    id: 'Navigation.supportCenter',
    defaultMessage: 'Support Center',
  },
})
