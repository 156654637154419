import { useRef, useEffect } from 'react'

/**
 * usePrevious() stores a given prop's previous value when it gets updated
 */
export default (prop: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = prop
  }, [prop])

  return ref.current
}
