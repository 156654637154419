import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames/bind'
import styles from './Link.module.scss'

const cx = classNames.bind(styles)
const hrefAbsolutePathsList = ['http', 'https', 'mailto', 'tel']
const isAbsoluteHref = (href) =>
  hrefAbsolutePathsList.some((element) => href.startsWith(element))

const Link = ({
  children,
  className,
  style,
  href,
  target,
  onClick,
  ...rest
}) => {
  const isAbsolute = href && isAbsoluteHref(href)
  const Wrapper = isAbsolute ? 'a' : RouterLink
  const actionProps = isAbsolute ? { href } : { to: href }

  return (
    <Wrapper {...actionProps} target={target} onClick={onClick} {...rest}>
      <span style={style} className={cx('Link', className)}>
        {children}
      </span>
    </Wrapper>
  )
}

Link.defaultProps = {
  title: undefined,
  style: {},
  href: '',
  target: '',
  className: null,
}

Link.propTypes = {
  style: PropTypes.shape({}),
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
}

export default React.memo(Link)
