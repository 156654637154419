import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Redirect, useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

import { Text, Form, InputField, ButtonReactRouterLink } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'

import { emailRegistrationCompleted } from './actions'
import LandingHeader from '../../LandingHeader'
import Container from '@/components/Container'
import Loading from '@/components/Loading'
import PageTitle from '@/components/PageTitle/PageTitle'
import { useUserId } from '../selectors'
import messages from './messages'
import appStyles from '@/App.module.scss'
import { useRegistrationFields } from '@/redux/selectors'

const RegisterPage = () => {
  const translate = useFormatMessage()
  const userId = useUserId()
  const { enabled: registrationEnabled } = useRegistrationFields()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const { data, loading } = useQuery(gql`
    query registrationConfig {
      registrationConfig {
        fields {
          name
        }
      }
    }
  `)

  const [updateUser] = useMutation(gql`
    mutation updatePatient($id: Int!, $patient: ProfileInformation!) {
      updatePatient(id: $id, patient: $patient) {
        id
      }
    }
  `)

  const onSubmit = async (formValues) => {
    try {
      await updateUser({
        variables: { id: userId, patient: { ...formValues } },
      })

      if (formValues.promocode) {
        dispatch(
          emailRegistrationCompleted({ promocode: formValues.promocode })
        )
      }

      push('/')
    } catch (error) {
      const isEligibilityError = error?.graphQLErrors?.some(
        (error) => error.extensions?.isEligibilityError
      )

      if (isEligibilityError) {
        push('/postcode-not-eligible')
      } else {
        throw error
      }
    }
  }

  if (loading) {
    return <Loading />
  }

  const fields = data?.registrationConfig?.fields

  if (!registrationEnabled || !fields) {
    return <Redirect to="/" />
  }

  const initialValues = fields.reduce((values, field) => {
    values[field.name] = ''

    return values
  }, {})

  const showSkipButton = !fields.some(({ required }) => required)

  return (
    <>
      <PageTitle title={translate(messages.pageTitle)} />
      <div>
        <LandingHeader />
        <main className={appStyles.view}>
          <div className={appStyles.view__inner}>
            <Container variant="form">
              <Text variant="h1">{translate(messages.pageTitle)}</Text>
              <Text variant="body">{translate(messages.description)}</Text>
              <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitButtonText={translate(messages.submitText)}
                submitButtonLoadingText={translate(messages.submitLoading)}
              >
                {fields.map(({ name, required }) => {
                  const title = messages[`${name}Title`]
                  const description = messages[`${name}Description`]

                  // TODO: what should this be from an A11Y perspective
                  return (
                    <>
                      {title && <p>{translate(title)}</p>}
                      {description && <p>{translate(description)}</p>}
                      <InputField
                        key={name}
                        name={name}
                        label={translate(messages[`${name}Label`])}
                        validateMessage={translate(
                          messages[`${name}Validation`]
                        )}
                        required={required}
                      />
                    </>
                  )
                })}
              </Form>
              {showSkipButton && (
                <ButtonReactRouterLink
                  variant="secondary"
                  data-testid="skipAddingAdditionalDetails"
                  fullWidth
                  to="/"
                >
                  Skip for now
                </ButtonReactRouterLink>
              )}
            </Container>
          </div>
        </main>
      </div>
    </>
  )
}

export default RegisterPage
