import { TrackingEventProps, TrackingService } from '../types'

export default class NoOpTrackingService implements TrackingService {
  app: string = ''

  appName: string = ''

  appVersion: string = ''

  env: string = ''

  countryIsoCode: string = ''

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  trackView(_payload: TrackingEventProps): void {
    // do nothing here presumably because the user has not accepted tracking cookies
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  trackEvent(_payload: TrackingEventProps): void {
    // do nothing here presumably because the user has not accepted tracking cookies
  }
}
