import gql from 'graphql-tag'

export const BABYLON_USER_FRAGMENT = gql`
  fragment Viewer on Viewer {
    id
    uuid
    type
    partnerIds
    timezone
    roles {
      id
      name
    }
    consultant @include(if: $consultant) {
      id
      uuid
      name
      email
      avatar_full_url
      avatar_expiring_url
      profession_name
      supply_networks {
        id
        name
      }
    }
  }
`

export const BABYLON_USER_QUERY = gql`
  query BabylonUser($consultant: Boolean!) {
    viewer {
      ...Viewer
    }
  }
  ${BABYLON_USER_FRAGMENT}
`
