import step1 from '@/assets/images/injection-instructions/wegovy-pencap-remove.mp4'
import step2 from '@/assets/images/injection-instructions/wegovy-check-clarity.mp4'
import step3 from '@/assets/images/injection-instructions/wegovy-needle-box-and-paper-off.mp4'
import step4 from '@/assets/images/injection-instructions/wegovy-twist-on-needle.mp4'
import step5 from '@/assets/images/injection-instructions/wegovy-outercap-remove.mp4'
import step6 from '@/assets/images/injection-instructions/wegovy-innercap-remove.mp4'
import step7 from '@/assets/images/injection-instructions/wegovy-check-flow.mp4'
import step8 from '@/assets/images/injection-instructions/wegovy-check-flow-for-drop.mp4'
import step9 from '@/assets/images/injection-instructions/wegovy-drop-or-not.mp4'
import step10 from '@/assets/images/injection-instructions/wegovy-check-dose.mp4'
import step11 from '@/assets/images/injection-instructions/wegovy-choose-injection-site.mp4'
import step12 from '@/assets/images/injection-instructions/wegovy-clean-the-injection-site.mp4'
import step13 from '@/assets/images/injection-instructions/wegovy-inject-allsteps-waist.mp4'
import step14 from '@/assets/images/injection-instructions/wegovy-inject-remove-needle.mp4'
import step15 from '@/assets/images/injection-instructions/wegovy-cap-pen.mp4'
import step16 from '@/assets/images/injection-instructions/wegovy-remove-needle.mp4'
import step17 from '@/assets/images/injection-instructions/wegovy-pencap-replace.mp4'
import step18 from '@/assets/images/injection-instructions/global-checkin-norefill.mp4'

type InjectionStep = {
  video?: string
  text: string[]
  buttonText?: string
  buttonTextExit?: string
}

const steps: InjectionStep[] = [
  {
    text: [
      'Well now guide you through the injection process.',
      'Make sure to read the patient information leaflet which provides you with an overview of the injection procedure.',
      'Click Start to begin.',
    ],
    buttonText: 'Start',
    buttonTextExit: 'I need support',
  },
  {
    video: step1,
    text: ['Firstly, please remove the pen cap from your Wegovy pen.'],
  },
  {
    video: step2,
    text: [
      'Please ensure that the Wegovy medication in your pen is clear and colorless. You can observe this through the pen window.',
      'If Wegovy appears cloudy or contains particles, please refrain from using the pen.',
    ],
    buttonText: 'The liquid is clear and colourless',
    buttonTextExit: 'The liquid is cloudy or has a colour',
  },
  {
    video: step3,
    text: ['Obtain a new needle from the needle box and remove the paper tab'],
  },
  {
    video: step4,
    text: [
      'Attach the needle straight onto the pen, turning it clockwise until it is securely tightened.',
    ],
  },
  {
    video: step5,
    text: [
      'Please remove the outer needle cap.',
      'Keep this cap to one side as you’ll need it after the injection to safely remove it from the pen.',
    ],
  },
  {
    video: step6,
    text: [
      'Remove the inner needle cap and dispose of it in the sharps bin provided.',
      'A droplet of Wegovy may appear at the needle tip. This is normal, however, we still need to verify the flow of Wegovy when using a new pen for the first time.',
    ],
  },
  {
    video: step7,
    text: [
      "Before you set the dose as prescribed by your healthcare professional, you'll need to prime your injector pen.",
      'Rotate the dose selector one click until the dose counter displays the flow check symbol (two dots and a line as shown in the image).',
    ],
  },
  {
    video: step8,
    text: [
      'Hold the pen with the needle facing upwards.',
      'Depress and hold the dose button until the dose counter reads 0.',
      'The 0 must align with the dose pointer.',
      'A droplet of Wegovy should appear at the tip of the needle.',
    ],
  },
  {
    video: step9,
    text: [
      'Does a droplet of Wegovy appear at the tip of the needle?',
      'If a drop does not appear, check the flow again.',
      'This should only be done twice. If there is still no drop, change the needle and check the flow one more time.',
    ],
    buttonText: 'Yes, there is a drop',
    buttonTextExit: 'I was not able to get a drop from any needle',
  },
  {
    video: step10,
    text: [
      'Now that your pen is primed, please confirm the dosage as prescribed by your healthcare professional.',
      'This can be found on the label of your prescription or in the information provided by your pharmacy.',
      "It's crucial to adjust to the correct dosage amount as prescribed by your healthcare professional. They should have prescribed you a specific dose for each weekly treatment.",
    ],
  },
  {
    video: step10,
    text: [
      "Adjust the dose selector to match the dose prescribed by your healthcare professional for this week's dose.",
      'The dashed line in the dose counter will lead you to the correct dosage.',
      "If you've selected the incorrect dose, you can adjust the dose selector either forward or backward to the correct dosage.",
    ],
  },
  {
    video: step11,
    text: [
      "Based on the manufacturer's guidelines, let's find the best spot for your injection.",
      'Your options include your lower abdomen, upper leg, or the back of your upper arm.',
      "It's best to avoid places where the skin feels tender, appears bruised or red, or feels hard. Also, areas with scars or stretch marks aren't ideal.",
      "While you can use the same general area each week, try to mix it up a bit – don't go for the exact same spot every time.",
    ],
  },
  {
    video: step12,
    text: ['First, make sure the injection site is clean.'],
  },
  {
    video: step13,
    text: [
      'Before you inject, ensure the dose counter is visible.',
      'Do not obstruct it with your fingers, as this could interfere with the injection process.',
      'Lift a fold of skin between your thumb and your index finger. Please insert the needle into your skin.',
      'Press and hold down the dose button until the dose counter reads 0. Once dose counter reads 0, count slowly to 6.',
    ],
  },
  {
    video: step14,
    text: [
      'Remove the needle from your skin.',
      'If you notice any blood at the injection site, gently press down with a cotton ball or tissue. Avoid rubbing the area.',
    ],
  },
  {
    video: step15,
    text: [
      'Lead the needle tip into the outer needle cap on a flat surface without touching the needle or the outer needle cap.',
      'Once the needle is covered, carefully push the outer needle cap completely on.',
    ],
  },
  {
    video: step16,
    text: [
      'Carefully remove the outer needle cap as shown in the animation.',
      'Please dispose of the needle in the sharps bin provided.',
    ],
  },
  {
    video: step17,
    text: [
      'After each use, place the pen cap back on your pen to shield Wegovy from light.',
      'Store your Wegovy pen in the fridge as per the patient information leaflet included with your prescription.',
    ],
  },
  {
    video: step18,
    text: [
      "We're all done with your first check-in! Click Done to see your progress",
    ],
    buttonText: 'Done',
  },
]

export default steps
