import React, { useRef } from 'react'
import cn from 'classnames'

import { IMedkitComponent, Fieldset, HelpText } from '../..'
import { BaseInputProps } from '../BaseInput/BaseInput'
import BaseInput from '../BaseInput'
import baseInputStyles from '../BaseInput/BaseInput.module.scss'
import dateInputTheme from './theme'
import styles from './DateInput.module.scss'
import { isValidDate, getFieldsOrder, getISOFormattedDate } from './utils'

export type Props = Omit<BaseInputProps<HTMLInputElement>, 'onChange'> & {
  label: string // Making label required for this for accessibility reasons
  helpText?: string
  dayLabel: string
  monthLabel: string
  yearLabel: string
  dayAria?: string
  monthAria?: string
  yearAria?: string
  dateFormat?: string
  onChange?: (value: string) => void
}

interface IFieldsProps {
  [key: string]: IField
}

export enum FieldEnum {
  day = 'DD',
  month = 'MM',
  year = 'YYYY',
}

type IField = {
  length: number
  label: string
  aria?: string
  value: string
  ref: any
  className?: string
}

// Default fields order
export const defaultDateFormat = `${FieldEnum.day}/${FieldEnum.month}/${FieldEnum.year}`

const DateInput: IMedkitComponent<Props, typeof dateInputTheme> = ({
  label,
  helpText,
  dayLabel,
  dayAria,
  monthLabel,
  monthAria,
  yearLabel,
  yearAria,
  disabled,
  ErrorMessages,
  value,
  error,
  onChange,
  onBlur,
  dateFormat,
}: Props) => {
  const fieldsOrder = getFieldsOrder(dateFormat)

  const fieldsProps: IFieldsProps = {
    [FieldEnum.day]: {
      length: 2,
      label: dayLabel,
      aria: dayAria,
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    [FieldEnum.month]: {
      length: 2,
      label: monthLabel,
      aria: monthAria,
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    [FieldEnum.year]: {
      length: 4,
      label: yearLabel,
      aria: yearAria,
      value: '',
      className: styles.dateInputYearField,
      ref: useRef<HTMLInputElement>(null),
    },
  }

  if (value && isValidDate(value)) {
    ;[
      fieldsProps[FieldEnum.year].value,
      fieldsProps[FieldEnum.month].value,
      fieldsProps[FieldEnum.day].value,
    ] = value.split('-')
  }

  const onFieldChange = () => {
    if (onChange) {
      const day = fieldsProps[FieldEnum.day].ref.current!.value
      const month = fieldsProps[FieldEnum.month].ref.current!.value
      const year = fieldsProps[FieldEnum.year].ref.current!.value

      const date = `${year}-${month}-${day}`

      if (isValidDate(date)) {
        onChange(getISOFormattedDate(date))
      } else {
        onChange(date)
      }
    }
  }

  const commonProps: Partial<BaseInputProps<HTMLInputElement>> = {
    disabled,
    error,
    onChange: onFieldChange,
    onBlur,
    type: 'text',
    inputMode: 'numeric',
    pattern: '[0-9]*',
    autoComplete: 'off',
  }

  return (
    <div className={baseInputStyles.BaseInput}>
      <Fieldset legend={label} role="group">
        {helpText && <HelpText>{helpText}</HelpText>}
        {ErrorMessages && <ErrorMessages />}

        <div className={styles.dateInputFieldWrapper}>
          {fieldsOrder.map((field) => (
            <BaseInput
              {...commonProps}
              className={cn(
                styles.dateInputField,
                fieldsProps[field]?.className
              )}
              label={fieldsProps[field].label}
              aria-label={fieldsProps[field].aria}
              maxLength={fieldsProps[field].length}
              defaultValue={fieldsProps[field].value}
              ref={fieldsProps[field].ref}
              key={`dateInput_${field}`}
              data-testid={`dateInput_${field}`}
              labelProps={{
                className: styles.dateInputFieldLabel,
                'data-testid': `dateInput_${field}_label`,
              }}
            />
          ))}
        </div>
      </Fieldset>
    </div>
  )
}

DateInput.theme = dateInputTheme

export default DateInput
