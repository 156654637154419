import gql from 'graphql-tag'

export default gql`
  query fetchVideoCallInfoByToken($token: String!) {
    fetchVideoCallInfoByToken(token: $token) {
      id
      appointmentId
      consultant_name
      consultant_avatar_url
      session
      unique_token
    }
  }
`
