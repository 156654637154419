import React, { useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'

import { TrackingActionType } from '@babylon/tracking/react'
import messages from '../messages'
import ReportHeader from './components/ReportHeader'
import IndicatorsSection from './components/IndicatorsSection'
import VitalSignsSection from './components/VitalSignsSection'
import { IndicatorDisplay } from './types'

import LabSection from './components/LabSection'
import LoadingView from './components/LoadingView'
import PageTitle from '@/components/PageTitle/PageTitle'
import useGetWellnessReportMetrics from '@/programmes/hooks/useWellnessReportMetrics'
import useGetLabResults from '@/programmes/hooks/useGetLabResults'
import useTracking from '@/tracking/useTracking'

const ReportHomePage = () => {
  const t = useFormatMessage()
  const { data, loading } = useGetWellnessReportMetrics()
  const { data: labsData, loading: labsLoading } = useGetLabResults()
  const { trackView } = useTracking()

  useEffect(
    () =>
      trackView({
        screenTitle: '/screen/v2/report',
        actionType: TrackingActionType.pageLoaded,
      }),
    [trackView]
  )

  return (
    <>
      <PageTitle title={t(messages.dashboardPageTitle)} />
      <section>
        <div
          className={`tw-flex tw-flex-col tw-items-center tw-min-h-screen ${
            !data && 'tw-justify-center tw-overflow-y-hidden'
          }`}
        >
          <div
            className={`tw-w-full tw-flex tw-flex-col tw-items-center tw-p-4 tw-text-xs lg:tw-text-base tw-text-pretty tw-transform-none ${
              data && 'tw-bg-white'
            }`}
            style={{ maxWidth: '1024px' }}
          >
            {loading && (
              <>
                <LoadingView />
                <LoadingView />
                <LoadingView />
                <LoadingView />
              </>
            )}
            {data && !loading && (
              <>
                <ReportHeader
                  title="Health Report"
                  caption="Thank you for taking your eMed Screen. Personal Health Report summarises your results and gives you recommendations on further steps."
                />
                {data.questionnaire && (
                  <IndicatorsSection
                    indicators={
                      data.questionnaire ?? ([] as IndicatorDisplay[])
                    }
                  />
                )}
                {data.healthMetrics && (
                  <VitalSignsSection indicators={data.healthMetrics} />
                )}
                {!labsLoading && <LabSection labs={labsData} />}
              </>
            )}
            {!data && (
              <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-text-base lg:tw-text-lg tw-font-semibold">
                We couldn&apos;t find your report.
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default ReportHomePage
