import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'DownloadAppPage.pageTitle',
    defaultMessage: 'Download App',
  },
  contentTitle: {
    id: 'DownloadAppPage.contentTitle',
    defaultMessage: 'Thank you for joining eMed!',
  },
  contentMessage: {
    id: 'DownloadAppPage.contentMessage',
    defaultMessage:
      'Download the app, and sign-in to book a Video Appointment 24/7, available at no cost through select health plans. Access our Chatbot and Healthcheck for free.',
  },
})
