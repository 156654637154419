import React from 'react'

import ComponentsButton from '@/programmes/components/Button'

interface ButtonProps {
  title: string
  type?: 'primary' | 'secondary'
  onClick: (event: any) => void
}
const Button: React.FC<ButtonProps> = ({
  title,
  type = 'primary',
  onClick,
}) => (
  <ComponentsButton onClick={onClick} secondary={type !== 'primary'}>
    {title}
  </ComponentsButton>
)

export default Button
