/*
 * Follow the steps described there : docs/standards/app-configuration/env-variable.md
 */

const {
  APP_API_ENDPOINT,
  BABYLON_ENVIRONMENT,
  BING_TRACKING_ID,
  CORE_URL,
  FACEBOOK_APP_ID,
  GOOGLE_API_KEY,
  // The google tracking ID is the GA/GTM key of UA-XXXXXXXX-X
  GOOGLE_TRACKING_ID,
  GOOGLE_ADS_TRACKING_ID,
  GRAPHQL_ENDPOINT,
  PLATFORM_GATEWAY_GRAPHQL_ENDPOINT,
  HEALTH_REPORT_URL,
  MICROSERVICES_URL,
  NHS_FAMILY_FORM,
  NHS_PRESCRIPTION_HELP_URL,
  NHS_SWITCH_FORM,
  NODE_ENV,
  ONE_TRUST_APP_ID,
  OPENTOK_API_KEY,
  PRODUCT_CONFIG_URL,
  PUBNUB_SUBSCRIBE_KEY,
  PUBNUB_AUTH_KEY,
  SENTRY_ENVIRONMENT,
  SENTRY_URL,
  // tracking
  TRACKING_DISABLED,
  ZENDESK_CHAT_API_KEY,
  ZENDESK_CHAT_API_KEY_VAULT,
  // Marketing tags
  MARKETING_TAGS_ENABLED,
  SNAPCHAT_ID,
  // MentionMe RAF
  MENTION_ME_API_URL,
  ENABLE_APPOINTMENT_TIMEZONE,
  WEIGHT_LOSS_INJECTION_ADMINISTERED_URL,
  WEIGHT_LOSS_ONBOARDING_URL,
  WEIGHT_LOSS_REFILL_URL,
  WELLNESS_ONBOARDING_URL,
  WEIGHT_LOSS_SUBSCRIPTION_PRODUCT_ID,
  UK_WELLNESS_SUBSCRIPTION_PRODUCT_ID,
  UK_WELLNESS_BLOOD_TEST_PRODUCT_ID,
  UPGRADE_PLAN_ID_PAYG,
  UPGRADE_PLAN_ID_LITE,
  UPGRADE_PLAN_ID_GOLD,
  US_CN_NAME,
  US_WELLNESS_SUBSCRIPTION_PRODUCT_ID,
  SERVICE_NOW_CHAT_URL,
} = window.babylonConfig || {}

const finalConfig = {
  API_KEY_STRING: 'api_key',
  APP_API_ENDPOINT:
    APP_API_ENDPOINT || 'https://app-uk.dev.babylontech.co.uk/api',
  BABYLON_ENVIRONMENT: BABYLON_ENVIRONMENT || 'dev',
  BING_TRACKING_ID: BING_TRACKING_ID || '',
  CLINICAL_AUTH_TIMEOUT: 5,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID || '417515351756917',
  GOOGLE_API_KEY: GOOGLE_API_KEY || 'AIzaSyDO3jkWIoagfJ9LqKsBAA7iaNfRWxUx9jA',
  GOOGLE_TRACKING_ID: GOOGLE_TRACKING_ID || 'UA-78074544-3',
  GOOGLE_ADS_TRACKING_ID: GOOGLE_ADS_TRACKING_ID || '',
  GRAPHQL_ENDPOINT:
    GRAPHQL_ENDPOINT ||
    (NODE_ENV === 'production'
      ? '/graphql'
      : `https://localhost.${
          process.env.REACT_APP_ENV || 'dev'
        }.babylontech.co.uk:6443/graphql`),
  PLATFORM_GATEWAY_GRAPHQL_ENDPOINT:
    PLATFORM_GATEWAY_GRAPHQL_ENDPOINT ||
    `https://platform-uk.${
      process.env.REACT_APP_ENV || 'dev'
    }.babylontech.co.uk/graph`,
  US_CN_NAME: US_CN_NAME || 'sent with love us',
  WEIGHT_LOSS_SUBSCRIPTION_PRODUCT_ID:
    WEIGHT_LOSS_SUBSCRIPTION_PRODUCT_ID || 'wl-monthly',
  UK_WELLNESS_SUBSCRIPTION_PRODUCT_ID:
    UK_WELLNESS_SUBSCRIPTION_PRODUCT_ID || 'uk-wellness-monthly',
  US_WELLNESS_SUBSCRIPTION_PRODUCT_ID:
    US_WELLNESS_SUBSCRIPTION_PRODUCT_ID || 'us-wellness-monthly',
  UK_WELLNESS_BLOOD_TEST_PRODUCT_ID:
    UK_WELLNESS_BLOOD_TEST_PRODUCT_ID || 'uk-blood-test',
  HEALTH_REPORT_URL:
    HEALTH_REPORT_URL || 'https://health-report-uk.dev.babylontech.co.uk',
  NHS_FAMILY_FORM: NHS_FAMILY_FORM || '/get-started/nhs-family/add-member',
  NHS_PRESCRIPTION_HELP_URL:
    NHS_PRESCRIPTION_HELP_URL ||
    'https://support.babylonhealth.com/cspgpathand?id=csp_kb_category&kb_category=333a787247aaa11067070b55d36d43f5',
  NHS_SWITCH_FORM: NHS_SWITCH_FORM || '/get-started/nhs/eligibility',
  OPENTOK_API_KEY: OPENTOK_API_KEY || 34001322,
  PRODUCT_CONFIG_URL:
    PRODUCT_CONFIG_URL ||
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_ENV &&
      `https://localhost.${process.env.REACT_APP_ENV}.babylontech.co.uk:6443/v2/config`) ||
    '',
  PUBNUB_SUBSCRIBE_KEY:
    PUBNUB_SUBSCRIBE_KEY || 'sub-c-d9720118-7673-11e6-b8e1-0619f8945a4f',
  SENTRY_ENVIRONMENT,
  SENTRY_URL: SENTRY_URL || '',
  VERSION: process.env.REACT_APP_VERSION,
  ZENDESK_CHAT_API_KEY:
    ZENDESK_CHAT_API_KEY || '6c0a5e78-0be0-4480-9a47-aa33bf5ea535',
  ZENDESK_CHAT_API_KEY_VAULT,
  PUBNUB_AUTH_KEY: PUBNUB_AUTH_KEY || 'YGoXsqkwEQQx4iUa',

  // These are chatbot items
  MICROSERVICES_URL:
    MICROSERVICES_URL ||
    (process.env.REACT_APP_ENV
      ? `https://services-uk.${process.env.REACT_APP_ENV}.babylontech.co.uk`
      : 'https://localhost.dev.babylontech.co.uk:6443'),
  CORE_URL: CORE_URL || 'https://app-uk.dev.babylontech.co.uk',

  // tracking
  TRACKING_DISABLED: TRACKING_DISABLED || false,

  // Marketing tags
  MARKETING_TAGS_ENABLED,
  SNAPCHAT_ID: SNAPCHAT_ID || 'd6ec9328-3d08-46fb-8fc8-796de0ddc6c4',

  // MentionMe RAF
  MENTION_ME_API_URL: MENTION_ME_API_URL || 'https://tag-demo.mention-me.com',
  MENTION_ME_PARTNER_ID: 'mm391978d7',
  IS_SERVER: Boolean(window.isServer),
  ENABLE_APPOINTMENT_TIMEZONE,
  WEIGHT_LOSS_INJECTION_ADMINISTERED_URL:
    WEIGHT_LOSS_INJECTION_ADMINISTERED_URL ||
    `https://services-uk.${
      process.env.REACT_APP_ENV || 'dev'
    }.babylontech.co.uk/integrations/v1/weight_loss/injection`,
  WEIGHT_LOSS_ONBOARDING_URL:
    WEIGHT_LOSS_ONBOARDING_URL ||
    `https://services-uk.${
      process.env.REACT_APP_ENV || 'dev'
    }.babylontech.co.uk/integrations/v1/weight_loss/onboarding_completed`,
  WEIGHT_LOSS_REFILL_URL:
    WEIGHT_LOSS_REFILL_URL ||
    `https://services-uk.${
      process.env.REACT_APP_ENV || 'dev'
    }.babylontech.co.uk/integrations/v1/weight_loss/refill`,
  WELLNESS_ONBOARDING_URL:
    WELLNESS_ONBOARDING_URL ||
    `https://services-uk.${
      process.env.REACT_APP_ENV || 'dev'
    }.babylontech.co.uk/integrations/v1/wellness_programme/onboarding_completed`,
  ONE_TRUST_APP_ID,
  UPGRADE_PLAN_ID_PAYG,
  UPGRADE_PLAN_ID_LITE,
  UPGRADE_PLAN_ID_GOLD,
  SERVICE_NOW_CHAT_URL,
}

module.exports = finalConfig
