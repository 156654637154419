export type Feature = {
  name: string
}

export type Subscription = {
  currentPeriodEnd: string
  id: string
  price: Price
}

export type Price = {
  currency: string
  id: string
  interval: string
  lookupKey: string
  unitAmount: number
}

export enum ProductType {
  Mounjaro = 'Mounjaro',
  Wegovy = 'Wegovy',
}

export type Product = {
  defaultPrice: string | null
  description: string
  disabled?: boolean
  features: Feature[]
  group: string
  hasLegacySubscription: boolean | null
  id: string
  images: string[]
  isRecommended: boolean
  name: string
  order: number | null
  prices: Price[]
  subscription: Subscription | null
  targetConsumerNetworkId: number | null
  type?: ProductType
}
