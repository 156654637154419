import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Alert, Link, Text } from '@babylon/medkit'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { miamiTheme, ThemeProvider } from '@babylon/web-platform-ui'
import { useLocation } from 'react-router-dom'
import Template from '../Template'
import messages from '../messages'
import {
  useWeightLossImmediatePayment,
  useWeightLossDiscountCode,
  useWeightLossDiscountCodeValidUntil,
  useWeightLossDiscountAmount,
  useWeightLossDiscountNewPrice,
} from '@/redux/selectors'
import styles from '../index.module.scss'
import { onboardingSteps } from '../Setup/steps'
import { getDiscountCode } from '@/hooks/useHandleDiscountCode'
import ProductsQuery from '@/queries/Products'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'
import { Product, ProductType } from '@/programmes/components/Products/models'
import Products from '@/programmes/components/Products/Products'
import { setupRoutes, checkoutRoutes } from '@/programmes/WeightLoss/routes'
import Spinner from '@/programmes/components/Spinner'
import {
  commonFeatures,
  productGroups,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/data'
import { addStripeLiveData } from '@/programmes/WeightLoss/CheckOut/ProductPicker/utils/addStripeLiveData'
import { ProductPicker } from '@/programmes/WeightLoss/CheckOut/ProductPicker/ProductPicker'
import { splitProducts } from '@/programmes/WeightLoss/CheckOut/ProductPicker/utils/splitProducts'
import { addCommonFeatures } from '@/programmes/WeightLoss/CheckOut/ProductPicker/utils/addCommonFeatures'
import {
  ADDON_PRODUCT_GROUP,
  LITE_PRODUCT_TYPE,
  MOUNJARO_PRODUCT_ID,
  ULTRA_PRODUCT_TYPE,
  WEIGHT_LOSS_PRODUCT_GROUP,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/const'
import DiscountDetail from '@/queries/DiscountDetail'

const cx = classNames.bind(styles)

const useGetDiscountParams = () => {
  // these are coming from Product Config
  const weightLossDiscountCode = useWeightLossDiscountCode()
  const weightLossDiscountCodeValidUntil = useWeightLossDiscountCodeValidUntil()
  const weightLossDiscountAmount = useWeightLossDiscountAmount()
  const weightLossDiscountNewPrice = useWeightLossDiscountNewPrice()

  // these are coming from Ads -> Marketing website
  const discountCodeParams = getDiscountCode()

  return {
    discountCodeId: discountCodeParams?.discount_code_id,
    discountCode: discountCodeParams?.discount_code || weightLossDiscountCode,
    discountAmount:
      discountCodeParams?.discount_amount || weightLossDiscountAmount,
    discountExpiry:
      discountCodeParams?.discount_expiry || weightLossDiscountCodeValidUntil,
    discountNewPrice:
      discountCodeParams?.discount_new_price || weightLossDiscountNewPrice,
  }
}

const CheckOutHome = () => {
  const t = useFormatMessage()
  const weightLossImmediatePayment = useWeightLossImmediatePayment()
  const {
    discountCode,
    discountCodeId,
    discountAmount,
    discountExpiry,
    discountNewPrice,
  } = useGetDiscountParams()

  const v2 = getDiscountCode()?.discount_v2

  const hasDiscountCode = discountCode && discountAmount && discountExpiry
  const userUUID = useSelector((state: any) => state.auth?.user?.uuid)

  const [productsLookupKeys, setProductsLookupKeys] = useState<string[]>([])

  const [products, setProducts] = useState<Product[]>([])
  const [addons, setAddons] = useState<Product[]>([])

  const { data: productsData, loading: productsLoading } = useQuery(
    ProductsQuery,
    {
      fetchPolicy: 'network-only',
      context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
      variables: {
        patientId: userUUID,
        productGroups: [WEIGHT_LOSS_PRODUCT_GROUP, ADDON_PRODUCT_GROUP],
      },
    }
  )

  useEffect(() => {
    if (productsData) {
      const productsFromApi: Product[] = productsData?.patient?.products?.items
      const { products, addons } = splitProducts(productsFromApi)

      setProducts(products)
      setAddons(addons)

      const lookupKeys = products
        .map(
          (product) =>
            product.prices.find((price) => price.id === product.defaultPrice)
              ?.lookupKey
        )
        .filter((key): key is string => key !== undefined)
      setProductsLookupKeys(lookupKeys)
    }
  }, [productsData])

  const {
    data: discountDetailsData,
    loading: discountDetailsLoading,
  } = useQuery(DiscountDetail, {
    fetchPolicy: 'network-only',
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      lookupKeys: productsLookupKeys,
      promotionCode: discountCode,
    },
    skip: productsLookupKeys.length === 0 || !discountCode,
  })

  // This is for A/B testing
  const useQueryParams = new URLSearchParams(useLocation().search)
  const newProductPicker =
    !v2 || useQueryParams.get('ab_override_product_picker_v3') === '1'

  if (productsLoading || discountDetailsLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner colour="#000000" />
      </div>
    )
  }

  const discountDetails = discountDetailsData?.discountDetail

  // This is required to provide backward compatibility with the old product picker
  const filteredProducts = products.filter((product) => {
    const productName = product.name.toLowerCase()

    return (
      !productName.includes(LITE_PRODUCT_TYPE) &&
      !productName.includes(ULTRA_PRODUCT_TYPE)
    )
  })

  // This is a temporary solution that allows adding to the product type. It is necessary to complete the questionnaire data.
  const enhancedProducts: Product[] = filteredProducts.map((product) => {
    if (product.name.toLowerCase().includes(MOUNJARO_PRODUCT_ID)) {
      return {
        ...product,
        type: ProductType.Mounjaro,
      }
    }

    return {
      ...product,
      type: ProductType.Wegovy,
    }
  })

  const productGroupsWithCommonFeatures = addCommonFeatures(
    productGroups,
    commonFeatures
  )

  const enhancedProductGroups = addStripeLiveData(
    products,
    productGroupsWithCommonFeatures,
    discountDetails,
    addons
  )

  return (
    <Template
      title={
        newProductPicker
          ? t(messages.checkoutPageTitleV3)
          : t(messages.checkoutPageTitle)
      }
      steps={!newProductPicker ? onboardingSteps : undefined}
      activeStep={!newProductPicker ? 1 : undefined}
      trackingScreenTitle={newProductPicker ? 'v3' : 'v2'}
    >
      {!newProductPicker && (
        <>
          <h2 className={cx('heading')}>{t(messages.checkoutPageHeading)}</h2>

          <ThemeProvider theme={miamiTheme}>
            {discountCode && discountAmount && discountExpiry && (
              <Alert variant="status" className={cx('discount-code-alert')}>
                <div>
                  <span className={cx('discount-code-description')}>
                    {t(messages.checkoutPageDiscountCodeText1)}{' '}
                    <strong>{discountCode}</strong>
                  </span>{' '}
                  <span className={cx('discount-code-description')}>
                    {t(messages.checkoutPageDiscountCodeText2)} {discountAmount}
                  </span>
                </div>
                <div style={{ fontSize: '12px' }}>
                  {t(messages.checkoutPageDiscountValidUntil)} {discountExpiry}
                </div>
              </Alert>
            )}
            <Products
              products={enhancedProducts}
              successPath={setupRoutes.gpInfo}
              cancelPath={checkoutRoutes.home}
              discountCodeId={discountCodeId}
            />
          </ThemeProvider>
        </>
      )}

      {newProductPicker && (
        <ProductPicker
          productGroups={enhancedProductGroups}
          successPath={setupRoutes.gpInfo}
          cancelPath={`${checkoutRoutes.home}?ab_override_product_picker_v3=1`}
          discountDetails={discountDetails}
        />
      )}

      {weightLossImmediatePayment ? (
        <Text className={cx('small-text')} variant="smallBody">
          {t(messages.checkoutDescription3Immediate)}
        </Text>
      ) : (
        <>
          <Text className={cx('small-text')} variant="smallBody">
            {t(messages.checkoutDescription3Immediate)}
          </Text>
          <Text className={cx('small-text')} variant="smallBody">
            {t(messages.checkoutPageSmallPrint)}
          </Text>
        </>
      )}

      <Link className={cx('small-text')} id="subtitle" href="/terms" blank>
        {hasDiscountCode && discountNewPrice
          ? t(messages.checkoutAfterDiscountCodeTermsAndConditions)
          : t(messages.checkoutPageTermsAndConditions)}
      </Link>
      <Text className={cx('small-text')} variant="smallBody">
        {t(messages.checkoutMounjaroDisclaimer)}
      </Text>
    </Template>
  )
}

export default CheckOutHome
