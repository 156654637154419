import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { TrackingActionType } from '@babylon/tracking/react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cn } from '@/components/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  LITE_PRODUCT_TYPE,
  OLD_RECOMMENDED_PRODUCT_TYPE,
  OLD_ULTRA_PRODUCT_TYPE,
  ULTRA_PRODUCT_TYPE,
  RECOMMENDED_PRODUCT_TYPE,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/const'
import {
  Product,
  Products,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import useGetCheckoutLink from '@/programmes/hooks/useGetCheckoutLink'
import { useProductsData } from '@/programmes/WeightLoss/CheckOut/ProductPicker/ProductPicker'
import messages from '@/programmes/WeightLoss/messages'
import useSubmitFormData from '@/programmes/components/Form/hooks/useSubmitFormData'
import { WEIGHT_LOSS_ONBOARDING_URL } from '@/config'
import getPreScreeningSteps from '@/programmes/WeightLoss/steps/getPreScreeningSteps'
import { QUESTIONNAIRE_FORM_DATA_KEY } from '@/programmes/components/Form/hooks/useForm'
import useTracking from '@/tracking/useTracking'

interface ProductSelectorProps {
  products: Products
}

export const ProductSelector = ({ products }: ProductSelectorProps) => {
  const t = useFormatMessage()
  const location = useLocation()
  const { trackEvent } = useTracking()
  const productsArray: Product[] = Object.keys(products).map(
    (key) => products[key]
  )
  const [selectedProductType, setSelectedProductType] = useState<string>(
    RECOMMENDED_PRODUCT_TYPE
  )
  const [selectedProduct, setSelectedProduct] = useState<Product>(
    productsArray.filter(
      (product) =>
        product.productTier === RECOMMENDED_PRODUCT_TYPE ||
        product.productTier === OLD_RECOMMENDED_PRODUCT_TYPE
    )[0]
  )
  const [error, setError] = useState<string | null>(null)

  const handleProductTypeChange = (value: string) => {
    trackEvent({
      screenTitle: location.pathname,
      actionType: TrackingActionType.click,
      eventAction: 'select-plan',
      eventLabel: value,
    })

    setSelectedProductType(value)
    setSelectedProduct(
      productsArray.filter((product) => product.productTier === value)[0]
    )
  }

  const { successPath, cancelPath, discountDetails } = useProductsData()

  const addons = selectedProduct.addons.map((addon) => ({
    id: addon,
    quantity: 1,
  }))

  const productList = [{ id: selectedProduct.id, quantity: 1 }]

  if (addons.length > 0) {
    productList.push(...addons)
  }

  const { checkOutMutation, loading } = useGetCheckoutLink({
    productList,
    successPath,
    cancelPath,
    discountCodeId: selectedProduct.discount
      ? discountDetails?.promoCodeId
      : undefined,
  })

  const {
    submitData: submitQuestionnaire,
    loading: submitQuestionnaireLoading,
    error: submitQuestionnaireError,
  } = useSubmitFormData(WEIGHT_LOSS_ONBOARDING_URL, getPreScreeningSteps())

  const isLoading = loading || submitQuestionnaireLoading

  useEffect(() => {
    if (submitQuestionnaireError) {
      setError(t(messages.checkoutSubmitError))
    }
  }, [submitQuestionnaire, submitQuestionnaireError, t])

  useEffect(() => {
    const stripeDataValid = productsArray.every(
      (product) => product.price && product.addons.every((addon) => addon)
    )

    if (!stripeDataValid) {
      setError(t(messages.checkoutPageError))
    }
  }, [productsArray, t])

  const handleCheckout = async () => {
    trackEvent({
      screenTitle: location.pathname,
      actionType: TrackingActionType.click,
      eventAction: 'click-checkout',
    })

    const formData = sessionStorage.getItem(QUESTIONNAIRE_FORM_DATA_KEY)

    if (!formData) {
      return
    }

    const { answers } = JSON.parse(formData)
    const submissionSuccess = await submitQuestionnaire(
      answers,
      selectedProduct.productType
    )

    if (submissionSuccess) {
      checkOutMutation()
    }
  }

  if (error) {
    return <p className="tw-text-red-600">{error}</p>
  }

  return (
    <>
      <RadioGroup
        defaultValue={selectedProductType}
        className="tw-mt-6"
        onValueChange={handleProductTypeChange}
      >
        {productsArray.map((product, index) => (
          <label
            key={product.id ? product.id : index}
            htmlFor={product.id}
            className={cn(
              'tw-border tw-border-solid tw-border-gray-300 tw-rounded-md hover:tw-shadow-lg tw-mt-2',
              { 'tw-bg-gray-100': product.productTier === LITE_PRODUCT_TYPE }
            )}
          >
            <div className="tw-flex tw-justify-end">
              <Badge
                className={cn(
                  'tw-py-1 tw-bg-accent tw-text-primary tw-uppercase tw-rounded-none tw-rounded-tr-md',
                  { 'tw-hidden': !product.badge }
                )}
                variant="outline"
                aria-hidden={!product.badge}
              >
                {t(messages.checkoutRecommended)}
              </Badge>
            </div>
            <div
              className={cn('tw-mt-1 tw-ml-3 tw-mr-2 tw-mb-6', {
                'tw-my-6': !product.badge,
              })}
            >
              <div className="tw-flex tw-items-center tw-space-x-2">
                <RadioGroupItem
                  value={product.productTier}
                  id={product.id}
                  aria-label={product.name}
                />
                <div className="tw-w-full tw-flex tw-justify-between">
                  <div id={product.name}>{product.name}</div>
                  <div className="tw-flex tw-justify-end tw-items-center">
                    <span className="tw-mr-2 tw-font-semibold">
                      {product.priceWithDiscount
                        ? product.priceWithDiscount
                        : product.price}
                    </span>
                    {product.discount && (
                      <span className="tw-text-red-700 tw-font-semibold">
                        <del>{product.price}</del>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </label>
        ))}
      </RadioGroup>
      <div className="tw-mt-6">
        {selectedProduct.discount && (
          <div className="tw-w-full tw-flex tw-justify-between tw-mb-2">
            <span>
              {t(messages.checkoutDiscountDescription)} (
              {discountDetails?.promoCodeName}):
            </span>
            <span className="tw-font-semibold tw-text-base md:tw-text-lg">
              - {selectedProduct.discount}
            </span>
          </div>
        )}
        {(selectedProduct.productTier === OLD_ULTRA_PRODUCT_TYPE ||
          selectedProduct.productTier === ULTRA_PRODUCT_TYPE) && (
          <div className="tw-w-full tw-flex tw-justify-between">
            <span>{t(messages.checkoutDelivery)}:</span>
            <span className="tw-text-green-500 tw-text-base md:tw-text-lg">
              {t(messages.checkoutFree)}
            </span>
          </div>
        )}
        <hr className="tw-my-4 tw-border-none tw-h-[1px] tw-bg-gray-300" />
        <div className="tw-w-full tw-flex tw-justify-between tw-text-lg tw-font-semibold">
          <span>{t(messages.checkoutTotal)}:</span>
          <span className="tw-text-xl tw-font-semibold">
            {selectedProduct.priceWithDiscount
              ? selectedProduct.priceWithDiscount
              : selectedProduct.price}
          </span>
        </div>
      </div>
      <Button
        onClick={handleCheckout}
        className="tw-text-lg tw-mt-4 tw-w-full"
        size="lg"
        disabled={isLoading}
      >
        {t(messages.checkoutPageTitleCta)} -{' '}
        {selectedProduct.priceWithDiscount
          ? selectedProduct.priceWithDiscount
          : selectedProduct.price}
      </Button>
    </>
  )
}
