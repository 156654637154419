import React from 'react'

type Variant = 'narrow' | 'form'

const Container = ({
  children,
  variant,
}: {
  children: React.ReactNode
  variant: Variant
}) => <section className={`Content--${variant}`}>{children}</section>

export default Container
