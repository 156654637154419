import { defineMessages } from 'react-intl'

export default defineMessages({
  ctaLogin: {
    id: 'Programmes.ActionOrLogin.login',
    defaultMessage: 'Log In',
  },
  ctaSignup: {
    id: 'Programmes.ActionOrLogin.signup',
    defaultMessage: 'Create account',
  },
})
