import React from 'react'
import { logException } from '@babylon/sentry'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })

    logException(error, { extra: info })
  }

  render() {
    const { children, Component } = this.props

    if (this.state.hasError) {
      if (Component) {
        return <Component />
      }
      return <h2>Something went wrong.</h2>
    }
    return children
  }
}

export default ErrorBoundary
