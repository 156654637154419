import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { appStores } from '@/config/constants'
import styles from './AppsInfo.module.scss'
import messages from './AppsInfo.messages'
import appStoreBadge from '@/assets/images/app-store-badge.svg'
import playStoreBadge from '@/assets/images/play-store-badge.svg'

interface Props {
  region?: string
  callback?: (device: string) => void
}

const openStoreUrl = (
  device: string,
  region: string,
  callback?: (device: string) => void
) => {
  if (callback) {
    callback(device)
  }

  const url = appStores[region][device]

  if (url) {
    window.open(url)
  }
}

const AppIcons = ({ region = 'GBR', callback }: Props) => {
  const translate = useFormatMessage()

  return (
    <div className={styles.iconsWrapper}>
      <button
        data-testid="appstore-apple"
        type="button"
        onClick={() => openStoreUrl('apple', region, callback)}
        className={styles.storeBadge}
      >
        <img src={appStoreBadge} alt={translate(messages.iosDownloadAltText)} />
      </button>
      <button
        data-testid="appstore-google"
        type="button"
        onClick={() => openStoreUrl('android', region, callback)}
        className={styles.storeBadge}
      >
        <img src={playStoreBadge} alt={translate(messages.playStoreAltText)} />
      </button>
    </div>
  )
}

export default AppIcons
