import { SHOW_REFER_FRIEND, HIDE_REFER_FRIEND } from './constants'
import { RESET_STORE } from '../constants'
import { LOGOUT } from '@/components/Authentication/actionTypes'

const initialState = {
  show: false,
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOGOUT:
    case RESET_STORE:
      return { ...initialState }
    case SHOW_REFER_FRIEND:
      return {
        show: true,
        situation: action.situation,
        wait: action.wait,
        userProvidedDetails: action.userProvidedDetails,
      }
    case HIDE_REFER_FRIEND:
      return {
        show: false,
      }
    default:
      return state
  }
}

export default reducer
