import React, { createContext, useContext, ReactNode } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { TrackingActionType } from '@babylon/tracking/react'
import messages from '@/programmes/WeightLoss/messages'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Badge } from '@/components/ui/badge'
import { TabContent } from '@/programmes/WeightLoss/CheckOut/ProductPicker/components/TabContent'
import {
  DiscountDetails,
  ProductGroup,
  ProductGroups,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import useTracking from '@/tracking/useTracking'

type PathContextType = {
  successPath: string
  cancelPath: string
  discountDetails?: DiscountDetails
}

const ProductsDataContext = createContext<PathContextType | undefined>(
  undefined
)

export const useProductsData = () => {
  const context = useContext(ProductsDataContext)

  if (context === undefined) {
    throw new Error(
      'useProductsData must be used within a ProductsDataProvider'
    )
  }

  return context
}

interface ProductsDataProviderProps extends PathContextType {
  children: ReactNode
}

export const ProductDataProvider: React.FC<ProductsDataProviderProps> = ({
  children,
  successPath,
  cancelPath,
  discountDetails,
}) => (
  <ProductsDataContext.Provider
    value={{ successPath, cancelPath, discountDetails }}
  >
    {children}
  </ProductsDataContext.Provider>
)

interface ProductPickerProps {
  productGroups: ProductGroups
  successPath: string
  cancelPath: string
  discountDetails?: DiscountDetails
}

export const ProductPicker: React.FC<ProductPickerProps> = ({
  productGroups,
  successPath,
  cancelPath,
  discountDetails,
}) => {
  const t = useFormatMessage()
  const { trackEvent } = useTracking()

  const productGroupsArray: ProductGroup[] = Object.keys(productGroups).map(
    (key) => {
      const productGroup = productGroups[key]

      return {
        ...productGroup,
      }
    }
  )

  const handleProductChange = (value: string) => {
    trackEvent({
      screenTitle: location.pathname,
      actionType: TrackingActionType.click,
      eventAction: 'select-product',
      eventLabel: value,
    })
  }

  return (
    <ProductDataProvider
      successPath={successPath}
      cancelPath={cancelPath}
      discountDetails={discountDetails}
    >
      <h2 className="tw-text-2xl tw-font-medium">
        {t(messages.checkoutPageSubHeading)}
      </h2>
      <Tabs
        defaultValue="mounjaro"
        onValueChange={handleProductChange}
        className="tw-flex tw-flex-col tw-mb-6"
      >
        <TabsList className="tw-flex tw-justify-start tw-bg-transparent tw-border-0">
          {productGroupsArray.map((productGroup) => (
            <TabsTrigger
              key={productGroup.id}
              className="tw-bg-transparent tw-text-base md:tw-text-xl tw-border-0 tw-border-b tw-shadow-none tw-rounded-none tw-px-5 tw-h-14 tw-flex-1 tw-flex tw-items-center tw-justify-center tw-font-normal tw-text-primary data-[state=active]:tw-border-b-2 data-[state=active]:tw-font-semibold data-[state=active]:tw-outline-0 data-[state=active]:tw-shadow-none"
              value={productGroup.id}
            >
              {productGroup.label}{' '}
              {productGroup.badge && (
                <Badge
                  className="tw-ml-2 tw-bg-accent tw-text-primary tw-uppercase"
                  variant="outline"
                >
                  {t(messages.checkoutRecommended)}
                </Badge>
              )}
            </TabsTrigger>
          ))}
        </TabsList>
        {productGroupsArray.map((productGroup) => (
          <TabContent productGroup={productGroup} key={productGroup.id} />
        ))}
      </Tabs>
    </ProductDataProvider>
  )
}
