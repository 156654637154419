import React from 'react'

import { Card, CardContent } from '@/components/ui/card'
import RAGConditionalSvgComponent from '@/programmes/Wellness/Report/components/icons/labs/RAGConditionalSvgComponent'
import { ReportColors } from '@/programmes/Wellness/Report/types'

interface RagCardParams {
  name: string
  value: string | number
  unit: string
  rangeLabel?: string
  interpretation?: string
  rag?: ReportColors
}

export const RagCard = ({
  name,
  value,
  unit,
  rangeLabel,
  interpretation,
  rag,
}: RagCardParams) => (
  <Card>
    <CardContent className="tw-p-6 tw-flex tw-items-start tw-justify-between tw-gap-2">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-h-full tw-flex-grow">
        <div className="tw-text-gray-500 dark:tw-text-gray-400 tw-font-bold">
          {name}
        </div>
        <div>
          <span className="tw-text-3xl tw-font-bold">{value}</span>
          <span className="tw-text-gray-500 dark:tw-text-gray-400 tw-pl-2">
            {unit}
          </span>
        </div>
        {rangeLabel && (
          <div className="tw-text-gray-500 dark:tw-text-gray-400">
            {rangeLabel}
          </div>
        )}
        {interpretation && (
          <div className="tw-text-gray-500 dark:tw-text-gray-400">
            {interpretation}
          </div>
        )}
      </div>
      {rag && (
        <div
          style={{
            width: 30,
            height: 30,
          }}
          className="tw-flex tw-items-center tw-justify-center"
        >
          <RAGConditionalSvgComponent rag={rag} />
        </div>
      )}
    </CardContent>
  </Card>
)

export default RagCard
