import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useCurrentUserId } from '@/redux/selectors'
import UpdateGPMutation from '@/mutations/UpdateGP'
import ClinicalUserInformationQuery from '@/queries/ClinicalUserInformation'

type GpInfo = <B extends boolean = false>(
  isLazy?: B
) => B extends true
  ? { getGpInfo: () => void; data: any; loading: boolean }
  : { data: any; loading: boolean }

export const useGetUserGpInfo = ((isLazy?: boolean) => {
  const currentUserId = useCurrentUserId()
  const [getGpInfo, { data, loading, called }] = useLazyQuery(
    ClinicalUserInformationQuery,
    {
      variables: {
        id: currentUserId,
      },
    }
  )

  if (isLazy) {
    return { getGpInfo, data: data?.clinicalUserInformation, loading }
  }

  if (!called) {
    getGpInfo()
  }

  return { data: data?.clinicalUserInformation, loading }
}) as GpInfo // needed because of https://github.com/microsoft/TypeScript/issues/33912

export const useUpdateGpInfo = () => {
  const [updateGpMutation, { data, loading }] = useMutation(UpdateGPMutation)

  return { updateGpMutation, data, loading }
}
