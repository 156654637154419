const CLINICAL_RECORDS_ROOT = '/partners/clinical-records'
const CLINICAL_RECORDS_APPOINTMENTS_ROUTE =
  '/partners/clinical-records/appointments'

const partnerClinicalRecords = {
  id: 'clinicalRecords',
  route: CLINICAL_RECORDS_APPOINTMENTS_ROUTE,
  getUrl: () => CLINICAL_RECORDS_APPOINTMENTS_ROUTE,
  redirect: CLINICAL_RECORDS_ROOT,
  children: [
    {
      id: 'appointments',
      route: CLINICAL_RECORDS_APPOINTMENTS_ROUTE,
      getUrl: () =>
        // only supporting main patient appointments for now, set to '0'
        `${CLINICAL_RECORDS_ROOT}/0/appointments`,
    },
    {
      id: 'appointment',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}`,
      breadcrumb: {
        path: ['appointments'],
      },
    },
    {
      id: 'appointmentConsultantNotes',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/consultant-notes`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/consultant-notes`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentReferrals',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/referrals`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/referrals`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentArchives',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/archives`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/archives`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentPrescriptions',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/prescription`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/prescription`,
      breadcrumb: {
        path: ['appointments', 'appointment'],
      },
    },
    {
      id: 'appointmentCheckIn',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/check-in`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/check-in`,
      breadcrumb: {
        isExcluded: true,
      },
    },
    {
      id: 'appointmentLocation',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/location`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/location`,
      breadcrumb: {
        isExcluded: true,
      },
    },
    {
      id: 'appointmentWaitingRoom',
      route: `${CLINICAL_RECORDS_ROOT}/appointments/:appointmentId/waiting-room`,
      getUrl: ({ appointmentId }) =>
        `${CLINICAL_RECORDS_ROOT}/appointments/${appointmentId}/waiting-room`,
      breadcrumb: {
        isExcluded: true,
      },
    },
  ],
}

export default partnerClinicalRecords
