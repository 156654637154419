"use strict";
exports.__esModule = true;
exports.Rag = exports.QuestionType = exports.PrescriptionStateActionType = exports.PgmReportStatus = exports.PgmReportDecision = exports.PartnerStatus = exports.NotificationTypes = exports.NoticeName = exports.MonitorSymptomItemType = exports.MonitorReminderType = exports.MonitorMetricGroupType = exports.MonitorMetricAggregationType = exports.MessageInputChoiceType = exports.MessageInputChoiceLocationTarget = exports.MemberWorkflowEntityState = exports.MemberThreadType = exports.MemberThreadSubType = exports.ListOrder = exports.IneligibilityReason = exports.Include = exports.IdVerificationStatus = exports.HealthCheckDisplayType = exports.HealthCheckActionType = exports.Gender = exports.FeedbackProgressStatus = exports.EnrolmentStatus = exports.EligibilityStatus = exports.DemographicStatus = exports.CurrencyCode = exports.ConversationType = exports.ConversationMessageType = exports.ConversationMemberType = exports.ConversationInputType = exports.ComparisonPeriod = exports.ClientType = exports.ClientCompatibleFlows = exports.ChoiceInputType = exports.CheckInSupport = exports.CheckInStatus = exports.CallStep = exports.AvailableMedia = exports.AppointmentMedium = exports.AppointmentInviteRecurrenceFrequency = exports.AppointmentInviteMedium = exports.AppointmentFeatures = exports.AppointmentBookingStatus = exports.AggregationType = exports.AggregationTimeframe = exports.AggregationGrouping = exports.ActionType = void 0;
exports.UserContentTaskType = exports.UserContentBannerType = exports.Trend = exports.SortOrder = exports.SmokingStatus = exports.RepeatPrescriptionStatus = exports.ReferralStatus = exports.ReferralSortField = void 0;
var ActionType;
(function (ActionType) {
    ActionType["ClinicianType"] = "clinician_type";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var AggregationGrouping;
(function (AggregationGrouping) {
    AggregationGrouping["Hourly"] = "hourly";
    AggregationGrouping["Daily"] = "daily";
    AggregationGrouping["Monthly"] = "monthly";
    AggregationGrouping["Quarterly"] = "quarterly";
})(AggregationGrouping = exports.AggregationGrouping || (exports.AggregationGrouping = {}));
var AggregationTimeframe;
(function (AggregationTimeframe) {
    AggregationTimeframe["Yearly"] = "yearly";
    AggregationTimeframe["Monthly"] = "monthly";
    AggregationTimeframe["Weekly"] = "weekly";
    AggregationTimeframe["Daily"] = "daily";
})(AggregationTimeframe = exports.AggregationTimeframe || (exports.AggregationTimeframe = {}));
var AggregationType;
(function (AggregationType) {
    AggregationType["Latest"] = "latest";
    AggregationType["Sum"] = "sum";
    AggregationType["Average"] = "average";
    AggregationType["Summary"] = "summary";
})(AggregationType = exports.AggregationType || (exports.AggregationType = {}));
var AppointmentBookingStatus;
(function (AppointmentBookingStatus) {
    AppointmentBookingStatus["Pending"] = "pending";
    AppointmentBookingStatus["Cancelled"] = "cancelled";
    AppointmentBookingStatus["TimedOut"] = "timed_out";
    AppointmentBookingStatus["NoShow"] = "no_show";
    AppointmentBookingStatus["Paid"] = "paid";
    AppointmentBookingStatus["Completed"] = "completed";
})(AppointmentBookingStatus = exports.AppointmentBookingStatus || (exports.AppointmentBookingStatus = {}));
var AppointmentFeatures;
(function (AppointmentFeatures) {
    AppointmentFeatures["ServiceTypeBooking"] = "service_type_booking";
})(AppointmentFeatures = exports.AppointmentFeatures || (exports.AppointmentFeatures = {}));
var AppointmentInviteMedium;
(function (AppointmentInviteMedium) {
    AppointmentInviteMedium["FaceToFace"] = "face_to_face";
    AppointmentInviteMedium["Voice"] = "voice";
    AppointmentInviteMedium["Video"] = "video";
})(AppointmentInviteMedium = exports.AppointmentInviteMedium || (exports.AppointmentInviteMedium = {}));
var AppointmentInviteRecurrenceFrequency;
(function (AppointmentInviteRecurrenceFrequency) {
    AppointmentInviteRecurrenceFrequency["Daily"] = "DAILY";
    AppointmentInviteRecurrenceFrequency["Weekly"] = "WEEKLY";
    AppointmentInviteRecurrenceFrequency["Monthly"] = "MONTHLY";
    AppointmentInviteRecurrenceFrequency["Yearly"] = "YEARLY";
})(AppointmentInviteRecurrenceFrequency = exports.AppointmentInviteRecurrenceFrequency || (exports.AppointmentInviteRecurrenceFrequency = {}));
var AppointmentMedium;
(function (AppointmentMedium) {
    AppointmentMedium["Voice"] = "voice";
    AppointmentMedium["Video"] = "video";
    AppointmentMedium["Physical"] = "physical";
    AppointmentMedium["Text"] = "text";
})(AppointmentMedium = exports.AppointmentMedium || (exports.AppointmentMedium = {}));
var AvailableMedia;
(function (AvailableMedia) {
    AvailableMedia["Video"] = "video";
    AvailableMedia["Voice"] = "voice";
    AvailableMedia["Physical"] = "physical";
})(AvailableMedia = exports.AvailableMedia || (exports.AvailableMedia = {}));
var CallStep;
(function (CallStep) {
    CallStep["NotificationReceived"] = "notification_received";
    CallStep["NotificationAccepted"] = "notification_accepted";
})(CallStep = exports.CallStep || (exports.CallStep = {}));
var CheckInStatus;
(function (CheckInStatus) {
    CheckInStatus["NotOpened"] = "NOT_OPENED";
    CheckInStatus["Open"] = "OPEN";
    CheckInStatus["CheckedIn"] = "CHECKED_IN";
    CheckInStatus["Missed"] = "MISSED";
    CheckInStatus["Failed"] = "FAILED";
    CheckInStatus["Cancelled"] = "CANCELLED";
})(CheckInStatus = exports.CheckInStatus || (exports.CheckInStatus = {}));
var CheckInSupport;
(function (CheckInSupport) {
    CheckInSupport["NotSupported"] = "NOT_SUPPORTED";
    CheckInSupport["Optional"] = "OPTIONAL";
    CheckInSupport["Required"] = "REQUIRED";
})(CheckInSupport = exports.CheckInSupport || (exports.CheckInSupport = {}));
var ChoiceInputType;
(function (ChoiceInputType) {
    ChoiceInputType["Text"] = "text";
    ChoiceInputType["Location"] = "location";
})(ChoiceInputType = exports.ChoiceInputType || (exports.ChoiceInputType = {}));
var ClientCompatibleFlows;
(function (ClientCompatibleFlows) {
    ClientCompatibleFlows["CheckIn"] = "CHECK_IN";
    ClientCompatibleFlows["Queued"] = "QUEUED";
})(ClientCompatibleFlows = exports.ClientCompatibleFlows || (exports.ClientCompatibleFlows = {}));
var ClientType;
(function (ClientType) {
    ClientType["Member"] = "member";
    ClientType["Clinician"] = "clinician";
})(ClientType = exports.ClientType || (exports.ClientType = {}));
var ComparisonPeriod;
(function (ComparisonPeriod) {
    ComparisonPeriod["WeekOnWeek"] = "WEEK_ON_WEEK";
    ComparisonPeriod["MonthOnMonth"] = "MONTH_ON_MONTH";
})(ComparisonPeriod = exports.ComparisonPeriod || (exports.ComparisonPeriod = {}));
var ConversationInputType;
(function (ConversationInputType) {
    ConversationInputType["Text"] = "text";
    ConversationInputType["TextAndFiles"] = "text_and_files";
    ConversationInputType["Date"] = "date";
    ConversationInputType["Phone"] = "phone";
    ConversationInputType["Symptom"] = "symptom";
    ConversationInputType["Choices"] = "choices";
    ConversationInputType["Number"] = "number";
    ConversationInputType["Measurement"] = "measurement";
    ConversationInputType["Card"] = "card";
})(ConversationInputType = exports.ConversationInputType || (exports.ConversationInputType = {}));
var ConversationMemberType;
(function (ConversationMemberType) {
    ConversationMemberType["User"] = "user";
    ConversationMemberType["Babylon"] = "babylon";
})(ConversationMemberType = exports.ConversationMemberType || (exports.ConversationMemberType = {}));
var ConversationMessageType;
(function (ConversationMessageType) {
    ConversationMessageType["Text"] = "text";
    ConversationMessageType["Location"] = "location";
    ConversationMessageType["Image"] = "image";
    ConversationMessageType["Diagnosis"] = "diagnosis";
    ConversationMessageType["Leaflet"] = "leaflet";
    ConversationMessageType["DoctorResponse"] = "doctor_response";
    ConversationMessageType["Notice"] = "notice";
    ConversationMessageType["Action"] = "action";
})(ConversationMessageType = exports.ConversationMessageType || (exports.ConversationMessageType = {}));
var ConversationType;
(function (ConversationType) {
    ConversationType["Proms"] = "proms";
    ConversationType["Test"] = "test";
    ConversationType["Support"] = "support";
    ConversationType["CareLiveChat"] = "care_live_chat";
    ConversationType["Healthcheck"] = "healthcheck";
    ConversationType["Hmp"] = "hmp";
    ConversationType["SupportTicket"] = "support_ticket";
    ConversationType["Information"] = "information";
    ConversationType["PreBooking"] = "pre_booking";
})(ConversationType = exports.ConversationType || (exports.ConversationType = {}));
var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["Gbp"] = "gbp";
    CurrencyCode["Usd"] = "usd";
    CurrencyCode["Eur"] = "eur";
    CurrencyCode["Cad"] = "cad";
})(CurrencyCode = exports.CurrencyCode || (exports.CurrencyCode = {}));
var DemographicStatus;
(function (DemographicStatus) {
    DemographicStatus["Failed"] = "failed";
    DemographicStatus["Retrieved"] = "retrieved";
    DemographicStatus["Pending"] = "pending";
    DemographicStatus["Unknown"] = "unknown";
})(DemographicStatus = exports.DemographicStatus || (exports.DemographicStatus = {}));
var EligibilityStatus;
(function (EligibilityStatus) {
    EligibilityStatus["EligibleArea"] = "eligible_area";
    EligibilityStatus["NotEligibleArea"] = "not_eligible_area";
    EligibilityStatus["NoPostcodePassed"] = "no_postcode_passed";
    EligibilityStatus["InvalidPostcode"] = "invalid_postcode";
    EligibilityStatus["NotEnglishPostcode"] = "not_english_postcode";
    EligibilityStatus["UserAlreadyExists"] = "user_already_exists";
    EligibilityStatus["ValidUser"] = "valid_user";
})(EligibilityStatus = exports.EligibilityStatus || (exports.EligibilityStatus = {}));
var EnrolmentStatus;
(function (EnrolmentStatus) {
    EnrolmentStatus["Processing"] = "processing";
    EnrolmentStatus["ActionRequired"] = "action_required";
    EnrolmentStatus["Unqueued"] = "unqueued";
})(EnrolmentStatus = exports.EnrolmentStatus || (exports.EnrolmentStatus = {}));
var FeedbackProgressStatus;
(function (FeedbackProgressStatus) {
    FeedbackProgressStatus["InProgress"] = "in_progress";
    FeedbackProgressStatus["Completed"] = "completed";
    FeedbackProgressStatus["Expired"] = "expired";
})(FeedbackProgressStatus = exports.FeedbackProgressStatus || (exports.FeedbackProgressStatus = {}));
var Gender;
(function (Gender) {
    Gender["Female"] = "female";
    Gender["Male"] = "male";
})(Gender = exports.Gender || (exports.Gender = {}));
var HealthCheckActionType;
(function (HealthCheckActionType) {
    HealthCheckActionType["Maintain"] = "maintain";
    HealthCheckActionType["Change"] = "change";
})(HealthCheckActionType = exports.HealthCheckActionType || (exports.HealthCheckActionType = {}));
var HealthCheckDisplayType;
(function (HealthCheckDisplayType) {
    HealthCheckDisplayType["Continuous"] = "continuous";
    HealthCheckDisplayType["Simple"] = "simple";
    HealthCheckDisplayType["Segmented"] = "segmented";
    HealthCheckDisplayType["Status"] = "status";
    HealthCheckDisplayType["Time"] = "time";
    HealthCheckDisplayType["TrafficLight"] = "traffic_light";
})(HealthCheckDisplayType = exports.HealthCheckDisplayType || (exports.HealthCheckDisplayType = {}));
var IdVerificationStatus;
(function (IdVerificationStatus) {
    IdVerificationStatus["New"] = "new";
    IdVerificationStatus["Pending"] = "pending";
    IdVerificationStatus["Succeeded"] = "succeeded";
    IdVerificationStatus["Failed"] = "failed";
    IdVerificationStatus["Unknown"] = "unknown";
})(IdVerificationStatus = exports.IdVerificationStatus || (exports.IdVerificationStatus = {}));
var Include;
(function (Include) {
    Include["Genders"] = "genders";
    Include["PreviousConsultants"] = "previous_consultants";
    Include["NextAvailableConsultants"] = "next_available_consultants";
    Include["IncludeBioPreviousConsultants"] = "include_bio_previous_consultants";
    Include["IncludeBioNextAvailableConsultants"] = "include_bio_next_available_consultants";
})(Include = exports.Include || (exports.Include = {}));
var IneligibilityReason;
(function (IneligibilityReason) {
    IneligibilityReason["InsuranceDetailsNotProvided"] = "insurance_details_not_provided";
    IneligibilityReason["MemberDetailsMismatch"] = "member_details_mismatch";
    IneligibilityReason["InsuranceDetailsMismatch"] = "insurance_details_mismatch";
    IneligibilityReason["CoverageInactive"] = "coverage_inactive";
    IneligibilityReason["CoverageExpired"] = "coverage_expired";
    IneligibilityReason["CoverageWillExpire"] = "coverage_will_expire";
    IneligibilityReason["CoverageNotStarted"] = "coverage_not_started";
    IneligibilityReason["ServiceNotCovered"] = "service_not_covered";
    IneligibilityReason["UnknownReason"] = "unknown_reason";
})(IneligibilityReason = exports.IneligibilityReason || (exports.IneligibilityReason = {}));
var ListOrder;
(function (ListOrder) {
    ListOrder["Asc"] = "asc";
    ListOrder["Desc"] = "desc";
})(ListOrder = exports.ListOrder || (exports.ListOrder = {}));
var MemberThreadSubType;
(function (MemberThreadSubType) {
    MemberThreadSubType["BloodTest"] = "BLOOD_TEST";
    MemberThreadSubType["Absent"] = "ABSENT";
})(MemberThreadSubType = exports.MemberThreadSubType || (exports.MemberThreadSubType = {}));
var MemberThreadType;
(function (MemberThreadType) {
    MemberThreadType["Test"] = "TEST";
    MemberThreadType["Referal"] = "REFERAL";
    MemberThreadType["Referral"] = "REFERRAL";
    MemberThreadType["Prescription"] = "PRESCRIPTION";
    MemberThreadType["Absent"] = "ABSENT";
})(MemberThreadType = exports.MemberThreadType || (exports.MemberThreadType = {}));
var MemberWorkflowEntityState;
(function (MemberWorkflowEntityState) {
    MemberWorkflowEntityState["Completed"] = "COMPLETED";
    MemberWorkflowEntityState["Pending"] = "PENDING";
})(MemberWorkflowEntityState = exports.MemberWorkflowEntityState || (exports.MemberWorkflowEntityState = {}));
var MessageInputChoiceLocationTarget;
(function (MessageInputChoiceLocationTarget) {
    MessageInputChoiceLocationTarget["Hospital"] = "hospital";
    MessageInputChoiceLocationTarget["Pharmacy"] = "pharmacy";
    MessageInputChoiceLocationTarget["EyeCasualty"] = "eye_casualty";
    MessageInputChoiceLocationTarget["StdClinic"] = "std_clinic";
})(MessageInputChoiceLocationTarget = exports.MessageInputChoiceLocationTarget || (exports.MessageInputChoiceLocationTarget = {}));
var MessageInputChoiceType;
(function (MessageInputChoiceType) {
    MessageInputChoiceType["Text"] = "text";
    MessageInputChoiceType["Location"] = "location";
})(MessageInputChoiceType = exports.MessageInputChoiceType || (exports.MessageInputChoiceType = {}));
var MonitorMetricAggregationType;
(function (MonitorMetricAggregationType) {
    MonitorMetricAggregationType["Latest"] = "latest";
})(MonitorMetricAggregationType = exports.MonitorMetricAggregationType || (exports.MonitorMetricAggregationType = {}));
var MonitorMetricGroupType;
(function (MonitorMetricGroupType) {
    MonitorMetricGroupType["Daily"] = "DAILY";
})(MonitorMetricGroupType = exports.MonitorMetricGroupType || (exports.MonitorMetricGroupType = {}));
var MonitorReminderType;
(function (MonitorReminderType) {
    MonitorReminderType["Medication"] = "medication";
    MonitorReminderType["Mood"] = "mood";
    MonitorReminderType["Appointment"] = "appointment";
    MonitorReminderType["Covid"] = "covid";
})(MonitorReminderType = exports.MonitorReminderType || (exports.MonitorReminderType = {}));
var MonitorSymptomItemType;
(function (MonitorSymptomItemType) {
    MonitorSymptomItemType["Graph"] = "graph";
    MonitorSymptomItemType["Timeline"] = "timeline";
})(MonitorSymptomItemType = exports.MonitorSymptomItemType || (exports.MonitorSymptomItemType = {}));
var NoticeName;
(function (NoticeName) {
    NoticeName["TermsAndConditions"] = "TERMS_AND_CONDITIONS";
    NoticeName["PrivacyPolicy"] = "PRIVACY_POLICY";
})(NoticeName = exports.NoticeName || (exports.NoticeName = {}));
var NotificationTypes;
(function (NotificationTypes) {
    NotificationTypes["PushNotification"] = "push_notification";
    NotificationTypes["InAppNotification"] = "in_app_notification";
    NotificationTypes["WebsocketOrPushNotification"] = "websocket_or_push_notification";
})(NotificationTypes = exports.NotificationTypes || (exports.NotificationTypes = {}));
var PartnerStatus;
(function (PartnerStatus) {
    PartnerStatus["Active"] = "active";
    PartnerStatus["Retired"] = "retired";
})(PartnerStatus = exports.PartnerStatus || (exports.PartnerStatus = {}));
var PgmReportDecision;
(function (PgmReportDecision) {
    PgmReportDecision["SelfCare"] = "SELF_CARE";
    PgmReportDecision["Pharmacy"] = "PHARMACY";
    PgmReportDecision["Gp"] = "GP";
    PgmReportDecision["GpUrgent"] = "GP_URGENT";
    PgmReportDecision["Hospital"] = "HOSPITAL";
    PgmReportDecision["HospitalUrgent"] = "HOSPITAL_URGENT";
})(PgmReportDecision = exports.PgmReportDecision || (exports.PgmReportDecision = {}));
var PgmReportStatus;
(function (PgmReportStatus) {
    PgmReportStatus["Scheduled"] = "scheduled";
    PgmReportStatus["Started"] = "started";
    PgmReportStatus["Completed"] = "completed";
})(PgmReportStatus = exports.PgmReportStatus || (exports.PgmReportStatus = {}));
var PrescriptionStateActionType;
(function (PrescriptionStateActionType) {
    PrescriptionStateActionType["AcceptEpsCode"] = "ACCEPT_EPS_CODE";
    PrescriptionStateActionType["ApprovePrescription"] = "APPROVE_PRESCRIPTION";
    PrescriptionStateActionType["Create"] = "CREATE";
    PrescriptionStateActionType["MarkAsConfirmedReceived"] = "MARK_AS_CONFIRMED_RECEIVED";
    PrescriptionStateActionType["MarkAsPrinted"] = "MARK_AS_PRINTED";
    PrescriptionStateActionType["MarkDrugsDispatched"] = "MARK_DRUGS_DISPATCHED";
    PrescriptionStateActionType["MarkPrinted"] = "MARK_PRINTED";
    PrescriptionStateActionType["RejectPharmacySelection"] = "REJECT_PHARMACY_SELECTION";
    PrescriptionStateActionType["RejectPrescription"] = "REJECT_PRESCRIPTION";
    PrescriptionStateActionType["RejectPrescriptionDispensing"] = "REJECT_PRESCRIPTION_DISPENSING";
    PrescriptionStateActionType["ResendEpsCode"] = "RESEND_EPS_CODE";
    PrescriptionStateActionType["Void"] = "VOID";
})(PrescriptionStateActionType = exports.PrescriptionStateActionType || (exports.PrescriptionStateActionType = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["StarRating"] = "star_rating";
    QuestionType["FreeText"] = "free_text";
    QuestionType["SingleSelect"] = "single_select";
    QuestionType["MultiSelect"] = "multi_select";
    QuestionType["MultiSelectWithFreeText"] = "multi_select_with_free_text";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var Rag;
(function (Rag) {
    Rag["Red"] = "red";
    Rag["Amber"] = "amber";
    Rag["Green"] = "green";
})(Rag = exports.Rag || (exports.Rag = {}));
var ReferralSortField;
(function (ReferralSortField) {
    ReferralSortField["Id"] = "id";
    ReferralSortField["FirstName"] = "firstName";
    ReferralSortField["LastName"] = "lastName";
    ReferralSortField["CreatedAt"] = "createdAt";
    ReferralSortField["ConsultantName"] = "consultantName";
    ReferralSortField["SpecialismName"] = "specialismName";
    ReferralSortField["SpecialistName"] = "specialistName";
    ReferralSortField["SpecialistReference"] = "specialistReference";
})(ReferralSortField = exports.ReferralSortField || (exports.ReferralSortField = {}));
var ReferralStatus;
(function (ReferralStatus) {
    ReferralStatus["Pending"] = "pending";
    ReferralStatus["Accepted"] = "accepted";
    ReferralStatus["Rejected"] = "rejected";
})(ReferralStatus = exports.ReferralStatus || (exports.ReferralStatus = {}));
var RepeatPrescriptionStatus;
(function (RepeatPrescriptionStatus) {
    RepeatPrescriptionStatus["Archived"] = "ARCHIVED";
    RepeatPrescriptionStatus["CheckingReorder"] = "CHECKING_REORDER";
    RepeatPrescriptionStatus["Collected"] = "COLLECTED";
    RepeatPrescriptionStatus["ReadyToCollect"] = "READY_TO_COLLECT";
    RepeatPrescriptionStatus["ReorderNow"] = "REORDER_NOW";
    RepeatPrescriptionStatus["ReorderSoon"] = "REORDER_SOON";
    RepeatPrescriptionStatus["ReviewPendingOnGp"] = "REVIEW_PENDING_ON_GP";
    RepeatPrescriptionStatus["ReviewPendingOnPrescribingPharmacist"] = "REVIEW_PENDING_ON_PRESCRIBING_PHARMACIST";
})(RepeatPrescriptionStatus = exports.RepeatPrescriptionStatus || (exports.RepeatPrescriptionStatus = {}));
var SmokingStatus;
(function (SmokingStatus) {
    SmokingStatus["Smoker"] = "smoker";
    SmokingStatus["NonSmoker"] = "non_smoker";
    SmokingStatus["ExSmoker"] = "ex_smoker";
})(SmokingStatus = exports.SmokingStatus || (exports.SmokingStatus = {}));
var SortOrder;
(function (SortOrder) {
    SortOrder["Ascending"] = "ascending";
    SortOrder["Descending"] = "descending";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var Trend;
(function (Trend) {
    Trend["NoChange"] = "NO_CHANGE";
    Trend["NoTrend"] = "NO_TREND";
    Trend["Increased"] = "INCREASED";
    Trend["Decreased"] = "DECREASED";
})(Trend = exports.Trend || (exports.Trend = {}));
var UserContentBannerType;
(function (UserContentBannerType) {
    UserContentBannerType["Alert"] = "alert";
    UserContentBannerType["Error"] = "error";
    UserContentBannerType["Success"] = "success";
    UserContentBannerType["Info"] = "info";
})(UserContentBannerType = exports.UserContentBannerType || (exports.UserContentBannerType = {}));
var UserContentTaskType;
(function (UserContentTaskType) {
    UserContentTaskType["Info"] = "info";
    UserContentTaskType["Transfer"] = "transfer";
})(UserContentTaskType = exports.UserContentTaskType || (exports.UserContentTaskType = {}));
