import React, { useEffect, useRef, useState } from 'react'
import { Box, ThemeProvider, miamiTheme } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'
import { logException } from '@babylon/sentry'
import Loading from '@/components/Loading'
import styles from './ServiceNowChatPage.module.scss'
import { useLogoutLoginServiceNow } from './hooks'
import WebViewMode from '@/utils/WebViewMode'
import { useContactUsUrl, useCurrentUserId } from '@/redux/selectors'
import messages from './ServiceNowChatPage.messages'
import { ConnectingChatSpinner } from './components'
import ErrorModal from './components/ErrorModal'

const ServiceNowChatPage = () => {
  const userId = useCurrentUserId()
  const isWebViewMode = WebViewMode.isWebViewMode()
  const [iframeLoading, setIframeLoading] = useState(true)
  const contactUsUrl = useContactUsUrl()
  const {
    loading,
    url,
    error: authError,
    apolloError,
  } = useLogoutLoginServiceNow({
    userId,
  })
  const [error, setError] = useState<boolean>(!!apolloError || !!authError)
  const initial = useRef<boolean>(true)
  const translate = useFormatMessage()
  const webViewSNOWError = useRef<string>(
    sessionStorage.getItem('webViewSNOWError') ?? 'false'
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const onMessage = (event: MessageEvent) => {
      if (event.data.type === 'SESSION_CREATED' && event.data.authenticated) {
        if (initial.current) {
          initial.current = false
          timeout = setTimeout(() => {
            setIframeLoading(false)
          }, 5000)
        } else {
          clearTimeout(timeout)
          setIframeLoading(false)
        }
      }

      if (event.data.type === 'SESSION_CREATED' && !event.data.authenticated) {
        if (initial.current) {
          initial.current = false

          if (isWebViewMode) {
            if (webViewSNOWError.current === 'false') {
              sessionStorage.setItem('webViewSNOWError', 'true')
              window.location.reload()
            } else {
              logException(
                new Error('ServiceNow chat failed to authenticate in webview')
              )
              setIframeLoading(false)
              setError(true)
            }
          } else {
            timeout = setTimeout(() => {
              setIframeLoading(false)
            }, 5000)
          }
        } else {
          setError(true)
          setIframeLoading(false)
        }
      }
    }
    window.addEventListener('message', onMessage)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('message', onMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const IframeSpinner = (webViewSnowError: boolean) =>
    webViewSnowError ? (
      <ConnectingChatSpinner
        isWebViewMode={isWebViewMode}
        heading={translate(messages.chatLoading)}
      />
    ) : (
      <Loading />
    )

  return (
    <Box
      className={
        isWebViewMode ? styles.iframeContainer__mobile : styles.iframeContainer
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {iframeLoading && IframeSpinner(webViewSNOWError.current !== 'true')}
          <ThemeProvider theme={miamiTheme}>
            <ErrorModal
              error={error}
              setError={setError}
              isWebViewMode={isWebViewMode}
              heading={translate(messages.authError)}
              body={translate(messages.contactUsBody)}
              buttonText={translate(messages.retryBtn)}
              contactUsLinkText={translate(messages.contactUsLink)}
              contactUsUrl={contactUsUrl}
            />
          </ThemeProvider>
          <iframe
            style={{
              display: iframeLoading || error ? 'none' : 'block',
            }}
            id="chat-iframe"
            title="Support Chat"
            className={isWebViewMode ? styles.iframe__mobile : styles.iframe}
            src={url}
          />
        </>
      )}
    </Box>
  )
}

export default ServiceNowChatPage
