import React from 'react'
import { LazyImage } from '@babylon/medkit'
import { logException } from '@babylon/sentry'

const importAsset = (asset: string, fallback?: string) => async () => {
  try {
    const { default: src } = await import(
      /* webpackMode: "lazy-once" */
      `../../assets/${asset}`
    )

    return src
  } catch (error) {
    logException(`Failed to load image "${asset}"`)
    return fallback
  }
}

type Props = {
  asset: string
  fallback?: string
  alt: string
  [prop: string]: any
}

const Image = ({ asset, fallback, ...props }: Props) => (
  <LazyImage load={importAsset(asset, fallback)} {...props} />
)

export default Image
export { importAsset }
