import React, { useEffect } from 'react'
import { Answer } from '../../types'
import InputBody from '../InputBody'
import styles from './RenderConfirmation.module.scss'

type RenderConfirmationProps = {
  id: string
  title: string
  body?: string | string[]
  handleAnswer: (id: string, answer: Answer) => void
}

const RenderConfirmation: React.FC<RenderConfirmationProps> = ({
  body,
  title,
  id,
  handleAnswer,
}) => {
  useEffect(() => {
    handleAnswer(id, 'yes')
  }, [handleAnswer, id])

  return (
    <div>
      <h2 className={styles.titleV1}>{title}</h2>
      {body && <InputBody body={body} />}
    </div>
  )
}

export default RenderConfirmation
