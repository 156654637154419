import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import classNames from 'classnames/bind'
import Template from '../Template'
import { onboardingSteps } from './steps'
import messages from '../messages'
import gpMessages from '@/views/GpDetails/GpDetails.messages'
import GpInfoForm from '@/programmes/components/GPInfoForm/GpInfoForm'
import styles from '@/programmes/WeightLoss/index.module.scss'

const cx = classNames.bind(styles)

const GpDetails = () => {
  const t = useFormatMessage()

  return (
    <Template
      title={t(gpMessages.pageTitle)}
      steps={onboardingSteps}
      activeStep={1}
      afterRedirectTitle={
        /* if we are reaching this page after a redirect it means that the user has just paid
           In this case we want to show a different title so Amit Singh can identify the page in the Google Analytics
           Google Analytics doesn't go via the normal Tealium pipleine yet so we don't have the data layer available
           there. This is a temporary solution until we figure out how GA fits into our analytics pipeline
        */
        t(gpMessages.orderConfirmedPageTitle)
      }
    >
      <h2 className={cx('heading')}>{t(gpMessages.pageTitle)}</h2>
      <p className={cx('description')}>{t(messages.gpDetailsNeeded)}</p>
      <GpInfoForm />
    </Template>
  )
}

export default GpDetails
