import { defineMessages } from 'react-intl'

export default defineMessages({
  checkoutHeading: {
    id: 'BloodTest.Checkout.heading',
    defaultMessage: 'Payment details',
  },
  checkoutDescription1: {
    id: 'BloodTest.Checkout.description1',
    defaultMessage:
      'Thank you for your interest in our eMed® Blood Test - Wellness.',
  },
  checkoutDescription2: {
    id: 'BloodTest.Checkout.description2',
    defaultMessage:
      'We will need your payment details to pay for your test. You will be taken to a secure payment page.',
  },
  checkoutPageTermsAndConditions: {
    id: 'BloodTest.Checkout.termsAndConditions',
    defaultMessage: '* Please see our terms and conditions',
  },
  checkoutPageTitle: {
    id: 'BloodTest.Checkout.Title',
    defaultMessage: 'Payment details',
  },
  checkoutPageTitleCta: {
    id: 'BloodTest.Checkout.cta',
    defaultMessage: 'Pay Now',
  },
  checkoutPageProductDescription: {
    id: 'BloodTest.Checkout.productDescription',
    defaultMessage:
      '• Monitor your health and susceptibility to conditions like heart disease and diabetes with our clinical experts.\n' +
      '• Review your test results with a clinician and receive a personalised report.\n',
  },
  checkoutPageProductTitle: {
    id: 'BloodTest.Checkout.productTitle',
    defaultMessage: 'eMed® Blood Test - Wellness',
  },
  checkoutPageProductPrice: {
    id: 'BloodTest.Checkout.productPrice',
    defaultMessage: '£77.99',
  },
  checkoutConfirmationPageTitle: {
    id: 'BloodTest.checkoutConfirmationPage.pageTitle',
    defaultMessage: 'Blood Test - Payment Confirmation',
  },
  checkoutConfirmationPageHeading: {
    id: 'BloodTest.checkoutConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  checkoutConfirmationPageDescription: {
    id: 'BloodTest.checkoutConfirmationPage.description',
    defaultMessage:
      'Congratulations, you have completed your payment.\n\n' +
      'You will soon receive an email with your receipt and your test kit will be sent to the address you provided during the check out process.\n',
  },
  checkoutConfirmationPageAppInfoHeading: {
    id: 'BloodTest.checkoutConfirmationPage.AppInfoHeading',
    defaultMessage:
      "Once your tests have been completed, you'll need to go to the app to view your results.",
  },
  checkoutConfirmationPageAppInfoDescription: {
    id: 'BloodTest.checkoutConfirmationPage.AppInfoDescription',
    defaultMessage:
      'Download the app, and sign-in to book a Video Appointment 24/7, available at no cost through select health plans. Access our Chatbot and Healthcheck for free.',
  },
  setupConfirmationPageCta: {
    id: 'BloodTest.SetupConfirmationPage.cta',
    defaultMessage: 'Done',
  },
  setupConfirmationPageTitle: {
    id: 'BloodTest.CheckInStartupConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Check-in startup confirmation',
  },
  setupConfirmationPageHeading: {
    id: 'BloodTest.CheckInStartupConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  setupConfirmationPageDescription: {
    id: 'BloodTest.CheckInStartupConfirmationPage.description',
    defaultMessage: 'Congratulations on taking your blood test.',
  },
  setupConfirmationPageCard1Title: {
    id: 'BloodTest.SetupConfirmation.Card1.title',
    defaultMessage: 'Return your sample',
  },
  setupConfirmationPageCard1Description: {
    id: 'BloodTest.SetupConfirmation.Card1.description',
    defaultMessage: 'Return your kit via Royal Mail before noon today.',
  },
  setupConfirmationPageCard1ImageDescription: {
    id: 'BloodTest.SetupConfirmation.Card1.imageDescription',
    defaultMessage: 'Video icon',
  },
  setupConfirmationPageCard2Title: {
    id: 'BloodTest.SetupConfirmation.Card2.title',
    defaultMessage: 'Download the app for your results',
  },
  setupHomePageTitle: {
    id: 'BloodTest.setupHomePage.pageTitle',
    defaultMessage: 'eMed® Blood Test - Setup',
  },
  setupHomePageHeading: {
    id: 'BloodTest.setupHomePage.heading',
    defaultMessage: 'Get ready',
  },
  setupHomePageDescription1: {
    id: 'BloodTest.setupHomePage.description1',
    defaultMessage:
      "You're close to collecting your blood sample and we're here to help through a video call with an eMed Healthcare Technician. Here are key steps to make it easy:\n",
  },
  setupHomePageCta: {
    id: 'BloodTest.setupHomePage.cta',
    defaultMessage: 'Start',
  },
  timeAndPlaceTitle: {
    id: 'BloodTest.SetupHome.Card1.title',
    defaultMessage: 'Time and Place',
  },
  timeAndPlaceDescription: {
    id: 'BloodTest.SetupHome.Card1.description',
    defaultMessage:
      'Set aside 30 minutes in a private space for the whole process, including getting ready, collecting your sample, and wrapping up.',
  },
  sampleMailingTitle: {
    id: 'BloodTest.SetupHome.Card2.title',
    defaultMessage: 'Sample Mailing',
  },
  sampleMailingDescription: {
    id: 'BloodTest.SetupHome.Card2.description',
    defaultMessage:
      "Send your blood sample back quickly after you've collected it. Best to mail it from Monday to Thursday, ideally before noon, to catch the last postal collection.",
  },
  fastingTitle: {
    id: 'BloodTest.SetupHome.Card4.title',
    defaultMessage: 'Fasting',
  },
  fastingDescription: {
    id: 'BloodTest.SetupHome.Card4.description',
    defaultMessage:
      "Don't eat or drink anything except water for 6 hours before collecting your sample. If you've eaten recently, visit this page later. Morning collections might work best for you.",
  },
  fastingCheckboxLabel: {
    id: 'BloodTest.SetupHome.fastingCheckboxLabel',
    defaultMessage:
      'I haven’t eaten or drunk anything other than water for at least 6 hours',
  },
  hydrationCheckboxLabel: {
    id: 'BloodTest.SetupHome.hydrationCheckboxLabel',
    defaultMessage: 'I drank 2 glasses of water 30 minutes ago',
  },
  hydrationTitle: {
    id: 'BloodTest.SetupHome.Card5.title',
    defaultMessage: 'Hydration',
  },
  hydrationDescription: {
    id: 'BloodTest.SetupHome.Card5.description',
    defaultMessage:
      'Drink two large glasses of water 30 minutes before your call. It helps with easier sample collection.',
  },
  clothingTitle: {
    id: 'BloodTest.SetupHome.Card6.title',
    defaultMessage: 'Clothing',
  },
  clothingDescription: {
    id: 'BloodTest.SetupHome.Card6.description',
    defaultMessage:
      'Wear comfortable, loose clothes for the video call. This makes accessing your upper arm easier.',
  },
  deviceTitle: {
    id: 'BloodTest.SetupHome.Card7.title',
    defaultMessage: 'Device Requirements',
  },
  deviceDescription: {
    id: 'BloodTest.SetupHome.Card7.description',
    defaultMessage:
      'Use a device with a camera and microphone for your session with the Healthcare Technician.',
  },
  setupHelpDescription: {
    id: 'BloodTest.SetupHome.helpDescription',
    defaultMessage:
      'If you have not fasted or consumed two glasses of water, please do so before proceeding. These steps are crucial to ensuring the success of your blood test.',
  },
})
