import React from 'react'
import classNames from 'classnames/bind'

import { Link, Text } from '@babylon/medkit'
import styles from './Notifications.module.scss'

const cx = classNames.bind(styles)

export default function NotificationsHeader({ showViewAll, loading, title }) {
  return (
    <header className={cx(styles.header)}>
      <Text variant="h1" tag="h2" className={cx(styles.title)}>
        {title}
      </Text>
      {showViewAll && !loading && <Link to="/notifications">See all</Link>}
    </header>
  )
}
