export enum ReportColors {
  GREEN = 'GREEN',
  AMBER = 'AMBER',
  RED = 'RED',
  GREY = 'GREY',
}

export type IndicatorDisplayData = {
  variable: string
  value: string | string[] | number | number[] | boolean
}
export type HealthReportConfigDisplayContentIcon = {
  type: 'image' | 'value'
  name?: string
  caption?: string
  separator?: string
  color: ReportColors
}

export type HealthReportConfigDisplayContent = {
  caption?: string
  header?: string
  text?: string
  icon: HealthReportConfigDisplayContentIcon
}

export type IndicatorDisplay = {
  linkID: string
  data: IndicatorDisplayData
  matchedDisplayRule: { display: HealthReportConfigDisplayContent }
}
