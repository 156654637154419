/* eslint-disable react/prop-types */
import React from 'react'
import { Input, Select } from '@babylon/ui'
import { logException } from '@babylon/sentry'
import { useFormatMessage } from '@babylon/intl'

import Form from '../Form'
import Modal from './index'
import PhoneInput from '../PhoneInputWrapper'
import DatePicker from '../DatePicker'
import messages from './Modal.messages'
import profileMessages from '@/views/ProfilePage/ProfilePage.messages'

const arrayFields = (
  user,
  checkFields,
  translate,
  useSexAtBirthTerminology,
  phoneDescription
) => {
  const result: string[] = []

  const fieldMap = {
    date_of_birth: {
      rowFields: [
        {
          component: DatePicker,
          name: 'date_of_birth',
          label: translate(messages.dateOfBirth),
          required: true,
          value: null,
          defaultValue: '',
        },
      ],
    },
    phone_number: {
      rowFields: [
        {
          component: PhoneInput,
          name: 'phone_number',
          label: translate(messages.telephoneNumber),
          placeholder: translate(messages.telephoneNumber),
          description: phoneDescription,
          required: true,
          defaultValues: {
            phone_number: '',
            phone_country_code: user.region?.default_phone_country_code || '',
          },
        },
      ],
    },
    gender: {
      rowFields: [
        {
          component: Select,
          required: true,
          name: 'gender',
          label: translate(
            useSexAtBirthTerminology ? messages.sexAtBirth : messages.gender
          ),
          placeholder: translate(
            useSexAtBirthTerminology ? messages.sexAtBirth : messages.gender
          ),
          options: [
            { value: 'male', label: translate(messages.male) },
            { value: 'female', label: translate(messages.female) },
          ],
        },
      ],
    },
    healthcare_identifier: {
      rowFields: [
        {
          component: Input,
          name: 'public_healthcare_identifier',
          label: translate(profileMessages.publicHealthcareIdentifier),
          required: false,
          value: null,
          defaultValue: '',
        },
      ],
    },
  }

  checkFields.forEach((key) => {
    if (!user[key]) {
      result.push(fieldMap[key])
    }
  })

  return result
}

type Props = {
  user: any
  checkFields: string[]
  updatePatient: (options: { variables: any }) => Promise<any>
  avoidClose?: boolean
  useSexAtBirthTerminology?: boolean
  checkPristine?: boolean
  phoneDescription?: string
  onMount?: () => void
  onClose?: () => void
}

const MissingFieldsModal: React.FC<Props> = ({
  onClose,
  checkFields = [],
  user,
  updatePatient,
  checkPristine = false,
  avoidClose = false,
  useSexAtBirthTerminology = false,
  onMount,
  phoneDescription,
}) => {
  const translate = useFormatMessage()

  // if no fields to check or none of them is null in the user object, skip this modal
  if (
    !user ||
    !checkFields.length ||
    checkFields.every((field) => !!user[field])
  ) {
    return null
  }

  const onSubmit = (fields) =>
    updatePatient({
      variables: { patient: fields, id: user.id },
    }).catch(logException)

  if (typeof onMount === 'function') {
    onMount()
  }

  return (
    <Modal
      onClose={onClose}
      avoidClose={avoidClose}
      data-testid="missingFieldsModal"
    >
      <h3>{translate(messages.titleMissing)}</h3>
      <Form
        options={{ submitBtnText: translate(messages.confirm) }}
        fields={arrayFields(
          user,
          checkFields,
          translate,
          useSexAtBirthTerminology,
          phoneDescription
        )}
        onSubmit={onSubmit}
        operationName="updatePatient"
        checkPristine={checkPristine}
      />
    </Modal>
  )
}

export default MissingFieldsModal
