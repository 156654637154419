import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Text, ButtonVariant } from '@babylon/medkit'
import { Search } from '@babylon/icons'
import styles from './Modal.module.scss'
import messages from './Modal.messages'

interface Props {
  onLookup: () => void
}

const EmptyState: React.FC<Props> = ({ onLookup }) => (
  <div className={styles.addressPicker__modal__statesBody}>
    <Search />
    <Text variant="body">
      <FormattedMessage {...messages.modalBodySearchForPostcode} />
    </Text>
    <Button
      form="searchForm"
      className={styles.addressPicker__modal__btnWrapper}
      type="submit"
      variant={ButtonVariant.primary}
      fullWidth
      onClick={onLookup}
      data-testid="addresses-picker-modal-submit-btn"
    >
      <FormattedMessage {...messages.modalCtaFindMyAddress} />
    </Button>
  </div>
)

export default EmptyState
