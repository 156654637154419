import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import Button from '../Button'
import styles from './ErrorView.module.scss'
import messages from './messages'

interface Props {
  retry: () => void
}
const ExitView: React.FC<Props> = ({ retry }) => {
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.containerV1}>
      <div>
        <h1 className={styles.titleV1}>{formatMessage(messages.title)}</h1>
        <span className={styles.subTextV1}>{formatMessage(messages.body)}</span>
      </div>

      <Button title={formatMessage(messages.retry)} onClick={retry} />
    </div>
  )
}

export default ExitView
