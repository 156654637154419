import { defineMessages } from 'react-intl'

export default defineMessages({
  surgeryName: {
    id: 'gpInfoForm.surgeryName',
    defaultMessage: 'Surgery name',
  },
  surgeryPostcode: {
    id: 'gpInfoForm.surgeryPostcode',
    defaultMessage: 'Surgery postcode',
  },
  submitButton: {
    id: 'gpInfoForm.submitButton',
    defaultMessage: 'Save GP details',
  },
  skipButton: {
    id: 'gpInfoForm.skipButton',
    defaultMessage: "I don't have an NHS GP",
  },
  invalidSurgeryName: {
    id: 'gpInfoForm.invalidSurgeryName',
    defaultMessage: 'Please enter a valid surgery name',
  },
  invalidPostcode: {
    id: 'gpInfoForm.invalidPostcode',
    defaultMessage: 'Please enter a valid postcode',
  },
  continue: {
    id: 'gpInfoForm.continue',
    defaultMessage: 'Continue',
  },
  gpDisclaimerTitle: {
    id: 'gpInfoForm.disclaimerTitle',
    defaultMessage: 'If you are a GP at Hand patient, please note:',
  },
  gpDisclaimerBodyFirstParagraph: {
    id: 'gpInfoForm.disclaimerFirstBody',
    defaultMessage:
      'Under NHS regulations, we are unable to offer private services to patients registered with our NHS practice, GP at Hand.',
  },
  gpDisclaimerBodySecondParagraph: {
    id: 'gpInfoForm.disclaimerSecondBody',
    defaultMessage:
      'If you decide to continue with our programme, you must understand that you will need to de-register from GP at Hand and obtain NHS services from a different practice while participating in our programme.',
  },
  gpDisclaimerBodyThirdParagraph: {
    id: 'gpInfoForm.disclaimerThirdBody',
    defaultMessage:
      'Please note that this is a private service, and by signing up, you are choosing to do so independently.',
  },
})
