import React, { useEffect, useState } from 'react'
import uuid from 'uuid/v4'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/react-hooks'
import { MEASUREMENT_UNIT, Weight } from '@/programmes/components/Form/types'
import { ProgrammeCheckInStep } from '../../types'
import AddObservation from '@/mutations/AddObservation'
import { weightInMetric } from '@/programmes/utils/convertHeightAndWeight'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'
import TextInput from '@/programmes/components/Form/components/TextInput'
import styles from '../../index.module.scss'
import Button from '@/programmes/components/Button'
import messages from '../../messages'
import Loading from '@/components/Loading'

type Props = {
  config: ProgrammeCheckInStep
  onWeightSaved: (data) => void
}

const cx = classNames.bind(styles)

const WeightEntryLayout: React.FC<Props> = ({ config, onWeightSaved }) => {
  const t = useFormatMessage()
  const [saveWeight, { data: weightSavedResponse, loading }] = useMutation(
    AddObservation
  )

  const [weight, setWeight] = useState<Weight>({
    unit: MEASUREMENT_UNIT.METRIC,
    kg: '',
    stones: '',
    pounds: '',
  })

  useEffect(() => {
    if (weightSavedResponse) {
      onWeightSaved(weight)
    }
  }, [weightSavedResponse, onWeightSaved, weight])

  const handleSubmitWeight = async () => {
    const weightKg = weightInMetric(weight)
    saveWeight({
      context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
      variables: {
        datapoint: {
          metricId: 'weight',
          value: {
            numericValue: weightKg,
          },
          unit: 'kg',
          dateTime: new Date().toISOString(),
          eventId: uuid(),
        },
        appName: 'babylon',
      },
    })
  }

  const weightLayout = (
    <>
      <h2 data-testid="title">{t(config.title)}</h2>
      <p>{t(config.subtitle || messages.defaultWeightSubtitle)}</p>
      {weight.unit === MEASUREMENT_UNIT.METRIC ? (
        <TextInput
          label={t(messages.weightLayoutKg)}
          type="number"
          required
          data-testid="weightKg"
          onChange={(event) => {
            setWeight({
              ...weight,
              kg: event.target.value,
            })
          }}
        />
      ) : (
        <>
          <div key="Stones" className={styles.textInput}>
            <TextInput
              label={t(messages.weightLayoutStone)}
              type="number"
              required
              onChange={(event) => {
                setWeight({
                  ...weight,
                  stones: event.target.value,
                })
              }}
              value={weight.stones}
            />
          </div>
          <div className={styles.textInput}>
            <TextInput
              label={t(messages.weightLayoutPounds)}
              type="number"
              onChange={(event) => {
                setWeight({
                  ...weight,
                  pounds: event.target.value,
                })
              }}
              value={weight.pounds}
            />
          </div>
        </>
      )}
      <div className={cx('weight-entry-submit-button')}>
        <Button
          onClick={() => {
            handleSubmitWeight()
          }}
        >
          {t(messages.submit)}
        </Button>
      </div>
      <div className={cx('weight-entry-change-unit-button')}>
        <Button
          onClick={() => {
            const newWeight = {
              ...weight,
              unit:
                weight.unit === MEASUREMENT_UNIT.IMPERIAL
                  ? MEASUREMENT_UNIT.METRIC
                  : MEASUREMENT_UNIT.IMPERIAL,
            }
            setWeight(newWeight)
          }}
          secondary
        >
          {weight.unit === MEASUREMENT_UNIT.IMPERIAL
            ? t(messages.weightLayoutSwitchToKg)
            : t(messages.weightLayoutSwitchToLbs)}
        </Button>
      </div>
    </>
  )

  return <>{loading ? <Loading small /> : weightLayout}</>
}

export default WeightEntryLayout
