const ROOT_PATH = '/blood-test'

export const procedures = {
  UK_BLOOD_TEST_SETUP: 'BLOOD_TEST_SETUP',
  US_BLOOD_TEST_SETUP: 'US_BLOOD_TEST_SETUP',
}

export const setupRoutes = {
  home: `${ROOT_PATH}/setup`,
  confirmation: `${ROOT_PATH}/confirmation`,
}

export const checkoutRoutes = {
  home: `${ROOT_PATH}/checkout`,
  confirmation: `${ROOT_PATH}/checkout/confirmation`,
}
