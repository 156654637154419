import { Box, Icon, IconProps } from '@babylon/web-platform-ui'
import React from 'react'

interface Modifier {
  color?: IconProps['color']
  height?: string
  width?: string
}

interface CustomIconProps {
  color: IconProps['color']
  icon: IconProps['icon']
  modifier?: Modifier
  paddingLeft?: string
  paddingRight?: string
  paddingTop?: string
}

export const CustomIcon = ({
  color,
  icon,
  modifier,
  paddingLeft,
  paddingRight,
  paddingTop,
}: CustomIconProps) => (
  <Box
    alignItems="center"
    data-testid="custom-icon"
    display="flex"
    h="fit-content"
    justifyContent="center"
    paddingLeft={paddingLeft ?? '0'}
    paddingRight={paddingRight ?? '0'}
    paddingTop={paddingTop ?? '0'}
    position="relative"
    w="fit-content"
    _before={{
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: '-1',
      width: modifier?.width ?? '14px',
      height: modifier?.height ?? '14px',
      borderRadius: '50%',
      backgroundColor: modifier?.color ?? 'light.brand.primary',
    }}
  >
    <Icon icon={icon} color={color} />
  </Box>
)
