import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { Text, Button, Link, ButtonVariant } from '@babylon/medkit'
import withUserId from '@/wrappers/withUserId'
import withPageTitle from '@/wrappers/withPageTitle'
import messages from './RequestAppointment.messages'
import styles from './RequestAppointment.module.scss'
import { REQUEST_APPOINTMENT_SUCCESS_URL } from './constants'
import { useSupportPhoneNumber } from '../../redux/selectors'

const enhance = compose(
  withUserId,
  withRouter,
  withPageTitle(messages.pageTitle)
)

const Book = ({ history }) => {
  const goToNextPage = () => history.push(REQUEST_APPOINTMENT_SUCCESS_URL)
  const supportPhoneNumber = useSupportPhoneNumber()

  return (
    <section className="Content--narrow">
      <div className={styles.requestAppointment__contentSection}>
        <Text variant="h1" className="PageTitle">
          <FormattedMessage {...messages.bookAppointmentPageTitle} />
        </Text>
        <Text variant="body">
          <FormattedMessage {...messages.bookAppointmentPageSubTitle} />
        </Text>
      </div>
      <div className={styles.requestAppointment__contentSection}>
        <Text variant="h4">
          <FormattedMessage
            {...messages.bookAppointmentCall}
            values={{
              supportPhoneNumber: (
                <Link href={`tel:${supportPhoneNumber}`}>
                  {supportPhoneNumber}
                </Link>
              ),
            }}
          />
        </Text>
      </div>
      <Button variant={ButtonVariant.secondary} onClick={() => goToNextPage()}>
        <FormattedMessage {...messages.bookAppointmentCta} />
      </Button>
    </section>
  )
}

export default enhance(Book)
