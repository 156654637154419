import {
  INDICATOR_VARIABLE_COMPUTED_TYPE,
  ReportColors,
  ReportSources,
} from './types'

const config = {
  indicators: {
    data: [
      {
        id: 'indicator-01',
        source: ReportSources.QUESTIONNAIRE,
        linkID: 'Q2',
        rules: [
          {
            variable: 'SMOKER',
            operator: '=',
            value: '4',
            display: {
              caption: 'Smoking',
              header:
                'You indicated that you smoke over 20 cigarettes or equivalent per day.',
              text:
                "Smoking increases your risk of heart disease, stroke and many types of cancer. Stopping smoking is likely to be the single most beneficial action you could take to improve your longterm health. There are now many different ways that you can be supported to quit smoking. You can get more information, help and the contact details for your local support service <a href='https://www.nhs.uk/better-health/quit-smoking/' target='_blank'>here</a>.",
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.RED,
              },
            },
          },
          {
            variable: 'SMOKER',
            operator: '=',
            value: '3',
            display: {
              caption: 'Smoking',
              header:
                'You indicated that you smoke between 10 and 20 cigarettes or equivalent per day.',
              text:
                "Smoking increases your risk of heart disease, stroke and many types of cancer. Stopping smoking is likely to be the single most beneficial action you could take to improve your longterm health. There are now many different ways that you can be supported to quit smoking. You can get more information, help and the contact details for your local support service <a href='https://www.nhs.uk/better-health/quit-smoking/' target='_blank'>here</a>.",
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.RED,
              },
            },
          },
          {
            variable: 'SMOKER',
            operator: '=',
            value: '2',
            display: {
              caption: 'Smoking',
              header:
                'You indicated that you smoke less than 10 cigarettes or equivalent per day. ',
              text:
                "Smoking increases your risk of heart disease, stroke and many types of cancer, even if you only smoke a small amount. Stopping smoking is likely to be the single most beneficial action you could take to improve your longterm health. There are now many different ways that you can be supported to quit smoking. You can get more information, help and the contact details for your local support service <a href='https://www.nhs.uk/better-health/quit-smoking/' target='_blank'>here</a>.",
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.RED,
              },
            },
          },
          {
            variable: 'SMOKER',
            operator: '=',
            value: '1',
            display: {
              caption: 'Smoking',
              header:
                'You indicated that you are an ex-smoker. Well done for giving up smoking.',
              text:
                'Doing so is likely to be the single most beneficial action for your longterm health. Did you know - after just one year of quitting, your risk of a heart attack will have halved compared to a smoker, and ten years after quitting, your risk of death from lung cancer will have halved compared to a smoker.',
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'SMOKER',
            operator: '=',
            value: '0',
            display: {
              caption: 'Smoking',
              header: 'You are a non-smoker.',
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'SMOKER',
            operator: '=',
            value: '5',
            display: {
              caption: 'Smoking',
              header: 'You indicated that you smoke occasionally',
              text:
                "This still increases your risk of heart disease, stroke and many types of cancer, even if you only smoke a small amount. Stopping smoking is likely to be the single most beneficial action you could take to improve your longterm health. There are now many different ways that you can be supported to quit smoking. You can get more information, help and the contact details for your local support service <a href='https://www.nhs.uk/better-health/quit-smoking/' target='_blank'>here</a>.",
              icon: {
                type: 'image',
                name: 'smoking',
                color: ReportColors.AMBER,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-02',
        source: ReportSources.QUESTIONNAIRE,
        linkID: 'BMI',
        rules: [
          {
            variable: 'BMI',
            operator: '<',
            value: 18.5,
            display: {
              caption: 'BMI',
              header: 'Your weight looks a little low for your height.',
              text:
                'Your weight looks a little low for your height, based on a calculation of your BMI. It is important to remember that BMI is just one measure of health. It cannot, for instance tell the difference between muscle and fat, and it should not be used as a measure of health during pregnancy.',
              icon: {
                type: 'value',
                caption: 'Low',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'BMI',
            operator: '<=',
            value: 24.9,
            display: {
              caption: 'BMI',
              header: 'Your weight looks about right for your height.',
              text:
                'Your weight looks about right for your height, based on a calculation of your BMI. It is important to remember that BMI is just one measure of health. It cannot, for instance, tell the difference between muscle and fat, and it should not be used as a measure of health during pregnancy.',
              icon: {
                type: 'value',
                caption: 'Ideal',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'BMI',
            operator: '<=',
            value: 29.9,
            display: {
              caption: 'BMI',
              header:
                'It looks like you may be a little overweight for your height.',
              text:
                'It looks like you may be a little overweight for your height. We have calculated that your BMI is just over the ideal range of between 18.5 and 24.9. It is important to remember that BMI is just one measure of health. It cannot, for instance, tell the difference between muscle and fat, and it should not be used as a measure of health during pregnancy.',
              icon: {
                type: 'value',
                caption: 'Overweight',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'BMI',
            operator: '>',
            value: 29.9,
            display: {
              caption: 'BMI',
              header: 'It looks like you may be overweight for your height.',
              text:
                'It looks like you may be overweight for your height. We have calculated that your BMI is >30. An ideal BMI is between 18.5 and 24.9. It is important to remember that BMI is just one measure of health. It cannot, for instance, tell the difference between muscle and fat, and it should not be used as a measure of health during pregnancy.',
              icon: {
                type: 'value',
                caption: 'High',
                color: ReportColors.RED,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-03',
        source: ReportSources.QUESTIONNAIRE,
        linkID: ['Q6', 'Q6.1', 'Q6.2'],
        aggregate: 'SUM',
        rules: [
          {
            variable: 'ALCOHOL',
            operator: '=',
            value: 0,
            display: {
              caption: 'Alcohol',
              header:
                'You have indicated that you do not normally drink alcohol',
              icon: {
                type: 'image',
                name: 'alcohol',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'ALCOHOL',
            operator: '=',
            value: 1,
            display: {
              caption: 'Alcohol',
              header:
                'You have indicated that your alcohol intake is within current recommended limits',
              text:
                'You have indicated that your alcohol intake is within current recommended limits (no more than 7 units of alcohol a week for women or no more than 14 units of alcohol a week for men). There is no completely safe level of drinking, but sticking within these guidelines lowers your risk of harming your health.',
              icon: {
                type: 'image',
                name: 'alcohol',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'ALCOHOL',
            operator: '=',
            value: 2,
            display: {
              caption: 'Alcohol',
              header:
                'You have indicated that your alcohol intake is above current recommended limits.',
              text:
                'You have indicated that your alcohol intake is above current recommended limits (no more than 7 units of alcohol a week for women or no more than 14 units of alcohol a week for men). You would benefit from reducing your alcohol intake to improve your longterm health.',
              icon: {
                type: 'image',
                name: 'alcohol',
                color: ReportColors.RED,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-04',
        source: ReportSources.QUESTIONNAIRE,
        linkID: 'Q10',
        rules: [
          {
            variable: 'DIET',
            operator: '=',
            value: '1',
            display: {
              caption: 'Diet',
              header:
                'You have indicated that you consume five portions of fruit and vegtables daily.',
              text:
                'The World Health Organisation recommends that everyone should have five portions of fruit and vegetables daily as part of a healthy diet. Evidence shows that doing so reduces the risk of serious health problems such as strokes, heart attacks and many types of cancer. We recommend that you continue to stick to the good habits you already have in this area.',
              icon: {
                type: 'image',
                name: 'diet',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'DIET',
            operator: '=',
            value: '2',
            display: {
              caption: 'Diet',
              header:
                'We recommend that you try to increase your intake of fruit and vegetables.',
              text:
                'The World Health Organisation recommends that everyone should have five portions of fruit and vegetables daily as part of a healthy diet. Evidence shows that doing so reduces the risk of serious health problems such as strokes, heart attacks and many types of cancer. We recommend that you try to increase your intake of fruit and vegetables to get as close to this as you can.',
              icon: {
                type: 'image',
                name: 'diet',
                color: ReportColors.RED,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-05',
        source: ReportSources.QUESTIONNAIRE,
        linkID: 'Q11',
        rules: [
          {
            variable: 'DIET',
            operator: '=',
            value: '3',
            display: {
              caption: 'Diet',
              header:
                'We recommend that you try to have three meals per day and limit snacking as best you can.',
              text:
                'Skipping meals and snacking instead can lead to a slowdown in metabolism and lead to weight gain. Snacks are also often much lower in nutritional content, vitamins and essential minerals than healthy meals. We recommend that you try to have three meals per day and limit snacking as best you can.',
              icon: {
                type: 'image',
                name: 'diet-snacking',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'DIET',
            operator: '=',
            value: '4',
            display: {
              caption: 'Diet',
              header: 'You have indicated that you do not snack often',
              text:
                'Skipping meals and snacking instead can lead to a slowdown in metabolism and lead to weight gain. Snacks are also often much lower in nutritional content, vitamins and essential minerals than healthy meals. We recommend that you continue to stick to the good habits you already have in this area.',
              icon: {
                type: 'image',
                name: 'diet',
                color: ReportColors.GREEN,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-06',
        source: ReportSources.QUESTIONNAIRE,
        linkID: ['Q8', 'Q9'],
        aggregate: 'SUM',
        rules: [
          {
            variable: 'SLEEP',
            operator: '=',
            value: 2,
            display: {
              caption: 'Sleep',
              header: 'You indicated that you receive adequate sleep.',
              text:
                'Good sleep improves your brain performance, mood, and health. Not getting enough quality sleep regularly can increase the risk of many disorders, ranging from mood problems to heart disease.Things like various health conditions, some medications, sleep disorders, and your personal bedtime routine can all make falling asleep difficult. It seems as if you have good habits in this area at the moment. Keep it up!',
              icon: {
                type: 'image',
                name: 'sleep',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'SLEEP',
            operator: '=',
            value: 1,
            display: {
              caption: 'Sleep',
              header:
                'It seems that it may be taking you longer than average to fall asleep sometimes.',
              text:
                'Good sleep improves your brain performance, mood, and health. Not getting enough quality sleep regularly can increase the risk of many disorders, ranging from mood problems to heart disease.Things like various health conditions, some medications, sleep disorders, and your personal bedtime routine can all make falling asleep difficult. Simple changes like having a consistent bedtime routine and avoiding electronics before bed may be helpful, but if this problem persists, we recommend that you speak to a Clinician.',
              icon: {
                type: 'image',
                name: 'sleep',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'SLEEP',
            operator: '=',
            value: 0,
            display: {
              caption: 'Sleep',
              header:
                'Feeling tired when you wake up may be a sign that you are not getting enough quality sleep',
              text:
                'Feeling tired when you wake up may be a sign that you are not getting enough quality sleep, or may be a sign of an underlying health issue. We recommend speaking with a Clinician about this if simple techniques to improve your sleep routine do not help.',
              icon: {
                type: 'image',
                name: 'sleep',
                color: ReportColors.RED,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-07',
        source: ReportSources.QUESTIONNAIRE,
        linkID: ['Q7', 'Q7.1', 'Q7.2'],
        aggregate: 'SUM',
        rules: [
          {
            variable: 'EXERCISE',
            operator: '=',
            value: 0,
            display: {
              caption: 'Exercise',
              header: 'You would benefit from increasing your exercise',
              text:
                'You would benefit from increasing your exercise to get closer to the recommended 150 minutes of moderate exercise or 75 minutes of vigourus activity weekly. Starting can sometimes be the biggest step. We recommend speaking with a Clinician if you need some advice on how to go about this.',
              icon: {
                type: 'image',
                name: 'exercise',
                color: ReportColors.RED,
              },
            },
          },
          {
            variable: 'EXERCISE',
            operator: '=',
            value: 2,
            display: {
              caption: 'Exercise',
              header: 'You would benefit from increasing your exercise',
              text:
                'You would benefit from increasing your exercise to get closer to the recommended 150 minutes of moderate exercise or 75 minutes of vigourus activity weekly.',
              icon: {
                type: 'image',
                name: 'exercise',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'EXERCISE',
            operator: '>=',
            value: 3,
            display: {
              caption: 'Exercise',
              header:
                'You are currently meeting the recommended exercise guidelines.',
              text: 'Keep up the good work!',
              icon: {
                type: 'image',
                name: 'exercise',
                color: ReportColors.GREEN,
              },
            },
          },
        ],
      },
      {
        id: 'indicator-08',
        source: ReportSources.BINAH,
        linkID: 'bloodPressure',
        rules: [
          {
            variable: 'BLOOD_PRESSURE',
            operator: '<',
            value: [120, 80],
            display: {
              caption: 'Blood Pressure',
              header: 'Your blood pressure is currently in a healthy range.',
              icon: {
                type: 'value',
                separator: '/',
                caption: 'Normal',
                color: ReportColors.GREEN,
              },
            },
          },
          {
            variable: 'BLOOD_PRESSURE',
            operator: '<=',
            value: [139, 89],
            display: {
              caption: 'Blood Pressure',
              header: 'Your blood pressure may be elevated.',
              text:
                'We recommend you speak with a Clinician to discuss next steps.',
              icon: {
                type: 'value',
                separator: '/',
                caption: 'Elevated',
                color: ReportColors.AMBER,
              },
            },
          },
          {
            variable: 'BLOOD_PRESSURE',
            operator: '>',
            value: [139, 89],
            display: {
              caption: 'Blood Pressure',
              header: 'Your reported blood pressure appears very high.',
              text:
                'We recommend you speak with a Clinician urgently to assess this further.',
              icon: {
                type: 'value',
                separator: '/',
                caption: 'High',
                color: ReportColors.RED,
              },
            },
          },
        ],
      },
    ],
    variableMappings: [
      {
        linkID: 'Q1',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'AGE',
          computed: INDICATOR_VARIABLE_COMPUTED_TYPE.AGE,
          mappings: [
            {
              operator: '<',
              answerInteger: 50,
              variable: '<50',
            },
            {
              operator: '>=',
              answerInteger: 50,
              variable: '>50',
            },
          ],
        },
      },
      {
        linkID: 'Q2',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'SMOKER',
          mappings: [
            {
              operator: '=',
              answerString: 'Yes, 1-9 cigarettes daily',
              variable: '4',
            },
            {
              operator: '=',
              answerString: 'Yes, 10-19 cigarettes daily',
              variable: '3',
            },
            {
              operator: '=',
              answerString: 'Yes  over 20 cigarettes daily',
              variable: '2',
            },
            {
              operator: '=',
              answerString: "I used to smoke but don't any more",
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'No, I have never smoked',
              variable: '0',
            },
            {
              operator: '=',
              answerString: 'I smoke occasionally but not every day',
              variable: '5',
            },
          ],
        },
      },
      {
        linkID: 'Q3',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'WEIGHT',
          mappings: [],
        },
      },
      {
        linkID: 'Q4',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'HEIGHT',
          computed: INDICATOR_VARIABLE_COMPUTED_TYPE.HEIGHT_FEET,
          mappings: [],
        },
      },
      {
        linkID: 'BMI',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'BMI',
          mappings: [],
        },
      },
      {
        linkID: 'Q5',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'SEX',
          mappings: [
            {
              operator: '=',
              answerString: 'Male',
              variable: 'M',
            },
            {
              operator: '=',
              answerString: 'Female',
              variable: 'F',
            },
          ],
        },
      },
      {
        linkID: 'Q6',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'ALCOHOL',
          mappings: [
            {
              operator: '=',
              answerString: 'Never',
              variable: '0',
            },
            {
              operator: '=',
              answerString: 'Rarely',
              variable: '0',
            },
            {
              operator: '=',
              answerString: 'Occasionally',
              variable: '0',
            },
            {
              operator: '=',
              answerString: 'Daily',
              variable: '0',
            },
          ],
        },
      },
      {
        linkID: 'Q6.1',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'ALCOHOL',
          mappings: [
            {
              operator: '=',
              answerString: '14 or less',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'More than 14',
              variable: '2',
            },
          ],
        },
      },
      {
        linkID: 'Q6.2',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'ALCOHOL',
          mappings: [
            {
              operator: '=',
              answerString: '7 or less',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'More than 7',
              variable: '2',
            },
          ],
        },
      },
      {
        linkID: 'Q7',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'EXERCISE',
          mappings: [
            {
              operator: '=',
              answerString: 'Yes',
              variable: '0',
            },
            {
              operator: '=',
              answerString: 'No',
              variable: '0',
            },
          ],
        },
      },
      {
        linkID: 'Q7.1',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'EXERCISE',
          mappings: [
            {
              operator: '=',
              answerString: '0 to 150 minutes weekly',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'More than 150 minutes weekly',
              variable: '2',
            },
          ],
        },
      },
      {
        linkID: 'Q7.2',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'EXERCISE',
          mappings: [
            {
              operator: '=',
              answerString: '0 to 75 minutes weekly',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'More than 75 minutes weekly',
              variable: '2',
            },
          ],
        },
      },
      {
        linkID: 'Q8',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'SLEEP',
          mappings: [
            {
              operator: '=',
              answerString: '<30 minutes',
              variable: '1',
            },
            {
              operator: '=',
              answerString: '>30 minutes',
              variable: '0',
            },
          ],
        },
      },
      {
        linkID: 'Q9',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'SLEEP',
          mappings: [
            {
              operator: '=',
              answerString: 'Yes',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'No',
              variable: '0',
            },
          ],
        },
      },
      {
        linkID: 'Q10',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'DIET',
          mappings: [
            {
              operator: '=',
              answerString: 'Yes',
              variable: '1',
            },
            {
              operator: '=',
              answerString: 'No',
              variable: '2',
            },
          ],
        },
      },
      {
        linkID: 'Q11',
        source: ReportSources.QUESTIONNAIRE,
        answerMappings: {
          variableName: 'DIET',
          mappings: [
            {
              operator: '=',
              answerString: 'Yes',
              variable: '3',
            },
            {
              operator: '=',
              answerString: 'No',
              variable: '4',
            },
          ],
        },
      },
    ],
    combinedColors: [{ combine: ['Q10', 'Q11'] }],
  },
}

export default config
