import { babylonUser } from '@babylon/pact-globals' // eslint-disable-line import/no-extraneous-dependencies
import { isE2eMode } from './auth-mode'

export type MockToken = {
  accessToken: string
  userId: number
  userUuid: string
}

const createStubMockToken = () =>
  Promise.resolve({
    accessToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2JhYnlsb25oZWFsdGguY29tL3VzZXIiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAifQ.ncXQfjwO2AlKRuaaIm-2jB8_qfRjnnS6ttFP9cL4txk',
    userId: babylonUser.id,
    userUuid: babylonUser.uuid,
    email: babylonUser.email,
  })

const createE2eMockToken = async () => {
  const result = await fetch(
    'https://services-uk.preprod.babylontech.co.uk/identity/test-fixtures/v1/tags/web-app-e2e/users',
    { method: 'POST' }
  )
  const json = await result.json()

  return {
    accessToken: json.session.access_token,
    userId: json.user.id32,
    userUuid: json.user.id,
    email: json.user.email,
  }
}

const createToken: () => Promise<MockToken> = isE2eMode
  ? createE2eMockToken
  : createStubMockToken

export default createToken
