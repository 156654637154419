import { find, propEq } from 'ramda'

import { RouteId } from './types'

const ROOT_PATH = '/booking-invitation'

// e.g. 123e4567-e89b-12d3-a456-426655440000
const UUID_PATTERN =
  '[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}'

type RouteConfig = {
  id: RouteId
  route: string
  getUrl: (params: { inviteId: string }) => string
}[]

const routeConfig: RouteConfig = [
  // booking flow:
  {
    id: RouteId.BookBegin,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?`,
    getUrl: ({ inviteId }) => `${ROOT_PATH}/book/${inviteId}`,
  },
  {
    id: RouteId.BookSelectMedium,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?/choose-medium`,
    getUrl: ({ inviteId }) => `${ROOT_PATH}/book/${inviteId}/choose-medium`,
  },
  {
    id: RouteId.BookSelectClinicianType,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?/choose-clinician-type`,
    getUrl: ({ inviteId }) =>
      `${ROOT_PATH}/book/${inviteId}/choose-clinician-type`,
  },
  {
    id: RouteId.BookSelectLocation,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?/choose-clinic`,
    getUrl: ({ inviteId }) => `${ROOT_PATH}/book/${inviteId}/choose-clinic`,
  },
  {
    id: RouteId.BookSelectSlot,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?/choose-time`,
    getUrl: ({ inviteId }) => `${ROOT_PATH}/book/${inviteId}/choose-time`,
  },
  {
    id: RouteId.BookReview,
    route: `${ROOT_PATH}/book/:inviteId(${UUID_PATTERN})?/review`,
    getUrl: ({ inviteId }) => `${ROOT_PATH}/book/${inviteId}/review`,
  },
]

const getRouteById = (childId) => find(propEq('id', childId), routeConfig)

const getRouteUrl = (childId, params) => getRouteById(childId).getUrl(params)

export {
  // exported for testing:
  getRouteById,
  // exported for consumption:
  ROOT_PATH,
  getRouteUrl,
  routeConfig,
}
