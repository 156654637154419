import React from 'react'
import {
  HealthReportConfigDisplayContent,
  IndicatorDisplayData,
  ReportColors,
} from '../types'
import getIcon from './icons/indicators'

export const colorMappings = {
  [ReportColors.RED]: '#D7373F',
  [ReportColors.AMBER]: '#f4cb14',
  [ReportColors.GREEN]: '#44B556',
}

export const Indicator = ({
  data,
  display,
}: {
  data: IndicatorDisplayData
  display: HealthReportConfigDisplayContent
}) => {
  const icon =
    display.icon.type === 'image' &&
    display.icon.name &&
    display.icon.color &&
    getIcon(display.icon.name, display.icon.color)

  return (
    <div
      className="tw-grid tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-w-full tw-grid-cols-12 tw-gap-0"
      style={{ minHeight: '90px', margin: '0.92em 0' }}
    >
      <div className="tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-h-full tw-col-span-2 tw-align-middle tw-flex md:tw-col-span-1">
        <span
          className="tw-w-full tw-text-xs tw-font-medium tw-text-center -tw-rotate-90 tw-justify-center tw-text-wrap"
          style={{ backgroundColor: '#f5f5f5' }}
        >
          <p className="tw-tracking-tight" style={{ fontSize: '0.75em' }}>
            {display.caption}
          </p>
        </span>
      </div>
      <div
        className="tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-col-span-2 tw-flex md:tw-col-span-1 lg:tw-w-full lg:tw-h-full tw-size-12"
        style={{ maxHeight: '72px', maxWidth: '74px' }}
      >
        <div
          className="tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-p-1 tw-border tw-border-solid tw-rounded-md tw-flex tw-size-12 lg:tw-w-full lg:tw-h-full md:tw-p-0"
          style={{
            borderColor: colorMappings[display.icon.color],
            color: colorMappings[display.icon.color],
            minHeight: '42px',
            minWidth: '44px',
          }}
        >
          {icon && (
            <img
              alt=""
              width="72"
              height="74"
              className="tw-h-full tw-w-full"
              style={{ padding: '4px' }}
              src={icon}
            />
          )}
          {display.icon.type === 'value' && (
            <div className="tw-flex-col">
              <p className="tw-font-medium tw-text-center tw-text-xs sm:tw-text-sm tw-transform-none lg:tw-scale-125">
                {Array.isArray(data.value)
                  ? data.value.join(display.icon.separator || ' ')
                  : data.value}
              </p>
              <p
                className="tw-text-center tw-text-xs tw-transform-none md:tw-scale-110"
                style={{ fontSize: '0.5em' }}
              >
                {display.icon.caption}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="tw-flex-col tw-justify-center tw-col-span-8 tw-ml-2 tw-flex md:tw-col-span-10 tw-items-left">
        <p className="tw-text-sm tw-font-medium tw-mb-4 tw-text-start">
          {display.header}
        </p>
        {display.text && (
          <p
            className="tw-font-light tw-text-xs tw-text-start"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: display.text }}
          />
        )}
      </div>
    </div>
  )
}
