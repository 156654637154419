import gql from 'graphql-tag'

export default gql`
  query validatePromocode($code: String!, $region_id: Int!) {
    validatePromocode(code: $code, region_id: $region_id) {
      promotion {
        id
        description
        logo
      }
      membership {
        id
        description
      }
    }
  }
`
