import React from 'react'
import { Button, ButtonVariant } from '@babylon/medkit'
import { FormattedMessage } from 'react-intl'
import AddressesList from './AddressesList'
import messages from './Modal.messages'
import { LookupDataList } from '../../types'

interface Props {
  postcodeLookupData: LookupDataList
  onSelectAddress: (address: string) => void
  searchString: string
  onAddManualAddress: (searchString: string) => void
}

const AddressesListState: React.FC<Props> = ({
  postcodeLookupData,
  onSelectAddress,
  searchString,
  onAddManualAddress,
}) => (
  <>
    <AddressesList list={postcodeLookupData} onSelect={onSelectAddress} />
    <Button
      variant={ButtonVariant.secondary}
      fullWidth
      onClick={() => onAddManualAddress(searchString)}
    >
      <FormattedMessage {...messages.modalCtaCantFindMyAddress} />
    </Button>
  </>
)

export default AddressesListState
