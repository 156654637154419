import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Route, Switch, Redirect } from 'react-router-dom'
import { compose } from 'recompose'

import withUserId from '@/wrappers/withUserId'
import withPageTitle from '@/wrappers/withPageTitle'
import UserQuery from '@/queries/Patient'
import Loading from '@/components/Loading'
import messages from './RequestAppointment.messages'
import {
  REQUEST_APPOINTMENT_BOOK_URL,
  REQUEST_APPOINTMENT_SUCCESS_URL,
  REQUEST_APPOINTMENT_DETAILS_URL,
} from './constants'
import {
  RequestAppointmentBook,
  RequestAppointmentDetails,
  RequestAppointmentSuccess,
} from '.'

const enhance = compose(withUserId, withPageTitle(messages.pageTitle))

const RequestAppointment = ({ currentUserId }) => {
  const { loading, data: { patient } = {} } = useQuery(UserQuery, {
    variables: { id: currentUserId },
  })

  if (loading) {
    return <Loading />
  }

  return (
    <section className="Content--form">
      <Switch>
        <Route
          path={REQUEST_APPOINTMENT_DETAILS_URL}
          key="details"
          render={() => <RequestAppointmentDetails patient={patient} />}
        />
        <Route
          path={REQUEST_APPOINTMENT_BOOK_URL}
          key="book"
          render={() => <RequestAppointmentBook />}
        />
        <Route
          path={REQUEST_APPOINTMENT_SUCCESS_URL}
          key="success"
          render={() => <RequestAppointmentSuccess />}
        />
        <Redirect to="/" />
      </Switch>
    </section>
  )
}

export default enhance(RequestAppointment)
