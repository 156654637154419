import React, { Component } from 'react'
import { graphql } from '@apollo/react-hoc'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { logException } from '@babylon/sentry'
import debugLog from '@/utils/debugLog'
import { OPENTOK_API_KEY } from '@/config'
import VideoCallPortal from './components/VideoCallPortal'
import fetchVideoCallInfoByTokenQuery from '@/queries/FetchVideoCallByToken'
import {
  showIncomingCall,
  hideIncomingCall,
  videoCallStarted,
  videoCallEnded,
  videoCallDropped,
} from './actions'
import ringtone from '@/assets/audio/ringtone.mp3'
import styles from './VideoCall.module.scss'

const enhance = compose(
  connect(
    ({ videoCall }) => ({
      videoCall,
    }),
    (dispatch) => ({
      showIncomingCall: () => dispatch(showIncomingCall()),
      hideIncomingCall: () => dispatch(hideIncomingCall()),
      videoCallStarted: () => dispatch(videoCallStarted()),
      videoCallEnded: () => dispatch(videoCallEnded()),
      videoCallDropped: () => dispatch(videoCallDropped()),
    })
  ),
  graphql(fetchVideoCallInfoByTokenQuery, {
    name: 'fetchVideoCallInfoByTokenData',
    options: ({ token }) => ({
      variables: { token },
      context: {
        showFeedback: {
          failure: false,
        },
      },
    }),
  })
)

class VideoCallWithToken extends Component {
  constructor() {
    super()
    this.state = {
      connecting: false,
      audioEnabled: true,
      videoEnabled: true,
      isAccessDialogOpened: false,
      isMediaAccessGiven: null,
      consultant: {},
      callAnswered: false,
    }

    this.session = null
    this.token = null
    this.publisher = null
    this.subscriber = null
    this.stream = null
    this.audioRingtone = new Audio(ringtone)
    this.audioRingtone.loop = true
  }

  isConnected = () => this.session?.currentState === 'connected'

  subscribeToStream = async () => {
    if (
      this.props.fetchVideoCallInfoByTokenData.loading ||
      this.props.fetchVideoCallInfoByTokenData.error
    ) {
      return
    }

    const {
      session: sessionKey,
      unique_token,
      consultant_avatar_url,
      consultant_name,
    } = this.props.fetchVideoCallInfoByTokenData.fetchVideoCallInfoByToken

    const { showIncomingCall } = this.props

    this.token = unique_token

    if (!sessionKey && !unique_token) {
      return
    }

    this.setState({
      consultant: {
        name: consultant_name,
        avatar: consultant_avatar_url,
      },
      connecting: true,
    })

    const opentok = await import(
      /* webpackChunkName: "opentok" */ '@opentok/client'
    )

    this.session = opentok.initSession(OPENTOK_API_KEY, sessionKey)
    this.session.on('streamCreated', (e) => {
      showIncomingCall()

      // Safari doesn't allow autoplay audio files until user accepts permissions the first time.
      // If permission is already given, the browser callback runs in runtime after this part
      // The timeout put this process at the back of the runtime queue solving the callback issue
      setTimeout(() => {
        // The catch is needed because Safari disable the autoplay by default and it throws an error crashing the app
        this.audioRingtone.play().catch((error) => debugLog(error, 'warn'))
      }, 500)

      this.stream = e.stream
    })

    this.session.on('streamDestroyed', () => {
      this.dropSession()
    })

    this.connectSession()
  }

  connectSession = () => {
    this.session.connect(this.token, (error) => {
      if (error) {
        // TODO: show error to user
        logException(error)
      }

      this.setState({ connecting: false })
    })
  }

  answerCall = async () => {
    const { videoCallStarted } = this.props

    this.audioRingtone.pause()
    videoCallStarted()
    this.setState({
      callAnswered: true,
    })

    if (!document.getElementById('consultantVideo')) {
      const consultantVideo = document.createElement('div')
      consultantVideo.id = 'consultantVideo'
      consultantVideo.className = styles.consultantVideo
      document.getElementById('callContainer').appendChild(consultantVideo)
    }

    if (!document.getElementById('patientVideo')) {
      const patientVideo = document.createElement('div')
      patientVideo.id = 'patientVideo'
      patientVideo.className = styles.patientVideo
      document.getElementById('callContainer').appendChild(patientVideo)
    }

    this.subscriber = this.session.subscribe(this.stream, 'consultantVideo', {
      insertMode: 'replace',
      style: {
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
      },
    })

    const opentok = await import(
      /* webpackChunkName: "opentok" */ '@opentok/client'
    )

    this.publisher = opentok.initPublisher('patientVideo', {
      insertMode: 'replace',
      style: {
        archiveStatusDisplayMode: 'off',
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
      },
    })

    this.session.publish(this.publisher)

    this.publisher.on({
      // The Allow/Deny dialog box is opened.
      accessDialogOpened: () => {
        this.setState({ isAccessDialogOpened: true })
      },
      // The user has granted access to the camera and mic.
      accessAllowed: () => {
        this.setState({ isMediaAccessGiven: true })
      },
      // The user has denied access to the camera and mic.
      accessDenied: () => {
        this.setState({ isMediaAccessGiven: false })
      },
    })
  }

  declineCall = () => {
    const { hideIncomingCall } = this.props

    this.audioRingtone.pause()
    this.session.disconnect()
    hideIncomingCall()

    // Connecting again to the session in case user or clinician dropped the call by mistake
    this.connectSession()
  }

  dropSession = () => {
    this.props.videoCallDropped()
    this.session.disconnect()
    this.audioRingtone.pause()

    if (this.publisher) {
      this.session.unpublish(this.publisher)
    }

    if (this.state.callAnswered) {
      this.props.onConsultationEnded()
    }

    this.connectSession()
  }

  hangUp = () => {
    this.dropSession()

    if (this.subscriber) {
      this.session.unsubscribe(this.subscriber)
    }

    // Connecting again to the session in case user or clinician dropped the call by mistake
    this.connectSession()
  }

  toggleVideo = () => {
    this.publisher.publishVideo(!this.state.videoEnabled)
    this.setState({
      videoEnabled: !this.state.videoEnabled,
    })
  }

  toggleAudio = () => {
    this.publisher.publishAudio(!this.state.audioEnabled)
    this.setState({
      audioEnabled: !this.state.audioEnabled,
    })
  }

  handleHangUp = () => {
    this.hangUp()
  }

  render() {
    const {
      loading,
      fetchVideoCallInfoByToken,
    } = this.props.fetchVideoCallInfoByTokenData

    if (!loading && !fetchVideoCallInfoByToken && this.props.onFail) {
      return this.props.onFail()
    }

    if (!this.state.connecting && !this.isConnected() && !this.session) {
      this.subscribeToStream()
    }

    if (!this.isConnected()) {
      return false
    }

    const {
      consultant,
      audioEnabled,
      videoEnabled,
      isAccessDialogOpened,
      isMediaAccessGiven,
    } = this.state

    return (
      <VideoCallPortal
        isAccessDialogOpened={isAccessDialogOpened}
        isMediaAccessGiven={isMediaAccessGiven}
        consultant={consultant}
        answerCall={this.answerCall}
        declineCall={this.declineCall}
        hangUp={this.handleHangUp}
        toggleVideo={this.toggleVideo}
        toggleAudio={this.toggleAudio}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
      />
    )
  }
}

export default enhance(VideoCallWithToken)
