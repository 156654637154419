/* eslint array-callback-return: 0 */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Pharmacy, Delivery, Prescription } from '@babylon/icons'

import noop from 'lodash/noop'

import styles from './DeliveryMethodList.module.scss'

const deliveryMethods = {
  pharmacyDelivery: {
    urlFragment: 'pharmacy-delivery',
    title: 'Collect from pharmacy',
    icon: Pharmacy,
    description: 'Pay at the pharmacy - prices may vary',
    type: 'collection',
  },
  homeDrugDelivery: {
    urlFragment: 'medication-delivery',
    title: 'Delivery',
    icon: Delivery,
    description: 'Pay for your medication and delivery - priced by provider',
    type: 'delivery',
  },
  homeDelivery: {
    urlFragment: 'prescription-delivery',
    title: 'Prescription delivery',
    icon: Prescription,
    description: 'Deliver prescription to me',
    type: 'delivery',
  },
}

const DeliveryMethodList = ({ methods, groupName, onChange }) =>
  methods.map((method) => {
    const resolvedMethod = deliveryMethods[method]

    if (resolvedMethod) {
      return (
        <Fragment key={method}>
          <input
            id={`${groupName}-${method}`}
            name={groupName}
            type="radio"
            className={styles.radio}
            value={resolvedMethod.urlFragment}
            defaultChecked={methods.length === 1}
            onChange={() => onChange(resolvedMethod.title)}
          />
          <label htmlFor={`${groupName}-${method}`} className={styles.label}>
            <div className={styles.icon}>
              <resolvedMethod.icon aria-hidden />
            </div>
            <div>
              <div className={styles.title}>{resolvedMethod.title}</div>
              <div className={styles.description}>
                {resolvedMethod.description}
              </div>
            </div>
          </label>
        </Fragment>
      )
    }

    return null
  })

DeliveryMethodList.propTypes = {
  methods: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(deliveryMethods))),
  groupName: PropTypes.string,
  onChange: PropTypes.func,
}

DeliveryMethodList.defaultProps = {
  methods: [],
  groupName: 'delivery-method',
  onChange: noop,
}

export { deliveryMethods }
export default DeliveryMethodList
