import React, { useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import Spinner from '../Spinner'
import Button from './components/Button'
import ExitView from './components/ExitView'
import RenderQuestion from './components/RenderQuestion'
import RenderInterstitial, {
  isAutoTransitioningInterstitial,
} from './components/RenderInterstitial'
import ErrorView from './components/ErrorView'
import BackLink from './components/BackLink'
import { Answer, Question, Step } from './types'
import styles from './Form.module.scss'
import messages from './messages'
import calculateCompletionPercentageByStep from './utils/calculateCompletionPercentageByStep'
import useForm from './hooks/useForm'
import { useTrackingQuestionnaire } from '../../tracking'
import DirectRegisterPage from '@/components/Authentication/Login/DirectRegisterPage'
import { useCurrentUser } from '@/redux/selectors'
import { PREFILL_EMAIL_KEY, STEP_ELEMENT_ID_PREFIX } from './constants'
import EnhancedSection from '@/programmes/components/EnhancedSection/EnhancedSection'
import { preScreenMarketingEnhancedSchema } from '@/programmes/WeightLoss/steps/preScreenMarketingEnhancedSchema'

// TODO - use simple object with key as id, to prevent find (which loops through the list)
// TODO - use Context to keep track of data, to prevent passing props to sub components (refactor)

interface Props {
  steps: Step[]
  onSubmit: (answers?: { [p: string]: Answer }) => void
  onExit?: () => void // Pass this function to override exit behaviour
  loading?: boolean
  error?: boolean
}

const Form: React.FC<Props> = ({
  steps,
  onSubmit,
  onExit,
  loading,
  error,
}: Props) => {
  const {
    showExitView,
    answers,
    currentStep,
    handleAnswer,
    handleExit,
    handleBeforeExit,
    handleNext,
    handlePrevious,
    showFormError,
    formHistory,
  } = useForm(steps, onSubmit)
  const formatMessage = useFormatMessage()

  useTrackingQuestionnaire(currentStep?.id, error)
  const currentUser = useCurrentUser()
  const isLoggedOut = !currentUser?.id
  const isAfterLoginGate = useMemo(() => {
    const loginGateStepIndex = steps.findIndex(
      (step) => step.type === 'interstitial' && step.loginGate
    )
    const currentStepIndex = steps.findIndex(
      (step) => step.id === currentStep?.id
    )

    if (loginGateStepIndex === -1) {
      return false
    }

    return currentStepIndex > loginGateStepIndex
  }, [currentStep, steps])

  const nextButtonText = useMemo(() => {
    if (currentStep.loginGate && isLoggedOut) {
      return formatMessage(messages.registerAndContinue)
    }

    return currentStep?.nextButtonText ?? formatMessage(messages.next)
  }, [
    currentStep.loginGate,
    currentStep?.nextButtonText,
    formatMessage,
    isLoggedOut,
  ])

  if (isAfterLoginGate && isLoggedOut) {
    const email = sessionStorage.getItem(PREFILL_EMAIL_KEY) || undefined

    return <DirectRegisterPage email={email} weightLoss />
  }

  if (showExitView && onExit) {
    onExit()
  }

  return (
    <form
      className={styles.formContainer}
      onSubmit={(event) => event.preventDefault()}
      noValidate
    >
      {loading && <Spinner colour="#000000" />}

      {showExitView && <ExitView />}

      {!loading && error && (
        <ErrorView
          retry={async () => {
            await onSubmit(answers)
          }}
        />
      )}

      {!showExitView && !error && !loading && (
        <div
          key={currentStep?.id}
          className={styles.form}
          id={`${STEP_ELEMENT_ID_PREFIX}-${currentStep.id}`}
        >
          {formHistory.length > 1 && (
            <BackLink
              onClick={handlePrevious}
              title={formatMessage(messages.previous)}
            />
          )}

          <div className={styles.progressBar}>
            <span
              style={{
                width: `${calculateCompletionPercentageByStep(
                  currentStep,
                  steps
                )}%`,
              }}
            />
          </div>

          {currentStep?.type === 'question' && (
            <RenderQuestion
              question={currentStep as Question}
              answer={answers[currentStep.id]}
              handleAnswer={handleAnswer}
              error={showFormError}
            />
          )}
          {currentStep?.type === 'interstitial' && (
            <RenderInterstitial
              interstitial={currentStep}
              answers={answers}
              handleNext={handleNext}
            />
          )}
          {currentStep.callOut && (
            <div className={styles.callOut}>
              <h6>{currentStep.callOut.title}</h6>
              <span className={styles.callOutBody}>
                {currentStep.callOut.body}
              </span>
            </div>
          )}
        </div>
      )}

      {!isAutoTransitioningInterstitial(currentStep) && (
        <div className={styles.footer}>
          {showExitView && (
            <>
              <Button
                onClick={handleBeforeExit}
                title={formatMessage(messages.review)}
              />
              <Button
                onClick={handleExit}
                type="secondary"
                title={formatMessage(messages.home)}
              />
            </>
          )}

          {!showExitView && !error && !loading && (
            <Button onClick={handleNext} title={nextButtonText} />
          )}
        </div>
      )}

      <EnhancedSection
        currentStepId={currentStep.id}
        schema={preScreenMarketingEnhancedSchema}
      />
    </form>
  )
}

export default Form
