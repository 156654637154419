import calculateBMI from '@/programmes/utils/calculateBMI'
import { RAG, WeightMetricBMI, BMICategory, WeightMetric } from '../types'
import {
  Height,
  MEASUREMENT_UNIT,
  Weight,
} from '@/programmes/components/Form/types'

export const getBMIChangeRAG = (
  current?: WeightMetricBMI,
  previous?: WeightMetricBMI
) => {
  if (!previous || !current) {
    return RAG.NEUTRAL
  }

  if (current.rag === RAG.GREEN && previous.rag === RAG.RED) {
    return RAG.GREEN
  }

  if (current.rag === RAG.GREEN && previous.rag === RAG.GREEN) {
    return RAG.NEUTRAL
  }

  if (current.rag === RAG.RED && current.value < previous.value) {
    return RAG.GREEN
  }

  if (current.rag === RAG.RED && current.value > previous.value) {
    return RAG.RED
  }

  if (current.rag === RAG.RED && current.value === previous.value) {
    return RAG.NEUTRAL
  }

  return undefined
}

export const getBMICategory = (
  bmi: number | undefined
): BMICategory | undefined => {
  if (!bmi) return undefined

  if (bmi < 18.5) return BMICategory.UNDERWEIGHT

  if (bmi >= 18.5 && bmi < 25) return BMICategory.NORMAL

  if (bmi >= 25 && bmi < 30) return BMICategory.OVERWEIGHT

  if (bmi >= 30) return BMICategory.OBESE

  return undefined
}

export const getBMIRag = (bmi: number): RAG => (bmi < 25 ? RAG.GREEN : RAG.RED)

export const getBMIChangeFromKgValues = (
  currentWeightKg: number,
  previousWeightKg: number,
  heightCm: number
): WeightMetric | undefined => {
  const heightObject: Height = {
    unit: MEASUREMENT_UNIT.METRIC,
    cm: `${heightCm}`,
    feet: '',
    inches: '',
  }

  const currentWeightObject: Weight = {
    unit: MEASUREMENT_UNIT.METRIC,
    kg: `${currentWeightKg}`,
    stones: '',
    pounds: '',
  }

  const previousWeightObject: Weight = {
    unit: MEASUREMENT_UNIT.METRIC,
    kg: `${previousWeightKg}`,
    stones: '',
    pounds: '',
  }

  const currentBMIValue: number | undefined = calculateBMI(
    heightObject,
    currentWeightObject
  )

  const previousBMIValue: number | undefined = calculateBMI(
    heightObject,
    previousWeightObject
  )

  if (!currentBMIValue || !previousBMIValue) return undefined

  const currentBMI: WeightMetricBMI = {
    value: currentBMIValue,
    category: getBMICategory(currentBMIValue) || BMICategory.NORMAL,
    rag: currentBMIValue && getBMIRag(currentBMIValue),
  }

  const previousBMI: WeightMetricBMI = {
    value: previousBMIValue,
    category: getBMICategory(previousBMIValue) || BMICategory.NORMAL,
    rag: previousBMIValue && getBMIRag(previousBMIValue),
  }

  return {
    bmi: {
      current: currentBMI,
      previous: previousBMI,
      changeRag: getBMIChangeRAG(currentBMI, previousBMI),
    },
    weight: currentWeightKg,
    weightLoss: currentWeightKg - previousWeightKg,
  }
}
