import {
  AppointmentFilled,
  AppointmentCancelledFilled,
  DoctorFilled,
  PharmacyFilled,
  PrescriptionFilled,
  VoucherFilled,
  StarFilled,
  TestFilled,
  ShieldFilled,
  ChatFilled,
  MembershipFilled,
  HeartFilled,
  BoxFilled,
} from '@babylon/icons'
import notificationTypes from '@babylon/notification-types'

import assignDefinitionToTypes from './assignDefinitionToTypes'
import NOTIFICATION_CATEGORIES from './notificationCategories'
import { repeatPrescriptionNotificationTypes } from './notificationTypeGroups'

const notificationIconAndCategory = {
  ...assignDefinitionToTypes(
    {
      Icon: PharmacyFilled,
      category: NOTIFICATION_CATEGORIES.clinical,
    },
    repeatPrescriptionNotificationTypes
  ),
  [notificationTypes.APPOINTMENT_CREDIT_RECEIVED]: {
    Icon: VoucherFilled,
    category: NOTIFICATION_CATEGORIES.marketing,
  },
  [notificationTypes.APPOINTMENT_INVITE_CREATED]: {
    Icon: AppointmentFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.APPOINTMENT_INVITE_DISMISSED]: {
    Icon: AppointmentCancelledFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.NO_SHOW_APPOINTMENT]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.UPCOMING_APPOINTMENT]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.APPOINTMENT_CHECK_IN_REMINDER]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.RATE_APPOINTMENT]: {
    Icon: StarFilled,
    category: NOTIFICATION_CATEGORIES.rating,
  },
  [notificationTypes.CHATBOT_RESPONSE]: {
    Icon: ChatFilled,
    category: NOTIFICATION_CATEGORIES.system,
  },
  [notificationTypes.EXTENDED_APPOINTMENT_INVITE_CREATED]: {
    Icon: AppointmentFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.ID_VERIFICATION_FAILED]: {
    Icon: ShieldFilled,
    category: NOTIFICATION_CATEGORIES.system,
  },
  [notificationTypes.ID_VERIFICATION_SUCCESS]: {
    Icon: ShieldFilled,
    category: NOTIFICATION_CATEGORIES.system,
  },
  [notificationTypes.MEMBERSHIP_REMINDER]: {
    Icon: MembershipFilled,
    category: NOTIFICATION_CATEGORIES.system,
  },
  [notificationTypes.NEW_PRESCRIPTION]: {
    Icon: PrescriptionFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.PRESCRIPTION_EXPIRY]: {
    Icon: PrescriptionFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.PHARMACY_UNAVAILABLE]: {
    Icon: PharmacyFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.POST_PAYMENT_PENDING]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.REDEMPTION_EXPIRY]: {
    Icon: VoucherFilled,
    category: NOTIFICATION_CATEGORIES.marketing,
  },
  [notificationTypes.REDEMPTION_USED]: {
    Icon: VoucherFilled,
    category: NOTIFICATION_CATEGORIES.marketing,
  },
  [notificationTypes.BLOOD_ANALYSIS_HEALTHY]: {
    Icon: TestFilled,
    category: NOTIFICATION_CATEGORIES.tests,
  },
  [notificationTypes.BLOOD_ANALYSIS_APPOINTMENT]: {
    Icon: TestFilled,
    category: NOTIFICATION_CATEGORIES.tests,
  },
  [notificationTypes.APPOINTMENT_REMINDER]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.APPOINTMENT_REQUIRED_CHECK_IN_MISSED]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.CLINICIAN_LATENESS]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.CLINICIAN_LATENESS_FIRST_MATCHED]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.CLINICIAN_LATENESS_SECOND_MATCHED]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.CLINICIAN_LATENESS_FIRST_NOT_MATCHED]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.CLINICIAN_LATENESS_SECOND_NOT_MATCHED]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.NEW_REFERRAL]: {
    Icon: DoctorFilled,
    category: NOTIFICATION_CATEGORIES.clinical,
  },
  [notificationTypes.TEST_RESULTS_READY]: {
    Icon: BoxFilled,
    category: NOTIFICATION_CATEGORIES.tests,
  },
  DEFAULT: {
    Icon: HeartFilled,
    category: NOTIFICATION_CATEGORIES.system,
  },
}

export default notificationIconAndCategory
