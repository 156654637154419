/* eslint-disable react/prop-types */
import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Link } from '@babylon/medkit'
import styles from './AppsInfo.module.scss'
import messages from './AppsInfo.messages'
import AppIcons from './AppsIcons'

import appointmentImage from './img-phone-appointment.png'

type Props = {
  content?: string
  title?: string
  linkName?: string
  linkDestination?: string
  image?: string
  region?: string
}

const AppsInfo: React.FC<Props> = ({
  content,
  title,
  linkName,
  linkDestination,
  image,
  region = 'GBR',
}) => {
  const translate = useFormatMessage()

  return (
    <div
      className={`ContentBlock ${
        !image ? styles.appsInfoWrapper : styles.configurableAppsInfoWrapper
      }`}
    >
      <div className={styles.leftBlock}>
        <h4>{title || translate(messages.title)}</h4>
        <p className={styles.callToAction}>
          {content || translate(messages.content)}
        </p>

        {linkName && linkDestination && (
          <div className={styles.linkContainer}>
            <Link to={linkDestination} title={linkName}>
              {linkName}
            </Link>
          </div>
        )}
        <div className={styles.iconsWrapper}>
          <AppIcons region={region} />
        </div>
      </div>
      <div className={styles.rightBlock}>
        <img
          className={styles.appImage}
          src={image || appointmentImage}
          alt=""
        />
      </div>
    </div>
  )
}

export default AppsInfo
