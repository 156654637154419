import React from 'react'

import { Avatar, Card } from '@babylon/medkit'
import { notificationIconAndCategory } from './utils'

import styles from './NotificationItem.module.scss'

const NotificationLoading = () => {
  const { Icon } = notificationIconAndCategory.DEFAULT

  return (
    <Card
      interactive
      thumbnail={
        <Avatar FallbackIcon={Icon} highlightIcon description="loading" />
      }
    >
      <div className={styles['item__message--loading']} />
    </Card>
  )
}

export default NotificationLoading
