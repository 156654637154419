export enum AppointmentEventType {
  AppointmentBookingComplete = 'appointment.booking.complete',
  AppointmentBookingCancelled = 'appointment.booking.cancelled',
  ConsultationCallEnded = 'consultation.call.ended',
}

interface Message {
  type: AppointmentEventType
  data: Record<string, any>
}

const SCHEMA = 'EVENT'
const VERSION = '1.0'

/**
 * This hook is used to post messages to the parent window.
 * This is useful when you need to communicate with the parent window from an iframe (partner booking flow, for example)
 * @returns A function that can be used to post messages to the parent window.
 */
const useMessagePoster = ({ disabled }: { disabled: boolean }) => {
  const postMessage = (message: Message) => {
    // If we are not in partner mode, we don't need to post messages to the parent window.
    if (disabled) {
      return
    }

    try {
      const details = {
        schema: SCHEMA,
        version: VERSION,
        ...message,
      }
      const jsonMessage = JSON.stringify(details)
      window.parent.postMessage(jsonMessage, '*')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error posting message:', error)
    }
  }

  return postMessage
}

export default useMessagePoster
