import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import Button from '../Button'
import styles from './ErrorView.module.scss'
import messages from './messages'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'

interface Props {
  retry: () => void
}
const ExitView: React.FC<Props> = ({ retry }) => {
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.container}>
      <div>
        <QuestionTitle content={formatMessage(messages.title)} type="header" />
        <QuestionBody body={formatMessage(messages.body)} />
      </div>

      <Button title={formatMessage(messages.retry)} onClick={retry} />
    </div>
  )
}

export default ExitView
