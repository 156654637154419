import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { Spacer } from '@babylon/ui'
import Template from '../Template'
import messages from '../messages'
import ProductActivationCode from '@/programmes/UrinaryTractInfection/Setup/components/ProductActivationCode'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'
import useOpenProcedure from '@/programmes/hooks/useOpenProcedure'
import Button from '@/programmes/components/Button'

export const patientQuery = gql`
  query Patient {
    patient {
      id
      full_name
      gender
    }
  }
`

const SetupActivation = () => {
  const t = useFormatMessage()
  const { data: patientData, loading: patientQueryLoading } = useQuery(
    patientQuery
  )
  const history = useHistory()

  const { openProcedure, loading: loadingVlabsInvite } = useOpenProcedure(
    UTI_TEST.procedures.setup
  )

  return (
    <Template
      title={t(messages.setupConfirmationPageTitle)}
      steps={UTI_TEST.setupSteps}
      loading={patientQueryLoading}
      activeStep={0}
    >
      <h2>{t(messages.setupActivationPageTitle)}</h2>
      <p>{`${t(messages.setupActivationPageHeading)} ${
        patientData?.patient.full_name
      }`}</p>
      {patientData?.patient.gender !== 'female' ? (
        <>
          <p>
            {t(messages.activationInvalidSexLabel, {
              link: <a href="/clinical-records/0/profile">personal details</a>,
            })}
          </p>
          <Spacer top={3}>
            <Button onClick={() => history.push('/')}>
              {t(messages.activationReturnHomeLabel)}
            </Button>
          </Spacer>
        </>
      ) : (
        <ProductActivationCode
          handleClick={openProcedure}
          activeUserId={patientData?.patient.id}
          loading={loadingVlabsInvite}
        />
      )}
    </Template>
  )
}

export default SetupActivation
