import { Step } from '../../components/Form/types'

const preScreeningSteps: Array<Step> = [
  {
    id: '5c2a5be5-82bd-4c57-a204-6e5d0f159fa4',
    type: 'interstitial',
    title: 'Medical history',
    body:
      'We will now pose a series of questions to assess whether the weight loss medications are a suitable choice for you. These questions will be focused on your medical history.',
    next: '04a3b4a4-ca58-11ee-9152-6f3377fdbff7',
  },
  {
    id: '04a3b4a4-ca58-11ee-9152-6f3377fdbff7',
    type: 'question',
    required: true,
    title: 'Weight and Height?',
    inputType: {
      component: 'heightAndWeight',
    },
    next: '91c7651a-ca58-11ee-a00f-ef21372b1c99',
    dataPoints: [
      {
        type: 'input',
        name: 'height',
      },
      {
        type: 'input',
        name: 'weight',
      },
    ],
  },
  {
    id: '91c7651a-ca58-11ee-a00f-ef21372b1c99',
    type: 'question',
    title: 'Do you have a weight related Illness?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Polycystic ovary syndrome',
        value: 'Polycystic ovary syndrome',
        followUpQuestion: {
          id: '4c029fad-3474-4330-a7d0-6316c1af580c',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Type 2 diabetes',
        value: 'Type 2 diabetes',
        followUpQuestion: {
          id: '510cf067-e3e3-4331-a72e-0d134a97b68d',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'High blood pressure - diagnosed with Hypertension',
        value: 'High blood pressure - diagnosed with Hypertension',
        followUpQuestion: {
          id: '511d16ae-51fe-46b6-ae2b-429f2db41fcd',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Heart disease',
        value: 'Heart disease',
        followUpQuestion: {
          id: '19c58bd0-b42e-49e0-84b9-bb95b2114b6a',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label:
          'Dyslipidemia - Unhealthy levels of one or more lipids (cholesterol, triglycerides)',
        value:
          'Dyslipidemia - Unhealthy levels of one or more lipids (cholesterol, triglycerides)',
        followUpQuestion: {
          id: '0fbf43cb-5ab1-4e21-8234-9fe006be6063',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Asthma / COPD',
        value: 'Asthma / COPD',
        followUpQuestion: {
          id: '8ef7387f-8e7f-4127-ac48-a4b117ce71aa',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Obstructive sleep apnoea',
        value: 'Obstructive sleep apnoea',
        followUpQuestion: {
          id: '5988f753-d088-4a43-a0ab-e13b4cd2adf0',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Another illness that has affected your weight not listed above',
        value: 'Another illness that has affected your weight not listed above',
        followUpQuestion: {
          id: '87cf92a8-101a-4dcb-8489-94b7f84fc0b7',
          type: 'question',
          required: true,
          title: 'Please tell us more?',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [{ label: 'None of the above', nextAction: 'exit-action' }],
    next: 'c2ca0ece-ca58-11ee-a7b8-6729b5205ead',
  },
  {
    id: 'c2ca0ece-ca58-11ee-a7b8-6729b5205ead',
    type: 'question',
    title: 'Confirm if you have any of the following:',
    required: true,
    body: [
      'Type 1 diabetes',
      'Type 2 diabetes on insulin or another GLP1',
      'Diabetic retinopathy',
      'Gastroparesis (delayed gastric emptying)',
      'History of pancreatitis',
      'Personal history of thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia (MEN)',
      'Family history with thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia (MEN) type 2',
      'History of organ transplant or on immunosuppressant (anti-rejection) medication',
      'Active cancer or on chemotherapy',
      'End-stage kidney disease (dialysis)',
      'End-stage liver disease (cirrhosis)',
      'History of eating disorder (anorexia nervosa, bulimia nervosa, binge eating disorder)',
      'History of thoughts of killing one-self (suicidal thoughts)',
      'Currently pregnant',
      'Planning to be pregnant in next 3 months',
      'Breastfeeding',
      'Serious hypersensitivity reaction to semaglutide/tirzepatide or to any of the excipients in Wegovy® or Mounjaro®',
      'Severe congestive heart failure',
    ],
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [{ label: 'yes', nextAction: 'exit-action' }],
    next: '969a9d04-ca59-11ee-b791-aff09851e50c',
  },
  {
    id: '969a9d04-ca59-11ee-b791-aff09851e50c',
    type: 'question',
    required: true,
    title:
      'Are you currently taking Wegovy®, Ozempic® or Mounjaro® for weight loss?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [{ label: 'yes', nextAction: 'exit-action' }],
    next: 'acaff882-ca59-11ee-9db0-2fe4d05657a5',
  },
  {
    id: 'acaff882-ca59-11ee-9db0-2fe4d05657a5',
    type: 'interstitial',
    title: 'Great news! You meet our initial requirements.',
    body:
      'You are now one step closer to starting your weight management journey.',
    next: 'ce9cd1d6-ca59-11ee-bf66-c7b6e56851e7',
    loginGate: true,
  },
  {
    id: 'ce9cd1d6-ca59-11ee-bf66-c7b6e56851e7',
    type: 'question',
    required: true,
    title:
      'Do you take any medications or supplements regularly, either prescribed or over the counter?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: 'f8d0d902-ca59-11ee-85ac-8be810c3d131',
      },
    ],
    next: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
  },
  {
    id: 'f8d0d902-ca59-11ee-85ac-8be810c3d131',
    type: 'question',
    required: true,
    title:
      'Please tell us the name, dose and frequency of your medications or supplements. Please give as much detail as you can, remembering to include both prescription and over the counter products. ',
    body:
      "Don't worry, this is only so we can make sure there won't be any reactions to prescription medications",
    inputType: {
      component: 'textArea',
    },
    next: '1d49424c-ca5a-11ee-933a-a7be183a4c7e',
  },
  {
    id: '1d49424c-ca5a-11ee-933a-a7be183a4c7e',
    type: 'interstitial',
    body:
      'The use of recreational drugs and weight loss medications is not advised as it may lead to potential adverse effects.' +
      'By continuing with our programme you confirm that you understand that you should not take any recreational drugs while using the weight loss medication.',
    next: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
  },
  {
    id: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
    type: 'question',
    required: true,
    title:
      'Do you have any allergies to certain medication (prescribed or over the counter medicine) or foods?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: '42764628-ca5a-11ee-a15e-dff476e93a1f',
      },
    ],
    next: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
  },
  {
    id: '42764628-ca5a-11ee-a15e-dff476e93a1f',
    type: 'question',
    required: true,
    title:
      'What are you allergic to? Please give us as much detail as you can regarding your allergy.',
    inputType: {
      component: 'textArea',
    },
    next: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
  },
  {
    id: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
    type: 'interstitial',
    title: 'Medical History',
    body:
      'The next set of questions will be about your medical history to ensure the treatment is right for you and we are prescribing safely.',
    next: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
  },
  {
    id: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
    type: 'question',
    required: true,
    title: 'Have you ever been diagnosed with type 2 diabetes or pre-diabetes?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: '800e0750-ca5a-11ee-bca7-9fd7a30e85c7',
      },
    ],
    next: 'da8aa852-ca58-11ee-b47b-e76a016b0d52',
  },
  {
    id: '800e0750-ca5a-11ee-bca7-9fd7a30e85c7',
    type: 'question',
    required: true,
    title: 'What’s your most recent HbA1c value?',
    body: "If you do not know your HbA1c value please write 'Not known'",
    inputType: {
      component: 'textArea',
    },
    next: 'da8aa852-ca58-11ee-b47b-e76a016b0d52',
  },
  {
    id: 'da8aa852-ca58-11ee-b47b-e76a016b0d52',
    type: 'question',
    title:
      'Are you aware of or ever been advised that your resting heart rate is above 100 beats per minute?',
    body:
      'Certain weight loss medications can affect your resting heart rate. Understanding how it might impact you is crucial for your health.',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Yes',
        value: 'yes',
        followUpQuestion: {
          id: '01e9c8d4-6bed-4e54-a5e5-f056e57aeafc',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      { label: 'No', value: 'no' },
    ],
    required: true,
    next: '8bcd3c0a-ca5a-11ee-b5e2-cb3ea4fe8836',
  },
  {
    id: '8bcd3c0a-ca5a-11ee-b5e2-cb3ea4fe8836',
    type: 'question',
    required: true,
    title:
      'Do any of the following apply to you? Please read this list carefully.',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label:
          'You sometimes make yourself sick because you feel uncomfortably full',
        value:
          'You sometimes make yourself sick because you feel uncomfortably full',
        followUpQuestion: {
          id: '39da759d-b789-4563-9c60-5fbb26b73c5d',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Kidney disease',
        value: 'Kidney disease',
        followUpQuestion: {
          id: '151aac73-3b41-484c-9914-7793ccc6f005',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Hypertension (high blood pressure)',
        value: 'Hypertension (high blood pressure)',
        followUpQuestion: {
          id: '54969e8c-1b39-403a-b650-5d2fe111ffd7',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Thoughts of suicide or harming yourself',
        value: 'Thoughts of suicide or harming yourself',
        followUpQuestion: {
          id: '710393e1-1b23-4314-80a8-12047abc89e7',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Glaucoma',
        value: 'Glaucoma',
        followUpQuestion: {
          id: '19996700-04ab-4a11-aa53-de040cd22d22',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Current or past cancer, or chemotherapy treatment',
        value: 'Current or past cancer, or chemotherapy treatment',
        followUpQuestion: {
          id: '980f7bb0-5d0e-4f7e-92f4-effb37824dea',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label:
          'Irregular heart beat, raised heart rate (tachycardia) or any other arrhythmia',
        value:
          'Irregular heart beat, raised heart rate (tachycardia) or any other arrhythmia',
        followUpQuestion: {
          id: '02933fc2-4925-481f-bc19-9bed18579379',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Current or past drug or alcohol misuse',
        value: 'Current or past drug or alcohol misuse',
        followUpQuestion: {
          id: '9b8f3d30-3e6e-4705-8479-02c1bd87104b',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Body dysmorphia',
        value: 'Body dysmorphia',
        followUpQuestion: {
          id: '77f1faba-67c3-4d34-a70e-e94d64164f33',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Thoughts of suicide or harming yourself',
        nextAction: 'b362c974-ca5a-11ee-991b-43af1450477a',
      },
    ],
    next: 'a5d21a26-ca5a-11ee-966e-e7e98d40c7b4',
  },
  {
    id: 'b362c974-ca5a-11ee-991b-43af1450477a',
    type: 'interstitial',
    body: [
      "I'm really sorry to hear that you're feeling this way. It's important that you get the support you require as soon as possible. If you feel like you are in immediate danger of suicide please telephone 999. If you have been having thoughts of suicide or considering harming yourself one of our mental health practitioners will be able to offer further support.",
      'Please be aware that there are also many services available for you to access: ',
      'Samaritans: Helpline: (116 123 (free of charge from a landline or mobile) Email jo@samaritans.org) 24 hr helpline offering emotional support for people who are experiencing feelings of distress or despair, including those which may lead to suicide.',
      'The National Suicide Prevention Helpline : 0800 689 5652 - Offers supportive listening services to anyone throughout the UK with thoughts of suicide or thoughts of self-harm. They are open from 6:00pm to 3:30am for those aged 18 or over. Website: www.spuk.org.uk',
      'SupportLine Telephone Helpline: 01708 765200, - Provides emotional support and details of support groups, helplines, agencies and counsellors throughout the UK.',
      'Calm: (0800 585858) Campaign Against Living Miserably Help and support for young men aged 15-35 on issues which include depression and suicide.',
      'HopeLine UK: (0800 068 4141) for practical advice on suicide prevention.',
    ],
    next: 'a5d21a26-ca5a-11ee-966e-e7e98d40c7b4',
  },
  {
    id: 'a5d21a26-ca5a-11ee-966e-e7e98d40c7b4',
    type: 'interstitial',
    title: 'Weight management history',
    body:
      "Your journey is unique, and we're here to support you every step of the way. In order to support you best, we need to know what weight management methods you have already tried.",
    next: 'c36b820c-ca5a-11ee-a1f5-274b99e8516c',
  },
  {
    id: 'c36b820c-ca5a-11ee-a1f5-274b99e8516c',
    type: 'question',
    required: true,
    title:
      'Have you ever attempted any of the following approaches to achieve weight loss?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Use of oral medications like Orlistat, Xenical or Rybelsus',
        value: 'Use of oral medications like Orlistat, Xenical or Rybelsus',
      },
      {
        label:
          'Utilisation of injectable medications such as Ozempic® or Wegovy®',
        value:
          'Utilisation of injectable medications such as Ozempic® or Wegovy®',
        followUpQuestion: {
          id: '41f0a169-07e1-4eb8-b946-eabfac0e087e',
          type: 'question',
          required: true,
          title:
            'I agree that if I am eligible for the weight management program, I must obtain these medications solely through this service.',
          inputType: {
            component: 'checkBox',
          },
        },
      },
      {
        label: 'Bariatric surgery',
        value: 'Bariatric surgery',
        followUpQuestion: {
          id: '6cce1afa-a8c8-4951-b8c5-f60c3d8fce5a',
          type: 'question',
          required: true,
          title: 'Please can you tell us what type of surgery you had?',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Other',
        value: 'other',
        followUpQuestion: {
          id: '16491284-b431-43d4-be54-ed1d11c0d312',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Bariatric surgery',
        nextAction: 'f3399046-ca5a-11ee-b368-c3134ad386f2',
      },
    ],
    next: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
  },
  {
    id: 'f3399046-ca5a-11ee-b368-c3134ad386f2',
    type: 'question',
    body: "Please enter the Month and year. For example: 'January 2019'",
    required: true,
    title: 'When did you have bariatric surgery?',
    inputType: {
      component: 'textInput',
    },
    next: '14418b68-ca5b-11ee-aa28-ef9aea498f7f',
  },
  {
    id: '14418b68-ca5b-11ee-aa28-ef9aea498f7f',
    type: 'question',
    required: true,
    title: 'Are you currently under the care of the bariatric clinic?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    next: '2581e864-ca5b-11ee-b959-f3bf547c15e5',
  },
  {
    id: '2581e864-ca5b-11ee-b959-f3bf547c15e5',
    type: 'interstitial',
    body:
      'If approved for our weight management program, please note that the medication may delay gastric emptying. It is essential to maintain your daily recommended calorie intake to support a balanced diet despite potential changes.',
    title: 'Disclaimer',
    next: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
  },
  {
    id: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
    type: 'interstitial',
    title: 'Lifestyle',
    body:
      "We'd like to learn more about your diet and exercise routine to support you achieve your health goals.",
    next: '332e00e2-ca5b-11ee-b085-4f2ffb229bc3',
  },
  {
    id: '332e00e2-ca5b-11ee-b085-4f2ffb229bc3',
    required: true,
    type: 'question',
    title: 'Can you briefly describe your eating habits or routine?',
    inputType: {
      component: 'textArea',
    },
    next: '41086658-ca5b-11ee-bce4-b7b088b099f5',
  },
  {
    id: '41086658-ca5b-11ee-bce4-b7b088b099f5',
    required: true,
    type: 'question',
    title: 'How many units of alcohol do you drink per week?',
    body: [
      '1 pint of beer or cider (4% strength) - 2.3 units',
      '1 medium (175ml) glass of wine (13% strength) - 2.3 units',
      '1 glass (25ml) of spirits such as gin or vodka (40% strength) - 1 unit',
    ],
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: "I don't drink alcohol", value: "I don't drink alcohol" },
      { label: '1-5 units', value: '1-5 units' },
      { label: '6-9 units', value: '6-9 units' },
      { label: '10-14 units', value: '10-14 units' },
      { label: 'Over 14 units per week', value: 'Over 14 units per week' },
    ],
    next: '4d1cc7cc-ca5b-11ee-80d7-5f0e2a73a0d4',
  },
  {
    id: '4d1cc7cc-ca5b-11ee-80d7-5f0e2a73a0d4',
    required: true,
    type: 'question',
    title: 'Please select your current level of physical activity',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Sedentary (little to no physical activity)',
        value: 'Sedentary (little to no physical activity)',
      },
      {
        label: 'Lightly Active (minimal physical activity, mostly sitting)',
        value: 'Lightly Active (minimal physical activity, mostly sitting)',
      },
      {
        label:
          'Moderately Active (regular physical activity, such as walking or light exercise)',
        value:
          'Moderately Active (regular physical activity, such as walking or light exercise)',
      },
      {
        label:
          'Very Active (frequent and intense physical activity or exercise)',
        value:
          'Very Active (frequent and intense physical activity or exercise)',
      },
    ],
    next: '57b189a2-ca5b-11ee-9986-671abcec6da7',
  },
  {
    id: '57b189a2-ca5b-11ee-9986-671abcec6da7',
    required: true,
    type: 'question',
    title: 'What are the main reasons you want to lose weight?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'I want to be more active',
        value: 'I want to be more active',
      },
      {
        label: 'I want to improve my mood(feel happier or less stressed)',
        value: 'I want to improve my mood(feel happier or less stressed)',
      },
      {
        label: 'I want to sleep better or have more energy',
        value: 'I want to sleep better or have more energy',
      },
      {
        label: 'I want to manage a specifc health condition',
        value: 'I want to manage a specifc health condition',
      },
      {
        label: 'I want to be healthier overall',
        value: 'I want to be healthier overall',
      },
      {
        label: 'I want to look and feel better',
        value: 'I want to look and feel better',
      },
      {
        label: 'Other',
        value: 'other',
        followUpQuestion: {
          id: '41a4465c-d687-4a11-8f00-c9efb535606b',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
    ],
    next: '6355ab08-ca5b-11ee-9af8-ff6ff13f3b9e',
  },
  {
    id: '6355ab08-ca5b-11ee-9af8-ff6ff13f3b9e',
    type: 'question',
    inputType: {
      component: 'confirmation',
    },
    title: 'Before we continue...',
    body:
      'Please confirm that the information you provided is accurate to the best of your knowledge and you agree to information sharing with  your GP',
    callOut: {
      title: 'Why eMed?',
      body:
        'Trusted by over 3 million users, eMed is a market leading digital first healthcare provider. Our team of experienced clinicians is here to support you every step of the way.',
    },
    next: '72e91ad2-ca5b-11ee-887b-3b84e7aa9e81',
    nextButtonText: 'Confirm',
  },
  {
    id: '72e91ad2-ca5b-11ee-887b-3b84e7aa9e81',
    type: 'question',
    required: false,
    title: 'Please tell us anything else you think might be relevant',
    inputType: {
      component: 'textArea',
    },
    next: 'complete-action',
  },
]

export default preScreeningSteps
