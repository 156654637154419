import { defineMessages } from 'react-intl'

export default defineMessages({
  defaultWeightSubtitle: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.defaultWeightSubtitle',
    defaultMessage: "Let's take your weight",
  },
  weightLayoutNoChange: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.noChange',
    defaultMessage: ' No change in weight this week',
  },
  injectionLayoutReadInstructions: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.readInstructions',
    defaultMessage:
      'Make sure to read the patient information leaflet which provides you with an overview of the injection procedure.',
  },
  injectionLayoutDosageLabel: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.dosageLabel',
    defaultMessage: 'Please let us know your current dosage',
  },
  injectionLayoutSelectDosage: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.selectDosage',
    defaultMessage: 'Select dosage',
  },
  injectionLayoutNeedMoreGuidance: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.needMoreGuidance',
    defaultMessage: 'Full instructions',
  },
  injectionLayoutCompletedInjection: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.completedInjection',
    defaultMessage: 'I have completed my injection',
  },
  speakToHealthcareTechnician: {
    id: 'ProgrammeCheckIn.speakToHealthcareTechnician',
    defaultMessage: 'Speak to your healthcare technician',
  },
  injectionLayoutPreviousStep: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.previousStep',
    defaultMessage: 'Previous step',
  },
  weightLayoutPounds: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.pounds',
    defaultMessage: 'Pounds',
  },
  weightLayoutStone: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.stone',
    defaultMessage: 'Stone',
  },
  weightLayoutKg: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.kg',
    defaultMessage: 'Kilograms',
  },
  submit: {
    id: 'ProgrammeCheckIn.submit',
    defaultMessage: 'Submit',
  },
  weightLayoutWeightUp: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.weightUp',
    defaultMessage: 'Your weight is up',
  },
  weightLayoutWeightDown: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.weightDown',
    defaultMessage: 'You have lost',
  },
  weightLayoutWeightDownEnd: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.weightDownEnd',
    defaultMessage: 'since your last check-in',
  },
  weightLayoutGoodProgressAlt: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.goodProgressAlt',
    defaultMessage: 'weight loss good progress',
  },
  weightLayoutSwitchToKg: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.switchToKg',
    defaultMessage: 'Switch to kg',
  },
  weightLayoutSwitchToLbs: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.switchToLbs',
    defaultMessage: 'Switch to st and lbs',
  },
  injectionLayoutNextStep: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.nextStep',
    defaultMessage: 'Next',
  },
  injectionLayoutHavingIssues: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.havingIssues',
    defaultMessage:
      "We're sorry your experiencing issues, please fill out a support form and we will be in touch.",
  },
  injectionLayoutContactSupport: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.contactSupport',
    defaultMessage: 'Contact Support',
  },
  injectionLayoutBackFromSupport: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.backFromSupport',
    defaultMessage: 'Back',
  },
  weightLayoutNextStep: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.nextStep',
    defaultMessage: 'Next',
  },
  weightLayourKeepItUp: {
    id: 'ProgrammeCheckIn.WeightCheckInLayout.keepItUp',
    defaultMessage: "Keep it up, you're doing great",
  },
  injectionLayoutSubmitErrorMessage: {
    id: 'ProgrammeCheckIn.InjectionCheckInLayout.submitErrorMessage',
    defaultMessage:
      'Something went wrong, please try again. If the error persists please contact support',
  },
  programmeErrorMessage: {
    id: 'ProgrammeCheckIn.programmeErrorMessage',
    defaultMessage: 'Error',
  },
})
