import React from 'react'

import { IMedkitComponent } from '..'
import { linkTheme } from './theme'

import { LinkAnchor, LinkButton, LinkReactRouter } from '.'
import { LinkReactRouterProps } from './LinkReactRouter'

export const Link: IMedkitComponent<ILinkProps, typeof linkTheme> = ({
  children,
  onClick,
  Element,
  href,
  to,
  blank,
  ...props
}: ILinkProps) => {
  if (to) {
    return (
      <LinkReactRouter onClick={onClick} to={to} {...props}>
        {children}
      </LinkReactRouter>
    )
  }

  if (onClick) {
    return (
      <LinkButton onClick={onClick} {...props}>
        {children}
      </LinkButton>
    )
  }

  const targetProps =
    blank && href ? { target: '_blank', rel: 'noreferrer noopener' } : undefined

  return (
    <LinkAnchor href={href} {...targetProps} {...props}>
      {children}
    </LinkAnchor>
  )
}

Link.theme = linkTheme

export interface ILinkProps
  extends React.ButtonHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
  children?: React.ReactChild | React.ReactChild[]
  Element?: 'a' | 'button'
  href?: string
  to?: LinkReactRouterProps['to']
  onClick?: () => void
  blank?: boolean
}

export default Link
