export const feedbacks = {
  positive: 'positive',
  negative: 'error',
  warning: 'warning',
  neutral: 'neutral',
  info: 'info',
}

export const WINDOW_FLASH = 'WINDOW_FLASH'
export const RESET_WINDOW = 'RESET_WINDOW'
