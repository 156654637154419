import React from 'react'

import { HelpText } from '../..'

import styles from './Fieldset.module.scss'

type FieldsetProps = {
  children: React.ReactNode
  legend: string
  helpText?: string | JSX.Element
  role: 'group' | 'radiogroup'
}

const Fieldset: React.FC<FieldsetProps> = ({
  children,
  legend,
  helpText,
  role,
}: FieldsetProps) => (
  <fieldset role={role} className={styles.Fieldset}>
    <legend className={styles.FieldsetLegend}>{legend}</legend>
    {helpText && <HelpText>{helpText}</HelpText>}
    {children}
  </fieldset>
)

export default Fieldset
