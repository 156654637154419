import { feedbacks, WINDOW_FLASH, RESET_WINDOW } from './constants'

export const displayFlashError = (text) => ({
  type: WINDOW_FLASH,
  style: feedbacks.negative,
  text,
})

export const displayFlashMessage = (text, feedback) => ({
  type: WINDOW_FLASH,
  style: feedback,
  text,
})

export const resetFlashMessage = () => ({ type: RESET_WINDOW })
