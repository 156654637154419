import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import InputBody from '../InputBody'
import styles from './TextArea.module.scss'
import messages from './messages'

interface TextAreaProps {
  label?: string
  body?: string | Array<string>
  value?: string
  required?: boolean
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  body,
  value = '',
  required = false,
  error = false,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [showError, setShowError] = useState(false)
  const [textareaValue, setTextareaValue] = useState(value)

  useEffect(() => {
    if (textareaValue && showError) {
      setShowError(false)
    }
  }, [textareaValue, showError])

  const onBlur = () => {
    if (required && !textareaValue) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)

    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div key={label} className={styles.containerV1}>
      {label && <label htmlFor={label}>{label}</label>}

      {body && <InputBody body={body} />}

      <textarea
        data-testid={`textarea-${label}`}
        id={label}
        value={textareaValue}
        onBlur={onBlur}
        onChange={handleOnChange}
        className={cn(
          styles.textAreaV1,
          !textareaValue && (showError || error) && styles.errorV1
        )}
      />

      {!textareaValue && (showError || error) && (
        <span className={styles.errorMessageV1}>
          {formatMessage(messages.errorMessage)}
        </span>
      )}
    </div>
  )
}

export default TextArea
