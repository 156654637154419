/**
 * Measure custom events
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 */
import { EventTracker, EventTrackerParams } from './types'

const validate = ({ action, category }: EventTrackerParams): boolean =>
  !!category && !!action

export default (gtag: Gtag.Gtag): EventTracker => (params) => {
  if (!validate(params)) {
    return
  }

  const { action, category, label, value, ...extraVariables } = params

  gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    ...extraVariables,
  })
}
