import React from 'react'
import cn from 'classnames'
import styles from './Checkbox.module.scss'
import CheckboxOnly from './CheckboxOnly'

interface CheckboxProps {
  id: string
  label: string
  value?: string
  error?: boolean
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  value,
  error = false,
  checked = false,
  onChange,
}) => (
  <label
    className={cn(
      styles.container,
      checked && styles.checked,
      error && styles.error
    )}
  >
    <CheckboxOnly
      id={`${label}-${id}`}
      testId={`checkbox-${label}`}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <span className={styles.title}>{label}</span>
  </label>
)

export default Checkbox
