import React, { forwardRef } from 'react'
import styles from './Checkbox.module.scss'

type CheckboxOnlyProps = {
  id?: string
  value?: string
  checked: boolean
  testId?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  tabIndex?: number
}
/* eslint-disable jsx-a11y/no-static-element-interactions */
const CheckboxOnly = forwardRef<HTMLInputElement, CheckboxOnlyProps>(
  ({ id, testId, value, checked, onChange, tabIndex = 0 }, ref) => (
    <span className={styles.checkMarkContainer}>
      <input
        ref={ref}
        type="checkbox"
        tabIndex={tabIndex}
        id={id}
        data-testid={testId}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkmark} />
    </span>
  )
)

export default CheckboxOnly
