export enum ProgrammeCheckInStepLayoutType {
  WEIGHT,
  INJECTION,
}

export enum MedicationCode {
  WEGOVY = 'wegovy',
}

export enum MedicationUnit {
  MG = 'mg',
}

export type LocalisedString = {
  id: string
  defaultMessage: string
}

export type ProgrammeCheckInStep = {
  title: LocalisedString
  subtitle?: LocalisedString
  layoutType: ProgrammeCheckInStepLayoutType
}

export type ProgrammeCheckInConfig = {
  steps: ProgrammeCheckInStep[]
  exitRoute: string
}

export type MedicationRecord = {
  medication_code: MedicationCode
  dose: number
  unit: MedicationUnit
}
