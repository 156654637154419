import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  useWelcomePageBookInMinutesButtonEnabled,
  useWelcomePageBookInMinutesMaxWaitTime,
} from '@/redux/selectors'
import { User, isGPAtHandPreferredCN } from './utils'

export const BOOK_IN_MINUTES_KEY = 'book_in_minutes'
export const NOW_MS = new Date().getTime()

export enum ConsultantType {
  GP = 'GP',
  Nurse = 'Advanced Nurse Practitioner',
  Pharmacist = 'Prescribing Pharmacist',
}

export type AppointmentSlot = {
  time: string
}

export const AppointmentSlotsQuery = gql`
  query appointmentSlots(
    $patientId: Int!
    $consultantType: String
    $startTime: String!
    $endTime: String!
  ) {
    appointmentSlots(
      member_id: $patientId
      consultant_type_name: $consultantType
      start_time: $startTime
      end_time: $endTime
    ) {
      appointment_slots {
        time
      }
    }
  }
`

const getWaitingTime = (appointmentSlots: AppointmentSlot[]) => {
  const orderedSlots = [...appointmentSlots].sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
  )
  const soonestSlot = new Date(orderedSlots[0].time).getTime()
  const now = new Date().getTime()
  const waitingTimeInMinutes = Math.round((soonestSlot - now) / 1000 / 60)

  // show at least 1 minute waiting time
  return waitingTimeInMinutes < 1 ? 1 : waitingTimeInMinutes
}

const cacheWaitingTime = (waitingTime: number) => {
  const twoMinutesInMs = 1000 * 60 * 2
  const data = { waitingTime, cacheTTL: NOW_MS + twoMinutesInMs }

  sessionStorage.setItem(BOOK_IN_MINUTES_KEY, JSON.stringify(data))
}

const useGetConsultantAppointmentSlots = (
  patientId: string,
  consultantType: ConsultantType
) => {
  const maxWaitTimeInMinutes = useWelcomePageBookInMinutesMaxWaitTime() || 180
  const maxWaitTimeInMs = 1000 * 60 * maxWaitTimeInMinutes

  return useLazyQuery(AppointmentSlotsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      patientId,
      consultantType,
      startTime: new Date(NOW_MS).toISOString(),
      endTime: new Date(NOW_MS + maxWaitTimeInMs).toISOString(),
    },
  })
}

const useGetAppointmentSlots = (patientId: string) => {
  const [getNurseSlots, nurseResult] = useGetConsultantAppointmentSlots(
    patientId,
    ConsultantType.Nurse
  )
  const [getGPSlots, gpResult] = useGetConsultantAppointmentSlots(
    patientId,
    ConsultantType.GP
  )
  const [
    getPharmacistSlots,
    pharmacistResult,
  ] = useGetConsultantAppointmentSlots(patientId, ConsultantType.Pharmacist)

  return useCallback(() => {
    if (!nurseResult.called && !gpResult.called && !pharmacistResult.called) {
      getNurseSlots()
      getGPSlots()
      getPharmacistSlots()
    }

    return {
      appointmentSlots: [
        ...(nurseResult.data?.appointmentSlots?.appointment_slots ?? []),
        ...(gpResult.data?.appointmentSlots?.appointment_slots ?? []),
        ...(pharmacistResult.data?.appointmentSlots?.appointment_slots ?? []),
      ],
      loading:
        nurseResult.loading || gpResult.loading || pharmacistResult.loading,
    }
  }, [
    getNurseSlots,
    getGPSlots,
    getPharmacistSlots,
    nurseResult,
    gpResult,
    pharmacistResult,
  ])
}

export const useBookInMinutes = (user: User) => {
  const isBookInMinutesButtonEnabled = useWelcomePageBookInMinutesButtonEnabled()
  const getAppointmentSlots = useGetAppointmentSlots(user?.id)

  if (
    !isBookInMinutesButtonEnabled ||
    isGPAtHandPreferredCN(user) ||
    !user?.id
  ) {
    return { showBookInMinutes: false, waitingTime: undefined, loading: false }
  }

  const { waitingTime, cacheTTL } = JSON.parse(
    sessionStorage.getItem(BOOK_IN_MINUTES_KEY) || '{}'
  )

  if (waitingTime && cacheTTL > NOW_MS) {
    return { showBookInMinutes: true, waitingTime, loading: false }
  }

  const { appointmentSlots, loading } = getAppointmentSlots()

  if (loading || appointmentSlots?.length === 0) {
    return { showBookInMinutes: false, waitingTime: undefined, loading }
  }

  const newWaitingTime = getWaitingTime(appointmentSlots)
  cacheWaitingTime(newWaitingTime)

  return {
    showBookInMinutes: appointmentSlots.length > 0,
    waitingTime: newWaitingTime,
    loading,
  }
}
