import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames/bind'
import { graphql, withApollo } from '@apollo/react-hoc'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Question } from '@babylon/icons'
import intlShape from '@/utils/intlShape'
import User from '@/queries/Patient'
import UnreadNotificationQuery from '@/queries/UnreadNotifications'
import { resetFormErrors } from '@/redux/formErrors/actions'
import { isDesktop } from '@/utils'
import { withUserId, withFeatureFlags } from '@/wrappers'
import Avatar from '../Avatar'
import Logo from '../Logo'
import Link from '../Link'
import Navigation from './Navigation'
import UserMenu from './UserMenu'
import { excludedNotificationTypesFilters } from '@/config/constants'
import generalMessages from '@/App.messages'
import { selectBookAppointmentsEnabled, selectLogo } from '@/redux/selectors'

import messages from './Header.messages'
import styles from './Header.module.scss'
import navStyles from './Navigation/Navigation.module.scss'
import HelpMenu from './HelpMenu/index'
import navMessages from './Navigation/Navigation.messages'

const cx = classNames.bind(styles)

const enhance = compose(
  withApollo,
  injectIntl,
  withRouter,
  withUserId,
  withFeatureFlags,
  graphql(User, {
    options: ({ userId }) => ({
      variables: { id: userId },
    }),
  }),
  graphql(UnreadNotificationQuery, {
    name: 'unreadNotifications',
    options: ({ userId }) => ({
      ...(!window.Cypress && { pollInterval: 60000 }), // 60 seconds
      variables: {
        id: userId,
        excludeFilters: excludedNotificationTypesFilters,
      },
    }),
    props: ({ unreadNotifications: { loading, unreadNotifications } }) => ({
      hasUnreadNotifications: !loading && (unreadNotifications || {}).count > 0,
    }),
  }),
  connect(
    (state) => ({
      logo: selectLogo(state),
      isBookAppointmentsEnabled: selectBookAppointmentsEnabled(state),
    }),
    (dispatch) => bindActionCreators({ resetFormErrors }, dispatch)
  )
)

const manageScrollView = (mobileNavOpened) => {
  const appWrapper = document.getElementsByTagName('body')[0]
  mobileNavOpened
    ? appWrapper.classList.add('no-scroll')
    : appWrapper.classList.remove('no-scroll')
}

class Header extends Component {
  constructor() {
    super()
    this.state = {
      mobileNavOpened: false,
      showAccountMenu: false,
      showHelpMenu: false,
    }
    this.timeout = null
  }

  componentDidMount() {
    manageScrollView(this.state.mobileNavOpened)
  }

  onAccountClick = () => {
    this.onMouseEnter()

    // On desktop devices menu changes behaviour
    if (isDesktop()) {
      this.setState({
        showAccountMenu: !this.state.showAccountMenu,
        showHelpMenu: false,
      })
    } else {
      const accountPath = this.props.pageUserId
        ? `/account/${this.props.pageUserId}`
        : '/account'
      this.props.history.push(accountPath)
      this.setState({ mobileNavOpened: false }, () =>
        manageScrollView(this.state.mobileNavOpened)
      )
    }
  }

  onHelpClick = () => {
    this.onHelpMouseEnter()

    // On desktop devices menu changes behaviour
    if (isDesktop()) {
      this.setState((prevState) => ({
        ...prevState,
        showHelpMenu: !prevState.showHelpMenu,
        showAccountMenu: false,
      }))
    }
  }

  onMouseEnter = () => {
    clearTimeout(this.timeout)
  }

  onMouseLeave = () =>
    (this.timeout = setTimeout(
      () =>
        this.setState({
          showAccountMenu: false,
        }),
      700
    ))

  onHelpMouseEnter = () => {
    clearTimeout(this.timeout)
  }

  onHelpMouseLeave = () => {
    this.timeout = setTimeout(
      () =>
        this.setState({
          showHelpMenu: false,
        }),
      700
    )
  }

  onMobileNavClick = () => {
    const { mobileNavOpened } = this.state

    if (!isDesktop()) {
      this.togglemobileNavOpened(mobileNavOpened)
    }
  }

  hideAccountMenu = () => {
    this.setState({ showAccountMenu: false })
  }

  hideHelpMenu = () => {
    this.setState({ showHelpMenu: false })
  }

  togglemobileNavOpened = (mobileNavOpened) => {
    this.setState({ mobileNavOpened: !mobileNavOpened }, () =>
      manageScrollView(this.state.mobileNavOpened)
    )
  }

  /* TO DO: [Refactor] [CW-1332] Reduce complexity to align with new linting rules */
  /* eslint-disable complexity */
  render() {
    const { mobileNavOpened, showAccountMenu, showHelpMenu } = this.state
    const {
      data: {
        patient: { first_name, last_name, avatar_full_url } = {},
        loading,
      },
      intl = {},
      hasUnreadNotifications,
      pageUserId,
      logo,
      featureFlags,
      isBookAppointmentsEnabled,
    } = this.props

    // TODO: Consider extending and using MenuDropdown component
    // (created initially to support breadcrumb toggle menus)
    // to provide keyboard friendly interaction for the two menu toggles here
    return (
      <>
        <header className={styles.header}>
          <section className={styles.header__wrapper}>
            {/* Logo */}
            <div className={styles.header__logo}>
              <Link
                href="/"
                data-testid="headerLogo"
                title={intl.formatMessage(generalMessages.ctaHome)}
                className={styles.header__logo__wrapper}
              >
                <Logo
                  name={logo}
                  className={cx(
                    styles.header__logo__image,
                    styles[`${logo}-logo`]
                  )}
                  data-testid="babylonLogo"
                />
              </Link>
            </div>

            {/* Main Menu Trigger */}
            <button
              data-testid="navigationBurger"
              id="Burger"
              title={intl.formatMessage(
                messages[`mainMenu${mobileNavOpened ? 'Hide' : 'Show'}`]
              )}
              className={cx(mobileNavOpened ? 'opened' : null, styles.burger)}
              onClick={() => this.togglemobileNavOpened(mobileNavOpened)}
              aria-expanded={mobileNavOpened}
              disabled={featureFlags.prototype_triage_booking}
              type="button"
            >
              <span />
              <span />
              <span />
              <span />
            </button>

            {/* Main Menu */}
            <Navigation
              className={styles.headerNavigation}
              showBookingLink={isBookAppointmentsEnabled}
              mobileNavOpened={mobileNavOpened}
              onMobileNavClick={this.onMobileNavClick}
              unreadNotifications={hasUnreadNotifications}
            />

            {/* Help Menu Trigger */}
            <button
              tabIndex={0}
              className={styles.header__help}
              onClick={this.onHelpClick}
              onMouseEnter={this.onHelpMouseEnter}
              onMouseLeave={this.onHelpMouseLeave}
              aria-expanded={isDesktop() && showHelpMenu}
              disabled={featureFlags.prototype_triage_booking}
              type="button"
            >
              <div className={styles.header__help_item}>
                <Question className={navStyles.navigation__icon} aria-hidden />
                {intl.formatMessage(navMessages.help)}
              </div>
            </button>
            {/* User Menu Trigger */}
            <button
              data-testid="navigationAccount"
              id="accountButton"
              title={
                isDesktop()
                  ? intl.formatMessage(
                      messages[`userMenu${showAccountMenu ? 'Hide' : 'Show'}`]
                    )
                  : intl.formatMessage(messages.ctaAccount)
              }
              tabIndex={0}
              className={styles.header__account}
              onClick={this.onAccountClick}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              aria-expanded={isDesktop() && showAccountMenu}
              disabled={featureFlags.prototype_triage_booking}
              type="button"
            >
              <Avatar
                src={avatar_full_url}
                desc={first_name}
                size="xs"
                useInitials={
                  !loading && { firstName: first_name, lastName: last_name }
                }
              />
            </button>

            {/* User Menu */}
            {showAccountMenu && (
              <div
                id="userMenu" // for aria reference
                className={styles.headerUserMenu}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                data-testid="navigationUserMenu"
              >
                <UserMenu
                  intl={intl}
                  hideAccountMenu={this.hideAccountMenu}
                  userId={pageUserId}
                />
              </div>
            )}
            {showHelpMenu && (
              <div
                id="helpMenu" // for aria reference
                className={styles.headerHelpMenu}
                onMouseEnter={this.onHelpMouseEnter}
                onMouseLeave={this.onHelpMouseLeave}
                data-testid="navigationUserMenu"
              >
                <HelpMenu intl={intl} hideAccountMenu={this.hideHelpMenu} />
              </div>
            )}
          </section>
        </header>
        {/*<AnnouncementBanner />*/}
      </>
    )
  }
  /* eslint-enable */
}

Header.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape({}).isRequired,
}

export default enhance(Header)
