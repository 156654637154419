import { useQuery } from '@apollo/react-hooks'

import PrivacySettingsQuery from './queries'

const usePrivacySettings = (userId: string) =>
  useQuery(PrivacySettingsQuery, {
    variables: { id: userId },
  })

const usePrivacySettingsEnabled = (userId: string): boolean => {
  const { loading, data } = usePrivacySettings(userId)

  if (loading) {
    return false
  }

  const notices = data.patient.privacySettings
  return Array.isArray(notices) && notices.length > 0
}

export { usePrivacySettingsEnabled }
export default usePrivacySettings
