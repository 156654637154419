import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import InputBody from '../InputBody'
import styles from './TextInput.module.scss'
import messages from './messages'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface TextInputProps {
  id?: string
  label?: string
  type?: 'text' | 'number'
  body?: string | Array<string> | React.ReactNode
  error?: boolean
  errorMessage?: string
  required?: boolean
  value?: string
  min?: number | string
  max?: number | string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  body,
  id,
  type = 'text',
  error = false,
  errorMessage,
  required = false,
  value,
  min,
  max,
  placeholder,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [showError, setShowError] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)

    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    error && setShowError(error)
  }, [error, errorMessage])

  useEffect(() => {
    if (inputValue && showError) {
      setShowError(error)
    }
  }, [inputValue, error, showError])

  const onBlur = () => {
    if (required && !inputValue) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  return (
    <div className={cn(styles.containerV1, showError && styles.errorV1)}>
      <label
        htmlFor={label}
        id={id ? `${STEP_ELEMENT_ID_PREFIX}-label-${id}` : undefined}
      >
        {label}
      </label>

      {body && <InputBody body={body} id={id} />}

      <input
        type={type}
        onBlur={onBlur}
        id={label}
        min={min}
        max={max}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        name={label}
        aria-invalid={showError}
        aria-describedby={`${label}ErrorHint`}
      />

      {showError && (
        <span id={`${label}ErrorHint`} className={styles.errorMessageV1}>
          {errorMessage ?? formatMessage(messages.errorMessage)}
        </span>
      )}
    </div>
  )
}

export default TextInput
