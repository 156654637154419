import React from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { Link } from '@babylon/medkit'
import { useDispatch, useSelector } from 'react-redux'
import Template from '../Template'
import messages from '../messages'
import Button from '@/programmes/components/Button'
import styles from '../index.module.scss'
import { checkoutRoutes, setupRoutes } from '@/programmes/BloodTest/routes'
import { UK_WELLNESS_BLOOD_TEST_PRODUCT_ID } from '@/config'
import checkoutSteps from './steps'
import useGetCheckoutLink from '@/programmes/hooks/useGetCheckoutLink'
import { login as loginAction } from '@/components/Authentication/actions'

const cx = classNames.bind(styles)

const CheckoutHome = () => {
  const t = useFormatMessage()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const dispatch = useDispatch()
  const { checkOutMutation, loading } = useGetCheckoutLink({
    productList: [{ id: UK_WELLNESS_BLOOD_TEST_PRODUCT_ID, quantity: 1 }],
    successPath: checkoutRoutes.confirmation,
    cancelPath: setupRoutes.home,
  })

  const handleClick = () => {
    if (isAuthenticated) {
      return checkOutMutation()
    }

    return dispatch(loginAction())
  }

  return (
    <Template
      title={t(messages.checkoutPageTitle)}
      steps={checkoutSteps}
      activeStep={0}
      hasAppLayout={isAuthenticated}
    >
      <h2 className={cx('heading')}>{t(messages.checkoutHeading)}</h2>
      <Spacer top={2}>
        <p className={cx('description')}>{t(messages.checkoutDescription1)}</p>
        <p className={cx('description')}>{t(messages.checkoutDescription2)}</p>
      </Spacer>
      <Spacer top={2} bottom={2}>
        <div className={cx('product-box')}>
          <div className={cx('info')}>
            <div className={cx('title')}>
              {t(messages.checkoutPageProductTitle)}
            </div>
            <div className={cx('subtitle')}>
              {t(messages.checkoutPageProductDescription)}
            </div>
            <div className={cx('price')}>
              {t(messages.checkoutPageProductPrice)}
            </div>
          </div>
        </div>
      </Spacer>
      <Spacer top={2} bottom={2}>
        <Button onClick={handleClick} loading={loading}>
          {t(messages.checkoutPageTitleCta)}
        </Button>
      </Spacer>
      <Link className={cx('small-text')} id="subtitle" href="/terms" blank>
        {t(messages.checkoutPageTermsAndConditions)}
      </Link>
    </Template>
  )
}

export default CheckoutHome
