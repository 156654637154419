import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'MaintenanceSplashScreen.pageTitle',
    defaultMessage: 'Under maintenance',
  },
  contentTitle: {
    id: 'MaintenanceSplashScreen.contentTitle',
    defaultMessage: 'eMed is currently under maintenance',
  },
  contentMessage: {
    id: 'MaintenanceSplashScreen.contentMessage',
    defaultMessage:
      'This page will be back in a few hours. Thanks for your patience.',
  },
  stethoscope: {
    id: 'MaintenanceSplashScreen.stethoscope',
    defaultMessage: 'Stethoscope icon',
  },
})
