import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCurrentUser } from '@/redux/selectors'

export const dateOfBirthQuery = gql`
  query Patient {
    patient {
      date_of_birth
    }
  }
`

function isNotAgedBetween18and75(
  birthdate: string,
  lowerAgeLimit: number,
  upperAgeLimit: number
): boolean {
  const birthDate = new Date(birthdate)
  const currentDate = new Date()

  const age = currentDate.getFullYear() - birthDate.getFullYear()

  if (age > lowerAgeLimit && age < upperAgeLimit) {
    return false
  }

  if (age < lowerAgeLimit || age > upperAgeLimit) {
    return true
  }

  if (age === lowerAgeLimit) {
    // In this case, the user is either 18 years old or they will be soon. We need to check the month and day.
    return (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    )
  }

  // In this case, the user is either 75 years old or they will be soon. We need to check the month and day.
  return (
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() > birthDate.getDate())
  )
}

const useIsOutOfTheAgeRangeCheck = () => {
  const isLoggedOut = !useCurrentUser()?.id
  const { data, loading } = useQuery(dateOfBirthQuery, { skip: isLoggedOut })
  const [outOfTheAgeRange, setOutOfTheAgeRange] = useState<boolean>(false)

  useEffect(() => {
    if (data && !loading) {
      setOutOfTheAgeRange(
        isNotAgedBetween18and75(data.patient?.date_of_birth, 18, 75)
      )
    }
  }, [data, loading])

  return {
    outOfTheAgeRange,
    loading,
  }
}

export default useIsOutOfTheAgeRangeCheck
