import React, { FC } from 'react'

import { Helmet } from 'react-helmet'

import { useAppTitle } from '@/redux/selectors'

export interface PageTitleProps {
  title?: string
  prefix?: string
  suffix?: string
  separator?: string
}

const PageTitle: FC<PageTitleProps> = ({
  title,
  prefix,
  suffix,
  separator = ' - ',
}: PageTitleProps) => {
  const appTitle = useAppTitle()
  const titleParts: string[] = []

  if (prefix) {
    titleParts.push(prefix)
  }

  if (title) {
    titleParts.push(title)
  }

  if (suffix) {
    titleParts.push(suffix)
  }

  if (!prefix && !suffix) {
    titleParts.push(appTitle)
  }

  const titleToUse = titleParts.join(separator)

  return (
    <Helmet>
      <title>{titleToUse}</title>
    </Helmet>
  )
}

export default PageTitle
