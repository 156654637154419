const observeStore = (store, select, onChange) => {
  let currentState

  const handleChange = () => {
    const state = store.getState()
    const nextState = select ? select(state) : state

    if (nextState !== currentState) {
      currentState = nextState
      onChange(currentState)
    }
  }

  const unsubscribe = store.subscribe(handleChange)
  handleChange()

  return unsubscribe
}

export { observeStore }
