import React from 'react'
import classNames from 'classnames/bind'
import { Card } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import { ProgrammeCheckInConfig, ProgrammeCheckInStepLayoutType } from './types'
import WeightCheckInLayout from './ProgrammeCheckInLayouts/WeightCheckInLayout/WeightCheckInLayout'
import styles from './index.module.scss'
import messages from './messages'
import InjectionLayout from './ProgrammeCheckInLayouts/InjectionLayout'

const cx = classNames.bind(styles)
type Props = {
  config: ProgrammeCheckInConfig
}

const ProgrammeCheckIn: React.FC<Props> = ({ config }) => {
  const history = useHistory()
  const t = useFormatMessage()
  let [currentStep, setCurrentStep] = React.useState(0)

  const getCurrentStepView = (step) => {
    const currentStepConfig = config.steps[step]
    const goToNextStep = () => {
      if (currentStep === config.steps.length - 1) {
        history.push(config.exitRoute)
      } else {
        setCurrentStep(currentStep + 1)
      }
    }

    if (
      currentStepConfig.layoutType === ProgrammeCheckInStepLayoutType.WEIGHT
    ) {
      return (
        <WeightCheckInLayout
          config={currentStepConfig}
          onStepComplete={goToNextStep}
        />
      )
    }

    if (
      currentStepConfig.layoutType === ProgrammeCheckInStepLayoutType.INJECTION
    ) {
      return (
        <InjectionLayout
          config={currentStepConfig}
          onStepComplete={goToNextStep}
        />
      )
    }

    return <div>{t(messages.programmeErrorMessage)}</div>
  }

  return (
    <Card>
      <div className={cx('check-in-card')}>
        {getCurrentStepView(currentStep)}
      </div>
    </Card>
  )
}

export default ProgrammeCheckIn
