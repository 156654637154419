import React, { ChangeEvent } from 'react'
import { isEmpty } from 'lodash'
import { Button, Link, ButtonVariant } from '@babylon/medkit'
import { Input } from '@babylon/ui'
import { FormattedMessage } from 'react-intl'
import FormattedFullAddress from './FormattedFullAddress'
import styles from '../Address.module.scss'
import messages from './PostcodeForm.messages'
import formMessages from '../../Form/Form.messages'
import { NewAddressFormat, Errors } from '../types'

interface Props {
  postcode: string
  address: NewAddressFormat
  searchPostcode: (postcode: string) => void
  onOpenModal: () => void
  changePostcodeUrl?: string
  disablePostcodeInput?: boolean
  showPostcodeInput?: boolean
  showManualAddressForm: boolean
  hasFullAddress: boolean
  errors: Errors
}

interface ButtonCopy {
  postcode: string
  address: NewAddressFormat
  showManualAddressForm: boolean
}

// The button has a different text based on the scenario
const getButtonCopy = ({
  postcode,
  address,
  showManualAddressForm,
}: ButtonCopy) => {
  if (showManualAddressForm) {
    return <FormattedMessage {...messages.postcodeFormButtonFindAddress} />
  }

  if (address) {
    return <FormattedMessage {...messages.postcodeFormButtonChangeAddress} />
  }

  if (postcode) {
    return <FormattedMessage {...messages.postcodeFormButtonSelectAddress} />
  }

  return <FormattedMessage {...messages.postcodeFormButtonAddAddress} />
}

const PostcodeForm: React.FC<Props> = ({
  postcode,
  disablePostcodeInput,
  showPostcodeInput,
  searchPostcode,
  address,
  showManualAddressForm,
  onOpenModal,
  errors,
  changePostcodeUrl,
  hasFullAddress,
}) => {
  const btnText = getButtonCopy({
    postcode,
    address,
    showManualAddressForm,
  })
  const showAddressRequiredError = !hasFullAddress && !isEmpty(errors)

  return (
    <>
      {showPostcodeInput && (
        <Input
          name="address_post_code"
          className={styles.addressPicker__input}
          label={<FormattedMessage {...formMessages.postcodeLabel} />}
          value={postcode}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            searchPostcode(event.target.value)
          }
          errors={errors.address_post_code || errors.postcode}
          disabled={disablePostcodeInput}
          data-testid="postcode-form-input"
        />
      )}
      {showPostcodeInput && disablePostcodeInput && changePostcodeUrl && (
        <Link
          className={styles.addressPicker__changeLink}
          to={changePostcodeUrl}
          data-testid="postcode-form-change-link"
        >
          <FormattedMessage {...messages.postcodeFormChangeLink} />
        </Link>
      )}
      {address && !showManualAddressForm && (
        <FormattedFullAddress address={address} />
      )}
      <Button
        variant={ButtonVariant.secondary}
        className={styles.addressPicker__mainButton}
        onClick={onOpenModal}
        data-testid="postcodeSubmit"
      >
        {btnText}
      </Button>
      {showAddressRequiredError && (
        <span className="error-feedback">
          <FormattedMessage {...messages.postcodeFormAddressRequired} />
        </span>
      )}
    </>
  )
}

export default PostcodeForm
