import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Input } from '@babylon/ui'
import styles from './Modal.module.scss'
import messages from '../../../Form/Form.messages'

interface Props {
  onSubmit: (event: ChangeEvent<HTMLFormElement>) => void
  searchString?: string
  onChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void
  disableSearch: boolean
  postcodeError: string[]
}

const SearchInput: React.FC<Props> = ({
  onSubmit,
  searchString,
  onChangeSearch,
  disableSearch,
  postcodeError,
}) => (
  <form
    id="searchForm"
    className={styles.addressPicker__modal__form}
    onSubmit={onSubmit}
  >
    <div>
      <Input
        type="search"
        className={styles.addressPicker__modal__input}
        label={<FormattedMessage {...messages.postcodeLabel} />}
        value={searchString}
        onChange={onChangeSearch}
        disabled={disableSearch}
        errors={postcodeError}
        data-testid="addresses-picker-modal-postcode"
      />
    </div>
  </form>
)

export default SearchInput
