import React, { FC, RefObject } from 'react'
import { Tick } from '@babylon/icons'
import cn from 'classnames'

import styles from './Step.module.scss'

const Step: FC<IProps> = ({
  label,
  index,
  active,
  complete,
  activeRef,
  preventInaccessibleFocus,
}: IProps) => (
  <li
    className={cn(styles.Step, {
      [styles.Step_highlight]: active || complete,
      [styles.Step_active]: active,
    })}
    aria-current={active ? 'step' : undefined}
    ref={active ? activeRef : undefined}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={preventInaccessibleFocus ? undefined : 0}
  >
    <span className={styles.Step__circle}>
      {complete ? <Tick className={styles.Step__icon} /> : index + 1}
    </span>
    <span className={styles.Step__label}>{label}</span>
  </li>
)

interface IProps extends React.HTMLProps<HTMLDivElement> {
  label: string
  index: number
  active: boolean
  complete: boolean
  activeRef: RefObject<HTMLLIElement>
  /** It's generally considered inaccessible to focus on a non interactive element.
   *  I'm unsure why the original authors made these steps tab focusable so let's just put in a flag to disable it.
   */
  preventInaccessibleFocus?: boolean
}

export default Step
