import gql from 'graphql-tag'

const PrivacyNoticeFragment = gql`
  fragment privacy_notice on PrivacyNotice {
    accepted
    noticeVersionId
    version
    noticeId
    title
    summary
    longText
    acceptButtonText
    declineButtonText
    readMoreButtonText
    popupTitle
    popupText
    updateTitle
    updateText
    onboardingText
    settingsText
    linkText
    errorTitle
    errorText
    mandatory
    noticeType
  }
`

export { PrivacyNoticeFragment }
