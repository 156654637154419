import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'ErrorPage.title',
    defaultMessage: 'Oops, something went wrong!',
  },
  message1: {
    id: 'ErrorPage.message1',
    defaultMessage:
      'We’ve found an error and our developers have been notified.',
  },
  message2: {
    id: 'ErrorPage.message2',
    defaultMessage: 'Try reloading the page or return to the homepage.',
  },
  extra: {
    id: 'ErrorPage.extra',
    defaultMessage:
      'Try reloading the page or accessing the page in a different browser. Alternatively you can download the eMed app on your phone to access our services immediately.',
  },
  action: {
    id: 'ErrorPage.action',
    defaultMessage: 'Go to Home',
  },
  expiredSessionTitle: {
    id: 'ExpiredSessionErrorPage.title',
    defaultMessage: 'Your session has expired.',
  },
})
