import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'FeedbackPage.pageTitle',
    defaultMessage: 'Tell us what you think!',
  },
  submissionSuccess: {
    id: 'FeedbackPage.submissionSuccess',
    defaultMessage:
      'Thanks for your feedback, your response has been recorded.',
  },
  description: {
    id: 'FeedbackPage.description',
    defaultMessage:
      'We’re keen to learn more about your experience using the eMed Website. Please don’t share any personal medical information in the form below. All responses are anonymous.',
  },
  ratingField: {
    id: 'FeedbackPage.ratingField',
    defaultMessage: 'How satisfied are you with your experience?',
  },
  infoField: {
    id: 'FeedbackPage.infoField',
    defaultMessage: 'Tell us about your experience',
  },
  required: {
    id: 'FeedbackPage.required',
    defaultMessage: 'This field is required',
  },
  ratingOptions: {
    id: 'FeedbackPage.ratingOptions',
    defaultMessage:
      '{rating, select, 1 {1 (Very Unsatisfied)} 5 {5 (Very Satisfied)} other {{rating}}}',
  },
  submitButton: {
    id: 'FeedbackPage.submitButton',
    defaultMessage: 'Submit Feedback',
  },
})
