import React from 'react'

import { IMedkitComponent, Text } from '..'
import Link from '../Link'
import { paginationTheme } from './theme'
import styles from './index.module.scss'

const Pagination: IMedkitComponent<Props, typeof paginationTheme> = ({
  currentPage = 1,
  pageTotal,
  title,
  paginationLabel,
  nextLink,
  nextButtonText,
  nextButtonAccessibleText,
  previousLink,
  previousButtonText,
  previousButtonAccessibleText,
}) => {
  const isBackDisabled = currentPage <= 1
  const isNextDisabled = pageTotal ? currentPage >= pageTotal : false

  return (
    <nav aria-label={title} className={styles.pagination}>
      {previousLink ? (
        <Link
          data-testid="pagination-back"
          disabled={isBackDisabled}
          aria-disabled={isBackDisabled}
          to={previousLink}
          aria-label={previousButtonAccessibleText}
        >
          {previousButtonText}
        </Link>
      ) : (
        <Text
          className={styles.previousText}
          data-testid="pagination-back"
          variant="caption"
          aria-label={previousButtonAccessibleText}
        >
          {previousButtonText}
        </Text>
      )}

      <Text
        data-testid="pagination-current-page"
        variant="caption"
        className={styles.currentPage}
        aria-current="true"
      >
        {paginationLabel || currentPage}
      </Text>

      {nextLink ? (
        <Link
          data-testid="pagination-next"
          disabled={isNextDisabled}
          aria-disabled={isNextDisabled}
          to={nextLink}
          aria-label={nextButtonAccessibleText}
        >
          {nextButtonText}
        </Link>
      ) : (
        <Text
          className={styles.nextText}
          data-testid="pagination-next"
          variant="caption"
          aria-label={nextButtonAccessibleText}
        >
          {nextButtonText}
        </Text>
      )}
    </nav>
  )
}

Pagination.theme = paginationTheme

export interface Props {
  currentPage?: number
  pageTotal?: number
  title?: string
  paginationLabel?: string
  nextLink?: string
  nextButtonText: string
  nextButtonAccessibleText?: string
  previousLink?: string
  previousButtonText: string
  previousButtonAccessibleText?: string
}

export default Pagination
