import {
  TRACKING_PREFERENCE,
  TRACKING_SCRIPTS_LOADED,
  MARKETING_TAGS_ADDED,
} from './actionTypes'
import {
  hasAcceptedNonEssentialTracking,
  shouldDisplayCookieNotice,
} from './persistence'
import { MarketingTag } from './marketing-tags/types'

interface InitialState {
  nonEssentialTracking: boolean
  nonEssentialTrackingLoaded: boolean
  displayCookieNotice: boolean
  marketingTags: MarketingTag[]
}

const initialState: InitialState = {
  nonEssentialTracking: hasAcceptedNonEssentialTracking(),
  nonEssentialTrackingLoaded: false,
  displayCookieNotice: shouldDisplayCookieNotice(),
  marketingTags: [],
}

const addMarketingTags = (tags: MarketingTag[], newTags: MarketingTag[]) => {
  let marketingTags = [...tags]

  for (const tag of newTags) {
    const existingTag = tags.find(
      ({ type, id }) => tag.id === id && tag.type === type
    )

    if (!existingTag) {
      marketingTags.push(tag)
    }
  }

  return marketingTags
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case MARKETING_TAGS_ADDED:
      return {
        ...state,
        marketingTags: addMarketingTags(state.marketingTags, action.tags),
      }
    case TRACKING_PREFERENCE:
      return {
        ...state,
        trackingDecisionMade: true,
        nonEssentialTracking: action.accepted,
        displayCookieNotice: false,
      }
    case TRACKING_SCRIPTS_LOADED:
      return {
        ...state,
        marketingTags: [],
        nonEssentialTrackingLoaded: true,
      }
    default:
      return state
  }
}
