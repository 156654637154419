import gql from 'graphql-tag'

export default gql`
  mutation AddObservation(
    $datapoint: MyhealthHealthStatusAddMetricDatapointDatapoint!
    $appName: String!
  ) {
    _myhealth_healthStatusAddMetricDatapoint(
      datapoint: $datapoint
      appName: $appName
    )
  }
`
