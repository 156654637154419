import { useCallback, useEffect } from 'react'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import { useTrackingPageRedirect } from '@/programmes/tracking'
import { Procedure } from '@/programmes/types'

const useOpenProcedure = (procedureIdentifier: Procedure) => {
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const trackingPageRedirect = useTrackingPageRedirect()

  const proctorInviteUrl = data?.createProctorSessionInvite?.url

  useEffect(() => {
    if (proctorInviteUrl) {
      trackingPageRedirect()
      window.location.href = proctorInviteUrl
    }
  }, [proctorInviteUrl, trackingPageRedirect])

  const openProcedure = useCallback(async () => {
    await getProctorSessionInvite({
      variables: {
        input: {
          procedure: {
            identifier: { value: procedureIdentifier },
          },
        },
      },
    })
  }, [getProctorSessionInvite, procedureIdentifier])

  return {
    openProcedure,
    loading,
  }
}

export default useOpenProcedure
