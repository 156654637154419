import React from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import AppsInfo from '@/components/AppsInfo'
import BABYLON_US_APP from '@/assets/images/babylon_us_ios_app.png'
import Template from '../Template'
import checkInSteps from './steps'
import messages from '../messages'
import styles from '../index.module.scss'

const cx = classNames.bind(styles)

const CheckInConfirmationPage = () => {
  const t = useFormatMessage()

  return (
    <Template
      title={t(messages.confirmationPageTitle)}
      steps={checkInSteps}
      activeStep={2}
    >
      <h2 className={cx('heading')}>{t(messages.confirmationPageHeading)}</h2>
      <p className={cx('description')}>
        {t(messages.confirmationPageDescription)}
      </p>
      <AppsInfo
        title={t(messages.confirmationPageAppInfoHeading)}
        content={t(messages.confirmationPageAppInfoDescription)}
        image={BABYLON_US_APP}
      />
    </Template>
  )
}

export default CheckInConfirmationPage
