import { RESET_PRODUCT_CONFIG, UPDATE_PRODUCT_CONFIG } from './constants'

const resetProductConfig = () => ({
  type: RESET_PRODUCT_CONFIG,
})

const updateProductConfig = (payload) => ({
  type: UPDATE_PRODUCT_CONFIG,
  payload,
})

export { resetProductConfig, updateProductConfig }
