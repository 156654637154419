import React, { useEffect } from 'react'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames/bind'
import loadable from '@loadable/component'
import PrivateRoute from '@/components/Authentication'
import PartnerLandingPage from '@/components/PartnerLandingPage'
import { PARTNERS_HOME_PATH } from '@/components/Appointments/constants'
import {
  useBookAppointmentEnabled,
  useRemoteConfigLoaded,
} from '@/redux/selectors'
import ExpiredSessionErrorPage from '@/views/ErrorPage/ExpiredSessionErrorPage'

import styles from '@/App.module.scss'

import ThemedLayout from './ThemedLayout'
import {
  appointments as getAppointmentsConfig,
  partnerClinicalRecords,
} from './config'

const cx = classNames.bind(styles)

const ComponentWrapper = ({ Component, layout = {}, props }) => (
  <div
    className={cx(styles.view__inner, {
      'view__inner--vFlush': layout.vFlush,
      'view__inner--hFlush': layout.hFlush,
    })}
  >
    <Component {...props} />
  </div>
)

const RouteGroup = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ './RouteGroup')
)

/**
 *
 * @template T
 * @param {React.Component<T>} Component
 * @returns {React.FC<T>}
 */
const withThemedLayout = (Component, layout) => (props) => (
  <ThemedLayout>
    <ComponentWrapper
      {...{
        Component,
        layout,
        props,
      }}
    />
  </ThemedLayout>
)

const getPartnerRedirectUri = (pathname) => {
  if (pathname && pathname.includes('/partners/')) {
    return `/partners${pathname.split('partners')[1]}`
  }
  return undefined
}

const PartnerRoutes = () => {
  const isBookingAppointmentsEnabled = useBookAppointmentEnabled()
  const location = useLocation()
  const redirectUri = getPartnerRedirectUri(location.state?.previousPath)
  const navStateForPartnerFlow = { previousPath: location.pathname }
  const history = useHistory()
  const remoteConfigLoaded = useRemoteConfigLoaded()

  useEffect(() => {
    if (remoteConfigLoaded && redirectUri) {
      history.replace(redirectUri)
    }
  }, [remoteConfigLoaded, history, redirectUri])

  return (
    <Switch>
      <PrivateRoute
        key="partner-root-fallback-route"
        exact
        path={PARTNERS_HOME_PATH}
        fallbackComponent={ExpiredSessionErrorPage}
        component={withThemedLayout(PartnerLandingPage, {
          vFlush: !remoteConfigLoaded,
        })}
      />
      {remoteConfigLoaded && (
        <PrivateRoute
          key="partners-clinical-records-route"
          path={partnerClinicalRecords.route}
          component={withThemedLayout(() => (
            <RouteGroup config={partnerClinicalRecords} />
          ))}
          fallbackComponent={ExpiredSessionErrorPage}
        />
      )}
      {/* Do not load partner routes until product config is ready or default booking steps/paths will be used */}
      {remoteConfigLoaded && isBookingAppointmentsEnabled && (
        <PrivateRoute
          key="book-partners-consultation-route"
          path={getAppointmentsConfig('partners').route}
          fallbackComponent={ExpiredSessionErrorPage}
          component={withThemedLayout(() => (
            <RouteGroup config={getAppointmentsConfig('partners')} />
          ))}
        />
      )}
      {!remoteConfigLoaded && (
        <Redirect
          to={{ pathname: PARTNERS_HOME_PATH, state: navStateForPartnerFlow }}
        />
      )}
    </Switch>
  )
}

export { default as Status } from './Status'
export default PartnerRoutes
