import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Height, MEASUREMENT_UNIT } from '../../types'
import TextInput from '../TextInput'
import Button from '../Button'
import InputBody from '../InputBody'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import {
  HEIGHT_VALID_RANGE_IMPERIAL,
  HEIGHT_VALID_RANGE_METRIC,
} from '../../constants'

interface Props {
  title?: string
  body?: string | Array<string>
  value?: Height
  handleChange: (height: Height) => void
  error?: boolean
}

const HeightMeasurement: React.FC<Props> = ({
  title,
  body,
  handleChange,
  value,
  error,
}) => {
  const formatMessage = useFormatMessage()
  const [height, setHeight] = useState<Height>(
    value || {
      unit: MEASUREMENT_UNIT.IMPERIAL,
      cm: '',
      feet: '',
      inches: '',
    }
  )

  return (
    <div className={styles.measurementContainerV1}>
      <div
        className={title ? styles.headerVerticalV1 : styles.headerHorizontalV1}
      >
        <div className={styles.titleContainerV1}>
          <span className={styles.titleV1}>
            {title || formatMessage(messages.height)}
          </span>
          {body && <InputBody body={body} />}
        </div>
        <div className={styles.buttonWrapperV1}>
          <Button
            type="secondary"
            onClick={() => {
              const newHeight = {
                ...height,
                unit:
                  height.unit === MEASUREMENT_UNIT.IMPERIAL
                    ? MEASUREMENT_UNIT.METRIC
                    : MEASUREMENT_UNIT.IMPERIAL,
              }
              setHeight(newHeight)
              handleChange(newHeight)
            }}
            title={
              height.unit === MEASUREMENT_UNIT.IMPERIAL
                ? formatMessage(messages.switchToCm)
                : formatMessage(messages.switchToftin)
            }
          />
        </div>
      </div>

      <div className={styles.inputContainerV1}>
        {height.unit === MEASUREMENT_UNIT.IMPERIAL ? (
          <>
            <div
              key={formatMessage(messages.feet)}
              className={styles.textInputV1}
            >
              <TextInput
                label={formatMessage(messages.feet)}
                type="number"
                onChange={(event) => {
                  const newHeight = { ...height, feet: event.target.value }
                  setHeight(newHeight)
                  handleChange(newHeight)
                }}
                value={height.feet}
              />
            </div>

            <div className={styles.textInputV1}>
              <TextInput
                label={formatMessage(messages.inches)}
                type="number"
                onChange={(event) => {
                  const newHeight = { ...height, inches: event.target.value }
                  setHeight(newHeight)
                  handleChange(newHeight)
                }}
                value={height.inches}
              />
            </div>
          </>
        ) : (
          <div
            key={formatMessage(messages.centimetres)}
            className={styles.textInputV1}
          >
            <TextInput
              label={formatMessage(messages.centimetres)}
              type="number"
              onChange={(event) => {
                const newHeight = { ...height, cm: event.target.value }
                setHeight(newHeight)
                handleChange(newHeight)
              }}
              value={height.cm}
            />
          </div>
        )}
      </div>
      {error && (
        <span className={styles.errorMessageV1}>
          {height?.unit === MEASUREMENT_UNIT.IMPERIAL
            ? formatMessage(messages.heightImperialErrorMessage, {
                min: HEIGHT_VALID_RANGE_IMPERIAL.min,
                max: HEIGHT_VALID_RANGE_IMPERIAL.max,
              })
            : formatMessage(messages.heightMetricErrorMessage, {
                min: HEIGHT_VALID_RANGE_METRIC.min,
                max: HEIGHT_VALID_RANGE_METRIC.max,
              })}
        </span>
      )}
    </div>
  )
}

export default HeightMeasurement
