import React from 'react'
import { Text } from '@babylon/medkit'
import styles from './Avatar.module.scss'

export interface AvatarProps {
  imageSrc: string
  alt: string
  title?: string
  name?: string
}

const Avatar: React.FC<AvatarProps> = ({ imageSrc, alt, name, title }) => (
  <div className={styles.wrapper}>
    <img src={imageSrc} alt={alt} className={styles.image} />
    {name && (
      <Text className={styles.name} variant="h3">
        {name}
      </Text>
    )}
    {title && (
      <Text className={styles.title} variant="smallBody">
        {title}
      </Text>
    )}
  </div>
)

export default Avatar
