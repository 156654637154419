import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-common'
import { Provider, ReactReduxContext } from 'react-redux'

import { initSentry } from '@babylon/sentry'
import { ConnectedRouter } from 'connected-react-router'
import { loadableReady } from '@loadable/component'

import App from './App'
import Theme from './theme'
import ErrorBoundary from './components/ErrorBoundary'
import ErrorPage from './views/ErrorPage'
import { init as initApolloClient, getClient } from './config/apollo-client'
import { reduxState, initStore } from './redux'
import config from './config'
import { IntlProvider } from './localisation'
import AuthProvider from './AuthProvider'

import '@babylon/fonts/Poppins'
import { selectDefaultLanguage } from './redux/selectors'

/**
As part of the service now integration, we call a login url that we want to filter
parts of in the sentry breadcrumb to prevent sensitive information from being sent to sentry
**/

const filterServiceNowTokenFromBreadcrumb = (breadCrumb: any) => {
  if (
    breadCrumb.category === 'fetch' &&
    breadCrumb.data?.url.includes('SM_USER')
  ) {
    const uuid = breadCrumb.data.url.split('SM_USER')[1].split('&DE_USER')[0]

    const newBreadcrumb = {
      ...breadCrumb,
      data: {
        ...breadCrumb.data,
        url: `${breadCrumb.data.url.split('.do?')[0]}?SM_USER${uuid}*REDACTED`,
      },
    }

    return newBreadcrumb
  }

  return undefined
}

if (config.SENTRY_URL) {
  initSentry({
    dsn: config.SENTRY_URL,
    environment: config.SENTRY_ENVIRONMENT,
    release: config.VERSION,
    beforeBreadcrumb(breadCrumb) {
      const newBreadcrumb = filterServiceNowTokenFromBreadcrumb(breadCrumb)

      return newBreadcrumb ?? breadCrumb
    },
  })
}

if (!config.TRACKING_DISABLED) {
  import('./tracking').then((tracking) => {
    tracking.init(reduxState.store)
  })
}

if (config.MARKETING_TAGS_ENABLED) {
  import('./tracking/marketing-tags').then((tracking) => {
    tracking.init({ store: reduxState.store })
  })
}

const getDefaultLanguage = () =>
  selectDefaultLanguage(reduxState.store.getState())

initStore()
initApolloClient({ store: reduxState.store })

const client = getClient()

const Root = () => (
  <Provider store={reduxState.store} context={ReactReduxContext}>
    <ApolloProvider client={client}>
      <IntlProvider defaultLocale={getDefaultLanguage()}>
        <Theme isRoot>
          <AuthProvider>
            <ErrorBoundary Component={ErrorPage}>
              <ConnectedRouter
                history={reduxState.history}
                context={ReactReduxContext}
              >
                <App />
              </ConnectedRouter>
            </ErrorBoundary>
          </AuthProvider>
        </Theme>
      </IntlProvider>
    </ApolloProvider>
  </Provider>
)

if (module.hot) {
  ReactDOM.render(<Root />, document.getElementById('root'))
} else {
  loadableReady(() => {
    ReactDOM.hydrate(<Root />, document.getElementById('root'))
  })
}
