const debugLog = (args, type = 'log') => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  const logFn = console[type] || console.log // eslint-disable-line no-console

  if (Array.isArray(args)) {
    logFn(...args)
  } else {
    logFn(args)
  }
}

export default debugLog
