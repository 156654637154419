import {
  RESET_STORE,
  PRIVACY_AGREEMENT_NEEDED,
  UPDATE_APOLLO_REQUEST_COUNT,
} from './constants'

export const resetStore = () => ({ type: RESET_STORE })
export const setPrivacyAgreementNeeded = (value) => ({
  type: PRIVACY_AGREEMENT_NEEDED,
  value,
})
export const updateApolloRequestCount = () => ({
  type: UPDATE_APOLLO_REQUEST_COUNT,
})
