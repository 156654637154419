import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { useWeightLossSetupConfirmationInfo } from '@/redux/selectors'
import FemaleDoctorImage from '@/assets/images/weight-loss_female-doctor.jpg'
import GetYourMedicationImage from '@/assets/images/weight-loss_get-your-medication.jpg'
import LoseWeightImage from '@/assets/images/weight-loss_lose-weight.jpg'
import Card from '@/programmes/components/Card'
import messages from '../messages'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import Button from '@/programmes/components/Button'
import styles from '../index.module.scss'
import { onboardingSteps } from './steps'

const cx = classNames.bind(styles)

const SetupConfirmationPage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const announcement = useWeightLossSetupConfirmationInfo()

  return (
    <Template
      title={t(messages.setupConfirmationPageTitle)}
      steps={onboardingSteps}
      activeStep={3}
    >
      <h2 className={cx('heading')}>
        {t(messages.setupConfirmationPageHeading)}
      </h2>
      <p className={cx('description')}>
        {t(messages.setupConfirmationPageDescription)}
      </p>
      {announcement && <Announcement content={announcement} />}
      <div className={cx('cards')}>
        <Card
          title={t(messages.setupConfirmationPageMedicalCardTitle)}
          description={t(messages.setupConfirmationPageMedicalCardDescription)}
          image={FemaleDoctorImage}
          imageDescription={t(
            messages.setupConfirmationPageMedicalCardImageDescription
          )}
        />
        <Card
          title={t(messages.setupConfirmationPageMedicationCardTitle)}
          description={t(
            messages.setupConfirmationPageMedicationCardDescription
          )}
          image={GetYourMedicationImage}
          imageDescription={t(
            messages.setupConfirmationPageMedicationCardImageDescription
          )}
        />
        <Card
          title={t(messages.setupConfirmationPageWeightCardTitle)}
          description={t(messages.setupConfirmationPageWeightCardDescription)}
          image={LoseWeightImage}
          imageDescription={t(
            messages.setupConfirmationPageWeightCardImageDescription
          )}
        />
      </div>
      <Spacer top={2}>
        <Button
          onClick={() => {
            history.push('/')
          }}
        >
          {t(messages.setupConfirmationPageCta)}
        </Button>
      </Spacer>
    </Template>
  )
}

export default SetupConfirmationPage
