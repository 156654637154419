import React from 'react'
import { Box, Text } from '@babylon/medkit'
import { AlertsAlert } from '@babylon/icons'
import style from './MessageBox.module.scss'

interface MessageBoxProps {
  children: React.ReactNode
  title: string
}

const MessageBox = ({ children, title }: MessageBoxProps) => (
  <Box className={style.wrapper}>
    <Box className={style.header}>
      <AlertsAlert className={style.icon} />
      <span>{title}</span>
    </Box>
    <Text variant="body">{children}</Text>
  </Box>
)

export default MessageBox
