import wegovyImage from '@/assets/images/wegovy-img.png'
import wegovyIcon from '@/assets/images/wegovy-icon.png'
import mounjaroImage from '@/assets/images/mounjaro-img.png'
import mounjaroIcon from '@/assets/images/mounjaro-icon.png'
import {
  LITE_PRODUCT_TYPE,
  MOUNJARO_LITE_LOOKUP_KEY,
  MOUNJARO_PRODUCT_ID,
  MOUNJARO_ULTRA_LOOKUP_KEY,
  ULTRA_PRODUCT_TYPE,
  WEGOVY_LITE_LOOKUP_KEY,
  WEGOVY_PRODUCT_ID,
  WEGOVY_ULTRA_LOOKUP_KEY,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/const'
import {
  Feature,
  ProductGroups,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import { ProductType } from '@/programmes/components/Products/models'

export const productGroups: ProductGroups = {
  wegovy: {
    id: WEGOVY_PRODUCT_ID,
    label: 'Wegovy',
    content:
      'Lose up to 15% of weight with Wegovy. Single action medication which works on GLP-1 hormone.',
    badge: false,
    img: wegovyImage,
    icon: wegovyIcon,
    features: [
      {
        title: 'Wegovy medication',
        description:
          'The price includes a four week supply of 0.25mg Wegovy injections.',
        lite: true,
        ultra: true,
      },
    ],
    products: {
      [LITE_PRODUCT_TYPE]: {
        id: WEGOVY_LITE_LOOKUP_KEY,
        name: 'Wegovy Weight Loss',
        productTier: LITE_PRODUCT_TYPE,
        productType: ProductType.Wegovy,
        discount: '',
        priceWithDiscount: '',
        price: '',
        badge: false,
        addons: [],
      },
      [ULTRA_PRODUCT_TYPE]: {
        id: WEGOVY_ULTRA_LOOKUP_KEY,
        name: 'Wegovy Weight Loss+',
        productTier: ULTRA_PRODUCT_TYPE,
        productType: ProductType.Wegovy,
        discount: '',
        priceWithDiscount: '',
        price: '',
        badge: true,
        addons: [],
      },
    },
  },
  mounjaro: {
    id: MOUNJARO_PRODUCT_ID,
    label: 'Mounjaro',
    content:
      'Lose up to 20% of weight with Mounjaro. Dual action medication which works on GLP-1 and GIP hormones.',
    additionalConsideration:
      'Additional considerations if you take oral contraceptives below**.',
    badge: true,
    img: mounjaroImage,
    icon: mounjaroIcon,
    features: [
      {
        title: 'Mounjaro medication',
        description:
          'The price includes a four week supply of 2.5mg Mounjaro injections.',
        lite: true,
        ultra: true,
      },
    ],
    products: {
      [LITE_PRODUCT_TYPE]: {
        id: MOUNJARO_LITE_LOOKUP_KEY,
        name: 'Mounjaro Weight Loss',
        productTier: LITE_PRODUCT_TYPE,
        productType: ProductType.Mounjaro,
        discount: '',
        priceWithDiscount: '',
        price: '',
        badge: false,
        addons: [],
      },
      [ULTRA_PRODUCT_TYPE]: {
        id: MOUNJARO_ULTRA_LOOKUP_KEY,
        name: 'Mounjaro Weight Loss+',
        productTier: ULTRA_PRODUCT_TYPE,
        productType: ProductType.Mounjaro,
        discount: '',
        priceWithDiscount: '',
        price: '',
        badge: true,
        addons: [],
      },
    },
  },
}

export const commonFeatures: Feature[] = [
  {
    title: 'Free next day delivery',
    description:
      'Your medication delivered directly to your chosen address, in safe temperature controlled packaging. Order by 4pm, Mon-Fri for next working day delivery. (Weekend orders will be delivered the following Tuesday.)',
    lite: false,
    ultra: true,
  },
  {
    title: 'Weight management specialist',
    description:
      "Personalised weight management support as and when you need it. We ensure you're on the correct dosage and provide guidance through email and digital consultations when needed.",
    lite: true,
    ultra: true,
  },
  {
    title: 'Weekly progress report',
    description:
      'A weekly personalised progress report that tracks your weight loss against your goal.',
    lite: true,
    ultra: true,
  },
  {
    title: '10 week education pack',
    description:
      'Clinically proven up-to-date advice on how to make a sustainable difference to your weight. We send you weekly instructions, worksheets and exercises - all you need to know to beat any barriers that are currently stopping you.',
    lite: true,
    ultra: true,
  },
  {
    title: 'On-demand healthcare technician check-ins',
    description:
      'Live guided weigh-in and injection support every week. On-demand access. No scheduling required.',
    lite: false,
    ultra: true,
  },
  {
    title: 'Mental health support',
    description:
      'Online access to our mental health practitioners, who will provide psychological support and advice to help you build strategies to resolve any possible underlying weight management issues such as stress, sleep, and anxiety.',
    lite: false,
    ultra: true,
  },
  {
    title: 'Physiotherapists',
    description:
      'Physical change can incur stresses and strains on the body. Our physiotherapists are on hand to provide you with tailored online programmes to keep you on track.',
    lite: false,
    ultra: true,
  },
  {
    title: 'On-demand digital GP services',
    description:
      "Full access to eMed's premium digital GP services. Our GPs are available 24/7 to support with all your health needs.",
    lite: false,
    ultra: true,
  },
]
