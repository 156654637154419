function getSelectOptions(options, inputValue) {
  const showCreatableOption = shouldShowCreatableOption(options, inputValue)

  return showCreatableOption
    ? [
        {
          label: 'Add',
          value: 'Add',
          __creatable: true,
        },
        ...options,
      ]
    : options
}

function shouldShowCreatableOption(options, inputValue) {
  const matchingOptions = options.filter(
    (item) => item.label.toLowerCase() === inputValue.trim().toLowerCase()
  )

  return inputValue && !matchingOptions.length
}

function getCreatableItemProps(item, inputValue) {
  if (item.__creatable) {
    return {
      item: {
        ...item,
        label: `${inputValue}`,
        value: `${inputValue}`,
      },
    }
  }

  return undefined
}

export { getSelectOptions, getCreatableItemProps }
