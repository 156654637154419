import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'Home.pageTitle',
    defaultMessage: 'How can I help?',
  },
  bookAppointmentWithTriage: {
    id: 'Home.bookAppointmentWithTriage',
    defaultMessage: 'Book new appointment',
  },
  failedCodeRedemption: {
    id: 'Home.failedCodeRedemption',
    defaultMessage: 'The entered code is not valid',
  },
  greetingMorning: {
    id: 'Home.greeting.morning',
    defaultMessage: 'Good morning',
  },
  greetingAfternoon: {
    id: 'Home.greeting.afternoon',
    defaultMessage: 'Good afternoon',
  },
  greetingEvening: {
    id: 'Home.greeting.evening',
    defaultMessage: 'Good evening',
  },
  greetingDefault: {
    id: 'Home.greeting.default',
    defaultMessage: 'Hi',
  },
  greetingFull: {
    id: 'Home.greeting',
    defaultMessage: '{greeting} {name}. How can I help?',
  },
  findHealthInformation: {
    id: 'Home.findHealthInformation',
    defaultMessage: 'Find Health Information',
  },
})
