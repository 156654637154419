import { defineMessages } from 'react-intl'

export default defineMessages({
  next: {
    id: 'form.next',
    defaultMessage: 'Next',
  },
  confirm: {
    id: 'form.confirm',
    defaultMessage: 'Confirm',
  },
  registerAndContinue: {
    id: 'form.registerAndContinue',
    defaultMessage: 'Register and continue',
  },
  previous: {
    id: 'form.previous',
    defaultMessage: 'Back',
  },
  nextSteps: {
    id: 'form.nextSteps',
    defaultMessage: 'Next steps',
  },
  home: {
    id: 'form.home',
    defaultMessage: 'Back to homepage',
  },
  review: {
    id: 'form.review',
    defaultMessage: 'Review answers',
  },
})
