export const textTheme = {
  color: '--medkit-text-base-color',
  marginTop: '--medkit-text-base-margin-top',
  marginBottom: '--medkit-text-base-margin-bottom',

  h1FontSize: '--medkit-text-h1-font-size',
  h1LineHeight: '--medkit-text-h1-line-height',

  h2FontSize: '--medkit-text-h2-font-size',
  h2LineHeight: '--medkit-text-h2-line-height',

  h3FontSize: '--medkit-text-h3-font-size',
  h3LineHeight: '--medkit-text-h3-line-height',

  h4FontSize: '--medkit-text-h4-font-size',
  h4LineHeight: '--medkit-text-h4-line-height',

  h5FontSize: '--medkit-text-h5-font-size',
  h5LineHeight: '--medkit-text-h5-line-height',

  bodyFontSize: '--medkit-text-body-font-size',
  bodyLineHeight: '--medkit-text-body-line-height',

  bodyMarginTopAfterH1: '--medkit-body-margin-top-after-h1',
  bodyMarginTopAfterH2: '--medkit-body-margin-top-after-h2',
  bodyMarginTopAfterH3: '--medkit-body-margin-top-after-h3',
  bodyMarginTopAfterH4: '--medkit-body-margin-top-after-h4',
  bodyMarginTopAfterH5: '--medkit-body-margin-top-after-h5',

  smallTextFontSize: '--medkit-text-small-font-size',
  smallTextLineHeight: '--medkit-text-small-line-height',
}
