import React from 'react'
import { LinkButton } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'

interface Props {
  onRetry: () => void
}

const ResetPasswordSubmitted: React.FC<Props> = ({ onRetry }) => {
  const t = useFormatMessage()

  return (
    <>
      <p>{t(messages.success)}</p>
      <p>
        {t(messages.notReceived)}{' '}
        <LinkButton onClick={onRetry}>{t(messages.tryAgain)}</LinkButton>
      </p>
    </>
  )
}

export default ResetPasswordSubmitted
