import { defineMessages } from 'react-intl'

export default defineMessages({
  recommendedProduct: {
    id: 'WeightLoss.ProductCard.recommendedProduct',
    defaultMessage: 'Recommended',
  },
  subscribe: {
    id: 'WeightLoss.ProductCard.subscribe',
    defaultMessage: 'Select {productName}',
  },
  buttonUnavailable: {
    id: 'WeightLoss.ProductCard.buttonUnavailable',
    defaultMessage: 'Unavailable',
  },
  pricePerMonth: {
    id: 'WeightLoss.ProductCard.pricePerMonth',
    defaultMessage: 'per month',
  },
  featuresShowLabel: {
    id: 'WeightLoss.ProductCard.featuresShowLabel',
    defaultMessage: 'Expand features',
  },
  featuresHideLabel: {
    id: 'WeightLoss.ProductCard.featuresHideLabel',
    defaultMessage: 'Collapse features',
  },
  submitError: {
    id: 'WeightLoss.ProductCard.submitError',
    defaultMessage: 'Something went wrong, please try again',
  },
  wegovyTooltip: {
    id: 'WeightLoss.ProductCard.wegovyTooltip',
    defaultMessage:
      "Your dose will likely increase every 4 weeks until you reach the maintenance dose of 2.4mg. Your pricing will increase along with the dose you're prescribed, up to £349.",
  },
  mounjaroTooltip: {
    id: 'WeightLoss.ProductCard.mounjaroTooltip',
    defaultMessage:
      "Your dose will likely increase every 4 weeks until you reach the dose that is most effective for you. Your pricing will increase along with the dose you're prescribed, up to £279.",
  },
})
