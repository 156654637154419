import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React, { Component } from 'react'

import { Tick } from '@babylon/icons'
import asFormControl from '../asFormControl'

import styles from './checkboxgroup.module.scss'

const cx = classNames.bind(styles)

class Checkboxgroup extends Component {
  static normalizeOptions(options, values) {
    const isArray = Array.isArray(values)

    return options.reduce((result, { value }) => {
      result[value] = isArray ? values.includes(value) : !!values[value]

      return result
    }, {})
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedOptions: Checkboxgroup.normalizeOptions(
        props.options,
        props.value
      ),
    }
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({
        selectedOptions: Checkboxgroup.normalizeOptions(
          nextProps.options,
          nextProps.value
        ),
      })
    }
  }

  handleChange = (event) => {
    const { onChange, options, readOnly } = this.props

    if (readOnly) {
      return
    }

    const { selectedOptions } = this.state
    const key = event.target.value
    const updatedOptions = {
      ...selectedOptions,
      [key]: !selectedOptions[key],
    }

    if (onChange) {
      onChange(
        options
          .map((option) => option.value)
          .filter((key) => updatedOptions[key])
      )
    }
  }

  render() {
    const { label, name, options, readOnly, disabled } = this.props

    return (
      <div className="input-wrapper">
        {label && <span className={cx('label')}>{label}</span>}

        {options.map(({ value, label: itemLabel, ...wrapperProps }) => {
          const checkboxId = `${name}${value}`

          return (
            <div key={value} className={cx('checkbox')} {...wrapperProps}>
              <input
                type="checkbox"
                className={cx('checkbox__input')}
                id={checkboxId}
                name={name}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
                checked={this.state.selectedOptions[value]}
                onChange={this.handleChange}
              />
              <label htmlFor={checkboxId} className={cx('checkbox__label')}>
                <span className={cx('checkbox__label__content')}>
                  {itemLabel}
                </span>
                <Tick className={cx('checkbox__icon')} aria-hidden />
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

Checkboxgroup.propTypes = {
  /** The label of the checkbox items */
  label: PropTypes.node,
  /** The name of the checkbox group */
  name: PropTypes.string,
  /** The checkbox items to repeat over */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ),
  /** The function to call when the value changes */
  onChange: PropTypes.func,
  /** The value property can be an object or an array:
   *
   * ```
   * {
   *    value1: true,
   *    value2: true,
   *    value3: false
   * }
   * ```
   *
   * or
   *
   * ```
   * [ 'value1', 'value2' ]
   * ```
   *
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  /** The value property can be an object or an array (See the value property)  */
  readOnly: PropTypes.bool,
  /** Makes the component disabled */
  disabled: PropTypes.bool,
}

Checkboxgroup.defaultProps = {
  label: '',
  name: '',
  options: [],
  onChange: null,
  readOnly: false,
  disabled: false,
}

export default asFormControl(Checkboxgroup)
