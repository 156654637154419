import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import PrivacySettingsQuery from './queries'
import usePrivacySettings from './usePrivacySettings'
import PrivacyPolicyDecision, { updateCache } from '../mutations'
import withUserId from '@/wrappers/withUserId'
import SettingsPage from './Settings'

interface Props {
  userId: string
}

const PrivacySettingsPageContainer: React.FC<Props> = ({ userId }) => {
  const { loading, data } = usePrivacySettings(userId)
  const [changeSetting] = useMutation(PrivacyPolicyDecision, {
    context: {
      showFeedback: {
        failure: true,
      },
    },
  })
  const handleChange = async (noticeVersionId: string, decision: boolean) => {
    await changeSetting({
      variables: {
        decision,
        noticeVersionId,
      },
      update: updateCache({
        propertyName: 'privacySettings',
        query: PrivacySettingsQuery,
        userId,
        noticeVersionId,
      }),
    })
  }

  const notices = !loading && data.patient.privacySettings

  return (
    <SettingsPage
      loading={loading}
      notices={notices}
      handleChange={handleChange}
    />
  )
}

export default withUserId(PrivacySettingsPageContainer)
