import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { allCountries } from 'country-telephone-data'
import classNames from 'classnames/bind'

import asFormControl from '../asFormControl'

import styles from './phoneInput.module.scss'

const cx = classNames.bind(styles)

const formatCode = (code) => {
  let countryCode = code
  if (!['-', '+'].includes(countryCode.charAt(0))) {
    countryCode = '+' + countryCode
  }
  return countryCode
}

// There are multiple countries with the same country code in the library
// `country-telephone-data` so we are excluding all the duplications of
// the UK prefix (+44). These are: Guernsey, Isle of Man and Jersey
const EXCLUDED_ISO2_CODES = ['gg', 'im', 'je']

const countryCodes = allCountries.filter(
  ({ iso2 }) => !EXCLUDED_ISO2_CODES.includes(iso2)
)

class PhoneInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // setting default value to UK's code if none is provided
      countryCode: formatCode(props.countryCode.toString()),
      value: props.value.toString(),
    }
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(newProps) {
    let newState
    if (
      newProps.countryCode &&
      newProps.countryCode !== this.props.countryCode
    ) {
      newState = {
        countryCode: newProps.countryCode,
      }
    }
    if (newProps.value && newProps.value !== this.props.value) {
      newState = newState
        ? {
            ...newState,
            value: newProps.value,
          }
        : { value: newProps.value }
    }

    if (newState) {
      this.setState(newState)
    }
  }

  onChange = (changedField) => (e) => {
    this.setState(
      {
        [changedField]:
          changedField === 'countryCode'
            ? formatCode(e.target.value)
            : e.target.value,
      },
      () => this.props.onChange(this.state)
    )
  }

  render() {
    const {
      label,
      errors,
      success,
      disabled,
      selectDisplayClassName,
    } = this.props
    const { countryCode, value } = this.state

    const inputClass = cx(
      styles.phoneInput,
      errors.length ? styles.error : '',
      success ? styles.success : ''
    )

    const selectDisplayClass = cx(
      styles.selectDisplay,
      errors.length ? styles.error : '',
      success ? styles.success : '',
      selectDisplayClassName
    )

    return (
      <div>
        <label className="label" htmlFor="phone-number">
          {label}
        </label>
        <div className={styles.inputsWrapper}>
          <select
            data-testid="phoneCountryCode"
            className={styles.countrySelect}
            value={countryCode}
            onChange={this.onChange('countryCode')}
            onBlur={() => ({})}
            disabled={disabled}
          >
            <option label="----" value="----" disabled />
            {countryCodes.map((country) => (
              <option
                key={country.iso2}
                label={`${country.name} +${country.dialCode}`}
                value={`+${country.dialCode.toString()}`}
              >
                {country.name}
              </option>
            ))}
          </select>
          <div className={selectDisplayClass}>{countryCode}</div>
          <input
            data-testid="phoneInput"
            type="tel"
            className={inputClass}
            id="phone-number"
            value={value}
            onChange={this.onChange('value')}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}

PhoneInput.propTypes = {
  /** The label for the input */
  label: PropTypes.node,
  /** The array of errors on the input */
  errors: PropTypes.arrayOf(PropTypes.string),

  /** The success message */
  success: PropTypes.string,
  /** onChange method that takes an object argument with `countryCode` and `value` */
  onChange: PropTypes.func,
  /** country code to display before user's choice */
  countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** phone number to display if already available  */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** sets a custom CSS class for the selectDisplayClass **/
  selectDisplayClassName: PropTypes.string,
}

PhoneInput.defaultProps = {
  label: 'Phone Input',
  errors: [],
  success: '',
  onChange: () => {},
  countryCode: '----',
  value: '',
  selectDisplayClassName: '',
}

export default asFormControl(PhoneInput)
