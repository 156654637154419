/*
  given a DOM element and a list of css props and options,
  returns an object where each key is a requested CSS prop name,
  and each value its current computed style

  for example:
    getStyleValues(myEl, [
      {prop: 'margin-bottom' },
      {prop: 'margin-top', { asNumber: true }},
    ])

  could return:
    { margin-bottom: '10px', margin-top: 10 }
*/
const getStyleValues = (el, props) =>
  props.reduce((acc, { prop, options = {} }) => {
    const value = window.getComputedStyle(el).getPropertyValue(prop)

    acc[prop] = options.asNumber ? parseFloat(value.replace('px')) : value

    return acc
  }, {})

export { getStyleValues }
