/**
 * Initialise GA Data Layer before sending any events
 */
import { CustomDimensionsMap } from './types'

interface InitialParameters {
  gtag: Gtag.Gtag
  GA_MEASUREMENT_ID: string
  appName: string
  customMap?: CustomDimensionsMap
}

export default ({
  gtag,
  GA_MEASUREMENT_ID,
  appName,
  customMap,
}: InitialParameters): void => {
  gtag('config', GA_MEASUREMENT_ID, {
    app_name: appName,
    custom_map: customMap,
    // IMPORTANT: 'config' command always sends a 'page_view' event
    // unless 'send_page_view' is explicitly set to 'false'
    // this could result in multiple page view events
    send_page_view: false,
  })
}
