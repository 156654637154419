import React from 'react'
import cn from 'classnames'
import styles from './RadioButton.module.scss'

interface RadioButtonProps {
  id: string
  label: string
  value: string
  checked: boolean
  onChange: (value: string) => void
  extraLabel?: string
  tabIndex?: number
  onFocus?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  value,
  checked,
  onChange,
  extraLabel,
  tabIndex = -1,
  onFocus = () => {},
  onKeyDown = () => {},
}) => {
  const radioOptionRef = React.useRef<HTMLButtonElement | null>(null)

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <button
      type="button"
      className={cn(styles.containerV1, checked && styles.checkedV1)}
      ref={radioOptionRef}
      tabIndex={tabIndex}
      role="radio"
      aria-checked={checked}
      onClick={() => {
        onChange(value)
      }}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    >
      <span className={styles.labelV1}>
        <input
          type="radio"
          id={`${label}-${id}`}
          data-testid={`radio-${label}`}
          name={`${label}-${id}`}
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
        />

        <span className={styles.radioMarkV1} />
        <span className={styles.titleV1}>{label}</span>
      </span>
      {extraLabel && <span className={styles.extraLabelV1}>{extraLabel}</span>}
    </button>
  )
}

export default RadioButton
