import React from 'react'
import cn from 'classnames'
import { UserCircleFilled } from '@babylon/icons'

import { IMedkitComponent } from '..'
import styles from './Avatar.module.scss'
import { avatarTheme } from './theme'
import { AvatarSize } from './AvatarSize.enum'

export interface IAvatarProps {
  /** Source url of the image */
  src?: string
  /** description text of the image */
  description: string
  /** Extra class name(s) to be applied to the component */
  className?: string
  /** Extra class name(s) to be applied to the fallback icon  */
  fallbackClassName?: string
  /** Whether to display round image or not */
  isRounded?: boolean
  /** An extra icon element to display */
  icon?: React.ReactNode
  /** Whether to highlight extra icon or not */
  highlightIcon?: boolean
  /** An string used to display text as fallback */
  fallbackText?: string
  /** An object used to display initials as fallback */
  FallbackIcon?: React.ComponentType<React.HTMLProps<HTMLOrSVGElement>>
  /** Type of user of the avatar */
  isSpecialist?: boolean
  /** Size of the avatar */
  size?: AvatarSize
}

const getBackgroundImage = (src: string) => ({
  backgroundImage: `url('${src}')`,
})

const StandardImage: React.FC<React.HTMLProps<HTMLImageElement>> = ({
  src = '',
  alt,
}) => {
  const isBase64 = src.includes('base64')

  return isBase64 ? (
    <span className={styles.avatar__imgWrapper}>
      <img src={src} alt={alt} className={styles.avatar__img} />
    </span>
  ) : (
    <span
      className={styles.avatar__imgWrapper}
      style={getBackgroundImage(src)}
      title={alt}
    />
  )
}

const Avatar: IMedkitComponent<IAvatarProps, typeof avatarTheme> = ({
  className,
  description,
  isRounded = true,
  icon,
  highlightIcon = false,
  size = AvatarSize.Medium,
  src,
  fallbackText,
  FallbackIcon = UserCircleFilled,
  fallbackClassName,
}) => (
  <figure
    className={cn(
      styles.avatar,
      size && styles[`avatar--${size}`],
      isRounded && styles['avatar--rounded'],
      className
    )}
  >
    {src ? (
      <StandardImage src={src} alt={description} />
    ) : fallbackText ? (
      <span className={styles.avatar__text}>{fallbackText}</span>
    ) : (
      FallbackIcon && (
        <FallbackIcon
          title={description}
          className={cn(styles.avatar__default, fallbackClassName)}
        />
      )
    )}
    {icon && (
      <span
        className={cn(
          styles.avatar__iconWrapper,
          highlightIcon && styles['avatar__iconWrapper--highlight']
        )}
      >
        {icon}
      </span>
    )}
  </figure>
)

Avatar.theme = avatarTheme

export default Avatar
