import React, { useState, ComponentType } from 'react'
import { Patient } from '@babylon/consumer-api/types'
import moment from 'moment-timezone'

import { useQuery } from '@apollo/react-hooks'
import UserQuery from '@/queries/Patient'
import { ENABLE_APPOINTMENT_TIMEZONE } from '@/config'
import getFlags from './featureFlags'
import { REGION_CODES } from '@/config/constants'
import { useCurrentUser } from '@/redux/selectors'

export const timezonePrettyFormat = (timezone: string): string => {
  const zone = moment.tz.zone(timezone)

  if (!zone) {
    return timezone
  }

  const splittedRegions = timezone.split('/')

  if (splittedRegions.length > 2) {
    splittedRegions.shift()
  }

  const splittedTimezone = splittedRegions.reverse().join('/')

  return splittedTimezone.replace(/\//g, ', ').replace(/_/g, ' ')
}

export const timezoneShortFormat = (
  timezone: string,
  timestamp: number
): string => {
  const momentObject = moment.tz(timestamp, timezone)
  const zoneFormat = momentObject.format('z')

  if (zoneFormat === 'GMT') {
    return zoneFormat
  }

  let offsetInHour = momentObject.format('Z')

  if (offsetInHour.endsWith(':00')) {
    offsetInHour = offsetInHour.replace(/:00$/, '')
  }

  if (offsetInHour.startsWith('+0') || offsetInHour.startsWith('-0')) {
    offsetInHour = offsetInHour.replace(/^(\+|-)0/, '$1')
  }

  return `GMT ${offsetInHour}`
}

export const isTimezoneEnabled = (): undefined | boolean => {
  if (
    ENABLE_APPOINTMENT_TIMEZONE === 'true' ||
    ENABLE_APPOINTMENT_TIMEZONE === true ||
    getFlags().appt_timezone === true
  ) {
    return true
  }

  if (
    ENABLE_APPOINTMENT_TIMEZONE === 'false' ||
    ENABLE_APPOINTMENT_TIMEZONE === false
  ) {
    return false
  }

  return undefined
}

export const useTimezoneFeature = (): [boolean] => {
  const [hasTimezone, setHasTimezone] = useState(false)
  const { id: userId } = useCurrentUser()

  const { data } = useQuery<{
    patient: Patient
  }>(UserQuery, {
    variables: { id: userId },
    skip: !userId,
  })

  if (data?.patient.region?.iso_code && !hasTimezone) {
    const region = data!.patient.region!.iso_code.toUpperCase()

    if ([REGION_CODES.CAN, REGION_CODES.USA].indexOf(region) > -1) {
      setHasTimezone(true)
    }
  }

  // we want the feature flag to have priority over businesss logic
  if (isTimezoneEnabled() !== undefined) {
    return [isTimezoneEnabled() as boolean]
  }

  return [hasTimezone]
}

export const withTimezoneFeature = (Component: ComponentType<any>) => (
  props: any
) => {
  const [isTimezoneEnabled] = useTimezoneFeature()

  return <Component {...props} isTimezoneEnabled={isTimezoneEnabled} />
}
