import React, { Children, Component } from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import styles from './tab.module.scss'

const cx = classNames.bind(styles)

const createPointerStyle = (width, left) => ({
  width: `${width}px`,
  transform: `translate3d(${left}px, -2px, 0)`,
})
/**
 * Container for `Tabs`.
 *
 * @example ./TabGroup.md
 */
class TabGroup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabName: props.default || null,
      pointerStyle: createPointerStyle(0, 0),
    }
  }

  componentDidMount() {
    this.calculatePointerPosition()
  }

  calculatePointerPosition = () => {
    const { _activeButton = null } = this

    if (_activeButton) {
      const rect = _activeButton.getBoundingClientRect()
      const width = Math.round(rect.width)
      const left = _activeButton.offsetLeft

      this.setState({
        pointerStyle: createPointerStyle(width, left),
      })
    }
  }

  handleButtonClick = (activeTabName) => (event) => {
    const prevTabName = this.state.activeTabName

    if (prevTabName !== activeTabName) {
      this.setState({ activeTabName })

      if (this.props.onTabChange) {
        this.props.onTabChange({ prevTabName, activeTabName, srcEvent: event })
      }

      setTimeout(this.calculatePointerPosition)
    }
  }

  setActiveButtonRef = (isActive) => (button) => {
    if (isActive) {
      this._activeButton = button
    }
  }

  render() {
    const children = Children.toArray(this.props.children)
    const { activeTabName, pointerStyle } = this.state

    if (children.length === 0) {
      return null
    }

    const buttons = children.map((child) => {
      const { name, title, disabled = false } = child.props
      const active = child.props.name === activeTabName

      const classname = cx('tab', {
        active,
        disabled,
      })

      return (
        <div
          key={name}
          role="tab"
          tabIndex={0}
          className={classname}
          ref={this.setActiveButtonRef(active)}
          onClick={this.handleButtonClick(name)}
        >
          {title}
        </div>
      )
    })

    const content = activeTabName
      ? children.find((child) => child.props.name === activeTabName)
      : null

    return (
      <div className={styles.tabGroup}>
        <div className={styles.tabListContainer}>
          <div className={styles.tabList} role="tablist">
            {buttons}
          </div>
          <div className={styles.pointer} style={pointerStyle} />
        </div>
        <div className={styles.tabPanel} role="tabpanel">
          {content}
        </div>
      </div>
    )
  }
}

TabGroup.propTypes = {
  /** Default tab name */
  default: PropTypes.string,
  /** Event handler that will be triggered every tame a new tab is opened */
  onTabChange: PropTypes.func,
}

TabGroup.defaultProps = {
  default: null,
}

export default TabGroup
