import { CookieGroup, OneTrustState } from './types'

const CHANGE_COOKIE_PREFERENCES_TYPE = 'ChangeCookiePreferences' as const

const INITIAL_STATE = { cookiePreferences: [] }

export type OneTrustAction = {
  type: typeof CHANGE_COOKIE_PREFERENCES_TYPE
  cookiePreferences: CookieGroup[]
}

export const changeCookePreferencesAction = (
  cookiePreferences: CookieGroup[]
): OneTrustAction => ({
  type: CHANGE_COOKIE_PREFERENCES_TYPE,
  cookiePreferences,
})

export default function reducer(
  state = INITIAL_STATE,
  action: OneTrustAction
): OneTrustState {
  switch (action.type) {
    case CHANGE_COOKIE_PREFERENCES_TYPE:
      return { cookiePreferences: action.cookiePreferences }
    default:
      return state
  }
}
