/* eslint-disable camelcase */

import { IFileProps } from '@babylon/medkit/src/FileUpload'
import {
  AppointmentCategory,
  AppointmentMedium,
  AppointmentRequestInput,
  Gender,
  Patient,
  SymptomCategory,
} from '@babylon/consumer-api/types'

import { CHANGE_BOOKING_FORM_DATA, CLEAR_BOOKING_FORM_DATA } from './types'
import { PatientWithFamily } from '../SelectPatient'
import { BookSoonestAppointmentResult } from '../InstantAppointmentReview/useBookSoonestAppointment'

export type ProfessionCriteria = {
  type: 'Profession'
  name: string
  allowed_mediums: undefined
}
export type ServiceTypeCriteria = {
  type: 'ServiceType'
  uuid: string
  name: string
  allowed_mediums?: AppointmentMedium[] | null
}

export type ClinicianCriteria = ServiceTypeCriteria | ProfessionCriteria
export type SelectedAppointmentType = 'digital' | 'physical'

export interface BookingState
  extends Omit<
    AppointmentRequestInput,
    'patient_id' | 'image' | 'medium' | 'images'
  > {
  gp_consent: boolean
  image: IFileProps // to deprecate when multiple images upload is enabled
  images: IFileProps[]
  medium?: AppointmentMedium
  selectedPatient?: Patient | PatientWithFamily
  patient_note: string
  preferred_date_time: string
  clinicianCriteria?: ClinicianCriteria
  specialist_id?: number
  clinician_gender?: Gender
  timezone_id: string
  referrer: string
  category?:
    | (AppointmentCategory & { recommendedClinicians: undefined })
    | (SymptomCategory & { service_types: undefined })
  serviceTypeCategory?: Pick<AppointmentCategory, 'uuid' | 'consultation_types'>
  isDSM?: boolean
  isImageRequired?: boolean
  bookingResult?: BookSoonestAppointmentResult
  recommended?: boolean
  hasClickedSomeoneElse?: boolean
  showPreviousClinicianModal: boolean
  selectedAppointmentType?: SelectedAppointmentType
}

export const initialState: BookingState = {
  image: {
    size: 0,
    name: '',
    error: null,
    base64: '',
    isImage: true,
    file: undefined,
    loading: false,
  },
  images: [],
  medium: undefined,
  selectedPatient: undefined,
  patient_note: '',
  preferred_date_time: '',
  specialist_id: undefined,
  clinician_gender: undefined,
  serviceTypeCategory: undefined,
  timezone_id: '',
  gp_consent: false,
  referrer: '/',
  category: undefined,
  isDSM: false,
  isImageRequired: false,
  recommended: false,
  hasClickedSomeoneElse: false,
  showPreviousClinicianModal: false,
  selectedAppointmentType: undefined,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_BOOKING_FORM_DATA:
      return {
        ...state,
        ...payload,
      }
    case CLEAR_BOOKING_FORM_DATA:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
