/* eslint react/no-unused-prop-types:0 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { compose, withHandlers, withProps, withState } from 'recompose'

import RatingItem from './RatingItem'
import styles from './starRating.module.scss'

const cx = classNames.bind(styles)

const enhance = compose(
  withState('hasFocus', '_setHasFocus', false),
  withHandlers({
    onSelectRating: ({ isReadOnly, onRate }) => (num) => {
      if (!isReadOnly) {
        onRate(num)
      }
    },
    onFocusIn: ({ _setHasFocus }) => () => _setHasFocus(true),
    onFocusOut: ({ _setHasFocus }) => () => _setHasFocus(false),
  }),
  withProps(({ max = 5, onSelectRating, rating }) => ({
    options: [...Array(max + 1).keys()] // array of integers from 1 to {max}
      .slice(1) // slice to remove the 0
      .map((num) => ({
        value: num,
        isActive: rating && rating >= num,
        isSelected: rating === num,
        onSelect: (e) => {
          e.preventDefault()
          onSelectRating(num)
        },
      })),
  }))
)

const StarRating = ({
  hasFocus,
  isReadOnly,
  label,
  onFocusIn,
  onFocusOut,
  options,
  starColor,
}) => (
  <div className={styles.starRating}>
    <p className={styles.starRating__label}>{label}</p>
    <ol
      role="radiogroup"
      className={cx(styles.starRating__list, {
        'starRating__list--focus': hasFocus,
      })}
      onMouseOut={onFocusOut}
      onFocus={onFocusIn}
      onBlur={onFocusOut}
    >
      {options.map((item) => (
        <RatingItem key={item.value} {...item} {...{ isReadOnly, starColor }} />
      ))}
    </ol>
  </div>
)

StarRating.propTypes = {
  isReadOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onRate: PropTypes.func,
  rating: PropTypes.number.isRequired,
  starColor: PropTypes.string,
  max: PropTypes.number,
}

StarRating.defaultProps = {
  max: 5,
  isReadOnly: false,
  onRate: () => {},
  starColor: undefined,
}

export default enhance(StarRating)
