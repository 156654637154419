import React, { useState } from 'react'
import { Icon } from '@babylon/web-platform-ui'
import styles from './Tooltip.module.scss'

interface TooltipProps {
  position?: 'top' | 'bottom'
  children: React.ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({ children, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => setIsVisible((prevState) => !prevState)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      setIsVisible(false)
    } else if (
      event.key === 'Enter' ||
      event.key === ' ' ||
      event.key === 'Spacebar'
    ) {
      event.preventDefault()
      toggleVisibility()
    }
  }

  return (
    <div className={styles.tooltipWrapper}>
      <button
        type="button"
        className={styles.icon}
        onClick={toggleVisibility}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onKeyDown={handleKeyPress}
        aria-label="price information tooltip"
        aria-describedby={isVisible ? 'tooltipContent' : undefined}
      >
        <Icon icon="Information" color="light.functional.inactive" />
      </button>
      {isVisible && (
        <div
          id="tooltipContent"
          role="tooltip"
          className={`${styles.tooltip} ${styles[position]}`}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Tooltip
