import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Text } from '@babylon/medkit'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import { Height, Weight, Measurements, MEASUREMENT_UNIT } from '../../types'
import HeightMeasurement from './HeightMeasurement'
import WeightMeasurement from './WeightMeasurement'
import { validateHeight, validateWeight } from '../../utils/validateAnswer'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'

interface HeightAndWeightProps {
  id: string
  title?: string
  body?: string | Array<string>
  error?: boolean
  value?: Measurements
  onChange: (height: Height, weight: Weight) => void
}

const HeightAndWeight: React.FC<HeightAndWeightProps> = ({
  id,
  title,
  body,
  error = false,
  value,
  onChange,
}) => {
  const formatMessage = useFormatMessage()

  const handleHeightChange = (height: Height) => {
    onChange(
      height,
      value?.weight || {
        unit: MEASUREMENT_UNIT.IMPERIAL,
        kg: '',
        stones: '',
        pounds: '',
      }
    )
  }

  const handleWeightChange = (weight: Weight) => {
    onChange(
      value?.height || {
        unit: MEASUREMENT_UNIT.IMPERIAL,
        cm: '',
        feet: '',
        inches: '',
      },
      weight
    )
  }

  const bodies = body?.split('\n') || []

  return (
    <div>
      {title && <QuestionTitle id={id} content={title} type="header" />}
      {bodies.map((line) => (
        <div className={styles.bodyLine}>
          <Text variant="body">{line}</Text>
        </div>
      ))}

      <div className={styles.container}>
        <HeightMeasurement
          handleChange={handleHeightChange}
          error={(!value?.height || !validateHeight(value?.height)) && error}
          value={value?.height}
        />

        <WeightMeasurement
          handleChange={handleWeightChange}
          error={(!value?.weight || !validateWeight(value?.weight)) && error}
          value={value?.weight}
        />
      </div>
      <p className={styles.disclaimer}>
        {formatMessage(messages.weightWarning)}
      </p>
    </div>
  )
}

export default HeightAndWeight
