import { useMutation } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import { logException } from '@babylon/sentry'
import { useCallback } from 'react'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import { QUESTIONNAIRE_SEX_QUESTION_ID } from '@/programmes/WeightLoss/steps/preScreeningStepsV2'
import { RadioGroupValue } from '@/programmes/components/FormV2/types'

const useUpdateSexAtBirth = () => {
  const userId = useSelector((state: any) => state.auth?.user?.id)
  const [updateSexAtBirthMutation, { data, loading }] = useMutation(
    UpdatePatientMutation
  )
  const updateSexAtBirth = useCallback(
    (fields) => {
      updateSexAtBirthMutation({
        variables: {
          patient: {
            gender: (fields[QUESTIONNAIRE_SEX_QUESTION_ID] as RadioGroupValue)
              .value,
          },
          id: userId,
        },
      }).catch(logException)
    },
    [updateSexAtBirthMutation, userId]
  )

  return { updateSexAtBirth, data, loading }
}

export default useUpdateSexAtBirth
