import { isEmpty } from 'lodash'
import {
  Answer,
  Measurements,
  Question,
  Step,
  CheckBoxGroupValue,
  RadioGroupValue,
  Height,
  Weight,
  DataPointsValue,
  QuestionOption,
  FormInputs,
} from '../types'
import getHeightAndWeightAsMetric from '@/programmes/utils/convertHeightAndWeight'
import getDataPoints from '@/programmes/components/Form/utils/getDataPoints'

enum PayloadEnum {
  FREE_TEXT = 'freeText',
  OPTIONS = 'options',
}
type freeTextAnswer = {
  value: string | number
  data_points?: DataPointsValue[]
  unit?: string
  type: PayloadEnum
}

type optionsAnswer = {
  value: boolean
  data_points?: DataPointsValue[]
  followUpQuestion?: {
    question: string
    value: string | undefined
    data_points?: DataPointsValue[]
  }
  type: PayloadEnum
}

function getBody(question: Question): string[] | undefined {
  if (!question.body) {
    return undefined
  }

  return Array.isArray(question.body)
    ? (question.body as string[])
    : [question.body]
}

function addHeightAndWeight(
  payload: Array<{
    id: string
    question: string
    body?: string[]
    answer_options: {
      title?: string
      answer: freeTextAnswer | optionsAnswer
    }[]
  }>,
  question: Question,
  answers: { [key: string]: Answer },
  key: string
) {
  const { height, weight } = getHeightAndWeightAsMetric(
    (answers[key] as Measurements).height,
    (answers[key] as Measurements).weight
  )

  if (height === undefined || weight === undefined) return

  // height and weight component is custom for now, so we need to handle data points differently, as it has two data points
  // need to find a better way of handling this, instead of relying on the name of the data point, as this can change
  let heightDataPoints: DataPointsValue[] = []
  let weightDataPoints: DataPointsValue[] = []
  if (question.dataPoints && question.dataPoints.length > 0) {
    heightDataPoints =
      (question.dataPoints[0].name === 'height' &&
        getDataPoints([question.dataPoints[0]], Number(height), 'cm')) ||
      []
    weightDataPoints =
      (question.dataPoints[1].name === 'weight' &&
        getDataPoints([question.dataPoints[1]], Number(weight), 'kg')) ||
      []
  }

  payload.push({
    id: `${question?.id}_height`,
    question: 'What is your height?',
    ...(question.body && { body: getBody(question) }),
    answer_options: [
      {
        answer: {
          value: Number(height),
          unit: 'cm',
          type: PayloadEnum.FREE_TEXT,
          data_points: heightDataPoints,
        },
      },
    ],
  })

  payload.push({
    id: `${question?.id}_weight`,
    question: 'What is your weight?',
    ...(question.body && { body: getBody(question) }),
    answer_options: [
      {
        answer: {
          value: Number(weight),
          unit: 'kg',
          type: PayloadEnum.FREE_TEXT,
          data_points: weightDataPoints,
        },
      },
    ],
  })
}

function formatFollowUpQuestionValue(
  followUpQuestionValue: Answer,
  type: FormInputs
): string {
  //We are sending all the followupQuestion values as strings to prevent the backend from breaking
  //We will need to update this once the backend is ready to accept the correct format

  if (type.component === 'checkboxGroup') {
    return (followUpQuestionValue as CheckBoxGroupValue[])
      .map((value) => value.value)
      .join(', ')
  } else if (type.component === 'radioGroup') {
    return (followUpQuestionValue as RadioGroupValue).value
  } else {
    return followUpQuestionValue as string
  }
}

function addFollowUpQuestionValue(
  option: QuestionOption,
  result: CheckBoxGroupValue
) {
  if (option.followUpQuestion && result.followUpQuestionValue) {
    const formattedValue = formatFollowUpQuestionValue(
      result.followUpQuestionValue,
      option.followUpQuestion.inputType
    )

    return {
      followUpQuestion: {
        question: option.followUpQuestion.title,
        value: formattedValue,
        data_points: getDataPoints(
          option.followUpQuestion.dataPoints,
          formattedValue
        ),
      },
    }
  }
  return undefined
}

function getQuestionsPayload(
  answers: { [key: string]: Answer },
  steps: Step[]
) {
  const questionPayload: Array<{
    id: string
    question: string
    body?: string[]
    answer_options: {
      title?: string
      answer: freeTextAnswer | optionsAnswer
    }[]
  }> = []
  Object.keys(answers).forEach((key) => {
    const question: Question = steps.find((step) => step.id === key) as Question

    if (!question) {
      return
    }

    if (question?.inputType?.component === 'heightAndWeight') {
      addHeightAndWeight(questionPayload, question, answers, key)

      return
    }

    if (question?.inputType?.component === ('collectEmail' as any)) {
      // This question is only used to send marketing preferences to Braze prior to sign up
      // If they get to the stage of sending in the questionnaire they will have provided marketing consent via Auth0
      // So I think we can ignore this question

      // This is is actually a v2 question type however because there is only a single version of the product picker
      // we're still using this v1 version of of formatQuestionnairePayload regarless of whether or not the v2 version
      // of the questionnaire was filled out.
      // Ideally we should have refactored this so that product picker would dispatch to the correct
      // version formatQuestionnairePayload. But considering we'll probably be removing the v1 version of the
      // questionnaire soon it might not be worth the effort in refactoring this
      return
    }

    if (question?.inputType?.component === 'height') {
      const { height } = getHeightAndWeightAsMetric(answers[key] as Height)

      if (height === undefined) return

      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: [
          {
            answer: {
              value: Number(height),
              unit: 'cm',
              type: PayloadEnum.FREE_TEXT,
              data_points: getDataPoints(
                question.dataPoints,
                Number(height),
                'cm'
              ),
            },
          },
        ],
      })
      return
    }

    if (question?.inputType?.component === 'weight') {
      const { weight } = getHeightAndWeightAsMetric(
        undefined,
        answers[key] as Weight
      )

      if (weight === undefined) return

      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: [
          {
            answer: {
              value: Number(weight),
              unit: 'kg',
              type: PayloadEnum.FREE_TEXT,
              data_points: getDataPoints(
                question.dataPoints,
                Number(weight),
                'kg'
              ),
            },
          },
        ],
      })
      return
    }

    if (question.inputType?.component === 'checkboxGroup') {
      const options = question.options?.map((option) => {
        const result = (answers[key] as CheckBoxGroupValue[]).find(
          (checked) => checked.value === option.value
        )

        if (result) {
          return {
            title: option.label,
            answer: {
              value: true,
              data_points: getDataPoints(option.dataPoints, result.value),
              ...(option.followUpQuestion &&
                result.followUpQuestionValue &&
                addFollowUpQuestionValue(option, result)),
              type: PayloadEnum.OPTIONS,
            },
          }
        }

        return {
          title: option.label,
          answer: {
            value: false,
            type: PayloadEnum.OPTIONS,
          },
        }
      })

      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: options || [],
      })

      return
    }

    if (question.inputType?.component === 'radioGroup') {
      const options = question.options?.map((option) => {
        if (option.value === (answers[key] as RadioGroupValue).value) {
          const value = answers[key] as RadioGroupValue
          return {
            title: option.label,
            answer: {
              value: true,
              data_points: getDataPoints(option.dataPoints, value.value),
              ...(option.followUpQuestion &&
                value.followUpQuestionValue &&
                addFollowUpQuestionValue(option, value)),
              type: PayloadEnum.OPTIONS,
            },
          }
        }

        return {
          title: option.label,
          answer: {
            value: false,
            type: PayloadEnum.OPTIONS,
          },
        }
      })

      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: options || [],
      })
    } else if (question.inputType?.component === 'confirmation') {
      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: [
          {
            answer: {
              value: answers[key] as string,
              type: PayloadEnum.FREE_TEXT,
            },
          },
        ],
      })
    } else {
      // Possibly we should error here as it would mean that we have a question type that we don't know how to handle
      questionPayload.push({
        id: question?.id,
        question: question?.title,
        ...(question.body && { body: getBody(question) }),
        answer_options: [
          {
            answer: {
              value: answers[key] as string,
              type: PayloadEnum.FREE_TEXT,
            },
          },
        ],
      })
    }
  })

  return questionPayload
}

function getVitalSignsPayload(
  steps: Step[],
  answers: { [key: string]: Answer }
) {
  //For some programmes we use the heightAndWeight as a single question, so we need to handle it differently
  //For other programmes we use height and weight as separate questions
  const step = steps.find(
    (step) =>
      step.type === 'question' &&
      step.inputType?.component === 'heightAndWeight'
  )

  if (step) {
    const { height, weight } = getHeightAndWeightAsMetric(
      (answers[step.id] as Measurements).height,
      (answers[step.id] as Measurements).weight
    )
    return {
      height: { value: Number(height), unit: 'cm' },
      weight: { value: Number(weight), unit: 'kg' },
    }
  } else {
    const weightStep = steps.find(
      (step) =>
        step.type === 'question' && step.inputType?.component === 'weight'
    )
    const heightStep = steps.find(
      (step) =>
        step.type === 'question' && step.inputType?.component === 'height'
    )

    if (weightStep || heightStep) {
      const { height, weight } = getHeightAndWeightAsMetric(
        (heightStep && (answers[heightStep.id] as Height)) || undefined,
        (weightStep && (answers[weightStep.id] as Weight)) || undefined
      )
      return {
        height: { value: Number(height), unit: 'cm' },
        weight: { value: Number(weight), unit: 'kg' },
      }
    }
  }

  return {}
}

// formatting the payload and making it simpler for the backend to digest for now.
export const formatQuestionnairePayload = (
  steps: Step[],
  answers?: {
    [questionId: string]: Answer
  }
) => {
  if (!answers || isEmpty(answers)) {
    return undefined
  }

  const questionPayload = getQuestionsPayload(answers, steps)
  const vitalSignsPayload = getVitalSignsPayload(steps, answers)

  return {
    version: 2,
    questions: questionPayload,
    vital_signs: vitalSignsPayload,
  }
}
