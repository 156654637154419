/* eslint-disable react/prop-types */
import React from 'react'
import { Spacer } from '@babylon/ui'

type Props = {
  content: string
}

const Announcement: React.FC<Props> = ({ content }) => (
  <Spacer bottom={2}>
    <strong>{content}</strong>
  </Spacer>
)

export default Announcement
