import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import Template from './Template'
import messages from './messages'
import styles from './index.module.scss'
import Button from '@/programmes/components/Button'

const cx = classNames.bind(styles)

const SetupErrorIneligiblePage = () => {
  const t = useFormatMessage()
  const history = useHistory()

  return (
    <Template title={t(messages.ineligiblePageHeading)}>
      <h2 className={cx('heading')}>{t(messages.ineligiblePageHeading)}</h2>
      <p className={cx('description')}>
        {t(messages.ineligiblePageDescription)}
      </p>
      <div className={cx('cta')}>
        <Button onClick={() => history.push('/account/0/membership/plans')}>
          {t(messages.ineligiblePageCta)}
        </Button>
      </div>
    </Template>
  )
}

export default SetupErrorIneligiblePage
