import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'ServiceUnavailable.pageTitle',
    defaultMessage: 'Service Unavailable',
  },
  pageHeading: {
    id: 'ServiceUnavailable.pageHeading',
    defaultMessage:
      "We’re sorry, your account wasn't created as this service isn't available in your area yet.",
  },
  body1: {
    id: 'ServiceUnavailable.body1',
    defaultMessage:
      'If you have symptoms of coronavirus, please stay at home and use the {link}.',
  },
  body1LinkText: {
    id: 'ServiceUnavailable.body1LinkText',
    defaultMessage: '111 coronavirus service',
  },
  body1LinkHref: {
    id: 'ServiceUnavailable.body1LinkHref',
    defaultMessage: 'https://111.nhs.uk/',
  },
  body2: {
    id: 'ServiceUnavailable.body2',
    defaultMessage:
      'If you think that your symptoms are life-threatening, call 999',
  },
  body3: {
    id: 'ServiceUnavailable.body3',
    defaultMessage: 'For general information on coronavirus, visit the {link}',
  },
  body3LinkText: {
    id: 'ServiceUnavailable.body3LinkText',
    defaultMessage: 'government website',
  },
  body3LinkHref: {
    id: 'ServiceUnavailable.body3LinkHref',
    defaultMessage: 'https://www.gov.uk/coronavirus',
  },
})
