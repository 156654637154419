import { defineMessages } from 'react-intl'

export default defineMessages({
  linkMessage: {
    id: 'pulseInput.linkMessage',
    defaultMessage:
      'How do I check my pulse? Your pulse can be measured in beats per minutes.',
  },
  nhsHowMessage: {
    id: 'pulseInput.nhsHowMessage',
    defaultMessage: 'See this NHS article for step-by-step guidance',
  },
  bpmLabel: {
    id: 'pulseInput.bpmLabel',
    defaultMessage: 'BPM',
  },
})
