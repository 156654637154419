import React, { createContext } from 'react'
import { TrackingService } from '../types'

type ProviderProps = React.PropsWithChildren<{
  service: TrackingService
}>

export const TrackingContext = createContext<TrackingService | null>(null)

/**
 * Provides an IoC container that takes an instantiated `TealiumService` object for use within
 * a component tree
 *
 * @param props - the provider's component props
 * @param props.service - an instance of a tracking service that conforms to a `TrackingService`
 * @param props.children - the component's children
 */
export const TrackingProvider = ({ service, children }: ProviderProps) => (
  <TrackingContext.Provider value={service}>
    {children}
  </TrackingContext.Provider>
)
