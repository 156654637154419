import { compose, withProps } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

import { setPrivacyAgreementNeeded } from '@/redux/actions'
import { PrivacyNoticeFragment } from '@/components/Privacy/fragments'
import withUserId from './withUserId'

const justInTimeNotices = gql`
  query User($id: ID!) {
    patient(id: $id) {
      id
      just_in_time_notices {
        ...privacy_notice
      }
    }
  }
  ${PrivacyNoticeFragment}
`

const loginNotices = gql`
  query User($id: ID!) {
    patient(id: $id) {
      id
      privacy_notices {
        ...privacy_notice
      }
    }
  }
  ${PrivacyNoticeFragment}
`

export default (action) => {
  const isLogin = action === 'login'
  const queryName = isLogin ? 'loginData' : 'justInTimeData'
  const propertyName = isLogin ? 'privacy_notices' : 'just_in_time_notices'
  const gqlToUse = isLogin ? loginNotices : justInTimeNotices
  const query = graphql(gqlToUse, {
    name: queryName,
    skip: (props) => !props.userId,
    options: ({ userId }) => ({
      variables: { id: userId },
    }),
  })

  return compose(
    withUserId,
    query,
    connect(null, (dispatch) =>
      bindActionCreators({ setPrivacyAgreementNeeded }, dispatch)
    ),
    withProps((props) => {
      const data = props[queryName]
      const notices = ((data.patient || {})[propertyName] || []).filter((n) =>
        isLogin
          ? !n.accepted && n.mandatory
          : n.accepted === null && !n.mandatory
      )
      const privacyAgreementNeeded = notices && notices.length > 0

      // NOTE: hacky non-user triggerred dispatch placed into a `setTimeout` to avoid the React error message:
      // "Cannot update a component ... while rendering a different component"
      setTimeout(() => {
        props.setPrivacyAgreementNeeded(privacyAgreementNeeded)
      }, 0)

      return {
        privacyNotices: notices,
        privacyAgreementNeeded,
      }
    })
  )
}

export { justInTimeNotices, loginNotices }
