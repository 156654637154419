import React, { useState } from 'react'
import { ToggleSwitch, LinkButton } from '@babylon/medkit'

import Modal from '@/components/Modal'

import styles from './Notice.module.scss'

const SwitchOption = ({ id, notice, onChange }) => {
  const [showModal, toggleModal] = useState(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} id={`${id}_label`}>
        <h2 className={styles.title}>{notice.title}</h2>
        <p className={styles.subtitle}>{`${notice.settingsText} `}</p>
        {notice.linkText && (
          <LinkButton isStandaloneLink onClick={() => toggleModal(true)}>
            {notice.linkText}
          </LinkButton>
        )}
      </div>
      <ToggleSwitch
        aria-describedby={`${id}_label`}
        onClick={() => onChange(!notice.accepted)}
        checked={notice.accepted}
      />
      {showModal && (
        <Modal onClose={() => toggleModal(false)}>
          <div dangerouslySetInnerHTML={{ __html: notice.longText }} />
        </Modal>
      )}
    </div>
  )
}

export default SwitchOption
