import gql from 'graphql-tag'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'
import {
  UnknownState,
  PaidOnlyState,
  OnProgrammeState,
  OffProgrammeState,
  CarePlan,
} from '../model'

export const PROGRAMME_STATE_QUERY = gql`
  query GetProgrammeState(
    $patientId: ID!
    $categoryFilter: [CodeFilterOption!]!
    $productGroups: [ProductGroup!]!
  ) {
    patient(id: $patientId) {
      ... on Patient {
        id
        carePlans(
          first: 1
          _sort: [{ order: DESC, field: CREATED }]
          _filter: { category: { ONE_OF: $categoryFilter } }
        ) {
          nodes {
            id
            title
            created
            status
          }
        }
        products(productGroups: $productGroups) {
          ... on ProductList {
            items {
              id
              name
              group
              subscription {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`

const CARE_PLAN_ACTIVE_STATUSES = [
  'active',
  // Normally, there should be one active status but since the programmes
  // are in active development 'draft' is also considered as active
  'draft',
]

const isActiveCarePlan = (carePlan: CarePlan) =>
  CARE_PLAN_ACTIVE_STATUSES.includes(carePlan.status)

export enum CarePlanCategory {
  WeightLoss = 'weight-loss-management-care-plan',
}

export enum ProductGroup {
  WeightLossMedication = 'weightLossMedication',
}

const parseActiveProducts = (data: any) =>
  data?.patient?.products?.items.filter(
    (item: any) => item.subscription?.status.toLowerCase() === 'active'
  ) || []

const parseActiveCarePlan = (data: any) => {
  const carePlan = data?.patient?.carePlans?.nodes?.[0] as CarePlan
  if (carePlan && isActiveCarePlan(carePlan)) {
    return carePlan
  }
  return null
}

const parseProgrammeState = (data: any) => {
  const products = parseActiveProducts(data)
  const carePlan = parseActiveCarePlan(data)
  if (
    // If there is no products and care plan is active we consider the patient
    // is on the programme though it may change in the future.
    (products.length === 0 && carePlan) ||
    // If there is care plan and subscriptions they are definitely on the programme
    (products.length !== 0 && carePlan)
  ) {
    return {
      kind: 'OnProgrammeState',
      products: products || [],
      carePlan,
    } as OnProgrammeState
  }
  if (products.length !== 0 && !carePlan) {
    return { kind: 'PaidOnlyState', products } as PaidOnlyState
  }
  if (products.length === 0 && !carePlan) {
    return { kind: 'OffProgrammeState' } as OffProgrammeState
  }
  return {
    kind: 'UnknownState',
    reason: 'Failed to determine state',
  } as UnknownState
}

const useGetProgrammeState = () => {
  const userUUID = useSelector((state: any) => state.auth?.user?.uuid)
  const { data, loading, error } = useQuery(PROGRAMME_STATE_QUERY, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      patientId: userUUID,
      categoryFilter: [{ code: CarePlanCategory.WeightLoss }],
      productGroups: [ProductGroup.WeightLossMedication],
    },
  })

  if (loading) {
    return {
      programmeState: {
        kind: 'UnknownState',
        reason: 'Loading programme state',
      } as UnknownState,
      loading,
    }
  }
  if (error) {
    return {
      programmeState: {
        kind: 'UnknownState',
        reason: error.message,
      } as UnknownState,
      loading: false,
    }
  }

  return { programmeState: parseProgrammeState(data), loading: false }
}

export default useGetProgrammeState
