/* eslint-disable no-underscore-dangle */
import { get, isBoolean, isDate, isNil, isObject, toInteger } from 'lodash'
import { INDICATOR_VARIABLE_COMPUTED_TYPE } from './types'

function compareEquals(actualValue, expectedValue) {
  // Checking the presence of a specific answer on an array with the "=" operator
  if (Array.isArray(actualValue) && !isBoolean(expectedValue)) {
    return actualValue.includes(expectedValue)
  }

  // Boolean questions check for "true" strings against true value
  if (
    (actualValue === 'true' && expectedValue === true) ||
    (actualValue === true && expectedValue === 'true')
  ) {
    return true
  }

  // Boolean questions check for "true" strings against true value
  if (
    (actualValue === 'false' && expectedValue === false) ||
    (actualValue === false && expectedValue === 'false')
  ) {
    return true
  }

  // compares equality
  return actualValue === expectedValue
}

function valueExists(actualValue, expectedValue) {
  // if it has the exists operator and the expectedValue is a string
  if (Array.isArray(actualValue) && !isBoolean(expectedValue)) {
    return actualValue.includes(expectedValue)
  }

  // Checking the existence of any value on any type of question
  if (expectedValue === true) {
    return actualValue && actualValue.length !== 0
  }

  return false
}

function compareDoesNotEqual(actualValue, expectedValue) {
  if (Array.isArray(actualValue)) {
    return !actualValue.includes(expectedValue)
  }

  return actualValue !== expectedValue
}

// eslint-disable-next-line import/prefer-default-export
export const compareItems = (
  actualAnswer,
  operator: string,
  expectedAnswer
) => {
  switch (operator) {
    default:
    case '=':
      return compareEquals(actualAnswer, expectedAnswer)
    case 'exists':
      return valueExists(actualAnswer, expectedAnswer)
    case '!=':
      return compareDoesNotEqual(actualAnswer, expectedAnswer)
    case '>':
      return actualAnswer > expectedAnswer
    case '<':
      return actualAnswer < expectedAnswer
    case '>=':
      return actualAnswer >= expectedAnswer
    case '<=':
      return actualAnswer <= expectedAnswer
  }
}

export const getAnswerComputedIfNecessary = (answer, computedFuncion) => {
  let computed = answer

  // eslint-disable-next-line default-case
  switch (computedFuncion) {
    case INDICATOR_VARIABLE_COMPUTED_TYPE.AGE.valueOf():
      computed = _computeAge(answer)

      break
    case INDICATOR_VARIABLE_COMPUTED_TYPE.HEIGHT_FEET.valueOf():
      computed = _computeHeightFeet(answer)

      break
    case INDICATOR_VARIABLE_COMPUTED_TYPE.BLOOD_PRESSURE.valueOf():
      computed = _computeBloodPressure(answer)

      break
  }

  return computed
}

const _computeAge = (value: string | Date) => {
  const birthDay = isDate(value) ? value : new Date(value)
  const ageDifMs = Date.now() - birthDay.getTime()
  const ageDate = new Date(ageDifMs)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const _computeHeightFeet = (value: string | number) => {
  const heightVals = _imperialHeightValueToObject(value)

  return Math.round(heightVals.feet + heightVals.inches * 0.0833333)
}

const _computeBloodPressure = (value) => {
  const val = value?.value ?? value

  if (!isObject(val)) {
    return val
  }

  if (!isNil(get(val, 'systolic')) && !isNil(get(val, 'diastolic'))) {
    return [toInteger(get(val, 'systolic')), toInteger(get(val, 'diastolic'))]
  }

  return val
}

const _imperialHeightValueToObject = (value: string | number) => ({
  feet: typeof value === 'string' ? parseInt(value.charAt(0)) : value,
  inches: typeof value === 'string' ? parseInt(value.split("'")[1]) : 0,
})

export const getExpectedAnswer = (item) =>
  item.answerBoolean ??
  item.answerDecimal ??
  item.answerInteger ??
  item.answerDate ??
  item.answerDateTime ??
  item.answerTime ??
  item.answerString ??
  item.answerCoding ??
  item.answerQuantity ??
  item.answerReference
