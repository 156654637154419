import { defineMessages } from 'react-intl'

export default defineMessages({
  homePageTitle: {
    id: 'Screen.V2.HomePage.pageTitle',
    defaultMessage: 'eMed Screen™ - Welcome',
  },
  homeHeading: {
    id: 'Screen.V2.HomePage.heading',
    defaultMessage: 'Welcome to eMed® Screen',
  },
  homeDescriptionLoggedOut: {
    id: 'Screen.V2.HomePage.description.loggedOut',
    defaultMessage:
      'Please create an account to start your eMed® Screen assessment. \n\n' +
      'Experience the future of health and wellness with our AI-powered digital assessment and screening tool. Gain valuable insights into your overall health and access comprehensive tools, care, and support to enhance your wellbeing.',
  },
  homeDescriptionPart1: {
    id: 'Screen.V2.HomePage.description.loggedIn.part1',
    defaultMessage:
      "We believe in supporting you through every step of your eMed Screen™ journey. From the moment you begin, you'll be virtually connected to one of our healthcare technicians, who will guide you through the assessment process step by step",
  },
  homeDescriptionHeading2: {
    id: 'Screen.V2.HomePage.description.loggedIn.heading2',
    defaultMessage: 'Connecting with a Healthcare Technician',
  },
  homeDescriptionPart2: {
    id: 'Screen.V2.HomePage.description.loggedIn.part2',
    defaultMessage:
      'We recommend that you connect with one of our healthcare technicians to complete your eMed Screen™ assessment Monday - Thursday before midday.',
  },
  homeDescriptionHeading3: {
    id: 'Screen.V2.HomePage.description.loggedIn.heading3',
    defaultMessage: 'What to expect during your eMed Screen™ journey:',
  },
  homeDescriptionPart3: {
    id: 'Screen.V2.HomePage.description.loggedIn.part3',
    defaultMessage:
      'On the next screen, you will be prompted to start your eMed Screen™ assessment. Once you begin, you will be virtually connected to a healthcare technician.',
  },
  homeDescriptionHeading4: {
    id: 'Screen.V2.HomePage.description.loggedIn.heading4',
    defaultMessage: 'Assessment steps:',
  },
  homeDescriptionSubheading1: {
    id: 'Screen.V2.HomePage.description.loggedIn.subheading1',
    defaultMessage: '1. Digital vital signs',
  },
  homeDescriptionPart4Section1: {
    id: 'Screen.V2.HomePage.description.loggedIn.part4.section1',
    defaultMessage:
      'You will begin with an AI-powered digital assessment that collects three key metrics: blood pressure, heart rate, and heart rate variability. \n\n' +
      'This initial assessment takes approximately 2 minutes.',
  },
  homeDescriptionSubheading2: {
    id: 'Screen.V2.HomePage.description.loggedIn.subheading2',
    defaultMessage: '2. Blood sample collection & health questions',
  },
  homeDescriptionPart4Section2: {
    id: 'Screen.V2.HomePage.description.loggedIn.part4.section2',
    defaultMessage:
      'After the vital sign assessment, a healthcare technician will guide you through collecting your blood sample using the Tasso device provided in your kit.\n\n' +
      'This procedure takes about five minutes. During this time, we will also ask you a series of questions to better understand your health and well-being.',
  },
  homeDescriptionHeading5: {
    id: 'Screen.V2.HomePage.description.loggedIn.heading5',
    defaultMessage: 'Post assessment steps:',
  },
  homeDescriptionPart5: {
    id: 'Screen.V2.HomePage.description.loggedIn.part5',
    defaultMessage:
      "Once you complete the assessment, you'll need to post your blood sample to our partner laboratory using the included prepaid addressed envelope.\n\n" +
      'You will receive your eMed Screen™ Report within five working days.\n\n' +
      "When your report is ready, we'll email you a link to access your personalised dashboard\n\n" +
      'We will then prompt you to book an appointment with one of our GPs to discuss your health findings and create a personalised health plan together.\n\n' +
      "Thank you for choosing eMed Screen™ for your annual health assessment. We're here to support you every step of the way.",
  },
  homeCta2: {
    id: 'Screen.V2.HomePage.cta2',
    defaultMessage: 'Get started',
  },
  homePrivacy: {
    id: 'Screen.V2.HomePage.privacy',
    defaultMessage:
      'Your data will be processed in line with our updated <a>privacy policy</a>.',
  },
  confirmationPageAppInfoDescription: {
    id: 'Screen.V2.ConfirmationPage.AppInfoDescription',
    defaultMessage:
      'Download the app now and sign-up for appointments with GPs, nurses, pharmacists and physiotherapists, instant health information and a range of digital tools to help you manage your health 24/7.',
  },
  setupConfirmationPageTitle: {
    id: 'ScreenV2.CheckInStartupConfirmationPage.pageTitle',
    defaultMessage: 'eMed Screen™ - Confirmation',
  },
  setupConfirmationPageHeading: {
    id: 'ScreenV2.CheckInStartupConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  setupConfirmationPageDescription: {
    id: 'ScreenV2.CheckInStartupConfirmationPage.description',
    defaultMessage:
      'You’ve now completed your eMed Screen™ assessment.\n\n' +
      'Remember to post your blood sample to our partner laboratory using the included prepaid addressed envelope as soon as possible. \n\n' +
      'You will receive your eMed Screen Report within 5 working days. \n\n' +
      'When your report is ready, we’ll email you a link to access your personalised dashboard\n\n' +
      'We will then prompt you to book an appointment with one of our GPs to discuss your health findings and together created a personalised health plan.',
  },
  setupConfirmationPageDescriptionFooter: {
    id: 'ScreenV2.CheckInStartupConfirmationPage.description.footer',
    defaultMessage:
      'Thank you for choosing eMed Screen™ for your annual health assessment. We’re here to support you every step of the way.',
  },
  setupConfirmationPageCta: {
    id: 'ScreenV2.SetupConfirmationPage.cta',
    defaultMessage: 'Done',
  },
  setupHomePageTitle: {
    id: 'ScreenV2.setupHomePage.pageTitle',
    defaultMessage: 'eMed Screen™ - Setup',
  },
  setupHomePageHeading: {
    id: 'ScreenV2.setupHomePage.heading.v2',
    defaultMessage: 'Get ready to complete your eMed® Screen assessment',
  },
  setupHomePageDescription: {
    id: 'ScreenV2.setupHomePage.description.v2',
    defaultMessage:
      "You're close to beginning your eMed® Screen assessment. The eMed healthcare technician will be there to support you throughout the journey. Here are a few key steps to ensure it is easy.\n",
  },
  timeAndPlaceTitle: {
    id: 'ScreenV2.SetupHome.Card1.title',
    defaultMessage: 'Time and Place',
  },
  timeAndPlaceDescription: {
    id: 'ScreenV2.SetupHome.Card1.description.v2',
    defaultMessage:
      'Set aside 30 minutes in a private space to complete the entire process, including preparation, the eMed Screen™ assessment, and packaging the sample. Ideally, complete this assessment between Monday and Thursday before midday to catch the last postal collection.',
  },
  fastingTitle: {
    id: 'ScreenV2.SetupHome.Card4.title',
    defaultMessage: 'Fasting',
  },
  fastingDescription: {
    id: 'ScreenV2.SetupHome.Card4.description.v2',
    defaultMessage:
      "Do not eat or drink anything except water for six hours before collecting your blood sample. If you've eaten recently, we recommend returning to this page later after you have suitably fasted. Many people find first thing in the morning works best for them",
  },
  fastingCheckboxLabel: {
    id: 'ScreenV2.SetupHome.fastingCheckboxLabel',
    defaultMessage:
      'I haven’t eaten or drunk anything other than water for at least 6 hours',
  },
  hydrationCheckboxLabel: {
    id: 'ScreenV2.SetupHome.hydrationCheckboxLabel',
    defaultMessage: 'I drank 2 glasses of water 30 minutes ago',
  },
  hydrationTitle: {
    id: 'ScreenV2.SetupHome.Card5.title',
    defaultMessage: 'Hydration',
  },
  hydrationDescription: {
    id: 'ScreenV2.SetupHome.Card5.description',
    defaultMessage:
      'Drink two large glasses of water 30 minutes before your assessment. It helps with easier blood sample collection.',
  },
  clothingTitle: {
    id: 'ScreenV2.SetupHome.Card6.title',
    defaultMessage: 'Clothing',
  },
  clothingDescription: {
    id: 'ScreenV2.SetupHome.Card6.description',
    defaultMessage:
      'Wear comfortable, loose clothing for the video call. This will make it easier to access your upper arm.',
  },
  deviceTitle: {
    id: 'ScreenV2.SetupHome.Card7.title',
    defaultMessage: 'Device Requirements',
  },
  deviceDescription: {
    id: 'ScreenV2.SetupHome.Card7.description',
    defaultMessage:
      'Use a device with a camera and microphone, such as your mobile phone, to help communication with the healthcare technician.',
  },
  returningSampleTitle: {
    id: 'ScreenV2.SetupHome.Card8.title',
    defaultMessage: 'Returning your blood sample',
  },
  returningSampleDescription: {
    id: 'ScreenV2.SetupHome.Card8.description',
    defaultMessage:
      'Send your blood sample back as quickly as possible. It is best to return the sample between Monday and Thursday, ideally before midday. This will ensure your sample is processed in the laboratory as quickly as possible.',
  },
  setupHelpDescription: {
    id: 'ScreenV2.SetupHome.helpDescription',
    defaultMessage:
      'If you have not fasted or consumed two glasses of water, please do so before proceeding. These steps are crucial to ensuring the success of your blood test.',
  },
  setupHomePageCta: {
    id: 'ScreenV2.setupHomePage.cta',
    defaultMessage: 'Start',
  },
})
