import React, { useEffect, useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import Template from './Template'
import Form from '@/programmes/components/Form'
import { checkoutRoutes } from './routes'
import {
  wellnessOnboardingStepsUk,
  wellnessOnboardingStepsUS,
} from '@/programmes/Wellness/steps/wellnessOnboardingSteps'
import messages from './messages'
import onboardingSteps from './steps'
import useSubmitFormData from '@/programmes/components/Form/hooks/useSubmitFormData'
import { US_CN_NAME, WELLNESS_ONBOARDING_URL } from '@/config'
import useGetPreferredConsumerNetwork from '@/programmes/hooks/useGetPreferredConsumerNetwork'
import { Step } from '@/programmes/components/Form/types'

const QuestionnairePage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const [wellnessOnboardingSteps, setWellnessOnboardingSteps] = useState<
    Step[]
  >(wellnessOnboardingStepsUk)

  const {
    getPreferredConsumerNetwork,
    loading: loadingCN,
  } = useGetPreferredConsumerNetwork()

  useEffect(() => {
    const preferredConsumerNetwork = getPreferredConsumerNetwork()
    const wellnessOnboardingSteps = preferredConsumerNetwork?.name
      ?.toLowerCase()
      .includes(US_CN_NAME)
      ? wellnessOnboardingStepsUS
      : wellnessOnboardingStepsUk

    setWellnessOnboardingSteps(wellnessOnboardingSteps)
  }, [getPreferredConsumerNetwork])

  const { submitData, loading, success, error } = useSubmitFormData(
    WELLNESS_ONBOARDING_URL,
    wellnessOnboardingSteps
  )

  useEffect(() => {
    if (success) {
      history.push(checkoutRoutes.home)
    }
  }, [success, history])

  return (
    <Template
      title={t(messages.questionnairePageTitle)}
      steps={onboardingSteps}
      activeStep={1}
    >
      <Form
        steps={wellnessOnboardingSteps}
        onSubmit={submitData}
        loading={loading || loadingCN}
        error={error}
      />
    </Template>
  )
}

export default QuestionnairePage
