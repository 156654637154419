import React from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'

import Logo from '../../Logo'
import messages from './PartnerBranding.messages'
import styles from './PartnerBranding.module.scss'

const PartnerBranding = ({ logo }) => {
  const translate = useFormatMessage()
  const className = cn(styles.partnerLogo, styles[`${logo}-logo`])

  return (
    <div className={styles.partnerBrandingContainer}>
      <div className={styles.partnerBranding}>
        <span>{translate(messages.deliveredBy)} </span>
        <Logo name={logo} className={className} />
      </div>
    </div>
  )
}

export default PartnerBranding
