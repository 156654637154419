import React from 'react'

import styles from './List.module.scss'
import NotificationItem from '../NotificationItem'

const List = ({ loading, notifications, markAsRead }) => (
  <ul className={styles.list}>
    {loading ? (
      <>
        <NotificationItem loading />
        <NotificationItem loading />
        <NotificationItem loading />
      </>
    ) : (
      notifications.items.map((item) => (
        <NotificationItem key={item.id} item={item} markAsRead={markAsRead} />
      ))
    )}
  </ul>
)

export default List
