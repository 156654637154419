import React from 'react'
import { DateInput as MedkitDateInput } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import messages from './messages'
import styles from './DateInput.module.scss'
import QuestionTitle from '../QuestionTitle/QuestionTitle'
import QuestionBody from '../QuestionBody'

type Props = {
  onChange: (date: string) => void
  error: boolean
  title?: string
  body?: string | string[]
  value?: string
  label?: string
}

const DateInput: React.FC<Props> = ({
  title,
  body,
  label,
  value,
  onChange,
  error,
}) => {
  const t = useFormatMessage()

  return (
    <>
      {title && <QuestionTitle content={title} type="header" />}
      {body && <QuestionBody body={body} />}
      <MedkitDateInput
        label={label || ''}
        dayLabel={t(messages.dayLabel)}
        monthLabel={t(messages.monthLabel)}
        yearLabel={t(messages.yearLabel)}
        value={value}
        onChange={onChange}
        error={error}
      />

      {error && (
        <span className={styles.errorMessage}>{t(messages.errorMessage)}</span>
      )}
    </>
  )
}

export default DateInput
