import React, { Fragment } from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { find, pipe, propOr, propEq, range } from 'ramda'
import { withProps } from 'recompose'
import { ArrowRight } from '@babylon/icons'

import ActionWrapper from '../ActionWrapper'
import Button from '../Button'

import {
  formatCombinedHourRanges,
  locationOpenStates,
  groupOpeningHours,
} from './LocationSummary.helpers'
import styles from './LocationSummary.module.scss'

const cx = classNames.bind(styles)

/* currently no i18n in component library - hence inline strings for now */
const dictionary = {
  phoneLabel: 'Phone:',
  openingHoursLabel: 'Opening hours:',
}

const enhance = withProps(
  ({ action, lat, lng, openingHours, showAllOpeningHours }) => {
    const enableDirections = typeof lat === 'number' && typeof lng === 'number'

    return {
      action: enableDirections
        ? `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
        : action,
      enableDirections,
      openingHours:
        showAllOpeningHours && openingHours
          ? groupOpeningHours(openingHours)
          : null,
      openingInfoToday:
        !showAllOpeningHours && openingHours
          ? pipe(
              find(propEq('isToday', true)),
              propOr([], 'hours'),
              formatCombinedHourRanges
            )(openingHours)
          : null,
    }
  }
)

const LocationSummary = ({
  action,
  className,
  e2eHandle,
  address,
  chevron,
  cta,
  enableDirections,
  framed,
  icon,
  info,
  isBusy,
  openingHours,
  openingInfoToday,
  padded,
  phoneNumber,
  title,
  onLocationPhoneNumberClick,
}) => (
  <div
    data-testid={e2eHandle}
    className={cx('container', className, {
      'container--selectable': action && !cta,
      'container--busy': isBusy,
      'container--framed': framed,
      'container--padded': padded,
      'container--hasSecondaryAction': phoneNumber,
      'container--hasFooter': phoneNumber || openingHours || cta,
    })}
  >
    {/* if there's an action, but no CTA label, then make the whole thing selectable */}
    <ActionWrapper
      className={styles.main}
      action={!cta ? action : null}
      isExternal={enableDirections}
    >
      {!cta && chevron && (
        <ArrowRight className={styles.main__decoration} aria-hidden />
      )}
      <div className={styles.main__content}>
        <h3 className={styles.title}>{title}</h3>
        <address className={styles.detail}>{address}</address>
        {openingInfoToday && (
          <p className={styles.detail}>
            {dictionary.openingHoursLabel} {openingInfoToday}
          </p>
        )}
        {info && !phoneNumber && (
          <p className={cx('detail', 'detail--strong')}>{info}</p>
        )}
      </div>
      {icon && (
        <div className={cx('main__aside', 'main__aside--icon')}>{icon}</div>
      )}
    </ActionWrapper>

    {(phoneNumber || openingHours || cta) && (
      <div className={styles.footer}>
        {phoneNumber && (
          <p className={cx(styles.detail, styles.secondaryAction)}>
            {dictionary.phoneLabel}{' '}
            <a
              onClick={onLocationPhoneNumberClick}
              href={`tel://${phoneNumber}`}
            >
              {phoneNumber}
            </a>
          </p>
        )}

        {info && phoneNumber && (
          <p className={cx('detail', 'detail--strong')}>{info}</p>
        )}

        {openingHours && (
          <div className={styles.features}>
            <h4 className={cx(styles.title, styles['title--sub'])}>
              {dictionary.openingHoursLabel}
            </h4>
            <dl className={styles.features__inner}>
              {openingHours.map(({ days, info }) => (
                <Fragment key={days}>
                  <dt className={styles.features__label}>{days}</dt>
                  <dd className={styles.features__detail}>
                    {info.replace(', ', '\n')}
                  </dd>
                </Fragment>
              ))}
            </dl>
          </div>
        )}

        {/* if there's an action, and a CTA label, then provide an explicit button */}
        {action && cta && (
          <Button
            className={styles.cta}
            label={cta}
            size="small"
            onClick={action}
          />
        )}
      </div>
    )}
  </div>
)

const requirePropWhenNotBusy = (expectedType) => (props, propKey) => {
  if (props.isBusy) {
    return undefined
  }

  /* eslint valid-typeof:0 */
  // ignore rule, because expectedType IS a string
  if (props[propKey] === undefined || typeof props[propKey] !== expectedType) {
    return new Error(`${propKey} ${expectedType} is required`)
  }

  return undefined
}

LocationSummary.propTypes = {
  /* eslint react/no-unused-prop-types:0 */
  // ignore rule, as some of our prop types are used just by the container
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  address: requirePropWhenNotBusy('string'),
  chevron: PropTypes.bool,
  className: PropTypes.string,
  /** If a call to action label is provided, we will show this in a button
  rather than make the whole location summary selectable */
  cta: PropTypes.string,
  e2eHandle: PropTypes.string,
  framed: PropTypes.bool,
  icon: PropTypes.element,
  info: PropTypes.string,
  isBusy: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  openingHours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.oneOf(range(1, 8)), // iso index (1 = mon, 7 = sun)
      isToday: PropTypes.bool,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.string.isRequired, // HH:mm
          end: PropTypes.string.isRequired, // HH:mm
        })
      ),
    })
  ),
  /** We are optionally informed of the current opening state,
  though we do not yet make use of this in the design,
  we expect to in the near future */
  openingState: PropTypes.oneOf(Object.values(locationOpenStates)),
  /** We are optionally informed of the current opening state,
  though we do not yet make use of this in the design,
  we expect to in the near future */
  openingStateChangeHour: PropTypes.string,
  padded: PropTypes.bool,
  phoneNumber: PropTypes.string,
  /** If true, all known opening hours will be displayed.
  If false, only today's opening hours will be displayed */
  showAllOpeningHours: PropTypes.bool,
  title: requirePropWhenNotBusy('string'),
}

LocationSummary.defaultProps = {
  framed: false,
  isBusy: false,
  onLocationPhoneNumberClick: () => true,
  padded: false,
  showAllOpeningHours: false,
}

export default enhance(LocationSummary)
