import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormatMessage } from '@babylon/intl'
import classNames from 'classnames/bind'
import {
  login as loginAction,
  registerWithParams as registerAction,
} from '@/components/Authentication/actions'
import messages from './messages'
import Button from '@/programmes/components/Button'
import styles from './index.module.scss'

const cx = classNames.bind(styles)

export function ActionOrLogin({
  onActionClick,
  onActionLabel,
}: {
  onActionClick: () => void
  onActionLabel: string
}) {
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const dispatch = useDispatch()
  const t = useFormatMessage()

  return (
    <div className={cx('cta')}>
      {isAuthenticated ? (
        <Button onClick={onActionClick}>{onActionLabel}</Button>
      ) : (
        <>
          <Button onClick={() => dispatch(loginAction())}>
            {t(messages.ctaLogin)}
          </Button>
          <Button onClick={() => dispatch(registerAction())} secondary>
            {t(messages.ctaSignup)}
          </Button>
        </>
      )}
    </div>
  )
}

export default ActionOrLogin
