import { defineMessages } from 'react-intl'

export default {
  chatbot: defineMessages({
    pageTitle: {
      id: 'FeatureUnavailable.chatbot.pageTitle',
      defaultMessage: 'A new message is available for you',
    },
    appInstructionsTitle: {
      id: 'FeatureUnavailable.chatbot.appInstructionsTitle',
      defaultMessage: 'A new message is available for you',
    },
    appInstructions: {
      id: 'FeatureUnavailable.chatbot.appInstructions',
      defaultMessage: 'This feature is only available on our apps.',
    },
  }),
  monitor: defineMessages({
    pageTitle: {
      id: 'FeatureUnavailable.monitor.pageTitle',
      defaultMessage: 'Your test results are available',
    },
    appInstructions: {
      id: 'FeatureUnavailable.monitor.appInstructions',
      defaultMessage: 'To access your results download the app today.',
    },
  }),
}
