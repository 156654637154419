import {
  createEnhancedSection,
  EnhancedSchemaMap,
} from '@/programmes/components/EnhancedSection/EnhancedSection'
import Testimonial from '@/programmes/components/EnhancedSection/marketing/components/Testimonial/Testimonial'
import MessageBox from '@/programmes/components/EnhancedSection/marketing/components/MessageBox/MessageBox'
import Accordion from '@/programmes/components/EnhancedSection/marketing/components/Accordion/Accordion'
import HeadSet from '@/icons/eMed/HeadSet'
import Focus from '@/icons/eMed/Focus'

export const preScreenMarketingEnhancedSchema: EnhancedSchemaMap = {
  '8893f8e8-f66b-11ee-afb5-3331515d4d7c': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      'A healthy weight can improve a range of weight-related health conditions',
  }),
  '89fbb04a-f66b-11ee-8e9f-5306c48c8eb3': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      'A healthy weight can improve a range of weight-related health conditions',
  }),
  '82bf8334-88c9-4810-804b-3658612f08fc': createEnhancedSection(Testimonial, {
    sectionTitle: 'What our members say',
    stars: 5,
    title: 'I don’t feel like snacking',
    description: "So far I've lost 14lbs... my cravings have officially gone!",
    author: 'Diane L',
  }),
  'b39783c3-e9fb-475a-8c64-38d1f7dda728': createEnhancedSection(Accordion, {
    sectionTitle: 'Mental health & physiotherapy included',
    items: [
      {
        icon: Focus,
        title: 'FREE mental healthcare & UNLIMITED physiotherapy',
        description:
          'We have a selection of plans tailored to your needs. With our Weight Loss+ plan, receive holistic care with FREE mental healthcare, digital physiotherapy appointments and nutritional guidance via the eMed app. Integrating physiotherapy and mental health support alongside GLP-1 medication offers comprehensive treatment, helping to improve outcomes and overall wellbeing.',
      },
      {
        icon: HeadSet,
        title: 'Ongoing care and check-ins included 100% online',
        description:
          'Receive the best care, answer to your questions, check-ins and medication adjustments - all 100% online at no additional cost.',
      },
    ],
  }),
  'be07fa78-f266-11ee-8dec-d745fa3e7abc': createEnhancedSection(Testimonial, {
    sectionTitle: 'What our members say',
    stars: 5,
    title: 'A life-changing decision',
    description:
      'I started the eMed Weight Loss Programme and lost 15kgs really quickly!',
    author: 'Calinda Robinson',
  }),
  'a949c22c-f268-11ee-9bc2-bb006c6c4925': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      'eMed provides access to Mental Health Practitioners who will offer support to help you to overcome any underlying concerns you may have (sleep, anxiety or low mood).',
  }),
  'b74c983e-fb20-11ee-b676-af9d51b50bc6': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      "GLP-1 weight loss medication works by slowing down your body's digestion, so you stay satisfied with your meals for a longer period of time.",
  }),
  '579de2fc-fb21-11ee-a72c-97907460c475': createEnhancedSection(Testimonial, {
    sectionTitle: 'What our members say',
    stars: 5,
    title: 'I have more confidence in myself',
    description:
      "I have always been overweight and on the chubbier side, in a few short weeks I felt a lot better about myself, I’ve shed belly fat and I feel so much more confident. This programme has been amazing, because I feel that I'm not alone and I have help and support along the whole way. The support has been incredible and getting my medication has been on time.",
    author: 'Jacob R.',
  }),
  'acaff882-ca59-11ee-9db0-2fe4d05657a5': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      "eMed's Weight Loss Programme is the answer to achieving sustained weight loss through medication and clinical support. We've got you covered.",
  }),
  '6f5f56f2-fc0a-11ee-90fb-1390f1f51ab6': createEnhancedSection(Testimonial, {
    sectionTitle: 'What our members say',
    stars: 5,
    title: 'eMed transformed my life!',
    description: "It's the ultimate weight-loss game-changer!",
    author: 'Sonia Hall',
  }),
  'ff4b181a-fd8f-11ee-8781-efc5243a1d77': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      'eMed provides a 10-week holistic support programme for you to access with activites to help you address a variety of topics.',
  }),
  '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b': createEnhancedSection(MessageBox, {
    title: 'Did you know?',
    children:
      'Losing weight can lower your risk of high blood pressure and reduce raised blood cholesterol levels.',
  }),
}
