import React from 'react'
import { MessageDescriptor } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'

import Image, { importAsset } from '../Image'
import messages from './messages'

type LogoVariant = 'primary' | 'square'

type Props = {
  name: string
  variant?: LogoVariant
  fallback?: string
  className?: string
  [prop: string]: any
}

type Messages = {
  [key: string]: MessageDescriptor
}

const getLogoAssetName = (name: string, variant?: LogoVariant) => {
  const logoName = variant !== 'primary' ? `${name}--${variant}` : name

  return `images/logo/${logoName}.png`
}

const getLogoAsset = async (name: string, variant?: LogoVariant) => {
  const logoAssetName = getLogoAssetName(name, variant)

  let asset: string = await importAsset(logoAssetName)()

  if (!asset) {
    return undefined
  }

  if (!asset.startsWith('data:')) {
    asset = `${window.location.origin}${asset}`
  }

  return asset
}

export const Logo = ({
  name,
  variant = 'primary',
  fallback,
  className,
  ...props
}: Props) => {
  const translate = useFormatMessage()
  const message = (messages as Messages)[`${name}-logo`]
  const alt = message ? translate(message) : 'logo'
  const logoAssetName = getLogoAssetName(name, variant)

  return (
    <Image
      asset={logoAssetName}
      fallback={fallback}
      className={className}
      alt={alt}
      {...props}
    />
  )
}

export default Logo
export { getLogoAsset }
