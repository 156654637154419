import React from 'react'

import ComponentsButton from '@/programmes/components/Button'

interface ButtonProps {
  title: string
  type?: 'primary' | 'secondary'
  onClick: (event: any) => void
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  title,
  type = 'primary',
  onClick,
  loading,
}) => (
  <ComponentsButton
    onClick={onClick}
    secondary={type !== 'primary'}
    loading={loading}
  >
    {title}
  </ComponentsButton>
)

export default Button
