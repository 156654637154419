import { compose, withHandlers, withProps, withState } from 'recompose'

const withRefs = compose(
  withState('_refCount', '_setRefCount', 0),
  withHandlers(() => {
    let refs = {}

    return {
      onRef: ({ _refCount, _setRefCount }) => (id) => (ref) => {
        // avoid an infinite loop of render > ref set > prop change > render
        if (refs[id]) return

        refs = { ...refs, [id]: ref }

        // trigger a prop change
        _setRefCount(_refCount + 1)
      },
      _getRefs: () => () => refs,
    }
  }),
  withProps(({ _getRefs }) => ({
    ..._getRefs(),
  }))
)

export default withRefs
