import React from 'react'
import { Interstitial } from '@/programmes/components/FormV2/types'
import Spinner from './Spinner'
import styles from './AutoTransitionInterstitial.module.scss'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import Avatar, {
  AvatarProps,
} from '@/programmes/components/FormV2/components/Avatar/Avatar'

const DEFAULT_DURATION = 3

type Props = {
  interstitial: Interstitial
  avatar: AvatarProps
  body: string
}

const AutoTransitionInterstitial = ({ interstitial, avatar, body }: Props) => (
  <div className={styles.container}>
    <Avatar
      imageSrc={avatar.imageSrc}
      alt={avatar.alt}
      name={avatar.name}
      title={avatar.title}
    />
    <div className={styles.body}>
      <QuestionBody body={body} />
    </div>
    <Spinner
      width={80}
      duration={interstitial.autoTransitionSeconds || DEFAULT_DURATION}
    />
  </div>
)

export default AutoTransitionInterstitial
