import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { List, Text } from '@babylon/medkit'
import { compose } from 'recompose'
import { graphql } from '@apollo/react-hoc'
import { Appointments, Doctor } from '@babylon/icons'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import { path } from 'ramda'
import Form from '@/components/Form'
import PageTitle from '@/components/PageTitle/PageTitle'
import Loading from '@/components/Loading'
import { useRemoteConfigLoaded } from '@/redux/selectors'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import UserQuery from '@/queries/Patient'

import messages from './PartnerLandingPage.messages'
import {
  appointments as getAppointmentsConfig,
  partnerClinicalRecords,
} from '@/routes/config'
import styles from './PartnerLandingPage.module.scss'
import withUserId from '@/wrappers/withUserId'
import Address from '@/components/Address'

const cx = classNames.bind(styles)

type NavItemProps = {
  title: string
  description: string
  ICON: React.FC<any>
  redirectPath: string
  onClick?: () => void
}

const enhance = compose(
  withUserId,
  graphql(UserQuery, {
    name: 'userData',
    options: ({ userId }) => ({
      variables: { id: userId },
    }),
  }),
  graphql(UpdatePatientMutation, {
    name: 'updatePatient',
  })
)

const NavItem = ({
  title,
  description,
  ICON,
  redirectPath,
  onClick,
}: NavItemProps) => {
  const history = useHistory()

  return (
    <li className={cx('card')} aria-label={`${title}: ${description}`}>
      <button
        type="button"
        onClick={() => {
          if (onClick) {
            onClick()

            return
          }

          history.push(redirectPath)
        }}
        className={cx('card__button')}
      >
        <section className={cx('card__button__left')}>
          <ICON fill="white" />
        </section>
        <section className={cx('card__button__right')}>
          <Text variant="h6" className={cx('card__button__right__title')}>
            {title}
          </Text>
          <Text
            variant="smallBody"
            className={cx('card__button__right__description')}
          >
            {description}
          </Text>
        </section>
      </button>
    </li>
  )
}

// eslint-disable-next-line react/prop-types
const MissingAddress = ({ bookPath, userId, userData, updatePatient }) => {
  const translate = useFormatMessage()
  const history = useHistory()

  const addressFields = () => {
    const data = userData.patient || {}
    const restrictDetails = true

    return [
      {
        title: 'Address',
        rowFields: [
          {
            component: Address,
            name: 'address',
            defaultValues: {
              address_post_code: data.address_post_code || '',
              address_first_line: data.address_first_line || '',
              address_second_line: data.address_second_line || '',
              address_third_line: data.address_third_line || '',
            },
            required: true,
            label: 'Address',
            disabled: restrictDetails,
            regionId: path(['region', 'id'], data),
          },
        ],
      },
    ]
  }

  const onAddressSubmit = async (fields) => {
    try {
      await updatePatient({
        variables: {
          id: userId,
          patient: {
            ...fields,
          },
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error updating address', e)
    } finally {
      history.push(bookPath)
    }
  }

  return (
    <div>
      <h3>{translate(messages.missingAddress)}</h3>
      <Form
        handle="addressForm"
        onSubmit={onAddressSubmit}
        fields={addressFields()}
        options={{
          submitBtnText: translate(messages.addressSave),
        }}
        operationName="updatePatient"
        checkPristine
      />
    </div>
  )
}

/**
 * Partner landing page component that displays the two main routes for partners to navigate to.
 */
const PartnerLanding = ({ userId, userData, updatePatient }: any) => {
  const [showMissingAddress, setShowMissingAddress] = useState(false)
  const history = useHistory()
  const translate = useFormatMessage()
  const managePath = partnerClinicalRecords.route
  const bookPath = getAppointmentsConfig('partners').route
  const remoteConfigLoaded = useRemoteConfigLoaded()

  if (!remoteConfigLoaded) {
    return <Loading />
  }

  const {
    address_post_code: postcode,
    address_first_line: firstLine,
    address_second_line: town,
    address_third_line: county,
  } = userData?.patient || {}

  const hasFullAddress =
    Boolean(postcode) && Boolean(firstLine) && Boolean(town) && Boolean(county)

  const onBookClick = () => {
    if (hasFullAddress) {
      history.push(bookPath)
    }

    setShowMissingAddress(true)
  }

  return (
    <div className="Content--narrow">
      <PageTitle title={translate(messages.pageTitle)} />
      <article>
        {!showMissingAddress && (
          <List variant="ul" className={styles.list}>
            <p>{translate(messages.stayOnThePage)}</p>
            <NavItem
              title={translate(messages.book)}
              description={translate(messages.bookDescription)}
              ICON={Doctor}
              redirectPath={bookPath}
              onClick={onBookClick}
            />
            <NavItem
              title={translate(messages.manage)}
              description={translate(messages.manageDescription)}
              ICON={Appointments}
              redirectPath={managePath}
            />
          </List>
        )}
        {showMissingAddress && (
          <MissingAddress
            bookPath={bookPath}
            userId={userId}
            userData={userData}
            updatePatient={updatePatient}
          />
        )}
      </article>
    </div>
  )
}

export default enhance(PartnerLanding)
