import React from 'react'
import cn from 'classnames'

import styles from './index.module.scss'

type EditorialLayoutProps = {
  children: React.ReactNode
  className?: string
}

const EditorialLayout: React.FC<EditorialLayoutProps> = ({
  children,
  className,
}: EditorialLayoutProps) => (
  <div className={cn(styles.EditorialLayout, className)}>{children}</div>
)

export default EditorialLayout
