import { useEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TrackingActionType } from '@babylon/tracking/react'
import useTracking from '@/tracking/useTracking'

const SESSION_STORAGE_KEY = 'wl-handoff-ref'

// tracking the following handoff: our platform -> VLAB / payment checkout -> our platform,
export const useTrackingPageRedirect = () => {
  const location = useLocation()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    const handoffRef = new Date().getTime().toString()
    sessionStorage.setItem(SESSION_STORAGE_KEY, handoffRef)

    trackEvent({
      screenTitle: `${location.pathname}/${handoffRef}`,
      actionType: TrackingActionType.pageRedirected,
    })
  }, [location, trackEvent])
}

export const useTrackingPageView = (trackingScreenTitle?: string) => {
  const location = useLocation()
  const { trackView } = useTracking()
  const [wasHandedOff, setWasHandedOff] = useState(false)

  useEffect(() => {
    const handoffRef = sessionStorage.getItem(SESSION_STORAGE_KEY)

    if (handoffRef) {
      setWasHandedOff(true)
      sessionStorage.removeItem(SESSION_STORAGE_KEY)
    } else {
      setWasHandedOff(false)
    }

    const extraScreenTitle = handoffRef || trackingScreenTitle
    const screenTitle = extraScreenTitle
      ? `${location.pathname}/${extraScreenTitle}`
      : location.pathname

    trackView({
      screenTitle,
      actionType: TrackingActionType.pageLoaded,
    })
  }, [location, trackView, setWasHandedOff, trackingScreenTitle])
  return { wasHandedOff }
}

export const useTrackingQuestionnaire = (
  questionStep: string,
  error?: boolean
) => {
  const location = useLocation()
  const { trackView } = useTracking()
  useEffect(() => {
    trackView({
      screenTitle: `${location.pathname}/${error ? 'error' : questionStep}`,
      actionType: TrackingActionType.pageLoaded,
    })
  }, [questionStep, error, location, trackView])
}

export const useTrackingInvalidDiscountCode = () => {
  const location = useLocation()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    trackEvent({
      screenTitle: `${location.pathname}/invalid-discount-code`,
      actionType: TrackingActionType.formError,
    })
  }, [location, trackEvent])
}
