import {
  initialise as initialiseTealium,
  UTAG,
  trackView,
  trackEvent,
} from '../../tealium'
import { TealiumEvent, TealiumView } from '../../tealium/types'
import { getSessionId } from '../utils/sessions'
import {
  TrackingService,
  TrackingEventProps,
  TrackingServiceProps,
} from '../types'

/**
 * The `TealiumService` is a wrapper around the `@babylon/tracking` library that
 * instantiates and encapsulates a tealium tracking instance.
 */
export class TealiumService implements TrackingService {
  constructor({
    appVersion,
    appName,
    app,
    env,
    countryIsoCode,
  }: TrackingServiceProps) {
    this.appVersion = appVersion
    this.appName = appName
    this.app = app
    this.env = env
    this.countryIsoCode = countryIsoCode

    this.init()
  }

  /**
   * The name of the tealium application
   */
  public app: string

  /**
   * The name of the application (needed for the tracking payload)
   */
  public appName: string

  /**
   * The current app version
   */
  public appVersion: string

  /**
   * The current environment the app is deployed in
   */
  public env: string

  /**
   * The 3 character country code that the app is currently deployed in
   */
  public countryIsoCode: string

  /**
   * Method tracks a Tealium View Event
   *
   * @param payload - the tracking payload of the view to be tracked
   */
  public trackView(payload: TrackingEventProps) {
    trackView(({
      ...payload,
      ...this.defaultPayload,
    } as unknown) as TealiumView)
  }

  /**
   * Method tracks a generic Tealium Event
   *
   * @param payload - the tracking payload of the event to be tracked
   */
  public trackEvent(payload: TrackingEventProps) {
    trackEvent(({
      ...payload,
      ...this.defaultPayload,
    } as unknown) as TealiumEvent)
  }

  /**
   * The default payload expected by tealium for a view or generic event
   */
  private get defaultPayload() {
    const sessionId = getSessionId()
    return {
      app_build: this.appVersion,
      app_environment: this.env,
      app_name: this.appName,
      app_version: this.appVersion,
      country_iso_code: this.countryIsoCode,
      ...(sessionId ? { session_id: getSessionId() } : {}),
    }
  }

  /**
   * Getter returns the tealium environment based on the app's env
   */
  private get tealiumEnv(): string {
    switch (this.env) {
      case 'prod':
        return 'prod'
      case 'preprod':
      case 'staging':
      case 'dev':
      default:
        return 'dev'
    }
  }

  /**
   * Getter returns the utag script URI
   */
  private get scriptURL() {
    return `https://tags.tiqcdn.com/utag/babylon/${this.app}/${this.tealiumEnv}/utag.js`
  }

  /**
   * Method injects the tealium utag script into the DOM
   *
   * @param src - the tealium script's src url
   */
  // eslint-disable-next-line class-methods-use-this
  private async injectTealiumScript(src: string) {
    return new Promise<UTAG>((resolve, reject) => {
      const script = document.createElement('script')
      script.async = true
      script.type = 'text/javascript'

      script.onload = () => {
        resolve((window as any).utag)
      }
      script.onerror = () => {
        reject()
      }

      script.src = src

      const head = document.getElementsByTagName('head')[0]
      head.appendChild(script)
    })
  }

  /**
   * Convience method that initializes the service
   */
  private async init() {
    try {
      const utag: UTAG = await this.injectTealiumScript(this.scriptURL)
      initialiseTealium(utag, this.countryIsoCode)
    } catch (e) {
      console.error('Cannot initialise Tealium')
    }
  }
}
