import React, { Dispatch, SetStateAction } from 'react'

interface LocaleContext {
  locale: string
  setLocale: Dispatch<SetStateAction<string>>
}

const SetLocaleContext = React.createContext<LocaleContext>({
  locale: 'en-GB',
  setLocale: () => {},
})

export default SetLocaleContext
