import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Height, MEASUREMENT_UNIT } from '../../types'
import TextInput from '../TextInput'
import QuestionBody from '../QuestionBody'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import {
  HEIGHT_VALID_RANGE_IMPERIAL,
  HEIGHT_VALID_RANGE_METRIC,
} from '../../constants'
import ToggleSwitch from '@/programmes/components/FormV2/components/ToggleSwitch/ToggleSwitch'
import Error from '@/programmes/components/FormV2/components/Error/Error'

interface Props {
  title?: string
  body?: string | Array<string>
  value?: Height
  handleChange: (height: Height) => void
  error?: boolean
}

const HeightMeasurement: React.FC<Props> = ({
  title,
  body,
  handleChange,
  value,
  error,
}) => {
  const formatMessage = useFormatMessage()
  const [height, setHeight] = useState<Height>(
    value || {
      unit: MEASUREMENT_UNIT.IMPERIAL,
      cm: '',
      feet: '',
      inches: '',
    }
  )

  return (
    <div className={styles.measurementContainer}>
      <div className={title ? styles.headerVertical : styles.headerHorizontal}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>
            {title || formatMessage(messages.height)}
          </span>
          {body && <QuestionBody body={body} />}
        </div>
        <div className={styles.buttonWrapper}>
          <ToggleSwitch
            inactiveLabel={{
              visualLabel: formatMessage(messages.Ft),
              ariaLabel: formatMessage(messages.switchToftin),
            }}
            activeLabel={{
              visualLabel: formatMessage(messages.Cm),
              ariaLabel: formatMessage(messages.switchToCm),
            }}
            initialActive={height.unit === MEASUREMENT_UNIT.METRIC}
            onClick={() => {
              const newHeight = {
                ...height,
                unit:
                  height.unit === MEASUREMENT_UNIT.IMPERIAL
                    ? MEASUREMENT_UNIT.METRIC
                    : MEASUREMENT_UNIT.IMPERIAL,
              }
              setHeight(newHeight)
              handleChange(newHeight)
            }}
          />
        </div>
      </div>

      <div className={styles.inputContainer}>
        {height.unit === MEASUREMENT_UNIT.IMPERIAL ? (
          <>
            <div
              key={formatMessage(messages.feet)}
              className={styles.textInput}
            >
              <TextInput
                label={formatMessage(messages.feet)}
                hiddenLabel
                type="number"
                onChange={(event) => {
                  const newHeight = { ...height, feet: event.target.value }
                  setHeight(newHeight)
                  handleChange(newHeight)
                }}
                placeholder={formatMessage(messages.feet)}
                value={height.feet}
              />
            </div>

            <div className={styles.textInput}>
              <TextInput
                label={formatMessage(messages.inches)}
                hiddenLabel
                type="number"
                onChange={(event) => {
                  const newHeight = { ...height, inches: event.target.value }
                  setHeight(newHeight)
                  handleChange(newHeight)
                }}
                placeholder={formatMessage(messages.inches)}
                value={height.inches}
              />
            </div>
          </>
        ) : (
          <div
            key={formatMessage(messages.centimetres)}
            className={styles.textInput}
          >
            <TextInput
              label={formatMessage(messages.centimetres)}
              hiddenLabel
              type="number"
              onChange={(event) => {
                const newHeight = { ...height, cm: event.target.value }
                setHeight(newHeight)
                handleChange(newHeight)
              }}
              placeholder={formatMessage(messages.centimetres)}
              value={height.cm}
            />
          </div>
        )}
      </div>
      {error && (
        <Error>
          {height?.unit === MEASUREMENT_UNIT.IMPERIAL
            ? formatMessage(messages.heightImperialErrorMessage, {
                min: HEIGHT_VALID_RANGE_IMPERIAL.min,
                max: HEIGHT_VALID_RANGE_IMPERIAL.max,
              })
            : formatMessage(messages.heightMetricErrorMessage, {
                min: HEIGHT_VALID_RANGE_METRIC.min,
                max: HEIGHT_VALID_RANGE_METRIC.max,
              })}
        </Error>
      )}
    </div>
  )
}

export default HeightMeasurement
