import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { MEASUREMENT_UNIT, Weight } from '../../types'
import TextInput from '../TextInput'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import InputBody from '@/programmes/components/Form/components/InputBody'
import {
  WEIGHT_VALID_RANGE_IMPERIAL,
  WEIGHT_VALID_RANGE_METRIC,
} from '../../constants'
import ToggleSwitch from '@/programmes/components/FormV2/components/ToggleSwitch/ToggleSwitch'
import Error from '@/programmes/components/FormV2/components/Error/Error'
import QuestionTitle from '../QuestionTitle/QuestionTitle'

interface Props {
  title?: string
  body?: string | Array<string>
  value?: Weight
  handleChange: (weight: Weight) => void
  error?: boolean
}

const WeightMeasurement: React.FC<Props> = ({
  title,
  body,
  handleChange,
  value,
  error,
}) => {
  const [weight, setWeight] = useState<Weight>(
    value || {
      unit: MEASUREMENT_UNIT.IMPERIAL,
      kg: '',
      stones: '',
      pounds: '',
    }
  )
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.measurementContainer}>
      <div className={title ? styles.headerVertical : styles.headerHorizontal}>
        {title ? (
          <QuestionTitle content={title} type="header" />
        ) : (
          <div className={styles.titleContainer}>
            <span className={styles.title}>
              {formatMessage(messages.weight)}
            </span>
            {body && <InputBody body={body} />}
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <ToggleSwitch
            inactiveLabel={{
              visualLabel: formatMessage(messages.Lb),
              ariaLabel: formatMessage(messages.switchToStLb),
            }}
            activeLabel={{
              visualLabel: formatMessage(messages.Kg),
              ariaLabel: formatMessage(messages.switchToKg),
            }}
            initialActive={weight.unit === MEASUREMENT_UNIT.METRIC}
            onClick={() => {
              const newHeight = {
                ...weight,
                unit:
                  weight.unit === MEASUREMENT_UNIT.IMPERIAL
                    ? MEASUREMENT_UNIT.METRIC
                    : MEASUREMENT_UNIT.IMPERIAL,
              }
              setWeight(newHeight)
              handleChange(newHeight)
            }}
          />
        </div>
      </div>

      <div className={styles.inputContainer}>
        {weight.unit === MEASUREMENT_UNIT.IMPERIAL ? (
          <>
            <div
              key={formatMessage(messages.stones)}
              className={styles.textInput}
            >
              <TextInput
                label={formatMessage(messages.stones)}
                hiddenLabel
                type="number"
                onChange={(event) => {
                  const newWeight = { ...weight, stones: event.target.value }
                  setWeight(newWeight)
                  handleChange(newWeight)
                }}
                placeholder={formatMessage(messages.stones)}
                value={weight.stones}
              />
            </div>
            <div className={styles.textInput}>
              <TextInput
                label={formatMessage(messages.pounds)}
                hiddenLabel
                type="number"
                onChange={(event) => {
                  const newWeight = { ...weight, pounds: event.target.value }
                  setWeight(newWeight)
                  handleChange(newWeight)
                }}
                placeholder={formatMessage(messages.pounds)}
                value={weight.pounds}
              />
            </div>
          </>
        ) : (
          <div
            key={formatMessage(messages.kilograms)}
            className={styles.textInput}
          >
            <TextInput
              label={formatMessage(messages.kilograms)}
              hiddenLabel
              type="number"
              onChange={(event) => {
                const newWeight = { ...weight, kg: event.target.value }
                setWeight(newWeight)
                handleChange(newWeight)
              }}
              placeholder={formatMessage(messages.kilograms)}
              value={weight.kg}
            />
          </div>
        )}
      </div>
      {error && (
        <Error>
          {weight?.unit === MEASUREMENT_UNIT.IMPERIAL
            ? formatMessage(messages.weightImperialErrorMessage, {
                min: WEIGHT_VALID_RANGE_IMPERIAL.min,
                max: WEIGHT_VALID_RANGE_IMPERIAL.max,
              })
            : formatMessage(messages.weightMetricErrorMessage, {
                min: WEIGHT_VALID_RANGE_METRIC.min,
                max: WEIGHT_VALID_RANGE_METRIC.max,
              })}
        </Error>
      )}
    </div>
  )
}

export default WeightMeasurement
