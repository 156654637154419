import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { logException } from '@babylon/sentry'
import { useFormatMessage } from '@babylon/intl'
import Modal from '@/components/Modal'
import PostcodeLookupMutation from '@/mutations/PostcodeLookup'
import { setupRoutes } from '@/programmes/WeightLoss/routes'
import Button from '@/programmes/components/Button'
import SearchInput from './components/SearchInput'
import messages from './messages'
import styles from './GpInfoForm.module.scss'
import { parseSurgeryData, removeDuplicates, SearchItem } from './utils'
import { UK_POSTCODE_REGEX } from '@/config/constants'
import { useCurrentUserId } from '@/redux/selectors'
import { useUpdateGpInfo } from '@/programmes/hooks/useGpInfo'

const SURGERY_URL =
  'https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations'
export const MIN_SEARCH_LENGTH = 5
const INELIGIBLE_PRACTISE_NAMES = [
  'gp at hand',
  'babylon',
  'emed',
  'lillie road',
]

const GpInfoForm = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const currentUserId = useCurrentUserId()
  const surgeryNameInputRef = useRef<HTMLInputElement>(null)
  const postcodeInputRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<string>()
  const [surgeries, setSurgeries] = useState<SearchItem[]>()
  const [postcodes, setPostcodes] = useState<SearchItem[]>()
  const [selectedSurgery, setSelectedSurgery] = useState<SearchItem>()
  const [selectedPostcode, setSelectedPostcode] = useState<SearchItem>()
  const [getPostcode] = useMutation(PostcodeLookupMutation)
  const { updateGpMutation, loading } = useUpdateGpInfo()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const onCancel = () => {
    history.push(setupRoutes.nextSteps)
  }

  const updateMutation = (surgeryName: string, postcode: string) =>
    updateGpMutation({
      variables: {
        id: currentUserId,
        formData: {
          gp_surgery_name: surgeryName,
          gp_address_post_code: postcode,
        },
      },
    })
      .then(() => history.push(setupRoutes.nextSteps))
      .catch(logException)

  const handleSubmit = (e) => {
    e.preventDefault()

    const hasSurgeriesButNoneSelected = surgeries && !selectedSurgery
    const surgeryName = surgeryNameInputRef.current?.value || ''
    const hasPostcodesButNoneSelected = postcodes && !selectedPostcode
    const postcode = postcodeInputRef.current?.value || ''
    const invalidPostcode = !new RegExp(UK_POSTCODE_REGEX).test(postcode)
    const isIneligibleSurgery = INELIGIBLE_PRACTISE_NAMES.some((item) =>
      surgeryName.toLowerCase().includes(item)
    )

    if (!surgeryName || hasSurgeriesButNoneSelected) {
      return setError(t(messages.invalidSurgeryName))
    }

    if (invalidPostcode || hasPostcodesButNoneSelected) {
      return setError(t(messages.invalidPostcode))
    }

    if (isIneligibleSurgery) {
      setIsModalOpen(true)

      return undefined
    }

    return updateMutation(surgeryName, postcode)
  }

  const consentAndSubmit = () => {
    setIsModalOpen(false)

    if (
      !!surgeryNameInputRef.current?.value &&
      !!postcodeInputRef.current?.value
    ) {
      return updateMutation(
        surgeryNameInputRef.current?.value,
        postcodeInputRef.current?.value
      )
    }

    return undefined
  }

  const searchForSurgery = async (searchValue: string) => {
    setSelectedSurgery(undefined)
    setSelectedPostcode(undefined)
    setPostcodes(undefined)
    setError(undefined)

    if (postcodeInputRef.current) {
      postcodeInputRef.current.value = ''
    }

    if (searchValue.trim().length < MIN_SEARCH_LENGTH) {
      setSurgeries(undefined)

      return undefined
    }

    const response = await fetch(
      `${SURGERY_URL}?Name=${encodeURIComponent(searchValue)}`
    )
    const data = await response.json()
    const parsedSurgeries = parseSurgeryData(data)
    const uniqueSurgeries = removeDuplicates(parsedSurgeries)

    if (uniqueSurgeries.length > 0) {
      setSurgeries(uniqueSurgeries)
    } else {
      setError(t(messages.invalidSurgeryName))
    }

    return uniqueSurgeries
  }

  const searchForPostcode = async (searchValue: string) => {
    setSelectedPostcode(undefined)
    setPostcodes(undefined)
    setError(undefined)

    if (!UK_POSTCODE_REGEX.test(searchValue)) {
      return undefined
    }

    try {
      const response = await getPostcode({
        variables: { postcode: searchValue },
      })
      const postCodeData = response?.data?.postcodeLookup
      const addresses = postCodeData?.addresses

      if (addresses.length > 0) {
        setPostcodes(
          addresses.map((address) => ({
            name: postCodeData?.postcode,
            displayName: `${postCodeData?.postcode} - ${address}`,
            postcode: postCodeData?.postcode,
          }))
        )
      } else {
        setError(t(messages.invalidPostcode))
      }
    } catch {
      setError(t(messages.invalidPostcode))
    }

    return undefined
  }

  return (
    <form className={styles.gpInfoForm} onSubmit={handleSubmit}>
      <SearchInput
        ref={surgeryNameInputRef}
        label={t(messages.surgeryName)}
        searchItems={surgeries}
        onSearch={(searchValue: string) => searchForSurgery(searchValue)}
        onSelect={(searchItem: SearchItem) => {
          setSelectedSurgery(searchItem)
          setSelectedPostcode(searchItem)
          setSurgeries(undefined)

          if (surgeryNameInputRef?.current) {
            surgeryNameInputRef.current.value = searchItem.name
          }

          if (postcodeInputRef?.current) {
            postcodeInputRef.current.value = searchItem.postcode
          }
        }}
      />
      <SearchInput
        ref={postcodeInputRef}
        label={t(messages.surgeryPostcode)}
        searchItems={postcodes}
        disabled={!!selectedSurgery}
        onSearch={(searchValue: string) => searchForPostcode(searchValue)}
        onSelect={(searchItem: SearchItem) => {
          setSelectedPostcode(searchItem)
          setPostcodes(undefined)

          if (postcodeInputRef?.current) {
            postcodeInputRef.current.value = searchItem.postcode
          }
        }}
      />

      {error && <p className={styles.error}>{error}</p>}

      {isModalOpen && (
        <Modal testId="side-overlay" open={isModalOpen} avoidClose>
          <div className={styles.header}>
            <div className={styles.gpInfoDisclaimer}>
              <p>
                <b>{t(messages.gpDisclaimerTitle)}</b>
              </p>
              <p>{t(messages.gpDisclaimerBodyFirstParagraph)}</p>
              <p>{t(messages.gpDisclaimerBodySecondParagraph)}</p>
              <p>{t(messages.gpDisclaimerBodyThirdParagraph)}</p>
            </div>

            <Button onClick={consentAndSubmit} data-testid="Continue">
              {t(messages.continue)}
            </Button>
          </div>
        </Modal>
      )}

      <div className={styles.footer}>
        <Button onClick={onCancel} secondary>
          {t(messages.skipButton)}
        </Button>
        <Button onClick={handleSubmit} loading={loading}>
          {t(messages.submitButton)}
        </Button>
      </div>
    </form>
  )
}

export default GpInfoForm
