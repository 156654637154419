import React from 'react'
import cn from 'classnames'

import styles from './Box.module.scss'

export type Space =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'

interface Props {
  children: React.ReactNode
  padding?: Space
  paddingX?: Space
  paddingY?: Space
  margin?: Space
  marginX?: Space
  marginY?: Space
  className?: string
  [key: string]: any
}

const Box = ({
  children,
  padding,
  paddingX,
  paddingY,
  margin,
  marginX,
  marginY,
  className,
  ...props
}: Props) => {
  const classNames = cn(
    padding && styles[`padding--${padding}`],
    paddingX && styles[`paddingX--${paddingX}`],
    paddingY && styles[`paddingY--${paddingY}`],
    margin && styles[`margin--${margin}`],
    marginX && styles[`marginX--${marginX}`],
    marginY && styles[`marginY--${marginY}`],
    className
  )

  return (
    <div {...props} className={classNames}>
      {children}
    </div>
  )
}

export default Box
