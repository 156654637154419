import React from 'react'
import { Box, Heading } from '@babylon/web-platform-ui'
import { Spinner } from '@babylon/medkit'

interface ConnectingChatSpinnerProps {
  heading: string
  isWebViewMode: boolean
}

const ConnectingChatSpinner = ({
  heading,
  isWebViewMode,
}: ConnectingChatSpinnerProps) => (
  <Box height="80vh">
    <Heading
      textAlign="center"
      paddingTop="35vh"
      size="md"
      paddingBottom="1rem"
    >
      {heading}
    </Heading>
    <Spinner variant="primary" center size={isWebViewMode ? 36 : undefined} />
  </Box>
)

export default ConnectingChatSpinner
