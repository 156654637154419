/**
 * Measure page views
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 *
 * NOTE:
 *
 * this method of "resetting Gtag.js configuration" with `page_title` and `page_path`
 * to send page views to GA is specific to and necessary in case of Single Page Apps
 * as there would be no page view events automatically fired by Gtag.js otherwise
 * due to the nature of SPAs.
 *
 * Custom dimensions setup:
 *
 * the `config` command basically re-configures Gtag.js, it also resets
 * the Custom Dimension mapping, therefore each time we fire a page view event
 * the mapping needs to be added to the configuration object,
 * otherwise the custom dimensions will not be sent along with any events.
 */
import getPageName from './getPageName'
import { KnownUris, CustomDimensionsMap } from './types'

export type PageViewTracker = (path: string) => void

export default (
  gtag: Gtag.Gtag,
  GA_MEASUREMENT_ID: string,
  knownUris: KnownUris,
  customMap?: CustomDimensionsMap
): PageViewTracker => (path) => {
  const pageName = getPageName(path, knownUris)

  if (!pageName) {
    return
  }

  gtag('config', GA_MEASUREMENT_ID, {
    page_title: pageName,
    page_path: path,
    custom_map: customMap,
  })
}
