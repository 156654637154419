import React, { useEffect, useRef } from 'react'

import { IMedkitComponent } from '..'
import stepperTheme from './theme'
import Step from './Step'
import styles from './Stepper.module.scss'

export const Stepper: IMedkitComponent<IProps, typeof stepperTheme> = ({
  steps,
  activeStep,
  focusOnLoad,
  preventInaccessibleFocus = false,
}: IProps) => {
  const activeRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (activeRef.current && activeStep !== 0 && focusOnLoad) {
      activeRef.current?.focus()
    }
  }, [activeStep, focusOnLoad])

  return (
    <ol className={styles.Stepper}>
      {steps.map((step, index) => (
        <Step
          key={step}
          label={step}
          index={index}
          active={activeStep === index}
          complete={activeStep > index}
          activeRef={activeRef}
          preventInaccessibleFocus={preventInaccessibleFocus}
        />
      ))}
    </ol>
  )
}

Stepper.theme = stepperTheme

interface IProps extends React.HTMLProps<HTMLDivElement> {
  /** Steps to display */
  steps: string[]
  /** Active step to highlight */
  activeStep: number
  /** Focus the stepper with screen reader when page loads */
  focusOnLoad?: boolean
  /** It's generally considered inaccessible to focus on a non interactive element.
   *  I'm unsure why the original authors made these steps tab focusable so let's just put in a flag to disable it.
   */
  preventInaccessibleFocus?: boolean
}

export default Stepper
