import React, { FC } from 'react'
import { VictoryAxis, VictoryChart } from 'victory'
import cn from 'classnames'
import style from './WeightLossChart.module.scss'
import {
  Answers,
  Interstitial,
  Measurements,
} from '@/programmes/components/FormV2/types'
import {
  convertImperialWeightToMetric,
  convertMetricWeightToImperial,
} from '@/programmes/utils/convertHeightAndWeight'
import { formatString } from '@/programmes/utils/formatString'
import { useWeightLossPercentage } from '@/redux/selectors'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import Avatar, {
  AvatarProps,
} from '@/programmes/components/FormV2/components/Avatar/Avatar'

export interface WeightLossChartProps {
  interstitial: Interstitial
  title?: string
  body?: string
  avatar: AvatarProps
  answers: Answers
  heightAndWeightQuestionId: string
}

const getNextMonths = (startMonth: number): string[] => {
  const months: string[] = []
  const currentDate = new Date()
  const startYear =
    currentDate.getMonth() > startMonth
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear()

  for (let i = 0; i <= 4 * 2; i += 2) {
    const futureDate = new Date(startYear, startMonth + i, 1)
    const monthName = new Intl.DateTimeFormat('en-GB', {
      month: 'short',
    }).format(futureDate)
    months.push(monthName)
  }

  return months
}

function calculateWeightLoss(
  initialWeight: number,
  maxWeightLoss: number
): number[] {
  const weights: number[] = [initialWeight]

  for (let i = 1; i <= 3; i++) {
    weights.push(
      Math.round(initialWeight - initialWeight * i * (maxWeightLoss / 2))
    )
  }

  return weights
}

const WeightLossChart: FC<WeightLossChartProps> = ({
  interstitial,
  title,
  body,
  avatar,
  answers,
  heightAndWeightQuestionId,
}) => {
  const { id } = interstitial
  const useWeightLossPercentageConfig = useWeightLossPercentage()
  const weightLossPercentage = useWeightLossPercentageConfig
    ? Number(useWeightLossPercentageConfig)
    : 0.1
  const { weight } = answers[heightAndWeightQuestionId] as Measurements
  const isImperial = !!weight.unit
  const weightMetric = isImperial
    ? Math.round(
        convertImperialWeightToMetric(
          Number(weight.stones),
          Number(weight.pounds)
        )
      )
    : Number(weight.kg)
  const currentMont = new Date().getMonth()
  const xAxes = getNextMonths(currentMont)
  const yAxes = calculateWeightLoss(weightMetric, weightLossPercentage)
  const maxY = Math.max(...yAxes)
  const minY = Math.min(...yAxes)
  const imperialWeightLoss = convertMetricWeightToImperial(
    weightLossPercentage * weightMetric
  )
  const weightLoss = isImperial
    ? `${imperialWeightLoss.stones} st ${imperialWeightLoss.pounds} lb`
    : `${(weightMetric * weightLossPercentage).toFixed(1).toString()} kg`

  return (
    <div className={style.container}>
      <Avatar imageSrc={avatar.imageSrc} alt={avatar.alt} />

      {title && <QuestionTitle content={title} id={id} type="header" />}

      {body && (
        <QuestionBody
          body={formatString(body, { weight: weightLoss })}
          id={id}
        />
      )}
      <div
        className={cn(style.wrapper, {
          [style.imperialWrapper]: isImperial,
          [style.metricWrapper]: !isImperial,
        })}
      >
        <VictoryChart
          height={210}
          padding={{
            top: 20,
            bottom: 36,
            left: isImperial ? 60 : 50,
            right: 0,
          }}
          domainPadding={20}
        >
          <VictoryAxis
            tickValues={xAxes}
            fixLabelOverlap
            style={{
              axis: { stroke: 'none' },
            }}
          />
          <VictoryAxis
            dependentAxis
            fixLabelOverlap
            style={{
              axis: { stroke: 'none' },
              grid: {
                stroke: '#ddd',
                strokeWidth: 0.5,
                strokeDasharray: '10,5',
              },
            }}
            tickValues={yAxes}
            tickFormat={(t) =>
              isImperial
                ? `${convertMetricWeightToImperial(t).stones}st ${
                    convertMetricWeightToImperial(t).pounds
                  }lb`
                : `${t}kg`
            }
            domain={[minY, maxY]}
          />
        </VictoryChart>
      </div>
    </div>
  )
}

export default WeightLossChart
