import React, { useState, FC, PropsWithChildren } from 'react'
import { BabylonIntl } from '@babylon/intl'

import LocaleContext from './LocaleContext'
import getMessagesOnLocaleChange from './getMessagesOnLocaleChange'

interface Props {
  defaultLocale?: string
}
type IntlProviderProps = PropsWithChildren<Props>

const IntlProvider: FC<IntlProviderProps> = ({
  children,
  defaultLocale = 'en-GB',
}: IntlProviderProps) => {
  const [locale, setLocale] = useState(defaultLocale)

  return (
    <BabylonIntl
      initialLocale={locale}
      textComponent="span"
      defaultLocale="en-GB"
      getMessagesOnLocaleChange={getMessagesOnLocaleChange}
    >
      <LocaleContext.Provider value={{ locale, setLocale }}>
        {children}
      </LocaleContext.Provider>
    </BabylonIntl>
  )
}

export default IntlProvider
