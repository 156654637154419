import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import classnames from 'classnames/bind'

import Input from '../Input'
import { getSelectOptions, getCreatableItemProps } from './utils'
import styles from './Autosuggest.module.scss'

const cx = classnames.bind(styles)

const Suggestion = ({ item, inputValue }) => {
  if (item.__creatable) {
    return `Add ${inputValue}`
  }

  return (
    <div>
      <div className={cx('autoSelect__suggestion__label')}>{item.label}</div>
      {item.description && (
        <div className={cx('autoSelect__suggestion__description')}>
          {item.description}
        </div>
      )}
    </div>
  )
}

const AutoSuggest = ({
  hideLabel,
  inputValue,
  isCreatable,
  isLoading,
  isOpen,
  label,
  onInputChange,
  onSelect,
  options: _options,
  placeholder,
}) => (
  <Downshift
    onChange={onSelect}
    itemToString={(item) => (item ? item.label : '')}
    isOpen={isOpen}
    inputValue={inputValue}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex,
    }) => {
      const options = isCreatable
        ? getSelectOptions(_options, inputValue)
        : _options

      return (
        <fieldset
          className={cx('autoSelect', {
            'autoSelect--open': isOpen && options.length > 0,
            'autoSelect--hideLabel': hideLabel,
          })}
        >
          <Input
            {...getInputProps({
              onChange: onInputChange,
              loading: isLoading,
              label,
              labelProps: getLabelProps(),
              placeholder,
            })}
          />
          {isOpen && (
            <ul {...getMenuProps({ className: cx('autoSelect__suggestions') })}>
              {options.map((item, index) => (
                <li
                  key={item.id || item.label}
                  {...getItemProps({
                    index,
                    item,
                    className: cx('autoSelect__suggestion', {
                      'autoSelect__suggestion--selected':
                        highlightedIndex === index,
                    }),
                    ...getCreatableItemProps(item, inputValue),
                  })}
                >
                  <Suggestion item={item} inputValue={inputValue} />
                </li>
              ))}
            </ul>
          )}
        </fieldset>
      )
    }}
  </Downshift>
)

AutoSuggest.propTypes = {
  /** The options to display in the select dropdown. You can pass more fields but these are the required ones */
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  /** What to do when the input changes (e.g. fetch more results) */
  onInputChange: PropTypes.func.isRequired,
  /** What to do when a value is selected - Gets the whole option object as an argument */
  onSelect: PropTypes.func.isRequired,
  /** Is the input loading e.g. fetching results */
  isLoading: PropTypes.bool,
  /** The label for the autoselect input */
  label: PropTypes.string.isRequired,
  /** Whether to hide the label (is still in page for accessibility reasons, but not visible) */
  hideLabel: PropTypes.bool,
  /** Force the dropdown select to be open/closed - NOTE: This makes the component controlled and so must always be true/false */
  isOpen: PropTypes.bool,
  /** The value of the text input - NOTE: This makes the component controlled and so must always be set */
  inputValue: PropTypes.string,
  /** The placeholder text for the input */
  placeholder: PropTypes.string,
  /** If a matching item doesn't exist show an item which calls onSelect with current inputValue */
  isCreatable: PropTypes.bool,
}

export default AutoSuggest
