import { useCallback, useState } from 'react'
import { logException } from '@babylon/sentry'
import { getAuthToken } from '@babylon/auth0'
import { getHeaders } from '@babylon/shared-utils'
import { VERSION } from '@/config'
import { useCurrentUserId } from '@/redux/selectors'
import { saveQuestionnaireTimestamp } from '@/programmes/WeightLoss/hooks/useHandlePageVisitAndRedirection'
import { formatQuestionnairePayload } from '../utils/formatQuestionnairePayload'
import { Answer } from '../types'
import { ProductType } from '@/programmes/components/Products/models'

// the question below was removed from the questionnaire and moved to the checkout page
// but to generate the same questionnaire payload we need to manually add it to the payload
// see: https://babylonpartners.atlassian.net/browse/GROW-108
const getPayload = (productType, steps, answers) => {
  if (productType) {
    const productPickerStep = {
      id: 'bcbd6688-ca59-11ee-945a-1f43caaf4364',
      type: 'question',
      title: 'Please select the treatment you would like',
      inputType: { component: 'radioGroup' },
      options: [
        { label: 'Wegovy®', value: ProductType.Wegovy },
        { label: 'Mounjaro®', value: ProductType.Mounjaro },
      ],
    }

    const extendedSteps = [...steps, productPickerStep]
    const extendedAnswers = {
      ...answers,
      [productPickerStep.id]: { value: productType },
    }

    return formatQuestionnairePayload(extendedSteps, extendedAnswers)
  }

  return formatQuestionnairePayload(steps, answers)
}

type SubmissionState = 'Initial' | 'Loading' | 'Success' | 'Error'

const useSubmitFormData = (url, steps) => {
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<{
    [questionId: string]: Answer
  }>({})
  const [state, setState] = useState<SubmissionState>('Initial')
  const error = state === 'Error'
  const success = state === 'Success'
  const loading = state === 'Loading'

  const currentUserId = useCurrentUserId()

  const submitData = useCallback(
    async (
      answers?: { [questionId: string]: Answer },
      productType?: ProductType
    ) => {
      try {
        setState('Loading')

        const payload = getPayload(productType, steps, answers)
        const authToken = await getAuthToken()
        const result = await fetch(url, {
          method: 'POST',
          headers: {
            ...getHeaders({
              appId: 'babylon',
              appVersion: VERSION,
              acceptLanguage: 'en-GB',
            }),
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(payload),
        })

        if (!result.ok) {
          setState('Error')
          return false
        }

        setState('Success')

        if (answers) {
          setQuestionnaireAnswers(answers)
        }

        saveQuestionnaireTimestamp(currentUserId)
      } catch (err) {
        setState('Error')
        logException(err)
        return false
      }
      return true
    },
    [steps, url, currentUserId, setState]
  )

  return { submitData, loading, success, error, questionnaireAnswers }
}

export default useSubmitFormData
