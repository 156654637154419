import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { MEASUREMENT_UNIT, Weight } from '@/programmes/components/Form/types'
import { getBMIChangeFromKgValues } from '@/programmes/WeightLoss/utils/assessBMI'
import {
  convertMetricWeightToPounds,
  weightInMetric,
} from '@/programmes/utils/convertHeightAndWeight'
import styles from '../../index.module.scss'
import Button from '@/programmes/components/Button'
import Loading from '@/components/Loading'
import messages from '../../messages'
import useGetWeightMetrics from '@/programmes/hooks/useGetWeightMetrics'
import useCompleteAnimation from '@/programmes/hooks/useCompleteAnimation'
import { RAG, WeightMetric } from '@/programmes/WeightLoss/types'

type Props = {
  weight: Weight
  onComplete: () => void
}

const cx = classNames.bind(styles)

const WeightChangeFeedbackLayout: React.FC<Props> = ({
  weight,
  onComplete,
}) => {
  const t = useFormatMessage()
  const { data, loading } = useGetWeightMetrics()
  const { AnimationScreen, setIsPlaying } = useCompleteAnimation(false)
  const [weightChange, setWeightChange] = useState<WeightMetric | undefined>()

  useEffect(() => {
    if (data && weight) {
      const { weightMetrics, height } = data
      const lastWeight = weightMetrics[1]?.weight

      if (!height) return

      const weightKg = weightInMetric(weight)
      const bmiChangeRag = getBMIChangeFromKgValues(
        weightKg,
        lastWeight,
        Number(height.cm)
      )

      if (bmiChangeRag?.bmi.changeRag === RAG.GREEN) {
        setWeightChange(bmiChangeRag)
      } else {
        onComplete()
      }
    }
  }, [data, onComplete, weight])

  const getWeightChangeCard = () => {
    let weightLossAbsolute = Math.abs(weightChange?.weightLoss || 0)

    if (weight.unit === MEASUREMENT_UNIT.IMPERIAL) {
      weightLossAbsolute = convertMetricWeightToPounds(weightLossAbsolute)
    }

    const weightChangeText = `${t(messages.weightLayoutWeightDown)} ${Number(
      weightLossAbsolute.toFixed(1)
    )} ${weight.unit === MEASUREMENT_UNIT.IMPERIAL ? 'lbs' : 'kg'}`

    return (
      <div>
        <>
          <AnimationScreen onAnimationFinish={() => setIsPlaying(false)} />
        </>
        <h2 data-testid="weight-change-text">{weightChangeText}</h2>
        <p data-testid="weight-change-keep-it-up-text">
          {t(messages.weightLayourKeepItUp)}
        </p>
        <div className={cx('weight-entry-next-step-button')}>
          <Button onClick={onComplete}>
            {t(messages.weightLayoutNextStep)}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      {loading && <Loading small />}
      {data && getWeightChangeCard()}
    </>
  )
}

export default WeightChangeFeedbackLayout
