import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import Template from '../Template'
import { refillRoutes } from '../routes'
import { WEIGHT_LOSS_REFILL_URL } from '@/config'
import useIsOutOfTheAgeRangeCheck from '@/programmes/WeightLoss/hooks/useIsOutOfTheAgeRangeCheck'
import Spinner from '@/programmes/components/Spinner'
import ExitView from '@/programmes/components/Form/components/ExitView'
import refillSteps from '@/programmes/WeightLoss/steps/refillSteps'
import useSubmitFormData from '../../components/Form/hooks/useSubmitFormData'
import Form from '../../components/Form'
import messages from '../messages'

const RefillQuestionnairePage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const { search } = useLocation()
  const {
    loading: outOfTheAgeRangeLoading,
    outOfTheAgeRange,
  } = useIsOutOfTheAgeRangeCheck()
  const { submitData, loading, success, error } = useSubmitFormData(
    WEIGHT_LOSS_REFILL_URL,
    refillSteps
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    if (success) {
      history.push(
        searchParams.get('check-in') === 'true'
          ? refillRoutes.nextSteps
          : `${refillRoutes.confirmation}?check-in=false`
      )
    }
  }, [history, success, search])

  return (
    <Template title={t(messages.refillQuestionnairePageTitle)}>
      {outOfTheAgeRangeLoading && <Spinner colour="#000000" />}

      {!outOfTheAgeRange && !outOfTheAgeRangeLoading && (
        <Form
          steps={refillSteps}
          onSubmit={submitData}
          loading={loading}
          error={error}
        />
      )}

      {outOfTheAgeRange && !outOfTheAgeRangeLoading && <ExitView />}
    </Template>
  )
}

export default RefillQuestionnairePage
