/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  useModalActions,
  ButtonSimple,
  ThemeProvider,
  Text,
} from '@babylon/medkit'
import Avatar from '@/components/Avatar'

import styles from '../VideoCall.module.scss'
import messages from '../VideoCall.messages'

const IncomingCallModal = ({
  consultant,
  answer,
  decline,
  showIncomingCall,
}) => {
  const intl = useIntl()
  const { openModal, closeModal } = useModalActions()
  const [isModalOpen, setModalOpen] = useState(false)

  const acceptCall = async () => {
    await answer()
    setModalOpen(false)
    closeModal()
  }

  const declineCall = async () => {
    await decline()
    setModalOpen(false)
    closeModal()
  }

  const IncomingModalContent = () => (
    <>
      <div className={styles.caller}>
        <div className={styles.caller__imageWrapper}>
          <Avatar
            desc={consultant?.name}
            src={consultant?.avatar}
            size="l"
            isSpecialist
            className={styles.caller__image}
          />
        </div>
        <Text
          id="modal-title"
          className={styles.caller__title}
          variant="body"
          tag="h1"
        >
          {consultant?.name
            ? consultant?.name
            : `${intl.formatMessage(
                messages.incomingCallModalConsultant
              )} ${intl.formatMessage(messages.incomingCallModalCalling)}`}
        </Text>
        <Text
          className={styles.caller__a11y}
          variant="body"
          tag="span"
          id="modal-content"
        >
          {intl.formatMessage(
            messages.incomingCallModalA11YHiddenAcceptOrDecline
          )}
        </Text>
      </div>
      {/* Two theme providers are needed for different colour buttons */}
      <div className={styles.caller__actions}>
        <ThemeProvider
          theme={{
            '--medkit-button-color-one': '#008535',
            '--medkit-button-color-two': 'transparent',
          }}
        >
          <ButtonSimple className={styles.caller__action} onClick={acceptCall}>
            {intl.formatMessage(messages.incomingCallModalAnswerCall)}
          </ButtonSimple>
        </ThemeProvider>
        <ThemeProvider
          theme={{
            '--medkit-button-color-one': '#d53232',
            '--medkit-button-color-two': 'transparent',
          }}
        >
          <ButtonSimple className={styles.caller__action} onClick={declineCall}>
            {intl.formatMessage(messages.incomingCallModalDeclineCall)}
          </ButtonSimple>
        </ThemeProvider>
      </div>
    </>
  )

  if (!isModalOpen && showIncomingCall) {
    setModalOpen(true)
    openModal(<IncomingModalContent />, {
      isDismissable: false,
      role: 'alertdialog',
    })
  } else if (isModalOpen && !showIncomingCall) {
    setModalOpen(false)
    closeModal()
  }

  return null
}

IncomingCallModal.propTypes = {
  showIncomingCall: PropTypes.bool.isRequired,
  consultant: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  answer: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
}

export default IncomingCallModal
