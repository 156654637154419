import {
  ClinicalAppointment,
  AppointmentInvite,
} from '@babylon/consumer-api/types'
import * as Constants from './constants'
import { InitialState as ReducerInitialState } from './reducer'
import { ValueOf } from './types'

const {
  BEGIN_BOOKING,
  CLEAR_SELECTION,
  COMPLETE_BOOKING,
  CONFIRM_NEEDS,
  SELECT_CLINICIAN_TYPE,
  SELECT_LOCATION,
  SELECT_SLOT,
  UNDO_LAST_CONFIRMATION,
  SELECT_MEDIUM,
} = Constants

const creator = <Y extends {}>() => <K extends ValueOf<typeof Constants>>(
  type: K
) => (payload: Y) => ({ type, payload })

export const beginBooking = creator<{
  invite: AppointmentInvite
  inviteId: string
}>()(BEGIN_BOOKING)

export const clearSelection = creator<string[]>()(CLEAR_SELECTION)

export const completeBooking = creator<{ appointment: ClinicalAppointment }>()(
  COMPLETE_BOOKING
)
export const confirmNeeds = creator<{
  invite: AppointmentInvite
  inviteId: string
}>()(CONFIRM_NEEDS)

type Options = { options: ReducerInitialState['bookingChoices'] }

export const selectLocation = creator<
  Options & { inviteId: string; nextRouteId: string }
>()(SELECT_LOCATION)

export const selectSlot = creator<
  Options & { inviteId: string; nextRouteId: string }
>()(SELECT_SLOT)

export const selectMedium = creator<
  Options & { inviteId: string; invite: AppointmentInvite }
>()(SELECT_MEDIUM)

export const selectClinicianType = creator<Options & { inviteId: string }>()(
  SELECT_CLINICIAN_TYPE
)
export const undoLastConfirmation = creator()(UNDO_LAST_CONFIRMATION)
