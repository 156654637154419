import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

const updateCache = ({
  propertyName,
  query,
  userId,
  noticeVersionId,
}: {
  propertyName: string
  query: string | DocumentNode
  userId: string
  noticeVersionId: string
}) => (proxy: any, response: any) => {
  const {
    data: { privacyPolicyDecision },
  } = response
  const cacheData = proxy.readQuery({
    query,
    variables: { id: userId },
  })
  const newData = {
    ...cacheData,
    patient: {
      ...cacheData.patient,
      [propertyName]: cacheData.patient[propertyName].map((notice: any) => {
        if (notice.noticeVersionId === noticeVersionId) {
          return {
            ...notice,
            accepted: privacyPolicyDecision.accepted,
          }
        }

        return notice
      }),
    },
  }
  proxy.writeQuery({
    query,
    variables: { id: userId },
    data: newData,
  })
}

const PrivacyPolicyDecision = gql`
  mutation privacyPolicyDecision(
    $decision: Boolean!
    $noticeVersionId: String!
  ) {
    privacyPolicyDecision(
      decision: $decision
      noticeVersionId: $noticeVersionId
    ) {
      accepted
    }
  }
`

export { updateCache }
export default PrivacyPolicyDecision
