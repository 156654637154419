import {
  createInterstitialComponent,
  SimpleInterstitial,
  WeightLossChart,
  AutoTransitionInterstitial,
} from '@/programmes/components/FormV2/components/RenderInterstitial'
import { Step } from '@/programmes/components/FormV2/types'
import drMazAlsaffarAvatar from '@/assets/images/dr-maz-alsaffar.jpg'
import {
  ethnicityHandler,
  heightAndWeightHandler,
  collectEmailHandler,
} from './nextHandlers'

export const HEIGHT_AND_WEIGHT_QUESTION_ID =
  '04a3b4a4-ca58-11ee-9152-6f3377fdbff7'
export const WEIGHT_RELATED_ILLNESS_QUESTION_ID =
  '91c7651a-ca58-11ee-a00f-ef21372b1c99'
export const ETHNICITY_QUESTION_ID = '7dcdef52-fe29-11ee-b517-8fdafa4b4fa4'
export const EMAIL_QUESTION_ID = 'b39783c3-e9fb-475a-8c64-38d1f7dda728'
export const WEIGHT_LOSS_PLAN_QUESTION_ID =
  '935acf93-73a1-4a82-91f0-19434c09a9e5'
export const QUESTIONNAIRE_SEX_QUESTION_ID =
  '62be2107-f8a0-4ac9-b1d7-4e901a623732'

const preScreeningStepsV2: Array<Step> = [
  {
    id: '8893f8e8-f66b-11ee-afb5-3331515d4d7c',
    type: 'interstitial',
    render: createInterstitialComponent(AutoTransitionInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
        name: 'Dr Maz Alsaffar',
        title: 'Primary Care Physician',
      },
      body:
        'Welcome to the start of your weight management journey. The initial assessment will take about 10 minutes.',
    }),
    autoTransitionSeconds: 4,
    next: HEIGHT_AND_WEIGHT_QUESTION_ID,
  },
  {
    id: HEIGHT_AND_WEIGHT_QUESTION_ID,
    type: 'question',
    required: true,
    title: "Let's start with where you are",
    body:
      'Enter your height and weight to find out what your projected weight loss could be with eMed.\n \n Your BMI, any weight related medical conditions and ethnicity will impact your eligibility.',
    inputType: {
      component: 'heightAndWeight',
    },
    nextButtonText: 'Find out here',
    nextHandler: heightAndWeightHandler,
    dataPoints: [
      {
        type: 'input',
        name: 'height',
      },
      {
        type: 'input',
        name: 'weight',
      },
    ],
  },
  {
    id: WEIGHT_RELATED_ILLNESS_QUESTION_ID,
    type: 'question',
    title: 'Do you have a weight-related illness?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Polycystic ovarian syndrome (PCOS)',
        value: 'Polycystic ovarian syndrome (PCOS)',
      },
      {
        label: 'Type 2 diabetes',
        value: 'Type 2 diabetes',
      },
      {
        label: 'High blood pressure - diagnosed with hypertension',
        value: 'High blood pressure - diagnosed with hypertension',
      },
      {
        label: 'Heart disease',
        value: 'Heart disease',
      },
      {
        label:
          'Dyslipidemia - unhealthy levels of one or more lipids (cholesterol, triglycerides)',
        value:
          'Dyslipidemia - unhealthy levels of one or more lipids (cholesterol, triglycerides)',
      },
      {
        label: 'Asthma / COPD',
        value: 'Asthma / COPD',
      },
      {
        label: 'Obstructive sleep apnoea',
        value: 'Obstructive sleep apnoea',
      },
      {
        label: 'Another illness that has affected your weight not listed above',
        value: 'Another illness that has affected your weight not listed above',
        followUpQuestion: {
          id: '87cf92a8-101a-4dcb-8489-94b7f84fc0b7',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: ETHNICITY_QUESTION_ID,
  },
  {
    id: ETHNICITY_QUESTION_ID,
    type: 'question',
    title: 'Are you in one of the following ethnic groups?',
    header:
      "We're asking this because people from some ethnic backgrounds have a higher risk of weight-related diseases and therefore may be eligible for weight loss treatment with a lower BMI.",
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Asian or Asian British',
        value: 'Asian or Asian British',
        body:
          'Includes any Asian background, for example, Bangladeshi, Chinese, Indian or Japanese',
      },
      {
        label: 'Black, African, Caribbean or Black British',
        value: 'Black, African, Caribbean or Black British',
        body: 'Includes any other Black background',
      },
      {
        label: 'Middle Eastern',
        value: 'Middle Eastern',
        body:
          'Includes any Middle Eastern background, for example, Arab or Iranian',
      },
      {
        label:
          'Mixed or multiple ethnicities with an Asian, Black or Middle Eastern background',
        value:
          'Mixed or multiple ethnicities with an Asian, Black or Middle Eastern background',
        body:
          'For example, White and Black Caribbean or Black British and Chinese',
      },
      {
        label: 'White',
        value: 'White',
        body:
          'Includes any White background, for example, British or Irish traveller',
      },
      {
        label: 'Other ethnic group',
        value: 'Other ethnic group',
        body: 'Includes any other ethnic background',
      },
      { label: 'Prefer not to say', value: 'Prefer not to say' },
    ],
    nextHandler: ethnicityHandler,
  },
  {
    id: EMAIL_QUESTION_ID,
    type: 'question',
    title: 'Your personalised weight loss plan',
    body: 'Enter your email address below to see your projected weight loss',
    required: true,
    inputType: {
      component: 'collectEmail',
    },
    nextButtonText: 'Submit',
    nextHandler: collectEmailHandler,
  },
  {
    id: WEIGHT_LOSS_PLAN_QUESTION_ID,
    type: 'interstitial',
    nextButtonText: 'Continue',
    render: createInterstitialComponent(WeightLossChart, {
      title: 'Your personalised weight loss plan',
      body:
        'Based on your current height and weight, eMed® believes you can lose {{weight}}s in the first 6 months of your weight loss journey.',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      heightAndWeightQuestionId: HEIGHT_AND_WEIGHT_QUESTION_ID,
    }),
    next: '6f5f56f2-fc0a-11ee-90fb-1390f1f51ab6',
  },
  {
    id: '6f5f56f2-fc0a-11ee-90fb-1390f1f51ab6',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        "In order to support you in the best way possible and help you to achieve your weight loss goals, we need to ask you some questions about your medical history to determine if you're eligible for treatment.",
    }),
    next: 'c2ca0ece-ca58-11ee-a7b8-6729b5205ead',
  },
  {
    id: 'c2ca0ece-ca58-11ee-a7b8-6729b5205ead',
    type: 'question',
    title: 'Do you have any of the following medical conditions?',
    required: true,
    body: [
      'Type 1 diabetes',
      'Type 2 diabetes on insulin or another GLP1',
      'Diabetic retinopathy',
      'Gastroparesis (delayed gastric emptying)',
      'History of pancreatitis',
      'Personal history of thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia (MEN)',
      'Family history of thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia (MEN) type 2',
      'History of organ transplant or on immunosuppressant (anti-rejection) medication',
      'Active cancer or on chemotherapy',
      'End-stage kidney disease (dialysis)',
      'End-stage liver disease (cirrhosis)',
      'Serious hypersensitivity reaction to semaglutide/tirzepatide or to any of the excipients in Wegovy or Mounjaro',
      'Severe congestive heart failure',
      'A history of suicidal thoughts',
    ],
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [{ label: 'yes', nextAction: 'exit-action' }],
    next: 'a00c3ddc-fcba-11ee-8d06-9bb8d6ffdf0d',
  },
  {
    id: 'a00c3ddc-fcba-11ee-8d06-9bb8d6ffdf0d',
    type: 'question',
    title:
      'Do you currently suffer from an eating disorder such as anorexia nervosa, bulimia nervosa or binge eating disorder?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [{ label: 'yes', nextAction: 'exit-action' }],
    next: 'a2aed932-fcba-11ee-b630-cfd79d0112e7',
  },
  {
    id: 'a2aed932-fcba-11ee-b630-cfd79d0112e7',
    type: 'question',
    title:
      'Are you currently pregnant, planning to be pregnant in the next three months, or breastfeeding?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [{ label: 'yes', nextAction: 'exit-action' }],
    next: 'be07fa78-f266-11ee-8dec-d745fa3e7abc',
  },
  {
    id: 'be07fa78-f266-11ee-8dec-d745fa3e7abc',
    type: 'question',
    title: "Tell us why you want to join eMed's Weight Loss Programme:",
    body:
      "To help you develop long-lasting healthy habits, it's important for us to understand what motivates you to lose weight.",
    required: true,
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'To lose weight and keep it off',
        value: 'To lose weight and keep it off',
      },
      {
        label: 'To silence food noise and cravings',
        value: 'To silence food noise and cravings',
      },
      {
        label: 'To improve my mood and/or mental wellbeing',
        value: 'To improve my mood and/or mental wellbeing',
      },
      {
        label: 'To improve my overall health',
        value: 'To improve my overall health',
      },
      {
        label: 'To feel more confident about my appearance',
        value: 'To feel more confident about my appearance',
      },
      {
        label: 'To lose weight for a significant event',
        value: 'To lose weight for a significant event',
      },
      {
        label: 'For increased energy',
        value: 'For increased energy',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: 'a949c22c-f268-11ee-9bc2-bb006c6c4925',
  },
  {
    id: 'a949c22c-f268-11ee-9bc2-bb006c6c4925',
    type: 'question',
    title:
      'What are the biggest challenges you have faced when you have previously attempted to lose weight?',
    body:
      "To help you develop long-lasting healthy habits, it's important for us to understand what motivates you to lose weight.",
    required: true,
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'I lose weight then put it back on',
        value: 'I lose weight then put it back on',
      },
      {
        label: 'I do not have enough time',
        value: 'I do not have enough time',
      },
      {
        label: 'I find it difficult to be consistent',
        value: 'I find it difficult to be consistent',
      },
      {
        label: 'I lose motivation',
        value: 'I lose motivation',
      },
      {
        label: 'I found it difficult to focus at work',
        value: 'I found it difficult to focus at work',
      },
      {
        label: 'The cravings were too intense',
        value: 'The cravings were too intense',
      },
      {
        label: 'I felt exhausted all the time',
        value: 'I felt exhausted all the time',
      },
      {
        label: 'I was hungry all the time',
        value: 'I was hungry all the time',
      },
      {
        label: 'Other',
        value: 'Other',
        followUpQuestion: {
          id: 'a2fd3e70-f269-11ee-a532-37880f573784',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
    ],
    next: '82bf8334-88c9-4810-804b-3658612f08fc',
  },
  {
    id: '82bf8334-88c9-4810-804b-3658612f08fc',
    type: 'question',
    required: true,
    title: 'How motivated are you to achieve your weight loss goal?',
    body:
      "To help you develop long-lasting healthy habits, it's important for us to understand what motivates you to lose weight.",
    inputType: {
      component: 'slider',
    },
    settings: {
      min: 0,
      max: 10,
      step: 1,
      defaultValue: 8,
      markers: true,
      minLabel: 'Low',
      maxLabel: 'High',
    },
    next: 'acaff882-ca59-11ee-9db0-2fe4d05657a5',
  },
  {
    id: 'acaff882-ca59-11ee-9db0-2fe4d05657a5',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      title: 'Great news! You meet our initial requirements.',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body: 'You are now one step closer to starting your weight loss journey.',
    }),
    next: QUESTIONNAIRE_SEX_QUESTION_ID,
    loginGate: true,
  },
  {
    id: QUESTIONNAIRE_SEX_QUESTION_ID,
    type: 'question',
    title: 'What sex were you registered with at birth?',
    body:
      "We're collecting this information to help our clinicians with their prescribing decision.",
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Male',
        value: 'male',
        dataPoints: [
          {
            type: 'predefined',
            name: 'sex',
            value: 'male',
          },
        ],
      },
      {
        label: 'Female',
        value: 'female',
        dataPoints: [
          {
            type: 'predefined',
            name: 'sex',
            value: 'female',
          },
        ],
      },
    ],
    next: 'ff4b181a-fd8f-11ee-8781-efc5243a1d77',
  },
  {
    id: 'ff4b181a-fd8f-11ee-8781-efc5243a1d77',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      title: 'Weight management history',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        "Your journey is unique, and we're here to support you every step of the way. To help us do this, we'd like to know what weight loss methods you have previously tried.",
    }),
    next: '0b9a5e3c-fd90-11ee-af78-f7a33f2ef2b9',
  },
  {
    id: '0b9a5e3c-fd90-11ee-af78-f7a33f2ef2b9',
    type: 'question',
    required: true,
    title: 'Are you currently taking a weight loss injection?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    optionsRules: [
      {
        label: 'Yes',
        nextAction: 'exit-action',
      },
    ],
    next: '8b14d1f6-fd90-11ee-a186-9f5f4e18755d',
  },
  {
    id: '92a6e10a-e71a-9bb7-76ca-7a92b81ceaff', // < Path disabled as we are not ready to support those already on GLP-1
    type: 'question',
    required: true,
    title: 'Which weight loss injection are you currently taking?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Wegovy', value: 'Wegovy' },
      { label: 'Mounjaro', value: 'Mounjaro' },
      { label: 'Ozempic', value: 'Ozempic' },
      { label: 'Saxenda', value: 'Saxenda' },
      {
        label: 'Other',
        value: 'Other',
        followUpQuestion: {
          id: '66c08e62-fd90-11ee-9a14-1f16419fea7f',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
    ],
    next: '99e5c424-fd90-11ee-9722-df6714b638a6',
  },
  {
    id: '99e5c424-fd90-11ee-9722-df6714b638a6',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      title: "It's great that you want to join our Weight Loss Programme",
      body: `By continuing with our programme you are confirming that eMed will become the sole provider of your weight loss medication and that you will not obtain any medication for weight loss elsewhere.

      To support you on your successful weight loss journey we now need to understand a bit more about the current medication you are taking.`,
    }),
    next: '44c3ea7e-fd91-11ee-8d8a-1329e364c7fe',
  },
  {
    id: '44c3ea7e-fd91-11ee-8d8a-1329e364c7fe',
    type: 'question',
    required: true,
    title:
      'When did you begin treatment with your current weight loss medication?',
    inputType: {
      component: 'date',
      validation: undefined,
    },
    next: '381faee4-00ba-11ef-9d79-afcefbd50605',
  },
  {
    id: '381faee4-00ba-11ef-9d79-afcefbd50605',
    type: 'question',
    required: true,
    title: 'What was your weight before you started treatment?',
    inputType: {
      component: 'weight',
    },
    next: '5f0cb060-00ba-11ef-8a47-83390629c775',
  },
  {
    id: '5f0cb060-00ba-11ef-8a47-83390629c775',
    type: 'question',
    required: true,
    title: 'What dose are you currently taking?',
    body: 'For example: 5mg, once a week',
    inputType: {
      component: 'textArea',
    },
    next: '0c2a92b2-00bb-11ef-bc52-177c7d6fb4bc',
  },
  {
    id: '0c2a92b2-00bb-11ef-bc52-177c7d6fb4bc',
    type: 'question',
    required: true,
    title: 'How many weeks have you been on this dose for?',
    inputType: {
      component: 'textArea',
    },
    next: '3f569456-00bb-11ef-84af-9b4dc7af90e7',
  },
  {
    id: '3f569456-00bb-11ef-84af-9b4dc7af90e7',
    type: 'question',
    required: true,
    title:
      "As you may have experienced, your weight loss medication dose gradually goes up over time. Please tell us the doses you have been on and how long you've been on each dose.",
    inputType: {
      component: 'textArea',
    },
    next: '699d5a42-00bb-11ef-b9d4-23662fb64a1b',
  },
  {
    id: '699d5a42-00bb-11ef-b9d4-23662fb64a1b',
    type: 'question',
    required: true,
    title:
      'In the last 4 weeks, have you missed any doses of your weight loss medication, excluding doses taken later than prescribed?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Yes',
        value: 'Yes',
        followUpQuestion: {
          id: 'c3e15bca-00bb-11ef-8110-73c57f7be7a4',
          type: 'question',
          required: true,
          title: 'How many doses have you missed?',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
    next: '0738fa86-00bc-11ef-a067-47f2466a853f',
  },
  {
    id: '0738fa86-00bc-11ef-a067-47f2466a853f',
    type: 'question',
    required: true,
    title: 'Are you currently experiencing any side effects?',
    body:
      'If yes, please provide more detail on which side effects you are experiencing',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Nausea',
        value: 'Nausea',
        followUpQuestion: {
          id: 'c1a57c2c-00bd-11ef-8607-9377557d6f20',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Vomiting',
        value: 'Vomiting',
        followUpQuestion: {
          id: '16fc022c-00be-11ef-8349-47964427ace2',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Diarrhea',
        value: 'Diarrhea',
        followUpQuestion: {
          id: '19ffbe82-00be-11ef-b8dc-fb8fb33dacb6',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Constipation',
        value: 'Constipation',
        followUpQuestion: {
          id: '1f833f3c-00be-11ef-80e2-e373cc5b7a42',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Abdominal pain',
        value: 'Abdominal pain',
        followUpQuestion: {
          id: '226f7e68-00be-11ef-b6b7-1fe92e988c0f',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Headache',
        value: 'Headache',
        followUpQuestion: {
          id: '256a1c04-00be-11ef-9564-67984a58f9d8',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Tiredness',
        value: 'Tiredness',
        followUpQuestion: {
          id: '27decf84-00be-11ef-85ae-ef95b0823fd6',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Dizziness',
        value: 'Dizziness',
        followUpQuestion: {
          id: '2a9679d4-00be-11ef-90cc-f3e9002958bb',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Visual disturbances',
        value: 'Visual disturbances',
        followUpQuestion: {
          id: '2ce34e9c-00be-11ef-a6a5-93561d13d1b1',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label:
          'Increased heart rate, feeling your heart is racing at rest or experiencing palpitations',
        value:
          'Increased heart rate, feeling your heart is racing at rest or experiencing palpitations',
        followUpQuestion: {
          id: '3196c5f4-00be-11ef-88ea-0f79414da3a6',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Flatulence / Bloatedness',
        value: 'Flatulence / Bloatedness',
        followUpQuestion: {
          id: '345f733a-00be-11ef-ad6c-b74c6954f843',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Acid reflux',
        value: 'Acid reflux',
        followUpQuestion: {
          id: '3742fb8a-00be-11ef-8980-b30409af4a2e',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Hair loss',
        value: 'Hair loss',
        followUpQuestion: {
          id: '3c6afb80-00be-11ef-bd1c-d7917a97d1c5',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Rash',
        value: 'Rash',
        followUpQuestion: {
          id: '3fea4df6-00be-11ef-9a66-cff1e048ffab',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Thoughts of suicide or harming yourself',
        value: 'Thoughts of suicide or harming yourself',
        followUpQuestion: {
          id: '42d08d82-00be-11ef-a7f7-9fead9328b7b',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Other',
        value: 'Other',
        followUpQuestion: {
          id: '462d352a-00be-11ef-8b83-43892c3e64d7',
          type: 'question',
          required: true,
          title: 'Please provide more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Thoughts of suicide or harming yourself',
        nextAction: 'b9f87ab6-00bc-11ef-9651-db46a124e936',
      },
    ],
    next: 'd6650aca-00bc-11ef-ad9e-fb801d332dc4',
  },
  {
    id: 'b9f87ab6-00bc-11ef-9651-db46a124e936',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      header:
        "I'm really sorry to hear that you're feeling this way. It's important that you get the support you require as soon as possible. If you feel like you are in immediate danger of suicide please telephone <strong>999</strong>.  If you have been having thoughts of suicide or are considering harming yourself then be assured that one of our mental health practitioners will be able to offer further support.\n\nPlease be aware that there are also many services available for you to access:",
      body: [
        'Samaritans Helpline: <strong>116 123</strong> (free of charge from a landline or mobile) - 24 hr helpline offering emotional support for people who are experiencing feelings of distress or despair, including those which may lead to suicide.',
        'The National Suicide Prevention Helpline: <strong>0800 689 5652</strong> - offers supportive listening services to anyone throughout the UK with thoughts of suicide or thoughts of self-harm. They are open from 6:00pm to 3:30am for those aged 18 or over. Website: <a>www.spuk.org.uk</a>',
        'SupportLine Telephone Helpline: <strong>01708 765200</strong> - provides emotional support and details of support groups, helplines, agencies and counsellors throughout the UK.',
        'Calm: <strong>0800 585858</strong> - Campaign Against Living Miserably Help and support for young men aged 15-35 on issues which include depression and suicide.',
        'HopeLine UK: <strong>0800 068 4141</strong> - for practical advice on suicide prevention.',
      ],
    }),
    next: 'd6650aca-00bc-11ef-ad9e-fb801d332dc4',
  },
  {
    id: 'd6650aca-00bc-11ef-ad9e-fb801d332dc4',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      title: 'Upload your current prescription',
      body: `For continuity of care, we need the prescription from your current medication provider.

      Please take a photo of your most recent prescription or medication packaging. Make sure it shows your name, the date it was issued, and the most recent dosage you have been taking.

      Once you have that photo, please upload it through our contact form on our <a>https://support.babylonhealth.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d|support website</a>. If you're not able to do this immediately then don't worry - you have up to 48 hours to send it to us.`,
    }),
    next: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
  },
  {
    id: '8b14d1f6-fd90-11ee-a186-9f5f4e18755d',
    type: 'question',
    required: true,
    title:
      'Have you ever attempted any of the following approaches to achieve weight loss?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Use of oral medications like Orlistat, Xenical or Rybelsus',
        value: 'Use of oral medications like Orlistat, Xenical or Rybelsus',
      },
      {
        label:
          'Use of injectable medications like Ozempic, Wegovy, Mounjaro or Saxenda',
        value:
          'Use of injectable medications like Ozempic, Wegovy, Mounjaro or Saxenda',
        followUpQuestion: {
          id: '898e796e-b430-47c1-9511-d5a1ea3a2dfa',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          body: [
            'What is the name of the injectable medication you have previously used?',
            'When did you last take a dose of this medication?',
            'Why did you stop the treatment?',
          ],
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Bariatric surgery',
        value: 'Bariatric surgery',
        followUpQuestion: {
          id: '6cce1afa-a8c8-4951-b8c5-f60c3d8fce5a',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          body: [
            'What kind of bariatric surgery have you had?',
            'Are you currently under the care of the bariatric clinic?',
            'When did you have the bariatric surgery?\nFor example: January 2019',
          ],
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Endoscopic procedures',
        value: 'Endoscopic procedures',
        followUpQuestion: {
          id: '061c4879-2d16-44c1-8de9-aad6310f2bc5',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          body: [
            'What type of endoscopic procedure have you had?',
            'When did you have the endoscopic procedure? \nFor example: January 2019',
          ],
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Lifestyle interventions',
        value: 'Lifestyle interventions',
        followUpQuestion: {
          id: '5181743a-7fa0-4a58-a39a-c17c4cacaed3',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Other',
        value: 'other',
        followUpQuestion: {
          id: '0c7f4ecc-7dd7-404c-a5a5-f13d11225cdc',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Bariatric surgery',
        nextAction: '55884e54-3cbb-4b09-a8a7-50cfb28ce8c1',
      },
    ],
    next: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
  },
  {
    id: '55884e54-3cbb-4b09-a8a7-50cfb28ce8c1',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      title: 'Disclaimer',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        'If approved for our Weight Loss Programme, please note that the medication may delay gastric emptying. It is essential to maintain your daily recommended calorie intake to support a balanced diet despite potential changes.',
    }),
    next: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
  },
  {
    id: '63bcad7c-ca5a-11ee-ad08-cbe39fbb31aa',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      title: 'Medical History',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        'In the next part of the assessment, we will ask you some questions about your medical history.',
    }),
    next: 'ce9cd1d6-ca59-11ee-bf66-c7b6e56851e7',
  },
  {
    id: 'ce9cd1d6-ca59-11ee-bf66-c7b6e56851e7',
    type: 'question',
    required: true,
    title:
      'Do you take any medications or supplements regularly, either prescribed or over the counter?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: 'f8d0d902-ca59-11ee-85ac-8be810c3d131',
      },
    ],
    next: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
  },
  {
    id: 'f8d0d902-ca59-11ee-85ac-8be810c3d131',
    type: 'question',
    required: true,
    title:
      'Please tell us the name, dose and frequency of your medications or supplements. Please give as much detail as you can, remembering to include both prescription and over the counter products. ',
    body:
      "Don't worry, this is only so we can make sure there won't be any reactions to prescription medications.",
    inputType: {
      component: 'textArea',
    },
    next: '1d49424c-ca5a-11ee-933a-a7be183a4c7e',
  },
  {
    id: '1d49424c-ca5a-11ee-933a-a7be183a4c7e',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        'The use of recreational drugs and weight loss medications is not advised as it may lead to potential adverse effects.\n\n' +
        'By continuing with our programme you confirm that you understand that you should not take any recreational drugs while using the weight loss medication.',
    }),
    next: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
  },
  {
    id: 'dbb819ca-ca59-11ee-aff5-ff676aa2b929',
    type: 'question',
    required: true,
    title:
      'Do you have any allergies to certain medication (prescribed or over the counter), or foods?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: '42764628-ca5a-11ee-a15e-dff476e93a1f',
      },
    ],
    next: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
  },
  {
    id: '42764628-ca5a-11ee-a15e-dff476e93a1f',
    type: 'question',
    required: true,
    title: 'What are you allergic to?',
    body: 'Please give us as much detail as you can regarding your allergy.',
    inputType: {
      component: 'textArea',
    },
    next: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
  },
  {
    id: '2ff4d4f6-ca5a-11ee-8180-f708f486e640',
    type: 'question',
    required: true,
    title: 'Have you ever been diagnosed with type 2 diabetes or pre-diabetes?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    optionsRules: [
      {
        label: 'yes',
        nextAction: '800e0750-ca5a-11ee-bca7-9fd7a30e85c7',
      },
    ],
    next: '8bcd3c0a-ca5a-11ee-b5e2-cb3ea4fe8836',
  },
  {
    id: '800e0750-ca5a-11ee-bca7-9fd7a30e85c7',
    type: 'question',
    required: true,
    title: 'Do you know your most recent HbA1c value?',
    body: "If you do not know your HbA1c value, please write 'Not known'.",
    inputType: {
      component: 'textArea',
    },
    next: '8bcd3c0a-ca5a-11ee-b5e2-cb3ea4fe8836',
  },
  {
    id: '8bcd3c0a-ca5a-11ee-b5e2-cb3ea4fe8836',
    type: 'question',
    required: true,
    title: 'Do any of the following conditions apply to you?',
    body: 'Please read this list carefully.',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Kidney disease',
        value: 'Kidney disease',
        followUpQuestion: {
          id: '151aac73-3b41-484c-9914-7793ccc6f005',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Hypertension (high blood pressure)',
        value: 'Hypertension (high blood pressure)',
        followUpQuestion: {
          id: '54969e8c-1b39-403a-b650-5d2fe111ffd7',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Glaucoma',
        value: 'Glaucoma',
        followUpQuestion: {
          id: '19996700-04ab-4a11-aa53-de040cd22d22',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Current or past cancer, or chemotherapy treatment',
        value: 'Current or past cancer, or chemotherapy treatment',
        followUpQuestion: {
          id: '980f7bb0-5d0e-4f7e-92f4-effb37824dea',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label:
          'Irregular heart beat, raised heart rate (tachycardia) or any other arrhythmia',
        value:
          'Irregular heart beat, raised heart rate (tachycardia) or any other arrhythmia',
        followUpQuestion: {
          id: '02933fc2-4925-481f-bc19-9bed18579379',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Current or past drug or alcohol misuse',
        value: 'Current or past drug or alcohol misuse',
        followUpQuestion: {
          id: '9b8f3d30-3e6e-4705-8479-02c1bd87104b',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: '64f30b9b-5ffe-4413-8ec8-2b46088c47e6',
  },
  {
    id: '64f30b9b-5ffe-4413-8ec8-2b46088c47e6',
    type: 'question',
    required: true,
    title:
      'Do you or have you ever experienced any of the following mental health conditions?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label:
          'Eating disorder (e.g. anorexia nervosa, bulimia nervosa, binge eating disorder, ARFID, or OSFED).',
        value:
          'Eating disorder (e.g. anorexia nervosa, bulimia nervosa, binge eating disorder, ARFID, or OSFED).',
        followUpQuestion: {
          id: '17443f86-516d-42f2-9e83-6b2d1b73a6ec',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Anxiety',
        value: 'Anxiety',
        followUpQuestion: {
          id: '5e40d20e-79d3-4a52-8372-9d287e64de9a',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Depression',
        value: 'Depression',
        followUpQuestion: {
          id: '672a23e3-9966-421c-8fbc-237d51c4a271',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label:
          'You sometimes make yourself sick because you feel uncomfortably full',
        value:
          'You sometimes make yourself sick because you feel uncomfortably full',
        followUpQuestion: {
          id: '513e3855-7be3-4ff0-a6d4-163136a409c4',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Thoughts of suicide or harming yourself',
        value: 'Thoughts of suicide or harming yourself',
        followUpQuestion: {
          id: '710393e1-1b23-4314-80a8-12047abc89e7',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Body dysmorphia',
        value: 'Body dysmorphia',
        followUpQuestion: {
          id: '77f1faba-67c3-4d34-a70e-e94d64164f33',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Thoughts of suicide or harming yourself',
        nextAction: 'b362c974-ca5a-11ee-991b-43af1450477a',
      },
    ],
    next: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
  },
  {
    id: 'b362c974-ca5a-11ee-991b-43af1450477a',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      header:
        "I'm really sorry to hear that you're feeling this way. It's important that you get the support you require as soon as possible. If you feel like you are in immediate danger of suicide please telephone <strong>999</strong>.  If you have been having thoughts of suicide or are considering harming yourself then be assured that one of our mental health practitioners will be able to offer further support.\n\nPlease be aware that there are also many services available for you to access:",
      body: [
        'Samaritans Helpline: <strong>116 123</strong> (free of charge from a landline or mobile) - 24 hr helpline offering emotional support for people who are experiencing feelings of distress or despair, including those which may lead to suicide.',
        'The National Suicide Prevention Helpline: <strong>0800 689 5652</strong> - offers supportive listening services to anyone throughout the UK with thoughts of suicide or thoughts of self-harm. They are open from 6:00pm to 3:30am for those aged 18 or over. Website: <a>www.spuk.org.uk</a>',
        'SupportLine Telephone Helpline: <strong>01708 765200</strong> - provides emotional support and details of support groups, helplines, agencies and counsellors throughout the UK.',
        'Calm: <strong>0800 585858</strong> - Campaign Against Living Miserably Help and support for young men aged 15-35 on issues which include depression and suicide.',
        'HopeLine UK: <strong>0800 068 4141</strong> - for practical advice on suicide prevention.',
      ],
    }),
    next: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
  },
  {
    id: '0157e5c4-ca5b-11ee-80e2-ebd0a83a7f3b',
    type: 'interstitial',
    render: createInterstitialComponent(SimpleInterstitial, {
      title: 'Lifestyle',
      avatar: {
        imageSrc: drMazAlsaffarAvatar,
        alt: 'Dr Maz Alsaffar',
      },
      body:
        "We'd like to learn more about your diet and exercise routine to support you achieve your health goals.",
    }),
    next: '332e00e2-ca5b-11ee-b085-4f2ffb229bc3',
  },
  {
    id: '332e00e2-ca5b-11ee-b085-4f2ffb229bc3',
    required: true,
    type: 'question',
    title: 'Can you briefly describe your eating habits or routine?',
    inputType: {
      component: 'textArea',
    },
    next: '41086658-ca5b-11ee-bce4-b7b088b099f5',
  },
  {
    id: '41086658-ca5b-11ee-bce4-b7b088b099f5',
    required: true,
    type: 'question',
    title: 'How many units of alcohol do you drink per week?',
    body: [
      '1 pint of beer or cider (4% strength) - 2.3 units',
      '1 medium (175ml) glass of wine (13% strength) - 2.3 units',
      '1 glass (25ml) of spirits such as gin or vodka (40% strength) - 1 unit',
    ],
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: "I don't drink alcohol", value: "I don't drink alcohol" },
      { label: '1-5 units', value: '1-5 units' },
      { label: '6-9 units', value: '6-9 units' },
      { label: '10-14 units', value: '10-14 units' },
      { label: 'Over 14 units per week', value: 'Over 14 units per week' },
    ],
    next: '4d1cc7cc-ca5b-11ee-80d7-5f0e2a73a0d4',
  },
  {
    id: '4d1cc7cc-ca5b-11ee-80d7-5f0e2a73a0d4',
    required: true,
    type: 'question',
    title: 'Please select your current level of physical activity',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Sedentary (little to no physical activity)',
        value: 'Sedentary (little to no physical activity)',
      },
      {
        label: 'Lightly active (minimal physical activity, mostly sitting)',
        value: 'Lightly active (minimal physical activity, mostly sitting)',
      },
      {
        label:
          'Moderately active (regular physical activity, such as walking or light exercise)',
        value:
          'Moderately active (regular physical activity, such as walking or light exercise)',
      },
      {
        label:
          'Very active (frequent and intense physical activity or exercise)',
        value:
          'Very active (frequent and intense physical activity or exercise)',
      },
    ],
    next: '6355ab08-ca5b-11ee-9af8-ff6ff13f3b9e',
  },
  {
    id: '6355ab08-ca5b-11ee-9af8-ff6ff13f3b9e',
    type: 'question',
    inputType: {
      component: 'confirmation',
    },
    title: 'Before we continue...',
    body:
      'Please confirm that the information you provided is accurate to the best of your knowledge and you agree to information sharing with  your GP',
    callOut: {
      title: 'Why eMed?',
      body:
        'Trusted by over 3 million users, eMed is a market leading digital first healthcare provider. Our team of experienced clinicians is here to support you every step of the way.',
    },
    next: '72e91ad2-ca5b-11ee-887b-3b84e7aa9e81',
    nextButtonText: 'Confirm',
  },
  {
    id: '72e91ad2-ca5b-11ee-887b-3b84e7aa9e81',
    type: 'question',
    required: false,
    title: 'Please tell us anything else you think might be relevant',
    inputType: {
      component: 'textArea',
    },
    next: 'complete-action',
  },
]

export default preScreeningStepsV2
