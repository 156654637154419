import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'

type ProctorSessionInviteData = {
  createProctorSessionInvite: {
    url: string
  }
}

type ProctorSessionInviteVariables = {
  input: {
    procedure: {
      identifier: {
        value: string
      }
    }
  }
}

export const getProctorSessionInviteQuery = gql`
  mutation _firstmile_createProctorSessionInvite(
    $input: CreateProctorSessionInviteInput!
  ) {
    createProctorSessionInvite(input: $input) {
      ... on ProctorSessionInvite {
        url
      }
      ... on CreateProctorSessionInviteError {
        errorMessage
        statusCode
      }
    }
  }
`

const useGetProctorSessionInvite = () => {
  const [getProctorSessionInvite, { data, loading }] = useMutation<
    ProctorSessionInviteData,
    ProctorSessionInviteVariables
  >(getProctorSessionInviteQuery, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
  })

  return { getProctorSessionInvite, data, loading }
}

export default useGetProctorSessionInvite
