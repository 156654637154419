import {
  REGISTRATION_STARTED,
  REGISTRATION_COMPLETED,
  REGISTRATION_PROMO_MODAL_SHOWN,
} from './actionTypes'

const initialState = {
  registrationStarted: false,
  registrationCompleted: false,
  promocode: null,
  showPromotionModal: false,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_STARTED:
      return {
        ...state,
        registrationStarted: true,
        signupMethod: action.signupMethod,
      }
    case REGISTRATION_COMPLETED:
      return {
        ...state,
        registrationCompleted: true,
        signupMethod: action.signupMethod,
        promocode: action.promocode,
        showPromotionModal: !!action.promocode,
      }
    case REGISTRATION_PROMO_MODAL_SHOWN: {
      return {
        ...state,
        showPromotionModal: false,
      }
    }
    default:
      return state
  }
}

export default user
