import {
  Feature,
  ProductGroups,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'

export const addCommonFeatures = (
  productGroups: ProductGroups,
  commonFeatures: Feature[]
): ProductGroups =>
  Object.keys(productGroups).reduce((acc, key) => {
    const productGroup = productGroups[key]
    const combinedFeatures = [...productGroup.features, ...commonFeatures]
    acc[key] = {
      ...productGroup,
      features: combinedFeatures,
    }

    return acc
  }, {} as ProductGroups)
