import React from 'react'

import Modal from '@/components/Modal'
import LoginForm from './Form'

const LoginModal = ({ onClose, avoidClose, ...props }) => (
  <Modal onClose={onClose} avoidClose={avoidClose}>
    <LoginForm stopRedirection showCreateAccount={false} {...props} />
  </Modal>
)

export default LoginModal
