import React from 'react'
import { useRouteMatch, useLocation, Redirect, useHistory } from 'react-router'
import qs from 'qs'
import { graphql } from '@apollo/react-hoc'
import { Form, InputField, Text } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { logMessage } from '@babylon/sentry'
import * as Validation from '@/validation'
import appStyles from '@/App.module.scss'
import PageTitle from '@/components/PageTitle/PageTitle'
import LandingHeader from '../../LandingHeader'
import changePasswordMutation from '../mutations'
import messages from './messages'
import registerMessages from '../Register/messages'

const enhance = graphql(changePasswordMutation, {
  name: 'changePassword',
})

const ChangePasswordPage = ({
  changePassword,
  description,
  title,
  successMessage,
  submitMessage,
}) => {
  const translate = useFormatMessage()
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  let { token } = match.params

  if (token) {
    logMessage(
      '/change-password/:token is deprecated - use /change-password?token=... instead',
      'warning'
    )
  } else {
    token = qs.parse(location.search, { ignoreQueryPrefix: true }).token
  }

  if (!token) {
    return <Redirect to="/" />
  }

  const onSubmit = ({ password: newPassword }) =>
    changePassword({
      variables: {
        newPassword,
        token,
      },

      context: {
        showFeedback: {
          success: successMessage || translate(messages.success),
          failure: true,
        },
      },
    }).then(() => history.push('/'))

  return (
    <>
      <PageTitle title={title || translate(messages.pageTitle)} />
      <div>
        <LandingHeader />
        <main className={appStyles.view}>
          <div className={appStyles.view__inner}>
            <section className="Content--form">
              <h1 className="PageTitle">
                {title || translate(messages.pageTitle)}
              </h1>
              {description && <Text variant="body">{description}</Text>}
              <Form
                initialValues={{
                  password: '',
                }}
                onSubmit={onSubmit}
                submitButtonText={submitMessage || translate(messages.change)}
              >
                <InputField
                  name="password"
                  type="password"
                  label={translate(messages.newPassword)}
                  helpText={translate(registerMessages.passwordTip)}
                  validate={Validation.password({
                    requiredMessage: translate(
                      registerMessages.passwordValidation
                    ),
                    formatMessage: translate(
                      registerMessages.passwordErrorFormat
                    ),
                    lengthMessage: translate(
                      registerMessages.passwordErrorLength,
                      {
                        length: 8,
                      }
                    ),
                    length: 8,
                  })}
                  autoComplete="new-password"
                  required
                />
                {/* Hidden field to handle error message */}
                <InputField name="reset_password_token" type="hidden" />
              </Form>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

export default enhance(ChangePasswordPage)
