import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Text, List, Link } from '@babylon/medkit'
import messages from './RequestAppointment.messages'
import styles from './RequestAppointment.module.scss'

const Success = () => (
  <section className="Content--narrow">
    <div className={styles.requestAppointment__contentSection}>
      <Text variant="h1" className="PageTitle">
        <FormattedMessage {...messages.successPageTitle} />
      </Text>
      <Text variant="body">
        <FormattedMessage {...messages.successPageSubTitle} />
      </Text>
    </div>
    <div className={styles.requestAppointment__contentSection}>
      <Text variant="body">
        <FormattedMessage
          {...messages.successPageSymptomsAdvice}
          values={{
            999: <Link href="tel:999">999</Link>,
          }}
        />
      </Text>
    </div>
    <div className={styles.requestAppointment__contentSection}>
      <Text variant="h5">
        <FormattedMessage {...messages.successPageAppointmentTimeTitle} />
      </Text>
      <Text variant="body">
        <FormattedMessage
          {...messages.successPageWhatAppointmentTimeDescription}
        />
      </Text>
    </div>
    <div className={styles.requestAppointment__contentSection}>
      <Text variant="h5">
        <FormattedMessage {...messages.successPageNeedsSectionTitle} />
      </Text>
      <List
        listData={[
          <FormattedMessage
            {...messages.successPageNeedsSectionFirstBulletPoint}
          />,
          <FormattedMessage
            {...messages.successPageNeedsSectionSecondBulletPoint}
          />,
        ]}
        variant="ul"
      />
    </div>
  </section>
)

export default Success
