import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { Link } from '@babylon/medkit'
import Template from '../Template'
import messages from '../messages'
import Button from '@/programmes/components/Button'
import { checkoutRoutes, routes } from '../routes'
import styles from '../index.module.scss'
import onboardingSteps from '../steps'
import {
  UK_WELLNESS_SUBSCRIPTION_PRODUCT_ID,
  US_WELLNESS_SUBSCRIPTION_PRODUCT_ID,
  US_CN_NAME,
} from '@/config'
import useGetCheckoutLink from '@/programmes/hooks/useGetCheckoutLink'
import useGetPreferredConsumerNetwork from '@/programmes/hooks/useGetPreferredConsumerNetwork'

const cx = classNames.bind(styles)

const CheckoutHome = () => {
  const t = useFormatMessage()

  const [preferredCN, setPreferredCN] = useState<string>()

  const {
    getPreferredConsumerNetwork,
    loading: loadingCN,
  } = useGetPreferredConsumerNetwork()

  useEffect(() => {
    const preferredConsumerNetwork = getPreferredConsumerNetwork()
    setPreferredCN(preferredConsumerNetwork?.name)
  }, [getPreferredConsumerNetwork, loadingCN])

  /**
   * Added to support US employee wellness programme, which has a different product ID in Stripe.
   */
  const productId = preferredCN?.toLowerCase().includes(US_CN_NAME)
    ? US_WELLNESS_SUBSCRIPTION_PRODUCT_ID
    : UK_WELLNESS_SUBSCRIPTION_PRODUCT_ID
  const { checkOutMutation, loading } = useGetCheckoutLink({
    productList: [{ id: productId, quantity: 1 }],
    successPath: checkoutRoutes.confirmation,
    cancelPath: routes.home,
  })

  return (
    <Template
      title={t(messages.checkoutPageTitle)}
      steps={onboardingSteps}
      activeStep={2}
      loading={loadingCN}
    >
      <h2 className={cx('heading')}>{t(messages.checkoutHeading)}</h2>
      <Spacer top={2}>
        <p className={cx('description')}>{t(messages.checkoutDescription1)}</p>
        <p className={cx('description')}>{t(messages.checkoutDescription2)}</p>
        <p className={cx('description')}>
          {t(messages.checkoutDescription3Immediate)}
        </p>
      </Spacer>
      <Spacer top={2} bottom={2}>
        <div className={cx('product-box')}>
          <div className={cx('info')}>
            <div className={cx('title')}>
              {t(messages.checkoutPageProductTitle)}
            </div>
            <div className={cx('subtitle')}>
              {t(messages.checkoutPageProductDescription)}
            </div>
            <div className={cx('price')}>
              {t(messages.checkoutPageProductPrice)}
            </div>
          </div>
        </div>
      </Spacer>
      <Spacer top={2} bottom={2}>
        <Button onClick={checkOutMutation} loading={loading}>
          {t(messages.checkoutPageTitleCta)}
        </Button>
      </Spacer>
      <Link className={cx('small-text')} id="subtitle" href="/terms" blank>
        {t(messages.checkoutPageTermsAndConditions)}
      </Link>
    </Template>
  )
}

export default CheckoutHome
