import React, { useEffect, useState } from 'react'
import styles from './ToggleSwitch.module.scss'

interface ToggleSwitchProps {
  inactiveLabel: {
    visualLabel: string
    ariaLabel: string
  }
  activeLabel: {
    visualLabel: string
    ariaLabel: string
  }
  onClick: () => void
  initialActive?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  inactiveLabel,
  activeLabel,
  onClick,
  initialActive,
}) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (initialActive) {
      setIsActive(true)
    }
  }, [initialActive])

  const toggleSwitch = () => {
    onClick()
    setIsActive(!isActive)
  }

  const ariaLabel = isActive ? inactiveLabel.ariaLabel : activeLabel.ariaLabel

  return (
    <button
      className={styles.toggleSwitch}
      onClick={toggleSwitch}
      title={ariaLabel}
      aria-live="assertive"
    >
      <span className={styles.label} aria-hidden="true">
        {inactiveLabel.visualLabel.toUpperCase()}
      </span>
      <span className={styles.label} aria-hidden="true">
        {activeLabel.visualLabel.toUpperCase()}
      </span>
      <div
        className={`${styles.slider} ${isActive ? styles.active : ''}`}
        aria-hidden="true"
      >
        <span className={styles.sliderLabel}>
          {isActive ? activeLabel.visualLabel : inactiveLabel.visualLabel}
        </span>
      </div>
    </button>
  )
}

export default ToggleSwitch
