import React from 'react'
import cn from 'classnames'
import styles from './RadioButton.module.scss'

interface RadioButtonProps {
  id: string
  label: string
  value: string
  checked: boolean
  onChange: (value: string) => void
  body?: string
  extraLabel?: string
  tabIndex?: number
  onFocus?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  value,
  body,
  checked,
  onChange,
  extraLabel,
  tabIndex = -1,
  onFocus = () => {},
  onKeyDown = () => {},
}) => {
  const radioOptionRef = React.useRef<HTMLButtonElement | null>(null)

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <button
      type="button"
      className={cn(styles.container, checked && styles.checked)}
      ref={radioOptionRef}
      tabIndex={tabIndex}
      role="radio"
      aria-checked={checked}
      onClick={() => {
        onChange(value)
      }}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    >
      <div className={cn(styles.wrapper)}>
        <span className={styles.label}>
          <input
            type="radio"
            id={`${label}-${id}`}
            data-testid={`radio-${label}`}
            name={`${label}-${id}`}
            value={value}
            checked={checked}
            onChange={() => onChange(value)}
          />

          <span className={styles.radioMark} />
          <span className={styles.title}>{label}</span>
        </span>
        {extraLabel && <span className={styles.extraLabel}>{extraLabel}</span>}
      </div>
      {body && <span className={styles.body}>{body}</span>}
    </button>
  )
}

export default RadioButton
