import React from 'react'
import { useIntl } from 'react-intl'
import {
  VideoFilled,
  VideoOffFilled,
  MicroFilled,
  MicroOffFilled,
  PhoneDown,
} from '@babylon/icons'
import messages from '../VideoCall.messages'
import styles from '../VideoCall.module.scss'

const VideoControls = ({
  toggleAudio,
  toggleVideo,
  audioEnabled,
  videoEnabled,
  hangUp,
}) => {
  const intl = useIntl()
  const iconsProps = {
    fill: '#FFF',
    width: '35px',
    height: '35px',
  }

  return (
    <nav className={styles.videoControls} aria-label="Video controls">
      <button
        type="button"
        className={styles.controlButton}
        onClick={toggleAudio}
        aria-pressed={audioEnabled ? 'false' : 'true'}
      >
        <span className={styles.controlButton__text}>
          {intl.formatMessage(messages.videoControlsTurnOffMicrophone)}
        </span>
        {audioEnabled ? (
          <MicroFilled {...iconsProps} />
        ) : (
          <MicroOffFilled {...iconsProps} />
        )}
      </button>

      <button className={styles.hangUp} onClick={hangUp} type="button">
        <span className={styles.controlButton__text}>
          {intl.formatMessage(messages.videoControlsAltTagsEndConsultation)}
        </span>
        <PhoneDown fill="#FFF" width="45px" height="45px" />
      </button>
      <button
        type="button"
        className={styles.controlButton}
        onClick={toggleVideo}
        aria-pressed={videoEnabled ? 'false' : 'true'}
      >
        <span className={styles.controlButton__text}>
          {intl.formatMessage(messages.videoControlsStopVideo)}
        </span>
        {videoEnabled ? (
          <VideoFilled {...iconsProps} />
        ) : (
          <VideoOffFilled {...iconsProps} />
        )}
      </button>
    </nav>
  )
}

export default VideoControls
