import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import SectionTitle from './SectionTitle'

import messages from '../../messages'
import {
  DiagnosticReport,
  ObservationReferenceRange,
  Quantity,
} from '@/programmes/hooks/useGetLabResults'
import { ReportColors } from '@/programmes/Wellness/Report/types'
import RagCard from '@/programmes/Wellness/Report/components/RagCard'

const getObsAndMatchedDisplayRule = (
  value: number,
  referenceRange: ObservationReferenceRange
): MatchedDisplayRule => {
  const isQuantity = (obj: any): obj is Quantity =>
    obj?.__typename === 'Quantity'

  const createResult = (
    value: number,
    text: string,
    color: ReportColors
  ): MatchedDisplayRule => ({
    value,
    display: {
      text,
      icon: {
        type: 'value',
        color,
      },
    },
  })

  if (!isQuantity(referenceRange.low) && !isQuantity(referenceRange.high)) {
    return createResult(
      0,
      'Reference range is not available.',
      ReportColors.GREY
    )
  }

  const { low, high } = referenceRange

  if (isQuantity(low) && value < low.value) {
    return createResult(low.value, 'Your value is low.', ReportColors.RED)
  }

  if (isQuantity(high) && value > high.value) {
    return createResult(high.value, 'Your value is high.', ReportColors.RED)
  }

  return createResult(
    0,
    'Your value is within the normal range.',
    ReportColors.GREEN
  )
}

type MatchedDisplayRule = {
  value?: number
  display: {
    text: string
    icon: {
      type: string
      color: ReportColors
    }
  }
}

type RagCardData = {
  name: string
  value: number
  unit: string
  referenceRange: string
}

type RagCardDisplayData = {
  data: RagCardData
  matchedDisplayRule: MatchedDisplayRule
}

const LabsSection = ({ labs }: { labs?: DiagnosticReport }) => {
  const t = useFormatMessage()

  const mappedData = labs?.results.nodes?.flatMap((observation) => {
    const name = observation.code.codings.nodes[0]?.display.text
    const value = observation.valueQuantity?.value
    const unit = observation.valueQuantity?.unit || 'Unknown'
    const referenceRange = observation.referenceRanges.nodes[0]

    if (!name || !value) {
      return []
    }

    return {
      data: {
        name,
        value,
        unit,
        referenceRange: referenceRange?.text ?? 'Unknown',
      },
      matchedDisplayRule: getObsAndMatchedDisplayRule(value, referenceRange),
    } as RagCardDisplayData
  })

  return (
    <>
      <SectionTitle text={t(messages.dashboardLabssSectionTitle)} />
      {!mappedData?.length && (
        <div className="tw-items-start tw-w-full tw-mb-8 tw-text-caption">
          {t(messages.dashboardLabsNoData)}
        </div>
      )}
      <div className="tw-grid lg:tw-grid-cols-3 tw-gap-6 md:tw-grid-cols-2 sm:tw-grid-cols-1">
        {mappedData?.map((lab) => {
          const ragColor = lab.matchedDisplayRule.display.icon.color
          const interpretation = lab.matchedDisplayRule.display.text
          const biomarker = lab.data

          return (
            lab.matchedDisplayRule && (
              <RagCard
                name={biomarker.name}
                value={biomarker.value}
                unit={biomarker.unit}
                rangeLabel={`${t(messages.dashboardLabsReferenceRange)} ${
                  biomarker.referenceRange
                }`}
                interpretation={interpretation}
                rag={ragColor}
              />
            )
          )
        })}
      </div>
    </>
  )
}

export default LabsSection
