import { connect } from 'react-redux'
import { compose, withProps, withHandlers } from 'recompose'
import { graphql } from '@apollo/react-hoc'
import { get } from 'lodash'

import { userId } from '@/components/Authentication/selectors'
import FamilyMembers from '@/queries/FamilyMembers'
import getPageIdFromUserId from './utils/getPageIdFromUserId'
import { selectFamilyEnabled } from '@/redux/selectors'

let cachedCurrentUserId
let cachedPageId
let userMap

export default compose(
  connect((state) => ({
    userId: userId(state),
    familyEnabled: selectFamilyEnabled(state),
  })),
  withHandlers({
    updateCurrentUserId: () => (userId) => {
      cachedCurrentUserId = userId
      cachedPageId = getPageIdFromUserId(userMap)(userId)
    },
  }),
  graphql(FamilyMembers, {
    name: 'familyMembersData',
    skip: ({ userId, familyEnabled }) => !userId || !familyEnabled,
    options: ({ userId }) => ({
      variables: { id: userId },
    }),
  }),
  /* TO DO: [Refactor] [CW-1386] Reduce complexity to align with new linting rules */
  /* eslint-disable complexity */
  withProps(
    ({ userId, familyMembersData = {}, match = {}, appointmentData = {} }) => {
      userMap = [userId]

      let currentUserId =
        ((appointmentData.appointment || {}).patient || {}).id ||
        cachedCurrentUserId ||
        userId

      const pageUserId = Number(get(match, 'params.userId', cachedPageId || 0))

      if (familyMembersData.familyMembers) {
        const { familyMembers } = familyMembersData

        userMap = [userId, ...familyMembers.map((f) => f.id)]

        currentUserId = userMap[pageUserId]
      }

      if (currentUserId && currentUserId !== cachedCurrentUserId) {
        cachedCurrentUserId = currentUserId
        cachedPageId = getPageIdFromUserId(userMap)(currentUserId)
      }

      return {
        userId,
        pageUserId,
        getPageIdFromUserId: getPageIdFromUserId(userMap),
        currentUserId, // this identifier covers the cases when our Patients admin their family members
      }
    }
  )
  /* eslint-enable */
)
