// Copied from https://github.com/formatjs/react-intl/blob/2.x/src/types.js
// This is now removed from react-intl 3 in favour of Typescript interfaces
/*
 * Copyright 2015, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */

import PropTypes from 'prop-types'

const { string, func, object, shape, any } = PropTypes

const funcReq = func.isRequired

const intlConfigPropTypes = {
  locale: string,
  timeZone: string,
  formats: object,
  messages: object,
  textComponent: any,

  defaultLocale: string,
  defaultFormats: object,

  onError: func,
}

const intlFormatPropTypes = {
  formatDate: funcReq,
  formatTime: funcReq,
  formatRelativeTime: funcReq,
  formatNumber: funcReq,
  formatPlural: funcReq,
  formatMessage: funcReq,
}

const intlShape = shape({
  ...intlConfigPropTypes,
  ...intlFormatPropTypes,
  formatters: object,
})

export default intlShape
