import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import { logException } from '@babylon/sentry'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import { Error } from './components/Error'
import Loading from '../../components/Loading'
import { trackAppointmentEvent } from '../../components/Appointments/utils'

const PaymentCompletionPage = () => {
  const { id } = useParams<{ id: string }>()
  const GET_APPOINTMENT_STATUS = gql`
    query GetAppointmentStatus($appointmentId: Int!) {
      appointment(id: $appointmentId) {
        state
      }
    }
  `

  const [getAppointmentState, { loading, data, error }] = useLazyQuery(
    GET_APPOINTMENT_STATUS,
    {
      variables: {
        appointmentId: parseInt(id),
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    getAppointmentState()
  }, [getAppointmentState])

  useEffect(() => {
    if (data?.appointment?.state === 'timed_out') {
      trackAppointmentEvent('payment-completion appointment timedOut', { id })
    }

    if (data?.appointment?.state === 'pending') {
      trackAppointmentEvent('payment-completion appointment pending', { id })
    }

    if (error) {
      logException(error)
    }
  }, [data?.appointment?.state, id, error])

  if (loading) {
    return <Loading />
  }

  if (data?.appointment?.state === 'timed_out') {
    return <Redirect to="/consultations/timed-out" />
  }

  if (data?.appointment?.state === 'paid') {
    return <Redirect to={`/consultations/${id}/success`} />
  }

  if (error || data?.appointment?.state === 'pending') {
    return <Error retry={getAppointmentState} />
  } else {
    return null
  }
}

export default PaymentCompletionPage
