import {
  routeConfig as bookAppointmentRoutes,
  getRootUrl,
  GROUP_ROOT_PATH,
} from '@/components/Appointments/BookAppointment/routesStreamlined'
import { RouteConfig } from './routeConfig'

const routeConfig: RouteConfig = {
  id: 'appointments-streamlined',
  route: GROUP_ROOT_PATH,
  getUrl: (args: { appointmentType: string }) => getRootUrl(args),
  getRedirectUrl: (args: { appointmentType: string }) => getRootUrl(args),
  breadcrumb: {
    isExcluded: true,
  },
  isSkipped: () => false,
  children: bookAppointmentRoutes,
}

export { GROUP_ROOT_PATH as ROOT_PATH } from '@/components/Appointments/BookAppointment/routesStreamlined'

export default routeConfig
