import React from 'react'
import { DocumentNode } from 'graphql'

import {
  BabylonUserQuery,
  BabylonUserQueryVariables,
  ViewerFragment,
} from './types'
import mapViewerToUser from './mapViewerToUser'
import { BABYLON_USER_QUERY } from './queries'

export interface UserRoles {
  admin: boolean
  careCoordinator: boolean
  clinician: boolean
  clinopsSupportAssociate: boolean
  covidChatSupport: boolean
  dietitian: boolean
  generalAdmin: boolean
  globalAdmin: boolean
  saasGeneralAdmin: boolean
  gp: boolean
  hcaClinician: boolean
  nurse: boolean
  pharmacist: boolean
  phlebotomist: boolean
  prescribingPharmacist: boolean
  seniorNurse: boolean
  specialist: boolean
  superConsultant: boolean
  therapist: boolean
  triageNurse: boolean
  clinicalAdministrator: boolean
  complianceOfficer: boolean
  promotionCsvManager: boolean
  configurationViewer: boolean
}

export type User = ViewerFragment & UserRoles

export type ConsultantUser = User & {
  consultant: NonNullable<ViewerFragment['consultant']>
}

export interface BabylonUserRender {
  loading: boolean
  error?: Error
  user?: User | null
}

interface BabylonUserProviderProps {
  consultant?: boolean
  children: React.ReactNode | ((v: BabylonUserRender) => React.ReactNode)
  useQuery: (
    query: DocumentNode,
    options: {
      variables: BabylonUserQueryVariables
      context?: { graphName: string }
    }
  ) => {
    data: BabylonUserQuery | undefined
    loading: boolean
    error?: Error | undefined
  }
}

export const isConsultantUser = (user: User): user is ConsultantUser =>
  Object.prototype.hasOwnProperty.call(user, 'consultant')

export const BabylonUserContext = React.createContext<User | null>(null)

export const BabylonUserProvider = ({
  consultant,
  children,
  useQuery,
}: BabylonUserProviderProps) => {
  const { data, loading, error } = useQuery(BABYLON_USER_QUERY, {
    variables: {
      consultant: Boolean(consultant),
    },
    context: {
      graphName: 'GraphQLMiddleware', // Graph name from @babylon/web-platform-utils-network-provider-react
    },
  })

  const viewer = data?.viewer
  const user = viewer ? mapViewerToUser(viewer) : null

  const content =
    typeof children === 'function'
      ? children({ loading, error, user })
      : children

  return (
    <BabylonUserContext.Provider value={user}>
      {content}
    </BabylonUserContext.Provider>
  )
}

/** BabylonUser
 *
 * A FaCC component to get the login information
 *
 * it provides the following information in the user object:
 *  - id: the uuid of the user
 *  - type: the user type
 *  - globalAdmin: true if the user has the role 'global_admin', false otherwise
 *  - pharmacist: true if the user has the role 'pharmacist', false otherwise
 *  - roles: an array with all of the users roles:
 *    [
 *      { id: 'global_admin', name: 'global_admin' },
 *      { id: 'pharmacist', name: 'pharmacist' }
 *    ]
 *
 * @example:
 *
 * <BabylonUser>{user => <span>{user.id}</span>}</BabylonUser>
 */
export const BabylonUser = BabylonUserContext.Consumer
