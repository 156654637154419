import React from 'react'
import { Text } from '@babylon/web-platform-ui'
import { AspectRatio } from '@/components/ui/aspect-ratio'
import { TabsContent } from '@/components/ui/tabs'
import { ProductSelector } from '@/programmes/WeightLoss/CheckOut/ProductPicker/components/ProductSelector'
import { ProductGroup } from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import { FeaturesTable } from '@/programmes/WeightLoss/CheckOut/ProductPicker/components/FeaturesTable'

interface TabContentProps {
  productGroup: ProductGroup
}

export const TabContent = ({ productGroup }: TabContentProps) => (
  <TabsContent
    className="tw-pt-5 tw-outline-none focus:tw-outline-none"
    key={productGroup.id}
    value={productGroup.id}
  >
    <AspectRatio ratio={16 / 8}>
      <img
        className="tw-h-full tw-w-full tw-object-contain"
        src={productGroup.img}
        alt={productGroup.label}
      />
    </AspectRatio>

    <p className="tw-text-base md:tw-text-xl tw-mt-4 tw-mb-4">
      {productGroup.content}
    </p>
    {productGroup.additionalConsideration && (
      <Text>{productGroup.additionalConsideration}</Text>
    )}

    <FeaturesTable productGroup={productGroup} />

    <ProductSelector products={productGroup.products} />
  </TabsContent>
)
