import React, { useEffect } from 'react'
import {
  Answers,
  Interstitial,
  Step,
} from '@/programmes/components/FormV2/types'

export const createInterstitialComponent = <P extends {}>(
  Component: React.ComponentType<P>,
  // we omit these props at declaration time because they are automatically added at render time
  props: Omit<P, 'interstitial' | 'answers'>
) => (interstitial: Interstitial, answers: any) => {
  // we need the explicit type assertion because there's a bug in the TS compiler
  // https://github.com/microsoft/TypeScript/issues/28884
  const extendedProps = ({ ...props, interstitial, answers } as unknown) as P

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...extendedProps} />
}

export const isAutoTransitioningInterstitial = (step: Step) =>
  step.type === 'interstitial' && step.autoTransitionSeconds

interface Props {
  interstitial: Interstitial
  answers: Answers
  handleNext: () => Promise<void>
}

const useAutoTransition = (
  interstitial: Interstitial,
  handleNext: () => Promise<void>
) => {
  useEffect(() => {
    let timeout

    if (interstitial.autoTransitionSeconds) {
      timeout = setTimeout(() => {
        handleNext()
      }, interstitial.autoTransitionSeconds * 1000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [handleNext, interstitial])
}

const RenderInterstitial = ({ interstitial, answers, handleNext }: Props) => {
  useAutoTransition(interstitial, handleNext)

  return interstitial.render(interstitial, answers)
}

export default RenderInterstitial
