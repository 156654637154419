import React from 'react'
import {
  PopOver,
  Box,
  Heading,
  Text,
  Link,
  Button,
} from '@babylon/web-platform-ui'

interface ErrorModalProps {
  error: boolean
  setError: (error: boolean) => void
  isWebViewMode: boolean
  heading: string
  body: string
  buttonText: string
  contactUsLinkText?: string
  contactUsUrl?: string
}

const ErrorModal = ({
  error,
  setError,
  isWebViewMode,
  heading,
  body,
  buttonText,
  contactUsLinkText,
  contactUsUrl,
}: ErrorModalProps) => (
  <PopOver
    isOpen={error}
    onClose={() => {
      if (!isWebViewMode) {
        setError(false)
      }
    }}
    onOverlayClick={() => {
      if (!isWebViewMode) {
        setError(false)
      }
    }}
    showCloseButton={!isWebViewMode}
  >
    <Box padding={3}>
      <Heading size="xs" marginBottom={isWebViewMode ? 2 : 1}>
        {heading}
      </Heading>

      <Text marginBottom={4}>
        {body}
        {!!contactUsUrl && contactUsLinkText && (
          <Link
            marginLeft={isWebViewMode ? 0 : 1}
            isExternal
            href={contactUsUrl}
          >
            {contactUsLinkText}
          </Link>
        )}
      </Text>
      <Button
        variant="primary"
        onClick={() => {
          window.location.reload()
        }}
      >
        {buttonText}
      </Button>
    </Box>
  </PopOver>
)

export default ErrorModal
