export enum CookieGroup {
  ESSENTIAL = 'C0001',
  PERFORMANCE = 'C0002',
  FUNCTIONAL = 'C0003',
  TARGETING = 'C0004',
  SOCIAL_MEDIA = 'C0005',
}

export type OneTrustState = {
  cookiePreferences: CookieGroup[]
}

export const isCookieGroup = (
  cookieGroup: string
): cookieGroup is CookieGroup =>
  Object.values(CookieGroup).includes(cookieGroup as CookieGroup)
