export enum INDICATOR_VARIABLE_COMPUTED_TYPE {
  AGE = 'AGE',
  HEIGHT_FEET = 'HEIGHT_FEET',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
}

export enum ReportSources {
  PATIENT_PROFILE = 'patient-profile',
  QUESTIONNAIRE = 'questionnaire',
  BINAH = 'binah',
  TASSO_LAB_DATA = 'tasso-lab-data',
}

export enum ReportColors {
  GREEN = 'GREEN',
  AMBER = 'AMBER',
  RED = 'RED',
}
