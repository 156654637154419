import { reduxState } from '@/redux'
import {
  NextAction,
  Step,
  Answers,
  Measurements,
  CheckBoxGroupValue,
  NextHandler,
  RadioGroupValue,
  EmailMarketingResponse,
} from '@/programmes/components/FormV2/types'
import calculateBMI from '@/programmes/utils/calculateBMI'
import { BRAZE_PROSPECT_PROFILE_CLIENT } from '@/marketing/braze'
import {
  EMAIL_QUESTION_ID,
  ETHNICITY_QUESTION_ID,
  HEIGHT_AND_WEIGHT_QUESTION_ID,
  WEIGHT_RELATED_ILLNESS_QUESTION_ID,
  WEIGHT_LOSS_PLAN_QUESTION_ID,
} from './preScreeningStepsV2'

export const heightAndWeightHandler = (
  currentStep: Step,
  answers: Answers
): NextAction => {
  if (
    currentStep.type === 'question' &&
    currentStep.inputType.component !== 'heightAndWeight'
  ) {
    return 'exit-action'
  }

  const { height, weight } = answers[currentStep.id] as Measurements
  const bmi = calculateBMI(height, weight)

  if (!bmi) {
    return 'exit-action'
  }

  return bmi < 27 ? 'exit-action' : WEIGHT_RELATED_ILLNESS_QUESTION_ID
}

export const ethnicityHandler: NextHandler = (
  currentStep: Step,
  answers: Answers
) => {
  if (currentStep.id !== ETHNICITY_QUESTION_ID) {
    return 'exit-action'
  }

  const { height, weight } = answers[
    HEIGHT_AND_WEIGHT_QUESTION_ID
  ] as Measurements
  const bmi = calculateBMI(height, weight)

  if (!bmi || bmi < 27) {
    return 'exit-action'
  }

  const state = reduxState.store.getState()
  const isLoggedIn = state?.auth?.user?.id
  const hasWeightRelatedIllness = (answers[
    WEIGHT_RELATED_ILLNESS_QUESTION_ID
  ] as CheckBoxGroupValue[]).every(
    (checkboxAnswer) =>
      (checkboxAnswer as CheckBoxGroupValue).value !== 'None of the above'
  )
  const currentAnswer = answers[currentStep.id] as RadioGroupValue
  const hasIneligibleEthnicity = [
    'White',
    'Other ethnic group',
    'Prefer not to say',
  ].includes(currentAnswer.value)

  if (bmi >= 30 || hasWeightRelatedIllness || !hasIneligibleEthnicity) {
    return isLoggedIn ? WEIGHT_LOSS_PLAN_QUESTION_ID : EMAIL_QUESTION_ID
  }

  return 'exit-action'
}

export const collectEmailHandler: NextHandler = (
  currentStep: Step,
  answers: Answers
) => {
  const { marketingConsentGiven, emailAddress, validEmailSupplied } = answers[
    currentStep.id
  ] as EmailMarketingResponse

  if (!validEmailSupplied || !emailAddress) {
    throw new Error(
      'Should not be able to progress to here without a valid email'
    )
  }

  const prospectTrackingClient = BRAZE_PROSPECT_PROFILE_CLIENT

  if (marketingConsentGiven) {
    prospectTrackingClient.setProspectOptedIn(emailAddress)
  } else {
    prospectTrackingClient.setProspectUnsubscribed(emailAddress)
  }

  return WEIGHT_LOSS_PLAN_QUESTION_ID
}
