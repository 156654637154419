import React, { useLayoutEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getLocation } from 'connected-react-router'
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'

import { getUser } from '@babylon/auth0'
import LoginPage from './Login'
import { IS_SERVER } from '@/config'
import Loading from '../Loading'
import { setLoggedIn, loading, setLoggedInWebview } from './actions'
import { getIsAuthenticated, isLoading } from './selectors'
import WebViewMode from '@/utils/WebViewMode'
import { usePartnerBookingFlowEnabled } from '@/redux/selectors'

const enhance = connect((state) => ({
  isAuthenticated: getIsAuthenticated(state),
  isLoading: isLoading(state),
  location: getLocation(state),
}))

const PrivacyNotice = loadable(() =>
  import(/* webpackChunkName: "appWithRouting" */ '../Privacy')
)

const VideoCall = loadable(() =>
  import(/* webpackChunkName: "appWithRouting" */ '../VideoCall')
)

const useCheckAuthentication = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    const checkAuthentication = async () => {
      const user = await getUser()
      const isWebViewToken = WebViewMode.getToken()

      if (!user) {
        if (isWebViewToken) {
          dispatch(setLoggedInWebview())
        } else {
          dispatch(loading({ isLoading: false }))
        }
      } else {
        dispatch(setLoggedIn())
      }
    }

    checkAuthentication()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

const Authentication = ({
  isLoading,
  isAuthenticated,
  children,
  component: Component,
  fallbackComponent: FallbackComponent,
  componentProps = {},
  ...rest
}) => {
  useCheckAuthentication()
  const isPartnerBookingFlow = usePartnerBookingFlowEnabled()

  const authenticatedUserAccessingAuthenticatedRoute = window.hasLoginToken

  if (
    (isLoading && !IS_SERVER) ||
    authenticatedUserAccessingAuthenticatedRoute
  ) {
    return <Loading />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return (
            <>
              <VideoCall />
              {!isPartnerBookingFlow && <PrivacyNotice />}
              <Component {...props} {...componentProps} />
            </>
          )
        }

        return FallbackComponent ? <FallbackComponent /> : <LoginPage />
      }}
    />
  )
}

export { useCheckAuthentication }
export default enhance(Authentication)
