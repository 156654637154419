import { trackEvent } from '@babylon/tracking/gtag'

import {
  trackingAppointmentIdNotificationTypes,
  trackingMessageNotificationTypes,
} from './notificationTypeGroups'

const trackNotificationClick = ({ itemType, message, itemId, actionUrl }) => {
  const shouldTrackMessage = trackingMessageNotificationTypes.some(
    (type) => type.toLowerCase() === itemType.toLowerCase()
  )

  const shouldTrackAppointmentId = trackingAppointmentIdNotificationTypes.some(
    (type) => type.toLowerCase() === itemType.toLowerCase()
  )

  const trackingEvent = {
    category: 'Notification',
    action: 'Click',
    label: itemType,
    destination: actionUrl,
  }

  if (shouldTrackMessage) {
    trackingEvent.notificationMessage = message
  }

  if (shouldTrackAppointmentId) {
    trackingEvent.appointmentID = itemId
  }

  trackEvent(trackingEvent)
}

export default trackNotificationClick
