import React from 'react'
import { InputField, Form } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'

import appMessages from '@/App.messages'
import { email as emailValidation } from '@/validation'
import messages from './messages'

interface Props {
  onSubmit: (values: { email: string }) => Promise<any>
}

const ResetPasswordForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const t = useFormatMessage()

  return (
    <>
      <p>{t(messages.resetInstructions)}</p>
      <Form
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmit}
        submitButtonText={t(messages.send)}
        submitButtonLoadingText={t(appMessages.loading)}
      >
        <InputField
          name="email"
          label={t(messages.email)}
          autoComplete="email"
          validate={emailValidation(t(messages.emailValidation))}
        />
      </Form>
    </>
  )
}

export default ResetPasswordForm
