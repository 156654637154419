import {
  VIDEOCALL_INCALL_STARTED,
  VIDEOCALL_INCALL_ENDED,
  VIDEOCALL_INCOMING_SHOW,
  VIDEOCALL_INCOMING_HIDE,
  VIDEOCALL_DROPPED,
} from './actionTypes'

const showIncomingCall = () => ({
  type: VIDEOCALL_INCOMING_SHOW,
})

const hideIncomingCall = () => ({
  type: VIDEOCALL_INCOMING_HIDE,
})

const videoCallStarted = () => ({
  type: VIDEOCALL_INCALL_STARTED,
})

const videoCallEnded = () => ({
  type: VIDEOCALL_INCALL_ENDED,
})

const videoCallDropped = () => ({
  type: VIDEOCALL_DROPPED,
})

export {
  showIncomingCall,
  hideIncomingCall,
  videoCallStarted,
  videoCallEnded,
  videoCallDropped,
}
