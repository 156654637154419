import { useCallback, useEffect, useState } from 'react'
import useEmbeddedSupportChat from '@/hooks/useSupportChat'

interface serviceNowLogoutProps {
  logoutUrl: any
  fetchOptions?: RequestInit
  onSuccess?: () => void
  onFailure?: () => void
}

interface serviceNowLoginProps {
  loginUrl: any
  fetchOptions?: RequestInit
  onSuccess?: () => void
  onFailure?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export const useLogoutLoginServiceNow = ({ userId }) => {
  const {
    data: serviceNowChatData,
    loading,
    error: embeddedChatError,
  } = useEmbeddedSupportChat(userId)
  const [requestLoading, setRequestLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const {
    login_url: loginSNUrl,
    logout_url: logoutChatUrl,
    embedded_chat_url: iframeUrl,
  } = serviceNowChatData || {}

  const serviceNowLogout = useCallback(
    async ({
      logoutUrl,
      fetchOptions,
      onSuccess,
      onFailure,
    }: serviceNowLogoutProps) => {
      try {
        await fetch(logoutUrl, {
          credentials: 'include',
          // we're able to use 'no-cors' because we don't care about the response from the logout endpoint.
          // This is a nicer way to force logout rather than actually having to visit the logout url inside
          // the iframe and perform redirects
          mode: 'no-cors',
          ...fetchOptions,
        })
        onSuccess?.()
      } catch (e) {
        onFailure?.()
      }
    },
    []
  )

  const serviceNowLogin = useCallback(
    async ({
      loginUrl,
      fetchOptions,
      onSuccess,
      onFailure,
    }: serviceNowLoginProps) => {
      try {
        await fetch(loginUrl, {
          credentials: 'include',
          // we're able to use 'no-cors' because we don't care about the response from the login endpoint.
          // This is a nicer way to authenticate properly rather than actually having to visit the login url inside
          // the iframe and perform redirects
          mode: 'no-cors',
          ...fetchOptions,
        })
        onSuccess?.()
      } catch (e) {
        onFailure?.()
      }
    },
    []
  )

  useEffect(() => {
    const authenticateServiceNow = async () => {
      await serviceNowLogout({
        logoutUrl: logoutChatUrl,
        onSuccess: () => {
          serviceNowLogin({
            loginUrl: loginSNUrl,
            onSuccess: () => {
              setRequestLoading(false)
            },
            onFailure: () => {
              setError('service now login call failed')
              setRequestLoading(false)
            },
          })
        },
        onFailure: () => {
          setError('service now logout call failed')
          setRequestLoading(false)
        },
      })
    }

    if (!loading && !embeddedChatError) {
      authenticateServiceNow()
    }
  }, [
    embeddedChatError,
    error,
    loading,
    loginSNUrl,
    logoutChatUrl,
    serviceNowLogin,
    serviceNowLogout,
  ])

  return {
    loading: requestLoading || loading,
    url: iframeUrl,
    apolloError: embeddedChatError,
    error,
  }
}
