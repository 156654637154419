import { defineMessages } from 'react-intl'

export default defineMessages({
  checkCorrect: {
    id: 'Form.checkCorrect',
    defaultMessage: 'Please check all fields are correct',
  },
  fillInRequired: {
    id: 'Form.fillInRequired',
    defaultMessage: 'Please fill in all required fields',
  },
  requiredField: {
    id: 'Form.requiredField',
    defaultMessage: 'Required Field',
  },
  passwordInvalid: {
    id: 'Form.passwordInvalid',
    defaultMessage:
      'Password must include 8 characters a number and uppercase and lowercase letters',
  },
  passwordTip: {
    id: 'Form.passwordTip',
    defaultMessage:
      'Use at least 8 characters including a number, an uppercase and a lowercase letter. A password is required to setup an account for you',
  },
  dobValidationError: {
    id: 'Form.dobValidationError',
    defaultMessage: 'This combination is not a valid date of birth',
  },
  dobTip: {
    id: 'Form.dobTip',
    defaultMessage:
      'Under 16s may be added to an adult account once registered',
  },
  phoneNumberTip: {
    id: 'Form.phoneNumberTip',
    defaultMessage:
      'Your mobile number is required to contact you in case of any emergencies',
  },
  continue: {
    id: 'Form.cta.continue',
    defaultMessage: 'Continue',
  },
  confirm: {
    id: 'Form.cta.confirm',
    defaultMessage: 'Confirm',
  },
  continueToIdVerification: {
    id: 'Form.cta.continueToIdVerification',
    defaultMessage: 'Continue to ID verification',
  },
  genderLabel: {
    id: 'Form.label.gender',
    defaultMessage: 'Gender',
  },
  genderMale: {
    id: 'Form.gender.male',
    defaultMessage: 'Male',
  },
  genderFemale: {
    id: 'Form.gender.female',
    defaultMessage: 'Female',
  },
  address: {
    id: 'Form.heading.address',
    defaultMessage: 'Address',
  },
  passwordLabel: {
    id: 'Form.label.passwordLabel',
    defaultMessage: 'Create Password',
  },
  phoneLabel: {
    id: 'Form.label.phone',
    defaultMessage: 'Phone',
  },
  emailLabel: {
    id: 'Form.label.email',
    defaultMessage: 'Email',
  },
  previousNames: {
    id: 'Form.label.previousNames',
    defaultMessage: 'Previous name(s)',
  },
  previousLastNames: {
    id: 'Form.label.previousLastNames',
    defaultMessage: 'Previous lastname/s',
  },
  firstNameLabel: {
    id: 'Form.label.firstName',
    defaultMessage: 'First name',
  },
  surnameLabel: {
    id: 'Form.label.surname',
    defaultMessage: 'Last name',
  },
  dateOfBirthLabel: {
    id: 'Form.label.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  dayLabel: {
    id: 'Form.label.day',
    defaultMessage: 'Day',
  },
  monthLabel: {
    id: 'Form.label.month',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'Form.label.year',
    defaultMessage: 'Year',
  },
  weightLabel: {
    id: 'Form.label.weight',
    defaultMessage: 'Weight (kg)',
  },
  heightLabel: {
    id: 'Form.label.height',
    defaultMessage: 'Height (cm)',
  },
  townCityLabel: {
    id: 'Form.label.townCity',
    defaultMessage: 'Town / City',
  },
  countyLabel: {
    id: 'Form.label.county',
    defaultMessage: 'County',
  },
  townOfBirthLabel: {
    id: 'Form.label.townOfBirth',
    defaultMessage: 'Town of birth',
  },
  countryOfBirthLabel: {
    id: 'Form.label.countryOfBirth',
    defaultMessage: 'Country of Birth',
  },
  streetAddressLabel: {
    id: 'Form.label.streetAddress',
    defaultMessage: 'Street address',
  },
  insertValidDate: {
    id: 'Form.label.dateOfBirth.errorMessage',
    defaultMessage: 'Please insert a valid date',
  },
  emergencyContactDetails: {
    id: 'Form.label.emergencyContactDetails',
    defaultMessage: 'Emergency contact details',
  },
  postcodeLabel: {
    id: 'Form.label.postcode',
    defaultMessage: 'Postcode',
  },
  homePostcodeLabel: {
    id: 'Form.label.homePostcode',
    defaultMessage: 'Home postcode',
  },
  countryPlaceholder: {
    id: 'Form.placeholder.country',
    defaultMessage: 'Country',
  },
  genderPlaceholder: {
    id: 'Form.placeholder.gender',
    defaultMessage: 'Select gender',
  },
  streetAddressTipInstruction: {
    id: 'Form.streetAddress.tipInstruction',
    defaultMessage: `Include flat number and building name if required`,
  },
  streetAddressTipExample: {
    id: 'Form.streetAddress.tipExample',
    defaultMessage: `Eg. 17 Hanbury Place, High Street`,
  },
})
