import moment from 'moment'

import { ConnectionEvent, ConnectionMetric } from '@babylon/consumer-api/types'

import ISODateString from '@/utils/rfc-3339-date'

export interface OpenTokCallEvent {
  type: string
  reason: string
}

export interface OpenTokCallMetric {
  stats: {
    timestamp: string
    audio: {
      bytesSent: number
      bytesReceived: number
      packetsLost: number
      packetsSent: number
      packetsReceived: number
    }
    video: {
      bytesSent: number
      bytesReceived: number
      packetsLost: number
      packetsSent: number
      packetsReceived: number
      frameRate: number
    }
  }
}

const parseCallEvent = (event: OpenTokCallEvent): ConnectionEvent => {
  const time: string = ISODateString(new Date())

  switch (event.type) {
    case 'streamCreated': {
      return {
        time,
        name: 'subscriberStreamCreated',
      }
    }
    case 'streamDestroyed': {
      return {
        time,
        name: 'subscriberStreamDestroyed',
      }
    }
    case 'videoDisableWarning': {
      return {
        time,
        name: 'subscriberVideoDisableWarning',
      }
    }
    case 'videoDisableWarningLifted': {
      return {
        time,
        name: 'subscriberVideoDisableWarningLifted',
      }
    }
    case 'videoDisabled': {
      return {
        time,
        name: 'subscriberVideoDisabled',
        body: JSON.stringify({ reason: event.reason }),
      }
    }
    case 'videoEnabled': {
      return {
        time,
        name: 'subscriberVideoEnabled',
        body: JSON.stringify({ reason: event.reason }),
      }
    }
    default: {
      return {
        time,
        name: event.type,
      }
    }
  }
}

const parseCallMetrics = (
  metrics: OpenTokCallMetric[],
  role: 'publisher' | 'subscriber'
): ConnectionMetric[] =>
  metrics.map((metric: OpenTokCallMetric) => ({
    role,
    time: ISODateString(moment(metric.stats.timestamp).toDate()),
    audio: {
      bytes_sent: metric.stats.audio.bytesSent,
      bytes_received: metric.stats.audio.bytesReceived,
      packets_lost: metric.stats.audio.packetsLost,
      packets_sent: metric.stats.audio.packetsSent,
      packets_received: metric.stats.audio.packetsReceived,
    },
    video: {
      bytes_sent: metric.stats.video.bytesSent,
      bytes_received: metric.stats.video.bytesReceived,
      packets_lost: metric.stats.video.packetsLost,
      packets_sent: metric.stats.video.packetsSent,
      packets_received: metric.stats.video.packetsReceived,
      frame_rate: Math.round(metric.stats.video.frameRate),
    },
  }))

export { parseCallEvent, parseCallMetrics }
