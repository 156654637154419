import { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'

import ValidatePromocodeQuery from '@/queries/ValidatePromocode'

const usePromotionInfoModal = (
  promoCode: string,
  regionId: number,
  skip: boolean = false
) => {
  const [isPromoCodeModalVisible, togglePromoCodeModal] = useState(false)
  const [promotionInfo, setPromotionInfo] = useState()

  const { data: promoData } = useQuery(ValidatePromocodeQuery, {
    variables: { code: promoCode, region_id: regionId },
    skip: skip || !promoCode || !regionId,
  })

  if (!isPromoCodeModalVisible && promoData?.validatePromocode) {
    const { promotion, membership } = promoData.validatePromocode
    const promoInfo = {
      ...(promotion || membership),
      isMembership: !!membership,
    }
    setPromotionInfo(promoInfo)
    togglePromoCodeModal(true)
  }

  return {
    isPromoCodeModalVisible,
    togglePromoCodeModal,
    promotionInfo,
  }
}

export default usePromotionInfoModal
