import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './IconRow.module.scss'

const IconRow = ({ children, iconLeft, iconRight, className }) => (
  <div className={cx(styles.IconRow, className)}>
    <span className={styles.iconLeft}>{iconLeft}</span>
    {children && <div className={styles.content}>{children}</div>}
    <span className={styles.iconRight}>{iconRight}</span>
  </div>
)

IconRow.propTypes = {
  /**
   * The text content of the row
   */
  children: PropTypes.node,
  /**
   * The icon imported from this component library
   */
  iconLeft: PropTypes.node,
  /**
   * The icon imported from this component library
   */
  iconRight: PropTypes.node,
  /**
   * The CSS class
   */
  className: PropTypes.string,
}

IconRow.defaultProps = {
  children: '',
  iconLeft: null,
  iconRight: null,
  className: '',
}

export default IconRow
