import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Title.module.scss'

const Title = ({ type, size, children, ...props }) => {
  const tag = type || `h${size}`

  return React.createElement(
    tag,
    {
      ...props,
      type,
      size,
      className: classNames(
        styles.base,
        styles[`size-${size}`],
        props.className
      ),
    },
    children
  )
}

Title.propTypes = {
  /**
   * The element or component being used to style.
   * Default: a heading size based on the size specified.
   */
  type: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /*
   * The title size
   */
  size: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
  /**
   * Children, typically a string but left as a node so you can include icons ect.
   */
  children: PropTypes.node,
  /**
   * Optional additional classnames
   */
  className: PropTypes.string,
}

export default Title
