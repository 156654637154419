import { SET_EMAIL } from './actionTypes'

const initialState = {
  email: null,
}

const getStarted = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    default:
      return state
  }
}

export default getStarted
