import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { seperatorTheme } from './theme'
import { IMedkitComponent } from '..'

export interface SeperatorProps {
  className?: string
}

const Seperator: IMedkitComponent<SeperatorProps, typeof seperatorTheme> = ({
  className,
}) => <div className={cn(styles.seperator, className)} role="separator" />

Seperator.theme = seperatorTheme

export default Seperator
