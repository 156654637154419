import React, { createContext, useContext } from 'react'
import { Box } from '@babylon/web-platform-ui'
import styles from './Products.module.scss'
import { ProductCard } from '@/programmes/components/Products/ProductCard/ProductCard'
import { Product } from '@/programmes/components/Products/models'

interface ProductsProps {
  products: Product[]
  successPath: string
  cancelPath: string
  discountCodeId?: string
}

type PathContextType = {
  successPath: string
  cancelPath: string
  discountCodeId?: string
}

const ProductDataContext = createContext<PathContextType | undefined>(undefined)

export const useProductData = () => {
  const context = useContext(ProductDataContext)

  if (context === undefined) {
    throw new Error('useProductsData must be used within a PathProvider')
  }

  return context
}

export const ProductDataProvider: React.FC<PathContextType> = ({
  children,
  successPath,
  cancelPath,
  discountCodeId,
}) => (
  <ProductDataContext.Provider
    value={{ successPath, cancelPath, discountCodeId }}
  >
    {children}
  </ProductDataContext.Provider>
)

const Products = ({
  products,
  successPath,
  cancelPath,
  discountCodeId,
}: ProductsProps) => (
  <ProductDataProvider
    successPath={successPath}
    cancelPath={cancelPath}
    discountCodeId={discountCodeId}
  >
    <Box className={styles.productContainer}>
      {products &&
        products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
    </Box>
  </ProductDataProvider>
)

export default Products
