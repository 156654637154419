import moment from 'moment'

export default ({ day, month, year } = {}, minDate, maxDate) => {
  if (day && month && year) {
    const date = moment()
      .date(day)
      .month(month - 1)
      .year(year)

    if (maxDate && minDate) {
      return date.isBetween(minDate, maxDate, 'day', [])
    }

    if (minDate) {
      return date.isSameOrAfter(minDate, 'day')
    }

    if (maxDate) {
      return date.isSameOrBefore(maxDate, 'day')
    }
  }
  return true
}
