import React, { useEffect, useState, useRef } from 'react'
import lottie from 'lottie-web'
import classNames from 'classnames/bind'
import completeLottie from './completeAnimation.json'
import styles from './index.module.scss'

type Props = {
  onAnimationFinish: () => void
}
const cx = classNames.bind(styles)

const LottieAnimation = ({ animationData }) => {
  // Explicitly define the type of the ref to be either HTMLElement or null
  const animationContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let anim

    if (animationContainer.current) {
      anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData,
      })
    }

    // Clean up
    return () => {
      if (anim) {
        anim.destroy()
      }
    }
  }, [animationData])

  return <div ref={animationContainer} className={cx('complete-animation')} />
}

const CompleteAnimation: React.FC<Props> = ({ onAnimationFinish }) => {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [onAnimationFinish])

  return <LottieAnimation animationData={completeLottie} />
}

type PlayAnimationType = {
  AnimationScreen: React.FC<Props>
  isPlaying: boolean
  setIsPlaying: (isPlaying: boolean) => void
}

const useCompleteAnimation = (showAnimation?: boolean): PlayAnimationType => {
  const [isPlaying, setIsPlaying] = useState<boolean>(!!showAnimation)

  return {
    AnimationScreen: CompleteAnimation,
    isPlaying,
    setIsPlaying,
  }
}

export default useCompleteAnimation
