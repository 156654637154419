import { useEffect, useState } from 'react'
import WebViewMode from '../../utils/WebViewMode'
import { Answer } from '../components/Form/types'
import { useQuestionnairesEnabled } from '../../redux/selectors'

const QUESTIONNAIRE_EVENTS = {
  STARTED_QUESTIONNAIRE: 'started_questionnaire',
  GO_TO_PREVIOUS_STEP: 'go_to_previous_step',
}

const useQuestionnaireWebViewHandler = <AnswerT = Answer>(
  answers: { [p: string]: AnswerT },
  handlePrevious: () => void
) => {
  const [startedFlow, setStartedFlow] = useState(false)
  const questionnairesEnabled = useQuestionnairesEnabled()

  const enabled = questionnairesEnabled && WebViewMode.isWebViewMode()

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (!startedFlow && Object.keys(answers).length > 0) {
      const event = {
        type: QUESTIONNAIRE_EVENTS.STARTED_QUESTIONNAIRE,
      }
      WebViewMode.sendMessage(JSON.stringify(event))
      setStartedFlow(true)
    }
  }, [startedFlow, answers, setStartedFlow, enabled])

  useEffect(() => {
    if (!enabled) {
      return () => {}
    }

    const removeListener = WebViewMode.onMessage((message) => {
      const dataAsJson = JSON.parse(message)

      switch (dataAsJson.type) {
        case QUESTIONNAIRE_EVENTS.GO_TO_PREVIOUS_STEP: {
          handlePrevious()
          break
        }
        default: {
          console.log('Unknown message type')
          break
        }
      }
    })

    return () => removeListener()
  }, [handlePrevious, enabled])
}

export default useQuestionnaireWebViewHandler
