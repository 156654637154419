export type SurgeryData = {
  Organisations: {
    Name: string
    PostCode: string
    OrgId: string
  }[]
}

export type SearchItem = {
  name: string
  displayName: string
  postcode: string
}

// converts title case to camel case
export const parseSurgeryData = (data: SurgeryData): SearchItem[] =>
  (data?.Organisations || []).map((surgery) => ({
    name: surgery.Name,
    displayName: `${surgery.Name} - ${surgery.PostCode}`,
    postcode: surgery.PostCode,
  }))

export const removeDuplicates = (array: SearchItem[]) =>
  array.filter(
    (item, i, a) =>
      i ===
      a.findIndex(
        ({ name, postcode }) => item.name === name && item.postcode === postcode
      )
  )
