import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { TrackingActionType } from '@babylon/tracking/react'
import useTracking from '@/tracking/useTracking'

export const useGoToAccountOverview = () => {
  const history = useHistory()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    trackEvent({
      screenTitle: 'home-page/welcome-component',
      eventAction: 'account-overview',
      actionType: TrackingActionType.click,
    })
    history.push('/account')
  }, [history, trackEvent])
}

export const useGoToBookAppointment = () => {
  const history = useHistory()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    trackEvent({
      screenTitle: 'home-page/welcome-component',
      eventAction: 'book-appointment',
      actionType: TrackingActionType.click,
    })
    history.push('/consultations/new')
  }, [history, trackEvent])
}

export const useGoToWeightManagement = () => {
  const history = useHistory()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    trackEvent({
      screenTitle: 'home-page/welcome-component',
      eventAction: 'go-to-weight-management',
      actionType: TrackingActionType.click,
    })
    history.push('/weight-loss/dashboard')
  }, [history, trackEvent])
}

export const useGoToUpgradePage = () => {
  const history = useHistory()
  const { trackEvent } = useTracking()

  return useCallback(() => {
    trackEvent({
      screenTitle: 'home-page/welcome-component',
      eventAction: 'go-to-upgrade-plans',
      actionType: TrackingActionType.click,
    })
    history.push('/account/0/membership/plans')
  }, [history, trackEvent])
}
