import { defineMessages } from 'react-intl'

export default defineMessages({
  emailLabel: {
    id: 'programmes.form.collectEmail.emailLabel',
    defaultMessage: 'Email',
  },
  checkboxLabel: {
    id: 'programmes.form.collectEmail.checkboxLabel',
    defaultMessage: 'Send me health ideas, news and promotions',
  },
  invalidEmailError: {
    id: 'programmes.form.collectEmail.invalidEmail',
    defaultMessage: 'Please type in a valid email address',
  },
  privacyPolicyLink: {
    id: 'programmes.form.collectEmail.privacyPolicyLink',
    defaultMessage: 'Privacy Policy',
  },
  privacyText: {
    id: 'programmes.form.collectEmail.privacyText',
    defaultMessage:
      'Find out how eMed collects, uses and manages your data in our {privacyPolicyLink}',
  },
})
