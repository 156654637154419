import gql from 'graphql-tag'

export default gql`
  query UpcomingAppointmentNotification($id: ID!) {
    upcomingAppointmentNotification(id: $id) {
      appointmentId
      session
      unique_token
      consultant_name
      consultant_avatar_url
      opentok_key
      __typename
    }
    __typename
  }
`
