import React, { useState, useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import QuestionBody from '../QuestionBody'
import styles from './TextInput.module.scss'
import messages from './messages'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'
import TextInputOnly from './TextInputOnly'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import Error from '@/programmes/components/FormV2/components/Error/Error'

interface TextInputProps {
  id?: string
  label?: string
  hiddenLabel?: boolean
  type?: 'text' | 'number'
  body?: string | Array<string> | React.ReactNode
  error?: boolean
  required?: boolean
  value?: string
  min?: number | string
  max?: number | string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  hiddenLabel = false,
  body,
  id,
  type = 'text',
  error = false,
  required = false,
  value,
  min,
  max,
  placeholder,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [showError, setShowError] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)

    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    error && setShowError(error)
  }, [error])

  useEffect(() => {
    if (inputValue && showError) {
      setShowError(false)
    }
  }, [inputValue, showError])

  const onBlur = () => {
    if (required && !inputValue) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  return (
    <div
      className={styles.container}
      id={
        hiddenLabel && id ? `${STEP_ELEMENT_ID_PREFIX}-label-${id}` : undefined
      }
    >
      {!hiddenLabel && label && (
        <QuestionTitle content={label} id={id} type="label" />
      )}

      {body && <QuestionBody body={body} id={id} />}

      <TextInputOnly
        type={type}
        onBlur={onBlur}
        id={label}
        min={min}
        max={max}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        name={label}
        aria-label={hiddenLabel ? label : undefined}
        aria-invalid={showError}
        aria-describedby={`${label}ErrorHint`}
      />

      {showError && <Error>{formatMessage(messages.errorMessage)}</Error>}
    </div>
  )
}

export default TextInput
