import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Link.module.scss'

/**
 * A versatile, styled, link component. Pass in whatever props and children
 * you like, they will be styled with low specificity.
 */

const Link = ({ children, type, ...props }) =>
  React.createElement(
    type,
    {
      ...props,
      rel:
        props.rel || (props.target === '_blank' ? 'noreferrer noopener' : null), // https://mathiasbynens.github.io/rel-noopener/
      className: classNames(styles.link, props.className),
    },
    children
  )

Link.propTypes = {
  /**
   * Children, typically a string but left as a node so you can include icons ect.
   */
  children: PropTypes.node,

  /**
   * The type of component that should be rendered. This allow you to pass in
   * custom link components (react-router for exmaple) and pass in whichever props
   * you need to make routing work within your app.
   */
  type: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Optional additional classnames
   */
  className: PropTypes.string,

  /**
   * Optional target of the link, like a normal anchor element.
   */
  target: PropTypes.string,

  /**
   * Optional relationship of the target object to the link object, like a normal anchor element.
   */
  rel: PropTypes.string,
}

Link.defaultProps = {
  children: '',
  type: 'a',
  className: '',
  target: null,
  rel: null,
}

export default Link
