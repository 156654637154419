import React, { ElementType, ReactNode, Ref } from 'react'
import cn from 'classnames'
import { IMedkitComponent } from '..'
import ListItem from './ListItem'
import { listTheme } from './theme'
import styles from './index.module.scss'

export type ListVariant = 'ol' | 'ul'
export type definitionListItemProps = {
  term: string
  definition: string
}
export type listItemProps = string | number

interface ListProps extends React.HTMLProps<HTMLDivElement> {
  /** Extra class name(s) to be applied to the component */
  className?: string
  listItemClassName?: string
  /** The variant of the component */
  variant: ListVariant
  listData?: listItemProps[]
  children?: ReactNode | ReactNode[]
  forwardedRef?: Ref<{}>
}

export const variantToElementMapping: { [key in ListVariant]: ElementType } = {
  ol: 'ol',
  ul: 'ul',
}

export const List: IMedkitComponent<ListProps, typeof listTheme> = ({
  className,
  listItemClassName,
  variant,
  listData = [],
  children,
  forwardedRef,
  ...props
}) => {
  const Component: ElementType = variantToElementMapping[variant]

  return (
    <Component
      className={cn(styles.List, styles[`List--${variant}`], className)}
      ref={forwardedRef}
      {...props}
    >
      {children ||
        listData.map((listItemValue, listItemIndex) => (
          <ListItem
            key={`${variant}-${listItemValue}-${listItemIndex}`}
            className={cn(styles.List__item, listItemClassName)}
          >
            {listItemValue}
          </ListItem>
        ))}
    </Component>
  )
}

List.theme = listTheme

export default React.forwardRef<{}, Omit<ListProps, 'forwardedRef'>>(
  (props, ref) => <List {...props} forwardedRef={ref} />
)
