import { NavigationClose, NavigationConfirm } from '@babylon/icons'
import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Badge } from '@/components/ui/badge'
import { cn } from '@/components/utils'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { ProductGroup } from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import messages from '@/programmes/WeightLoss/messages'

export interface FeaturesTableProps {
  productGroup: ProductGroup
}

export const FeaturesTable = ({ productGroup }: FeaturesTableProps) => {
  const t = useFormatMessage()

  return (
    <Table className="tw-border tw-border-solid tw-border-gray-300 tw-rounded-xl tw-border-separate tw-border-spacing-0">
      <TableHeader>
        <TableRow className="hover:tw-bg-transparent">
          <TableHead className="tw-rounded-tl-xl tw-p-1 tw-text-center tw-border-0 tw-border-b tw-border-r tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-w-2/4">
            <div className="tw-h-[100px] tw-w-[100px] tw-inline-block">
              <img
                className="tw-h-full tw-w-full tw-object-contain"
                src={productGroup.icon}
                alt={productGroup.label}
              />
            </div>
          </TableHead>

          <TableHead className="tw-p-1 tw-text-center tw-border-0 tw-border-b tw-border-r tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-w-1/4 tw-min-w-[90px] tw-align-top">
            <div className="tw-transform tw-translate-y-4 sm:tw-translate-y-7">
              <span className="tw-block tw-text-xl tw-font-bold tw-text-foreground">
                Weight Loss
              </span>
            </div>
          </TableHead>

          <TableHead className="tw-rounded-tr-xl tw-p-1 tw-text-center tw-border-0 tw-border-b tw-border-solid tw-border-gray-3500 tw-w-1/4 tw-min-w-[90px] tw-align-top">
            <div className="tw-transform tw-translate-y-4 sm:tw-translate-y-7">
              <span className="tw-block tw-text-xl tw-font-bold tw-text-foreground">
                Weight Loss+
              </span>
              <Badge
                className="tw-text-[0.5rem] tw-bg-accent tw-text-primary tw-uppercase tw-py-[0.1rem] md:tw-py-1 tw-px-[0.5rem] md:tw-px-2 tw-leading-3"
                variant="outline"
              >
                {t(messages.checkoutRecommended)}
              </Badge>
            </div>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell className="tw-bg-primary tw-text-white tw-p-3" colSpan={4}>
            {t(messages.checkoutProductTableHeading)}
          </TableCell>
        </TableRow>

        {productGroup.features.map((feature, index) => {
          const isLastRow = index === productGroup.features.length - 1

          return (
            <TableRow className="hover:tw-bg-transparent" key={feature.title}>
              <TableCell
                className={cn(
                  'tw-p-2 tw-border-0 tw-border-r tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-w-2/4 tw-max-w-0',
                  {
                    'tw-border-b': !isLastRow,
                    'tw-rounded-bl-xl': isLastRow,
                  }
                )}
              >
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem
                    value="item-1"
                    className="[&>h3]:tw-my-2 md:[&>h3]:tw-my-4"
                  >
                    <AccordionTrigger className="tw-bg-transparent tw-text-left tw-text-sm md:tw-text-base tw-font-normal tw-border-0 hover:tw-no-underline tw-py-1">
                      {feature.title}
                    </AccordionTrigger>

                    <AccordionContent className="tw-text-sm tw-pl-1 tw-pr-12 tw-break-words">
                      {feature.description}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </TableCell>

              <TableCell
                className={cn(
                  'tw-border-0 tw-border-r tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-w-1/4 tw-text-center tw-min-w-[90px]',
                  { 'tw-border-b': !isLastRow }
                )}
              >
                {feature.lite ? (
                  <NavigationConfirm className="tw-fill-green-500 tw-transform tw-translate-y-1" />
                ) : (
                  <NavigationClose className="tw-fill-red-700 tw-transform tw-translate-y-1" />
                )}
              </TableCell>

              <TableCell
                className={cn(
                  'tw-border-0 tw-border-solid tw-border-gray-300 tw-w-1/4 tw-text-center tw-min-w-[90px]',
                  {
                    'tw-border-b': !isLastRow,
                    'tw-rounded-br-xl': isLastRow,
                  }
                )}
              >
                {feature.ultra ? (
                  <NavigationConfirm className="tw-fill-green-500 tw-transform tw-translate-y-1" />
                ) : (
                  <NavigationClose className="tw-fill-red-700 tw-transform tw-translate-y-1" />
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
