import {
  SETUP_QUESTIONNAIRE_VERSION_KEY,
  SETUP_QUESTIONNAIRE_VERSION_V2,
} from '@/programmes/WeightLoss/hooks/useHandlePageVisitAndRedirection'
import preScreeningSteps from './preScreeningSteps'
import preScreeningStepsV2 from './preScreeningStepsV2'
import { setupRoutes, setupV2Routes } from '../routes'

const getPreScreeningSteps = () => {
  const { pathname } = window.location

  if (pathname === setupV2Routes.questionnaire) {
    return preScreeningStepsV2
  }

  if (pathname === setupRoutes.questionnaire) {
    return preScreeningSteps
  }

  const savedQuestionnaireVersion = localStorage.getItem(
    SETUP_QUESTIONNAIRE_VERSION_KEY
  )

  if (savedQuestionnaireVersion === SETUP_QUESTIONNAIRE_VERSION_V2) {
    return preScreeningStepsV2
  }

  return preScreeningSteps
}

export default getPreScreeningSteps
