import { createSelector } from 'reselect'

const selectRegistrationStarted = (state) =>
  state.auth.register.registrationStarted
const selectRegistrationCompleted = (state) =>
  state.auth.register.registrationCompleted
const selectRegistrationMethod = (state) => state.auth.register.signupMethod
const selectPromocode = (state) => state.auth.register.promocode
const selectShowPromotionModal = (state) =>
  state.auth.register.showPromotionModal

const startedRegistrationMethod = createSelector(
  [selectRegistrationStarted, selectRegistrationMethod],
  (registrationStarted, signupMethod) => registrationStarted && signupMethod
)

const completedRegistrationMethod = createSelector(
  [selectRegistrationCompleted, selectRegistrationMethod, selectPromocode],
  (registrationCompleted, signupMethod, promocode) =>
    registrationCompleted && { signupMethod, promocode }
)

export {
  selectRegistrationStarted,
  startedRegistrationMethod,
  completedRegistrationMethod,
  selectPromocode,
  selectShowPromotionModal,
}
