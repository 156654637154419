/* eslint-disable import/prefer-default-export */
/**
 * Implements Google Analytics tracking via `gtag.js`
 *
 * https://developers.google.com/gtagjs
 *
 * GA Data Layer must be initialised before sending any events.
 *
 * Exported methods are wrapped to check if the library has been
 * initialised and will throw otherwise.
 *
 * ```ts
 * import initialise from '@babylon/tracking/gtag'
 *
 * initialise(window.gtag, 'UA-XXXXX-XX', 'babylon', knownUris)
 * trackEvent({ category: 'login', action: 'started' })
 * ```
 */

import initialiseDataLayer from './initialise'
import createEventTracker from './trackEvent'
import createPageViewTracker, { PageViewTracker } from './trackPageView'
import createUserTracker, { UserTracker } from './trackUser'
import { KnownUris, CustomDimensionsMap, EventTracker } from './types'

let initialised = false
let eventTracker: EventTracker
let pageViewTracker: PageViewTracker
let userTracker: UserTracker

const initialise = (
  gtag: Gtag.Gtag,
  GA_MEASUREMENT_ID: string,
  appName: string,
  knownUris: KnownUris,
  customMap?: CustomDimensionsMap
) => {
  if (!initialised && Boolean(GA_MEASUREMENT_ID)) {
    initialiseDataLayer({ gtag, GA_MEASUREMENT_ID, appName, customMap })
    eventTracker = createEventTracker(gtag)
    pageViewTracker = createPageViewTracker(
      gtag,
      GA_MEASUREMENT_ID,
      knownUris,
      customMap
    )
    userTracker = createUserTracker(gtag, GA_MEASUREMENT_ID, customMap)
    initialised = true
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `@babylon/tracking/gtag has already been initialised with ID: ${GA_MEASUREMENT_ID}`
    )
  }
}

const checkIfInitialised = (): void => {
  if (!initialised) {
    throw new Error(
      '@babylon/tracking/gtag needs to be initialised before any tracking could be fired'
    )
  }
}

const trackEvent: EventTracker = (params) => {
  checkIfInitialised()
  eventTracker(params)
}

const trackPageView: PageViewTracker = (path) => {
  checkIfInitialised()
  pageViewTracker(path)
}

const trackUser: UserTracker = (id, dimensionName) => {
  checkIfInitialised()
  userTracker(id, dimensionName)
}

export { initialise, trackEvent, trackPageView, trackUser }

export * from './types'
