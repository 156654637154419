/* eslint-disable import/prefer-default-export */
import { CookieGroup, parseOneTrustCookieGroups } from '@/tracking/one-trust'

export interface ProspectProfileClient {
  setProspectOptedIn(emailAddress: string): void
  setProspectUnsubscribed(emailAddress: string): void
  addExternalIdToProspect(externalId: string): void
}
let braze: typeof import('@braze/web-sdk') | undefined

const getBraze = async (): Promise<Exclude<typeof braze, undefined>> => {
  // importing the braze SDK breaks SSR
  // This is a workaround using dynamic imports to prevent the SDK from being imported on the server
  if (braze === undefined) {
    braze = await import('@braze/web-sdk')

    return braze
  }

  return Promise.resolve(braze)
}

const getInitizalizedBraze = () =>
  getBraze().then((braze) => {
    const activeCookieGroups = parseOneTrustCookieGroups(
      window.OnetrustActiveGroups?.split(',') ?? []
    )
    const result = braze.initialize(
      window.babylonConfig.PROSPECT_BRAZE_API_KEY,
      {
        baseUrl: window.babylonConfig.PROSPECT_BRAZE_SDK_ENDPOINT,
        noCookies: !activeCookieGroups.includes(CookieGroup.TARGETING),
      }
    )

    if (!result) {
      console.warn('Braze SDK failed to initialize')
    }

    braze.openSession()

    return braze
  })

export const BRAZE_PROSPECT_PROFILE_CLIENT: ProspectProfileClient = {
  setProspectOptedIn(emailAddress: string) {
    getInitizalizedBraze().then((braze) => {
      const user = braze.getUser()
      user?.setEmail(emailAddress)
      user?.setEmailNotificationSubscriptionType('opted_in')
    })
  },

  setProspectUnsubscribed(emailAddress: string) {
    getInitizalizedBraze().then((braze) => {
      const user = braze.getUser()
      user?.setEmail(emailAddress)
      user?.setEmailNotificationSubscriptionType('unsubscribed')
    })
  },

  addExternalIdToProspect(externalId: string) {
    getInitizalizedBraze().then((braze) => {
      braze.changeUser(externalId)
    })
  },
}
