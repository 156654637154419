import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Text, ButtonSimple, List, EditorialLayout } from '@babylon/medkit'
import messages from './PostConsultationPage.messages'

const PostConsultation = () => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <EditorialLayout>
      <Text variant="h1" className="PageTitle">
        {intl.formatMessage(messages.postConsultationTitle)}
      </Text>
      <Text tag="h2" variant="h4">
        {intl.formatMessage(messages.whatNextTitle)}
      </Text>
      <Text variant="body">
        {intl.formatMessage(messages.whatNextSubTitle)}
      </Text>
      <List
        listData={[
          intl.formatMessage(messages.whatNextFirstBulletPoint),
          intl.formatMessage(messages.whatNextSecondBulletPoint),
        ]}
        variant="ul"
      />
      <ButtonSimple onClick={() => history.push('/')}>
        {intl.formatMessage(messages.signInCta)}
      </ButtonSimple>
    </EditorialLayout>
  )
}

export default PostConsultation
