import React from 'react'

const SectionTitle = ({ text }: { text: string }) => (
  <>
    <div className="tw-w-full tw-flex tw-flex-col tw-items-start tw-mt-8 tw-mb-2">
      <h2 className="tw-text-2xl tw-font-bold">{text}</h2>
    </div>
  </>
)

export default SectionTitle
