import gql from 'graphql-tag'

import { PrivacyNoticeFragment } from '../fragments'

const PrivacySettingsQuery = gql`
  query User($id: ID!) {
    patient(id: $id) {
      id
      privacySettings {
        ...privacy_notice
      }
    }
  }
  ${PrivacyNoticeFragment}
`

export default PrivacySettingsQuery
