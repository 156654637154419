import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { displayFlashMessage } from '@/redux/flash/actions'
import { feedbacks } from '@/redux/flash/constants'

const withAlertDisplay = connect(
  // mapStateToProps -> empty
  () => ({}),
  // mapDispatchToProps
  (dispatch) => bindActionCreators({ displayFlashMessage }, dispatch),
  // mergeProps
  (_, { displayFlashMessage }, ownProps) => ({
    ...ownProps,
    displayError: (text) => displayFlashMessage(text, feedbacks.negative),
    displaySuccess: (text) => displayFlashMessage(text, feedbacks.positive),
  })
)

export default withAlertDisplay
