import pickBy from 'lodash.pickby'
import RequestIdGenerator from '../request-id-generator/RequestIdGenerator'
import getLoginCookie from './get-login-cookie'

type Props = {
  appId: string
  appVersion?: string
  acceptLanguage?: string
}

interface IHeaders {
  'content-type'?: string
  'X-App-Name'?: string
  'babylon-request-id'?: string
  'X-Security-Token'?: string
  'X-Platform'?: string
  'X-App-Version'?: string
  'Accept-Language'?: string
}

interface IGetHeaders {
  (properties: Props): IHeaders
}

let idGenerator: RequestIdGenerator

const getHeaders: IGetHeaders = ({ appId, appVersion, acceptLanguage }) => {
  idGenerator = idGenerator || new RequestIdGenerator({ app: appId })

  const cookie = getLoginCookie('autologin_info')
  const csrfToken = cookie && cookie.csrf_token
  const babylonRequestId = idGenerator.generate()

  return pickBy(
    {
      'content-type': 'application/json',
      'X-App-Name': appId,
      'babylon-request-id': babylonRequestId,
      'X-Security-Token': csrfToken,
      'X-Platform': 'web',
      'X-App-Version': appVersion,
      'Accept-Language': acceptLanguage,
    },
    Boolean
  )
}

export default getHeaders
