import { defineMessages } from 'react-intl'

export default defineMessages({
  invalidDate: {
    id: 'DatePicker.invalidDate',
    defaultMessage: 'Invalid date',
  },
  day: {
    id: 'DatePicker.day',
    defaultMessage: 'Day',
  },
  month: {
    id: 'DatePicker.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'DatePicker.year',
    defaultMessage: 'Year',
  },
})
