import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'ResetPasswordPage.pageTitle',
    defaultMessage: 'Reset Password',
  },
  resetInstructions: {
    id: 'ResetPasswordPage.resetInstructions',
    defaultMessage:
      'Forgotten password? Enter your email address and we will send to you an email with the password reset instructions.',
  },
  email: {
    id: 'ResetPasswordPage.email',
    defaultMessage: 'Email',
  },
  emailValidation: {
    id: 'ResetPasswordPage.emailValidation',
    defaultMessage: 'Please provide a valid email address',
  },
  send: {
    id: 'ResetPasswordPage.send',
    defaultMessage: 'Send',
  },
  success: {
    id: 'ResetPasswordPage.success',
    defaultMessage:
      'You should receive an email with password reset instructions shortly',
  },
  notReceived: {
    id: 'ResetPasswordPage.notReceived',
    defaultMessage: "Didn't receive an email?",
  },
  tryAgain: {
    id: 'ResetPasswordPage.tryAgain',
    defaultMessage: 'Try again',
  },
  help: {
    id: 'ResetPasswordPage.help',
    defaultMessage: 'Help',
  },
  doYouHaveProblems: {
    id: 'ResetPasswordPage.doYouHaveProblems',
    defaultMessage: 'Do you have problems?',
  },
})
