import classNames from 'classnames'
import React, { forwardRef } from 'react'
import styles from './TextInput.module.scss'

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'type'> & {
  type?: 'text' | 'email' | 'url' | 'tel' | 'password' | 'search' | 'number'
}

const TextInputOnly = forwardRef<HTMLInputElement, Props>(
  ({ type = 'text', ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      type={type}
      className={classNames(styles.textInput)}
    />
  )
)

export default TextInputOnly
