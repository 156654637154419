import { useState, ChangeEvent } from 'react'
import { AddressComponentState } from './types'

export const useFormState = () => {
  const [inputs, setInputs] = useState({})
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }
  return {
    handleInputChange,
    inputs,
  }
}

export const useComponentState = (initialState: any) => {
  const [componentState, setState] = useState(initialState)
  const setComponentState = (patch: AddressComponentState) =>
    setState((state: AddressComponentState) => ({ ...state, ...patch }))
  return [componentState, setComponentState]
}
