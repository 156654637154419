import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React, { PureComponent } from 'react'
import asFormControl from '../asFormControl'
import styles from './textarea.module.scss'

const cx = classNames.bind(styles)

class Textarea extends PureComponent {
  setRef = (ref) => {
    this.ref = ref
  }

  handleChange = (event) => {
    const { autoresize, onChange } = this.props

    if (autoresize) {
      this.ref.style.height = 'auto'
      this.ref.style.height = this.ref.scrollHeight + 'px'
    }

    onChange && onChange(event)
  }

  render() {
    const {
      errors,
      success,
      autoresize,
      className,
      label,
      value,
      defaultValue,
      ...rest
    } = this.props

    const errorsClass = errors.length > 0 ? 'textarea--error' : ''
    const successClass = success ? 'textarea--success' : ''

    return (
      <div className={styles['input-wrapper']}>
        {label && (
          <label htmlFor={label} className={cx('label')}>
            {label}
          </label>
        )}

        <textarea
          data-testid="textarea"
          {...rest}
          value={value || defaultValue}
          ref={this.setRef}
          id={label}
          onChange={this.handleChange}
          className={cx(
            'textarea',
            className,
            { autoresize },
            errorsClass,
            successClass
          )}
        />
      </div>
    )
  }
}

Textarea.propTypes = {
  /** Automatically resize textarea depending on the content height */
  autoresize: PropTypes.bool,
  /** The label for the input */
  label: PropTypes.node,
  /** The array of errors on the input */
  errors: PropTypes.arrayOf(PropTypes.string),
  /** The success message */
  success: PropTypes.string,
}

Textarea.defaultProps = {
  autoresize: false,
  label: null,
  errors: [],
  success: '',
}

export default asFormControl(Textarea)
