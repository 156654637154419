import { isValidDate } from '@babylon/medkit'

interface DateValidation {
  requiredMessage: string
  invalidDateMessage: string
}

export default ({ requiredMessage, invalidDateMessage }: DateValidation) => (
  value: string
) => {
  if (!value) {
    return requiredMessage
  }

  if (!isValidDate(value)) {
    return invalidDateMessage
  }

  return undefined
}
