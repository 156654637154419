import React from 'react'
import { Skeleton } from '@/components/ui/skeleton'

const LoadingView = () => (
  <div className="tw-flex tw-items-start tw-space-x-4 tw-mb-4">
    <Skeleton className="tw-h-[125px] tw-w-[125px] tw-rounded-xl" />
    <div className="tw-space-y-2">
      <Skeleton className="tw-h-4 tw-w-[200px]" />
      <Skeleton className="tw-h-4 tw-w-[200px]" />
      <Skeleton className="tw-h-4 tw-w-[150px]" />
    </div>
  </div>
)

export default LoadingView
