import React, { useRef } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Answer, Question, RadioGroupValue } from '../../types'
import RadioButton from '../RadioButton'
import InputBody from '../InputBody'
import styles from './RadioGroup.module.scss'
import messages from './messages'
import FollowUpQuestion from '../FollowUpQuestion'
import useRadioGroupFocusHandlers from './useRadioGroupFocusHandlers'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface Props {
  id: string
  label?: string
  body?: string | Array<string>
  footer?: string | Array<string>
  error?: boolean
  options: Array<{
    label: string
    value: any
    extraLabel?: string
    followUpQuestion?: Question
  }>
  selectedOption: RadioGroupValue
  onChange: (selectedOption: RadioGroupValue) => void
}

const RadioGroup: React.FC<Props> = ({
  id,
  label,
  body,
  footer,
  error = false,
  options,
  selectedOption = { value: '' },
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  const {
    tabIndexableOptionIndex,
    listenerBuilder,
  } = useRadioGroupFocusHandlers({
    selectedValue: selectedOption.value,
    options,
    fieldsetRef,
    onChooseOptionIndex: (i) => onChange(options[i]),
  })

  return (
    <fieldset className={styles.containerV1} ref={fieldsetRef}>
      {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
      <legend
        className={styles.titleV1}
        id={id ? `${STEP_ELEMENT_ID_PREFIX}-title-${id}` : undefined}
      >
        {label}
      </legend>

      {body && <InputBody body={body} id={id} />}

      {options.map((option, i) => {
        const listeners = listenerBuilder(i)

        return (
          <div
            key={`${label}-${option.value}`}
            className={styles.radioButtonContainerV1}
          >
            <RadioButton
              tabIndex={i === tabIndexableOptionIndex ? 0 : -1}
              id={id}
              key={`${label}-${option.value}`}
              label={option.label}
              value={option.value}
              checked={selectedOption && selectedOption.value === option.value}
              onChange={(value) => onChange({ value })}
              extraLabel={option.extraLabel}
              onFocus={listeners?.focusListener}
              onKeyDown={listeners?.keyDownListener}
            />

            {selectedOption &&
              selectedOption.value === option.value &&
              option.followUpQuestion && (
                <FollowUpQuestion
                  option={option}
                  value={selectedOption.followUpQuestionValue as Answer}
                  error={error}
                  onChange={(value, followUpQuestionValue) =>
                    onChange({ value, followUpQuestionValue })
                  }
                />
              )}
          </div>
        )
      })}

      {footer && <InputBody body={footer} isFooter />}

      {error && !selectedOption.value && (
        <span id={`${label}ErrorHint`} className={styles.errorMessageV1}>
          {formatMessage(messages.errorMessage)}
        </span>
      )}
    </fieldset>
  )
}
export default RadioGroup
