import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { TrackingActionType } from '@babylon/tracking/react'
import {
  Box,
  Text,
  Button,
  Divider,
  Icon,
  Image,
} from '@babylon/web-platform-ui'
import { useQuery } from '@apollo/react-hooks'
import messages from './ProductCard.messages'
import styles from './ProductCard.module.scss'
import CheckoutSummaryQuery from '@/programmes/hooks/CheckoutSummary'

import { useProductData } from '@/programmes/components/Products/Products'
import Badge from '@/components/Badge/Badge'
import useGetCheckoutLink from '@/programmes/hooks/useGetCheckoutLink'
import { Product, ProductType } from '@/programmes/components/Products/models'
import { CustomIcon } from '@/components/CustomIcon/CustomIcon'
import useSubmitFormData from '@/programmes/components/Form/hooks/useSubmitFormData'
import {
  PLATFORM_GATEWAY_GRAPHQL_ENDPOINT,
  WEIGHT_LOSS_ONBOARDING_URL,
} from '@/config'
import getPreScreeningSteps from '@/programmes/WeightLoss/steps/getPreScreeningSteps'
import { QUESTIONNAIRE_FORM_DATA_KEY } from '@/programmes/components/Form/hooks/useForm'
import Tooltip from '@/components/Tooltip'
import { getDiscountCode } from '@/hooks/useHandleDiscountCode'
import useTracking from '@/tracking/useTracking'

interface CheckoutButtonProps {
  productId: string
  label: React.ReactNode
  productType?: ProductType
}

const shortProductName = (product: Product) => {
  const index = product.name.indexOf(' – ')

  return index === -1
    ? product.name
    : product.name.substring(index + ' – '.length)
}

const productNamePrefix = (product: Product) => {
  const index = product.name.indexOf(' – ')

  return index === -1 ? null : product.name.substring(0, index + 3)
}

const CheckoutButton = ({
  productId,
  label,
  productType,
}: CheckoutButtonProps) => {
  const translate = useFormatMessage()
  const location = useLocation()
  const { trackEvent } = useTracking()
  const { successPath, cancelPath, discountCodeId } = useProductData()
  const { checkOutMutation, loading } = useGetCheckoutLink({
    productList: [{ id: productId.toString(), quantity: 1 }],
    successPath,
    cancelPath,
    discountCodeId,
  })
  const {
    submitData: submitQuestionnaire,
    loading: submitQuestionnaireLoading,
    error: submitQuestionnaireError,
  } = useSubmitFormData(WEIGHT_LOSS_ONBOARDING_URL, getPreScreeningSteps())
  const isLoading = loading || submitQuestionnaireLoading

  const handleClick = async () => {
    trackEvent({
      screenTitle: location.pathname,
      actionType: TrackingActionType.click,
      eventAction: 'click-checkout-v2',
      eventLabel: productType,
    })

    const formData = sessionStorage.getItem(QUESTIONNAIRE_FORM_DATA_KEY)

    if (!formData) {
      return
    }

    const { answers } = JSON.parse(formData)
    const submissionSuccess = await submitQuestionnaire(answers, productType)

    if (submissionSuccess) {
      checkOutMutation()
    }
  }

  return (
    <>
      {submitQuestionnaireError && (
        <Text color="light.status.error">
          {translate(messages.submitError)}
        </Text>
      )}
      <Button
        isFullWidth
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={handleClick}
      >
        {label}
      </Button>
    </>
  )
}

interface SubscribeButtonsProps {
  disabled?: boolean
  product: Product
}

const SubscribeButtons = ({ disabled, product }: SubscribeButtonsProps) => {
  const translate = useFormatMessage()

  const productId = product.prices.filter(
    (price) => price.id === product.defaultPrice
  )[0].lookupKey

  if (disabled) {
    return (
      <Button isFullWidth isDisabled>
        {translate(messages.buttonUnavailable)}
      </Button>
    )
  }

  return (
    <CheckoutButton
      label={translate(messages.subscribe, {
        productName: shortProductName(product),
      })}
      productId={productId}
      productType={product.type}
    />
  )
}

const Features = ({
  mobileHidden,
  product,
  disabled,
}: {
  mobileHidden: boolean
  product: Product
  disabled?: boolean
}) => (
  <Box
    className={mobileHidden ? styles.mobileViewHidden : styles.mobileViewOnly}
  >
    <Box paddingTop="1rem" paddingBottom="1rem">
      <Divider />
    </Box>
    {product.features.map((feature) => {
      const isBadFeature = feature.name?.startsWith('ⓧ ')
      const featureName = isBadFeature
        ? feature.name?.substring(2)
        : feature.name

      return (
        <Box
          key={featureName}
          display="flex"
          flexDir="row"
          alignItems="start"
          paddingBottom="0.8rem"
        >
          <CustomIcon
            icon={isBadFeature ? 'AlertError' : 'Success'}
            color={
              isBadFeature || disabled
                ? 'light.functional.inactive'
                : 'light.brand.secondary'
            }
            paddingTop="0.2rem"
            modifier={{
              color:
                isBadFeature || disabled
                  ? 'light.graphics.white'
                  : 'light.brand.primary',
            }}
          />
          <Box paddingLeft="0.5rem" />
          <Text>{featureName}</Text>
        </Box>
      )
    })}
  </Box>
)

interface DiscountedPriceProps {
  subtotal: string
  discountedSubtotal: string | null
}

const DiscountedPrice: React.FC<DiscountedPriceProps> = ({
  subtotal,
  discountedSubtotal,
}) => (
  <>
    {discountedSubtotal !== null && (
      <Text
        display="inline"
        fontWeight={500}
        textDecoration={
          discountedSubtotal === null ? undefined : 'line-through'
        }
        fontSize="24"
        marginRight="0.375rem"
      >
        {subtotal}
      </Text>
    )}
    <Text display="inline" fontWeight={700} fontSize="24">
      {discountedSubtotal ?? subtotal}
    </Text>
  </>
)

interface ProductCardProps {
  showProductDescription?: boolean
  product: Product
}

export const ProductCard = ({
  product,
  showProductDescription,
}: ProductCardProps) => {
  const translate = useFormatMessage()
  const [expandCard, setExpandCard] = useState(false)

  const addDecimal = (amount: number) => (amount / 100).toFixed(2)

  const price = product.prices.filter((p) => p.id === product.defaultPrice)[0]

  const promotionCode = getDiscountCode()?.discount_code
  const priceString = `£${addDecimal(price.unitAmount)}`

  const { data } = useQuery(CheckoutSummaryQuery, {
    fetchPolicy: 'network-only',
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      lookupKeys: [price.lookupKey],
      promotionCode,
    },
  })

  return (
    <Box key={product.id} className={styles.productCardContainer}>
      <Box>
        <Box display="flex" justifyContent="flex-end">
          <Badge visible={product.isRecommended}>
            {translate(messages.recommendedProduct)}
          </Badge>
        </Box>

        {product.images.length > 0 && (
          <Image
            src={product.images[0]}
            alt=""
            fit="contain"
            width="100%"
            height="12rem"
          />
        )}

        <Text paddingBottom="0.4rem" paddingTop="0.4rem">
          {productNamePrefix(product) || product.name}
          {productNamePrefix(product) && (
            <span className={styles.productName}>
              {shortProductName(product)}
            </span>
          )}
        </Text>

        <Box>
          {!data && (
            <Text fontWeight="700" fontSize="24">
              {priceString}
            </Text>
          )}
          {data && <DiscountedPrice {...data.checkoutSummary} />}
          <div className={styles.productInfoContainer}>
            <Text fontWeight="600">{translate(messages.pricePerMonth)}</Text>
            <Tooltip>
              <Text>
                {product.type === 'Wegovy'
                  ? translate(messages.wegovyTooltip)
                  : translate(messages.mounjaroTooltip)}
              </Text>
            </Tooltip>
          </div>
        </Box>

        <Box display="flex" justifyContent="space-between" paddingTop="0.2rem">
          {showProductDescription && product.description && (
            <Text textStyle="caption">{product.description}</Text>
          )}
          {showProductDescription && product.features.length > 0 && (
            <Box className={styles.mobileViewOnly} marginLeft="1rem">
              <button
                className={styles.iconButton}
                aria-label={
                  expandCard
                    ? translate(messages.featuresHideLabel)
                    : translate(messages.featuresShowLabel)
                }
                type="button"
                onClick={() => setExpandCard((prevState) => !prevState)}
              >
                <Icon icon={expandCard ? 'Up' : 'Down'} />
              </button>
            </Box>
          )}
        </Box>

        {(!showProductDescription || expandCard) &&
          product.features.length > 0 && (
            <Features
              mobileHidden={false}
              product={product}
              disabled={product.disabled}
            />
          )}

        {product.features.length > 0 && (
          <Features
            mobileHidden
            product={product}
            disabled={product.disabled}
          />
        )}
      </Box>

      <div className={styles.buttonContainer}>
        <SubscribeButtons disabled={product.disabled} product={product} />
      </div>
    </Box>
  )
}

export default ProductCard
