import getFlags, { createFlagObject } from '@/utils/featureFlags'

const initialState = getFlags()

export default (state = initialState, action) => {
  switch (action.type) {
    case 'APPLY_FEATURE_FLAG':
      return {
        ...state,
        [action.payload]: true,
      }
    case 'LOAD_FEATURE_FLAGS':
      return {
        ...state,
        ...createFlagObject(action.payload),
      }
    default:
      return state
  }
}
