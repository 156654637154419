import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import TextInput from '../TextInput'
import messages from './messages'
import styles from './PulseInput.module.scss'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'

interface Props {
  label: string
  error?: boolean
  required?: boolean
  onChange: (pulse: string) => void
  value: string
}

const PulseBody = () => {
  const formatMessage = useFormatMessage()

  return (
    <p>
      {formatMessage(messages.linkMessage)}{' '}
      <a
        href="https://www.nhs.uk/common-health-questions/accidents-first-aid-and-treatments/how-do-i-check-my-pulse/"
        target="_blank"
        rel="noreferrer"
      >
        {formatMessage(messages.nhsHowMessage)}
      </a>
    </p>
  )
}
const PulseInput: React.FC<Props> = ({
  label,
  value,
  error = false,
  required = false,
  onChange,
}) => {
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.container}>
      <div>
        <QuestionTitle content={label} type="header" />
        <QuestionBody body={<PulseBody />} />
      </div>

      <div className={styles.textInput}>
        <TextInput
          label={formatMessage(messages.bpmLabel)}
          error={error}
          required={required}
          type="number"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </div>
  )
}
export default PulseInput
