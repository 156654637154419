import {
  REGISTRATION_STARTED,
  REGISTRATION_COMPLETED,
  REGISTRATION_PROMO_MODAL_SHOWN,
} from './actionTypes'

const REGISTRATION_METHODS = {
  EMAIL: 'email',
  FACEBOOK: 'facebook',
}

const registrationStarted = ({ signupMethod }) => ({
  type: REGISTRATION_STARTED,
  signupMethod,
})

const registrationCompleted = ({ signupMethod, promocode }) => ({
  type: REGISTRATION_COMPLETED,
  signupMethod,
  promocode,
})

const emailRegistrationStarted = () =>
  registrationStarted({ signupMethod: REGISTRATION_METHODS.EMAIL })

const emailRegistrationCompleted = ({ promocode }) =>
  registrationCompleted({ signupMethod: REGISTRATION_METHODS.EMAIL, promocode })

const facebookRegistrationStarted = () =>
  registrationStarted({ signupMethod: REGISTRATION_METHODS.FACEBOOK })

const facebookRegistrationCompleted = ({ promocode }) =>
  registrationCompleted({
    signupMethod: REGISTRATION_METHODS.FACEBOOK,
    promocode,
  })

const registrationPromoModalShown = () => ({
  type: REGISTRATION_PROMO_MODAL_SHOWN,
})

export {
  facebookRegistrationStarted,
  facebookRegistrationCompleted,
  emailRegistrationStarted,
  emailRegistrationCompleted,
  registrationPromoModalShown,
}
