import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import Template from './Template'
import styles from './index.module.scss'
import { routes } from './routes'
import messages from './messages'
import onboardingSteps from './steps'
import { ActionOrLogin } from '@/programmes/components/ActionOrLogin/ActionOrLogin'

const cx = classNames.bind(styles)

const HomePage = () => {
  const history = useHistory()
  const t = useFormatMessage()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.homePageTitle)}
      hasAppLayout={isAuthenticated}
      steps={onboardingSteps}
      activeStep={0}
    >
      <h2 className={cx('heading')}>{t(messages.homeHeading)}</h2>
      <p className={cx('description')}>{t(messages.homeDescription)}</p>
      <ActionOrLogin
        onActionClick={() => history.push(routes.questionnaire)}
        onActionLabel={t(messages.homeCta2)}
      />
      <p className={cx('footnote')}>
        <FormattedMessage
          {...messages.homePrivacy}
          values={{
            a: (chunks) => (
              <a href="/privacy" target="blank">
                {chunks}
              </a>
            ),
          }}
        />{' '}
      </p>
    </Template>
  )
}

export default HomePage
