import { useCallback, useEffect, useState } from 'react'
import validateAnswer from '../utils/validateAnswer'
import {
  Answer,
  CheckBoxGroupValue,
  Step,
  Question,
  RadioGroupValue,
  Answers,
} from '../types'
import useQuestionnaireWebViewHandler from '../../../hooks/useQuestionnaireWebViewHandler'
import { isAutoTransitioningInterstitial } from '../components/RenderInterstitial'
import { PREFILL_EMAIL_KEY } from '../constants'
import {
  HEIGHT_AND_WEIGHT_QUESTION_ID,
  WEIGHT_RELATED_ILLNESS_QUESTION_ID,
  EMAIL_QUESTION_ID,
} from '@/programmes/WeightLoss/steps/preScreeningStepsV2'

export const QUESTIONNAIRE_FORM_DATA_KEY = 'questionnaire-data'

const saveQuestionnaireData = (
  answers,
  formHistory,
  currentStep,
  completed = false
) => {
  // When the previous step was Weight Mgmt Onboarding v2 email, save it separately for future prefill
  if (
    formHistory.length >= 2 &&
    formHistory[formHistory.length - 2] === EMAIL_QUESTION_ID
  ) {
    const email = answers[EMAIL_QUESTION_ID]?.emailAddress
    sessionStorage.setItem(PREFILL_EMAIL_KEY, email)
  }

  sessionStorage.setItem(
    QUESTIONNAIRE_FORM_DATA_KEY,
    JSON.stringify({
      answers,
      formHistory,
      currentStep: currentStep.id,
      completed,
    })
  )
}

// we don't want to automatically add the first step ID to formHistory
// because it might be an auto-transitioning interstitial
const getInitialFormHistory = (steps: Step[]) =>
  isAutoTransitioningInterstitial(steps[0]) ? [] : [steps[0].id]

export default function useForm(
  steps: Array<Step>,
  onSubmit: (answers?: { [p: string]: Answer }) => void
) {
  const [currentStep, setCurrentStep] = useState<Step>(steps[0])
  const [answers, setAnswers] = useState<Answers>({})
  const [formHistory, setFormHistory] = useState<string[]>(() =>
    getInitialFormHistory(steps)
  )
  const [showExitView, setShowExitView] = useState<boolean>(false)
  const [showFormError, setShowFormError] = useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  useEffect(() => {
    const formData = sessionStorage.getItem(QUESTIONNAIRE_FORM_DATA_KEY)

    if (formData) {
      const { answers, formHistory, currentStep } = JSON.parse(formData)
      setAnswers(answers)
      setFormHistory(formHistory)
      setCurrentStep(steps.find((step) => step.id === currentStep) || steps[0])
    }
  }, [steps])

  useEffect(() => {
    saveQuestionnaireData(answers, formHistory, currentStep)
  }, [answers, currentStep, formHistory])

  useEffect(() => {
    setShowFormError(false)
  }, [currentStep])

  const handleConditions = (
    question: Question,
    answers: { [p: string]: Answer },
    steps: Step[]
  ): Step => {
    if (question?.skipCondition) {
      const answer = answers[question.skipCondition.id]

      // handle only RadioGroup skip condition need to add logic to handle for other types
      if ((answer as RadioGroupValue).value === question.skipCondition.answer) {
        const step = steps.find((s) => s.id === question.next)

        if (step) {
          return step
        }
      }
    }

    return question
  }

  const handleNextAction = (step: Step, answer: Answer): string | undefined => {
    if (step.nextHandler) {
      return step.nextHandler(step, answers)
    }
    const optionsRules = step?.optionsRules || []

    if (step?.type === 'question') {
      if (
        step?.inputType.component === 'checkboxGroup' &&
        optionsRules.length
      ) {
        const result = optionsRules.find((rule) =>
          (answer as Array<CheckBoxGroupValue>).find(
            (checked) => rule.label === checked.value
          )
        )

        if (result) {
          return result.nextAction
        }
      }

      if (step?.inputType.component === 'radioGroup' && optionsRules.length) {
        const result = optionsRules.find(
          (rule) => (answer as RadioGroupValue)?.value === rule.label
        )

        if (result) {
          return result.nextAction
        }
      }
    }

    return step?.next
  }

  const validateForm = () => {
    if (currentStep.type === 'question' && currentStep.required) {
      const valid = validateAnswer(currentStep, answers[currentStep.id])
      setShowFormError(!valid)

      return valid
    }

    return true
  }

  const handleAnswer = useCallback(
    (questionId: string, answer: Answer) => {
      if (showFormError) {
        setShowFormError(false)
      }

      setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: answer }))
    },
    [showFormError]
  )

  const handleNext = async (event?: any) => {
    event?.preventDefault()
    const valid = validateForm()

    if (!valid) {
      return
    }

    const next = handleNextAction(currentStep, answers[currentStep?.id])

    if (next === 'complete-action') {
      saveQuestionnaireData(answers, formHistory, currentStep, true)
      await onSubmit(answers)

      return
    }

    if (next === 'exit-action') {
      setShowExitView(true)

      return
    }

    let step = steps.find((s) => s.id === next)

    if (step) {
      if (step.type === 'question') {
        step = handleConditions(step as Question, answers, steps)
      }

      setCurrentStep(step)

      if (!isAutoTransitioningInterstitial(step)) {
        setFormHistory([...formHistory, step.id])
      }
    }
  }

  const handlePrevious = () => {
    if (formHistory && formHistory.length > 1) {
      formHistory.pop()
      const previousQuestionId = formHistory[formHistory.length - 1]
      const step = steps.find((question) => question.id === previousQuestionId)

      if (step) {
        setCurrentStep(step)
      }
    }
  }

  const handleExit = () => {
    sessionStorage.removeItem(QUESTIONNAIRE_FORM_DATA_KEY)
    window.location.replace('https://emed.com/uk')
  }

  const handleBeforeExit = () => {
    if (
      !showExitView ||
      formHistory?.indexOf(HEIGHT_AND_WEIGHT_QUESTION_ID) === -1
    ) {
      return
    }

    const firstClinicalQuestionIndex = formHistory.indexOf(
      WEIGHT_RELATED_ILLNESS_QUESTION_ID
    )

    if (firstClinicalQuestionIndex === -1) {
      const step = steps.find(
        (question) => question.id === HEIGHT_AND_WEIGHT_QUESTION_ID
      )

      if (step) {
        setShowExitView(false)
        setCurrentStep(step)
      }

      return
    }

    const step = steps.find(
      (question) => question.id === WEIGHT_RELATED_ILLNESS_QUESTION_ID
    )

    if (step) {
      setShowExitView(false)
      setFormHistory(formHistory.slice(0, firstClinicalQuestionIndex + 1))
      setCurrentStep(step)
    }
  }

  useQuestionnaireWebViewHandler(answers, handlePrevious)

  return {
    showExitView,
    answers,
    currentStep,
    handleAnswer,
    handleExit,
    handleNext,
    handlePrevious,
    handleBeforeExit,
    showFormError,
    formHistory,
  }
}
