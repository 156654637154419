import React, { useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import Spinner from '../Spinner'
import Button from './components/Button'
import ExitView from './components/ExitView'
import RenderQuestion from './components/RenderQuestion'
import RenderInterstitial from './components/RenderInterstitial'
import ErrorView from './components/ErrorView'
import { Step, Question, Answer } from './types'
import styles from './Form.module.scss'
import messages from './messages'
import calculateCompletionPercentageByStep from './utils/calculateCompletionPercentageByStep'
import useForm from './hooks/useForm'
import { useTrackingQuestionnaire } from '../../tracking'
import DirectRegisterPage from '@/components/Authentication/Login/DirectRegisterPage'
import { useCurrentUser } from '@/redux/selectors'
import { STEP_ELEMENT_ID_PREFIX } from './constants'

// TODO - use simple object with key as id, to prevent find (which loops through the list)
// TODO - use Context to keep track of data, to prevent passing props to sub components (refactor)

interface Props {
  steps: Step[]
  onSubmit: (answers?: { [p: string]: Answer }) => void
  loading?: boolean
  error?: boolean
}
const Form: React.FC<Props> = ({ steps, onSubmit, loading, error }: Props) => {
  const {
    showExitView,
    answers,
    currentStep,
    handleAnswer,
    handleExit,
    handleNext,
    handlePrevious,
    showFormError,
    formHistory,
  } = useForm(steps, onSubmit)
  const formatMessage = useFormatMessage()

  useTrackingQuestionnaire(currentStep?.id, error)
  const currentUser = useCurrentUser()
  const isLoggedOut = !currentUser?.id
  const isAfterLoginGate = useMemo(() => {
    const loginGateStepIndex = steps.findIndex(
      (step) => step.type === 'interstitial' && step.loginGate
    )
    const currentStepIndex = steps.findIndex(
      (step) => step.id === currentStep?.id
    )

    if (loginGateStepIndex === -1) {
      return false
    }

    return currentStepIndex > loginGateStepIndex
  }, [currentStep, steps])

  const nextButtonText = useMemo(() => {
    if (currentStep.type === 'question') {
      return currentStep?.nextButtonText ?? formatMessage(messages.next)
    }

    return currentStep.loginGate && isLoggedOut
      ? formatMessage(messages.registerAndContinue)
      : formatMessage(messages.next)
  }, [
    currentStep.loginGate,
    currentStep?.nextButtonText,
    currentStep.type,
    formatMessage,
    isLoggedOut,
  ])

  if (isAfterLoginGate && isLoggedOut) {
    return <DirectRegisterPage />
  }

  return (
    <form
      className={styles.formContainer}
      onSubmit={(event) => event.preventDefault()}
      noValidate
    >
      {loading && <Spinner colour="#000000" />}

      {showExitView && <ExitView />}

      {!loading && error && (
        <ErrorView
          retry={async () => {
            await onSubmit(answers)
          }}
        />
      )}

      {!showExitView && !error && !loading && (
        <div
          key={currentStep?.id}
          className={styles.form}
          id={`${STEP_ELEMENT_ID_PREFIX}-${currentStep.id}`}
        >
          <div className={styles.progressBar}>
            <span
              style={{
                width: `${calculateCompletionPercentageByStep(
                  currentStep,
                  steps
                )}%`,
              }}
            />
          </div>

          {currentStep?.type === 'question' && (
            <RenderQuestion
              question={currentStep as Question}
              answer={answers[currentStep.id]}
              handleAnswer={handleAnswer}
              error={showFormError}
            />
          )}
          {currentStep?.type === 'interstitial' && (
            <RenderInterstitial interstitial={currentStep} />
          )}
          {currentStep.callOut && (
            <div className={styles.callOut}>
              <h6>{currentStep.callOut.title}</h6>
              <span className={styles.callOutBody}>
                {currentStep.callOut.body}
              </span>
            </div>
          )}
        </div>
      )}

      <div className={styles.footer}>
        {showExitView && (
          <Button onClick={handleExit} title={formatMessage(messages.done)} />
        )}

        {!showExitView && !error && !loading && (
          <>
            <Button onClick={handleNext} title={nextButtonText} />
            {formHistory.length > 1 && (
              <Button
                onClick={handlePrevious}
                type="secondary"
                title={formatMessage(messages.previous)}
              />
            )}
          </>
        )}
      </div>
    </form>
  )
}

export default Form
