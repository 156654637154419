import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Text, EditorialLayout } from '@babylon/medkit'
import { VideoOffFilled } from '@babylon/icons'
import Modal from '@/components/Modal/index'
import VideoPermissionsCard from './VideoPermissionsCard'
import styles from '../VideoCall.module.scss'
import messages from '../VideoCall.messages'

const MediaPermissionsModal = ({ accessDenied, onClose }) => {
  const intl = useIntl()

  const WarningContent = () => (
    <>
      <Text tag="h5" variant="h3">
        {intl.formatMessage(messages.mediaPermissionsModalWarningTitle)}
      </Text>
      <VideoPermissionsCard
        errorStyle={accessDenied}
        icon={
          <VideoOffFilled
            className={styles.card__icon}
            width="45px"
            height="45px"
            fill="#fff"
          />
        }
        caption={intl.formatMessage(
          messages.mediaPermissionsModalCameraMicrophoneNotActive
        )}
      />
      <Text variant="body">
        {intl.formatMessage(
          messages.mediaPermissionsModalWarningDescriptionFirst
        )}
      </Text>
      <Text variant="body">
        <FormattedMessage
          {...messages.mediaPermissionsModalWarningDescriptionSecond}
          values={{
            permissionsAction: (
              <b>{intl.formatMessage(messages.mediaPermissionsModalAllow)}</b>
            ),
          }}
        />
      </Text>
    </>
  )

  const DeclinedContent = () => (
    <>
      <Text tag="h5" variant="h3">
        {intl.formatMessage(messages.mediaPermissionsModalDeclinedTitle)}
      </Text>
      <VideoPermissionsCard
        errorStyle={accessDenied}
        icon={
          <VideoOffFilled
            className={styles.card__icon}
            width="45px"
            height="45px"
            fill="#fff"
          />
        }
        caption={intl.formatMessage(
          messages.mediaPermissionsModalCameraMicrophoneBlocked
        )}
      />
      <Text variant="body">
        {intl.formatMessage(
          messages.mediaPermissionsModalDeclinedDescriptionFirst
        )}
      </Text>
      <Text variant="body">
        {intl.formatMessage(
          messages.mediaPermissionsModalDeclinedDescriptionSecond
        )}
      </Text>
      <Text variant="body">
        {intl.formatMessage(
          messages.mediaPermissionsModalDeclinedDescriptionThird
        )}
      </Text>
      <Text variant="h5">
        {intl.formatMessage(messages.mediaPermissionsModalProblemsTitle)}
      </Text>
      <Text variant="body">
        {intl.formatMessage(messages.mediaPermissionsModalProblemsDescription)}
      </Text>
    </>
  )

  return (
    <Modal onClose={onClose}>
      <EditorialLayout>
        {accessDenied ? <DeclinedContent /> : <WarningContent />}
      </EditorialLayout>
    </Modal>
  )
}

export default MediaPermissionsModal
