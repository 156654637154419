import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Alert.module.scss'

export const types = {
  info: 'info',
  warn: 'warn',
  error: 'error',
}

export const variants = {
  rounded: 'rounded',
}

const cx = classNames.bind(styles)

/**
 * A small colored box with optional icon and text based on bootstraps
 * implementation
 *
 */
const Alert = ({ children, type, icon: Icon, isBusy, variant, className }) => (
  <div
    className={cx(
      'alert',
      type,
      {
        [`alert--${variant}`]: variant,
        'alert--busy': isBusy,
      },
      className
    )}
    role="alert"
  >
    {Icon && <Icon className={styles.icon} />}
    {(children || isBusy) && <div className={styles.message}>{children}</div>}
  </div>
)

Alert.propTypes = {
  /**
   * The text content of the alert
   */
  children: PropTypes.node,

  /**
   * Denotes the color scheme for both text and icon. This module also exports
   * types that should be used as canonical types
   */
  type: PropTypes.oneOf(Object.values(types)),

  /**
   * The icon imported from this component library
   */
  icon: PropTypes.func,

  /**
   * Should the component indicate that it is busy?
   */
  isBusy: PropTypes.bool,

  /**
   * Denotes a certain style the component should be
   */
  variant: PropTypes.oneOf(Object.values(variants)),
}

Alert.defaultProps = {
  children: '',
  type: types.info,
  icon: null,
}

export default Alert
