/**
 * Finds matching route for a given whitelisted path and returns either:
 *
 * - page name assigned to a route
 * - the route with the variables removed
 * - the result of a function assigned to a route
 *
 * NOTE: this is to help getting closer the web tracking structure
 * to native apps (iOS, Android) and ease reporting in analytics
 */
import pathToRegexp from 'path-to-regexp'
import { logMessage } from '@babylon/sentry'
import { KnownUris } from './types'

export default (path: string, knownUris: KnownUris = {}): string | null => {
  const route = Object.keys(knownUris).find((route) =>
    pathToRegexp(route).test(path)
  )

  if (route) {
    const value = knownUris[route]

    if (typeof value === 'string') {
      return value
    }

    if (value === true) {
      // Replace regex part of route with nothing e.g. userId(\\d+) -> userId
      return route.replace(/\([^)]+\)/g, '')
    }

    if (typeof value === 'function') {
      return value(route, path)
    }

    return null
  }

  // NOTE: any unmatched path will end up with a logged message in Sentry
  // eventually all paths would show up in the (massive) whitelist
  // maybe all paths should be tracked and only those should remain in the whitelist
  // which need a specific mapped value to be worked out
  logMessage(
    "Route definition isn't listed in the known URIs whitelist. If you want this page tracked include it within the whitelist."
  )

  return null
}
