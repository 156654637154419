import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Text, List, EditorialLayout } from '@babylon/medkit'
import { useAppTitle } from '@/redux/selectors'
import messages from './VideoCallPage.messages'

const VideoCall = () => {
  const intl = useIntl()
  const appTitle = useAppTitle()

  return (
    <EditorialLayout>
      <Text variant="h1" className="PageTitle">
        {intl.formatMessage(messages.pageTitle, {
          appTitle,
        })}
      </Text>
      <Text tag="h2" variant="h4">
        {intl.formatMessage(messages.pageSubTitle)}
      </Text>
      <Text variant="body">{intl.formatMessage(messages.pageDescription)}</Text>
      <Text tag="h2" variant="h4">
        {intl.formatMessage(messages.checkListTitle)}
      </Text>
      <List
        listData={[
          intl.formatMessage(messages.checkListFirstBulletPoint),
          intl.formatMessage(messages.checkListSecondBulletPoint),
        ]}
        variant="ul"
      />
      <Text tag="h2" variant="h4">
        {intl.formatMessage(messages.connectionProblemsTitle)}
      </Text>
      <FormattedMessage {...messages.connectionProblemsDescription} />
    </EditorialLayout>
  )
}

export default VideoCall
