import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import classNames from 'classnames/bind'
import AppsInfo from '@/components/AppsInfo'
import BABYLON_US_APP from '@/assets/images/babylon_us_ios_app.png'
import Template from '../Template'
import messages from '../messages'
import styles from '../index.module.scss'
import checkoutSteps from './steps'

const cx = classNames.bind(styles)

const CheckoutConfirmation = () => {
  const t = useFormatMessage()

  return (
    <Template
      title={t(messages.checkoutConfirmationPageTitle)}
      steps={checkoutSteps}
      activeStep={2}
    >
      <h2 className={cx('heading')}>
        {t(messages.checkoutConfirmationPageHeading)}
      </h2>
      <p className={cx('description')}>
        {t(messages.checkoutConfirmationPageDescription)}
      </p>
      <AppsInfo
        title={t(messages.checkoutConfirmationPageAppInfoHeading)}
        content={t(messages.checkoutConfirmationPageAppInfoDescription)}
        image={BABYLON_US_APP}
      />
    </Template>
  )
}

export default CheckoutConfirmation
