import { defineMessages } from 'react-intl'

export default defineMessages({
  modalTitle: {
    id: 'AddressPicker.modal.title',
    defaultMessage: 'Select address',
  },
  modalErrorPostcodeInvalid: {
    id: 'AddressPicker.modal.error.postcodeInvalid',
    defaultMessage: 'Please enter a valid postcode',
  },
  modalErrorPostcodeNotFound: {
    id: 'AddressPicker.modal.error.postcodeNotFound',
    defaultMessage: 'Postcode can’t be found',
  },
  modalCtaAddManually: {
    id: 'AddressPicker.modal.cta.addManually',
    defaultMessage: 'Add manually',
  },
  modalCtaFindMyAddress: {
    id: 'AddressPicker.modal.cta.findMyAddress',
    defaultMessage: 'Find address',
  },
  modalCtaCantFindMyAddress: {
    id: 'AddressPicker.modal.cta.cantFindMyAddress',
    defaultMessage: 'Can’t find address',
  },
  modalBodySearchForPostcode: {
    id: 'AddressPicker.modal.body.searchForPostcode',
    defaultMessage: 'Search for postcode',
  },
})
