import { defineMessages } from 'react-intl'

export default defineMessages({
  whatNextTitle: {
    id: 'VideoCallWithToken.whatNext.title',
    defaultMessage: 'What to do next',
  },
  whatNextSubTitle: {
    id: 'VideoCallWithToken.whatNext.subTitle',
    defaultMessage: 'Sign in to:',
  },
  whatNextFirstBulletPoint: {
    id: 'VideoCallWithToken.whatNext.firstBulletPoint',
    defaultMessage: 'View a recording of your appointment',
  },
  whatNextSecondBulletPoint: {
    id: 'VideoCallWithToken.whatNext.secondBulletPoint',
    defaultMessage: 'Check your symptoms and get quick NHS advice',
  },
  signInCta: {
    id: 'VideoCallWithToken.signIn.cta',
    defaultMessage: 'Sign in',
  },
  postConsultationTitle: {
    id: 'VideoCallWithToken.postConsultation.title',
    defaultMessage: 'Video ended',
  },
})
