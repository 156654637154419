import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import { useTrackingQuestionnaire } from '@/programmes/tracking'
import messages from './messages'

const ExitView = () => {
  const formatMessage = useFormatMessage()
  useTrackingQuestionnaire('exit')

  return (
    <div>
      <QuestionTitle content={formatMessage(messages.title)} type="header" />
      <QuestionBody body={messages.body.defaultMessage} />
    </div>
  )
}

export default ExitView
