declare global {
  interface Window {
    webViewAuthToken?: string
    webViewMode?: boolean
    ReactNativeWebView?: {
      postMessage: (message: string) => void
    }
  }
}

class WebViewMode {
  public static isWebViewMode(): boolean {
    return !!window.webViewMode
  }

  public static getToken(): string | undefined {
    return window.webViewAuthToken
  }

  public static sendMessage(message: string): void {
    const messageDispatcher = window.ReactNativeWebView

    if (messageDispatcher?.postMessage) {
      messageDispatcher.postMessage(message)
    } else {
      console.warn('messageBus.postMessage is not available')
    }
  }

  public static onMessage(callback: (message: string) => void): () => void {
    const listener = (event: MessageEvent) => {
      if (typeof event.data !== 'string') {
        return
      }
      callback(event.data)
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }
}

export default WebViewMode
