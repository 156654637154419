import React from 'react'
import { Card, Text } from '@babylon/medkit'
import { StarFilled } from '@babylon/icons'
import cn from 'classnames'
import FullBleedSection from '@/components/FullBleedSection/FullBleedSection'
import style from './Testimonial.module.scss'

interface TestimonialProps {
  sectionTitle: string
  stars: number
  title?: string
  description: string
  author: string
  additionalData?: string
}

const renderStars = (stars: number) =>
  Array.from({ length: stars }, (_, index) => (
    <StarFilled data-testid="star-icon" key={index} className={style.star} />
  ))

const Testimonial: React.FC<TestimonialProps> = ({
  stars,
  title,
  description,
  author,
  additionalData,
  sectionTitle,
}) => (
  <FullBleedSection title={sectionTitle}>
    <Card className={style.card}>
      <div className={style.marginBottom}>{renderStars(stars)}</div>
      <Text variant="body" className={cn(style.semiBold, style.marginBottom)}>
        {title}
      </Text>
      <Text variant="body" className={style.marginBottom}>
        {description}
      </Text>
      <Text variant="body" className={style.semiBold}>
        {author}
      </Text>
      <Text variant="body" className={style.additionalData}>
        {additionalData}
      </Text>
    </Card>
  </FullBleedSection>
)

export default Testimonial
