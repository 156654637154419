const SESSION_IDS_KEY = 'sessionIds'
const SESSION_ID_KEY = 'sessionId'

export const getSessionId = () => sessionStorage.getItem(SESSION_ID_KEY)

export const removeSessionId = () => {
  const sessionId = getSessionId()
  const sessionIds = getSessionIds()
  const newSessionIds = sessionIds.filter((id: string) => id !== sessionId)
  localStorage.setItem(SESSION_IDS_KEY, JSON.stringify(newSessionIds))
}

export const getSessionIds = () =>
  JSON.parse(localStorage.getItem(SESSION_IDS_KEY) || '[]')

export const addSessionId = (id?: string | null) => {
  const sessionId = !id ? getSessionId() : id
  const sessionIds = getSessionIds()

  if (sessionId && !sessionIds.includes(sessionId)) {
    localStorage.setItem(
      SESSION_IDS_KEY,
      JSON.stringify([...sessionIds, sessionId])
    )
  }
}
