import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React, { PureComponent } from 'react'

import asFormControl from '../asFormControl'
import { Loader } from '../Button'

import styles from './input.module.scss'
import showIcon from './show-icon.svg'
import hideIcon from './hide-icon.svg'

const cx = classNames.bind(styles)

class Input extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      type: props.type,
    }
  }

  handlePasswordType = () => {
    const type = this.state.type === 'password' ? 'text' : 'password'
    this.setState({ type })
  }

  _renderShowPassword() {
    const isPassword = this.state.type === 'password'

    return (
      <button
        tabIndex="-1"
        type="button"
        className={cx('show-password')}
        onClick={this.handlePasswordType}
      >
        <img
          className={cx('show-password__icon')}
          src={isPassword ? showIcon : hideIcon}
          alt="Show and Hide Password"
        />
      </button>
    )
  }

  render() {
    const { type } = this.state
    const {
      errors,
      success,
      label,
      showPasswordToggle,
      loading,
      labelProps,
      refHandler,
      ...rest
    } = this.props

    const errorsClass = errors.length > 0 ? 'control--error' : ''
    const successClass = success ? 'control--success' : ''

    return (
      <div className={cx('control-wrapper', 'input-wrapper')}>
        {label && (
          <label htmlFor={label} className={cx('label')} {...labelProps}>
            {label}
          </label>
        )}

        {showPasswordToggle && this._renderShowPassword()}

        {loading && <Loader data-testid="loading" stroke="#2BBBBB" />}

        <input
          {...rest}
          ref={refHandler}
          type={type}
          id={label}
          className={cx('control', errorsClass, successClass, {
            'input--loading': loading,
          })}
        />
      </div>
    )
  }
}

Input.propTypes = {
  /** The type of input */
  type: PropTypes.oneOf([
    'text',
    'password',
    'email',
    'number',
    'tel',
    'hidden',
    'search',
  ]).isRequired,
  /** The label for the input */
  label: PropTypes.node,
  /** The array of errors on the input */
  errors: PropTypes.arrayOf(PropTypes.string),
  /** The success message */
  success: PropTypes.string,
  /** The passowrd icon to toggle show/hide */
  showPasswordToggle: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  label: null,
  errors: [],
  success: '',
  showPasswordToggle: false,
}

export default asFormControl(Input)
