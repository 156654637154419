import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'SetPasswordPage.pageTitle',
    defaultMessage: 'Account setup',
  },
  pageDescription: {
    id: 'SetPasswordPage.pageDescription',
    defaultMessage:
      'Please create a password to complete your setup for NHS video consultations',
  },
  createAccount: {
    id: 'SetPasswordPage.createAccount',
    defaultMessage: 'Create account',
  },
  success: {
    id: 'SetPasswordPage.success',
    defaultMessage:
      'Your account was set successfully. You may now sign in to the app.',
  },
})
