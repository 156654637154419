import { defineMessages } from 'react-intl'

export default defineMessages({
  allRightsReserved: {
    id: 'Footer.allRightsReserved',
    defaultMessage: 'All rights reserved',
  },
  giveFeedback: {
    id: 'Footer.giveFeedback',
    defaultMessage: 'Give feedback',
  },
  refer: {
    id: 'Footer.refer',
    defaultMessage: 'Refer a friend',
  },
  termsAndConditions: {
    id: 'Footer.termsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  privacyPolicy: {
    id: 'Footer.privacyPolicy',
    defaultMessage: 'Privacy policy',
  },
  babylonWebsite: {
    id: 'Footer.babylonWebsite',
    defaultMessage: 'eMed website',
  },
  cookies: {
    id: 'Footer.cookies',
    defaultMessage: 'Cookies',
  },
  faqs: {
    id: 'Footer.faqs',
    defaultMessage: 'FAQs',
  },
  regulatory: {
    id: 'Footer.regulatory',
    defaultMessage: 'Regulatory',
  },
})
