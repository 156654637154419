import gql from 'graphql-tag'

const ReportCallStep = gql`
  mutation ReportCallStep($consultationId: String!, $step: CallStep!) {
    reportCallStep(consultationId: $consultationId, step: $step)
  }
`

const SendCallStats = gql`
  mutation SendCallStats($payload: CallStats) {
    sendCallStats(payload: $payload) {
      status
    }
  }
`

export { ReportCallStep, SendCallStats }
