import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose, withHandlers, branch, renderComponent } from 'recompose'
import { withRouter } from 'react-router'
import { StarFilled } from '@babylon/icons'
import { LinkAnchor, Card, Avatar } from '@babylon/medkit'

import { withUserId } from '@/wrappers'
import styles from './NotificationItem.module.scss'
import NotificationLoading from './Loading'
import {
  getActionUrl,
  notificationIconAndCategory,
  trackNotificationClick,
} from './utils'

const enhance = compose(
  withRouter,
  withUserId,
  branch((p) => p.loading, renderComponent(NotificationLoading)),
  withHandlers({
    handleNotificationClick: ({
      pageUserId,
      item: { id, isRead, itemId, itemType, parentItemId, message },
      markAsRead,
      history,
    }) => (event) => {
      const actionUrl = getActionUrl({
        itemId,
        itemType,
        parentItemId,
        userId: pageUserId,
      })
      event.preventDefault()
      trackNotificationClick({ itemType, message, itemId, actionUrl })

      if (!isRead) {
        markAsRead(id)
      }

      if (actionUrl) {
        history.push(actionUrl)
      }
    },
  })
)

const NotificationItem = ({
  item: { createdAtUTC, itemType, isRead, avatarUrl, message },
  handleNotificationClick,
}) => {
  const title = moment(createdAtUTC).fromNow()

  const { Icon } =
    (itemType && notificationIconAndCategory[itemType.toUpperCase()]) ||
    notificationIconAndCategory.DEFAULT

  return (
    <li>
      <LinkAnchor
        role="link"
        tabIndex="0"
        className={styles.item__row}
        onClick={handleNotificationClick}
        data-testid="notificationAction"
        data-e2e-notification-type={itemType}
      >
        <Card
          interactive
          thumbnail={
            <Avatar
              src={avatarUrl}
              highlightIcon
              description={message}
              icon={!isRead && <StarFilled />}
              FallbackIcon={Icon}
            />
          }
          title={title}
          subTitle={message}
        />
      </LinkAnchor>
    </li>
  )
}

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default enhance(NotificationItem)
