import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { onAddressPicked } from '@/redux/persistentForm/actions'
import { postcodeErrorKeys } from '@/config/constants'

// filter parent form errors to only include the ones related to postcode / address
const parseFieldsErrors = (parentFormErrors) =>
  Object.keys(parentFormErrors).reduce((obj, key) => {
    if (postcodeErrorKeys.includes(key) && !!parentFormErrors[key]) {
      if (key === 'errors' && !!parentFormErrors[key].address_post_code) {
        obj.address_post_code = parentFormErrors[key].address_post_code
      } else {
        obj[key] = parentFormErrors[key]
      }
    }

    return obj
  }, {})

export default compose(
  connect(
    (state = { form: { createAddressForUser: { errors: [] } } }, ownProps) => {
      const parentFormErrors =
        (state.form &&
          state.form[ownProps.parentFormOperation] &&
          state.form[ownProps.parentFormOperation].errors) ||
        {}
      const addressFieldErrors = parseFieldsErrors(parentFormErrors)

      return {
        fieldErrors: addressFieldErrors,
      }
    }
  ),
  connect(null, (dispatch) => bindActionCreators({ onAddressPicked }, dispatch))
)
