import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import LandingFooter from '@/components/Footer/LandingFooter'
import LandingHeader from '@/components/LandingHeader'
import PageTitle from '@/components/PageTitle/PageTitle'
import errorImage from '@/views/ErrorPage/error.svg'
import messages from './messages'
import styles from './MaintenanceSplashScreen.module.scss'

const MaintenanceSplashScreen = () => {
  const translate = useFormatMessage()

  return (
    <section className={styles.container}>
      <PageTitle title={translate(messages.pageTitle)} />
      <LandingHeader />
      <main className={styles.content}>
        <img src={errorImage} alt={translate(messages.stethoscope)} />
        <h1 className={styles.title}>{translate(messages.contentTitle)}</h1>
        <p>{translate(messages.contentMessage)}</p>
      </main>
      <LandingFooter />
    </section>
  )
}

export default MaintenanceSplashScreen
