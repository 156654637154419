import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useSelector } from 'react-redux'
import Button from '@/programmes/components/Button'
import BABYLON_US_APP from '@/assets/images/babylon_us_ios_app.png'
import Template from '../Template'
import styles from '../index.module.scss'
import messages from '../messages'
import Card from '@/programmes/components/Card'
import WeightLossReadInstructions from '@/assets/images/assessment.png'
import AppsInfo from '@/components/AppsInfo'
import { setupSteps } from './steps'

const cx = classNames.bind(styles)

const SetupConfirmation = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.setupConfirmationPageTitle)}
      hasAppLayout={isAuthenticated}
      steps={setupSteps}
      activeStep={3}
    >
      <h2>{t(messages.setupConfirmationPageHeading)}</h2>
      <p>{t(messages.setupConfirmationPageDescription)}</p>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.setupConfirmationPageCard1Title)}
          description={t(messages.setupConfirmationPageCard1Description)}
          image={WeightLossReadInstructions}
          imageDescription={t(
            messages.setupConfirmationPageCard1ImageDescription
          )}
        />
      </div>
      <AppsInfo
        title={t(messages.setupConfirmationPageCard2Title)}
        content={t(messages.checkoutConfirmationPageAppInfoDescription)}
        image={BABYLON_US_APP}
      />
      <Button
        onClick={() => {
          history.push('/')
        }}
      >
        {t(messages.setupConfirmationPageCta)}
      </Button>
    </Template>
  )
}

export default SetupConfirmation
