import { Step } from '@/programmes/components/FormV2/types'

const eligibilitySteps: Step[] = [
  {
    id: '7137e447-5fca-41b3-aab1-42ba94ea0985',
    type: 'question',
    title: 'Please tell us your sex assigned at birth',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Female',
        value: 'female',
      },
      {
        label: 'Male',
        value: 'male',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
    optionsRules: [
      {
        label: 'female',
        nextAction: '07a25ee9-3922-4223-b804-91c064b52c75',
      },
    ],
    next: 'exit-action',
  },
  {
    id: '07a25ee9-3922-4223-b804-91c064b52c75',
    type: 'question',
    title: 'Are you currently pregnant, or could you be pregnant?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Pregnant',
        value: 'pregnant',
      },
      {
        label: 'Could be pregnant',
        value: 'could_be_pregnant',
      },
      {
        label: 'None of the above',
        value: 'none',
      },
    ],
    optionsRules: [
      {
        label: 'none',
        nextAction: 'fb5e3215-20fe-4cec-93d4-c9a59cef0efc',
      },
    ],
    next: 'exit-action',
  },
  {
    id: 'fb5e3215-20fe-4cec-93d4-c9a59cef0efc',
    type: 'question',
    required: true,
    title: 'Do you have a urinary catheter?',
    body:
      'This is a tube that is inserted into your bladder, which is used to empty the bladder and collect urine.',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    optionsRules: [
      {
        label: 'Yes',
        nextAction: 'exit-action',
      },
    ],
    next: 'complete-action',
  },
]

export default eligibilitySteps
