import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import cn from 'classnames'
import loadable from '@loadable/component'
import { useFormatMessage } from '@babylon/intl'
import { ModalProvider, ScrollToTop } from '@babylon/medkit'
import {
  TrackingProvider,
  TealiumService,
  NoOpTrackingService,
} from '@babylon/tracking/react'
import { envVar } from '@babylon/babylon-env'
import PageTitle from '@/components/PageTitle/PageTitle'
import NoticePage from '@/views/NoticePage'
import Alert from './components/Alert'
import Feedback from './views/FeedbackPage'
import ConsultationPage from './views/ConsultationPage'
import VideoCallWithToken from './views/ConsultationPage/VideoCallPage'
import ConsultationErrorPage from './views/ConsultationPage/ConsultationErrorPage'
import PostConsultation from './views/ConsultationPage/PostConsultationPage'
import PrivateRoute from './components/Authentication'
import Register from './components/Authentication/Register'
import ResetPassword from './components/Authentication/ResetPassword'
import ChangePassword from './components/Authentication/ChangePassword'
import SetPassword from './components/Authentication/SetPassword'
import AuthCallback from './components/Authentication/AuthCallback'
import PrivateRoutes from './routes'
import PartnerRoutes from './routes/PartnerRoutes'
import {
  useUnderMaintenance,
  useEmbeddedViewModeEnabled,
} from './redux/selectors'

import { useChatbotStarted } from './components/Chatbot/selectors'
import { useVideoCallState } from './components/VideoCall/selectors'
import messages from './App.messages'
import styles from './App.module.scss'
import 'normalize.css/normalize.css'
import './globals.css'
import ServiceUnavailablePage from './views/ServiceUnavailablePage'
import DownloadAppPage from './views/DownloadAppPage'
import MaintenanceSplashScreen from './views/MaintenanceSplashScreen'
import { useSetupOneTrustListeners, CookieGroup } from './tracking/one-trust'
import { ONE_TRUST_APP_ID } from '@/config'
import { useSaveDiscountCode } from '@/hooks/useHandleDiscountCode'
import useServiceNowChat from '@/utils/serviceNowChat'
import { isPartnerLocation } from '@/utils/partners'

const GetStartedLoader = loadable(() =>
  import(/* webpackChunkName: "onboarding" */ './views/GetStartedPage')
)

/**
 * @type {TeliumService | undefined}
 */
let tealiumService

const getTealiumService = () => {
  if (!tealiumService) {
    tealiumService = new TealiumService({
      app: envVar('TEALIUM_APP') ?? 'uk',
      appName: envVar('TEALIUM_APP_NAME') ?? 'web-app',
      appVersion: process.env.REACT_APP_VERSION ?? '',
      env: envVar('BABYLON_ENVIRONMENT') ?? 'dev',
      countryIsoCode: envVar('ENVIRONMENT_COUNTRY_ISO_CODE') ?? 'GBR',
    })
  }
  return tealiumService
}

const App = () => {
  const featureFlags = useSelector((state) => state.featureFlags)
  const underMaintenance = useUnderMaintenance()
  const location = useLocation()
  const isPartnerRoute = isPartnerLocation(location)
  const showPartnerRoutesOnly = useEmbeddedViewModeEnabled() || isPartnerRoute
  const translate = useFormatMessage()
  const [, inCall] = useVideoCallState()
  const hasChatbotStarted = useChatbotStarted()
  const disableOneTrustCookies = !!window.webViewMode || isPartnerRoute
  useSetupOneTrustListeners({
    uuid: ONE_TRUST_APP_ID,
    disabled: disableOneTrustCookies,
  })
  const isTrackingEnabled =
    useSelector(
      /**
       *
       * @param {{ oneTrust: import('./tracking/one-trust/types').OneTrustState }} state
       * @returns
       */
      (state) =>
        state.oneTrust.cookiePreferences.find(
          (cg) => cg === CookieGroup.PERFORMANCE
        )
    ) && !disableOneTrustCookies
  useSaveDiscountCode()
  useServiceNowChat()

  if (underMaintenance) {
    return <MaintenanceSplashScreen />
  }

  const trackingService = isTrackingEnabled
    ? getTealiumService()
    : new NoOpTrackingService()
  window.trackingService = trackingService

  return (
    <TrackingProvider service={trackingService}>
      <PageTitle />
      <ScrollToTop variableToWatch={location.pathname}>
        <ModalProvider
          closeButtonText={translate(messages.closeButtonText)}
          backButtonText={translate(messages.backButtonText)}
        >
          <div
            className={cn(styles.flexWrapper, {
              [styles.flexWrapperHidden]: inCall || hasChatbotStarted,
            })}
            aria-hidden={inCall ? true : undefined}
          >
            <Alert />
            {!showPartnerRoutesOnly && (
              <Switch>
                <PrivateRoute exact path="/register" component={Register} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route
                  exact
                  // Deprecated - use /change-password?token=... instead
                  path="/change-password/:token"
                  component={ChangePassword}
                />
                <Route
                  exact
                  path="/change-password"
                  component={ChangePassword}
                />
                <Route
                  exact
                  // Deprecated - use /set-password?token=... instead
                  path="/set-password/:token"
                  component={SetPassword}
                />
                <Route exact path="/set-password" component={SetPassword} />
                <Route path="/consultation">
                  <Switch>
                    <Route
                      exact
                      path="/consultation/error"
                      component={ConsultationErrorPage}
                    />
                    <ConsultationPage>
                      <Switch>
                        <Route
                          exact
                          path="/consultation/videocall"
                          component={VideoCallWithToken}
                        />
                        <Route
                          exact
                          path="/consultation/post"
                          component={PostConsultation}
                        />
                        <Redirect to="/" />
                      </Switch>
                    </ConsultationPage>
                  </Switch>
                </Route>
                <Route
                  // Deprecated - use /consultation/videocal?token=...
                  path="/videocall/:token"
                  render={({ match }) => {
                    const token = match?.params?.token

                    return (
                      <Redirect to={`/consultation/videocall?token=${token}`} />
                    )
                  }}
                />
                <Route exact path="/feedback" component={Feedback} />
                <Route
                  path="/get-started"
                  render={({ match }) => <GetStartedLoader {...match.params} />}
                />
                <Route
                  path="/postcode-not-eligible"
                  component={ServiceUnavailablePage}
                />
                <Route exact path="/download-app" component={DownloadAppPage} />
                <Route
                  exact
                  path="/privacy"
                  component={() => <NoticePage type="privacyPolicy" />}
                />
                <Route
                  exact
                  path="/terms"
                  component={() => <NoticePage type="termsAndConditions" />}
                />
                <Route path="/callback" exact render={() => <AuthCallback />} />
                <PrivateRoutes featureFlags={featureFlags} />
              </Switch>
            )}
            {showPartnerRoutesOnly && (
              <Switch>
                <PartnerRoutes featureFlags={featureFlags} />
              </Switch>
            )}
          </div>
        </ModalProvider>
      </ScrollToTop>
    </TrackingProvider>
  )
}

export default App
