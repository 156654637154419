import React, { useCallback, useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/react-hooks'
import { Box, FormControl } from '@babylon/web-platform-ui'
import { Code } from '@babylon/consumer-api/types'
import TextInput from '@/programmes/components/Form/components/TextInput'
import Button from '@/programmes/components/Form/components/Button'
import ApplyCodeMutation from '@/mutations/ApplyCode'
import messages from '../../messages'

interface ProductActivationCodeProps {
  activeUserId: string
  handleClick: () => Promise<any>
  loading: boolean
}

const ProductActivationCode = ({
  activeUserId,
  handleClick,
  loading,
}: ProductActivationCodeProps) => {
  const t = useFormatMessage()
  const [membershipCodeError, setMembershipCodeError] = useState<string>('')
  const [membershipCodeInput, setMembershipCodeInput] = useState<string>('')

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMembershipCodeError('')
      setMembershipCodeInput(e.target.value)
    },
    []
  )

  const [applyCode, { loading: isUpdatingUserCNCode }] = useMutation<Code>(
    ApplyCodeMutation
  )

  const addMembershipCode = useCallback(
    async (userId: number, code: string) => {
      try {
        await applyCode({
          variables: {
            patient_id: userId,
            code,
            redemption_type: 'hybrid',
          },
        })
        setMembershipCodeInput('')
        await handleClick()
      } catch (error: any) {
        setMembershipCodeInput('')
        const userMessage =
          error?.graphQLErrors?.[1]?.parsedError?.user_message ||
          'An error occurred'
        setMembershipCodeError(userMessage)
      }
    },
    [applyCode, handleClick]
  )

  const handleSubmit = useCallback(() => {
    addMembershipCode(parseInt(activeUserId, 10), membershipCodeInput)
  }, [addMembershipCode, activeUserId, membershipCodeInput])

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <FormControl>
        <TextInput
          required
          label={t(messages.activationCodeLabel)}
          body={t(messages.activationCodeHint)}
          placeholder={t(messages.activationCodePlaceholder)}
          onChange={handleInputChange}
          value={membershipCodeInput}
          error={membershipCodeError !== ''}
          errorMessage={membershipCodeError}
        />
      </FormControl>
      <Box paddingTop="0.8rem" />
      <Button
        title={t(messages.activationCodeSubmit)}
        type="primary"
        onClick={handleSubmit}
        loading={isUpdatingUserCNCode || loading}
      />
    </form>
  )
}

export default ProductActivationCode
