import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React from 'react'

import styles from './button.module.scss'

const cx = classNames.bind(styles)

const Loader = (props) => (
  <svg height="16" width="16" className={styles.loaderContainer}>
    <circle
      cx="8"
      cy="8"
      r="6"
      fill="transparent"
      stroke="white"
      strokeWidth="2"
      strokeDasharray="25"
      className={styles.loader}
      {...props}
    />
  </svg>
)

const Button = ({
  type,
  label,
  buttonStyle,
  size,
  isLoading,
  className,
  color,
  backgroundColor,
  elementType,
  ...rest
}) => {
  const style = { color, backgroundColor }
  const Wrapper = elementType

  return (
    <Wrapper
      type={elementType === 'button' ? type : null}
      style={style}
      className={cx(
        'button',
        `button--${buttonStyle}`,
        `button--${size}`,
        className
      )}
      {...rest}
    >
      {isLoading ? <Loader /> : label}
    </Wrapper>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  elementType: PropTypes.oneOf(['button', 'a']).isRequired,
  label: PropTypes.node,
  buttonStyle: PropTypes.oneOf([
    'primary',
    'primary-inverse',
    'secondary',
    'outlined',
    'outlined-negative',
    'positive',
    'negative',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
  elementType: 'button',
  label: 'Button',
  buttonStyle: 'primary',
  size: 'medium',
  isLoading: false,
  className: '',
  color: null,
  backgroundColor: null,
}

export default Button
export { Loader }
