import { ApolloClient } from 'apollo-client'
import { split, from } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { GRAPHQL_ENDPOINT, IS_SERVER } from '.'

import {
  formFeedbackAfterware,
  flashErrorAfterware,
  flashFeedbackAfterware,
  errorParsingAfterware,
  errorLoggingAfterware,
  unauthorizedRequestAfterware,
  setRequestHeaders,
  setWebViewRequestHeaders,
} from '@/middleware'
import { selectAppName, selectDefaultLanguage } from '@/redux/selectors'

const isUploading = ({ variables }) =>
  Object.values(variables).some(
    (value) =>
      value instanceof Blob ||
      value instanceof File ||
      value instanceof FileList
  )

let client

const apolloUri = `${GRAPHQL_ENDPOINT}/api`

// See https://github.com/apollographql/apollo-link/blob/e8105e628cd7d18718254fcfd65fad3fe5d9b8c7/packages/apollo-link-batch-http/src/batchHttpLink.ts#L208
// for the apollo implementation
const batchKey = (operation) => {
  const context = operation.getContext()

  const contextConfig = {
    http: context.http,
    options: context.fetchOptions,
    credentials: context.credentials,
    // babylon-request-id is unique on each request
    // we must remove its value here to ensure queries get batched
    headers: { ...context.headers, 'babylon-request-id': null },
  }

  const uri = context.uri || apolloUri

  return uri + JSON.stringify(contextConfig)
}

const init = ({ store }) => {
  const httpLinkConfig = {
    uri: apolloUri,
    credentials: 'include',
  }

  const httpLink = new BatchHttpLink({
    batchKey,
    ...httpLinkConfig,
  })
  const uploadLink = createUploadLink(httpLinkConfig)

  const cache = new InMemoryCache({
    addTypename: true,
  }).restore(window.__APOLLO_STATE__) // eslint-disable-line no-underscore-dangle

  // TODO: implement this in a way which allows the locale sent with headers to be updated when the user changes the language

  const state = store.getState()
  const appName = selectAppName(state)
  const defaultLocale = selectDefaultLanguage(state)

  client = new ApolloClient({
    ssrMode: IS_SERVER,
    link: from([
      setRequestHeaders(appName, defaultLocale),
      setWebViewRequestHeaders,
      flashErrorAfterware,
      unauthorizedRequestAfterware,
      flashFeedbackAfterware,
      formFeedbackAfterware,
      errorLoggingAfterware,
      errorParsingAfterware,
      split(isUploading, uploadLink, httpLink),
    ]),
    cache,
    addTypename: true,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  })
}

const getServerClient = () =>
  new ApolloClient({
    ssrMode: true,
    link: createHttpLink({
      uri: apolloUri,
      credentials: 'include',
      fetch,
    }),
    cache: new InMemoryCache(),
    addTypename: true,
  })

const getClient = () => client

export { init, getClient, getServerClient }
