import React from 'react'
import PropTypes from 'prop-types'

const rhythm = 8

const steps = [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8]

/**
 * A small component that allows consistent spacing to be used
 */
const Spacer = ({
  type,
  children,
  top,
  left,
  right,
  bottom,
  style,
  ...props
}) =>
  React.createElement(
    type,
    {
      ...props,
      style: {
        ...style,
        marginTop: top * rhythm,
        marginRight: right * rhythm,
        marginBottom: bottom * rhythm,
        marginLeft: left * rhythm,
      },
    },
    children
  )

Spacer.defaultProps = {
  type: 'div',
  children: undefined,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

Spacer.propTypes = {
  /**
   * The element or component that should be used with the spacer
   */
  type: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * The children that can optionally be rendered inside the component
   */
  children: PropTypes.node,
  /**
   * The spacing above this component
   */
  top: PropTypes.oneOf(steps),
  /**
   * The spacing to the right of this component
   */
  right: PropTypes.oneOf(steps),
  /**
   * The spacing below this component
   */
  bottom: PropTypes.oneOf(steps),
  /**
   * The spacing to the left of this component
   */
  left: PropTypes.oneOf(steps),
}

export default Spacer
