import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import Loading from '@/components/Loading'
import SwitchOption from './Notice'

import messages from './messages'

interface Notice {
  noticeVersionId: string
  accepted: boolean
  title: string
  settingsText: string
  linkText: string
  longText: string
}

export interface Props {
  loading: boolean
  notices: Notice[]
  handleChange: (noticeVersionId: string, decision: boolean) => void
}

const PrivacySettingsPage: React.FC<Props> = ({
  loading,
  notices,
  handleChange,
}) => {
  const translate = useFormatMessage()

  if (loading) {
    return <Loading />
  }

  return (
    <section className="Content--narrow">
      <h1 className="PageTitle">{translate(messages.pageTitle)}</h1>
      {notices && (
        <ul>
          {notices.map((notice: any) => (
            <li key={notice.noticeVersionId}>
              <SwitchOption
                id={notice.noticeVersionId}
                onChange={(decision: boolean) =>
                  handleChange(notice.noticeVersionId, decision)
                }
                notice={notice}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

export default PrivacySettingsPage
