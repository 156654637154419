import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'VideoCallPage.heading',
    defaultMessage: '{appTitle} video appointment',
  },
  pageSubTitle: {
    id: 'VideoCallPage.subHeading',
    defaultMessage: 'The clinician will be with you soon',
  },
  pageDescription: {
    id: 'VideoCallPage.description',
    defaultMessage: 'This might take a few minutes – keep this page open.',
  },
  checkListTitle: {
    id: 'VideoCallPage.checkList.title',
    defaultMessage: 'Check you:',
  },
  checkListFirstBulletPoint: {
    id: 'VideoCallPage.checkList.firstBulletPoint',
    defaultMessage: 'Have a good wifi or 4G connection',
  },
  checkListSecondBulletPoint: {
    id: 'VideoCallPage.checkList.secondBulletPoint',
    defaultMessage: 'Are somewhere you can talk about your health',
  },
  connectionProblemsTitle: {
    id: 'VideoCallPage.connectionProblems.title',
    defaultMessage: 'If you have connection problems',
  },
  connectionProblemsDescription: {
    id: 'VideoCallPage.connectionProblems.description',
    defaultMessage:
      'The clinician will phone you on the number you gave when booking.',
  },
})
