import { trackEvent, trackUser } from '@babylon/tracking/gtag'

import { observeStore } from '@/redux/utils'
import {
  selectUserId,
  startedLoginMethod,
  successfulLoginMethod,
} from './selectors'

const trackLoginStarted = (loginMethod) => {
  if (loginMethod) {
    trackEvent({
      category: 'login',
      action: 'started login',
      loginMethod,
    })
  }
}

const trackLoginSuccess = (loginMethod) => {
  if (loginMethod) {
    trackEvent({
      category: 'login',
      action: 'successful login',
      loginMethod,
    })
  }
}

const trackUserIdChange = (id) => {
  trackUser(id, 'userId')
}

const track = (store) => {
  observeStore(store, selectUserId, trackUserIdChange)
  observeStore(store, startedLoginMethod, trackLoginStarted)
  observeStore(store, successfulLoginMethod, trackLoginSuccess)
}

export default track
