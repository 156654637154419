/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import {
  ButtonSimple,
  ButtonVariant,
  Link,
  Text,
  EditorialLayout,
} from '@babylon/medkit'
import classNames from 'classnames/bind'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import { login as loginAction, register as registerAction } from '../../actions'
import { useRegistrationFields } from '@/redux/selectors'

import messages from '../messages'
import appMessages from '@/App.messages'
import styles from '../styles.module.scss'

import WELCOME_IMAGE from '@/assets/images/welcome_screen.jpg'

const cx = classNames.bind(styles)

const enhance = compose(
  injectIntl,
  withRouter,
  connect(null, (dispatch) => ({
    register: () => dispatch(registerAction()),
    login: (email) => dispatch(loginAction(email)),
  }))
)

const Login = ({
  showCreateAccount = true,
  showBackToMarketing = false,
  intl,
  login,
  register,
  email,
  subtitle = null,
  showWelcomeImage = false,
}) => {
  const { enabled: registrationEnabled } = useRegistrationFields() || {}
  const logoutRedirectPath = useSelector(
    (state) => state.config?.logoutRedirectPath
  )

  return (
    <div className={cx(styles.loginFormWrapper)}>
      <section className={cx('Content--form')}>
        <EditorialLayout>
          <Text variant="h1">{intl.formatMessage(messages.signIn)}</Text>
          <Text variant="body">{subtitle}</Text>
        </EditorialLayout>

        {showWelcomeImage && (
          <img
            className={cx(styles.welcomeImage)}
            src={WELCOME_IMAGE}
            alt={intl.formatMessage(messages.signIn)}
          />
        )}

        {registrationEnabled && showCreateAccount && (
          <ButtonSimple fullWidth onClick={register}>
            {intl.formatMessage(messages.createAccount)}
          </ButtonSimple>
        )}

        <ButtonSimple
          fullWidth
          variant={ButtonVariant.secondary}
          onClick={() => login(email)}
          data-testid="loginButton"
        >
          {intl.formatMessage(
            registrationEnabled && showCreateAccount
              ? messages.logIn
              : appMessages.next
          )}
        </ButtonSimple>
        {showBackToMarketing && logoutRedirectPath && (
          <Link
            to={logoutRedirectPath}
            className={cx(styles.backButton)}
            target="_self"
          >
            {intl.formatMessage(messages.back)}
          </Link>
        )}
      </section>
    </div>
  )
}

export default enhance(Login)
