import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'ChangePasswordPage.pageTitle',
    defaultMessage: 'Change Password',
  },
  newPassword: {
    id: 'ChangePasswordPage.newPassword',
    defaultMessage: 'New password',
  },
  change: {
    id: 'ChangePasswordPage.change',
    defaultMessage: 'Change my password',
  },
  success: {
    id: 'ChangePasswordPage.success',
    defaultMessage:
      'Your password was reset successfully. You may now sign in to the app.',
  },
})
