import React from 'react'
import { injectIntl } from 'react-intl'
import { Button } from '@babylon/medkit'

import intlShape from '@/utils/intlShape'

import Modal from './index'
import messages from './Modal.messages'
import styles from './Modal.module.scss'

const PromotionInfoModal = ({ onClose, promotion = {}, intl }) => {
  const { description, logo, isMembership } = promotion

  return (
    <Modal className={styles.centered} onClose={onClose}>
      <h3 data-testid="promotionTitle">
        {!isMembership
          ? intl.formatMessage(messages.promotionApplied)
          : intl.formatMessage(messages.membershipApplied)}
      </h3>
      {logo && <img className={styles.promotionImage} src={logo} alt="" />}
      <p className={styles.modalSubtitle}>{description}</p>
      {!isMembership && (
        <p className={styles.modalSubtitle}>
          {intl.formatMessage(messages.confirmationEmail)}
        </p>
      )}
      <Button data-testid="promotionCloseButton" onClick={onClose} fullWidth>
        {intl.formatMessage(messages.close)}
      </Button>
    </Modal>
  )
}

PromotionInfoModal.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(PromotionInfoModal)
