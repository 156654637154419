const ROOT_PATH = '/screen'

// take user's weight
const UK_WELLNESS_CHECK_IN = 'WELLNESS_CHECK_IN'
const US_WELLNESS_CHECK_IN = 'US_WELLNESS_CHECK_IN'
const WELLNESS_CHECKOUT = 'WELLNESS_CHECKOUT'
const WELLNESS_CHECKOUT_CONFIRMATION = 'WELLNESS_CHECKOUT_CONFIRMATION'
const WELLNESS_S2P_SETUP = 'WELLNESS_S2P_SETUP'

export const procedures = {
  [UK_WELLNESS_CHECK_IN]: UK_WELLNESS_CHECK_IN,
  [WELLNESS_S2P_SETUP]: WELLNESS_S2P_SETUP,
  [US_WELLNESS_CHECK_IN]: US_WELLNESS_CHECK_IN,
}

const entrypoints = {
  [WELLNESS_S2P_SETUP]: 'setup',
  [WELLNESS_CHECKOUT]: 'checkout',
  [WELLNESS_CHECKOUT_CONFIRMATION]: 'confirmation',
}

export const routes = {
  home: ROOT_PATH,
  questionnaire: `${ROOT_PATH}/questionnaire`,
  checkIn: `${ROOT_PATH}/check-in`,
  checkInAutomated: `${ROOT_PATH}/check-in/start`,
  confirmation: `${ROOT_PATH}/confirmation`,
  ineligible: `${ROOT_PATH}/ineligible`,
  dashboard: `${ROOT_PATH}/dashboard`,
}

export const v2Routes = {
  home: `${ROOT_PATH}/v2`,
  setup: `${ROOT_PATH}/v2/setup`,
  confirmation: `${ROOT_PATH}/v2/confirmation`,
  report: `${ROOT_PATH}/v2/report`,
}

export const checkoutRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WELLNESS_CHECKOUT]}`,
  confirmation: `${ROOT_PATH}/${entrypoints[WELLNESS_CHECKOUT]}/${entrypoints[WELLNESS_CHECKOUT_CONFIRMATION]}`,
}

export const reportRoutes = {
  report: '/wellness/report',
}
