import gql from 'graphql-tag'

export default gql`
  query FindSubscriptionInfoOnPatient(
    $patientId: ID!
    $productGroups: [ProductGroup!]
  ) {
    patient(id: $patientId) {
      ... on Patient {
        id
        products(productGroups: $productGroups) {
          ... on ProductList {
            items {
              defaultPrice
              isRecommended
              order
              description
              features {
                name
              }
              group
              hasLegacySubscription
              id
              images
              name
              prices {
                currency
                interval
                id
                lookupKey
                unitAmount
              }
              subscription {
                status
                id
                price {
                  currency
                  id
                  interval
                  lookupKey
                  unitAmount
                }
              }
              targetConsumerNetworkId
            }
          }
        }
      }
    }
  }
`
