import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import WeightLossImage from '@/assets/images/weight-loss_person-on-scales.jpg'
import { login as loginAction } from '@/components/Authentication/actions'
import Button from '@/programmes/components/Button'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import { useTrackingPageRedirect } from '@/programmes/tracking'
import Template from '../Template'
import { procedures } from '../routes'
import messages from '../messages'
import styles from '../index.module.scss'
import checkInSteps from '@/programmes/Wellness/CheckIn/steps'
import useGetPreferredConsumerNetwork from '@/programmes/hooks/useGetPreferredConsumerNetwork'
import { US_CN_NAME } from '@/config'

const cx = classNames.bind(styles)

const CheckInPage = () => {
  const t = useFormatMessage()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const trackingPageRedirect = useTrackingPageRedirect()
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const {
    getPreferredConsumerNetwork,
    loading: loadingCN,
  } = useGetPreferredConsumerNetwork()
  const proctorInviteUrl = data?.createProctorSessionInvite?.url
  const [preferredCN, setPreferredCN] = useState<string>()

  const procedure = preferredCN?.toLowerCase().includes(US_CN_NAME)
    ? procedures.US_WELLNESS_CHECK_IN
    : procedures.WELLNESS_CHECK_IN

  useEffect(() => {
    const preferredConsumerNetwork = getPreferredConsumerNetwork()
    setPreferredCN(preferredConsumerNetwork?.name)
  }, [getPreferredConsumerNetwork, loadingCN])

  if (proctorInviteUrl) {
    trackingPageRedirect()
    window.location.href = proctorInviteUrl
  }

  const handleClick = () => {
    if (isAuthenticated) {
      return getProctorSessionInvite({
        variables: {
          input: {
            procedure: { identifier: { value: procedure } },
          },
        },
      })
    }

    return dispatch(loginAction())
  }

  return (
    <Template
      title={t(messages.checkInPageTitle)}
      hasAppLayout={isAuthenticated}
      steps={checkInSteps}
      activeStep={0}
      loading={loadingCN}
    >
      <h2 className={cx('heading')}>{t(messages.checkInPageHeading)}</h2>
      <p className={cx('description')}>{t(messages.checkInPageDescription1)}</p>
      <p className={cx('description')}>{t(messages.checkInPageDescription2)}</p>
      <Spacer bottom={2}>
        <Button
          onClick={handleClick}
          loading={loading || !!proctorInviteUrl}
          disabled={loading || !!proctorInviteUrl}
        >
          {t(
            isAuthenticated
              ? messages.checkInPageCta2
              : messages.checkInPageCta1
          )}
        </Button>
      </Spacer>
      <p className={cx('description')}>{t(messages.checkInPageFooter)}</p>
      <Spacer top={2}>
        <img
          className={cx('check-in-image')}
          src={WeightLossImage}
          alt={t(messages.checkInPageImageDescription)}
        />
      </Spacer>
    </Template>
  )
}

export default CheckInPage
