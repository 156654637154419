import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

interface SupportChatData {
  patient: {
    support_chat: {
      embedded_chat_url: string
      login_url: string
      logout_url: string
    }
  }
}

interface SupportChatVars {
  userId: string
}

const GetSupportChatQuery = gql`
  query EmbeddedSupportChat($userId: ID!) {
    patient(id: $userId) {
      id # we will get this breaking error if we don't query the id: https://github.com/apollographql/apollo-client/issues/2510
      support_chat {
        login_url
        embedded_chat_url
        logout_url
      }
    }
  }
`

/**
 * Custom hook to retrieve the chat URL for the embedded support chat.
 * @param userEmail - The email of the user.
 * @returns The chat URL for the embedded support chat.
 */
const useEmbeddedSupportChat = (currentUserId: string) => {
  const { data, error, loading } = useQuery<SupportChatData, SupportChatVars>(
    GetSupportChatQuery,
    {
      skip: !currentUserId,
      variables: {
        userId: currentUserId,
      },
    }
  )

  return { data: data?.patient?.support_chat, error, loading }
}

export default useEmbeddedSupportChat
