import React from 'react'
import List from '../../../List'
import ArrowListItem from '../../../ArrowListItem'
import FormattedFirstLine from './FormattedFirstLine'
import { LookupDataList } from '../../types'

interface Props {
  list: LookupDataList
  onSelect: (first_line: string) => void
}

const AddressList: React.FC<Props> = ({ list, onSelect }) => (
  <List>
    {list.addresses.map((first_line: string, index: number) => (
      <ArrowListItem
        handle={`addresses-picker-modal-address${index}`}
        text={
          <FormattedFirstLine
            first_line={first_line}
            town={list.town}
            postcode={list.postcode}
          />
        }
        onClick={() => onSelect(first_line)}
        key={`address-${index}`}
      />
    ))}
  </List>
)

export default AddressList
