import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import {
  UPDATE_FORM_DATA,
  SET_FORM_STEP,
  RESET_FORM_DATA,
  RESET_FORM_STORE,
  ON_ADDRESS_PICKED,
  ON_NEW_ACCOUNT_LOGIN_INITIATED,
} from './constants'
import { RESET_STORE } from '../constants'
import { LOGOUT } from '@/components/Authentication/actionTypes'

const initialState = {
  highestStep: 0,
  formData: {},
  addressPicked: false,
  newAccountLoginInitiated: false,
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOGOUT:
    case RESET_STORE:
    case RESET_FORM_STORE:
      return { ...initialState }
    case RESET_FORM_DATA:
      return {
        ...initialState,
        highestStep: state.highestStep,
      }
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        },
      }
    case SET_FORM_STEP:
      return {
        ...state,
        highestStep: action.step,
      }
    case ON_ADDRESS_PICKED:
      return {
        ...state,
        addressPicked: action.addressPicked,
      }
    case ON_NEW_ACCOUNT_LOGIN_INITIATED:
      return {
        ...state,
        newAccountLoginInitiated: action.newAccountLoginInitiated,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'gp@hand',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default persistedReducer
