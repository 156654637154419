import gql from 'graphql-tag'

export default gql`
  query FeatureSwitches($patientId: Int!) {
    featureSwitches(patientId: $patientId) {
      enable_adding_adults
      enable_adding_children
      enable_appointments
      enable_changes_to_medical_history
      enable_digital_service_mix
      enable_family_accounts
      enable_public_healthcare_registration
      enable_restricted_your_details
      enable_see_pharmacies
      enable_update_gp_details
      id_verification_required_for_appointments
      nhs_gp_consumer_network
      refer_a_friend
      show_billing_history
      show_kit
      show_gp_consent
      show_gp_details
      show_insurance
      show_medical_history
      show_membership_codes
      show_payment_details
      show_pharmacy
      show_public_healthcare_identifier
    }
  }
`
