import React, { Component } from 'react'
import { ButtonSimple, ButtonVariant, Seperator } from '@babylon/medkit'
import classNames from 'classnames/bind'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import flatten from 'lodash/flatten'

import ErrorBoundary from '../ErrorBoundary'
import {
  resetSingleForm,
  displayFormErrors,
  registerActiveForm,
} from '@/redux/formErrors/actions'
import Loading from '../Loading'

import styles from './Form.module.scss'
import messages from './Form.messages'

const cx = classNames.bind(styles)

const mapFields = (parsedFields) => {
  const multiValueFields = []
  let defaultValues = {}
  const changeHandlers = {}
  const fields = parsedFields.reduce((res, row) => {
    ;(row.rowFields || []).forEach((field) => {
      if (field.defaultValue) {
        defaultValues[field.name] = field.defaultValue
      }

      if (field.defaultValues) {
        multiValueFields.push(field.name)
        res = {
          ...res,
          ...field.defaultValues,
        }
        defaultValues = {
          ...defaultValues,
          ...field.defaultValues,
        }
      } else if (typeof field.defaultValue !== 'function') {
        res[field.name] = field.defaultValue
      }

      if (field.onChange) {
        changeHandlers[field.name] = field.onChange
      }
    })

    return res
  }, {})

  return { fields, multiValueFields, defaultValues, changeHandlers }
}

const getRequiredFields = (rows, fields) => {
  let requiredFields = []
  rows.forEach((row) => {
    requiredFields = requiredFields.concat(
      row.rowFields
        .filter((f) =>
          typeof f.required === 'function' ? f.required(fields) : f.required
        )
        .filter((f) =>
          typeof f.shouldRender === 'function' ? f.shouldRender(fields) : true
        )
        .map((f) => (f.defaultValues ? Object.keys(f.defaultValues) : f.name))
    )
  })

  return flatten(requiredFields, true)
}

const getCustomMessages = (rows, invalidFields) =>
  rows
    .filter((row) =>
      row.rowFields.find((field) => invalidFields.includes(field.name))
    )
    .reduce((obj, row) => {
      const customMessageFields = row.rowFields.filter(
        (field) => field.requiredMessage
      )
      customMessageFields.forEach((field) => {
        const { name, requiredMessage = null } = field

        if (name && requiredMessage) {
          obj[name] = requiredMessage
        }
      })

      return obj
    }, {})

class Form extends Component {
  constructor(props) {
    super(props)
    const parsedFields = props.fields.filter((field) =>
      Array.isArray(field.rowFields)
    )

    const {
      fields,
      multiValueFields,
      defaultValues,
      changeHandlers,
    } = mapFields(parsedFields)

    // multiValue fields shouldn't change so we don't store them in state
    this.multiValueFields = multiValueFields
    this.changeHandlers = changeHandlers

    this.state = {
      parsedFields,
      fields,
      defaultValues,
      isLoading: false,
      areBtnsDisabled: props.checkPristine,
      isFormDirty: false,
    }
    this.initialState = { ...this.state }
  }

  static getDerivedStateFromProps(props, state) {
    const parsedFields = props.fields.filter((field) =>
      Array.isArray(field.rowFields)
    )
    // Update fields if default values have been added
    const fields = parsedFields.reduce((acc, row) => {
      const rowFields = row.rowFields || []
      rowFields.forEach((field) => {
        if (acc[field.name] === undefined && field.defaultValue) {
          acc[field.name] = field.defaultValue
        }
      })

      return acc
    }, state.fields)

    return {
      parsedFields,
      fields,
    }
  }

  componentDidMount() {
    this.props.operationName &&
      this.props.registerActiveForm(this.props.operationName)

    this._isMounted = true
  }

  componentWillUnmount() {
    this.props.operationName &&
      this.props.resetSingleForm(this.props.operationName)

    this._isMounted = false
  }

  changeInput = (inputName) => (event) => {
    let value = event

    if ((event || {}).target) {
      value = event.target.value
    }

    const { fields, isFormDirty } = this.state
    const { onChange, onFormDirty } = this.props

    // check if field has its own change handler
    const fieldOnChange = this.changeHandlers[inputName]

    if (!!onFormDirty && !isFormDirty) {
      this.setState({ isFormDirty: true }, () => onFormDirty())
    }

    // multiValue fields are expected to invoke `changeInput` with:
    // { [fieldName1]: <newValue>, [fieldName2]: <newValue2>, etc... }
    if (this.multiValueFields.indexOf(inputName) > -1) {
      this.setState(
        {
          fields: {
            ...fields,
            ...value,
          },
          areBtnsDisabled: false,
          isFormDirty: true,
        },
        () => {
          onChange(this.state.fields, inputName)

          if (fieldOnChange) {
            fieldOnChange(this.state.fields[inputName])
          }
        }
      )
    } else {
      this.setState(
        {
          fields: {
            ...fields,
            [inputName]: value,
          },
          areBtnsDisabled: false,
        },
        () => {
          onChange(this.state.fields, inputName)

          if (fieldOnChange) {
            fieldOnChange(this.state.fields[inputName])
          }
        }
      )
    }
  }

  setPristineState = () => {
    if (this.props.checkPristine) {
      this.setState({ areBtnsDisabled: true })
    }
  }

  onSubmit = (e) => {
    e && e.preventDefault && e.preventDefault()

    const {
      intl,
      checkPristine,
      onSubmit,
      resetSingleForm,
      displayFormErrors,
      operationName,
    } = this.props
    const { fields } = this.state

    const requiredFields = getRequiredFields(this.state.parsedFields, fields)
    // find required fields that aren't filled in
    const invalidFields = requiredFields.filter(
      (requiredFieldName) =>
        !fields[requiredFieldName] || fields[requiredFieldName].length < 1
    )

    const requiredMessages = getCustomMessages(
      this.state.parsedFields,
      invalidFields
    )

    // if required fields are missing, stop operation and display errors
    if (invalidFields.length) {
      const errors = invalidFields.reduce((obj, fieldName) => {
        const requiredMessage = requiredMessages[fieldName]

        return {
          ...obj,
          [fieldName]: [
            requiredMessage || intl.formatMessage(messages.requiredField),
          ],
        }
      }, {})

      displayFormErrors(operationName, {
        ...errors,
        baseErrors: [intl.formatMessage(messages.fillInRequired)],
      })

      return undefined
    }

    // clear errors and set state to loading
    this.setState({
      isLoading: true,
    })
    operationName && resetSingleForm(operationName)

    const postSubmitStateChange = {
      isLoading: false,
      ...(checkPristine && { areBtnsDisabled: true }),
    }
    const currentState = { ...this.state }

    return onSubmit({
      ...this.state.defaultValues,
      ...fields,
    })
      .then(() => {
        this.initialState = currentState
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState(postSubmitStateChange)
        }
      })
  }

  onCancel = (e) => {
    e && e.preventDefault()
    this.props.onCancel()
    this.setPristineState()
  }

  /* TO DO: [Refactor] [CW-1330] Reduce complexity to align with new linting rules */
  /* eslint-disable complexity */
  renderSingleField = (field) => {
    const { component: Component, fieldSize } = field

    if (field.loading) {
      if (field.LoadingComponent) {
        const { LoadingComponent, ...rest } = field
        const cleanedFields = Object.keys(rest).reduce((cleaned, key) => {
          if (typeof rest[key] !== 'function') {
            cleaned[key] = rest[key]
          }

          return cleaned
        }, {})

        return <LoadingComponent key={field.name} {...cleanedFields} />
      }

      return <Loading small key={field.name} />
    }

    if (!Component) {
      console.warn(
        `Invalid value ${field.Component} passed to 'Component' property`
      )

      return false
    }

    // check if the component has a condition to not render
    if (field.shouldRender !== null && field.shouldRender !== undefined) {
      if (
        (typeof field.shouldRender === 'function' &&
          !field.shouldRender(this.state.fields)) ||
        !field.shouldRender
      ) {
        return false
      }
    }

    const stateValue = this.state.fields[field.name]

    let value = stateValue

    if (value === null || value === undefined) {
      value =
        typeof field.defaultValue === 'function'
          ? field.defaultValue(this.state.fields)
          : field.defaultValue
    }

    const uiField = {
      // check for fields that are defined as a function of state
      // NOTE: make sure it's not a graphql mutation or query being passed
      ...Object.keys(field).reduce((result, key) => {
        if (['component', 'onChange', 'LoadingComponent'].includes(key)) {
          return result
        }

        if (
          typeof field[key] === 'function' &&
          !['mutation', 'query'].includes(key)
        ) {
          result[key] = field[key](this.state.fields)
        } else {
          result[key] = field[key]
        }

        return result
      }, {}),
      component: null,
      value: value || '',
      ...(field.defaultValues && {
        ...Object.keys(field.defaultValues).reduce((res, valKey) => {
          res[valKey] = this.state.fields[valKey]

          return res
        }, {}),
      }),
    }

    if (
      uiField.hasOwnProperty('value') &&
      uiField.hasOwnProperty('defaultValue')
    ) {
      delete uiField.defaultValue
    }

    // remove fieldSize and shouldRender props as it is invalid on html components
    delete uiField.fieldSize
    delete uiField.shouldRender

    const formErrors = this.props.errors || {}
    let errorsToDisplay = formErrors[field.name]

    // Fields can have an extra array of keys in which an error may be reported
    // this is because some field errors are reported differently from diff APIs
    if (field.errorKeys && (!errorsToDisplay || !formErrors.length)) {
      const erroredKey = Object.keys(formErrors).find((er) =>
        field.errorKeys.includes(er)
      )

      if (erroredKey) {
        errorsToDisplay = formErrors[erroredKey]
      }
    }

    return (
      <div
        className={cx(fieldSize, styles.formItem, field.className)}
        key={field.name}
        data-name={field.name}
      >
        <ErrorBoundary>
          <Component
            key={`comp-${field.name}`}
            errors={errorsToDisplay}
            disabled={this.props.disabled}
            onChange={this.changeInput(field.name)}
            {...uiField}
          />
        </ErrorBoundary>
      </div>
    )
  }

  render() {
    const {
      options: { submitBtnStyle = '', submitBtnText, cancelBtnText } = {},
      options,
      children,
      baseErrors = [],
      disabled,
      className,
    } = this.props

    const { isLoading, areBtnsDisabled } = this.state

    let bottomContent
    React.Children.forEach(children, (el) => {
      if (el && el.props.bottomcontent) {
        bottomContent = el
      }
    })

    return (
      <form
        data-testid={this.props.handle}
        className={className}
        onSubmit={this.onSubmit}
      >
        <div className={styles.formWrapper}>
          {this.state.parsedFields.map((object, index) => {
            if (
              typeof object.hideRow === 'function' &&
              object.hideRow(this.state.fields)
            ) {
              return undefined
            }

            return (
              <div key={index}>
                {object.border && <hr className={styles.partition} />}
                {object.title && (
                  <legend className={styles.formLegend}>{object.title}</legend>
                )}
                {object.subtitle && (
                  <span className={styles.formSubtitle}>{object.subtitle}</span>
                )}
                <div
                  className={cx(styles.formRow, this.props.rowStyles, {
                    flexRow: object.rowFields.length > 1,
                  })}
                >
                  {object.rowFields.map((field) =>
                    this.renderSingleField(field)
                  )}
                </div>
                {object.tip && (
                  <span className={styles.formTip}>{object.tip}</span>
                )}
                {object.hasSeparator && (
                  <Seperator className={styles.negativeMargin} />
                )}
              </div>
            )
          })}
        </div>
        {baseErrors.map((err) => (
          <span data-testid="formError" key={err} className={styles.formError}>
            {err}
          </span>
        ))}
        {bottomContent}
        {options && (
          <div className={styles.formButtonsWrapper}>
            {cancelBtnText && (
              <ButtonSimple
                disabled={areBtnsDisabled || disabled}
                className={styles.firstBtn}
                variant={ButtonVariant.secondary}
                onClick={this.onCancel}
              >
                {cancelBtnText}
              </ButtonSimple>
            )}
            {submitBtnText && (
              <ButtonSimple
                fullWidth
                className={cx(styles.submitBtn, submitBtnStyle)}
                disabled={areBtnsDisabled || disabled || isLoading}
                type="submit"
                isLoading={isLoading || this.props.isLoading}
                data-testid="formSubmit"
              >
                {submitBtnText}
              </ButtonSimple>
            )}
          </div>
        )}
      </form>
    )
  }
}
/* eslint-enable */

const mapStateToProps = (state = { form: {} }, { operationName, intl }) => {
  const { form } = state
  const formData = form[operationName]

  if (formData && formData.errors) {
    const { errors } = formData
    const baseErrors = errors.baseErrors || errors.errors?.base || []

    delete errors.baseErrors

    const errorsToUse = errors.errors || errors

    if (
      Object.keys(errorsToUse).filter(
        (key) => !['cause', 'error_code'].includes(key)
      ).length &&
      !(errorsToUse.cause || baseErrors.length)
    ) {
      baseErrors.push(intl.formatMessage(messages.checkCorrect))
    }

    return {
      errors: errorsToUse,
      baseErrors,
    }
  }

  return {}
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { resetSingleForm, displayFormErrors, registerActiveForm },
    dispatch
  )

Form.defaultProps = {
  onChange: () => ({}),
  onCancel: () => ({}),
  onSubmit: () => Promise.resolve(),
  rowStyles: '',
  errors: {},
  baseErrors: [],
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(Form)
)
