import React, { ElementType, ReactNode } from 'react'
import cn from 'classnames'
import { IMedkitComponent } from '..'
import { textTheme } from './theme'
import styles from './index.module.scss'

export type HeaderVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type AllowedTagReplacement =
  | HeaderVariant
  | 'span'
  | 'p'
  | 'label'
  | 'div'
  | 'dt'
  | 'dd'

export type TextVariant = HeaderVariant | 'body' | 'smallBody' | 'caption'

interface ITextProps extends React.HTMLProps<HTMLDivElement> {
  /** The content of the component */
  children?: ReactNode
  /** Extra class name(s) to be applied to the component */
  className?: string
  /** A tag name to override default tag for the component */
  tag?: AllowedTagReplacement
  /** The variant of the component */
  variant: TextVariant
}

export const variantToElementMapping: { [key in TextVariant]: ElementType } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body: 'p',
  smallBody: 'p',
  caption: 'div',
}

export const Text: IMedkitComponent<ITextProps, typeof textTheme> = ({
  children,
  className,
  tag,
  variant,
  style,
  ...props
}: ITextProps) => {
  const Component: ElementType = tag || variantToElementMapping[variant]

  return (
    <Component
      className={cn(styles.Text, styles[`Text__${variant}`], className)}
      style={style}
      {...props}
    >
      {children}
    </Component>
  )
}

Text.theme = textTheme

export default Text
