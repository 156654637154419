import { routeConfig as bookAppointmentRoutes } from '@/components/Appointments/BookAppointment/routesInstantAppointments'
import { RouteConfig } from './routeConfig'

export const ROOT_PATH = '/book-instant'

const routeConfig: RouteConfig = {
  id: 'instant-appointments',
  route: ROOT_PATH,
  getUrl: () => ROOT_PATH,
  getRedirectUrl: () => `${ROOT_PATH}/reason`,
  breadcrumb: {
    isExcluded: true,
  },
  isSkipped: () => false,
  children: bookAppointmentRoutes,
}

export default routeConfig
