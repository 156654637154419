import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Store,
  AnyAction,
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, Persistor } from 'redux-persist'
import thunk from 'redux-thunk'
import { createBrowserHistory, createMemoryHistory, History } from 'history'
import { routerMiddleware } from 'connected-react-router'

import { IS_SERVER } from '@/config'
import sagas from './sagas'
import createReducers, { getReducers, getInitialState } from './reducers'

export interface BabylonStore extends Store {
  injectReducer: (key: string, reducer: any) => any
}

interface ReduxState {
  history: History<any>
  persistor: Persistor
  store: BabylonStore
}

// @ts-ignore
const reduxState: ReduxState = {}

const initStore = (url = '/', featureFlags?: string) => {
  reduxState.history = IS_SERVER
    ? createMemoryHistory({ initialEntries: [url].filter(Boolean) })
    : createBrowserHistory()

  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

  const enhancers = composeEnhancers(
    applyMiddleware(routerMiddleware(reduxState.history), sagaMiddleware, thunk)
  )

  const reducers = createReducers(reduxState.history)
  const initialState = getInitialState(featureFlags)
  reduxState.store = createStore(reducers, initialState, enhancers)

  const asyncReducers = {}

  // Create an inject reducer function
  // implemented based on: https://redux.js.org/recipes/code-splitting#defining-an-injectreducer-function
  reduxState.store.injectReducer = (key, asyncReducer) => {
    const currentReducers = getReducers()

    if (currentReducers[key]) {
      throw new Error(
        `The following key is already taken in the store: ${key}!`
      )
    }

    // stores dynamic reducers to make sure injectReducer
    // doesn't replace previously injected reducers.
    asyncReducers[key] = asyncReducer

    reduxState.store.replaceReducer(
      combineReducers<any, AnyAction>({
        ...currentReducers,
        ...asyncReducers,
      })
    )
  }

  sagaMiddleware.run(sagas)

  reduxState.persistor = persistStore(reduxState.store)

  // expose store when run in Cypress
  // @ts-ignore
  if (window.Cypress) {
    // @ts-ignore
    window.store = reduxState.store
  }
}

export { reduxState, initStore }
