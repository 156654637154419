type Props = {
  selectedValue: string
  options: { value: string }[]
  fieldsetRef: React.RefObject<HTMLFieldSetElement>
  onChooseOptionIndex: (i: number) => void
}

interface RadioListenerBuilders {
  keyDownListener: (event: React.KeyboardEvent) => void
  focusListener: () => void
}

const getRadios = (fieldset: HTMLFieldSetElement) =>
  Array.from(fieldset.querySelectorAll('[role="radio"]')) as HTMLElement[]

function useRadioGroupFocusHandlers({
  selectedValue,
  options,
  fieldsetRef,
  onChooseOptionIndex,
}: Props) {
  const selectedOptionIndex = options.findIndex(
    (option) => option.value === selectedValue
  )
  const tabIndexableOptionIndex =
    selectedOptionIndex >= 0 ? selectedOptionIndex : 0

  const listenerBuilder = (i: number): RadioListenerBuilders | undefined => {
    const keyDownListener = (event: React.KeyboardEvent) => {
      if (fieldsetRef.current === null) {
        return
      }

      const radios = getRadios(fieldsetRef.current)

      const nextIndex = i === radios.length - 1 ? 0 : i + 1
      const prevIndex = i === 0 ? radios.length - 1 : i - 1

      switch (event.code) {
        case 'ArrowDown':
        case 'ArrowRight':
          event.preventDefault()
          radios[nextIndex].focus()

          break
        case 'ArrowUp':
        case 'ArrowLeft':
          event.preventDefault()
          radios[prevIndex].focus()

          break
        default:
          break
      }
    }
    const focusListener = () => onChooseOptionIndex(i)

    return { keyDownListener, focusListener }
  }

  return {
    listenerBuilder,
    tabIndexableOptionIndex,
    focusRadioGroup() {
      if (fieldsetRef.current === null) {
        return
      }

      const radios = getRadios(fieldsetRef.current)
      radios[tabIndexableOptionIndex].focus()
    },
  }
}

export default useRadioGroupFocusHandlers
