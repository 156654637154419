import React, { useState } from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/react-hooks'
import { useFormatMessage } from '@babylon/intl'

import LandingHeader from '../../LandingHeader'

import resetPasswordMutation from './mutations'
import PageTitle from '@/components/PageTitle/PageTitle'
import { useCustomerSupportEnabled } from '@/redux/selectors'
import ResetPasswordSubmitted from './ResetPasswordSubmitted'
import ResetPasswordForm from './ResetPasswordForm'
import ResetPasswordHelp from './ResetPasswordHelp'
import messages from './messages'
import appStyles from '@/App.module.scss'
import styles from './styles.module.scss'

interface FormProps {
  email: string
}

const ResetPasswordPage: React.FC = () => {
  const supportEnabled = useCustomerSupportEnabled()
  const translate = useFormatMessage()
  const [submitted, setSubmitted] = useState(false)
  const [resetPassword] = useMutation(resetPasswordMutation, {
    context: {
      showFeedback: {
        success: translate(messages.success),
        failure: true,
      },
    },
  })

  const onSubmit = async ({ email }: FormProps) => {
    await resetPassword({
      variables: {
        email,
      },
    })
    setSubmitted(true)
  }

  return (
    <>
      <PageTitle title={translate(messages.pageTitle)} />
      <div>
        <LandingHeader />
        <main className={cn(styles.loginFormWrapper, appStyles.view)}>
          <div className={appStyles.view__inner}>
            <section className="Content--form">
              <h1 className="PageTitle">{translate(messages.pageTitle)}</h1>
              {submitted ? (
                <ResetPasswordSubmitted onRetry={() => setSubmitted(false)} />
              ) : (
                <ResetPasswordForm onSubmit={onSubmit} />
              )}
              {supportEnabled && <ResetPasswordHelp />}
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

// exported for testing
export { ResetPasswordPage }
export default ResetPasswordPage
