import React, { ReactNode, Children } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { IMedkitComponent } from '..'

export enum GridSpacing {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

interface GridProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode
  container?: boolean
  barbell?: boolean
  item?: boolean
  spacing?: GridSpacing
  barbellProperties?: {
    sidesWidth?: 'medium' | 'large'
  }
  breakpoint?: 'tablet'
  grow?: boolean
}

export const Grid: IMedkitComponent<GridProps, {}> = ({
  children,
  container: isContainer,
  barbell: isBarbell,
  item: isItem,
  spacing,
  className,
  barbellProperties = {},
  breakpoint,
  grow,
  ...props
}) => {
  if (Children.count(children) !== 3 && isBarbell) {
    console.error('[medkit] Barbell grid expects 3 children.')
    return null
  }

  return (
    <div
      className={cn(
        {
          [styles.container]: isContainer,
          [styles.item]: isItem,
          [styles.container__barbell]: isBarbell,
          [styles[`container__barbell--${barbellProperties.sidesWidth}Sides`]]:
            barbellProperties.sidesWidth,
          [styles[`container__spacing--${spacing}`]]: spacing,
          [styles['container__breakpoint--tablet']]: breakpoint === 'tablet',
          [styles.grow]: grow,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

Grid.theme = {}

export default Grid
