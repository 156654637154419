import { useCallback, useState } from 'react'
import { getAuthToken } from '@babylon/auth0'
import { getHeaders } from '@babylon/shared-utils'
import { VERSION, WEIGHT_LOSS_INJECTION_ADMINISTERED_URL } from '@/config'
import { MedicationRecord } from '../ProgrammeCheckIn/types'

const useSubmitInjectionComplete = () => {
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const submitData = useCallback(async (medicationRecord: MedicationRecord) => {
    try {
      setLoading(true)
      const payload = {
        completed_injection: medicationRecord,
      }
      const authToken = await getAuthToken()
      const result = await fetch(WEIGHT_LOSS_INJECTION_ADMINISTERED_URL, {
        method: 'POST',
        headers: {
          ...getHeaders({
            appId: 'babylon',
            appVersion: VERSION,
            acceptLanguage: 'en-GB',
          }),
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(payload),
      })

      if (!result.ok) {
        setError(true)
        setSuccess(false)
        return
      }

      setSuccess(true)
    } catch (err) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  return { submitData, loading, success, error }
}

export default useSubmitInjectionComplete
