import React from 'react'
import classnames from 'classnames'
import { graphql } from '@apollo/react-hoc'
import { compose, defaultProps, withState, withHandlers } from 'recompose'
import { Close } from '@babylon/icons'
import { Portal } from '.'
import fetchReferralUrl from '@/queries/ReferAFriend'
import modalStyles from './Modal.module.scss'
import styles from './ReferAFriendModal.module.scss'
import withUserId from '@/wrappers/withUserId'
import Loading from '../Loading'

const enhance = compose(
  withUserId,
  graphql(fetchReferralUrl),
  defaultProps({
    situation: 'dashboard',
  }),
  withState('iframeLoaded', 'updateIframeLoaded', false),
  withHandlers({
    // eslint-disable-next-line no-unused-vars
    setIframeLoaded: ({ situation, updateIframeLoaded }) => () => {
      updateIframeLoaded(true)
    },
  })
)

const ReferAFriendModal = ({
  onClose,
  data: { referFriend = {} },
  setIframeLoaded,
  iframeLoaded,
  waitUntilReady,
}) => (
  <Portal onClose={onClose}>
    <div
      id="modalOverlay"
      className={classnames(modalStyles.modalOverlay, styles.modalCenter)}
      style={{
        visibility: waitUntilReady && !iframeLoaded ? 'hidden' : undefined,
      }}
    >
      <div className={styles.modalContainer}>
        {!iframeLoaded && (
          <div className={styles.loadingContainer}>
            <Loading inline transparent />
          </div>
        )}
        <button className={styles.closeBtn} onClick={onClose} type="button">
          <Close title="Close" className={styles.closeBtn__icon} />
        </button>
        {referFriend && referFriend.referUrl && (
          <div className={styles.iframeContainer}>
            <iframe
              title="Refer"
              frameBorder={0}
              className={styles.iframe}
              src={referFriend.referUrl}
              onLoad={setIframeLoaded}
            />
          </div>
        )}
      </div>
    </div>
  </Portal>
)

export default enhance(ReferAFriendModal)
