import React from 'react'
import { Link } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'
import styles from './styles.module.scss'

const ResetPasswordHelp: React.FC = () => {
  const t = useFormatMessage()

  return (
    <div className={styles.supportLink}>
      <span>{`${t(messages.doYouHaveProblems)} `}</span>
      <Link href="https://support.babylonhealth.com" blank>
        {t(messages.help)}
      </Link>
    </div>
  )
}

export default ResetPasswordHelp
