import { defineMessages } from 'react-intl'

export default defineMessages({
  mainMenuShow: {
    id: 'Header.mainMenuShow',
    defaultMessage: 'Open main menu',
  },
  mainMenuHide: {
    id: 'Header.mainMenuHide',
    defaultMessage: 'Close main menu',
  },
  userMenuShow: {
    id: 'Header.userMenuShow',
    defaultMessage: 'Open user menu',
  },
  userMenuHide: {
    id: 'Header.userMenuHide',
    defaultMessage: 'Close user menu',
  },
  ctaAccount: {
    id: 'Header.ctaAccount',
    defaultMessage: 'Go to account',
  },
})
