import alcoholGreen from './alcohol-green.svg'
import alcoholRed from './alcohol-red.svg'
import alcoholYellow from './alcohol-yellow.svg'
import dietGreen from './diet-green.svg'
import dietRed from './diet-red.svg'
import dietYellow from './diet-yellow.svg'
import dietSnackGreen from './diet-snacking-green.svg'
import dietSnackRed from './diet-snacking-red.svg'
import dietSnackYellow from './diet-snacking-yellow.svg'
import exerciseGreen from './exercise-green.svg'
import exerciseRed from './exercise-red.svg'
import exerciseYellow from './exercise-yellow.svg'
import smokingGreen from './smoking-green.svg'
import smokingRed from './smoking-red.svg'
import smokingYellow from './smoking-yellow.svg'
import sleepGreen from './sleep-green.svg'
import sleepRed from './sleep-red.svg'
import sleepYellow from './sleep-yellow.svg'
import wellbeingGreen from './wellbeing-green.svg'
import wellbeingRed from './wellbeing-red.svg'
import wellbeingYellow from './wellbeing-yellow.svg'

const iconMap = {
  RED: {
    alcohol: alcoholRed,
    diet: dietRed,
    'diet-snacking': dietSnackRed,
    exercise: exerciseRed,
    sleep: sleepRed,
    smoking: smokingRed,
    wellbeing: wellbeingRed,
  },
  AMBER: {
    alcohol: alcoholYellow,
    diet: dietYellow,
    'diet-snacking': dietSnackYellow,
    exercise: exerciseYellow,
    sleep: sleepYellow,
    smoking: smokingYellow,
    wellbeing: wellbeingYellow,
  },
  GREEN: {
    alcohol: alcoholGreen,
    diet: dietGreen,
    'diet-snacking': dietSnackGreen,
    exercise: exerciseGreen,
    sleep: sleepGreen,
    smoking: smokingGreen,
    wellbeing: wellbeingGreen,
  },
}

export default function getIcon(iconName: string, color: string) {
  return iconMap[color][iconName]
}
