import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'PartnerLanding.pageTitle',
    defaultMessage: 'Appointments',
  },
  book: {
    id: 'PartnerLanding.appointment.book',
    defaultMessage: 'Book',
  },
  bookDescription: {
    id: 'PartnerLanding.appointment.book.description',
    defaultMessage: 'Book an appointment with a clinician.',
  },
  manage: {
    id: 'PartnerLanding.appointment.manage',
    defaultMessage: 'Manage',
  },
  manageDescription: {
    id: 'PartnerLanding.appointment.manage.description',
    defaultMessage:
      'Check in to upcoming, view past, or cancel future appointments.',
  },
  stayOnThePage: {
    id: 'PartnerLanding.stayOnThePage',
    defaultMessage:
      'If you have an upcoming video appointment, please stay on this page to receive the video call.',
  },
  addressSave: {
    id: 'PartnerLanding.address.save',
    defaultMessage: 'Save',
  },
  missingAddress: {
    id: 'PartnerLanding.address.missing',
    defaultMessage: "We're missing an address...",
  },
})
