import React from 'react'
import { graphql } from '@apollo/react-hoc'
import { withRouter, Redirect } from 'react-router'
import {
  compose,
  withPropsOnChange,
  renderComponent,
  branch,
  shouldUpdate,
} from 'recompose'

import FeatureSwitchesQuery from '@/queries/FeatureSwitches'
import withUserId from './withUserId'
import { parseError } from '@/utils'

export default compose(
  withRouter,
  withUserId,

  graphql(FeatureSwitchesQuery, {
    options: ({ currentUserId }) => ({
      variables: {
        ...(currentUserId && { patientId: currentUserId }),
      },
    }),
    skip: ({ currentUserId }) =>
      currentUserId === undefined || currentUserId === null,
    props: ({ data }) => ({
      featureSwitches: data.featureSwitches || {},
      featureSwitchesLoading: data.loading && !data.featureSwitches,
      featureSwitchesError: data.error,
      featureSwitchesRefetch: data.refetch,
    }),
  }),
  withPropsOnChange(
    ['featureSwitchesLoading', 'featureSwitchesError'],
    ({ featureSwitchesError }) => {
      if (featureSwitchesError) {
        const graphqlErrors = featureSwitchesError.graphQLErrors
        const parsedError = parseError(graphqlErrors[0])
        const { status } = parsedError.response

        return { badAuthRedirect: status > 401 && status < 500 }
      }

      return { badAuthRedirect: false }
    }
  ),
  shouldUpdate(
    (
      { badAuthRedirect: previouslyRedirected },
      { badAuthRedirect: shouldRedirect }
    ) => !(shouldRedirect && previouslyRedirected)
  ),
  branch(
    ({ badAuthRedirect }) => badAuthRedirect,
    renderComponent(() => <Redirect to="/account/" />)
  )
)
