import { User } from './BabylonUser'
import { ViewerFragment } from './types'

export const checkRole = (viewer: ViewerFragment) => (
  ...roles: string[]
): boolean =>
  viewer.roles.some((role) => role && roles.some((name) => name === role.name))

const mapViewerToUser = (viewer: ViewerFragment): User => {
  const hasRole = checkRole(viewer)

  const partialUser = {
    ...viewer,
    id: viewer.uuid || viewer.id,
    globalAdmin: hasRole('global_admin'),
    generalAdmin: hasRole('general_admin'),
    saasGeneralAdmin: hasRole('saas_general_admin'),
    superConsultant: hasRole('super_consultant'),
    gp: hasRole('gp'),
    dietitian: hasRole('dietitian'),
    pharmacist: hasRole('pharmacist'),
    specialist: hasRole('specialist'),
    triageNurse: hasRole('triage_nurse'),
    seniorNurse: hasRole('senior_nurse'),
    nurse: hasRole('nurse'),
    therapist: hasRole('therapist'),
    prescribingPharmacist: hasRole('prescribing_pharmacist'),
    hcaClinician: hasRole('hca_clinician'),
    careCoordinator: hasRole('care_coordinator'),
    clinopsSupportAssociate: hasRole('clinops_support_associate'),
    phlebotomist: hasRole('phlebotomist'),
    complianceOfficer: hasRole('compliance_officer'),
    promotionCsvManager: hasRole('promotion_csv_manager'),
    covidChatSupport: hasRole('covid_chat_support'),
    configurationViewer: hasRole('saas_configuration_viewer'),
  }

  const admin =
    partialUser.generalAdmin ||
    partialUser.globalAdmin ||
    partialUser.saasGeneralAdmin

  const clinician =
    partialUser.careCoordinator ||
    partialUser.gp ||
    partialUser.hcaClinician ||
    partialUser.nurse ||
    partialUser.pharmacist ||
    partialUser.phlebotomist ||
    partialUser.prescribingPharmacist ||
    partialUser.seniorNurse ||
    partialUser.specialist ||
    partialUser.superConsultant ||
    partialUser.therapist ||
    partialUser.triageNurse ||
    partialUser.dietitian

  const clinicalAdministrator =
    partialUser.careCoordinator ||
    partialUser.pharmacist ||
    partialUser.prescribingPharmacist

  return {
    ...partialUser,
    admin,
    clinician,
    clinicalAdministrator,
  }
}

export default mapViewerToUser
