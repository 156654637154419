import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'exitView.title',
    defaultMessage: 'You don’t qualify for this programme at this time.',
  },
  body: {
    id: 'exitView.body',
    defaultMessage: 'Please contact our team here.',
  },
  needAssistance: {
    id: 'exitView.needAssistance',
    defaultMessage: 'Need assistance?',
  },
})
