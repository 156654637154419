import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { TrackingActionType } from '@babylon/tracking/react'
import { LinkAnchor } from '@babylon/medkit'
import { register as registerAction } from '@/components/Authentication/actions'
import { useBloodTestSetupNextStepsInfo } from '@/redux/selectors'
import MissingFieldsModal from '@/components/Modal/MissingFieldsModal'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import messages from './messages'
import Button from '@/programmes/components/Button'
import { procedures } from '../routes'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import { useTrackingPageRedirect } from '@/programmes/tracking'
import styles from '../index.module.scss'
import Card from '@/programmes/components/Card'
import useTracking from '@/tracking/useTracking'
import Checkbox from '@/programmes/components/Form/components/Checkbox'
import onboardingSteps from './steps'

const cx = classNames.bind(styles)

const patientQuery = gql`
  query Patient {
    patient {
      id
      date_of_birth
      gender
      phone_number
      region {
        id
        default_phone_country_code
      }
    }
  }
`

const SetupHome = () => {
  const t = useFormatMessage()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const dispatch = useDispatch()
  const announcement = useBloodTestSetupNextStepsInfo()
  const trackingPageRedirect = useTrackingPageRedirect()
  const { trackEvent } = useTracking()
  const {
    data: patientData,
    loading: patientQueryLoading,
  } = useQuery(patientQuery, { skip: !isAuthenticated })
  const [updatePatient] = useMutation(UpdatePatientMutation)
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const proctorInviteUrl = data?.createProctorSessionInvite?.url

  const [checkboxDrink, setCheckboxDrink] = useState<boolean>(false)
  const [checkboxFasting, setCheckboxFasting] = useState<boolean>(false)

  if (proctorInviteUrl) {
    trackingPageRedirect()
    window.location.href = proctorInviteUrl
  }

  const handleClick = () => {
    if (isAuthenticated) {
      return getProctorSessionInvite({
        variables: {
          input: {
            procedure: {
              identifier: { value: procedures.WELLNESS_S2P_SETUP },
            },
          },
        },
      })
    }

    return dispatch(registerAction())
  }

  const handleDrinkCheckboxChange = () => {
    setCheckboxDrink(!checkboxDrink)
  }

  const handleFastingCheckboxChange = () => {
    setCheckboxFasting(!checkboxFasting)
  }

  return (
    <Template
      title={t(messages.setupHomePageTitle)}
      steps={onboardingSteps}
      activeStep={0}
      loading={patientQueryLoading}
      hasAppLayout={isAuthenticated}
    >
      {patientData && (
        <MissingFieldsModal
          user={patientData.patient}
          checkFields={['date_of_birth', 'gender', 'phone_number']}
          updatePatient={updatePatient}
          useSexAtBirthTerminology
          avoidClose
        />
      )}
      <>
        <h2 className={cx('heading')}>{t(messages.setupHomePageHeading)}</h2>
        <p className={cx('description')}>
          {t(messages.setupHomePageDescription)}
        </p>
        <div className={cx('check-in-startup-cards')}>
          <Card
            title={t(messages.timeAndPlaceTitle)}
            description={t(messages.timeAndPlaceDescription)}
          />
          <Card
            title={t(messages.fastingTitle)}
            description={t(messages.fastingDescription)}
          />
          <Card
            title={t(messages.hydrationTitle)}
            description={t(messages.hydrationDescription)}
          />
          <Card
            title={t(messages.clothingTitle)}
            description={t(messages.clothingDescription)}
          />
          <Card
            title={t(messages.deviceTitle)}
            description={t(messages.deviceDescription)}
          />
          <Card
            title={t(messages.returningSampleTitle)}
            description={t(messages.returningSampleDescription)}
          />
        </div>
      </>
      <FormattedMessage
        {...messages.setupHelpDescription}
        values={{
          supportTeam: (
            <LinkAnchor
              onClick={() =>
                trackEvent({
                  screenTitle: t(messages.setupHomePageTitle),
                  eventAction: 'clicked support link',
                  actionType: TrackingActionType.click,
                })
              }
              href="https://support.babylonhealth.com/cspbabylonsupport"
            >
              support team
            </LinkAnchor>
          ),
        }}
      />
      {announcement && <Announcement content={announcement} />}
      <Spacer top={1} bottom={1} />
      <Checkbox
        id="fasting-checkbox"
        label={t(messages.fastingCheckboxLabel)}
        checked={checkboxFasting}
        onChange={handleFastingCheckboxChange}
      />
      <Checkbox
        id="hydration-checkbox"
        label={t(messages.hydrationCheckboxLabel)}
        checked={checkboxDrink}
        onChange={handleDrinkCheckboxChange}
      />
      <Spacer top={5} bottom={5}>
        <Button
          onClick={handleClick}
          loading={loading || !!proctorInviteUrl}
          disabled={!checkboxDrink || !checkboxFasting}
        >
          {t(messages.setupHomePageCta)}
        </Button>
      </Spacer>
    </Template>
  )
}

export default SetupHome
