import React from 'react'

import { cn } from '@/components/utils'

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('tw-animate-pulse tw-rounded-md tw-bg-muted', className)}
      {...props}
    />
  )
}

export { Skeleton }
