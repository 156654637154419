import React, { useState } from 'react'
import { Weight } from '@/programmes/components/Form/types'
import { ProgrammeCheckInStep } from '../../types'
import WeightEntryLayout from './WeightEntryLayout'
import WeightChangeFeedbackLayout from './WeightChangeFeedbackLayout'

type Props = {
  config: ProgrammeCheckInStep
  onStepComplete: () => void
}

const WeightCheckInLayout: React.FC<Props> = ({ config, onStepComplete }) => {
  const [weight, setWeight] = useState<Weight>()

  const handleWeightSaved = (savedWeight: Weight) => {
    setWeight(savedWeight)
  }

  return (
    <>
      {weight ? (
        <WeightChangeFeedbackLayout
          weight={weight}
          onComplete={onStepComplete}
        />
      ) : (
        <WeightEntryLayout onWeightSaved={handleWeightSaved} config={config} />
      )}
    </>
  )
}

export default WeightCheckInLayout
