import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'RegisterPage.pageTitle',
    defaultMessage: 'Create account',
  },
  description: {
    id: 'RegisterPage.description',
    defaultMessage:
      'We need some more details before you can access your account',
  },
  firstName: {
    id: 'RegisterPage.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'RegisterPage.lastName',
    defaultMessage: 'Last Name',
  },
  password: {
    id: 'RegisterPage.password',
    defaultMessage: 'Password',
  },
  passwordTip: {
    id: 'RegisterPage.passwordTip',
    defaultMessage:
      'Minimum 8 characters with a number, uppercase & lowercase letter',
  },
  passwordErrorLength: {
    id: 'RegisterPage.passwordErrorLength',
    defaultMessage: 'Password must be at least {length} letters',
  },
  passwordErrorFormat: {
    id: 'RegisterPage.passwordErrorFormat',
    defaultMessage:
      'Password must contain a number, an uppercase letter and a lowercase letter',
  },
  submitText: {
    id: 'RegisterPage.submitText',
    defaultMessage: 'Save details',
  },
  submitLoading: {
    id: 'RegisterPage.loading',
    defaultMessage: 'Loading',
  },
  termsAndConditionsRequired: {
    id: 'RegisterPage.termsAndConditionsRequired',
    defaultMessage: 'You must read and accept the terms and conditions',
  },
  passwordValidation: {
    id: 'RegisterPage.passwordValidation',
    defaultMessage: 'Please enter your password',
  },
  firstNameValidation: {
    id: 'RegisterPage.firstNameValidation',
    defaultMessage: 'Please enter your first name',
  },
  lastNameValidation: {
    id: 'RegisterPage.lastNameValidation',
    defaultMessage: 'Please enter your last name',
  },
  // text for BE driven fields
  promocodeTitle: {
    id: 'RegisterPage.promocodeTitle',
    defaultMessage: 'Do you have an employee, insurance or promo membership?',
  },
  promocodeDescription: {
    id: 'RegisterPage.promocodeDescription',
    defaultMessage:
      'Your employer or health insurer might have given you a code for you to add here. You can also do this later in Account > Membership',
  },
  promocodeLabel: {
    id: 'RegisterPage.promocode',
    defaultMessage: 'Promotion or membership code',
  },
  promocodeValidation: {
    id: 'RegisterPage.promocodeValidation',
    defaultMessage: 'text about validation',
  },
  postcodeLabel: {
    id: 'RegisterPage.postcode',
    defaultMessage: 'Postcode',
  },
  postcodeValidation: {
    id: 'RegisterPage.postcodeValidation',
    defaultMessage: 'Postcode is a required field',
  },
  // unused
  phoneCountryCode: {
    id: 'RegisterPage.phoneCountryCode',
    defaultMessage: 'Country code',
  },
  phoneNumber: {
    id: 'RegisterPage.phoneNumber',
    defaultMessage: 'Phone number',
  },
  phoneNumberValidation: {
    id: 'RegisterPage.phoneNumberValidation',
    defaultMessage: 'Please provide a valid phone number',
  },
  email: {
    id: 'RegisterPage.email',
    defaultMessage: 'Email',
  },
  emailValidation: {
    id: 'RegisterPage.emailValidation',
    defaultMessage: 'Please provide a valid email address',
  },
})
