import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Text } from '@babylon/medkit'
import styles from './QuestionBody.module.scss'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface Props {
  body: string | Array<string> | React.ReactNode
  header?: string
  subText?: string
  isFooter?: boolean
  id?: string
}

const intlOptions = {
  strong: (chunks: string[]) => <strong>{chunks}</strong>,
  u: (chunks: string[]) => <u>{chunks}</u>,
  a: (chunks: string[]) => {
    const [href, text] = chunks[0].split('|')

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {text || href}
      </a>
    )
  },
}

const QuestionBody: React.FC<Props> = ({
  body,
  header,
  subText,
  isFooter,
  id,
}) => {
  const t = useFormatMessage()
  const formatMessage = (message: string) =>
    t({ id: message, defaultMessage: message }, intlOptions)

  return (
    <div className={isFooter ? styles.footerSpacer : styles.spacer}>
      <div className={styles.container}>
        {header && (
          <span
            className={styles.text}
            id={id ? `${STEP_ELEMENT_ID_PREFIX}-header-${id}` : undefined}
          >
            {formatMessage(header)}
          </span>
        )}

        {Array.isArray(body) && (
          <ul
            className={styles.list}
            id={id ? `${STEP_ELEMENT_ID_PREFIX}-body-${id}` : undefined}
          >
            {body.map((text) => (
              <li key={text}>{formatMessage(text)}</li>
            ))}
          </ul>
        )}

        {React.isValidElement(body) && body}

        {typeof body === 'string' && (
          <Text
            variant="body"
            className={styles.text}
            id={id ? `${STEP_ELEMENT_ID_PREFIX}-body-${id}` : undefined}
          >
            {formatMessage(body)}
          </Text>
        )}

        {subText && (
          <Text variant="body" className={styles.subText}>
            {formatMessage(subText)}
          </Text>
        )}
      </div>
    </div>
  )
}

export default QuestionBody
