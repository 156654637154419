import { Interstitial, Question } from '../../components/Form/types'

const wellnessCommonOnboardingSteps: Array<Question | Interstitial> = [
  {
    id: '5ed5db09-9c6e-42c3-a286-ff2abc51f299',
    type: 'question',
    title: 'What was your sex at birth?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Male',
        value: 'Male',
        dataPoints: [
          {
            type: 'predefined',
            name: 'sex',
            value: 'male',
          },
        ],
      },
      {
        label: 'Female',
        value: 'Female',
        dataPoints: [
          {
            type: 'predefined',
            name: 'sex',
            value: 'female',
          },
        ],
      },
    ],
    next: '8c794f56-e8dc-46fe-8984-491b8ac02bcb',
  },
  {
    id: '8c794f56-e8dc-46fe-8984-491b8ac02bcb',
    type: 'question',
    required: true,
    title: 'Do you have any of these medical conditions?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Depression',
        value: 'Depression',
      },
      {
        label: 'Anxiety',
        value: 'Anxiety',
      },
      {
        label:
          'Other mental health condition, for example bipolar or schizophrenia',
        value:
          'Other mental health condition, for example bipolar or schizophrenia',
      },
      {
        label: 'ADHD (attention deficit hyperactivity disorder)',
        value: 'ADHD (attention deficit hyperactivity disorder)',
      },
      {
        label: 'Stroke',
        value: 'Stroke',
      },
      {
        label: 'Dementia',
        value: 'Dementia',
      },
      {
        label: 'Epilepsy',
        value: 'Epilepsy',
      },
      {
        label: 'Currently have cancer',
        value: 'Currently have cancer',
      },
      {
        label: 'Previously had cancer (successfully treated)',
        value: 'Previously had cancer (successfully treated)',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: '2def86d3-63f4-4f5a-9e47-0e740a3d4f93',
  },
  {
    id: '2def86d3-63f4-4f5a-9e47-0e740a3d4f93',
    type: 'question',
    required: true,
    title: 'And how about any of these conditions?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Coronary heart disease',
        value: 'Coronary heart disease',
      },
      {
        label: 'High blood pressure',
        value: 'High blood pressure',
      },
      {
        label: 'High cholesterol',
        value: 'High cholesterol',
      },
      {
        label: 'Heart failure',
        value: 'Heart failure',
      },
      {
        label: 'Atrial fibrillation (irregular heart beat)',
        value: 'Atrial fibrillation (irregular heart beat)',
      },
      {
        label: 'Asthma',
        value: 'Asthma',
      },
      {
        label: 'COPD (chronic obstructive pulmonary disease)',
        value: 'COPD (chronic obstructive pulmonary disease)',
      },
      {
        label: 'Type 1 diabetes (usually diagnosed as a child)',
        value: 'Type 1 diabetes (usually diagnosed as a child)',
      },
      {
        label: 'Type 2 diabetes (usually diagnosed as a child)',
        value: 'Type 2 diabetes (usually diagnosed as a child)',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: '8990ed19-a79f-4901-ab49-12d4aeae4b9e',
  },
  {
    id: '8990ed19-a79f-4901-ab49-12d4aeae4b9e',
    type: 'question',
    required: true,
    title: 'And how about any of these conditions?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Ulcer or stomach disease',
        value: 'Ulcer or stomach disease',
      },
      {
        label: 'Liver disease',
        value: 'Liver disease',
      },
      {
        label: 'Chronic kidney disease',
        value: 'Chronic kidney disease',
      },
      {
        label: 'Anaemia or other blood disease (like sickle cell disease)',
        value: 'Anaemia or other blood disease (like sickle cell disease)',
      },
      {
        label: 'Back Pain',
        value: 'Back Pain',
      },
      {
        label: 'Osteoarthritis',
        value: 'Osteoarthritis',
      },
      {
        label: 'Systemic Lupus Erythematosus (SLE)',
        value: 'Systemic Lupus Erythematosus (SLE)',
      },
      {
        label: 'Rheumatoid arthritis',
        value: 'Rheumatoid arthritis',
      },
      {
        label: 'Chronic pain',
        value: 'Chronic pain',
      },
      {
        label: 'Other condition not mentioned here',
        value: 'Other condition not mentioned here',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    next: '372b8c59-2908-40a2-b6e2-91a6b33a2d6d',
  },
  {
    id: '372b8c59-2908-40a2-b6e2-91a6b33a2d6d',
    type: 'question',
    title:
      'Do you have any first degree relatives who have been diagnosed with Angina or a Heart Attack under the age of 60?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    next: '176dbe26-0f4d-4165-87c9-71fc0bf62d3e',
  },
  {
    id: '176dbe26-0f4d-4165-87c9-71fc0bf62d3e',
    type: 'question',
    title: 'Do you have a family history of Kidney disease?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    next: '303e1645-d9be-4123-8bf0-74c1c31f186e',
  },
  {
    id: '303e1645-d9be-4123-8bf0-74c1c31f186e',
    type: 'question',
    title:
      'Do you take any regular medications? Do not count birth control pills, or supplements such as vitamins',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    next: '434fc077-0a4b-47ba-b49c-f2b9ddd7d87b',
  },
  {
    id: '434fc077-0a4b-47ba-b49c-f2b9ddd7d87b',
    type: 'question',
    required: true,
    title:
      'In the past 12 months, have you been admitted to hospital or the accident and emergency department (A&E)?',
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Hospital',
        value: 'Hospital',
        followUpQuestion: {
          id: '16223b4e-62ff-4c1f-97dc-e7aef64511b2',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Accident and Emergency Department (A&E)',
        value: 'Accident and Emergency Department (A&E)',
        followUpQuestion: {
          id: '741fc345-2a7e-45bc-a661-20c05ee11944',
          type: 'question',
          required: true,
          title: 'Please tell us more',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRule: [
      {
        label: 'None of the above',
        nextAction: '65c7fbe3-fa3c-4150-ab94-010e0e3bc6ea',
      },
    ],
    next: '59aad03a-e291-4b66-903c-2786de70eba6',
  },
  {
    id: '59aad03a-e291-4b66-903c-2786de70eba6',
    type: 'question',
    title:
      'Over the last 2 weeks, how often have you been bothered by any of the following problems? Little interest or pleasure in doing things?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Not at all', value: 'Not at all' },
      { label: 'Several days', value: 'Several days' },
      { label: 'More than half the days', value: 'More than half the days' },
      { label: 'Nearly every day', value: 'Nearly every day' },
    ],
    next: '8efd9753-a5f0-4844-8510-2451aefb81ee',
  },
  {
    id: '8efd9753-a5f0-4844-8510-2451aefb81ee',
    type: 'question',
    title: 'Feeling down, depressed or hopeless?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Not at all', value: 'Not at all' },
      { label: 'Several days', value: 'Several days' },
      { label: 'More than half the days', value: 'More than half the days' },
      { label: 'Nearly every day', value: 'Nearly every day' },
    ],
    next: '42f756ab-8a8f-45c4-85e6-86ae39acf4d3',
  },
  {
    id: '42f756ab-8a8f-45c4-85e6-86ae39acf4d3',
    type: 'question',
    title: 'Feeling nervous, anxious or on edge?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Not at all', value: 'Not at all' },
      { label: 'Several days', value: 'Several days' },
      { label: 'More than half the days', value: 'More than half the days' },
      { label: 'Nearly every day', value: 'Nearly every day' },
    ],
    next: '4b0ba81b-e414-465e-adbe-5386d9417c57',
  },
  {
    id: '4b0ba81b-e414-465e-adbe-5386d9417c57',
    type: 'question',
    title: 'Not being able to stop or control worrying?',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Not at all', value: 'Not at all' },
      { label: 'Several days', value: 'Several days' },
      { label: 'More than half the days', value: 'More than half the days' },
      { label: 'Nearly every day', value: 'Nearly every day' },
    ],
    next: '65c7fbe3-fa3c-4150-ab94-010e0e3bc6ea',
  },
  {
    id: '65c7fbe3-fa3c-4150-ab94-010e0e3bc6ea',
    type: 'question',
    required: true,
    title: 'Roughly how tall are you?',
    body: "It's fine to guess if you don't know your exact height",
    inputType: {
      component: 'height',
    },
    dataPoints: [
      {
        type: 'input',
        name: 'height',
      },
    ],
    next: '59d1d24b-ffe5-4b41-adf6-63711d290f93',
  },
  {
    id: '59d1d24b-ffe5-4b41-adf6-63711d290f93',
    type: 'question',
    required: true,
    title: 'And roughly, how much do you weigh?',
    body: "It's fine to guess if you don't know your exact weight",
    inputType: {
      component: 'weight',
    },
    dataPoints: [
      {
        type: 'input',
        name: 'weight',
      },
    ],
    next: '9d90cf83-6bb2-41d7-8f45-4d125d389874',
  },
  {
    id: '9d90cf83-6bb2-41d7-8f45-4d125d389874',
    type: 'question',
    title: 'Have you ever smoked cigarettes or tobacco?',
    body: 'Do not include e-cigarettes.',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'I currently smoke',
        value: 'I currently smoke',
        dataPoints: [
          {
            type: 'predefined',
            name: 'smoking_status',
            value: 'smoker',
          },
        ],
      },
      {
        label: 'I smoke socially',
        value: 'I smoke socially',
        dataPoints: [
          {
            type: 'predefined',
            name: 'smoking_status',
            value: 'social_smoker',
          },
        ],
      },
      {
        label: 'I used to smoke',
        value: 'I used to smoke',
        dataPoints: [
          {
            type: 'predefined',
            name: 'smoking_status',
            value: 'stopped_smoking',
          },
        ],
      },
      {
        label: 'I have never smoked',
        value: 'I have never smoked',
        dataPoints: [
          {
            type: 'predefined',
            name: 'smoking_status',
            value: 'never_smoked',
          },
        ],
      },
    ],
    next: '10208bf5-09d2-41f8-8fb9-74740fe95a76',
  },
  {
    id: '10208bf5-09d2-41f8-8fb9-74740fe95a76',
    type: 'question',
    title: 'How often do you have a drink containing alcohol?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Never', value: 'Never' },
      { label: 'Monthly or less', value: 'Monthly or less' },
      { label: '2-4 times a month', value: '2-4 times a month' },
      { label: '2-3 times a week', value: '2-3 times a week' },
      { label: '4 or more times a week', value: '4 or more times a week' },
    ],
    optionsRule: {
      label: 'Never',
      nextAction: '575001c2-fc55-4d88-8bc8-9b73de92a64c',
    },
    next: '68aa81b5-df1c-4be8-9975-d94115a3d68b',
  },
  {
    id: '68aa81b5-df1c-4be8-9975-d94115a3d68b',
    type: 'question',
    title:
      'How many units of alcohol do you drink on a typical day when you are drinking? What counts as one unit of alcohol? These are some examples of one unit of alcohol:',
    body: [
      'Half pint of "regular" beer, lager or cider',
      'Half a small glass of wine',
      '1 single measure of spirits',
      '1 small glass of sherry',
      '1 single measure of aperitifs',
    ],
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: '1 or 2', value: '1 or 2' },
      { label: '3 or 4', value: '3 or 4' },
      { label: '5 or 6', value: '5 or 6' },
      { label: '7 to 9', value: '7 to 9' },
    ],
    next: '9834e8bb-d384-4bd0-9f97-937b7a1c4635',
  },
  {
    id: '9834e8bb-d384-4bd0-9f97-937b7a1c4635',
    type: 'question',
    title:
      'How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?',
    body: [
      'Half pint of "regular" beer, lager or cider',
      'Half a small glass of wine',
      '1 single measure of spirits',
      '1 small glass of sherry',
      '1 single measure of aperitifs',
    ],
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      { label: 'Never', value: 'Never' },
      { label: 'Less than monthly', value: 'Less than monthly' },
      { label: 'Monthly', value: 'Monthly' },
      { label: 'Weekly', value: 'Weekly' },
      { label: 'Daily or almost daily', value: 'Daily or almost daily' },
    ],
    next: '575001c2-fc55-4d88-8bc8-9b73de92a64c',
  },
  {
    id: '575001c2-fc55-4d88-8bc8-9b73de92a64c',
    type: 'question',
    title:
      'In a typical week, how much time do you spend doing moderate exercise?',
    body:
      'Moderate exercise includes things like walking, casual cycling, yoga and cleaning.',
    required: true,
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: "I don't do any moderate exercise",
        value: "I don't do any moderate exercise",
      },
      { label: 'Less than 50 minutes', value: 'Less than 50 minutes' },
      {
        label: '50 minutes to 1 hour 40 minutes',
        value: '50 minutes to 1 hour 40 minutes',
      },
      {
        label: '1 hour 40 minutes to 2 hours 30 minutes',
        value: '1 hour 40 minutes to 2 hours 30 minutes',
      },
      {
        label: 'More than 2 hours 30 minutes',
        value: 'More than 2 hours 30 minutes',
      },
      {
        label: "I can't exercise for medical reasons",
        value: "I can't exercise for medical reasons",
      },
    ],
    optionsRule: {
      label: "I can't exercise for medical reasons",
      nextAction: 'complete-action',
    },
    next: '68f9ca52-317d-4376-923f-54e551a15540',
  },
  {
    id: '68f9ca52-317d-4376-923f-54e551a15540',
    type: 'question',
    title:
      'In a typical week, how much time do you spend doing intense exercise?',
    body:
      'Intense exercise includes things like running, mountain biking, boxing or lifting weights.',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: "I don't do any intense exercise",
        value: "I don't do any intense exercise",
      },
      { label: 'Less than 25 minutes', value: 'Less than 25 minutes' },
      { label: '25 minutes to 50 minutes', value: '25 minutes to 50 minutes' },
      {
        label: '50 minutes to 1 hour 15 minutes',
        value: '50 minutes to 1 hour 15 minutes',
      },
      {
        label: 'More than 1 hour 15 minutes',
        value: 'More than 1 hour 15 minutes',
      },
      {
        label: "I can't exercise for medical reasons",
        value: "I can't exercise for medical reasons",
      },
    ],
    next: 'complete-action',
  },
]

export const wellnessOnboardingStepsUk: Array<Question | Interstitial> = [
  {
    id: '6ee471bb-7a65-4180-a9b8-bd7fda202ed4',
    type: 'interstitial',
    title: 'Do not continue with this questionnaire if you:',
    body: [
      '• Need urgent medical advice, <a href="/consultations/new/" target="_blank">book an appointment</a> instead',
      '• Have a medical emergency, call <a href="tel://999">999</a> straight away',
    ],
    next: '5ed5db09-9c6e-42c3-a286-ff2abc51f299',
  },
  ...wellnessCommonOnboardingSteps,
]
export const wellnessOnboardingStepsUS: Array<Question | Interstitial> = [
  {
    id: '6ee471bb-7a65-4180-a9b8-bd7fda202ed4',
    type: 'interstitial',
    title: 'Do not continue with this questionnaire if you:',
    body: [
      '• Have a medical emergency, call <a href="tel://911">911</a> straight away',
    ],
    next: '5ed5db09-9c6e-42c3-a286-ff2abc51f299',
  },
  ...wellnessCommonOnboardingSteps,
]
