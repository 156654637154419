import React from 'react'
import TextInput from '../TextInput'
import CheckboxGroup from '../CheckboxGroup'
import HeightAndWeight from '../HeightAndWeight'
import TextArea from '../TextArea'
import RadioGroup from '../RadioGroup'
import Checkbox from '../Checkbox'
import PulseInput from '../PulseInput'
import HeightMeasurement from '../HeightAndWeight/HeightMeasurement'
import WeightMeasurement from '../HeightAndWeight/WeightMeasurement'
import {
  Answer,
  Question,
  Measurements,
  CheckBoxGroupValue,
  RadioGroupValue,
  Height,
  Weight,
} from '../../types'
import RenderConfirmation from '../RenderConfirmation/RenderConfirmation'

interface Props {
  question: Question
  answer?: Answer
  handleAnswer: (id: string, answer: Answer) => void
  error?: boolean
}
const RenderQuestion: React.FC<Props> = ({
  question,
  answer,
  handleAnswer,
  error = false,
}: Props) => {
  switch (question.inputType?.component) {
    case 'textInput':
      return (
        <TextInput
          label={question.title}
          id={question.id}
          body={question.body}
          error={error}
          value={(answer as string) || ''}
          onChange={(event) => handleAnswer(question.id, event.target.value)}
        />
      )
    case 'numberInput':
      return (
        <TextInput
          label={question.title}
          id={question.id}
          body={question.body}
          error={error}
          type="number"
          value={(answer as string) || ''}
          onChange={(event) => handleAnswer(question.id, event.target.value)}
        />
      )
    case 'radioGroup': {
      if (question.options) {
        return (
          <RadioGroup
            id={question.id}
            label={question.title}
            body={question.body}
            footer={question.footer}
            error={error}
            options={question.options}
            selectedOption={answer as RadioGroupValue}
            onChange={(selectedOption) =>
              handleAnswer(question.id, selectedOption)
            }
          />
        )
      }

      return null
    }
    case 'checkBox':
      return (
        <Checkbox
          id={question.id}
          label={question.title}
          error={error}
          checked={answer as boolean}
          onChange={(event) => handleAnswer(question.id, event.target.checked)}
        />
      )
    case 'checkboxGroup': {
      if (question.options) {
        return (
          <CheckboxGroup
            id={question.id}
            label={question.title}
            error={error}
            body={question.body}
            options={question.options}
            selectedOptions={answer as Array<CheckBoxGroupValue>}
            onChange={(selectedOption) =>
              handleAnswer(question.id, selectedOption)
            }
          />
        )
      }

      return null
    }
    case 'heightAndWeight': {
      return (
        <HeightAndWeight
          error={error}
          value={answer as Measurements}
          onChange={(height, weight) => {
            handleAnswer(question.id, { height, weight })
          }}
        />
      )
    }
    case 'height': {
      return (
        <HeightMeasurement
          title={question.title}
          body={question.body}
          error={error}
          value={answer as Height}
          handleChange={(height) => {
            handleAnswer(question.id, height)
          }}
        />
      )
    }
    case 'weight': {
      return (
        <WeightMeasurement
          title={question.title}
          body={question.body}
          error={error}
          value={answer as Weight}
          handleChange={(weight) => {
            handleAnswer(question.id, weight)
          }}
        />
      )
    }
    case 'pulseInput': {
      return (
        <PulseInput
          label={question.title}
          error={error}
          required={question.required}
          value={answer as string}
          onChange={(pulse) => {
            handleAnswer(question.id, pulse)
          }}
        />
      )
    }
    case 'textArea':
      return (
        <TextArea
          error={error}
          body={question.body}
          label={question.title}
          value={(answer as string) || ''}
          onChange={(event) => handleAnswer(question.id, event.target.value)}
        />
      )
    case 'confirmation':
      return (
        <RenderConfirmation
          body={question.body}
          title={question.title}
          id={question.id}
          handleAnswer={handleAnswer}
        />
      )

    default:
      return null
  }
}

export default RenderQuestion
