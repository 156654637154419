import { Product } from '@/programmes/components/Products/models'
import {
  ADDON_PRODUCT_GROUP,
  WEIGHT_LOSS_PRODUCT_GROUP,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/const'

export const splitProducts = (products: Product[]) => {
  const wlProducts = products.filter(
    (product) => product.group === WEIGHT_LOSS_PRODUCT_GROUP
  )
  const addons = products.filter(
    (product) => product.group === ADDON_PRODUCT_GROUP
  )

  const sortedAddons = [...addons].sort((a, b) => {
    if (a.order === null) return 1
    if (b.order === null) return -1
    return a.order - b.order
  })

  return { products: wlProducts, addons: sortedAddons }
}
