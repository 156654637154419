import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Text,
  LinkAnchor,
  LinkButton,
  ButtonSimple,
  ButtonVariant,
} from '@babylon/medkit'
import messages from './messages'
import styles from './WelcomeBanner.module.scss'
import {
  useBookAppointmentEnabled,
  useWelcomePageWeightManagementButtonEnabled,
} from '@/redux/selectors'
import Avatar from '@/components/Avatar'
import { getGreeting, getMembershipName, User } from './utils'
import { useBookInMinutes } from './useBookInMinutes'
import {
  useGoToAccountOverview,
  useGoToBookAppointment,
  useGoToUpgradePage,
  useGoToWeightManagement,
} from './useGoTo'

const WelcomeBannerHeader = ({ user }: { user?: User }) => {
  const translate = useFormatMessage()
  const goToAccountOverview = useGoToAccountOverview()

  return (
    <header className={styles.header}>
      <div>
        <Text variant="h3">
          {translate(getGreeting(messages))}
          {user ? ',' : ''}
        </Text>
        <Text variant="h2">{user?.first_name}</Text>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <LinkButton className={styles.avatar} onClick={goToAccountOverview}>
        <Avatar
          src={user?.avatar_full_url}
          desc={user?.first_name}
          size="l"
          useInitials={
            user && {
              firstName: user.first_name,
              lastName: user.last_name,
            }
          }
          onClick={goToAccountOverview}
        />
      </LinkButton>
    </header>
  )
}

const WelcomeBannerBody = ({
  user,
  waitingTime,
  showBookInMinutes,
}: {
  user: User
  waitingTime: number | undefined
  showBookInMinutes: boolean
}) => {
  const translate = useFormatMessage()
  const isBookAppointmentsEnabled = useBookAppointmentEnabled()
  const isWeightManagementEnabled = useWelcomePageWeightManagementButtonEnabled()
  const goToBookAppointment = useGoToBookAppointment()
  const goToWeightManagement = useGoToWeightManagement()
  const goToUpgradePage = useGoToUpgradePage()
  const membership = getMembershipName(user)

  return (
    <div className={styles.body}>
      {showBookInMinutes && waitingTime && (
        <Text className={styles.bookNow} variant="body">
          {translate(messages.bookInMinutes, {
            type: waitingTime < 60 ? 'minutes' : 'hours',
            time: waitingTime < 60 ? waitingTime : Math.floor(waitingTime / 60),
          })}{' '}
          <LinkAnchor tabIndex={0} onClick={goToBookAppointment}>
            {translate(messages.bookNow)}
          </LinkAnchor>
        </Text>
      )}
      <div className={styles.ctas}>
        {isBookAppointmentsEnabled && (
          <ButtonSimple
            variant={ButtonVariant.secondary}
            onClick={goToBookAppointment}
          >
            {translate(messages.bookAppointment)}
          </ButtonSimple>
        )}
        {isWeightManagementEnabled && (
          <ButtonSimple
            variant={ButtonVariant.secondary}
            onClick={goToWeightManagement}
          >
            {translate(messages.weightManagementJourney)}
          </ButtonSimple>
        )}
      </div>
      {membership && (
        <Text className={styles.membership} variant="body">
          {translate(messages.currentMembership)}{' '}
          <LinkAnchor tabIndex={0} onClick={goToUpgradePage}>
            {membership}
          </LinkAnchor>
        </Text>
      )}
    </div>
  )
}

const WelcomeBannerBodyLoading = () => (
  <div className={styles.bodyLoading}>
    <div className={styles.firstRow}>
      <div className={styles.item} />
      <div className={styles.item} />
    </div>
    <div className={styles.item} />
  </div>
)

const WelcomeBanner = ({ user }: { user: User }) => {
  const { showBookInMinutes, waitingTime, loading } = useBookInMinutes(user)

  return (
    <section className={styles.section}>
      <WelcomeBannerHeader user={user} />
      {!user || loading ? (
        <WelcomeBannerBodyLoading />
      ) : (
        <WelcomeBannerBody
          user={user}
          waitingTime={waitingTime}
          showBookInMinutes={showBookInMinutes}
        />
      )}
    </section>
  )
}

export default WelcomeBanner
