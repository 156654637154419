import { defineMessages } from 'react-intl'

export default defineMessages({
  negativeFeedback: {
    id: 'Alert.negative',
    defaultMessage: 'Oops, something went wrong.',
  },
  positiveFeedback: {
    id: 'Alert.positive',
    defaultMessage: 'Operation Successful!',
  },
  unauthorized: {
    id: 'Alert.unauthorized',
    defaultMessage: 'Your session has expired. Please log in again.',
  },
})
