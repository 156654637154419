import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useSelector } from 'react-redux'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import styles from '../index.module.scss'
import messages from './messages'
import steps from './steps'

const cx = classNames.bind(styles)

const WellnessConfirmationPage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.setupConfirmationPageTitle)}
      hasAppLayout={isAuthenticated}
      steps={steps}
      activeStep={3}
    >
      <h2>{t(messages.setupConfirmationPageHeading)}</h2>
      <p className={cx('description')}>
        {t(messages.setupConfirmationPageDescription)}
      </p>
      <p>
        <b>{t(messages.setupConfirmationPageDescriptionFooter)}</b>
      </p>
      <Button onClick={() => history.push('/')}>
        {t(messages.setupConfirmationPageCta)}
      </Button>
    </Template>
  )
}

export default WellnessConfirmationPage
