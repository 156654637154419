import { combineReducers } from 'redux'

import register from './Register/reducer'
import { LOADING, LOGIN_SUCCESS, LOGOUT_SUCCESS } from './actionTypes'

/**
 * @typedef {{ id: string | null, uuid: string | null, isLoading: boolean }} AuthState
 */

/**
 * @type {AuthState}
 */
const initialState = {
  id: null,
  uuid: null,
  isLoading: true,
}

/**
 * @typedef {{ type: typeof LOGIN_SUCCESS; userId: string, uuid: string, email: string }} LoginSuccessAction
 */

/**
 * @typedef {{ type: typeof LOADING }} LoginLoadingAction
 */

/**
 * @typedef {{ type: typeof LOGOUT_SUCCESS }} LogoutSuccessAction
 */

/**
 * @typedef {LoginSuccessAction | LoginLoadingAction | LogoutSuccessAction} AuthAction
 */

/**
 *
 * @param {AuthState} state
 * @param {AuthAction} action
 * @returns {AuthState}
 */
const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        id: action.userId,
        uuid: action.userUUID,
        email: action.email,
        isLoading: false,
      }
    case LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default combineReducers({
  user,
  register,
})
