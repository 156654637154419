import { push } from 'connected-react-router'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { trackEvent } from '@babylon/tracking/gtag'
import { scrollToPos } from '@/utils/dom-utils/scroll'
import { RouteId } from './types'
import { getRouteUrl } from './routes'
import {
  CONFIRM_NEEDS,
  SELECT_LOCATION,
  SELECT_SLOT,
  SELECT_MEDIUM,
  SELECT_CLINICIAN_TYPE,
} from './constants'
import {
  selectMedium,
  selectClinicianType,
  confirmNeeds,
  selectSlot,
  selectLocation,
} from './actions'
import { getNextStep, getStepNumberByRouteId } from './wrappers/withSteps'
import { selectBookingInvitation } from '@/redux/selectors'

export function* goToNextStep({ nextRouteId, inviteId }) {
  const url = getRouteUrl(nextRouteId, { inviteId })
  yield call(goToStep, url)
}

function* goToStep(url) {
  yield put(push(url))
  yield call(scrollToPos, 0, 0)
}

function* handleConfirmNeeds({ payload }: ReturnType<typeof confirmNeeds>) {
  const { inviteId, invite } = payload

  const shouldSetMedium = invite.allowed_mediums.length === 1

  if (shouldSetMedium) {
    const medium = invite.allowed_mediums[0]

    return yield put(
      selectMedium({
        options: {
          medium,
        },
        inviteId,
        invite,
      })
    )
  }

  const stepNumber = getStepNumberByRouteId(RouteId.BookBegin)

  return yield call(goToNextStep, {
    inviteId,
    nextRouteId: getNextStep(stepNumber)?.routeId,
  })
}

export function* handleSelectMedium({
  payload,
}: ReturnType<typeof selectMedium>) {
  const { options, inviteId, invite } = payload

  let stepNumber: number

  switch (invite.allowed_clinician_types?.length) {
    case undefined:
    case 0:
      stepNumber = getStepNumberByRouteId(RouteId.BookSelectClinicianType)
      break
    case 1: {
      const clinicianType = invite.allowed_clinician_types[0]!.key!

      return yield put(
        selectClinicianType({
          options: {
            clinicianType,
          },
          inviteId,
        })
      )
    }
    default:
      stepNumber = getStepNumberByRouteId(RouteId.BookSelectMedium)
      break
  }

  const nextRouteId = getNextStep(stepNumber, options.medium)?.routeId

  return yield call(goToNextStep, {
    inviteId,
    nextRouteId,
  })
}

function* handleSelectClinicianType({
  payload,
}: ReturnType<typeof selectClinicianType>) {
  const { inviteId } = payload
  const {
    bookingChoices: { medium },
  } = yield select(selectBookingInvitation)
  const stepNumber = getStepNumberByRouteId(RouteId.BookSelectClinicianType)

  const nextRouteId = getNextStep(stepNumber, medium)?.routeId

  return yield call(goToNextStep, {
    inviteId,
    nextRouteId,
  })
}

function* handleSelectLocation({ payload }: ReturnType<typeof selectLocation>) {
  yield call(goToNextStep, payload)
}

function* handleSelectSlot({ payload }: ReturnType<typeof selectSlot>) {
  trackEvent({
    category: 'invite appointments',
    action: 'select time picker',
    appointmentTime: payload.options.time,
  })

  yield call(goToNextStep, payload)
}

export default function* root() {
  yield takeLatest(CONFIRM_NEEDS, handleConfirmNeeds)
  yield takeLatest(SELECT_LOCATION, handleSelectLocation)
  yield takeLatest(SELECT_CLINICIAN_TYPE, handleSelectClinicianType)
  yield takeLatest(SELECT_MEDIUM, handleSelectMedium)
  yield takeLatest(SELECT_SLOT, handleSelectSlot)
}
