import { isEmpty, isNil } from 'lodash'

import healthReportConfig from './reportConfig'
import {
  compareItems,
  getAnswerComputedIfNecessary,
  getExpectedAnswer,
} from './utils'
import { ReportColors } from './types'

const resolveDisplayRule = (value, rules) => {
  const matched = rules.find((rule) =>
    compareItems(value, rule.operator, rule.value)
  )

  return matched
}

const computeIndicatorVariable = (indicatorDataItem) => {
  if (!isNil(indicatorDataItem?.variable)) {
    return indicatorDataItem.variable
  }

  const variableMappings = healthReportConfig.indicators.variableMappings.find(
    (variableMapping) => variableMapping.linkID === indicatorDataItem.linkID
  )

  if (isNil(variableMappings?.answerMappings)) {
    return null
  }

  const answerIsArray = Array.isArray(indicatorDataItem.value)

  const actualAnswer = answerIsArray
    ? indicatorDataItem.value
    : [indicatorDataItem.value]

  const computedVariable = (actualAnswer as
    | string[]
    | boolean[]
    | number[]).reduce((result: any, answer) => {
    const matchedMapping = variableMappings.answerMappings.mappings.find(
      (mapping) => {
        if (
          variableMappings.answerMappings.computed &&
          isEmpty(mapping.operator) &&
          isEmpty(mapping.variable)
        ) {
          return true
        }

        return compareItems(
          getAnswerComputedIfNecessary(
            answer,
            variableMappings.answerMappings.computed
          ),
          mapping.operator,
          getExpectedAnswer(mapping)
        )
      }
    )

    if (isNil(matchedMapping?.variable)) {
      result.push(
        getAnswerComputedIfNecessary(
          answer,
          variableMappings.answerMappings.computed
        )
      )
    } else {
      result.push(matchedMapping.variable)
    }

    return result
  }, [])

  return {
    name: variableMappings.answerMappings.variableName,
    value: answerIsArray ? computedVariable : computedVariable.shift(),
  }
}

const formatIndicatorsDisplay = (indicatorData) => {
  const indicators = healthReportConfig.indicators.data
    .map((indicatorConfig) => {
      const linkIDs = Array.isArray(indicatorConfig.linkID)
        ? indicatorConfig.linkID
        : [indicatorConfig.linkID]

      const data = linkIDs
        .map((linkID) => indicatorData.find((d) => d.linkID === linkID))
        .filter((d) => !!d)
        .reduce((res, curr) => {
          if (!curr.variable?.name) {
            return res
          }

          if (!res.variable) {
            res.variable = curr.variable.name
          }

          if (indicatorConfig.aggregate === 'SUM') {
            if (isNil(res.value)) {
              res.value = 0
            }

            res.value =
              Number(res.value) +
              (Array.isArray(curr.variable.value)
                ? Number(curr.variable.value[0])
                : Number(curr.variable.value))
          } else {
            res.value = curr.variable.value
          }

          return res
        }, {})

      const resolvedDisplayRule = resolveDisplayRule(
        data.value,
        indicatorConfig.rules
      )

      return {
        linkID: indicatorConfig.linkID,
        data,
        matchedDisplayRule: resolvedDisplayRule,
      }
    })
    .filter((indicatorDisplay) => !!indicatorDisplay?.matchedDisplayRule)

  healthReportConfig.indicators.combinedColors.forEach((combinedColor) => {
    const combinedIndexesColors = combinedColor.combine
      .map((combLinkID) => {
        const combinedIndicatorIndex = indicators.findIndex(
          (indicator) => indicator.linkID === combLinkID
        )

        if (combinedIndicatorIndex < 0) {
          return null
        }

        return {
          index: combinedIndicatorIndex,
          iconColor:
            indicators[combinedIndicatorIndex]?.matchedDisplayRule?.display
              ?.icon?.color,
        }
      })
      .filter((combIdxColor) => !!combIdxColor)

    const colors = combinedIndexesColors.map(
      (combIdxColor) => combIdxColor!.iconColor
    )
    let finalColor = ReportColors.GREEN

    if (colors.includes(ReportColors.RED)) {
      // any of them has RED color, then all of them must be RED
      finalColor = ReportColors.RED
    } else if (colors.includes(ReportColors.AMBER)) {
      // any of them has AMBER color, then all of them must be AMBER
      finalColor = ReportColors.AMBER
    }

    if (finalColor !== ReportColors.GREEN) {
      combinedIndexesColors.forEach((combIdxColor) => {
        indicators[
          combIdxColor!.index
        ].matchedDisplayRule.display.icon.color = finalColor
      })
    }
  })

  return indicators
}

// eslint-disable-next-line import/prefer-default-export
export const mapQuestionnaireData = (questionnaire: any): any => {
  if (questionnaire?.patient?.questionnaireResponses?.nodes.length === 0) {
    return []
  }

  const questionsMap = questionnaire?.patient?.questionnaireResponses?.nodes?.[0]?.questionnaire?.items?.nodes?.reduce(
    (data, node) => ({
      ...data,
      [node.linkId]: node.text,
    }),
    {}
  )

  const answers = questionnaire?.patient?.questionnaireResponses?.nodes?.[0]?.items?.nodes?.map(
    (node) => {
      const value = node.answers.nodes.map((answer: any) => answer.valueString)

      const variable = computeIndicatorVariable({ linkID: node.linkId, value })

      return variable
        ? {
            linkID: node.linkId,
            value,
            variable,
            text: questionsMap[node.linkId],
          }
        : {
            linkID: node.linkId,
            value,
            text: questionsMap[node.linkId],
          }
    }
  )

  return formatIndicatorsDisplay(answers)
}
