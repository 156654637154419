export type User = {
  /* eslint-disable camelcase */
  id: string
  first_name: string
  last_name: string
  avatar_full_url: string
  subscription: {
    plan: {
      title: string
      is_default: boolean
    }
  }
  consumer_networks: {
    uuid: string
    name: string
  }[]
  preferred_consumer_network_uuid: string
  /* eslint-enable camelcase */
}

export const getGreeting = (messages: {
  greetingMorning: { id: string; defaultMessage: string }
  greetingAfternoon: { id: string; defaultMessage: string }
  greetingEvening: { id: string; defaultMessage: string }
}) => {
  const hours = new Date().getHours()

  if (hours < 12) {
    return messages.greetingMorning
  }

  if (hours < 18) {
    return messages.greetingAfternoon
  }

  return messages.greetingEvening
}

export const getMembershipName = (user: User) => {
  const { plan } = user.subscription
  const preferredConsumerNetwork = user.consumer_networks.find(
    (cn) => cn.uuid === user.preferred_consumer_network_uuid
  )

  if (!plan.is_default) {
    return plan.title
  }

  return preferredConsumerNetwork?.name
}

export const isGPAtHandPreferredCN = (user?: User) => {
  const preferredConsumerNetwork = user?.consumer_networks?.find(
    (cn) => cn.uuid === user.preferred_consumer_network_uuid
  )
  // matches gp at hand with or without spaces or hyphens
  const gpAtHandRegex = new RegExp('gp[- ]?at[- ]?hand', 'i')

  return gpAtHandRegex.test(preferredConsumerNetwork?.name || '')
}
