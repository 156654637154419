import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useServiceNowChatEnabled } from '@/redux/selectors'
import { SERVICE_NOW_CHAT_URL } from '@/config'

type ServiceNowChat = {
  host: HTMLElement
}

const ALLOWED_PATHS = ['/weight-loss/']

const initializeServiceNowChat = () => {
  if (window.ServiceNowChat && SERVICE_NOW_CHAT_URL) {
    try {
      // eslint-disable-next-line no-new
      const chat = new window.ServiceNowChat({
        instance: SERVICE_NOW_CHAT_URL,
        branding: {
          bgColor: '#000000',
          primaryColor: '#ffffff',
          hoverColor: '#222222',
          activeColor: '#000000',
        },
        context: {
          topic: 'cdeca353474142107109a4e1516d43ba',
          live_agent_queue: null,
        },
      })

      return chat as ServiceNowChat
    } catch {
      // eslint-disable-next-line no-console
      console.warn('ServiceNowChat could not be initialized')
    }
  }

  return null
}

const useServiceNowChat = () => {
  const { pathname } = useLocation()
  const isServiceNowChatEnabled = useServiceNowChatEnabled()
  const isPathAllowed = ALLOWED_PATHS.some((path) => pathname.startsWith(path))

  const chatRef = useRef<ServiceNowChat | null>(null)

  if (isServiceNowChatEnabled && isPathAllowed && !chatRef.current) {
    chatRef.current = initializeServiceNowChat()
  }

  useEffect(() => {
    if (chatRef.current) {
      // ServiceNow doesn't prove a way to hide the chat dynamically, so we have to do it manually.
      // See https://www.servicenow.com/community/developer-forum/is-there-a-way-to-hide-the-agent-chat-portal-button-from-its/m-p/1615973
      chatRef.current.host.style.display = isPathAllowed ? 'block' : 'none'
    }
  }, [isPathAllowed])
}

export default useServiceNowChat
