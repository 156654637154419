import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Form, TextareaField, Text, SelectField, Alert } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import LandingHeader from '@/components/LandingHeader'
import appStyles from '@/App.module.scss'
import messages from './FeedbackPage.messages'

const FeedbackMutation = gql`
  mutation submitFeedback($rating: String!, $info: String!) {
    submitFeedback(rating: $rating, info: $info) {
      success
    }
  }
`

const FeedbackPage = () => {
  const [submit, { data }] = useMutation(FeedbackMutation)
  const translate = useFormatMessage()

  return (
    <>
      <LandingHeader />
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <Text variant="h1">{translate(messages.pageTitle)}</Text>
          {data?.submitFeedback.success === true && (
            <Alert type="positive">
              {translate(messages.submissionSuccess)}
            </Alert>
          )}
          {!data && (
            <Form
              initialValues={{ info: '', rating: '' }}
              onSubmit={({ rating, info }) =>
                submit({ variables: { rating, info } })
              }
              submitButtonText={translate(messages.submitButton)}
            >
              <Text variant="body">{translate(messages.description)}</Text>
              <SelectField
                required
                name="rating"
                validateMessage={translate(messages.required)}
                label={translate(messages.ratingField)}
                options={[1, 2, 3, 4, 5].map((rating) => ({
                  label: translate(messages.ratingOptions, { rating }),
                  value: `${rating}`,
                }))}
              />
              <TextareaField
                required
                name="info"
                validateMessage={translate(messages.required)}
                label={translate(messages.infoField)}
              />
            </Form>
          )}
        </div>
      </main>
    </>
  )
}

export default FeedbackPage
