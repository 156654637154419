const CONSULTATIONS_ROOT = '/consultations'

const bookConsultation = {
  id: 'bookConsultation',
  route: `${CONSULTATIONS_ROOT}`,
  getUrl: () => `${CONSULTATIONS_ROOT}`,
  redirect: `${CONSULTATIONS_ROOT}/new`,
  breadcrumb: {
    isExcluded: true,
  },

  children: [
    {
      id: 'consultationNew',
      route: `${CONSULTATIONS_ROOT}/new`,
      getUrl: () => `${CONSULTATIONS_ROOT}/new`,
      conditions: ['enable_appointments'],
    },
    {
      id: 'consultationPay',
      route: `${CONSULTATIONS_ROOT}/:appointmentId/payment/:planId?`,
      getUrl: ({ appointmentId, planId = '' }) =>
        `${CONSULTATIONS_ROOT}/${appointmentId}/payment/${planId}?`,
      breadcrumb: {
        isWide: true,
        path: ['consultationNew', 'consultationReview'],
      },
    },
    {
      id: 'consultationFeedback',
      route: `${CONSULTATIONS_ROOT}/:consultationId([0-9]+)?/feedback`,
      getUrl: ({ consultationId }) =>
        `${CONSULTATIONS_ROOT}/${consultationId}/feedback`,
      breadcrumb: {
        isWide: true,
      },
    },
    {
      id: 'consultationPlans',
      route: `${CONSULTATIONS_ROOT}/plans/:appointmentId`,
      getUrl: ({ appointmentId }) =>
        `${CONSULTATIONS_ROOT}/plans/${appointmentId}`,
      breadcrumb: {
        isWide: true,
        path: ['consultationNew', 'consultationReview', 'consultationPay'],
      },
    },
    {
      id: 'consultationReview',
      route: `${CONSULTATIONS_ROOT}/new/review`,
      getUrl: () => `${CONSULTATIONS_ROOT}/new/review`,
      breadcrumb: {
        isWide: true,
        path: ['consultationNew'],
      },
    },
    {
      id: 'consultationEdit',
      route: `${CONSULTATIONS_ROOT}/new/edit`,
      getUrl: () => `${CONSULTATIONS_ROOT}/new/edit`,
      breadcrumb: {
        isWide: true,
        path: ['consultationNew'],
      },
    },
    {
      id: 'consultationBooked',
      route: `${CONSULTATIONS_ROOT}/:appointmentId/success`,
      getUrl: ({ appointmentId }) =>
        `${CONSULTATIONS_ROOT}/${appointmentId}/success`,
      breadcrumb: {
        path: ['consultationNew'],
      },
    },
  ],
}

export default bookConsultation
