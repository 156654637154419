import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import calculateBMI from '@/programmes/utils/calculateBMI'
import validateAnswer from '../utils/validateAnswer'
import {
  Answer,
  CheckBoxGroupValue,
  Measurements,
  Step,
  Question,
  RadioGroupValue,
} from '../types'
import useQuestionnaireWebViewHandler from '../../../hooks/useQuestionnaireWebViewHandler'

export const QUESTIONNAIRE_FORM_DATA_KEY = 'questionnaire-data'

const saveQuestionnaireData = (
  answers,
  formHistory,
  currentStep,
  completed = false
) => {
  sessionStorage.setItem(
    QUESTIONNAIRE_FORM_DATA_KEY,
    JSON.stringify({
      answers,
      formHistory,
      currentStep: currentStep.id,
      completed,
    })
  )
}

export default function useForm(
  steps: Array<Step>,
  onSubmit: (answers?: { [p: string]: Answer }) => void
) {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState<Step>(steps[0])
  const [answers, setAnswers] = useState<{
    [questionId: string]: Answer
  }>({})
  const [formHistory, setFormHistory] = useState<string[]>([steps[0].id])
  const [showExitView, setShowExitView] = useState<boolean>(false)
  const [showFormError, setShowFormError] = useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  useEffect(() => {
    const formData = sessionStorage.getItem(QUESTIONNAIRE_FORM_DATA_KEY)
    if (formData) {
      const { answers, formHistory, currentStep } = JSON.parse(formData)
      setAnswers(answers)
      setFormHistory(formHistory)
      setCurrentStep(steps.find((step) => step.id === currentStep) || steps[0])
    }
  }, [steps])

  useEffect(() => {
    saveQuestionnaireData(answers, formHistory, currentStep)
  }, [answers, currentStep, formHistory])

  useEffect(() => {
    setShowFormError(false)
  }, [currentStep])

  const handleConditions = (
    question: Question,
    answers: { [p: string]: Answer },
    steps: Step[]
  ): Step => {
    if (question?.skipCondition) {
      const answer = answers[question.skipCondition.id]

      //handle only RadioGroup skip condition need to add logic to handle for other types
      if ((answer as RadioGroupValue).value === question.skipCondition.answer) {
        const step = steps.find((s) => s.id === question.next)

        if (step) {
          return step
        }
      }
    }

    return question
  }

  const handleNextAction = (step: Step, answer: Answer): string | undefined => {
    if (step?.type === 'question') {
      const optionsRules = step?.optionsRules || []

      if (
        step?.inputType.component === 'checkboxGroup' &&
        optionsRules.length
      ) {
        const result = optionsRules.find((rule) =>
          (answer as Array<CheckBoxGroupValue>).find(
            (checked) => rule.label === checked.value
          )
        )

        if (result) {
          return result.nextAction
        }
      }

      if (step?.inputType.component === 'radioGroup' && step?.optionsRules) {
        const result = optionsRules.find(
          (rule) => (answer as RadioGroupValue)?.value === rule.label
        )

        if (result) {
          return result.nextAction
        }
      }

      if (step?.inputType.component === 'heightAndWeight') {
        const { height, weight } = answers[step.id] as Measurements
        const bmi = calculateBMI(height, weight)

        //TODO- THIS IS SPECIFIC TO THE WEIGHT LOSS PROGRAMME FIND A WAY TO MAKE IT GENERIC
        if (bmi) {
          if (bmi < 27) {
            return 'exit-action'
          }

          if (bmi >= 27 && bmi <= 29.9) {
            return '91c7651a-ca58-11ee-a00f-ef21372b1c99'
          }

          if (bmi >= 30) {
            return 'c2ca0ece-ca58-11ee-a7b8-6729b5205ead'
          }
        }
      }
    }

    return step?.next
  }

  const validateForm = () => {
    if (currentStep.type === 'question' && currentStep.required) {
      const valid = validateAnswer(currentStep, answers[currentStep.id])
      setShowFormError(!valid)

      return valid
    }

    return true
  }

  const handleAnswer = useCallback(
    (questionId: string, answer: Answer) => {
      if (showFormError) {
        setShowFormError(false)
      }

      setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: answer }))
    },
    [showFormError]
  )

  const handleNext = async (event) => {
    event.preventDefault()
    const valid = validateForm()

    if (!valid) {
      return
    }

    const next = handleNextAction(currentStep, answers[currentStep?.id])

    if (next === 'complete-action') {
      saveQuestionnaireData(answers, formHistory, currentStep, true)
      await onSubmit(answers)

      return
    }

    if (next === 'exit-action') {
      setShowExitView(true)
      sessionStorage.removeItem(QUESTIONNAIRE_FORM_DATA_KEY)
      return
    }

    let step = steps.find((s) => s.id === next)

    if (step) {
      if (step.type === 'question') {
        step = handleConditions(step as Question, answers, steps)
      }

      setCurrentStep(step)
      setFormHistory([...formHistory, step.id])
    }
  }

  const handlePrevious = () => {
    if (formHistory && formHistory.length > 1) {
      formHistory.pop()
      const previousQuestionId = formHistory[formHistory.length - 1]
      const step = steps.find((question) => question.id === previousQuestionId)

      if (step) {
        setCurrentStep(step)
      }
    }
  }

  const handleExit = () => {
    history.push('/')
  }

  useQuestionnaireWebViewHandler(answers, handlePrevious)

  return {
    showExitView,
    answers,
    currentStep,
    handleAnswer,
    handleExit,
    handleNext,
    handlePrevious,
    showFormError,
    formHistory,
  }
}
