import React from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useWeightLossRefillNextStepsInfo } from '@/redux/selectors'
import WeightLossReadInstructions from '@/assets/images/assessment.png'
import WeightLossSyringeImage from '@/assets/images/weight-loss_syringe.png'
import WeightLossScaleImage from '@/assets/images/weight-loss_scale.png'
import WeightLossHandsImage from '@/assets/images/weight-loss_hands.png'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import messages from '../messages'
import Button from '@/programmes/components/Button'
import Card from '@/programmes/components/Card'
import { procedures } from '../routes'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import styles from '../index.module.scss'

const cx = classNames.bind(styles)

const RefillNextStepsPage = () => {
  const t = useFormatMessage()
  const announcement = useWeightLossRefillNextStepsInfo()
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const proctorInviteUrl = data?.createProctorSessionInvite?.url

  if (proctorInviteUrl) {
    window.location.href = proctorInviteUrl
  }

  return (
    <Template title={t(messages.refillNextStepsPageTitle)}>
      <h1 className={cx('heading')}>
        {t(messages.refillNextStepsPageHeading)}
      </h1>
      <p className={cx('description')}>
        {t(messages.refillNextStepsPageDescription)}
      </p>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.checkInStartupHomePageCard1Title)}
          description={t(messages.checkInStartupHomePageCard1Description)}
          image={WeightLossReadInstructions}
          imageDescription={t(
            messages.checkInStartupHomePageCard1ImageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard2Title)}
          description={t(messages.checkInStartupHomePageCard2Description)}
          image={WeightLossSyringeImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard12mageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard3Title)}
          description={t(messages.checkInStartupHomePageCard3Description)}
          image={WeightLossScaleImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard13mageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard4Title)}
          description={t(messages.checkInStartupHomePageCard4Description)}
          image={WeightLossHandsImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard14mageDescription
          )}
        />
      </div>
      {announcement && <Announcement content={announcement} />}
      <Button
        onClick={() =>
          getProctorSessionInvite({
            variables: {
              input: {
                procedure: {
                  identifier: { value: procedures.WEIGHT_LOSS_CHECK_IN_REFILL },
                },
              },
            },
          })
        }
        loading={loading || !!proctorInviteUrl}
      >
        {t(messages.refillNextStepsPageCta)}
      </Button>
    </Template>
  )
}

export default RefillNextStepsPage
