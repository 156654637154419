import React from 'react'

type Props = {
  duration: number
  width: number
}

const Spinner = ({ duration, width }: Props) => (
  <svg
    version="1.1"
    id="circle"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width={width}
  >
    <circle
      fill="none"
      stroke="#dddfe1"
      strokeWidth="6"
      cx="50"
      cy="50"
      r="47"
    />
    <circle
      fill="none"
      stroke="#49efb3"
      strokeWidth="6"
      cx="50"
      cy="50"
      r="47"
      strokeDasharray="360"
      strokeDashoffset="360"
      strokeLinecap="round"
      transform="rotate(-90 ) translate(-100 0)"
    >
      <animate
        attributeName="stroke-dashoffset"
        values="360;300;240;0"
        dur={`${duration}s`}
        fill="freeze"
        repeatCount="1"
      />
    </circle>
  </svg>
)

export default Spinner
