import { defineMessages } from 'react-intl'

export default defineMessages({
  next: {
    id: 'form.next',
    defaultMessage: 'Next',
  },
  confirm: {
    id: 'form.confirm',
    defaultMessage: 'Confirm',
  },
  registerAndContinue: {
    id: 'form.registerAndContinue',
    defaultMessage: 'Register and continue',
  },
  previous: {
    id: 'form.previous',
    defaultMessage: 'Previous',
  },
  nextSteps: {
    id: 'form.nextSteps',
    defaultMessage: 'Next steps',
  },
  done: {
    id: 'form.done',
    defaultMessage: 'Done',
  },
})
