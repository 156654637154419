import { createAuth0Client } from '@auth0/auth0-spa-js'
import decode from 'jwt-decode'
import { AuthClient } from './auth-client'
import { CoreAuth2Client } from './core-auth-2-client'

export interface Auth0Config {
  clientId: string
  domain: string
}

export interface CoreAuth2Config {
  url: string
  idToken?: string
}

let client: AuthClient | null = null

const openUrl = async (url: string): Promise<void> => {
  window.location.replace(url)
}

export const initialise = async (
  config?: Auth0Config,
  coreAuth2Config?: CoreAuth2Config
): Promise<boolean> => {
  if (coreAuth2Config && coreAuth2Config.idToken) {
    try {
      client = new CoreAuth2Client(coreAuth2Config)
      // getUser waits for the accessToken to be available
      const user = await client.getUser()
      return !!user
    } catch (e) {
      return false
    }
  }

  if (config && !client) {
    client = await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        scope: 'openid',
        prompt: 'login',
        redirect_uri: `${window.location.origin}/callback`,
        response_type: 'code',
      },
    })
  }
  return true
}

export const register = async (
  queryParams: Record<string, any> = {},
  skipHistory: boolean = false
) =>
  client?.loginWithRedirect({
    ...queryParams,
    authorizationParams: {
      mode: 'sign-up',
      ...queryParams,
    },
    appState: {
      targetUrl: `${window.location.pathname}${window.location.search}`,
      registration: true,
    },
    ...(skipHistory ? { openUrl } : {}),
  })

export const login = async (
  email?: string,
  queryParams: Record<string, any> = {},
  skipHistory: boolean = false
) =>
  client?.loginWithRedirect({
    authorizationParams: {
      mode: 'sign-in',
      ...queryParams,
      ...(email ? { login_hint: email } : {}),
    },
    appState: {
      targetUrl: `${window.location.pathname}${window.location.search}`,
    },
    ...(skipHistory ? { openUrl } : {}),
  })

export const logout = () => client?.logout()

export const getAuthToken = async () => client?.getTokenSilently()

export const getUser = async () => client?.getUser()

export const hasClinicalScope = async () => {
  const token = await getAuthToken()

  if (!token) {
    return false
  }

  const decodedToken = decode(token) as any

  return decodedToken.scope.includes('clinical')
}

export const handleRedirectCallback = async () =>
  client?.handleRedirectCallback()

export const getAuth0Client = () => client
