import { generateTheme } from '@babylon/medkit'
import baseThemes from './themes.json'

const themes = {}

Object.keys(baseThemes).forEach((themeName) => {
  themes[themeName] = generateTheme(baseThemes[themeName])
})

export default themes
