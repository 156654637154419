import React, { FC, ReactNode, useEffect } from 'react'
import { ThemeProvider } from '@babylon/medkit'
import { useSelector } from 'react-redux'

import themes from './themes'

const supportedTailwindThemes = ['emed', 'lime-health']

const useTheme = () => {
  let themeName = useSelector<any, any>((state) => state.config.theme)

  themeName = themeName || 'emed'

  return !themes[themeName]
    ? // eslint-disable-next-line dot-notation
      { themeName: 'emed', theme: themes['emed'] }
    : { themeName, theme: themes[themeName] }
}

const useTailwindTheme = (themeName) => {
  useEffect(() => {
    if (themeName && supportedTailwindThemes.includes(themeName)) {
      // Remvoe all theme classes
      document.body.classList.forEach((className) => {
        if (className.match(/^theme.*/)) {
          document.body.classList.remove(className)
        }
      })

      // Add current selected theme (emed is set by default)
      if (themeName !== 'emed') {
        document.body.classList.add(`theme-${themeName}`)
      }
    }
  }, [themeName])
}

interface ThemeProps {
  children?: ReactNode
  isRoot?: boolean
}

const Theme: FC<ThemeProps> = ({ children, isRoot = false }: ThemeProps) => {
  const { theme, themeName } = useTheme()

  useTailwindTheme(themeName)

  if (!theme) {
    return <>{children}</>
  }

  return (
    <ThemeProvider theme={theme} root={isRoot}>
      {children}
    </ThemeProvider>
  )
}

export default Theme
export { useTheme }
