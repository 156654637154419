import React from 'react'
import { injectIntl } from 'react-intl'
import { compose, withHandlers, withProps } from 'recompose'
import { Button } from '@babylon/medkit'
import LandingHeader from '@/components/LandingHeader'
import AppsIcons from '@/components/AppsInfo/AppsIcons'
import appStyles from '@/App.module.scss'
import errorImage from './error.svg'
import styles from './ErrorPage.module.scss'
import messages from './ErrorPage.messages'
import WebViewMode from '@/utils/WebViewMode'

const enhance = compose(
  injectIntl,
  withProps(() => {
    // We don't want to throw an error in the error handler (e.g. if the browser doesn't have localStorage)
    try {
      const time = Date.now()
      const errorKey = 'web-app-error'
      const lastError = localStorage.getItem(errorKey)
      const repeatTime = 1000 * 60 * 5 // 5 minutes
      const multiple = lastError && lastError > time - repeatTime
      localStorage.setItem(errorKey, time)
      return { multiple }
    } catch (err) {
      return { multiple: false }
    }
  }),
  withHandlers({
    goToHome: () => () => {
      // Load with timestamp to ignore cache
      window.location.href = `/?t=${Date.now()}`
    },
  })
)

const ErrorPage = ({ intl, goToHome, multiple, embeddedView }) => {
  const isWebView = WebViewMode.isWebViewMode
  const WebAppErrorMessages = () =>
    multiple ? (
      <>
        <p>{intl.formatMessage(messages.extra)}</p>
        <AppsIcons />
      </>
    ) : (
      <p>{intl.formatMessage(messages.message2)}</p>
    )

  return (
    <>
      {!embeddedView && <LandingHeader />}
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <div className={styles.errorContainer}>
            <img src={errorImage} alt="" />
            <h1 className={styles.title}>
              {intl.formatMessage(messages.title)}
            </h1>
            <p>{intl.formatMessage(messages.message1)}</p>
            {!isWebView && (
              <>
                <WebAppErrorMessages />
                <Button onClick={goToHome}>
                  {intl.formatMessage(messages.action)}
                </Button>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default enhance(ErrorPage)
