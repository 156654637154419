import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormatMessage } from '@babylon/intl'
import { TrackingActionType } from '@babylon/tracking/react'
import {
  useModalActions,
  ButtonSimple,
  LinkButton,
  ModalContent,
  Text,
  ButtonVariant,
} from '@babylon/medkit'
import useTracking from '@/tracking/useTracking'

import styles from './Modal.module.scss'
import messages from './Modal.messages'

const NoticeDetails = ({ html }) => (
  <Text
    variant="body"
    data-testid="noticeHtml"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

const getActionButtons = (notice, onAccept, onDecline) => {
  const actionButtons = []

  if (notice.acceptButtonText) {
    actionButtons.push(
      <ButtonSimple
        halfWidth={notice.declineButtonText}
        key="noticeAcceptButton"
        onClick={onAccept}
        data-testid="noticeAcceptButton"
      >
        {notice.acceptButtonText}
      </ButtonSimple>
    )
  }

  if (notice.declineButtonText) {
    actionButtons.push(
      <ButtonSimple
        halfWidth
        key="noticeDeclineButton"
        variant={ButtonVariant.destructive}
        onClick={onDecline}
        data-testid="noticeDeclineButton"
      >
        {notice.declineButtonText}
      </ButtonSimple>
    )
  }

  return actionButtons
}

const NoticeModal = ({ notice, onAccept, onDecline, newUser, onClose }) => {
  const translate = useFormatMessage()
  const [isModalOpen, setModalOpen] = useState(false)
  const { closeModal, openModal } = useModalActions()

  const {
    declineButtonText,
    readMoreButtonText,
    mandatory,
    updateTitle,
    popupText,
    updateText,
    title,
    noticeType,
  } = notice

  // Text will always render when it is a privacy policy for transparency
  const isPrivacyPolicy = mandatory && !declineButtonText
  const showReadMore = readMoreButtonText && !isPrivacyPolicy
  const showDetails = !readMoreButtonText || isPrivacyPolicy
  const showHeader = isPrivacyPolicy || !showDetails

  const noticeTitle = newUser && title ? title : updateTitle
  const text = newUser && popupText ? popupText : updateText

  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent({
      eventCategory: 'disclaimers',
      eventAction: `saw ${noticeType.toLowerCase()}`,
      screenTitle: 'notice-modal',
      actionType: TrackingActionType.pageLoaded,
    })
  }, [trackEvent, noticeType])

  const acceptPolicy = async () => {
    await onAccept()
    setModalOpen(false)
    closeModal()
    trackEvent({
      eventCategory: 'disclaimers',
      eventAction: `clicked accept ${noticeType.toLowerCase()}`,
      screenTitle: 'notice-modal',
      actionType: TrackingActionType.click,
    })
  }
  const declinePolicy = async () => {
    await onDecline()
    setModalOpen(false)
    closeModal()
    trackEvent({
      eventCategory: 'disclaimers',
      eventAction: `clicked reject ${noticeType.toLowerCase()}`,
      screenTitle: 'notice-modal',
      actionType: TrackingActionType.click,
    })
  }

  const actionButtons = getActionButtons(notice, acceptPolicy, declinePolicy)

  const NoticeModalContent = () => {
    const [showDetailedInfo, setDetailedInfo] = useState(showDetails)

    const learnMoreAction = () => {
      setDetailedInfo(!showDetailedInfo)
      trackEvent({
        eventCategory: 'disclaimers',
        eventAction: `clicked learn more ${notice.noticeType.toLowerCase()}`,
        screenTitle: 'notice-modal',
        actionType: TrackingActionType.click,
      })
    }

    return (
      <ModalContent title={noticeTitle} buttons={actionButtons}>
        <div data-notice-id={notice.noticeId}>
          {showHeader && (
            <Text data-testid="noticeText" variant="body">
              {text}
            </Text>
          )}
          {showDetailedInfo && <NoticeDetails html={notice.longText} />}
          {showReadMore && (
            <LinkButton
              className={styles.infoButton}
              onClick={() =>
                showDetailedInfo
                  ? setDetailedInfo(!showDetailedInfo)
                  : learnMoreAction()
              }
              data-testid="noticeShowMoreLink"
            >
              {showDetailedInfo
                ? translate(messages.lessInfo)
                : notice.readMoreButtonText}
            </LinkButton>
          )}
        </div>
      </ModalContent>
    )
  }

  if (!isModalOpen) {
    setModalOpen(true)
    openModal(<NoticeModalContent />, {
      isDismissable: !notice.mandatory,
      ...(onClose ? { onDismiss: () => onClose() } : {}),
    })
  }

  return null
}

NoticeModal.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onClose: PropTypes.func,
  newUser: PropTypes.bool.isRequired,
}

NoticeModal.defaultProps = {
  onAccept: () => {},
  onDecline: () => {},
  onClose: () => {},
}

export default NoticeModal
