import React from 'react'
import { compose, withHandlers } from 'recompose'
import { graphql } from '@apollo/react-hoc'

import { PrivacyPolicyDecision, updateCache } from '@/components/Privacy'
import withPrivacyNotices, { justInTimeNotices } from './WithPrivacyNotices'
import NoticeModal from '@/components/Modal/NoticeModal'
import { usePartnerBookingFlowEnabled } from '@/redux/selectors'

const enhance = compose(
  withPrivacyNotices('just_in_time'),
  graphql(PrivacyPolicyDecision, {
    name: 'decideOnPolicy',
  }),
  withHandlers({
    onPolicyDecision: ({ decideOnPolicy, userId }) => (notice, value) =>
      decideOnPolicy({
        variables: {
          decision: value,
          noticeVersionId: notice.noticeVersionId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          privacyPolicyDecision: {
            __typename: 'PrivacyNotice',
            noticeVersionId: notice.noticeVersionId,
            accepted: value,
          },
        },
        update: updateCache({
          noticeVersionId: notice.noticeVersionId,
          userId,
          query: justInTimeNotices,
          propertyName: 'just_in_time_notices',
        }),
      }),
  })
)

const hasAcceptedNotice = (Component) => {
  const Wrapper = ({
    onPolicyDecision,
    privacyAgreementNeeded,
    privacyNotices,
    ...props
  }) => {
    const isPartnerBookingEnabled = usePartnerBookingFlowEnabled()
    return (
      <>
        <Component
          isShowingPrivacyNotice={privacyAgreementNeeded}
          key="content"
          {...props}
        />
        {!isPartnerBookingEnabled && Boolean(privacyAgreementNeeded) && (
          <NoticeModal
            key="noticeModal"
            notice={privacyNotices[0]}
            onAccept={() => onPolicyDecision(privacyNotices[0], true)}
            onDecline={() => onPolicyDecision(privacyNotices[0], false)}
          />
        )}
      </>
    )
  }

  return enhance(Wrapper)
}

export default hasAcceptedNotice
