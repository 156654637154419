import React from 'react'

import styles from './List.module.scss'

interface Props {
  children?: React.ReactNode
  title?: string
}

const List: React.FC<Props> = ({ children, title }) => (
  <ul className={styles.list} aria-label={title}>
    {children}
  </ul>
)

export default List
