import React from 'react'
import SvgGreenRAG from './greenRAG'
import SvgAmberRAG from './amberRAG'
import SvgRedRAG from './redRAG'
import { ReportColors } from '../../../types'

const RAGConditionalSvgComponent = ({ rag }: { rag: ReportColors }) => {
  switch (rag) {
    case ReportColors.GREEN:
      return <SvgGreenRAG />
    case ReportColors.AMBER:
      return <SvgAmberRAG />
    case ReportColors.RED:
      return <SvgRedRAG />
    default:
      return <></>
  }
}

export default RAGConditionalSvgComponent
