import React from 'react'
import styles from './InputBody.module.scss'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface Props {
  body: string | Array<string> | React.ReactNode
  isFooter?: boolean
  id?: string
}

const InputBody: React.FC<Props> = ({ body, isFooter, id }) => (
  <div className={isFooter ? styles.footerSpacerV1 : styles.spacerV1}>
    <div className={styles.containerV1}>
      {Array.isArray(body) && (
        <ul
          className={styles.listV1}
          id={id ? `${STEP_ELEMENT_ID_PREFIX}-body-${id}` : undefined}
        >
          {body.map((text) => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      )}

      {React.isValidElement(body) && body}

      {typeof body === 'string' && (
        <span
          className={styles.subTextV1}
          id={id ? `${STEP_ELEMENT_ID_PREFIX}-body-${id}` : undefined}
        >
          {body}
        </span>
      )}
    </div>
  </div>
)

export default InputBody
