import { defineMessages } from 'react-intl'

export default defineMessages({
  homePageTitle: {
    id: 'Wellness.HomePage.pageTitle',
    defaultMessage: 'Wellness',
  },
  homeHeading: {
    id: 'Wellness.HomePage.heading',
    defaultMessage: 'Welcome to eMed® Screen',
  },
  homeDescription: {
    id: 'Wellness.HomePage.description',
    defaultMessage:
      'Enter your details to sign up for eMed Screen, your wellness programme.\n\n' +
      'We give you all of the tools, care and support you need to manage your health from the comfort of your own home.',
  },
  homeImage: {
    id: 'Wellness.HomePage.image',
    defaultMessage: 'Person standing on scale',
  },
  homeCta2: {
    id: 'Wellness.HomePage.cta2',
    defaultMessage: 'Next',
  },
  homePrivacy: {
    id: 'Wellness.HomePage.privacy',
    defaultMessage:
      'Your data will be processed in line with our updated <a>privacy policy</a>.',
  },
  questionnairePageTitle: {
    id: 'Wellness.QuestionnairePage.pageTitle',
    defaultMessage: 'Wellness - Questionnaire',
  },
  checkInAutomatedTitle: {
    id: 'Wellness.CheckInAutomated.title',
    defaultMessage: 'It is time for your weekly check-in!',
  },
  checkInAutomatedSubtitle: {
    id: 'Wellness.CheckInAutomated.subtitle',
    defaultMessage: "Let's record your latest weight",
  },
  checkInPageTitle: {
    id: 'Wellness.CheckInPage.pageTitle',
    defaultMessage: 'Wellness - Check-in',
  },
  checkInPageHeading: {
    id: 'Wellness.CheckInPage.heading',
    defaultMessage: 'One more step...',
  },
  checkInPageDescription1: {
    id: 'Wellness.CheckInPage.description1',
    defaultMessage:
      "To support you to maintain a healthy weight, we'd like you to record your weight each week. This is quick & easy - we will connect you to our platform where you'll enter the reading on your scales.",
  },
  checkInPageDescription2: {
    id: 'Wellness.CheckInPage.description2',
    defaultMessage: 'Please ensure you have access to your scales.',
  },
  checkInPageImageDescription: {
    id: 'Wellness.CheckInPage.imageDescription',
    defaultMessage: 'Never leave home',
  },
  checkInPageCta1: {
    id: 'Wellness.CheckInPage.cta1',
    defaultMessage: 'Get started',
  },
  checkInPageCta2: {
    id: 'Wellness.CheckInPage.cta2',
    defaultMessage: 'Capture your weight',
  },
  checkInPageFooter: {
    id: 'Wellness.CheckInPage.footer',
    defaultMessage:
      'Not a good time? You can return to this page from this device in the next two days.',
  },
  checkoutHeading: {
    id: 'Wellness.Checkout.heading',
    defaultMessage: 'Payment details',
  },
  checkoutDescription1: {
    id: 'Wellness.Checkout.description1',
    defaultMessage: 'Thank you for completing our wellness questionnaire.',
  },
  checkoutDescription2: {
    id: 'Wellness.Checkout.description2',
    defaultMessage:
      'We will need your payment details to sign you up to your subscription.',
  },
  checkoutDescription3Immediate: {
    id: 'Wellness.Checkout.description3Immediate',
    defaultMessage: 'Your subscription will start immediately.',
  },
  checkoutPageTermsAndConditions: {
    id: 'Wellness.Checkout.termsAndConditions',
    defaultMessage: '* Please see our terms and conditions',
  },
  checkoutPageTitle: {
    id: 'Wellness.Checkout.Title',
    defaultMessage: 'Payment details',
  },
  checkoutPageTitleCta: {
    id: 'Wellness.Checkout.cta',
    defaultMessage: 'Set up payment',
  },
  checkoutPageProductDescription: {
    id: 'Wellness.Checkout.productDescription',
    defaultMessage:
      '• Quarterly blood tests - Monitor your health and susceptibility to conditions like heart disease and diabetes with our clinical experts.\n' +
      '• Weekly well-being screenings - Stay committed with our Healthcare Technicians.\n' +
      '• Track your health - Monitor your weight, oversee your mental wellbeing and sync your activity trackers to prioritise your health and wellbeing.\n' +
      '• Achieve your health goals - Set realistic, lasting milestones to manage your health.\n',
  },
  checkoutPageProductTitle: {
    id: 'Wellness.Checkout.productTitle',
    defaultMessage: 'eMed® Screen - Wellness Programme',
  },
  checkoutPageProductPrice: {
    id: 'Wellness.Checkout.productPrice',
    defaultMessage: 'Free for eMed employees',
  },
  confirmationPageTitle: {
    id: 'Wellness.ConfirmationPage.pageTitle',
    defaultMessage: 'Wellness - Confirmation',
  },
  confirmationPageHeading: {
    id: 'Wellness.ConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  confirmationPageDescription: {
    id: 'Wellness.ConfirmationPage.description',
    defaultMessage:
      'Congratulations, you have completed your weekly check-in. Please go to your app to view your results, manage your health or book an appointment.',
  },
  confirmationPageAppInfoHeading: {
    id: 'Wellness.ConfirmationPage.AppInfoHeading',
    defaultMessage: 'Have you got the app?',
  },
  confirmationPageAppInfoDescription: {
    id: 'Wellness.ConfirmationPage.AppInfoDescription',
    defaultMessage:
      'Download the app now and sign-up for appointments with GPs, nurses, pharmacists and physiotherapists, instant health information and a range of digital tools to help you manage your health 24/7.',
  },
  ineligiblePageHeading: {
    id: 'Wellness.IneligiblePage.pageTitle',
    defaultMessage: 'Unfortunately we cannot offer you this programme',
  },
  ineligiblePageDescription: {
    id: 'Wellness.IneligiblePage.description',
    defaultMessage: 'You need to be an eMed employee to access this programme.',
  },
  ineligiblePageCta: {
    id: 'Wellness.IneligiblePage.cta',
    defaultMessage: 'Switch plan',
  },
  checkoutConfirmationPageTitle: {
    id: 'Wellness.checkoutConfirmationPage.pageTitle',
    defaultMessage: 'Wellness - Payment Confirmation',
  },
  checkoutConfirmationPageHeading: {
    id: 'Wellness.checkoutConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  checkoutConfirmationPageDescription: {
    id: 'Wellness.checkoutConfirmationPage.description',
    defaultMessage:
      "Congratulations, you have completed your application and it's now been submitted.",
  },
  checkoutConfirmationPageAppInfoHeading: {
    id: 'Wellness.checkoutConfirmationPage.AppInfoHeading',
    defaultMessage: 'Have you got the app?',
  },
  checkoutConfirmationPageAppInfoDescription: {
    id: 'Wellness.checkoutConfirmationPage.AppInfoDescription',
    defaultMessage:
      'Download the app, and sign-in to book a Video Appointment 24/7, available at no cost through select health plans. Access our Chatbot and Healthcheck for free.',
  },
  dashboardPageTitle: {
    id: 'Wellness.Report.pageTitle',
    defaultMessage: 'Test Results - Dashboard',
  },
  dashboardPatientInfoSectionTitle: {
    id: 'Wellness.Report.patientInfo.sectionTitle',
    defaultMessage: 'Patient information',
  },
  dashboardPatientInfoName: {
    id: 'Wellness.Report.patientInfo.name',
    defaultMessage: 'Patient Name:',
  },
  dashboardPatientInfoDateOfBirth: {
    id: 'Wellness.Report.patientInfo.dob',
    defaultMessage: 'Date of birth:',
  },
  dashboardPatientInfoGeneratedOn: {
    id: 'Wellness.Report.patientInfo.generatedOn',
    defaultMessage: 'Generated on:',
  },
  dashboardPatientInfoTestId: {
    id: 'Wellness.Report.patientInfo.testId',
    defaultMessage: 'Test ID:',
  },
  dashboardPatientInfoSessionId: {
    id: 'Wellness.Report.patientInfo.sessionId',
    defaultMessage: 'Session ID:',
  },
  dashboardIndicatorsSectionTitle: {
    id: 'Wellness.Report.indicators.sectionTitle',
    defaultMessage: 'General overview',
  },
  dashboardIndicatorsNoData: {
    id: 'Wellness.Report.indicators.noData',
    defaultMessage: "You currently don't have any indicators to display here.",
  },
  dashboardVitalSignsSectionTitle: {
    id: 'Wellness.Report.vitalSigns.sectionTitle',
    defaultMessage: 'Vital signs',
  },
  dashboardVitalSignsNoData: {
    id: 'Wellness.Report.vitalSigns.noData',
    defaultMessage: "You currently don't have any indicators to display here.",
  },
  dashboardVitalSignsDisclaimer: {
    id: 'Wellness.Report.vitalSigns.disclaimer',
    defaultMessage:
      '✨ Some of these metrics were captured using AI tools.  They are an estimation and should not be used for medical diagnosis.  If you are concerned about a value displayed here, please seek further advice from a healthcare professional or by calling 111.  In an emergency please call 999.',
  },
  dashboardLabssSectionTitle: {
    id: 'Wellness.Report.labs.sectionTitle',
    defaultMessage: 'Blood test results',
  },
  dashboardLabsNoData: {
    id: 'Wellness.Report.labs.noData',
    defaultMessage: "You currently don't have any labs to display here.",
  },
  dashboardLabsReferenceRange: {
    id: 'Wellness.Report.labs.referenceRange',
    defaultMessage: 'Range:',
  },
})
