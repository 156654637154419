import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormatMessage } from '@babylon/intl'
import gql from 'graphql-tag'
import { snakeCase } from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { TrackingActionType } from '@babylon/tracking/react'
import useTracking from '@/tracking/useTracking'
import PageTitle from '@/components/PageTitle/PageTitle'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import UserQuery from '@/queries/Patient'
import Notifications from '../Notifications'
import PromotionInfoModal from '../Modal/PromotionInfoModal'
import AppsInfo from '../AppsInfo'
import { withUserId } from '@/wrappers'
import hasAcceptedNotice from '@/wrappers/FeatureNoticeWrapper'
import { displayFlashError as displayFlashErrorAction } from '@/redux/flash/actions'
import { resetSingleForm as resetSingleFormAction } from '@/redux/formErrors/actions'
import MissingFieldsModal from '@/components/Modal/MissingFieldsModal'
import { registrationPromoModalShown } from '@/components/Authentication/Register/actions'

import messages from './HomePage.messages'
import {
  useDownloadAppEnabled,
  useNotificationsEnabled,
  useRegisterPromocode,
  useRegisterShowPromotionModal,
  useDefaultRegion,
  useMissingProfileFields,
  useWelcomePageDiscoverSectionEnabled,
} from '@/redux/selectors'
import usePromotionInfoModal from '../Modal/usePromotionInfoModal'
import WelcomeBanner from './WelcomeBanner'
import DiscoverSection from './DiscoverSection'
import { isGPAtHandPreferredCN } from './WelcomeBanner/utils'

const enhance = compose(
  withUserId,
  hasAcceptedNotice,
  connect(
    ({ privacyAgreementNeeded }) => ({ privacyAgreementNeeded }),
    (dispatch) =>
      bindActionCreators(
        {
          displayFlashError: displayFlashErrorAction,
          resetSingleForm: resetSingleFormAction,
          setRegistrationPromoModalShown: registrationPromoModalShown,
        },
        dispatch
      )
  )
)

const notificationsEnabledQuery = gql`
  query User {
    user {
      notifications {
        isEnabled
      }
    }
  }
`

const HOME_PAGE_SCREEN_TITLE = 'home-page'

const Home = ({
  userId,
  isShowingPrivacyNotice,
  setRegistrationPromoModalShown,
}) => {
  const { trackView, trackEvent } = useTracking()

  React.useEffect(() => {
    trackView({ screenTitle: HOME_PAGE_SCREEN_TITLE })
  }, [trackView])
  const translate = useFormatMessage()

  const { loading: notificationsLoading } = useQuery(notificationsEnabledQuery)
  const { loading: userLoading, data: { patient } = {} } = useQuery(UserQuery, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  const missingProfileFields = useMissingProfileFields()
  const missingProfileFieldList = missingProfileFields
    ? Object.keys(missingProfileFields)
        .filter((field) => missingProfileFields[field])
        .map((field) => snakeCase(field))
    : []

  const [updatePatient] = useMutation(UpdatePatientMutation, {
    onCompleted: () =>
      trackEvent({
        eventCategory: 'missing field',
        eventAction: `added ${missingProfileFieldList.join(
          ','
        )} to the patient's profile`,
        actionType: TrackingActionType.pageLoaded,
        screenTitle: 'home-page',
      }),
  })

  const isDownloadAppEnabled = useDownloadAppEnabled()

  const isNotificationsEnabled = useNotificationsEnabled()

  const canShowMissingProfileFieldsModal =
    !userLoading &&
    !isShowingPrivacyNotice &&
    missingProfileFieldList.length > 0

  const isPromoCodeModalNeeded = useRegisterShowPromotionModal()
  const promoCode = useRegisterPromocode()
  const regionId = patient?.region?.id

  const {
    isPromoCodeModalVisible,
    togglePromoCodeModal,
    promotionInfo,
  } = usePromotionInfoModal(promoCode, regionId, !isPromoCodeModalNeeded)

  const appRegion = useDefaultRegion()

  const closePromoCodeModal = () => {
    setRegistrationPromoModalShown()
    togglePromoCodeModal(false)
  }

  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const isDiscoverSectionEnabled =
    useWelcomePageDiscoverSectionEnabled() && !isGPAtHandPreferredCN(patient)
  const NotificationSection = () =>
    isNotificationsEnabled && (
      <Notifications
        pageNumber={1}
        pageSize={3}
        title="Notifications"
        setHasUnreadNotifications={setHasUnreadNotifications}
      />
    )
  const DiscoverySection = () =>
    isDiscoverSectionEnabled && !notificationsLoading && <DiscoverSection />
  const sections = [
    <NotificationSection key="notifications" />,
    <DiscoverySection key="discovery" />,
  ]

  const showPromotionInfoModal =
    isPromoCodeModalVisible && promotionInfo && !isShowingPrivacyNotice

  return (
    <div className="Content--narrow">
      <PageTitle title={translate(messages.pageTitle)} />

      <WelcomeBanner user={patient} />

      {/* show DiscoverSection first unless there are unread notifications newer than a week */}
      {hasUnreadNotifications ? sections : sections.reverse()}

      {isDownloadAppEnabled && <AppsInfo region={appRegion} />}

      {showPromotionInfoModal && (
        <PromotionInfoModal
          onClose={() => closePromoCodeModal()}
          promotion={promotionInfo}
        />
      )}

      {canShowMissingProfileFieldsModal && (
        <MissingFieldsModal
          user={patient}
          checkFields={missingProfileFieldList}
          updatePatient={updatePatient}
          avoidClose
          checkPristine
        />
      )}
    </div>
  )
}

export default enhance(Home)
