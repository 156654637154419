/* eslint-disable react/jsx-props-no-spreading */
import 'focus-visible/dist/focus-visible'
import React, { MouseEvent } from 'react'

import { IMedkitComponent } from '..'

import { ButtonVariant } from './Button.types'
import { buttonTheme } from './theme'
import { ButtonSimple, ButtonReactRouterLink, ButtonLink } from '.'

declare interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  className?: string
  children?: React.ReactChild | React.ReactChild[]
  loading?: boolean
  disabled?: boolean
  variant?: ButtonVariant
  Element?: 'button' | 'a'
  fullWidth?: boolean
  halfWidth?: boolean
  href?: string
  to?: string
  onClick?: (e: MouseEvent) => void
  target?: string
  loadingLabel?: string
}

// eslint-disable-next-line camelcase
export const UNSAFE_Button: IMedkitComponent<
  IButtonProps,
  typeof buttonTheme
> = ({
  children,
  loading = false,
  className,
  disabled = false,
  variant = ButtonVariant.primary,
  onClick,
  type = 'button',
  fullWidth = false,
  halfWidth = false,
  to,
  href,
  target,
  ...props
}: IButtonProps) => {
  if (href) {
    // eslint-disable-next-line no-console
    console.warn(
      'Medkit Button has been deprecated. Replace your link which is styled like a button with ButtonLink.'
    )

    return (
      <ButtonLink
        href={href}
        target={target}
        className={className}
        variant={variant}
        fullWidth={fullWidth}
        halfWidth={halfWidth}
        {...props}
      >
        {children}
      </ButtonLink>
    )
  }

  if (to) {
    // eslint-disable-next-line no-console
    console.warn(
      'Medkit Button has been deprecated. Replace your button React Router <Link> button with <ButtonReactRouterLink />.'
    )

    return (
      <ButtonReactRouterLink
        to={to}
        className={className}
        variant={variant}
        fullWidth={fullWidth}
        halfWidth={halfWidth}
        {...props}
      >
        {children}
      </ButtonReactRouterLink>
    )
  }

  // eslint-disable-next-line no-console
  console.warn(
    'Medkit Button has been deprecated. Replace your button with the Medkit <ButtonSimple /> component.'
  )

  return (
    <ButtonSimple
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      halfWidth={halfWidth}
      isLoading={loading}
      {...props}
    >
      {children}
    </ButtonSimple>
  )
}

UNSAFE_Button.theme = buttonTheme

// eslint-disable-next-line camelcase
export default UNSAFE_Button
