import React, { FC } from 'react'
import { LinkButton } from '@babylon/medkit'
import { Back as BackIcon } from '@babylon/icons'
import styles from './BackLink.module.scss'

type Props = {
  title: string
  onClick: () => void
}

const Back: FC<Props> = ({ title, onClick }) => (
  <LinkButton className={styles.link} onClick={onClick}>
    <BackIcon />
    {title}
  </LinkButton>
)

export default Back
