import React, { cloneElement } from 'react'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import appStyles from '@/App.module.scss'
import withFeatureSwitches from '@/wrappers/withFeatureSwitches'
import WebViewMode from '@/utils/WebViewMode'

const AppLayout = ({ children, featureSwitches, featureSwitchesLoading }) => {
  const isWebViewMode = WebViewMode.isWebViewMode()

  return (
    <>
      {!isWebViewMode && <Header />}
      <main className={appStyles.view}>
        {cloneElement(children, { featureSwitches, featureSwitchesLoading })}
      </main>
      {!isWebViewMode && <Footer />}
    </>
  )
}

export default withFeatureSwitches(AppLayout)
