import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { TrackingActionType } from '@babylon/tracking/react'
import useTracking from '@/tracking/useTracking'

const useGoToBookAppointment = (screenTitle: string) => {
  const history = useHistory()
  const { trackEvent } = useTracking()
  const { pathname } = history.location

  return useCallback(() => {
    trackEvent({
      screenTitle,
      eventAction: 'book-appointment',
      actionType: TrackingActionType.click,
    })
    history.push('/consultations/new', { referrer: pathname })
  }, [history, pathname, screenTitle, trackEvent])
}

export default useGoToBookAppointment
