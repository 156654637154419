import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import appStyles from '@/App.module.scss'
import errorImage from '@/views/ErrorPage/error.svg'
import styles from './ErrorPage.module.scss'
import messages from './ErrorPage.messages'

const enhance = compose(injectIntl)

const ExpiredSessionErrorPage = ({ intl }) => (
  <main className={appStyles.view}>
    <div className={appStyles.view__inner}>
      <div className={styles.errorContainer}>
        <img src={errorImage} alt="" />
        <h1 className={styles.title}>
          {intl.formatMessage(messages.expiredSessionTitle)}
        </h1>
        <p>{intl.formatMessage(messages.message2)}</p>
      </div>
    </div>
  </main>
)

export default enhance(ExpiredSessionErrorPage)
