import React from 'react'
import { useIntl } from 'react-intl'
import { Text, List, EditorialLayout } from '@babylon/medkit'
import LandingHeader from '@/components/LandingHeader'
import appStyles from '@/App.module.scss'
import styles from './ConsultationErrorPage.module.scss'
import messages from './ConsultationErrorPage.messages'

const ConsultationErrorPage = () => {
  const intl = useIntl()

  return (
    <>
      <LandingHeader />
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <section className="Content--form">
            <EditorialLayout>
              <Text variant="h1" className="PageTitle">
                {intl.formatMessage(messages.pageTitle)}
              </Text>
              <Text tag="h2" variant="h4">
                {intl.formatMessage(messages.nextStepsTitle)}
              </Text>
              <List
                listItemClassName={styles.consultationErrorlistItem}
                listData={[
                  intl.formatMessage(messages.nextStepsFirstBulletPoint),
                  intl.formatMessage(messages.nextStepsSecondBulletPoint),
                ]}
                variant="ul"
              />
              <Text tag="h2" variant="h4">
                {intl.formatMessage(messages.problemPersistsTitle)}
              </Text>
              <Text variant="smallBody">
                {intl.formatMessage(messages.problemPersistsDescription)}
              </Text>
            </EditorialLayout>
          </section>
        </div>
      </main>
    </>
  )
}

export default ConsultationErrorPage
