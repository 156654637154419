import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import WeightLossWegovyImage from '@/assets/images/weight-loss_wegovy.jpg'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import styles from '../index.module.scss'
import messages from '../messages'

const cx = classNames.bind(styles)

const CheckInConfirmationPage = () => {
  const t = useFormatMessage()
  const history = useHistory()

  return (
    <Template title={t(messages.checkInConfirmationPageTitle)}>
      <h2>{t(messages.checkInConfirmationPageHeading)}</h2>
      <p>{t(messages.checkInConfirmationPageDescription)}</p>
      <img
        className={cx('check-in-startup-confirmation-image')}
        src={WeightLossWegovyImage}
        alt={t(messages.setupNextStepsPageImageDescription)}
      />
      <Button
        onClick={() => {
          history.push('/')
        }}
      >
        {t(messages.setupConfirmationPageCta)}
      </Button>
    </Template>
  )
}

export default CheckInConfirmationPage
