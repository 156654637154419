import { defineMessages } from 'react-intl'

export default defineMessages({
  postcodeFormButtonChangeAddress: {
    id: 'AddressPicker.postcodeForm.button.changeAddress',
    defaultMessage: 'Change address',
  },
  postcodeFormButtonAddAddress: {
    id: 'AddressPicker.postcodeForm.button.addAddress',
    defaultMessage: 'Add address',
  },
  postcodeFormButtonSelectAddress: {
    id: 'AddressPicker.postcodeForm.button.selectAddress',
    defaultMessage: 'Select your address',
  },
  postcodeFormButtonFindAddress: {
    id: 'AddressPicker.postcodeForm.cta.findAddress',
    defaultMessage: 'Find address',
  },
  postcodeFormChangeLink: {
    id: 'AddressPicker.postcodeForm.changePostcode',
    defaultMessage: 'Change postcode',
  },
  postcodeFormAddressRequired: {
    id: 'AddressPicker.postcodeForm.errors.addressRequired',
    defaultMessage: 'Full address is Required',
  },
})
