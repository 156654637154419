import React, { ChangeEvent, FC } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Link } from '@babylon/medkit'
import messages from './CollectEmail.messages'
import CheckboxOnly from '../Checkbox/CheckboxOnly'
import styles from './CollectEmail.module.scss'
import { EmailMarketingResponse } from '../../types'
import TextInputOnly from '../TextInput/TextInputOnly'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import Error from '@/programmes/components/FormV2/components/Error/Error'

export type CollectEmailProps = {
  id: string
  title: string
  marketingConsentState: EmailMarketingResponse
  body?: string | string[]
  error?: boolean
  onChange: (EmailMarketingResponse: EmailMarketingResponse) => void
}

const CollectEmail: FC<CollectEmailProps> = ({
  id,
  title,
  body,
  marketingConsentState: {
    marketingConsentGiven = false,
    validEmailSupplied = false,
    emailAddress = '',
  } = {},
  onChange,
  error,
}) => {
  const translate = useFormatMessage()
  const checkboxId = `checkbox-${id}`

  function handleConsentChange(evt: ChangeEvent<HTMLInputElement>) {
    onChange({
      marketingConsentGiven: evt.target.checked,
      validEmailSupplied: validEmailSupplied,
      emailAddress,
    })
  }

  function handleEmailChange(evt: ChangeEvent<HTMLInputElement>) {
    onChange({
      marketingConsentGiven,
      validEmailSupplied:
        evt.target.checkValidity() && Boolean(evt.target.value),
      emailAddress: evt.target.value,
    })
  }

  return (
    <div id={`question-${id}`}>
      <QuestionTitle content={title} type="header" />
      <QuestionBody body={body} />
      <div>
        <label className={styles.emailInput}>
          Email
          <TextInputOnly
            onChange={handleEmailChange}
            type="email"
            placeholder="Enter your email"
            value={emailAddress}
            required
            aria-invalid={error}
          />
        </label>
        {error && <Error>{translate(messages.invalidEmailError)}</Error>}
      </div>
      <div className={styles.marketingConsentInput}>
        <CheckboxOnly
          id={checkboxId}
          onChange={handleConsentChange}
          checked={marketingConsentGiven}
          tabIndex={0}
        />
        <label htmlFor={checkboxId}>{translate(messages.checkboxLabel)}</label>
      </div>
      <div className={styles.privacyPolicy}>
        {translate(messages.privacyText, {
          privacyPolicyLink: (
            <Link href="/privacy" blank>
              {translate(messages.privacyPolicyLink)}
            </Link>
          ),
        })}
      </div>
    </div>
  )
}

export default CollectEmail
