import React from 'react'
import { compose } from 'recompose'
import { injectIntl, IntlShape } from 'react-intl'
import { Chat, User } from '@babylon/icons'

import List from '../../List'
import ArrowListItem from '../../ArrowListItem'

import messages from '../Navigation/Navigation.messages'
import styles from './HelpMenu.module.scss'
import { useAuthenticatedSnowEntryPointEnabled } from '@/redux/selectors'

const enhance = compose<any, UserMenuProps>(injectIntl)

// /**
//  *
//  * @type {React.FC<{
//  *  intl: import('react-intl').InjectedIntl;
//  *  hideHelpMenu: () => void;
//  * }>}
//  */

interface UserMenuProps {
  intl: IntlShape
  hideHelpMenu: () => void
}

const HelpMenu = ({ intl, hideHelpMenu }: UserMenuProps) => {
  const isServiceNowChatEnabled = useAuthenticatedSnowEntryPointEnabled()

  return (
    <div id="userMenu" className={styles.userMenu}>
      <List>
        <ArrowListItem
          handle="supportCenter"
          text={intl.formatMessage(messages.supportCenter)}
          href="https://support.babylonhealth.com/cspbabylonsupport"
          icon={<User aria-hidden />}
          onClick={hideHelpMenu}
          noArrow
          blank
        />
        {isServiceNowChatEnabled && (
          <ArrowListItem
            handle="supportChat"
            text={intl.formatMessage(messages.supportChat)}
            to="/chat-support"
            icon={<Chat aria-hidden />}
            onClick={hideHelpMenu}
            noArrow
          />
        )}
      </List>
    </div>
  )
}

export default enhance(HelpMenu)
