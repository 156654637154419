import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'Profile.pageTitle',
    defaultMessage: 'Personal Details',
  },
  bio: {
    id: 'Profile.heading.bio',
    defaultMessage: 'Your bio',
  },
  contact: {
    id: 'Profile.heading.contact',
    defaultMessage: 'Contact',
  },
  address: {
    id: 'Profile.heading.address',
    defaultMessage: 'Address',
  },
  additionalInfo: {
    id: 'Profile.heading.info',
    defaultMessage: 'Additional Info',
  },
  firstNameLabel: {
    id: 'Profile.label.firstName',
    defaultMessage: 'First name',
  },
  surnameLabel: {
    id: 'Profile.label.surname',
    defaultMessage: 'Last name',
  },
  dateOfBirthLabel: {
    id: 'Profile.label.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  dayLabel: {
    id: 'Profile.label.day',
    defaultMessage: 'Day',
  },
  monthLabel: {
    id: 'Profile.label.month',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'Profile.label.year',
    defaultMessage: 'Year',
  },
  weightLabel: {
    id: 'Profile.label.weight',
    defaultMessage: 'Weight (kg)',
  },
  heightLabel: {
    id: 'Profile.label.height',
    defaultMessage: 'Height (cm)',
  },
  genderLabel: {
    id: 'Profile.label.gender',
    defaultMessage: 'Gender',
  },
  genderMale: {
    id: 'Profile.label.gender.male',
    defaultMessage: 'Male',
  },
  genderFemale: {
    id: 'Profile.label.gender.female',
    defaultMessage: 'Female',
  },
  smokingLabel: {
    id: 'Profile.label.smokingStatus',
    defaultMessage: 'Do you smoke?',
  },
  smokingPlaceholder: {
    id: 'Profile.label.smokingStatus.placeholder',
    defaultMessage: 'Do you smoke?',
  },
  smokingSmoker: {
    id: 'Profile.label.smokingStatus.smoker',
    defaultMessage: 'Smoker',
  },
  smokingNonSmoker: {
    id: 'Profile.label.smokingStatus.nonSmoker',
    defaultMessage: 'Non-Smoker',
  },
  smokingExSmoker: {
    id: 'Profile.label.smokingStatus.exSmoker',
    defaultMessage: 'Ex-Smoker',
  },
  emailLabel: {
    id: 'Profile.label.email',
    defaultMessage: 'Email',
  },
  passwordLabel: {
    id: 'Profile.label.passwordLabel',
    defaultMessage: 'Create Password',
  },
  phoneLabel: {
    id: 'Profile.label.phone',
    defaultMessage: 'Phone',
  },
  phoneEmergencyTip: {
    id: 'Profile.emergencyTip',
    defaultMessage:
      'Please enter a mobile number so we can contact you during an emergency',
  },
  postcodeLabel: {
    id: 'Profile.label.postcode',
    defaultMessage: 'Postcode',
  },
  homePostcodeLabel: {
    id: 'Profile.label.homePostcode',
    defaultMessage: 'Home postcode',
  },
  selectYourAddress: {
    id: 'Profile.selectYourAddress',
    defaultMessage: 'Please select your address',
  },
  streetLabel: {
    id: 'Profile.label.street',
    defaultMessage: 'First Line of Address',
  },
  townCityLabel: {
    id: 'Profile.label.townCity',
    defaultMessage: 'Town/City',
  },
  countyLabel: {
    id: 'Profile.label.county',
    defaultMessage: 'County',
  },
  find: {
    id: 'Profile.label.find',
    defaultMessage: 'Find',
  },
  enterManually: {
    id: 'Profile.label.enterManually',
    defaultMessage: 'Enter Address Manually',
  },
  noAddressesFound: {
    id: 'Profile.noAddressesFound',
    defaultMessage:
      'We couldn’t find anything for that postcode, please try again',
  },
  noUserData: {
    id: 'Profile.noUserData',
    defaultMessage: 'Profile not found',
  },
  save: {
    id: 'Profile.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Profile.cancel',
    defaultMessage: 'Cancel',
  },
  saving: {
    id: 'Profile.saving',
    defaultMessage: 'Saving...',
  },
  success: {
    id: 'Profile.success',
    defaultMessage: 'Your details have been saved',
  },
  previousPostcode: {
    id: 'Profile.previousPostcode',
    defaultMessage: 'Previous postcode',
  },
  publicHealthcareIdentifier: {
    id: 'Profile.publicHealthcareIdentifier',
    defaultMessage: 'NHS Number',
  },
})
