import React from 'react'
import styles from './SimpleInterstitial.module.scss'
import { Interstitial } from '@/programmes/components/FormV2/types'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import Avatar, {
  AvatarProps,
} from '@/programmes/components/FormV2/components/Avatar/Avatar'

type Props = {
  interstitial: Interstitial
  title?: string
  avatar?: AvatarProps
  header?: string
  body?: string | string[]
}

const SimpleInterstitial = ({
  interstitial,
  title,
  avatar,
  header,
  body,
}: Props) => {
  const { id } = interstitial

  return (
    <div className={styles.container}>
      {avatar && <Avatar {...avatar} />}
      {title && <QuestionTitle content={title} id={id} type="header" />}
      {body && <QuestionBody header={header} body={body} id={id} />}
    </div>
  )
}

export default SimpleInterstitial
