import { get, CookieAttributes } from 'js-cookie'

interface IHeaderCookieAttributes extends CookieAttributes {
  csrf_token?: string
}

interface IGetLoginCookie {
  (cookieLoginInfo: string): IHeaderCookieAttributes
}

const getLoginCookie: IGetLoginCookie = (cookieLoginInfo) => {
  try {
    return JSON.parse(get(cookieLoginInfo) || '{}')
  } catch (e) {
    return {}
  }
}

export default getLoginCookie
