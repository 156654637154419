import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { Logout, User } from '@babylon/icons'

import List from '../../List'
import ArrowListItem from '../../ArrowListItem'
import { logout as logoutAction } from '../../Authentication/actions'

import messages from '../Navigation/Navigation.messages'
import styles from './UserMenu.module.scss'

const enhance = compose(injectIntl)

/**
 *
 * @type {React.FC<{
 *  intl: import('react-intl').InjectedIntl;
 *  hideAccountMenu: () => void;
 *  userId: string;
 * }>}
 */
const UserMenu = ({ intl, hideAccountMenu, userId }) => {
  const dispatch = useDispatch()
  const logoutRedirectPath = useSelector(
    (state) => state.config?.logoutRedirectPath ?? '/'
  )
  const logout = useCallback(
    () => dispatch(logoutAction({ redirectPath: logoutRedirectPath })),
    [dispatch, logoutRedirectPath]
  )

  return (
    <div id="userMenu" className={styles.userMenu}>
      <List>
        <ArrowListItem
          handle="userMenuAccount"
          text={intl.formatMessage(messages.account)}
          to={userId ? `/account/${userId}` : '/account'}
          icon={<User aria-hidden />}
          onClick={hideAccountMenu}
          noArrow
        />
        <ArrowListItem
          handle="userMenuLogout"
          text={intl.formatMessage(messages.logout)}
          to="/logout"
          icon={<Logout aria-hidden />}
          onClick={logout}
          noArrow
        />
      </List>
    </div>
  )
}

export default enhance(UserMenu)
