const STORAGE_KEY = 'babylon-tracking-decision'

const shouldDisplayCookieNotice = (): boolean => {
  const decision = getTrackingPreference()

  return !decision
}

const getTrackingPreference = (): string | null => {
  if (
    window &&
    window.localStorage &&
    typeof window.localStorage.getItem === 'function'
  ) {
    return window.localStorage.getItem(STORAGE_KEY)
  }

  return null
}

const hasAcceptedNonEssentialTracking = (): boolean => {
  const decision = getTrackingPreference()

  return Boolean(decision && decision === 'true')
}

const storeTrackingPreference = (accepted: boolean) => {
  if (
    window &&
    window.localStorage &&
    typeof window.localStorage.setItem === 'function'
  ) {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(accepted))
  }
}

export {
  hasAcceptedNonEssentialTracking,
  shouldDisplayCookieNotice,
  storeTrackingPreference,
}
