import React from 'react'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'
import ChangePassword from '../ChangePassword'

const SetPassword = () => {
  const translate = useFormatMessage()

  return (
    <ChangePassword
      title={translate(messages.pageTitle)}
      successMessage={translate(messages.success)}
      submitMessage={translate(messages.createAccount)}
      description={translate(messages.pageDescription)}
    />
  )
}

export default SetPassword
