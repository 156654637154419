import { isValidDate } from '@babylon/medkit'
import {
  Answer,
  CheckBoxGroupValue,
  Height,
  EmailMarketingResponse,
  Measurements,
  Question,
  RadioGroupValue,
  Weight,
} from '../types'
import getHeightAndWeightAsMetric from '../../../utils/convertHeightAndWeight'
import {
  HEIGHT_VALID_RANGE_METRIC,
  WEIGHT_VALID_RANGE_METRIC,
} from '../constants'

export function validateWeight(answer: Weight) {
  const measurementAsMetric = getHeightAndWeightAsMetric(
    undefined,
    answer as Weight
  )

  if (!measurementAsMetric) {
    return false
  } else {
    return !(
      Number(measurementAsMetric.weight) < WEIGHT_VALID_RANGE_METRIC.min ||
      Number(measurementAsMetric.weight) > WEIGHT_VALID_RANGE_METRIC.max
    )
  }
}

export function validateHeight(answer: Height) {
  const measurementAsMetric = getHeightAndWeightAsMetric(
    answer as Height,
    undefined
  )

  if (!measurementAsMetric.height) {
    return false
  } else {
    return !(
      Number(measurementAsMetric.height) < HEIGHT_VALID_RANGE_METRIC.min ||
      Number(measurementAsMetric.height) > HEIGHT_VALID_RANGE_METRIC.max
    )
  }
}

const validateAnswer = (question: Question, answer: Answer): boolean => {
  if (!answer) {
    return false
  }

  switch (question.inputType.component) {
    case 'heightAndWeight': {
      if (
        !answer ||
        !(answer as Measurements).height ||
        !(answer as Measurements).weight
      ) {
        return false
      }

      return (
        validateHeight((answer as Measurements).height) &&
        validateWeight((answer as Measurements).weight)
      )
    }
    case 'collectEmail': {
      return !!(answer as EmailMarketingResponse).validEmailSupplied
    }
    case 'height': {
      return validateHeight(answer as Height)
    }
    case 'weight': {
      return validateWeight(answer as Weight)
    }
    case 'checkboxGroup': {
      if (!answer || (answer as Array<CheckBoxGroupValue>).length === 0) {
        return false
      }

      const emptyFollowUpQuestion = (answer as Array<CheckBoxGroupValue>).filter(
        (answer) =>
          question.options?.find(
            (option) =>
              option.value === answer.value &&
              option.followUpQuestion?.required &&
              !answer.followUpQuestionValue
          )
      )

      return emptyFollowUpQuestion.length <= 0
    }
    case 'radioGroup': {
      if (question.required && !answer) {
        return false
      }

      const option = question.options?.find(
        (option) => option.value === (answer as RadioGroupValue).value
      )

      return !(
        option?.followUpQuestion?.required &&
        !(answer as RadioGroupValue).followUpQuestionValue
      )
    }
    case 'date': {
      if (question.required && !answer) {
        return false
      }

      return isValidDate(answer as string)
    }

    default: {
      return !!answer
    }
  }
}

export default validateAnswer
