import React, { FC } from 'react'
import { Text } from '../..'
import styles from './index.module.scss'

interface IModalContentProps extends React.HTMLProps<HTMLDivElement> {
  /** A list of buttons to render */
  buttons?: React.ReactChild[]
  /** Make CTA buttons sticky */
  stickyButtons?: boolean
  /** The content of the component */
  children: any
  /** The title of the modal */
  title?: string
}

export const ModalContent: FC<IModalContentProps> = ({
  buttons,
  children,
  title,
  stickyButtons,
}) => (
  <div className={styles.ModalContent}>
    {title && (
      <Text
        id="modal-title"
        data-testid="modalTitle"
        className={styles.ModalContentTitle}
        variant="h1"
        tag="h1"
      >
        {title}
      </Text>
    )}

    <div
      id="modal-content"
      data-testid="modalContent"
      className={styles.ModalContentBody}
    >
      {children}
    </div>

    {buttons && (
      <div
        data-testid="modalButtons"
        className={`${styles.ModalContentButtons}${
          stickyButtons ? ` ${styles.stickyButtons}` : ''
        }`}
      >
        {buttons}
      </div>
    )}
  </div>
)

export default ModalContent
