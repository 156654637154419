import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { graphql, withApollo } from '@apollo/react-hoc'
import { compose } from 'recompose'
import { logException } from '@babylon/sentry'

import debugLog from '@/utils/debugLog'
import upcomingAppointmentNotificationQuery from '@/queries/UpcomingAppointmentNotification'
import { ReportCallStep, SendCallStats } from '@/mutations/MultimediaMetrics'
import withUserId from '@/wrappers/withUserId'
import { OPENTOK_API_KEY } from '@/config'
import {
  showIncomingCall,
  hideIncomingCall,
  videoCallStarted,
  videoCallEnded,
  videoCallDropped,
} from './actions'
import { parseCallEvent, parseCallMetrics } from './utils'
import VideoCallPortal from './components/VideoCallPortal'
import CallEndFeedbackModal from './components/CallEndFeedbackModal'
import ringtone from '@/assets/audio/ringtone.mp3'

import ISODateString from '@/utils/rfc-3339-date'

import styles from './VideoCall.module.scss'

const enhance = compose(
  withApollo,
  injectIntl,
  withUserId,
  connect(null, (dispatch) => ({
    showIncomingCall: () => dispatch(showIncomingCall()),
    hideIncomingCall: () => dispatch(hideIncomingCall()),
    videoCallStarted: () => dispatch(videoCallStarted()),
    videoCallEnded: () => dispatch(videoCallEnded()),
    videoCallDropped: () => dispatch(videoCallDropped()),
  })),
  graphql(upcomingAppointmentNotificationQuery, {
    name: 'upcomingAppointmentNotificationData',
    options: ({ userId }) => ({
      ...(process.env.NODE_ENV !== 'development' &&
        !window.Cypress && { pollInterval: 60000 }), // 60 seconds
      fetchPolicy: 'cache-and-network',
      variables: { id: userId },
    }),
  }),
  graphql(SendCallStats, {
    name: 'sendCallStats',
  }),
  graphql(ReportCallStep, {
    name: 'reportCallStep',
  })
)

class VideoCall extends Component {
  constructor() {
    super()
    this.state = {
      connecting: false,
      audioEnabled: true,
      videoEnabled: true,
      showFeedbackModal: false,
      isAccessDialogOpened: false,
      isMediaAccessGiven: null,
    }
    this.session = null
    this.token = null
    this.callMetricsQueue = []
    this.callEventsQueue = []
    this.audioRingtone = new Audio(ringtone)
    this.audioRingtone.loop = true
    this.eventHanders = {}
  }

  componentDidUpdate(prevProps) {
    const { connecting } = this.state

    const {
      upcomingAppointmentNotificationData: {
        upcomingAppointmentNotification: prevAppointment,
      } = { upcomingAppointmentNotification: undefined },
    } = prevProps

    const {
      upcomingAppointmentNotificationData: {
        upcomingAppointmentNotification: appointment,
      } = { upcomingAppointmentNotification: undefined },
    } = this.props

    if (
      this.session &&
      prevAppointment?.appointmentId &&
      appointment?.appointmentId !== prevAppointment?.appointmentId
    ) {
      this.session.disconnect()
      this.subscribeToStream(appointment)
    } else if (
      appointment?.appointmentId &&
      !connecting &&
      !this.isConnected()
    ) {
      this.subscribeToStream(appointment)
    }
  }

  componentWillUnmount() {
    Object.keys(this.eventHanders).forEach((eventName) => {
      if (this.eventHanders[eventName]) {
        this.session.off(eventName, this.eventHanders[eventName])
      }
    })
  }

  isConnected = () => this.session?.currentState === 'connected'

  callStep = async (consultationId, step) => {
    const { reportCallStep } = this.props

    try {
      await reportCallStep({
        variables: { consultationId: String(consultationId), step },
      })
    } catch (e) {
      logException(e)
    }
  }

  subscribeToStream = async (appointment) => {
    const {
      appointmentId,
      session: sessionKey,
      unique_token: token,
      opentok_key: opentokApiKey,
    } = appointment

    if (!sessionKey || !token) {
      return
    }

    this.token = token
    this.setState({ connecting: true })
    const opentok = await import(
      /* webpackChunkName: "opentok" */ '@opentok/client'
    )

    this.session = opentok.initSession(
      opentokApiKey || OPENTOK_API_KEY,
      sessionKey
    )

    this.eventHanders = {
      sessionConnected: this.session.on('sessionConnected', (e) =>
        this.pushCallEvents(e)
      ),
      sessionDisconnected: this.session.on('sessionDisconnected', (e) =>
        this.pushCallEvents(e)
      ),
      streamCreated: this.session.on('streamCreated', (e) => {
        this.props.showIncomingCall()
        this.pushCallEvents(e)
        this.setState(
          {
            connecting: false,
            appointmentId,
          },
          () => {
            this.callStep(appointmentId, 'notification_received')
          }
        )

        // Safari doesn't allow autoplay audio files until user accepts permissions the first time.
        // If permission is already given, the browser callback runs in runtime after this part
        // The timeout put this process at the back of the runtime queue solving the callback issue
        setTimeout(() => {
          // The catch is needed because Safari disable the autoplay by default and it throws an error crashing the app
          this.audioRingtone.play().catch((error) => debugLog(error, 'warn'))
        }, 500)

        this.stream = e.stream
      }),
      streamDestroyed: this.session.on('streamDestroyed', (e) => {
        this.dropSession()
        this.pushCallEvents(e)
      }),
    }

    // Connecting again to the session in case user or clinician dropped the call by mistake
    this.connectSession()
  }

  connectSession = () => {
    this.session.connect(this.token, (error) => {
      if (error) {
        // TODO: show error to user
        logException(error)
      }

      this.setState({ connecting: false })
    })
  }

  answerCall = async () => {
    this.audioRingtone.pause()
    const { appointmentId } = this.state
    const { videoCallStarted } = this.props
    const { callStep } = this
    callStep(appointmentId, 'notification_accepted')
    videoCallStarted()

    if (!document.getElementById('consultantVideo')) {
      const consultantVideo = document.createElement('div')
      consultantVideo.id = 'consultantVideo'
      consultantVideo.className = styles.consultantVideo
      document.getElementById('callContainer').appendChild(consultantVideo)
    }

    if (!document.getElementById('patientVideo')) {
      const patientVideo = document.createElement('div')
      patientVideo.id = 'patientVideo'
      patientVideo.className = styles.patientVideo
      document.getElementById('callContainer').appendChild(patientVideo)
    }

    this.subscriber = this.session.subscribe(this.stream, 'consultantVideo', {
      insertMode: 'replace',
      style: {
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
      },
    })

    const opentok = await import(
      /* webpackChunkName: "opentok" */ '@opentok/client'
    )
    this.publisher = opentok.initPublisher('patientVideo', {
      insertMode: 'replace',
      style: {
        archiveStatusDisplayMode: 'off',
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
      },
    })

    this.session.publish(this.publisher)
    this.subscriber.on('videoEnabled', this.pushCallEvents)
    this.subscriber.on('videoDisabled', this.pushCallEvents)
    this.subscriber.on('videoDisableWarning', this.pushCallEvents)
    this.subscriber.on('videoDisableWarningLifted', this.pushCallEvents)

    this.publisher.on({
      // The Allow/Deny dialog box is opened.
      accessDialogOpened: () => {
        this.setState({ isAccessDialogOpened: true })
      },
      accessAllowed: () => {
        this.setState({ isMediaAccessGiven: true })
      },
      // The user has denied access to the camera and mic.
      accessDenied: () => {
        this.setState({ isMediaAccessGiven: false })
      },
    })

    this.callMetricInterval = setInterval(() => {
      this.publisher.getStats((error, stats) => {
        if (!error) {
          this.pushCallMetrics(stats, 'publisher')
        }

        this.subscriber.getStats((error, stats) => {
          if (!error) {
            this.pushCallMetrics([{ stats }], 'subscriber')
          }

          this.pushCallData()
        })
      })
    }, 5000)
  }

  declineCall = () => {
    this.audioRingtone.pause()
    this.props.hideIncomingCall()

    if (this.session) {
      this.session.disconnect()
    }

    // Connecting again to the session in case user or clinician dropped the call by mistake
    this.connectSession()
  }

  closeFeedbackModal = () => {
    this.setState({
      showFeedbackModal: false,
    })
  }

  dropSession = () => {
    this.props.videoCallDropped()

    this.setState({
      showFeedbackModal: true,
    })

    if (this.publisher) {
      this.session.unpublish(this.publisher)
    }

    this.session.disconnect()
    this.audioRingtone.pause()
    this.connectSession()
  }

  hangUp = () => {
    const { pushCallData } = this

    this.dropSession()

    if (this.subscriber) {
      this.session.unsubscribe(this.subscriber)
    }

    clearInterval(this.callMetricInterval)

    pushCallData() // Push one last time for remaining data.
  }

  pushCallEvents = (event) => {
    const uniformEvent = parseCallEvent(event)
    this.callEventsQueue.push(uniformEvent)
  }

  pushCallMetrics = (metrics, role) => {
    const parsedMetrics = parseCallMetrics(metrics, role)
    this.callMetricsQueue = [...this.callMetricsQueue, ...parsedMetrics]
  }

  pushCallData = async () => {
    if (
      this.callMetricsQueue.length === 0 &&
      this.callEventsQueue.length === 0
    ) {
      return
    }

    const {
      sendCallStats,
      userId,
      upcomingAppointmentNotificationData: {
        upcomingAppointmentNotification: appointment = {},
      },
    } = this.props

    const { appointmentId, session } = appointment

    // the API crashes if appointmentId is not valid
    if (appointmentId && session) {
      const payload = {
        time: ISODateString(new Date()),
        consultation_id: appointmentId.toString(),
        client_type: 'member',
        client_id: userId,
        open_tok_session_id: session,
        open_tok_connection_metrics: [...this.callMetricsQueue],
        events: [...this.callEventsQueue],
      }

      this.callMetricsQueue = []
      this.callEventsQueue = []

      sendCallStats({ variables: { payload } })
    }
  }

  toggleVideo = () => {
    this.publisher.publishVideo(!this.state.videoEnabled)
    this.setState({
      videoEnabled: !this.state.videoEnabled,
    })
  }

  toggleAudio = () => {
    const { audioEnabled } = this.state
    this.publisher.publishAudio(!audioEnabled)
    this.setState({
      audioEnabled: !audioEnabled,
    })
  }

  /* TO DO: [Refactor] [CW-1341] Reduce complexity to align with new linting rules */
  /* eslint-disable complexity */
  render() {
    const {
      upcomingAppointmentNotificationData: {
        upcomingAppointmentNotification = {},
        loading,
      },
    } = this.props

    const {
      videoEnabled,
      showFeedbackModal,
      audioEnabled,
      isAccessDialogOpened,
      isMediaAccessGiven,
      appointmentId,
    } = this.state

    if (showFeedbackModal) {
      return (
        <CallEndFeedbackModal
          appointmentId={appointmentId}
          closeFeedbackModal={this.closeFeedbackModal}
        />
      )
    }

    if (!this.isConnected() || (!upcomingAppointmentNotification && loading)) {
      return false
    }

    const consultant = {
      name: upcomingAppointmentNotification.consultantName,
      avatar: upcomingAppointmentNotification.consultanAvatar,
    }

    return (
      <VideoCallPortal
        isAccessDialogOpened={isAccessDialogOpened}
        isMediaAccessGiven={isMediaAccessGiven}
        consultant={consultant}
        answerCall={this.answerCall}
        declineCall={this.declineCall}
        hangUp={this.hangUp}
        setState={this.setState}
        toggleVideo={this.toggleVideo}
        toggleAudio={this.toggleAudio}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
      />
    )
  }
  /* eslint-enable */
}

VideoCall.propTypes = {
  upcomingAppointmentNotificationData: PropTypes.object.isRequired,
}

export default enhance(VideoCall)
