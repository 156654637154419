import React, { useEffect } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/react-hoc'
import { injectIntl } from 'react-intl'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { NotificationFilled } from '@babylon/icons'
import { Pagination } from '@babylon/medkit'
import { excludedNotificationTypesFilters } from '@/config/constants'
import markNotificationAsRead from '@/mutations/MarkNotificationAsRead'
import NotificationsHeader from './NotificationsHeader'
import NotificationsList from './List'
import NoContent from '../NoContent'
import { withUserId } from '@/wrappers'
import messages from './Notifications.messages'
import { useTimezoneFeature } from '../../utils/timezoneHelper'

const getUnreadNotificationsNewerThanAWeek = (notifications) =>
  notifications
    .filter(({ isRead }) => !isRead)
    .filter(({ createdAtUTC }) => {
      const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000
      const lastWeek = new Date().getTime() - WEEK_IN_MS
      const isOlderThanAWeek = new Date(createdAtUTC).getTime() < lastWeek

      return !isOlderThanAWeek
    })

const notificationsQuery = gql`
  query User(
    $pageNumber: Int
    $pageSize: Int
    $excludeFilters: [[NotificationFilter]]
    $withTimezone: Boolean
  ) {
    user {
      notifications(
        pageNumber: $pageNumber
        pageSize: $pageSize
        excludeFilters: $excludeFilters
        withTimezone: $withTimezone
      ) {
        items {
          id
          avatarUrl
          createdAtUTC
          date
          isRead
          itemId
          itemType
          message
        }
        paginationDetails {
          hasMultiplePages
          nextPage
          numberOfPages
          pageNumber
          previousPage
        }
      }
    }
  }
`

const enhance = compose(
  injectIntl,
  withUserId,
  graphql(markNotificationAsRead, {
    props: ({ ownProps: { userId, pageNumber, pageSize }, mutate }) => ({
      markAsRead: (id) => {
        mutate({
          variables: { notificationId: id, id: userId },
          refetchQueries: [
            {
              query: notificationsQuery,
              variables: {
                userId,
                pageNumber,
                pageSize,
                excludeFilters: excludedNotificationTypesFilters,
              },
            },
          ],
        })
      },
    }),
  })
)

const Notifications = ({
  pageNumber: requestedPageNumber = 1,
  markAsRead,
  showPagination,
  title,
  intl: { formatMessage },
  pageSize,
  setHasUnreadNotifications,
}) => {
  const [isTimezoneEnabled] = useTimezoneFeature()
  const { data, loading, error } = useQuery(notificationsQuery, {
    ...(!window.Cypress && { pollInterval: 60000 }), // 60 seconds
    variables: {
      pageNumber: requestedPageNumber,
      pageSize,
      excludeFilters: excludedNotificationTypesFilters,
      withTimezone: isTimezoneEnabled,
    },
  })

  useEffect(() => {
    const notifications = data?.user?.notifications?.items || []

    if (getUnreadNotificationsNewerThanAWeek(notifications).length > 0) {
      setHasUnreadNotifications && setHasUnreadNotifications(true)
    }
  }, [data, setHasUnreadNotifications])

  if (error) {
    return null
  }

  if (loading) {
    return (
      <>
        <NotificationsHeader loading={loading} />
        <NotificationsList loading={loading} />
      </>
    )
  }

  const {
    user: { notifications },
  } = data

  if (!notifications?.items) {
    if (!showPagination) {
      return null
    }

    return (
      <NoContent
        icon={NotificationFilled}
        title={formatMessage(messages.noNotifications)}
        subtitle={formatMessage(messages.reminder)}
      />
    )
  }

  const {
    hasMultiplePages,
    nextPage,
    pageNumber,
    previousPage,
    numberOfPages,
  } = notifications.paginationDetails || {}
  const isPaginationVisible = showPagination && hasMultiplePages
  const notificationsURL = '/notifications'
  const showViewAll = !showPagination && hasMultiplePages

  return (
    <>
      <NotificationsHeader
        notifications={notifications}
        showViewAll={showViewAll}
        title={title}
      />
      <NotificationsList
        notifications={notifications}
        markAsRead={markAsRead}
      />

      {isPaginationVisible && (
        <Pagination
          currentPage={pageNumber}
          pageTotal={numberOfPages}
          title={title}
          paginationLabel={formatMessage(messages.paginationLabel, {
            currentPage: pageNumber,
            pageTotal: numberOfPages,
          })}
          nextLink={nextPage && `${notificationsURL}/${nextPage}`}
          nextButtonText={formatMessage(messages.paginationNext)}
          nextButtonAccessibleText={formatMessage(
            messages.paginationNextAccessible
          )}
          previousLink={previousPage && `${notificationsURL}/${previousPage}`}
          previousButtonText={formatMessage(messages.paginationBack)}
          previousButtonAccessibleText={formatMessage(
            messages.paginationBackAccessible
          )}
        />
      )}
    </>
  )
}

Notifications.defaultProps = {
  showPagination: false,
  title: '',
  pageNumber: undefined,
}

Notifications.propTypes = {
  markAsRead: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  showPagination: PropTypes.bool,
  title: PropTypes.string,
}

export default enhance(Notifications)
