import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Alert as AlertComponent } from '@babylon/medkit'
import { CloseCircleFilled, InfoFilled, TickCircleFilled } from '@babylon/icons'

import FlashMessage from './FlashMessage'
import { resetFlashMessage } from '@/redux/flash/actions'
import { Portal } from '../Modal'

import { feedbacks } from '@/redux/flash/constants'

// Time that the flash message will be active for, in ms
const RESET_FLASH_TIMER = 8000

const feedbackIcons = {
  [feedbacks.positive]: <TickCircleFilled />,
  [feedbacks.negative]: <CloseCircleFilled />,
  default: <InfoFilled />,
}

const Alert = ({ alert, resetFlashMessage, intl }) => {
  if (alert && alert.text) {
    setTimeout(() => resetFlashMessage(), RESET_FLASH_TIMER)
  } else {
    return false
  }

  let { text } = alert

  if (typeof alert.text !== 'string') {
    text = intl.formatMessage(alert.text)
  }

  const Icon = feedbackIcons[alert.style] || feedbackIcons.default

  return (
    <Portal enableScroll>
      <FlashMessage>
        <AlertComponent
          icon={Icon}
          type={alert.style}
          data-testid="formFeedbackAlert"
          data-e2e-feedback-type={alert.style}
        >
          {text}
        </AlertComponent>
      </FlashMessage>
    </Portal>
  )
}

const mapStateToProps = (state = {}) => ({
  alert: state.flash,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetFlashMessage }, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Alert))
