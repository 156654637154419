import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React, { PureComponent } from 'react'

import asFormControl from '../asFormControl'

import styles from './select.module.scss'

const cx = classNames.bind(styles)

class Select extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || props.value || '',
      placeholder: props.placeholder || 'Select an option',
    }
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.value !== this.state.value) {
      this.setState({ value: newProps.value })
    }
  }

  handleChange = (event) => {
    const { onChange } = this.props
    const val = event.target.value
    this.setState({ value: val })
    if (typeof onChange === 'function') onChange(val)
  }

  convertLabelToId = (label) => label.replace(' ', '-')

  render() {
    const { errors, success, label, name, options, disabled } = this.props
    const errorsClass = errors.length > 0 ? 'control--error' : ''
    const successClass = success ? 'control--success' : ''
    const forAttribute = label && this.convertLabelToId(label)

    /* eslint-disable jsx-a11y/no-onchange */
    return (
      <div className="control-wrapper">
        {label && (
          <label htmlFor={forAttribute} className={cx('label')}>
            {label}
          </label>
        )}
        <div className={cx('select-wrapper')}>
          <select
            id={forAttribute}
            name={name}
            className={cx(
              'control',
              'control--select',
              errorsClass,
              successClass
            )}
            onChange={this.handleChange}
            value={this.state.value}
            disabled={disabled}
          >
            <option disabled hidden value="">
              {this.state.placeholder}
            </option>
            {options.map(({ value, label: itemLabel, ...optionProps }) => (
              <option key={value} value={value} {...optionProps}>
                {itemLabel}
              </option>
            ))}
          </select>
          <span className={cx('select-wrapper__arrow')} />
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  /** The label of the select */
  label: PropTypes.string,
  /** The name of the select group */
  name: PropTypes.string,
  /** The select items to repeat over */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  /** The array of errors on the input */
  errors: PropTypes.arrayOf(PropTypes.string),
  /** The success message */
  success: PropTypes.string,
  /** The function to call when the value changes */
  onChange: PropTypes.func,
  /** The value to preselect */
  defaultValue: PropTypes.string,
  /** The value of the select*/
  value: PropTypes.string,
  /** The placeholder */
  placeholder: PropTypes.string,
}

Select.defaultProps = {
  label: '',
  name: '',
  options: [],
  errors: [],
  success: '',
  onChange: null,
  value: '',
  placeholder: '',
}

export default asFormControl(Select)
