import React from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'

const getWrapperElement = ({ action }) => {
  switch (typeof action) {
    case 'function':
      return 'button'
    case 'string':
      return 'a'
    default:
      return 'div'
  }
}

const getWrapperProps = ({ action, isExternal }) => {
  switch (typeof action) {
    case 'function':
      return { onClick: action }
    case 'string':
      return {
        href: action,
        target: isExternal ? '_blank' : null,
        rel: isExternal ? 'noopener noreferrer' : null,
      }
    default:
      return {}
  }
}

const enhance = withProps((props) => ({
  Wrapper: getWrapperElement(props),
  wrapperProps: getWrapperProps(props),
}))

const ActionWrapper = ({ className, Wrapper, wrapperProps, children }) => (
  <Wrapper {...wrapperProps} className={className}>
    {children}
  </Wrapper>
)

ActionWrapper.propTypes = {
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
}

export default enhance(ActionWrapper)
