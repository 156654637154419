import React, { useState } from 'react'
import { withProps } from 'recompose'
import classNames from 'classnames/bind'
import { SpecialistCircleFilled, UserCircleFilled } from '@babylon/icons'

import { getInitials } from '@/utils'
import useAuthenticatedImage from './useAuthenticatedImage'

import styles from './Avatar.module.scss'

const cx = classNames.bind(styles)

const FallbackText = ({ useInitials }) => (
  <span className={styles.avatar__text}>
    {getInitials(useInitials.firstName, useInitials.lastName)}
  </span>
)

const StandardImage = ({ src, desc }) => {
  // NOTE: avoids FOUC due to slow base64 image rendering
  const [showAltText, setShowAltText] = useState(false)

  return (
    <span className={styles.avatar__imgWrapper}>
      <img
        src={src}
        alt={showAltText ? desc : ''}
        className={styles.avatar__img}
        onLoad={() => setShowAltText(true)}
      />
    </span>
  )
}

const enhance = withProps(({ isSpecialist }) => ({
  FallbackImage: isSpecialist ? SpecialistCircleFilled : UserCircleFilled,
}))

const Avatar = ({
  FallbackImage,
  className,
  desc,
  disableFrame,
  icon,
  iconHighlight,
  paddingSize,
  size,
  useInitials,
  src,
}) => {
  const base64Image = useAuthenticatedImage(src)

  return (
    <div
      className={cx(
        styles.avatar,
        size && styles[`avatar--${size}`],
        paddingSize && styles[`avatar--${paddingSize}Space`],
        {
          'avatar--frameless': disableFrame,
        },
        className
      )}
    >
      {base64Image ? (
        <StandardImage src={base64Image} desc={desc} />
      ) : useInitials ? (
        <FallbackText useInitials={useInitials} />
      ) : (
        <FallbackImage title={desc} className={styles.avatar__default} />
      )}
      {icon && (
        <span
          className={cx(styles.avatar__iconWrapper, {
            'avatar__iconWrapper--highlight': iconHighlight,
          })}
        >
          {icon}
        </span>
      )}
    </div>
  )
}

export default enhance(Avatar)
