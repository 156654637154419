import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import React, { PureComponent } from 'react'

import asFormControl from '../asFormControl'

import styles from './radiogroup.module.scss'

const cx = classNames.bind(styles)

class Radiogroup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: props.selectedOption,
    }
  }

  handleChange = (event) => {
    const { onChange, options } = this.props
    const val = event.target.value
    const isDisabled = options.find((opt) => String(opt.value) === val).disabled

    if (!isDisabled) {
      this.setState({ selectedOption: val })

      if (typeof onChange === 'function') {
        onChange(val)
      }
    }
  }

  render() {
    const {
      className,
      label,
      name,
      options,
      pillSelect,
      value: propsValue,
      noOptionsPlaceholder,
      loading,
      mainColour,
      mainColourLight,
      'data-testid': testHandle,
    } = this.props
    const { selectedOption: stateValue } = this.state
    const selectedValue = propsValue || stateValue
    const variant = pillSelect ? 'pills' : this.props.variant

    const controlClass = variant || 'radio'
    const disabledClass = `${controlClass}--disabled`
    const inputClass = `${controlClass}__input`
    const labelClass = `${controlClass}__label`

    const controllScrollClass =
      variant !== 'radio' ? 'control-items-scroll' : null

    const showPlaceholder = !options.length && noOptionsPlaceholder

    let newOptions = options

    if (loading) {
      newOptions = [
        { value: 0, label: ' ' },
        { value: 1, label: ' ' },
      ]
    }

    const labelId = `${name}-label`

    return (
      <div
        className={cx('control-wrapper', className)}
        style={{
          '--main-colour': mainColour || styles.primary,
          '--main-colour-light': mainColourLight || styles.primaryLight,
        }}
      >
        {label && (
          <span className={cx('label')} id={labelId}>
            {label}
          </span>
        )}

        <div
          className={cx(controllScrollClass)}
          data-testid="radioGroup"
          role="radiogroup"
          aria-labelledby={labelId}
        >
          {newOptions.map(
            ({ value, label: itemLabel, disabled, ...wrapperProps }, index) => {
              const radioId = `${name}${value}`

              return (
                <div
                  data-testid="radioButton"
                  key={value}
                  className={cx(controlClass, {
                    [disabledClass]: disabled,
                  })}
                  {...wrapperProps}
                >
                  {!loading && (
                    <input
                      type="radio"
                      className={cx(inputClass)}
                      id={radioId}
                      name={name}
                      value={value}
                      checked={String(value) === selectedValue}
                      onChange={this.handleChange}
                      {...(disabled && { disabled: 'disabled' })}
                    />
                  )}
                  <label
                    aria-busy={loading}
                    htmlFor={radioId}
                    className={cx(labelClass, {
                      [`${labelClass}--loading`]: loading,
                    })}
                    data-testid={`${testHandle}-${index}`}
                  >
                    <span className={cx(`${controlClass}__labelContent`)}>
                      {itemLabel}
                    </span>
                  </label>
                </div>
              )
            }
          )}
          {showPlaceholder && <p>{noOptionsPlaceholder}</p>}
        </div>
      </div>
    )
  }
}

Radiogroup.propTypes = {
  /** CSS class name to apply to the outermost container */
  className: PropTypes.node,
  /** The label of the radio items */
  label: PropTypes.node,
  /** The name of the radio group */
  name: PropTypes.string,
  /** The radio items to repeat over */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  /** The function to call when the value changes */
  onChange: PropTypes.func,
  /** Preselected value */
  selectedOption: PropTypes.string,
  /** Controlled value */
  value: PropTypes.string,
  /** DEPRECATED: Use variant 'pills' */
  pillSelect: PropTypes.bool,
  /** The alternative visual style to use (if any) */
  variant: PropTypes.oneOf(['pills', 'pillsJoined', 'radio']),
  /** Show loading component */
  loading: PropTypes.bool,
  /* Name of e2e handle */
  'data-testid': PropTypes.string,
}

Radiogroup.defaultProps = {
  label: '',
  name: '',
  options: [],
  onChange: null,
  pillSelect: false,
  value: null,
  selectedOption: '',
}

export default asFormControl(Radiogroup)
