import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT, VERSION } from '@/config'
import { selectAppName } from '@/redux/selectors'
import calculateBMI from '@/programmes/utils/calculateBMI'
import { Height, MEASUREMENT_UNIT } from '@/programmes/components/Form/types'
import {
  RAG,
  WeightMetric,
  WeightMetricBMI,
  WeightMetrics,
} from '@/programmes/WeightLoss/types'
import {
  getBMIChangeRAG,
  getBMICategory,
} from '@/programmes/WeightLoss/utils/assessBMI'
import useGetCarePlan from '@/programmes/hooks/useGetCarePlan'

export const weightMetricsQuery = gql`
  query GetMetricAggregation(
    $metricIDs: [ID!]!
    $acceptLanguage: Language!
    $appVersion: ID!
    $timeZoneOffset: TimezoneId!
    $appName: String!
    $startDate: Date
    $endDate: Date!
    $type: MyHealthAggregationType!
    $grouping: MyHealthAggregationGrouping!
  ) {
    _myhealth_healthStatus {
      myHealthMetrics(
        ids: $metricIDs
        acceptLanguage: $acceptLanguage
        appVersion: $appVersion
        timeZoneOffset: $timeZoneOffset
        appName: $appName
      ) {
        id
        name
        descriptor {
          displayUnit
          unit
          unitPrecision
        }
        aggregation(
          start: $startDate
          end: $endDate
          aggregationType: $type
          aggregationGrouping: $grouping
        ) {
          datapoints {
            value {
              ... on MyHealthNumericValue {
                value
              }
            }
            endTime
          }
        }
      }
    }
  }
`

const getTotalWeightLost = (weightMetrics: WeightMetric[]) => {
  if (weightMetrics.length === 1) {
    return {
      value: 0,
      rag: RAG.NEUTRAL,
    }
  }

  const firstEntry = weightMetrics[weightMetrics.length - 1]
  const lastEntry = weightMetrics[0]
  const totalWeightLost = firstEntry.weight - lastEntry.weight

  return {
    value: Number(totalWeightLost.toFixed(2)),
    rag: getBMIChangeRAG(lastEntry.bmi?.current, firstEntry.bmi?.current),
  }
}

const getPreviousWeekWeightLoss = (weightMetrics: WeightMetric[]) => {
  if (weightMetrics.length === 1) {
    return {
      value: 0,
      rag: RAG.NEUTRAL,
    }
  }

  const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000
  const currentWeightMetric = weightMetrics[0]
  const previousWeekWeightMetric = weightMetrics.find(
    (metric) =>
      new Date(weightMetrics[0].dateISO || 0).getTime() -
        new Date(metric.dateISO || 0).getTime() >=
      weekInMilliseconds
  )

  const weightLoss = previousWeekWeightMetric
    ? (previousWeekWeightMetric?.weight - currentWeightMetric.weight).toFixed(2)
    : 0

  const ragStatus = getBMIChangeRAG(
    currentWeightMetric.bmi?.current,
    previousWeekWeightMetric?.bmi?.current
  )

  return {
    value: Number(weightLoss),
    rag: weightLoss === 0 ? RAG.NEUTRAL : ragStatus,
  }
}

const getBMI = (weight: number, previousWeight: number, height: Height) => {
  //The query we use always seems to return metric values

  const weightObject = {
    unit: MEASUREMENT_UNIT.METRIC,
    kg: `${weight}`,
    stones: '',
    pounds: '',
  }

  const previousWeightObject = {
    unit: MEASUREMENT_UNIT.METRIC,
    kg: `${previousWeight}`,
    stones: '',
    pounds: '',
  }

  const bmi = calculateBMI(height, weightObject)
  const previousBMI =
    previousWeight && calculateBMI(height, previousWeightObject)

  const current = {
    value: bmi,
    category: getBMICategory(bmi),
    rag: bmi && bmi < 25 ? RAG.GREEN : RAG.RED,
  } as WeightMetricBMI

  const previous = previousWeight
    ? ({
        value: previousBMI,
        category: getBMICategory(previousBMI),
        rag: previousBMI && previousBMI < 25 ? RAG.GREEN : RAG.RED,
      } as WeightMetricBMI)
    : undefined

  const changeRag = getBMIChangeRAG(current, previous)

  return {
    current,
    previous,
    changeRag,
  }
}

const getHeight = (data: any): Height | undefined => {
  // Return the most recent height datapoint
  const datapoints =
    data?._myhealth_healthStatus?.myHealthMetrics[1]?.aggregation?.datapoints

  if (!datapoints) {
    return undefined
  }

  const sortedDatapoints = datapoints.sort(
    (a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()
  )

  const height = sortedDatapoints[0]?.value.value

  if (!height) {
    return undefined
  }

  const heightObject = {
    unit: MEASUREMENT_UNIT.METRIC,
    cm: `${height * 100}`,
    feet: '',
    inches: '',
  }

  return heightObject
}

const parseWeightMetricsData = (data: any) => {
  const datapoints =
    data?._myhealth_healthStatus?.myHealthMetrics[0]?.aggregation?.datapoints

  if (!datapoints) {
    return undefined
  }

  const sortedDatapoints = datapoints.sort(
    (a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()
  )

  const height = getHeight(data)

  const weightMetrics: WeightMetric[] = sortedDatapoints.map(
    (item, index: number, array) => {
      const previousWeight =
        index < array.length - 1 ? array[index + 1].value.value : undefined

      return {
        dateFormated: new Date(item.endTime).toLocaleDateString('en-GB', {
          month: 'short',
          day: 'numeric',
        }),
        dateISO: item.endTime,
        weight: item.value.value,
        weightLoss: previousWeight
          ? Number((previousWeight - item.value.value).toFixed(2))
          : 0,
        bmi: height && getBMI(item.value.value, previousWeight, height),
      } as WeightMetric
    }
  )
  const currentWeight = weightMetrics[0].weight
  const unit: string =
    data?._myhealth_healthStatus?.myHealthMetrics[0]?.descriptor?.displayUnit

  return {
    weightMetrics,
    currentWeight,
    height,
    unit,
    totalWeightLost: getTotalWeightLost(weightMetrics),
    previousWeekWeightLoss: getPreviousWeekWeightLoss(weightMetrics),
  }
}

const useGetWeightMetrics = () => {
  const history = useHistory()
  const appName = useSelector(selectAppName)
  const [weightMetrics, setWeightMetrics] = useState<WeightMetrics | undefined>(
    undefined
  )
  const { data: carePlansData, loading: carePlansLoading } = useGetCarePlan()
  const carePlanStartDate = carePlansData?.created

  if (!carePlansLoading && !carePlanStartDate) {
    history.push('/')
  }

  const today = new Date()
  const endDate = `${today.toISOString().split('T')[0]}T23:59:59.999Z`

  const { data, loading: weightMetricsLoading } = useQuery(weightMetricsQuery, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    skip: !carePlanStartDate,
    variables: {
      metricIDs: ['weight', 'height'],
      startDate: carePlanStartDate,
      endDate,
      type: 'LATEST',
      grouping: 'DAILY',
      acceptLanguage: 'en-GB',
      appVersion: VERSION,
      timeZoneOffset: '+00:00',
      appName,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const parsedWeightMetrics = parseWeightMetricsData(data)

    if (parsedWeightMetrics) {
      setWeightMetrics(parsedWeightMetrics)
    }
  }, [data])

  return {
    loading: carePlansLoading || weightMetricsLoading,
    data: weightMetrics,
  }
}

export default useGetWeightMetrics
