export enum RouteId {
  BookBegin = 'bookBegin',
  BookSelectLocation = 'bookSelectLocation',
  BookSelectSlot = 'bookSelectSlot',
  BookReview = 'bookReview',
  BookSelectMedium = 'bookSelectMedium',
  BookSelectClinicianType = 'bookSelectClinicianType',
}

export type ValueOf<T> = T[keyof T]
