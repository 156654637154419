import React from 'react'
import styles from './SearchResult.module.scss'

type Props = {
  result: string
  onClick: () => void
}

const SearchResult = ({ result, onClick }: Props) => (
  <button className={styles.searchResult} type="button" onClick={onClick}>
    <p>{result}</p>
    <span>&gt;</span>
  </button>
)

export default SearchResult
