import React, { useEffect } from 'react'
import { Answer } from '../../types'
import QuestionBody from '../QuestionBody'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'

type RenderConfirmationProps = {
  id: string
  title: string
  body?: string | string[]
  handleAnswer: (id: string, answer: Answer) => void
}

const RenderConfirmation: React.FC<RenderConfirmationProps> = ({
  body,
  title,
  id,
  handleAnswer,
}) => {
  useEffect(() => {
    handleAnswer(id, 'yes')
  }, [handleAnswer, id])

  return (
    <div>
      <QuestionTitle content={title} type="header" />
      {body && <QuestionBody body={body} />}
    </div>
  )
}

export default RenderConfirmation
