import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'errorView.title',
    defaultMessage: 'Oops, something went wrong ',
  },
  body: {
    id: 'errorView.body',
    defaultMessage: 'Please select Retry or try again later',
  },
  retry: {
    id: 'errorView.retry',
    defaultMessage: 'Retry',
  },
})
