const ACCOUNT_ROOT = '/account'

const account = {
  id: 'account',
  route: `${ACCOUNT_ROOT}/:userId([0-9]+)?`,
  getUrl: ({ pageUserId = '' }) => `${ACCOUNT_ROOT}/${pageUserId}`,
  children: [
    {
      id: 'membership',
      route: `${ACCOUNT_ROOT}/:userId/membership/plans`,
      getUrl: ({ pageUserId }) =>
        `${ACCOUNT_ROOT}/${pageUserId}/membership/plans`,
      breadcrumb: {
        isWide: true,
      },
    },
    {
      id: 'membershipPayment',
      route: `${ACCOUNT_ROOT}/:userId/membership/plans/:planId/payment`,
      getRedirectUrl: ({ userId }) =>
        `${ACCOUNT_ROOT}/${userId}/membership/plans`,
      getUrl: ({ pageUserId, planId }) =>
        `${ACCOUNT_ROOT}/${pageUserId}/membership/plans/${planId}/payment`,
      breadcrumb: {
        isWide: true,
        path: ['membership'],
      },
    },
    {
      id: 'membershipUpgrade',
      route: `${ACCOUNT_ROOT}/:userId/membership/upgrade`,
      getUrl: ({ pageUserId }) =>
        `${ACCOUNT_ROOT}/${pageUserId}/membership/upgrade`,
      breadcrumb: {
        isWide: true,
        path: ['membership'],
      },
    },
    {
      id: 'payments',
      route: `${ACCOUNT_ROOT}/payments`,
      getUrl: () => `${ACCOUNT_ROOT}/payments`,
    },
    {
      id: 'paymentMethods',
      route: `${ACCOUNT_ROOT}/payment`,
      getUrl: () => `${ACCOUNT_ROOT}/payment`,
      breadcrumb: {
        path: ['payments'],
      },
      conditions: ['show_payment_details'],
    },
    {
      id: 'paymentHistory',
      route: `${ACCOUNT_ROOT}/payment/history`,
      getUrl: () => `${ACCOUNT_ROOT}/payment/history`,
      breadcrumb: {
        path: ['payments'],
      },
      conditions: ['show_billing_history'],
    },
    {
      id: 'paymentInsurance',
      route: `${ACCOUNT_ROOT}/insurance-details`,
      getUrl: () => `${ACCOUNT_ROOT}/insurance-details`,
      breadcrumb: {
        path: ['payments'],
      },
      conditions: ['show_insurance'],
    },
    {
      id: 'privacy',
      route: `${ACCOUNT_ROOT}/privacy`,
      getUrl: () => `${ACCOUNT_ROOT}/privacy`,
    },
    {
      id: 'family',
      route: `${ACCOUNT_ROOT}/family`,
      getUrl: () => `${ACCOUNT_ROOT}/family`,
      conditions: ['enable_family_accounts'],
    },
    {
      id: 'paymentSubscription',
      route: `${ACCOUNT_ROOT}/payments/subscription/:programme`,
      getUrl: ({ programme = '' }) =>
        `${ACCOUNT_ROOT}/payments/subscription/${programme}`,
    },
    {
      id: 'paymentSubscriptionDefault',
      route: `${ACCOUNT_ROOT}/payments/subscription`,
      getUrl: () => `${ACCOUNT_ROOT}/payments/subscription`,
    },
  ],
}

export default account
