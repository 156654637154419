import {
  CheckboxGroupInput,
  NumberInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
  DateInput,
  HeightAndWeightInput,
  HeightInput,
  WeightInput,
  DropdownInput,
  PulseInput,
  CheckboxInput,
  ConfirmationInput,
} from '@/programmes/components/Form/types/InputTypes'

export interface Question {
  id: string
  type: 'question'
  title: string
  body?: string | Array<string>
  footer?: string | Array<string>
  required?: boolean
  dataPoints?: DataPoints[]
  inputType: FormInputs
  options?: QuestionOption[]
  optionsRules?: Array<{
    label: string
    nextAction: string | 'complete-action' | 'exit-action'
  }>
  skipCondition?: { id: string; answer: string }
  next?: string | 'complete-action' | 'exit-action'
  // This is to allow for step.loginGate to be in react dependencies
  // without typescript complaining about it not being a property of Question
  loginGate?: undefined
  nextButtonText?: string
  callOut?: CallOut
}

//data point that does not require direct user input, it is set when the question is answered. e.g check box selected
type PredefinedDataPoint = {
  type: 'predefined'
  name: string
  value: string | boolean | number
}

// requires user input to populate the data point value. e.g age, weight and height
type InputDataPoint = {
  type: 'input'
  name: string
}

export type DataPoints = PredefinedDataPoint | InputDataPoint

export type DataPointsValue = {
  name: string
  value: string | boolean | number
}

export type CallOut = {
  title: string
  body: string
}

export interface Interstitial {
  id: string
  type: 'interstitial'
  title?: string
  body?: string | string[]
  next: string
  loginGate?: boolean
  nextButtonText?: undefined
  callOut?: CallOut
}

export type Step = Question | Interstitial
export type Measurements = { height: Height; weight: Weight }

export type QuestionOption = {
  label: string
  value: string
  followUpQuestion?: Question
  exclusive?: boolean
  dataPoints?: DataPoints[]
  extraLabel?: string
}

export type CheckBoxGroupValue = {
  value: string
  followUpQuestionValue?: Answer
}

export type RadioGroupValue = {
  value: string
  followUpQuestionValue?: Answer
}

export type FormInputs =
  | TextInput
  | NumberInput
  | TextAreaInput
  | CheckboxGroupInput
  | RadioGroupInput
  | DateInput
  | HeightAndWeightInput
  | HeightInput
  | WeightInput
  | PulseInput
  | CheckboxInput
  | DropdownInput
  | ConfirmationInput

export type Answer =
  | string
  | boolean
  | Measurements
  | Height
  | Weight
  | GPInfo
  | CheckBoxGroupValue[]
  | RadioGroupValue

export type GPInfo = {
  name: string
  address: string
  iDontHaveNhsGP: boolean
}

export enum MEASUREMENT_UNIT {
  METRIC,
  IMPERIAL,
}

export type Height = {
  unit: MEASUREMENT_UNIT
  cm: string
  feet: string
  inches: string
}

export type Weight = {
  unit: MEASUREMENT_UNIT
  kg: string
  stones: string
  pounds: string
}

export type HeightErrorMessage = {
  cm: string | undefined
  feet: string | undefined
  inches: string | undefined
}

export type WeightErrorMessage = {
  kg: string | undefined
  stones: string | undefined
  pounds: string | undefined
}

export type HeightAndWeightErrorMessage = {
  heightErrorMessage: HeightErrorMessage
  weightErrorMessage: WeightErrorMessage
}

export type FormErrorMessage =
  | string
  | HeightErrorMessage
  | WeightErrorMessage
  | HeightAndWeightErrorMessage
