import gql from 'graphql-tag'

export const questionnaireQuery = gql`
  query getHealthAssessmentQuestionnaireResponse($memberId: ID!) {
    patient(id: $memberId) {
      ... on Patient {
        questionnaireResponses(
          first: 1
          _filter: { status: { ONE_OF: [AMENDED, COMPLETED] } }
          _sort: { field: AUTHORED, order: DESC }
        ) {
          nodes {
            questionnaire {
              ... on Questionnaire {
                items(first: 100) {
                  nodes {
                    linkId
                    text
                  }
                }
              }
            }
            items(first: 100) {
              nodes {
                linkId
                answers(first: 100) {
                  nodes {
                    valueString
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const healthMetricsQuery = gql`
  query GetMetricAggregation(
    $acceptLanguage: Language!
    $appVersion: ID!
    $timeZoneOffset: TimezoneId!
    $appName: String!
    $member_uuid: ID!
    $metricIds: [ID!]!
  ) {
    _myhealth_healthStatus {
      myHealthMetrics(
        ids: $metricIds
        appName: $appName
        appVersion: $appVersion
        timeZoneOffset: $timeZoneOffset
        acceptLanguage: $acceptLanguage
        patientUuid: $member_uuid
      ) {
        id
        name
        descriptor {
          displayUnit
          unitPrecision
        }
        rag
        resultExplanation {
          overview
          recommendation
          summary
          title
        }
        about {
          title
          description
        }
        lastDatapoints(count: 1) {
          value {
            ... on MyHealthNumericValue {
              value
            }
            ... on MyHealthNumericWithLabelValue {
              label
              value
            }
            ... on MyHealthBloodPressureValue {
              diastolic
              systolic
            }
          }
          endTime
        }
      }
    }
  }
`
