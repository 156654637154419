import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Input } from '@babylon/ui'
import { useFormState } from '../hooks'
import styles from '../Address.module.scss'
import messages from '../../Form/Form.messages'
import { NewAddressFormat, Errors } from '../types'

interface Props {
  address: NewAddressFormat
  onAddressChange: (address: NewAddressFormat) => void
  errors: Errors
  isAddressLookupSupported?: boolean
  isGpAddress?: boolean
  forceManualAddress?: boolean
}

const AddressForm: React.FC<Props> = ({
  address,
  onAddressChange,
  errors,
  isAddressLookupSupported,
  isGpAddress,
  forceManualAddress,
}) => {
  const { first_line, town, county, postcode } = address
  const { inputs, handleInputChange } = useFormState()

  useEffect(() => {
    if (!isEmpty(inputs)) {
      onAddressChange(inputs)
    }
  }, [inputs]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {(!isAddressLookupSupported || forceManualAddress) && (
        <div className={styles.formRow}>
          <Input
            className={styles.addressPicker__input}
            name="postcode"
            label={<FormattedMessage {...messages.homePostcodeLabel} />}
            value={postcode}
            onChange={handleInputChange}
            errors={
              errors.address_post_code ||
              errors.address_postcode ||
              errors.postcode
            }
            data-testid="manual-address-form-postcode"
          />
        </div>
      )}
      <div className={styles.formRow}>
        <Input
          className={styles.formItem}
          name="first_line"
          label={<FormattedMessage {...messages.streetAddressLabel} />}
          value={first_line}
          tip={
            !isGpAddress && (
              <>
                <FormattedMessage {...messages.streetAddressTipInstruction} />
                <br />
                <FormattedMessage {...messages.streetAddressTipExample} />
              </>
            )
          }
          onChange={handleInputChange}
          errors={errors.address_first_line || errors.first_line}
        />
      </div>
      <div className={styles.formRow}>
        <Input
          className={styles.formItem}
          name="town"
          label={<FormattedMessage {...messages.townCityLabel} />}
          value={town}
          onChange={handleInputChange}
          errors={errors.address_second_line || errors.second_line}
        />
      </div>
      <div className={styles.formRow}>
        <Input
          className={styles.formItem}
          name="county"
          label={<FormattedMessage {...messages.countyLabel} />}
          value={county}
          onChange={handleInputChange}
          errors={errors.address_third_line || errors.third_line}
        />
      </div>
    </>
  )
}

export default AddressForm
