import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { Text } from '@babylon/medkit'
import { withRouter } from 'react-router'
import withUserId from '@/wrappers/withUserId'
import withPageTitle from '@/wrappers/withPageTitle'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import PhoneInput from '@/components/PhoneInputWrapper'
import Form from '@/components/Form'
import formMessages from '@/components/Form/Form.messages'
import messages from './RequestAppointment.messages'
import { REQUEST_APPOINTMENT_BOOK_URL } from './constants'

const enhance = compose(
  withUserId,
  withRouter,
  withPageTitle(messages.pageTitle)
)

const arrayFields = ({
  phone_number,
  phone_country_code,
  region: { default_phone_country_code },
}) => [
  {
    rowFields: [
      {
        component: PhoneInput,
        name: 'phone_number',
        label: <FormattedMessage {...formMessages.phoneLabel} />,
        placeholder: <FormattedMessage {...formMessages.phoneLabel} />,
        required: true,
        defaultValues: {
          phone_number: phone_number || '',
          phone_country_code: phone_country_code || default_phone_country_code,
        },
      },
    ],
  },
]

const EnterDetails = ({ currentUserId, patient, history }) => {
  const formOptions = {
    submitBtnText: <FormattedMessage {...messages.enterDetailsPageCta} />,
  }

  const [updatePatient] = useMutation(UpdatePatientMutation, {
    context: {
      showFeedback: {
        success: true,
        failure: true,
      },
    },
  })

  const onSubmit = (fields) =>
    updatePatient({
      variables: {
        id: currentUserId,
        patient: fields,
      },
    }).then(() => {
      history.push(REQUEST_APPOINTMENT_BOOK_URL)
    })

  // If phone number already set move to next page
  if (patient.phone_number) {
    return <Redirect to={REQUEST_APPOINTMENT_BOOK_URL} />
  }

  return (
    <section className="Content--form">
      <Text variant="h1" className="PageTitle">
        <FormattedMessage {...messages.enterDetailsPageTitle} />
      </Text>
      <Text variant="body">
        <FormattedMessage {...messages.enterDetailsPageSubTitle} />
      </Text>
      <Form
        handle="profileForm"
        onSubmit={onSubmit}
        fields={arrayFields(patient)}
        options={formOptions}
        operationName="updatePatient"
        checkPristine
      />
    </section>
  )
}

export default enhance(EnterDetails)
