import { Product } from '@/programmes/components/Products/models'
import {
  DiscountDetails,
  ProductGroups,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/models/models'
import {
  LITE_PRODUCT_TYPE,
  MOUNJARO_PRODUCT_ID,
  OLD_LITE_PRODUCT_TYPE,
  OLD_ULTRA_PRODUCT_TYPE,
  ULTRA_PRODUCT_TYPE,
  WEGOVY_PRODUCT_ID,
} from '@/programmes/WeightLoss/CheckOut/ProductPicker/data/const'

const getDiscountValues = (
  discount: DiscountDetails,
  price: number
): { discount: string; priceWithDiscount: string } => {
  if (price === 0) {
    return {
      discount: '',
      priceWithDiscount: '',
    }
  }

  const discountValue = discount.discountPercentage
    ? price * (discount.discountPercentage / 100)
    : discount.discountAmount / 100

  const priceWithDiscount = Math.max(0, price - discountValue)

  return {
    discount: discountValue > 0 ? `£${discountValue.toFixed(2)}` : '',
    priceWithDiscount: `£${priceWithDiscount.toFixed(2)}`,
  }
}

// get product from productGroups using a backward compatible product type:
// if the product type is an old product type, convert it to the new product type
// this way we can use the same product picker data for both old and new products
const getLocalProduct = (
  productName: string,
  productType: string,
  productGroups: ProductGroups
) => {
  let compatibleProductType: string

  if (productType === OLD_LITE_PRODUCT_TYPE) {
    compatibleProductType = LITE_PRODUCT_TYPE
  } else if (productType === OLD_ULTRA_PRODUCT_TYPE) {
    compatibleProductType = ULTRA_PRODUCT_TYPE
  } else {
    compatibleProductType = productType
  }

  return productGroups[productName].products[compatibleProductType]
}

const updateProduct = (
  productName: string,
  productType: string,
  stripeProducts: Product[],
  productGroups: ProductGroups,
  discountDetails: DiscountDetails,
  addons: Product[]
) => {
  // Check if Stripe data contains products that can be used to enhance product picker data.
  const products = stripeProducts.filter((product) => {
    const name = product.name.toLowerCase()
    // escape special characters in the product type such as `+`
    const escapedProductType = productType.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )
    // notice the end of the string symbol '$' in the regex
    // this is needed to differentiate between similar product types e.g. 'weight loss' and 'weight loss+'
    const regexType = new RegExp(`${escapedProductType}$`)

    return name.includes(productName) && regexType.test(name)
  })

  // Return hardcoded data if there is more than one product.
  if (products.length > 1) {
    return productGroups
  }

  // Return hardcoded data if there is not even one product.
  if (products.length === 0) {
    return productGroups
  }

  const product = products[0]

  const price = product.prices.find(
    (price) => price.id === product.defaultPrice
  )

  // Return hardcoded data if the product does not contain the default price.
  if (!price) {
    return productGroups
  }

  const localProduct = getLocalProduct(productName, productType, productGroups)

  // Add price details
  localProduct.price = `£${(price.unitAmount / 100).toFixed(2)}`
  localProduct.id = price.lookupKey
  localProduct.name = product.name
  productGroups[productName].icon = product.images[0]

  // Add discount details if available.
  if (
    discountDetails &&
    discountDetails.prices.some(
      (price) =>
        price.priceLookupKey === localProduct.id && price.isApplicable === true
    )
  ) {
    const { discount, priceWithDiscount } = getDiscountValues(
      discountDetails,
      price.unitAmount / 100
    )

    localProduct.discount = discount
    localProduct.priceWithDiscount = priceWithDiscount
  }

  // Add add-ons to ultra products if add-ons are available.
  if (
    productType === ULTRA_PRODUCT_TYPE ||
    productType === OLD_ULTRA_PRODUCT_TYPE
  ) {
    const mappedAddons = addons.map((addon) => {
      const addonPrice = addon.prices.find(
        (price) => price.id === addon.defaultPrice
      )

      return addonPrice?.lookupKey
    })

    localProduct.addons = mappedAddons
  }

  return productGroups
}

export const addStripeLiveData = (
  stripeProducts: Product[],
  productGroups: ProductGroups,
  discountDetails: DiscountDetails,
  addons: Product[]
) => {
  let enhancedProductGroups = { ...productGroups }

  ;[WEGOVY_PRODUCT_ID, MOUNJARO_PRODUCT_ID].forEach((productName) => {
    ;[
      OLD_LITE_PRODUCT_TYPE,
      OLD_ULTRA_PRODUCT_TYPE,
      ULTRA_PRODUCT_TYPE,
      LITE_PRODUCT_TYPE,
    ].forEach((productType) => {
      enhancedProductGroups = updateProduct(
        productName,
        productType,
        stripeProducts,
        enhancedProductGroups,
        discountDetails,
        addons
      )
    })
  })

  return enhancedProductGroups
}
