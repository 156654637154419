import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeCookePreferencesAction } from './reducer'
import { CookieGroup, isCookieGroup } from './types'

const ONE_TRUST_SDK_STUB_URL =
  'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'

const optAnonFunc = `function OptanonWrapper() { }`

function injectOptanonWrapper() {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerText = optAnonFunc
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(script)
}

function injectSDKStubScript(uuid: string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.setAttribute('data-domain-script', uuid)

    script.onload = () => {
      resolve()
    }
    script.onerror = () => {
      reject()
    }

    script.src = ONE_TRUST_SDK_STUB_URL

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(script)
  })
}

const CONSENT_UPDATE_EVENT = 'oneTrustConsentUpdate'

function waitForOneTrust() {
  return new Promise<void>((resolve) => {
    const observer = new MutationObserver((mutationList) => {
      const mutationResult = mutationList.find(
        (mutation) =>
          ((mutation.target as any).id as string).search(/onetrust/) !== -1
      )

      if (mutationResult) {
        resolve()
      }
    })
    observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: false,
    })
  })
}

export function parseOneTrustCookieGroups(
  activeGroups: string[]
): CookieGroup[] {
  const cookieGroups: CookieGroup[] = []
  const unknownCookieGroups: string[] = []

  activeGroups.forEach((group) => {
    if (group.length !== 0) {
      ;(isCookieGroup(group) ? cookieGroups : unknownCookieGroups).push(group)
    }
  })

  if (unknownCookieGroups.length >= 0) {
    console.warn(
      `Unknown cookie groups found in One Trust consent update: ${unknownCookieGroups.join(
        ', '
      )}`
    )
  }

  return cookieGroups
}

async function injectOneTrust(uuid: string): Promise<string[]> {
  const waitForOneTrustPromise = waitForOneTrust()
  await injectSDKStubScript(uuid)
  await waitForOneTrustPromise
  injectOptanonWrapper()
  ;(window.OneTrust as any).OnConsentChanged((e: CustomEvent<string[]>) => {
    const event = new CustomEvent<string[]>(CONSENT_UPDATE_EVENT, {
      detail: e.detail,
    })
    document.dispatchEvent(event)
  })

  return window.OnetrustActiveGroups?.split(',').filter((s) => s) ?? []
}

export default function useSetupOneTrustListeners({
  uuid,
  disabled,
}: {
  uuid?: string
  disabled?: boolean
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (disabled) {
      return () => {}
    }

    if (!uuid) {
      console.warn(
        'Could not initialise One Trust as the app ID was not provided'
      )

      return () => {}
    }

    function consentListener(e: CustomEvent<string[]>) {
      const cookieGroups = parseOneTrustCookieGroups(e.detail)

      dispatch(changeCookePreferencesAction(cookieGroups))
    }

    injectOneTrust(uuid).then((activeGroups) => {
      dispatch(
        changeCookePreferencesAction(parseOneTrustCookieGroups(activeGroups))
      )
      document.addEventListener(CONSENT_UPDATE_EVENT, consentListener)
    })

    return () => {
      document.removeEventListener(CONSENT_UPDATE_EVENT, consentListener)
    }
  }, [disabled, dispatch, uuid])
}
