import React from 'react'
import styles from './Modal.module.scss'

interface Props {
  first_line: string
  town: string
  postcode: string
}

const FormattedFirstLine: React.FC<Props> = ({
  first_line,
  town,
  postcode,
}) => (
  <div>
    <span className={styles['addressPicker__modal__address-dark']}>
      {first_line}
    </span>
    <br />
    <span className={styles['addressPicker__modal__address-light']}>
      {town}, {postcode}
    </span>
  </div>
)

export default FormattedFirstLine
