import { defineMessages } from 'react-intl'

export default defineMessages({
  height: {
    id: 'heightAndWeight.Height',
    defaultMessage: 'What is your height?',
  },
  switchToCm: {
    id: 'heightAndWeight.switchToCm',
    defaultMessage: 'Switch to centimetres',
  },
  Cm: {
    id: 'heightAndWeight.Cm',
    defaultMessage: 'CM',
  },
  Ft: {
    id: 'heightAndWeight.Ft',
    defaultMessage: 'FT',
  },
  switchToftin: {
    id: 'heightAndWeight.switchToftin',
    defaultMessage: 'Switch to feet, inches',
  },
  feet: {
    id: 'heightAndWeight.feet',
    defaultMessage: 'Feet',
  },
  inches: {
    id: 'heightAndWeight.inches',
    defaultMessage: 'Inches',
  },
  centimetres: {
    id: 'heightAndWeight.centimetres',
    defaultMessage: 'Centimetres',
  },
  weight: {
    id: 'heightAndWeight.Weight',
    defaultMessage: 'What is your current weight?',
  },
  Kg: {
    id: 'heightAndWeight.Kg',
    defaultMessage: 'KG',
  },
  switchToKg: {
    id: 'heightAndWeight.switchToKg',
    defaultMessage: 'Switch to kilograms',
  },
  Lb: {
    id: 'heightAndWeight.Lb',
    defaultMessage: 'LB',
  },
  switchToStLb: {
    id: 'heightAndWeight.switchToStLb',
    defaultMessage: 'Switch to stones, pounds',
  },
  stones: {
    id: 'heightAndWeight.stones',
    defaultMessage: 'Stones',
  },
  pounds: {
    id: 'heightAndWeight.pounds',
    defaultMessage: 'Pounds',
  },
  kilograms: {
    id: 'heightAndWeight.kilograms',
    defaultMessage: 'Kilograms',
  },
  bmi: {
    id: 'heightAndWeight.bmi',
    defaultMessage: 'Your BMI is',
  },
  beingCalculated: {
    id: 'heightAndWeight.beingCalculated',
    defaultMessage: 'being calculated',
  },
  weightWarning: {
    id: 'heightAndWeight.weightWarning',
    defaultMessage:
      "We'll ask you to verify your exact weight later in the signup process.",
  },
  heightImperialErrorMessage: {
    id: 'heightAndWeight.heightImperialErrorMessage',
    defaultMessage: 'Please enter a height between {min}ft and {max}ft.',
  },
  heightMetricErrorMessage: {
    id: 'heightAndWeight.heightMetricErrorMessage',
    defaultMessage: 'Please enter a height between {min}cm and {max}cm',
  },
  weightImperialErrorMessage: {
    id: 'heightAndWeight.weightImperialErrorMessage',
    defaultMessage: 'Please enter a weight between {min}st and {max}st.',
  },
  weightMetricErrorMessage: {
    id: 'heightAndWeight.weightMetricErrorMessage',
    defaultMessage: 'Please enter a weight between {min}kg and {max}kg',
  },
})
