import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { register as registerAction } from '@/components/Authentication/actions'
import MissingFieldsModal from '@/components/Modal/MissingFieldsModal'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import Template from '../Template'
import messages from '../messages'
import Button from '@/programmes/components/Button'
import styles from '../index.module.scss'
import Card from '@/programmes/components/Card'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'
import { useCurrentUserId, useUtiTestEnabled } from '@/redux/selectors'

const cx = classNames.bind(styles)

export const patientQuery = gql`
  query Patient {
    patient {
      id
      date_of_birth
      gender
      phone_number
      region {
        id
        default_phone_country_code
      }
    }
  }
`

const SetupHome = () => {
  const t = useFormatMessage()
  const isAuthenticated = !!useCurrentUserId()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    data: patientData,
    loading: patientQueryLoading,
  } = useQuery(patientQuery, { skip: !isAuthenticated })
  const [updatePatient] = useMutation(UpdatePatientMutation)

  const handleClick = () => {
    if (isAuthenticated) {
      return history.push(UTI_TEST.routes.questionnaire.path)
    }

    return dispatch(registerAction())
  }

  const utiTestEnabled = useUtiTestEnabled()

  return (
    <Template
      title={t(messages.setupHomePageTitle)}
      steps={UTI_TEST.setupSteps}
      activeStep={0}
      loading={patientQueryLoading}
      hasAppLayout={isAuthenticated}
    >
      {patientData && (
        <MissingFieldsModal
          user={patientData.patient}
          checkFields={['date_of_birth', 'gender', 'phone_number']}
          updatePatient={updatePatient}
          useSexAtBirthTerminology
          avoidClose
        />
      )}
      <h2 className={cx('heading')}>
        {t(
          utiTestEnabled
            ? messages.setupHomePageHeading
            : messages.setupHomePageComingSoonHeading
        )}
      </h2>
      <p className={cx('description')}>
        {t(messages.setupHomePageDescription1)}
      </p>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.timeAndPlaceTitle)}
          description={t(messages.timeAndPlaceDescription)}
        />
        <Card
          title={t(messages.deviceTitle)}
          description={t(messages.deviceDescription)}
        />
        <Card
          title={t(messages.postTestExpectationsTitle)}
          description={t(messages.postTestExpectationsDescription)}
        />
        <Card
          title={t(messages.medicationDeliveryTitle)}
          description={t(messages.medicationDeliveryDescription)}
        />
      </div>
      {utiTestEnabled && (
        <Spacer top={5} bottom={5}>
          <Button onClick={handleClick}>{t(messages.setupHomePageCta)}</Button>
        </Spacer>
      )}
    </Template>
  )
}

export default SetupHome
