import React from 'react'
import logoSvg from './icons/emed-screen-logo.svg'

const ReportHeader = ({
  title,
  caption,
}: {
  title: string
  caption: string
}) => (
  <>
    <div
      className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4"
      style={{ marginBottom: '20px' }}
    >
      <div className="tw-flex tw-justify-start">
        <img
          className="tw-scale-90 md:tw-transform-none"
          src={logoSvg}
          alt="eMed Screen Logo"
          height="26"
          width="180"
        />
      </div>
    </div>
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-text-lg lg:tw-text-2xl tw-font-medium">
      {title}
    </div>
    <div
      className="tw-w-full tw-flex tw-flex-col tw-items-center tw-text-center tw-font-light tw-mt-4"
      style={{ maxWidth: '660px' }}
    >
      {caption}
    </div>
  </>
)

export default ReportHeader
