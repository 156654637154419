import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'GPDetails.pageTitle',
    defaultMessage: 'GP Details',
  },
  orderConfirmedPageTitle: {
    id: 'GPDetails.orderConfirmedPageTitle',
    defaultMessage: 'GP Details - Order Confirmed',
  },
  success: {
    id: 'GPDetails.success',
    defaultMessage: 'Your GP details have been saved',
  },
  gpAtHandClinicsTitle: {
    id: 'GPDetails.GpAtHand.clinics.title',
    defaultMessage: 'Clinic Locations',
  },
  gpAtHandClinicsSubTitle: {
    id: 'GPDetails.GpAtHand.clinics.subTitle',
    defaultMessage:
      'As a GP at Hand user, you have access to many GP at Hand clinics.',
  },
  gpAtHandClinicLocationsCta: {
    id: 'GPDetails.GpAtHand.Cta.clinicLocations',
    defaultMessage: 'Clinic Locations',
  },
  gpAtHandFaqCta: {
    id: 'GPDetails.GpAtHand.cta.faq',
    defaultMessage: `FAQ's about GP at Hand`,
  },
  gpAtHandAddressTitle: {
    id: 'GPDetails.GpAtHand.address.title',
    defaultMessage: 'You are registered with:',
  },
  gpAtHandPageAddressAdditionalInfo: {
    id: 'GPDetails.GpAtHand.address.additionaliIformation',
    defaultMessage:
      'If you need to give your GP details to anyone, use this address.',
  },
  gpAtHandSubmitButtonText: {
    id: 'GPDetails.GpAtHand.submitButtonText',
    defaultMessage: 'Save',
  },
})
