import gql from 'graphql-tag'

import patient from '@/fragments/Patient'

export default gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      ...patient
    }
  }
  ${patient}
`
