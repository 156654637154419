import React from 'react'

import PartnerBranding from './PartnerBranding'
import BabylonBranding from './BabylonBranding'
import { usePartnerLogo } from '@/redux/selectors'
import styles from './Footer.module.scss'
import WebViewMode from '@/utils/WebViewMode'

const LandingFooter = () => {
  const partnerLogo = usePartnerLogo()
  const isWebViewMode = WebViewMode.isWebViewMode()

  if (isWebViewMode) {
    return null
  }

  return (
    <footer className={styles.footer}>
      {' '}
      {partnerLogo && <PartnerBranding logo={partnerLogo} />}
      <BabylonBranding />
    </footer>
  )
}

export default LandingFooter
