import gql from 'graphql-tag'

export default gql`
  query DiscountDetailQuery($lookupKeys: [String!]!, $promotionCode: String!) {
    discountDetail(lookupKeys: $lookupKeys, promotionCode: $promotionCode) {
      ... on DiscountDetail {
        promoCodeId
        promoCodeName
        discountPercentage
        discountAmount
        prices {
          priceLookupKey
          isApplicable
        }
      }
    }
  }
`
