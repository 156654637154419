import React, { useEffect, useState } from 'react'
import { Select } from '@babylon/medkit'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import messages from '../../messages'
import styles from '../../index.module.scss'
import {
  MedicationCode,
  MedicationRecord,
  MedicationUnit,
  ProgrammeCheckInStep,
} from '../../types'
import InjectionVideo from '@/assets/images/ozempic-inject-allsteps-waist.mp4'
import InjectionInstructionsFull from './InjectionInstructionsFull'
import Button from '@/programmes/components/Button'
import useSubmitInjectionComplete from '@/programmes/hooks/useSubmitInjectionComplete'
import useCompleteAnimation from '@/programmes/hooks/useCompleteAnimation'
import { reduxState } from '@/redux'
import { displayFlashError } from '@/redux/flash/actions'

const SUPPORT_FORM_URL =
  'https://support.babylon-health.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d'

type Props = {
  config: ProgrammeCheckInStep
  onStepComplete: () => void
}

const cx = classNames.bind(styles)

const InjectionLayout: React.FC<Props> = ({ config, onStepComplete }) => {
  const t = useFormatMessage()
  const [fullInstructions, setFullInstructions] = useState(false)
  const { submitData, success, loading, error } = useSubmitInjectionComplete()
  const [selectedDosage, setSelectedDosage] = useState()
  const { AnimationScreen, setIsPlaying, isPlaying } = useCompleteAnimation(
    false
  )
  const dosageOptions = [
    { value: '0.25', label: '0.25mg' },
    { value: '0.5', label: '0.5mg' },
    { value: '1', label: '1mg' },
    { value: '1.7', label: '1.7mg' },
    { value: '2.4', label: '2.4mg' },
  ]

  const dosageSelected = (e: any) => {
    setSelectedDosage(e.target.value)
  }

  const linkToSupport = () => {
    window.location.href = SUPPORT_FORM_URL
  }

  const submitInjection = () => {
    const medicationRecord: MedicationRecord = {
      medication_code: MedicationCode.WEGOVY,
      dose: Number(selectedDosage),
      unit: MedicationUnit.MG,
    }
    submitData(medicationRecord)
  }

  useEffect(() => {
    if (success) {
      setIsPlaying(true)
    }
  }, [success, setIsPlaying])

  useEffect(() => {
    if (isPlaying) {
      setTimeout(() => {
        onStepComplete()
      }, 2000)
    }
  }, [isPlaying, onStepComplete])

  useEffect(() => {
    if (error) {
      const { store } = reduxState
      store.dispatch(
        displayFlashError(messages.injectionLayoutSubmitErrorMessage)
      )
    }
  }, [error])

  return (
    <>
      <h2>{t(config.title)}</h2>

      {fullInstructions ? (
        <>
          <InjectionInstructionsFull
            onStepComplete={submitInjection}
            loading={loading}
            success={success}
          />
          {success && (
            <AnimationScreen onAnimationFinish={() => setIsPlaying(false)} />
          )}
        </>
      ) : (
        <>
          <p>{t(messages.injectionLayoutReadInstructions)}</p>
          <video width="320" height="240" autoPlay loop>
            <track kind="captions" />
            <source src={InjectionVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={cx('injection-layout')}>
            <p>{t(messages.injectionLayoutDosageLabel)}</p>
            <Select
              options={dosageOptions}
              placeholder={t(messages.injectionLayoutSelectDosage)}
              onChange={(e) => dosageSelected(e)}
            />
            {selectedDosage && !success && (
              <>
                <Button onClick={submitInjection} loading={loading}>
                  {t(messages.injectionLayoutCompletedInjection)}
                </Button>
                <Button secondary onClick={() => setFullInstructions(true)}>
                  {t(messages.injectionLayoutNeedMoreGuidance)}
                </Button>
              </>
            )}

            {success && (
              <AnimationScreen onAnimationFinish={() => setIsPlaying(false)} />
            )}
            <div>
              <Button secondary onClick={linkToSupport}>
                {t(messages.injectionLayoutContactSupport)}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default InjectionLayout
