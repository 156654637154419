import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import {
  withProps,
  withState,
  compose,
  lifecycle,
  withHandlers,
} from 'recompose'
import { Select, asFormControl } from '@babylon/ui'
import checkDate from './dateChecking'
import styles from './DatePicker.module.scss'
import messages from './DatePicker.messages'

const initialDate = {
  day: '',
  month: '',
  year: '',
}

const DEFAULT_YEAR_INTERVAL = 100

const generateYears = (lower, upper) => {
  if (!upper && !lower) {
    upper = moment().year()
    lower = upper - DEFAULT_YEAR_INTERVAL
  } else {
    if (!upper) {
      upper = lower + DEFAULT_YEAR_INTERVAL
    }

    if (!lower) {
      lower = upper - DEFAULT_YEAR_INTERVAL
    }
  }

  // add 1 to make the year range inclusive
  return Array.from(new Array(upper - lower + 1), (v, i) => {
    const value = (upper - i).toString()

    return { value, label: value }
  })
}

const enhance = compose(
  injectIntl,
  withState('date', 'updateState', initialDate),
  withProps(({ minDate, maxDate }) => ({
    minDate: minDate ? moment(minDate, 'YYYY-MM-DD') : minDate,
    maxDate: maxDate ? moment(maxDate, 'YYYY-MM-DD') : maxDate,
  })),
  withProps(({ minDate, maxDate }) => {
    if (minDate && maxDate) {
      const minYear = minDate.year()
      const maxYear = maxDate.year()

      return { years: generateYears(minYear, maxYear) }
    }

    return {
      years: generateYears(),
    }
  }),
  withProps(
    ({
      minDate,
      maxDate,
      date,
      errors = [],
      messages: { validationError = 'Invalid date' } = {},
    }) => {
      if (!checkDate(date, minDate, maxDate)) {
        return {
          errors: [...errors, validationError],
        }
      }

      return undefined
    }
  ),
  withProps(({ date: { day, month, year }, errors = [], intl }) => {
    const dayInvalid =
      moment()
        .month(Number(month - 1 || 0)) // we have to subtract 1 because moment stores months as an enumerable
        .year(Number(year || 2001))
        .daysInMonth() < Number(day || 31)

    if (dayInvalid) {
      return {
        errors: [...errors, intl.formatMessage(messages.invalidDate)],
      }
    }

    return undefined
  }),
  asFormControl,
  // asFormControl has already consumed errors to display
  // replace with blank error to trigger error styles on nested selects
  withProps(({ errors }) => ({
    errors: errors.length ? [''] : [],
  })),
  lifecycle({
    componentWillMount() {
      const [year, month, day] = (this.props.value || '').split('-')
      this.props.updateState({ year, month, day })
    },
  }),
  withHandlers({
    updateDate: ({ onChange, updateState, date }) => (inputName) => (value) => {
      const newDate = {
        ...date,
        [inputName]: value,
      }
      updateState(newDate)

      const { day, month, year } = newDate

      if (day && month && year) {
        const parsedDate = `${year}-${month}-${day}`
        const isValidDate = moment(parsedDate).isValid()

        if (isValidDate) {
          onChange(parsedDate)
        }
      }
    },
  })
)

// A list of days from 1-31 for the days of the month
const daysOfMonth = Array.from(new Array(31), (v, i) => {
  const day = i + 1
  const value = day < 10 ? `0${day}` : `${day}`

  return { value, label: value }
})

const getMonths = (locale) => {
  const d = new Date()

  const months = []

  for (let i = 0; i < 12; i++) {
    d.setDate(1)
    d.setMonth(i)

    const month = {
      value: d.toLocaleString(locale, { month: '2-digit' }),
      label: d.toLocaleString(locale, { month: 'short' }),
    }
    months.push(month)
  }

  return months
}

const DatePicker = ({
  name,
  label,
  errors,
  date: { day, month, year },
  updateDate,
  disabled,
  years,
  intl,
}) => (
  <div>
    <label htmlFor={name} className="label">
      {label}
    </label>
    <div className={styles.datePicker}>
      <div className={styles.pickerItem}>
        <Select
          name="day"
          placeholder={intl.formatMessage(messages.day)}
          options={daysOfMonth}
          value={day}
          onChange={updateDate('day')}
          errors={errors}
          disabled={disabled}
        />
      </div>
      <div className={styles.pickerItem}>
        <Select
          name="month"
          placeholder={intl.formatMessage(messages.month)}
          options={getMonths(intl.locale)}
          value={month}
          onChange={updateDate('month')}
          errors={errors}
          disabled={disabled}
        />
      </div>
      <div className={styles.pickerItem}>
        <Select
          name="year"
          placeholder={intl.formatMessage(messages.year)}
          options={years}
          value={year}
          onChange={updateDate('year')}
          errors={errors}
          disabled={disabled}
        />
      </div>
    </div>
  </div>
)

export { generateYears }
export default enhance(DatePicker)
