import { Height } from '../components/Form/types'

export enum RAG {
  GREEN,
  RED,
  NEUTRAL,
}

export type WeightMetricBMI = {
  value: number
  category: BMICategory
  rag?: RAG
}

export type WeightMetricBMIChange = {
  current: WeightMetricBMI
  previous: WeightMetricBMI
  changeRag?: RAG
}

export type WeightMetric = {
  dateFormated?: string
  dateISO?: string
  weight: number
  weightLoss: number
  bmi: WeightMetricBMIChange
}

export type WeightMetrics = {
  weightMetrics: WeightMetric[]
  height?: Height
  currentWeight: number
  unit: string
  totalWeightLost: {
    value: number
    rag?: RAG
  }
  previousWeekWeightLoss: {
    value: number
    rag?: RAG
  }
}

export enum BMICategory {
  UNDERWEIGHT = 'UNDERWEIGHT',
  NORMAL = 'NORMAL',
  OVERWEIGHT = 'OVERWEIGHT',
  OBESE = 'OBESE',
}
