import React from 'react'
import { EditorialLayout, Text, Link } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import messages from './ServiceUnavailablePage.messages'
import appStyles from '@/App.module.scss'
import LandingHeader from '@/components/LandingHeader'
import LandingFooter from '@/components/Footer/LandingFooter'
import styles from './ServiceUnavailablePage.module.scss'

const ServiceUnavailablePage = () => {
  const translate = useFormatMessage()

  return (
    <>
      <Helmet>
        <title>{translate(messages.pageTitle)}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <LandingHeader />
      <main className={appStyles.view}>
        <section className={appStyles.view__inner}>
          <EditorialLayout
            className={styles['service-unavailable__editorial-layout']}
          >
            <Text variant="h3" tag="h1">
              {translate(messages.pageHeading)}
            </Text>
            <Text variant="body">
              <FormattedMessage
                {...messages.body1}
                values={{
                  link: (
                    <Link href={translate(messages.body1LinkHref)}>
                      {translate(messages.body1LinkText)}
                    </Link>
                  ),
                }}
              />
            </Text>
            <Text variant="body">{translate(messages.body2)}</Text>
            <Text variant="body">
              <FormattedMessage
                {...messages.body3}
                values={{
                  link: (
                    <Link href={translate(messages.body3LinkHref)}>
                      {translate(messages.body3LinkText)}
                    </Link>
                  ),
                }}
              />
            </Text>
          </EditorialLayout>
        </section>
      </main>
      <LandingFooter />
    </>
  )
}

export default ServiceUnavailablePage
