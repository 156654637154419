import React from 'react'
import { Route } from 'react-router-dom'

interface Props {
  code: number
  children: JSX.Element
}

const Status = ({ code, children }: Props) => (
  <Route
    render={({ staticContext }: any) => {
      if (staticContext) {
        staticContext.status = code
      }

      return children
    }}
  />
)

export default Status
