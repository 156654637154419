import { defineMessages } from 'react-intl'

export default defineMessages({
  errorTitle: {
    id: 'PaymentCompletionPage.errorTitle',
    defaultMessage: 'Oops!',
  },
  errorMessage: {
    id: 'PaymentCompletionPage.errorMessage',
    defaultMessage:
      'There is an issue retrieving your appointment details. Please try again.',
  },
  errorTryAgain: {
    id: 'PaymentCompletionPage.errorTryAgain',
    defaultMessage: 'Try Again',
  },
})
