import { useLocation } from 'react-router-dom'

type DiscountCodeParams = {
  /* eslint-disable camelcase */
  discount_code: string
  discount_code_id: string
  discount_amount: string
  discount_expiry: string
  discount_new_price: string
  /* eslint-enable camelcase */
}

const SUPPORTED_DISCOUNT_PREFIX = 'discount_'
export const DISCOUNT_STORAGE_KEY = 'discount_params'

export const useSaveDiscountCode = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const discountCodes = Array.from(searchParams.entries()).filter(([key]) =>
    key.startsWith(SUPPORTED_DISCOUNT_PREFIX)
  )

  if (discountCodes.length === 0) {
    return
  }

  sessionStorage.setItem(
    DISCOUNT_STORAGE_KEY,
    new URLSearchParams([...discountCodes]).toString()
  )
}

export const getDiscountCode = () => {
  const discountParams = sessionStorage.getItem(DISCOUNT_STORAGE_KEY)

  if (!discountParams) {
    return undefined
  }

  return Object.fromEntries(
    new URLSearchParams(discountParams).entries()
  ) as DiscountCodeParams
}
