import React, { useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { Patient } from '@babylon/consumer-api/types'
import {
  useWeightLossSetupEnabled,
  useWeightLossEnabledForGPAtHand,
} from '@/redux/selectors'
import Template from '../Template'
import { checkoutRoutes, setupRoutes } from '../routes'
import getPreScreeningSteps from '@/programmes/WeightLoss/steps/getPreScreeningSteps'
import Form from '@/programmes/components/FormV2'
import messages from '../messages'
import useIsOutOfTheAgeRangeCheck from '@/programmes/WeightLoss/hooks/useIsOutOfTheAgeRangeCheck'
import Spinner from '@/programmes/components/Spinner'
import ExitView from '@/programmes/components/Form/components/ExitView'
import { Step } from '@/programmes/components/FormV2/types'
import useUpdateSexAtBirth from '@/programmes/WeightLoss/hooks/useUpdateSexAtBirth'
import UserQuery from '@/queries/Patient'

interface UserQueryResult {
  patient: Patient
}

const SetupQuestionnairePage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const weightLossSetupEnabled = useWeightLossSetupEnabled()
  const weightLossEnabledForGPAtHand = useWeightLossEnabledForGPAtHand()
  const {
    loading: outOfTheAgeRangeLoading,
    outOfTheAgeRange,
  } = useIsOutOfTheAgeRangeCheck()
  const preScreeningSteps = getPreScreeningSteps() as Step[]
  const { updateSexAtBirth } = useUpdateSexAtBirth()

  const onSubmit = async (fields) => {
    updateSexAtBirth(fields)

    return history.push(checkoutRoutes.home)
  }

  const userId = useSelector((state: any) => state.auth?.user?.id)
  const isAuthenticated = userId !== undefined && userId !== null

  const { data: userData } = useQuery<UserQueryResult>(UserQuery, {
    variables: { id: userId },
    skip: !isAuthenticated,
  })

  useEffect(() => {
    const redirectIfOnGPAtHand = () => {
      if (
        !weightLossEnabledForGPAtHand &&
        userData?.patient?.consumer_networks?.some((network) =>
          network?.name.toLowerCase().includes('gp at hand')
        )
      ) {
        history.push(setupRoutes.errorIneligible)
      }
    }

    redirectIfOnGPAtHand()
  }, [history, userData, weightLossEnabledForGPAtHand])

  return (
    <Template title={t(messages.setupQuestionnairePageTitle)}>
      {weightLossSetupEnabled && (
        <>
          {outOfTheAgeRangeLoading && <Spinner colour="#000000" />}

          {!outOfTheAgeRange && !outOfTheAgeRangeLoading && (
            <Form steps={preScreeningSteps} onSubmit={onSubmit} />
          )}

          {outOfTheAgeRange && !outOfTheAgeRangeLoading && <ExitView />}
        </>
      )}
    </Template>
  )
}

export default SetupQuestionnairePage
