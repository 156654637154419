import React from 'react'
import { Link } from 'react-router-dom'

import { IMedkitComponent } from '..'
import { buttonTheme } from './theme'
import getButtonClassNames from './utils/getButtonClassNames'
import { ButtonVariant } from '.'

import { IButtonReactRouterLinkProps } from './Button.types'

export const ButtonReactRouterLink: IMedkitComponent<
  IButtonReactRouterLinkProps,
  typeof buttonTheme
> = ({
  children,
  className,
  fullWidth = false,
  halfWidth = false,
  variant = ButtonVariant.primary,
  component,
  ...props
}: IButtonReactRouterLinkProps) => {
  const Component = component || Link

  return (
    <Component
      className={getButtonClassNames({
        variant,
        fullWidth,
        halfWidth,
        className,
      })}
      {...props}
    >
      {children}
    </Component>
  )
}

ButtonReactRouterLink.theme = buttonTheme

export default ButtonReactRouterLink
