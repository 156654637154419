import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import classNames from 'classnames/bind'
import { IdFilled as IdIcon, ReportFilled as ScalesIcon } from '@babylon/icons'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/browser'
import { useWeightLossSetupNextStepsInfo } from '@/redux/selectors'
import WeightLossImage from '@/assets/images/weight-loss_proctor.jpg'
import MissingFieldsModal from '@/components/Modal/MissingFieldsModal'
import UpdatePatientMutation from '@/mutations/UpdatePatient'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import messages from '../messages'
import Button from '@/programmes/components/Button'
import { procedures } from '../routes'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import { useTrackingPageRedirect } from '@/programmes/tracking'
import styles from '../index.module.scss'
import { onboardingSteps } from './steps'
import useProgrammeState from '../hooks/useProgrammeState'
import ErrorComponent from '@/components/Error'
import { ProgrammeState } from '../model'

const cx = classNames.bind(styles)

const patientQuery = gql`
  query Patient {
    patient {
      id
      date_of_birth
      gender
      phone_number
      region {
        id
        default_phone_country_code
      }
    }
  }
`

const isAllowedToSeeThePage = (state: ProgrammeState) =>
  ['PaidOnlyState', 'OnProgrammeState'].includes(state.kind)

const reportError = (state: ProgrammeState, memberId?: string) => {
  const supportUuid = uuidv4()
  Sentry.withScope((scope) => {
    scope.setExtras({
      programme_state_kind: state.kind,
      support_id: supportUuid,
      member_id: memberId,
    })
    Sentry.captureException(
      new Error(
        'Member got to the next-steps setup page without care plan and subscription'
      )
    )
  })
  return (
    <ErrorComponent
      onRetry={() => window.location.reload()}
      title="Weight Management Programme Onboarding is not complete"
      detail={'Something went wrong on our side.\nSupport ID: ' + supportUuid}
    />
  )
}

const SetupNextStepsPage = () => {
  const { programmeState, loading: programmeStateLoading } = useProgrammeState()
  const t = useFormatMessage()
  const announcement = useWeightLossSetupNextStepsInfo()
  const trackingPageRedirect = useTrackingPageRedirect()
  const { data: patientData, loading: patientQueryLoading } = useQuery(
    patientQuery
  )
  const [updatePatient] = useMutation(UpdatePatientMutation)
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const proctorInviteUrl = data?.createProctorSessionInvite?.url

  if (proctorInviteUrl) {
    trackingPageRedirect()
    window.location.href = proctorInviteUrl
  }

  if (!programmeStateLoading && !isAllowedToSeeThePage(programmeState)) {
    // Don't allow people off the programme to join the proctor session.
    // In case if they reached the page and they are not allowed to see it yet -
    // DO NOT redirect them to the beginning of onboarding.
    // We need to avoid them accidentally onboarding second time and paying again.
    return reportError(programmeState, patientData?.patient?.id)
  }

  const isLoading = programmeStateLoading || patientQueryLoading

  return (
    <Template
      title={t(messages.setupNextStepsPageTitle)}
      steps={onboardingSteps}
      activeStep={2}
      loading={isLoading}
    >
      {patientData && (
        <MissingFieldsModal
          user={patientData.patient}
          checkFields={['date_of_birth', 'gender', 'phone_number']}
          updatePatient={updatePatient}
          useSexAtBirthTerminology
          phoneDescription={t(messages.setupNextStepsPagePhoneDescription)}
          avoidClose
        />
      )}
      <h2 className={cx('heading')}>{t(messages.setupNextStepsPageHeading)}</h2>
      <p className={cx('description')}>
        {t(messages.setupNextStepsPageDescription1)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupNextStepsPageDescription2)}
      </p>
      <ul className={cx('requirements')}>
        <li>
          <IdIcon />
          {t(messages.setupNextStepsPageRequirement1)}
        </li>
        <li>
          <ScalesIcon />
          {t(messages.setupNextStepsPageRequirement2)}
        </li>
      </ul>
      <h5>{t(messages.setupNextStepsPageFooterTitle)}</h5>
      <p className={cx('description')}>
        {t(messages.setupNextStepsPageFooter)}
      </p>
      {announcement && <Announcement content={announcement} />}
      <Spacer top={2} bottom={3}>
        <Button
          green
          onClick={() =>
            getProctorSessionInvite({
              variables: {
                input: {
                  procedure: {
                    identifier: { value: procedures.WEIGHT_LOSS_SETUP },
                  },
                },
              },
            })
          }
          loading={loading || !!proctorInviteUrl}
        >
          {t(messages.setupNextStepsPageCta)}
        </Button>
      </Spacer>

      <Spacer top={2}>
        <img
          className={cx('setup-confirmation-image')}
          src={WeightLossImage}
          alt={t(messages.setupNextStepsPageImageDescription)}
        />
      </Spacer>
    </Template>
  )
}

export default SetupNextStepsPage
