import React from 'react'
import { connect } from 'react-redux'

import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import styles from './styles.module.scss'
import { logout as logoutAction, login as loginAction } from '../../actions'
import { getIsAuthenticated } from '../../selectors'

const enhance = connect(
  (state) => ({ isAuthenticated: getIsAuthenticated(state) }),
  (dispatch, { redirectPath = window.location.href }) => ({
    logout: () => dispatch(logoutAction({ redirectPath })),
    login: () => dispatch(loginAction()),
  })
)

const Banner = ({ login, logout, isAuthenticated }) =>
  !isAuthenticated ? (
    <div className={styles.formLoginBanner}>
      <span className={styles.formLoginBanner__text}>
        Already have an account?
      </span>
      <ButtonSimple
        type="submit"
        onClick={login}
        data-testid="formLoginBannerButton"
      >
        Log In
      </ButtonSimple>
    </div>
  ) : (
    <div className={styles.formLoginBanner}>
      <span className={styles.formLoginBanner__text}>
        Log out of your eMed account
      </span>
      <ButtonSimple
        type="submit"
        variant={ButtonVariant.secondary}
        onClick={logout}
        data-testid="formLogoutBannerButton"
      >
        Logout
      </ButtonSimple>
    </div>
  )

export default enhance(Banner)
