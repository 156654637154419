import { takeLatest } from 'redux-saga/effects'
import { trackEvent } from '@babylon/tracking/gtag'
import { SHOW_REFER_FRIEND, HIDE_REFER_FRIEND } from './constants'

function* showReferFriend(action) {
  yield trackEvent({
    category: 'Refer A Friend',
    action: `Refer A Friend Initialized - ${action.situation}`,
  })
}

function* hideReferFriend() {
  yield trackEvent({
    category: 'Refer A Friend',
    action: 'Refer A Friend Closed',
  })
}

// Saga to watch for refer friend events and
// fire trackEvents when the action is called
export default function* root() {
  yield takeLatest(SHOW_REFER_FRIEND, showReferFriend)
  yield takeLatest(HIDE_REFER_FRIEND, hideReferFriend)
}
