import { Reducer } from 'react'
import {
  AppointmentInvite,
  ClinicalAppointment,
  AppointmentInviteMedium,
} from '@babylon/consumer-api/types'

import {
  BEGIN_BOOKING,
  CLEAR_SELECTION,
  COMPLETE_BOOKING,
  SELECT_LOCATION,
  SELECT_SLOT,
  SELECT_MEDIUM,
  UNDO_LAST_CONFIRMATION,
  SELECT_CLINICIAN_TYPE,
} from './constants'
import * as Actions from './actions'

import { ValueOf } from './types'

export type InitialState = {
  bookingChoices: {
    time?: string
    clinic?: any
    consultant?: any
    medium?: AppointmentInviteMedium
    clinicianType?: string
  }
  confirmedAppointment: null | ClinicalAppointment
  currentInviteId: null | string
  currentInvite: null | AppointmentInvite
}

const initialState: InitialState = {
  bookingChoices: {},
  confirmedAppointment: null,
  currentInviteId: null,
  currentInvite: null,
}

const reducer: Reducer<InitialState, ReturnType<ValueOf<typeof Actions>>> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BEGIN_BOOKING:
      return {
        ...state,
        currentInviteId: action.payload.inviteId,
        currentInvite: action.payload.invite,
      }
    case CLEAR_SELECTION:
      return {
        ...state,
        bookingChoices: Object.keys(state.bookingChoices).reduce((acc, key) => {
          if (action.payload.includes(key)) {
            return acc
          }
          return {
            ...acc,
            [key]: state.bookingChoices[key],
          }
        }, {}),
      }
    case SELECT_MEDIUM:
    case SELECT_CLINICIAN_TYPE:
    case SELECT_LOCATION:
    case SELECT_SLOT:
      return {
        ...state,
        bookingChoices: {
          ...state.bookingChoices,
          ...action.payload.options,
        },
      }
    case COMPLETE_BOOKING:
      return {
        ...state,
        confirmedAppointment: action.payload.appointment,
      }
    case UNDO_LAST_CONFIRMATION:
      return {
        ...state,
        confirmedAppointment: null,
      }
    default:
      return state
  }
}

export default reducer
