import React from 'react'

import styles from './NoContent.module.scss'

const NoContent = ({ icon: Icon, title, subtitle }) => (
  <div className={styles.noContent} data-testid="noContent">
    <figure className={styles.noContent__iconWrapper}>
      <Icon aria-hidden className={styles.noContent__icon} />
    </figure>
    <div className={styles.noContent__detail}>
      <p className={styles.noContent__title}>{title}</p>
      <p className={styles.noContent__subtitle}>{subtitle}</p>
    </div>
  </div>
)

export default NoContent
