import gql from 'graphql-tag'

export default gql`
  mutation postcodeLookup($postcode: String!) {
    postcodeLookup(postcode: $postcode) {
      addresses
      town
      county
      postcode
    }
  }
`
