import { Height, Weight } from '../components/Form/types'
import getHeightAndWeightAsMetric from './convertHeightAndWeight'

const calculateBMI = (height: Height, weight: Weight): number | undefined => {
  const measurementsAsMetric = getHeightAndWeightAsMetric(height, weight)

  if (
    !measurementsAsMetric ||
    !measurementsAsMetric.height ||
    !measurementsAsMetric.weight
  ) {
    return undefined
  }

  const heightInMeters = parseFloat(measurementsAsMetric.height) / 100

  if (!heightInMeters) return undefined

  const bmi =
    parseFloat(measurementsAsMetric.weight) / (heightInMeters * heightInMeters)

  return Math.round(bmi * 10) / 10
}
export default calculateBMI
