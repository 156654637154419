import { RouteType } from '@/routes/config/routeConfig'

const ROOT_PATH = '/book-instant'

export enum RouteId {
  NewAppointment = 'newInstantAppointment',
  SelectFormat = 'instantAppointmentSelectFormat',
  AppointmentReview = 'instantAppointmentReview',
  AppointmentBooked = 'instantAppointmentBooked',
  AppointmentReason = 'instantAppointmentReason',
}

export type RoutesType = {
  [key in RouteId]: Omit<RouteType, 'id'>
}

const routes: RoutesType = {
  [RouteId.NewAppointment]: {
    route: ROOT_PATH,
    requiredFields: [],
    isSkipped: () => false,
    getUrl: () => ROOT_PATH,
  },
  [RouteId.AppointmentReason]: {
    route: `${ROOT_PATH}/reason`,
    requiredFields: [],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Welcome',
    getUrl: () => `${ROOT_PATH}/reason`,
  },
  [RouteId.SelectFormat]: {
    route: `${ROOT_PATH}/format`,
    requiredFields: [],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Welcome',
    getUrl: () => `${ROOT_PATH}/format`,
  },
  [RouteId.AppointmentReview]: {
    route: `${ROOT_PATH}/review`,
    requiredFields: ['selectedPatient', 'preferred_date_time'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Finish & pay',
    getUrl: () => `${ROOT_PATH}/select`,
  },
  [RouteId.AppointmentBooked]: {
    route: `${ROOT_PATH}/:appointmentId/success`,
    requiredFields: ['selectedPatient', 'preferred_date_time'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    getUrl: ({ appointmentId }: { appointmentId: string }) =>
      `${ROOT_PATH}/${appointmentId}/success`,
  },
}

// The order of the elements in each array determines the order in which they appear in the booking flow

const routeConfig: RouteType[] = Object.keys(routes).map((routeId) => ({
  id: routeId,
  ...routes[routeId],
}))

const getRouteById = (id) => routeConfig.find((route) => route.id === id)

export {
  // exported for testing:
  getRouteById,
  // exported for consumption:
  routes,
  ROOT_PATH,
  routeConfig,
}
