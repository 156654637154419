/* eslint-disable react/prop-types */
import React from 'react'
import cn from 'classnames/bind'
import styles from './Spinner.module.scss'

const cx = cn.bind(styles)

type Props = {
  colour?: string
  size?: number
}

const Spinner: React.FC<Props> = ({ size = 18, colour = '#fff' }) => (
  <div className={cx('container')}>
    <svg
      width={size}
      height={size}
      stroke={colour}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={cx('spinner')}>
        <circle cx="12" cy="12" r="9.5" fill="none" strokeWidth="3" />
      </g>
    </svg>
  </div>
)

export default Spinner
