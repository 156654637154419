import React from 'react'
import cn from 'classnames'

import styles from './ListItem.module.scss'

interface Props {
  children: React.ReactNode
  className?: string
}

const ListItem: React.FC<Props> = ({ children, className, ...props }) => (
  <li className={cn(styles.listItem, className)} {...props}>
    {children}
  </li>
)

export default ListItem
