import { WINDOW_FLASH, RESET_WINDOW } from './constants'
import { RESET_STORE } from '../constants'

const initialState = {}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case RESET_STORE:
    case RESET_WINDOW:
      return { ...initialState }
    case WINDOW_FLASH:
      return {
        style: action.style,
        text: action.text,
      }
    default:
      return state
  }
}

export default reducer
