import { defineMessages } from 'react-intl'

export default defineMessages({
  height: {
    id: 'heightAndWeight.Height',
    defaultMessage: 'Height',
  },
  switchToCm: {
    id: 'heightAndWeight.switchToCm',
    defaultMessage: 'Switch to cm',
  },
  switchToftin: {
    id: 'heightAndWeight.switchToftin',
    defaultMessage: 'Switch to ft, in',
  },
  feet: {
    id: 'heightAndWeight.feet',
    defaultMessage: 'Feet',
  },
  inches: {
    id: 'heightAndWeight.inches',
    defaultMessage: 'Inches',
  },
  centimetres: {
    id: 'heightAndWeight.centimetres',
    defaultMessage: 'Centimetres',
  },
  weight: {
    id: 'heightAndWeight.Weight',
    defaultMessage: 'Weight',
  },
  switchToKg: {
    id: 'heightAndWeight.switchToKg',
    defaultMessage: 'Switch to kg',
  },
  switchToStLb: {
    id: 'heightAndWeight.switchToStLb',
    defaultMessage: 'Switch to st, lb',
  },
  stones: {
    id: 'heightAndWeight.stones',
    defaultMessage: 'Stones',
  },
  pounds: {
    id: 'heightAndWeight.pounds',
    defaultMessage: 'Pounds',
  },
  kilograms: {
    id: 'heightAndWeight.kilograms',
    defaultMessage: 'Kilograms',
  },
  bmi: {
    id: 'heightAndWeight.bmi',
    defaultMessage: 'Your BMI is',
  },
  beingCalculated: {
    id: 'heightAndWeight.beingCalculated',
    defaultMessage: 'being calculated',
  },
  weightWarning: {
    id: 'heightAndWeight.weightWarning',
    defaultMessage:
      "We'll ask you to verify your exact weight later in the signup process.",
  },
  heightImperialErrorMessage: {
    id: 'heightAndWeight.heightImperialErrorMessage',
    defaultMessage: 'Please enter a height between {min}ft and {max}ft.',
  },
  heightMetricErrorMessage: {
    id: 'heightAndWeight.heightMetricErrorMessage',
    defaultMessage: 'Please enter a height between {min}cm and {max}cm',
  },
  weightImperialErrorMessage: {
    id: 'heightAndWeight.weightImperialErrorMessage',
    defaultMessage: 'Please enter a weight between {min}st and {max}st.',
  },
  weightMetricErrorMessage: {
    id: 'heightAndWeight.weightMetricErrorMessage',
    defaultMessage: 'Please enter a weight between {min}kg and {max}kg',
  },
})
