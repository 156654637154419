import React from 'react'
import { useBabylonUser } from '.'
/** withBabylonUser(Component, options)
 *
 * A HOC to provide the login information
 *
 * for the user information details see the BabylonUser component
 *
 * @param Component - react components
 * @param options.name - the property name of the user object (default: 'user')
 *
 * @example:
 *
 * withBabylonUser(({ userInfo }) => <span>{userInfo.id}</span>, { name: 'userInfo' })
 */
const withBabylonUser = (Component: any) => (props: any) => {
  const user = useBabylonUser()

  return <Component {...props} user={user} />
}

export default withBabylonUser
