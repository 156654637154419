import React, { useEffect, useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import { Height, Weight, Measurements, MEASUREMENT_UNIT } from '../../types'
import calculateBMI from '@/programmes/utils/calculateBMI'
import HeightMeasurement from './HeightMeasurement'
import WeightMeasurement from './WeightMeasurement'
import { validateHeight, validateWeight } from '../../utils/validateAnswer'

interface HeightAndWeightProps {
  error?: boolean
  value?: Measurements
  onChange: (height: Height, weight: Weight) => void
}

const HeightAndWeight: React.FC<HeightAndWeightProps> = ({
  error = false,
  value,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [bmi, setBmi] = useState<string>('')

  useEffect(() => {
    if (!value || !value.height || !value.weight) {
      return
    }

    const bmi = calculateBMI(value.height, value.weight)

    if (!bmi) {
      setBmi('')
    } else {
      setBmi(bmi.toString())
    }
  }, [value])

  const handleHeightChange = (height: Height) => {
    onChange(
      height,
      value?.weight || {
        unit: MEASUREMENT_UNIT.IMPERIAL,
        kg: '',
        stones: '',
        pounds: '',
      }
    )
  }

  const handleWeightChange = (weight: Weight) => {
    onChange(
      value?.height || {
        unit: MEASUREMENT_UNIT.IMPERIAL,
        cm: '',
        feet: '',
        inches: '',
      },
      weight
    )
  }

  return (
    <div className={styles.containerV1}>
      <HeightMeasurement
        handleChange={handleHeightChange}
        error={(!value?.height || !validateHeight(value?.height)) && error}
        value={value?.height}
      />

      <span className={styles.dividerV1} />

      <WeightMeasurement
        handleChange={handleWeightChange}
        error={(!value?.weight || !validateWeight(value?.weight)) && error}
        value={value?.weight}
      />

      <div>
        <p className={styles.bmiDisclaimerV1}>
          {formatMessage(messages.bmi)}{' '}
          {bmi || formatMessage(messages.beingCalculated)}.
        </p>
        <p className={styles.bmiDisclaimerV1}>
          {formatMessage(messages.weightWarning)}
        </p>
      </div>
    </div>
  )
}

export default HeightAndWeight
