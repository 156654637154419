import { useEffect, useState } from 'react'
import { connect, FormikValues, useFormikContext } from 'formik'
import usePrevious from '../../utils/usePrevious'

interface Props {
  onChange: (
    currentState?: FormikValues,
    nextState?: FormikValues,
    changedKeys?: string[]
  ) => void
}

const FormikEffect = ({ onChange }: Props) => {
  const { values } = useFormikContext<FormikValues>()
  const [firstRender, setFirstRender] = useState(true)
  const previousValues = usePrevious(values)

  const previousKeys = Object.keys(previousValues || {})
  const valueKeys = Object.keys(values || {})

  const allKeys = [...previousKeys, ...valueKeys]
  const uniqueKeys = Array.from(new Set(allKeys))

  const changedKeys: string[] = []

  uniqueKeys.forEach((key) => {
    const prevValue = previousValues?.[key]
    const value = values?.[key]

    if (prevValue !== value) {
      changedKeys.push(key)
    }
  })

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    } else {
      onChange(previousValues, values, changedKeys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, values])

  return null
}

export default connect(FormikEffect)
