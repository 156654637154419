import React from 'react'
import classNames from 'classnames/bind'

import { Star, StarFilled } from '@babylon/icons'
import styles from './starRating.module.scss'

const cx = classNames.bind(styles)

const RatingItem = ({
  isActive,
  isReadOnly,
  isSelected,
  onSelect,
  starColor,
  value,
}) => (
  <li
    className={cx(styles.starRating__item, {
      'starRating__item--active': isActive,
      'starRating__item--selected': isSelected,
    })}
  >
    <button
      role="radio"
      aria-checked={isSelected}
      className={styles.starRating__control}
      onClick={onSelect}
      disabled={isReadOnly}
      tabIndex={isReadOnly ? -1 : 0}
    >
      {isActive ? (
        <StarFilled title={value} fill={starColor} />
      ) : (
        <Star title={value} fill={starColor} />
      )}
    </button>
  </li>
)

export default RatingItem
