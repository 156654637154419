/* eslint-disable react/no-array-index-key */
import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import styles from './asFormControl.module.scss'

const cx = classNames.bind(styles)

const asFormControl = (Component) => {
  const FormControl = ({ errors, success, tip, className, ...props }) => (
    <div className={cx('control', className || '')}>
      <Component errors={errors} success={success} tip={tip} {...props} />

      {tip && <div className={cx('control__tip')}>{tip}</div>}

      {success && <div className={cx('control__success')}>{success}</div>}

      {errors && errors.length > 0 && (
        <ul className={cx('errors')}>
          {errors.map(
            (error, index) =>
              error && (
                <li
                  className={cx('errors__item')}
                  key={`${index}-${error}`}
                  data-testid="formFieldError"
                >
                  {error}
                </li>
              )
          )}
        </ul>
      )}
    </div>
  )

  FormControl.propTypes = {
    /** The errors in the input */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** The success of the input */
    success: PropTypes.string,
  }

  FormControl.defaultProps = {
    tip: '',
    errors: [],
    success: '',
  }

  return FormControl
}

export default asFormControl
