import React from 'react'

export interface RouteConfig {
  path: string
  component: React.FC
  fallback?: React.FC
}

export enum Procedure {
  SHEMED_UTI = 'SHEMED_UTI',
}

export interface ProgrammeConfig {
  rootPath: string
  productBoxPath?: string
  procedures: Record<string, Procedure>
  routes: Record<string, RouteConfig>
  setupSteps: string[]
}
