import React, { useState, useEffect } from 'react'
import { initialise as initAuthClient } from '@babylon/auth0'
import { useAuth0Config, useServiceUrl } from '@/redux/selectors'
import Loading from './components/Loading'
import ErrorPage from './views/ErrorPage'

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [authFailed, setAuthFailed] = useState(false)
  const auth0Config = useAuth0Config()
  const servicesUrl = useServiceUrl()

  const coreAuth2Url = `${servicesUrl}/ai-auth/v2/register/jwt`

  useEffect(() => {
    const initialise = async () => {
      const getFragmentParameter = (frag) =>
        (window.location.hash &&
          new URLSearchParams(window.location.hash.substring(1)).get(frag)) ||
        undefined

      const idToken = getFragmentParameter('id_token')
      if (idToken) {
        window.location.hash = ''
      }

      const success = await initAuthClient(auth0Config, {
        url: coreAuth2Url,
        idToken,
      })

      setAuthFailed(!success)

      setLoading(false)
    }
    initialise()
  }, [auth0Config, servicesUrl, coreAuth2Url])

  if (loading) return <Loading />

  if (authFailed) return <ErrorPage embeddedView />

  return children
}

export default AuthProvider
