import React from 'react'
import cn from 'classnames'
import { Text } from '@babylon/medkit'
import style from './FullBleedSection.module.scss'

interface FullBleedSectionProps {
  children: React.ReactNode
  title?: string
}

const FullBleedSection = ({ children, title }: FullBleedSectionProps) => (
  <section className={cn(style.fullBleedSection)}>
    <div className={style.wrapper}>
      {title && (
        <Text variant="h2" className={cn(style.text, style.marginBottom)}>
          {title}
        </Text>
      )}
      {children}
    </div>
  </section>
)

export default FullBleedSection
