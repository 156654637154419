const password = 'Pa55word'

const facebookUser = {
  id: 123,
  email: 'test-facebook-user@babylonhealth.com',
  first_name: 'Facebook',
  last_name: 'User',
  region_id: 1,
  password,
  address_post_code: '',
} as const

const childFamilyMember = {
  id: 1111111,
  uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4592',
  email: '', // child family members do not have their own email address
  first_name: 'Child',
  last_name: 'User',
  date_of_birth: '2015-09-17',
  gender: 'female',
  region_id: 1,
  password,
  minor: true,
  preferred_consumer_network_id: 1,
  preferred_consumer_network_uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4600',
  address_post_code: '',
} as const

const vbcChildFamilyMember = {
  id: 333333333,
  uuid: '4148c252-b87b-4d6d-a264-bd9a2c5b358e',
  first_name: 'Child',
  last_name: 'User',
  gender: '',
  date_of_birth: '2014-01-01',
  email: '', // child family members do not have their own email address
  address_post_code: '12345',
} as const

const adultFamilyMember = {
  id: 22222222,
  uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4593',
  first_name: 'Adult',
  last_name: 'Daughter',
  date_of_birth: '1980-12-01',
  email: 'adult-daughter@babylonhealth.com',
  gender: 'female',
  region_id: 1,
  password,
  preferred_consumer_network_id: 1,
  preferred_consumer_network_uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4601',
  address_post_code: '',
} as const

const users = [childFamilyMember, adultFamilyMember, facebookUser]

const babylonUser = {
  email: 'b@by.lon',
  password,
  id: 123,
  uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4594',
  first_name: 'Babylon',
  last_name: 'Health',
  region_id: 1,
  preferred_consumer_network_id: 1,
  preferred_consumer_network_uuid: 'e6f02721-781c-4d12-8b93-8f66b7cb4602',
  vbc_consumer_network_uuid: 'd89fdc00-f1e8-40ad-8c35-b5e6fc07a344',
  address_post_code: 'SW33DD',
} as const

const babylonUserDetails = {
  date_of_birth: '1999-07-17',
  gender: 'female',
  phone_country_code: '+44',
  phone_number: '7911991919',
  postcode: 'SW33DD',
  address_post_code: 'SW33DD',
} as const

export {
  facebookUser,
  childFamilyMember,
  adultFamilyMember,
  users,
  babylonUser,
  babylonUserDetails,
  vbcChildFamilyMember,
}
