import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import classNames from 'classnames/bind'
import loadable from '@loadable/component'

import PrivateRoute from '@/components/Authentication'
import AppLayout from './AppLayout'
import HomePage from '@/components/HomePage'
import LandingHeader from '@/components/LandingHeader'
import NotificationsPage from '@/views/NotificationsPage'
import BookingInvitation from '@/components/Appointments/BookingInvitation'
import FeatureUnavailable from '@/views/FeatureUnavailable'
import {
  CheckOutHomePage,
  WeightLossCheckInAutomated,
  WeightLossCheckInConfirmationPage,
  WeightLossCheckInHomePage,
  WeightLossCheckInStartupConfirmationPage,
  WeightLossCheckInStartupHomePage,
  WeightLossDashboardHomePage,
  WeightLossRefillCheckInHomePage,
  WeightLossRefillConfirmationPage,
  WeightLossRefillHomePage,
  WeightLossRefillNextStepsPage,
  WeightLossRefillQuestionnairePage,
  WeightLossSetupConfirmationPage,
  WeightLossSetupErrorIneligiblePage,
  WeightLossSetupGPInfoPage,
  WeightLossSetupHomePage,
  WeightLossSetupNextStepsPage,
  WeightLossSetupQuestionnairePage,
  WeightLossSetupQuestionnaireV2Page,
  WeightLossSetupSunsetPage,
} from '@/programmes/WeightLoss'
import {
  WellnessCheckInAutomatedPage,
  WellnessCheckInConfirmationPage,
  WellnessCheckInPage,
  WellnessCheckoutConfirmationPage,
  WellnessCheckoutHomePage,
  WellnessHomePage,
  WellnessIneligiblePage,
  WellnessQuestionnairePage,
  WellnessReportPage,
} from '@/programmes/Wellness'
import {
  BloodTestCheckoutConfirmationPage,
  BloodTestCheckoutPage,
  BloodTestSetupConfirmationPage,
  BloodTestSetupHomePage,
} from '@/programmes/BloodTest'
import {
  WellnessV2ConfirmationPage,
  WellnessV2HomePage,
  WellnessV2SetupPage,
} from '@/programmes/Wellness/v2'
import { withPageTitle, withUserId } from '@/wrappers'
import {
  useAuthenticatedServiceNowChatEnabled,
  useBloodTestCheckoutEnabled,
  useBloodTestEnabled,
  useBookAppointmentEnabled,
  useQuestionnairesEnabled,
  useTestAndKitsEnabled,
  useWellnessEnabled,
} from '@/redux/selectors'
import { RequestAppointment } from '@/components/RequestAppointment'
import messages from './messages'

import styles from '@/App.module.scss'

import {
  account,
  appointments as getAppointmentsConfig,
  appointmentsStreamlined,
  clinicalRecords,
  instantAppointments,
} from './config'
import { ROOT_PATH as APPOINTMENTS_STREAMLINED_ROOT_PATH } from '@/routes/config/appointmentsStreamlined'
import { ROOT_PATH as INSTANT_APPOINTMENTS_ROOT_PATH } from '@/routes/config/instantAppointments'
import { ROOT_PATH as BOOKING_INVITE_ROOT_PATH } from '@/components/Appointments/BookingInvitation/routes'
import { ROOT_PATH as REPEAT_PRESCRIPTIONS_ROOT_PATH } from '@/components/RepeatPrescriptions/routes'
import { ROOT_PATH as TESTS_AND_KITS_ROOT_PATH } from '@/components/TestsAndKits'
import {
  checkoutRoutes as bloodTestCheckoutRoutes,
  setupRoutes as bloodTestSetupRoutes,
} from '@/programmes/BloodTest/routes'
import {
  checkInRoutes as weightLossCheckInRoutes,
  checkInStartupRoutes as weightLossCheckInStartupRoutes,
  checkoutRoutes as weightLossCheckoutRoutes,
  dashboardRoutes as weightLossDashboardRoutes,
  refillRoutes as weightLossRefillRoutes,
  setupRoutes as weightLossSetupRoutes,
  setupV2Routes as weightLossSetupV2Routes,
} from '@/programmes/WeightLoss/routes'
import {
  checkoutRoutes as wellnessCheckoutRoutes,
  routes as wellnessRoutes,
  v2Routes as wellnessV2Routes,
} from '@/programmes/Wellness/routes'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'
import DirectRegisterPage from '@/components/Authentication/Login/DirectRegisterPage'
import Questionnaire from '@/programmes/Wellness/Questionnaire'
import PaymentCompletionPage from '@/views/PaymentCompletionPage'
import ServiceNowChatPage from '@/views/ServiceNowChatPage'

const cx = classNames.bind(styles)

const ComponentWrapper = ({ Component, layout = {}, props }) => (
  <div
    className={cx(styles.view__inner, {
      'view__inner--vFlush': layout.vFlush,
      'view__inner--hFlush': layout.hFlush,
    })}
  >
    <Component {...props} />
  </div>
)

const ChatPage = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ '@babylon/async-chat').then(
    (module) => module.ChatPage
  )
)

/**
 *
 * @template T
 * @param {React.Component<T>} Component
 * @returns {React.FC<T>}
 */
const withLoggedOutLayout = (Component) => (props) => (
  <>
    <LandingHeader />
    <ComponentWrapper Component={Component} props={props} />
  </>
)

const withAppLayout = (Component) => (props) => (
  <AppLayout>
    <ComponentWrapper
      {...{
        Component,
        props,
      }}
    />
  </AppLayout>
)

const ReferAFriendPage = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ '@/views/ReferAFriendPage')
)

const RouteGroup = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ './RouteGroup')
)

const GpAtHandSummaryPage = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ '@/views/GpAtHandSummaryPage')
)

const RepeatPrescriptions = loadable(() =>
  import(
    /* webpackChunkName: "PrivatePages" */ '@/components/RepeatPrescriptions'
  )
)

const TestsAndKits = loadable(() =>
  import(/* webpackChunkName: "PrivatePages" */ '@/components/TestsAndKits')
)

const WeightLossRoutes = () => [
  <PrivateRoute
    key={weightLossSetupRoutes.home}
    exact
    path={weightLossSetupRoutes.home}
    component={withAppLayout(WeightLossSetupHomePage)}
    fallbackComponent={WeightLossSetupHomePage}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.sunset}
    exact
    path={weightLossSetupRoutes.sunset}
    component={withAppLayout(WeightLossSetupSunsetPage)}
    fallbackComponent={WeightLossSetupSunsetPage}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.questionnaire}
    exact
    path={weightLossSetupRoutes.questionnaire}
    component={withAppLayout(WeightLossSetupQuestionnairePage)}
    fallbackComponent={withLoggedOutLayout(WeightLossSetupQuestionnairePage)}
  />,
  <PrivateRoute
    key={weightLossSetupV2Routes.questionnaire}
    exact
    path={weightLossSetupV2Routes.questionnaire}
    component={withAppLayout(WeightLossSetupQuestionnaireV2Page)}
    fallbackComponent={withLoggedOutLayout(WeightLossSetupQuestionnaireV2Page)}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.nextSteps}
    exact
    path={weightLossSetupRoutes.nextSteps}
    component={withAppLayout(WeightLossSetupNextStepsPage)}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.gpInfo}
    exact
    path={weightLossSetupRoutes.gpInfo}
    component={withAppLayout(WeightLossSetupGPInfoPage)}
    fallbackComponent={WeightLossSetupHomePage}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.confirmation}
    exact
    path={weightLossSetupRoutes.confirmation}
    component={withAppLayout(WeightLossSetupConfirmationPage)}
    fallbackComponent={WeightLossSetupHomePage}
  />,
  <PrivateRoute
    key={weightLossSetupRoutes.errorIneligible}
    exact
    path={weightLossSetupRoutes.errorIneligible}
    component={withAppLayout(WeightLossSetupErrorIneligiblePage)}
    fallbackComponent={WeightLossSetupHomePage}
  />,
  <PrivateRoute
    key={weightLossCheckInStartupRoutes.home}
    exact
    path={weightLossCheckInStartupRoutes.home}
    component={withAppLayout(WeightLossCheckInStartupHomePage)}
    fallbackComponent={WeightLossCheckInStartupHomePage}
  />,

  <PrivateRoute
    key={weightLossCheckInRoutes.startAutomated}
    exact
    path={weightLossCheckInRoutes.startAutomated}
    component={withAppLayout(WeightLossCheckInAutomated)}
    fallbackComponent={WeightLossCheckInStartupHomePage}
  />,
  <PrivateRoute
    key={weightLossCheckInStartupRoutes.confirmation}
    exact
    path={weightLossCheckInStartupRoutes.confirmation}
    component={withAppLayout(WeightLossCheckInStartupConfirmationPage)}
    fallbackComponent={WeightLossCheckInStartupHomePage}
  />,
  <PrivateRoute
    key={weightLossCheckInRoutes.home}
    exact
    path={weightLossCheckInRoutes.home}
    component={withAppLayout(WeightLossCheckInHomePage)}
    fallbackComponent={WeightLossCheckInHomePage}
  />,
  <PrivateRoute
    key={weightLossCheckInRoutes.confirmation}
    exact
    path={weightLossCheckInRoutes.confirmation}
    component={withAppLayout(WeightLossCheckInConfirmationPage)}
    fallbackComponent={WeightLossCheckInHomePage}
  />,
  <PrivateRoute
    key={weightLossCheckoutRoutes.home}
    exact
    path={weightLossCheckoutRoutes.home}
    component={withAppLayout(CheckOutHomePage)}
  />,
  <PrivateRoute
    key={weightLossRefillRoutes.home}
    exact
    path={weightLossRefillRoutes.home}
    component={withAppLayout(WeightLossRefillHomePage)}
    fallbackComponent={WeightLossRefillHomePage}
  />,
  <PrivateRoute
    key={weightLossRefillRoutes.checkInHome}
    exact
    path={weightLossRefillRoutes.checkInHome}
    component={withAppLayout(WeightLossRefillCheckInHomePage)}
    fallbackComponent={WeightLossRefillCheckInHomePage}
  />,
  <PrivateRoute
    key={weightLossRefillRoutes.questionnaire}
    exact
    path={weightLossRefillRoutes.questionnaire}
    component={withAppLayout(WeightLossRefillQuestionnairePage)}
    fallbackComponent={WeightLossRefillHomePage}
  />,
  <PrivateRoute
    key={weightLossRefillRoutes.nextSteps}
    exact
    path={weightLossRefillRoutes.nextSteps}
    component={withAppLayout(WeightLossRefillNextStepsPage)}
    fallbackComponent={WeightLossRefillHomePage}
  />,
  <PrivateRoute
    key={weightLossRefillRoutes.confirmation}
    exact
    path={weightLossRefillRoutes.confirmation}
    component={withAppLayout(WeightLossRefillConfirmationPage)}
    fallbackComponent={WeightLossRefillHomePage}
  />,
  <PrivateRoute
    key={weightLossDashboardRoutes.home}
    exact
    path={weightLossDashboardRoutes.home}
    component={withAppLayout(WeightLossDashboardHomePage)}
  />,
]

const WellnessRoutes = () => [
  <PrivateRoute
    key={wellnessRoutes.home}
    exact
    path={wellnessRoutes.home}
    component={withAppLayout(WellnessHomePage)}
    fallbackComponent={WellnessHomePage}
  />,
  <PrivateRoute
    key={wellnessRoutes.questionnaire}
    exact
    path={wellnessRoutes.questionnaire}
    component={withAppLayout(WellnessQuestionnairePage)}
    fallbackComponent={WellnessHomePage}
  />,
  <PrivateRoute
    key={wellnessRoutes.checkIn}
    exact
    path={wellnessRoutes.checkIn}
    component={withAppLayout(WellnessCheckInPage)}
    fallbackComponent={WellnessCheckInPage}
  />,
  <PrivateRoute
    key={wellnessRoutes.checkInAutomated}
    exact
    path={wellnessRoutes.checkInAutomated}
    component={withAppLayout(WellnessCheckInAutomatedPage)}
  />,
  <PrivateRoute
    key={wellnessRoutes.confirmation}
    exact
    path={wellnessRoutes.confirmation}
    component={withAppLayout(WellnessCheckInConfirmationPage)}
    fallbackComponent={WellnessHomePage}
  />,
  <PrivateRoute
    key={wellnessRoutes.ineligible}
    exact
    path={wellnessRoutes.ineligible}
    component={withAppLayout(WellnessIneligiblePage)}
    fallbackComponent={WellnessHomePage}
  />,
  <PrivateRoute
    key={wellnessCheckoutRoutes.home}
    exact
    path={wellnessCheckoutRoutes.home}
    component={withAppLayout(WellnessCheckoutHomePage)}
    fallbackComponent={WellnessCheckoutHomePage}
  />,
  <PrivateRoute
    key={wellnessCheckoutRoutes.confirmation}
    exact
    path={wellnessCheckoutRoutes.confirmation}
    component={withAppLayout(WellnessCheckoutConfirmationPage)}
    fallbackComponent={WellnessHomePage}
  />,
  <PrivateRoute
    key={wellnessRoutes.dashboard}
    exact
    path={wellnessRoutes.dashboard}
    component={withAppLayout(WeightLossDashboardHomePage)}
  />,
]

const BloodTestCheckoutRoutes = () => [
  <PrivateRoute
    key={bloodTestCheckoutRoutes.home}
    exact
    path={bloodTestCheckoutRoutes.home}
    component={withAppLayout(BloodTestCheckoutPage)}
    fallbackComponent={BloodTestCheckoutPage}
  />,
  <PrivateRoute
    key={bloodTestCheckoutRoutes.confirmation}
    exact
    path={bloodTestCheckoutRoutes.confirmation}
    component={withAppLayout(BloodTestCheckoutConfirmationPage)}
    fallbackComponent={BloodTestCheckoutConfirmationPage}
  />,
]

const BloodTestSetupRoutes = () => [
  <PrivateRoute
    key={bloodTestSetupRoutes.home}
    exact
    path={bloodTestSetupRoutes.home}
    component={withAppLayout(BloodTestSetupHomePage)}
    fallbackComponent={BloodTestSetupHomePage}
  />,
  <PrivateRoute
    key={bloodTestSetupRoutes.confirmation}
    exact
    path={bloodTestSetupRoutes.confirmation}
    component={withAppLayout(BloodTestSetupConfirmationPage)}
    fallbackComponent={BloodTestSetupConfirmationPage}
  />,
]

const WellnessV2Routes = () => [
  <PrivateRoute
    key={wellnessV2Routes.home}
    exact
    path={wellnessV2Routes.home}
    component={withAppLayout(WellnessV2HomePage)}
    fallbackComponent={WellnessV2HomePage}
  />,
  <PrivateRoute
    key={wellnessV2Routes.setup}
    exact
    path={wellnessV2Routes.setup}
    component={withAppLayout(WellnessV2SetupPage)}
    fallbackComponent={WellnessV2HomePage}
  />,
  <PrivateRoute
    key={wellnessV2Routes.confirmation}
    exact
    path={wellnessV2Routes.confirmation}
    component={withAppLayout(WellnessV2ConfirmationPage)}
    fallbackComponent={WellnessV2HomePage}
  />,
  <PrivateRoute
    key={wellnessV2Routes.report}
    exact
    path={wellnessV2Routes.report}
    component={withAppLayout(WellnessReportPage)}
  />,
]

const UtiTestRoutes = () => {
  const routes = UTI_TEST.productBoxPath
    ? [
        <Redirect
          key={UTI_TEST.productBoxPath}
          exact
          from={UTI_TEST.productBoxPath}
          to={{ pathname: UTI_TEST.routes.home.path }}
        />,
      ]
    : []

  routes.push(
    Object.entries(UTI_TEST.routes).map(([key, routeConfig]) => (
      <PrivateRoute
        key={key}
        exact
        path={routeConfig.path}
        component={withAppLayout(routeConfig.component)}
        fallbackComponent={routeConfig.fallback}
      />
    ))
  )

  return routes
}

const Routes = () => {
  const isBookingAppointmentsEnabled = useBookAppointmentEnabled()
  const isBloodTestEnabled = useBloodTestEnabled()
  const isBloodTestCheckoutEnabled = useBloodTestCheckoutEnabled()
  const isWellnessEnabled = useWellnessEnabled()
  const isQuestionnairesEnabled = useQuestionnairesEnabled()
  const isServiceNowAuthenticatedChatEnabled = useAuthenticatedServiceNowChatEnabled()

  return (
    <Switch>
      <PrivateRoute
        path={account.route}
        key="account-route"
        component={withAppLayout(() => (
          <RouteGroup config={account} />
        ))}
      />
      <PrivateRoute
        key="clinical-records-route"
        path={clinicalRecords.route}
        component={withAppLayout(() => (
          <RouteGroup config={clinicalRecords} />
        ))}
      />
      <PrivateRoute
        key={instantAppointments.id}
        path={instantAppointments.route}
        fallbackComponent={DirectRegisterPage}
        component={withAppLayout(() => (
          <RouteGroup config={instantAppointments} />
        ))}
        componentProps={{ defaultRoute: INSTANT_APPOINTMENTS_ROOT_PATH }}
      />
      <PrivateRoute
        key={appointmentsStreamlined.id}
        path={appointmentsStreamlined.route}
        fallbackComponent={DirectRegisterPage}
        component={withAppLayout(() => (
          <RouteGroup config={appointmentsStreamlined} />
        ))}
        componentProps={{ defaultRoute: APPOINTMENTS_STREAMLINED_ROOT_PATH }}
      />
      {isBookingAppointmentsEnabled && (
        <PrivateRoute
          key="book-consultation-route"
          path={getAppointmentsConfig().route}
          fallbackComponent={DirectRegisterPage}
          component={withAppLayout(() => (
            <RouteGroup config={getAppointmentsConfig()} />
          ))}
        />
      )}
      <PrivateRoute
        key="notifications-route"
        path="/notifications/:pageNumber?"
        component={withAppLayout(NotificationsPage)}
      />
      <PrivateRoute
        key="feature-unavailable-route"
        exact
        path="/feature-unavailable"
        component={withAppLayout(FeatureUnavailable)}
      />
      <PrivateRoute
        key="gp-at-hand-application-summary-route"
        exact
        path="/gp-at-hand-application-summary"
        component={withAppLayout(GpAtHandSummaryPage)}
      />
      <PrivateRoute
        key="face-to-face-route"
        path={[`${BOOKING_INVITE_ROOT_PATH}`, '/face-to-face']}
        component={withAppLayout(BookingInvitation)}
      />
      <PrivateRoute
        key="repeat-prescriptions-root-route"
        path={REPEAT_PRESCRIPTIONS_ROOT_PATH}
        component={withAppLayout(RepeatPrescriptions)}
      />
      {useTestAndKitsEnabled() && (
        <PrivateRoute
          key="tests-and-kits-root-route"
          path={TESTS_AND_KITS_ROOT_PATH}
          component={withAppLayout(TestsAndKits)}
        />
      )}
      {useTestAndKitsEnabled() && (
        <PrivateRoute
          key="embed-tests-and-kits-route"
          path={`/embed${TESTS_AND_KITS_ROOT_PATH}`}
          component={TestsAndKits}
        />
      )}
      <PrivateRoute
        key="refer-a-friend-route"
        exact
        path="/refer-a-friend"
        component={withAppLayout(ReferAFriendPage)}
      />
      <PrivateRoute
        key="support-chat-route"
        exact
        path="/support-chat/:conversationType"
        component={withPageTitle(messages.asyncPageTitle)(withUserId(ChatPage))}
      />
      <PrivateRoute
        key="request-appointment"
        path="/request-appointment/:page"
        component={withAppLayout(RequestAppointment)}
      />
      <PrivateRoute
        key="root-fallback-route"
        exact
        path="/"
        component={withAppLayout(HomePage)}
      />
      {isBloodTestEnabled && BloodTestSetupRoutes()}
      {isBloodTestCheckoutEnabled && BloodTestCheckoutRoutes()}
      {isWellnessEnabled && WellnessRoutes()}
      {WeightLossRoutes()}
      {WellnessV2Routes()}
      {UtiTestRoutes()}
      {isQuestionnairesEnabled && (
        <PrivateRoute
          key="questionnaire"
          exact
          path="/questionnaire/:questionnaireId"
          component={withAppLayout(Questionnaire)}
        />
      )}
      <PrivateRoute
        key="payment-completion"
        exact
        path="/payment-completion/:id"
        component={withAppLayout(() => (
          <PaymentCompletionPage />
        ))}
      />
      {isServiceNowAuthenticatedChatEnabled && (
        <PrivateRoute
          key="chat-support"
          exact
          path="/chat-support"
          component={withAppLayout(() => (
            <ServiceNowChatPage />
          ))}
        />
      )}

      {/* These routes are printed on physical products and should not be changed */}
      <Redirect
        exact
        from="/start/demo/screen"
        to={{ pathname: '/screen/v2' }}
      />

      <Redirect to={{ pathname: '/' }} />
    </Switch>
  )
}

export { default as Status } from './Status'
export default Routes
