import React, { useState } from 'react'
import { MessageDescriptor, IntlFormatters, injectIntl } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import PageTitle from '@/components/PageTitle/PageTitle'

interface TitleGetter {
  (props: any): string
}

type TitleType = MessageDescriptor | string | TitleGetter | undefined

interface PageTitleProps {
  title?: TitleType
  prefix?: TitleType
  suffix?: TitleType
  separator?: TitleType
}

const getTitleString = (
  title: TitleType,
  translate: IntlFormatters['formatMessage'],
  props: any
): string | undefined => {
  if (!title) {
    return undefined
  }

  switch (typeof title) {
    case 'string':
      return title
    case 'function':
      return title(props)
    case 'object':
      return translate(title)
    default:
      return undefined
  }
}

const withPageTitle = (
  title: TitleType,
  { prefix, suffix, separator }: PageTitleProps = {}
) => (Component: React.ComponentType<any>) =>
  injectIntl((props: any) => {
    const translate = useFormatMessage()
    const initialTitle = getTitleString(title, translate, props)
    const [pageTitle, setCurrentPageTitle] = useState(initialTitle)

    const updatePageTitle = (newTitle: TitleType) => {
      setCurrentPageTitle(getTitleString(newTitle, translate, props))
    }

    const prefixToUse = getTitleString(prefix, translate, props)
    const suffixToUse = getTitleString(suffix, translate, props)
    const separatorToUse = getTitleString(separator, translate, props)

    return (
      <>
        <PageTitle
          title={pageTitle}
          prefix={prefixToUse}
          suffix={suffixToUse}
          separator={separatorToUse}
        />
        <Component
          {...props}
          pageTitle={pageTitle}
          updatePageTitle={updatePageTitle}
        />
      </>
    )
  })

export default withPageTitle
