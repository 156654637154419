import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Loading from '../Loading'

// Lazy load reports page
const Reports = React.lazy(() =>
  import(
    /* webpackChunkName: "testkits-reports" */
    /* webpackPrefetch: true */
    './components/Reports'
  )
)

const TestsAndKits = () => (
  <Switch>
    {['/tests', '/embed/tests'].map((path) => (
      <Route
        key="testsAndKits"
        path={path}
        render={() => (
          <Suspense fallback={<Loading />}>
            <Reports />
          </Suspense>
        )}
      />
    ))}
    <Redirect to="/tests" />
  </Switch>
)

export const ROOT_PATH = '/tests'

export default TestsAndKits
