import {
  getDimensions,
  getOffset,
  getScroll,
  getViewportDimensions,
} from './position'

const SCROLL_ANIMATION_INTERVAL = 16.66 // (1000 / 60)

/* animated scroll helper - not exported, see scrollToPos for usage */
const _animateScrollToPos = ({ el, pos, axis, onFinish }) => {
  const distance = pos - getScroll(el)[axis]
  const isBackwards = distance < 0
  const distancePerMs = distance / SCROLL_ANIMATION_INTERVAL

  const timer = setInterval(() => {
    const currentScroll = getScroll(el)
    let nextPos = Math.ceil(currentScroll[axis] + distancePerMs)

    // make sure we don't pass our final position
    if ((isBackwards && nextPos <= pos) || (!isBackwards && nextPos >= pos)) {
      nextPos = pos
      clearInterval(timer)
      if (typeof onFinish === 'function') onFinish()
    }

    if (el) {
      el[axis === 'x' ? 'scrollLeft' : 'scrollTop'] = nextPos
    } else {
      window.scrollTo(
        axis === 'x' ? nextPos : currentScroll.x,
        axis === 'y' ? nextPos : currentScroll.y
      )
    }
  }, SCROLL_ANIMATION_INTERVAL)
}

/* non-animated scroll helper - not exported, see scrollToPos for usage */
const _immediateScrollToPos = ({ el, x, y }) => {
  if (el) {
    if (!isNaN(x)) el.scrollLeft = x
    if (!isNaN(y)) el.scrollTop = y
  } else {
    window.scrollTo(isNaN(x) ? getScroll().x : x, isNaN(y) ? getScroll().y : y)
  }
}

/*
  performs DOM side effect:
    scrolls the page to the position of the given element
*/
const scrollToEl = (
  el,
  {
    // scroll vertifcally but not horizontally by default
    x,
    y = true,
    /* when true, if the current scroll pos is above or to the left
    of the respective requested coords, don't scroll to them */
    shouldNotScrollForwards,
    /* when true, if the current scroll pos is below or to the right
    of the respective requested coords, don't scroll to them */
    shouldNotScrollBackwards,
    // when true, the view will be animated smoothly towards the position(s)
    shouldAnimate,
    /* when true, the scroll position will only be enough to
    bring the element fully into view at the edge of the viewport
    rather than all the way across or down to the start of the element */
    bringIntoView,
  } = {}
) => {
  if (!el) return

  const currentScroll = getScroll()
  const offset = getOffset(el)

  /* when bringIntoView is true, find out how far
  the el's far edge overlaps the available viewport,
  and set the scroll to be just far enough to show it */
  let scrollToX =
    x && bringIntoView
      ? offset.x +
        getDimensions(el, { withMargin: true }).w -
        getViewportDimensions().w
      : offset.x
  let scrollToY =
    y && bringIntoView
      ? offset.y +
        getDimensions(el, { withMargin: true }).h -
        getViewportDimensions().h
      : offset.y

  /* respect optional flags not to scroll forwards or backwards
  to reach element from existing scroll position */
  if (shouldNotScrollForwards) {
    if (scrollToX > currentScroll.x) scrollToX = currentScroll.x
    if (scrollToY > currentScroll.y) scrollToY = currentScroll.y
  } else if (shouldNotScrollBackwards) {
    if (scrollToX < currentScroll.x) scrollToX = currentScroll.x
    if (scrollToY < currentScroll.y) scrollToY = currentScroll.y
  }

  // only scroll to axis that have been requested
  scrollToPos(x ? scrollToX : undefined, y ? scrollToY : undefined, {
    shouldAnimate,
  })
}

/*
  performs DOM side effect:
    scrolls the given element or else the current page
    to the given x y co-ordinates
*/
const scrollToPos = (x, y, { el, shouldAnimate } = {}) => {
  if (!el && typeof window.scrollTo !== 'function') return

  if (shouldAnimate) {
    // don't attempt to animate both scroll positions at once!
    // animate them sequentially, if the event that both are changing
    if (!isNaN(x)) {
      _animateScrollToPos({
        el,
        pos: x,
        axis: 'x',
        onFinish: !isNaN(y) && _animateScrollToPos({ el, pos: y, axis: 'y' }),
      })
    } else if (!isNaN(y)) {
      _animateScrollToPos({ el, pos: y, axis: 'y' })
    }
  } else {
    _immediateScrollToPos({ el, x, y })
  }
}

export { scrollToEl, scrollToPos }
