import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'DiscoverSection.title',
    defaultMessage: 'Discover',
  },
  subscriptionTitle: {
    id: 'DiscoverSection.subscription.title',
    defaultMessage: 'eMed Subscriptions',
  },
  subscriptionSubtitle: {
    id: 'DiscoverSection.subscription.subtitle',
    defaultMessage: 'GP, Mental Health & Physio. All included',
  },
  weightManagementTitle: {
    id: 'DiscoverSection.weightManagement.title',
    defaultMessage: 'Weight Management',
  },
  weightManagementSubtitle: {
    id: 'DiscoverSection.weightManagement.subtitle',
    defaultMessage:
      'Safety, support and science: the 3 pillars of weight management success',
  },
})
