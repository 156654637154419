import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import QuestionBody from '../QuestionBody'
import styles from './TextArea.module.scss'
import messages from './messages'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import Error from '@/programmes/components/FormV2/components/Error/Error'

interface TextAreaProps {
  label?: string
  body?: string | Array<string>
  value?: string
  required?: boolean
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  body,
  value = '',
  required = false,
  error = false,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [showError, setShowError] = useState(false)
  const [textareaValue, setTextareaValue] = useState(value)

  useEffect(() => {
    if (textareaValue && showError) {
      setShowError(false)
    }
  }, [textareaValue, showError])

  const onBlur = () => {
    if (required && !textareaValue) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)

    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div key={label} className={styles.container}>
      {label && <QuestionTitle content={label} type="label" />}

      {body && <QuestionBody body={body} />}

      <textarea
        data-testid={`textarea-${label}`}
        id={label}
        value={textareaValue}
        onBlur={onBlur}
        onChange={handleOnChange}
        className={cn(
          styles.textArea,
          !textareaValue && (showError || error) && styles.error
        )}
      />

      {!textareaValue && (showError || error) && (
        <Error>{formatMessage(messages.errorMessage)}</Error>
      )}
    </div>
  )
}

export default TextArea
