import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIsAutomatedCheckInEnabledForScreen } from '@/redux/selectors'
import useGetPreferredConsumerNetwork from '@/programmes/hooks/useGetPreferredConsumerNetwork'
import { routes } from './routes'

const SETUP_HOME_KEY = 'wellness-home-visited'

const useHandleSetupHomeRedirect = () => {
  const history = useHistory()
  const {
    getPreferredConsumerNetwork,
    loading,
  } = useGetPreferredConsumerNetwork()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  const handleSetupHomeRedirect = useCallback(() => {
    if (!isAuthenticated) {
      return
    }

    const preferredConsumerNetwork = getPreferredConsumerNetwork()

    if (!preferredConsumerNetwork) {
      return
    }

    // some sent-with-love CNs have a different format e.g. have a space in their name
    const isSentWithLove = preferredConsumerNetwork.name
      .replaceAll(' ', '')
      .toLowerCase()
      .includes('sentwithlove')

    if (!isSentWithLove) {
      history.push(routes.ineligible)

      return
    }

    if (localStorage.getItem(SETUP_HOME_KEY) === 'true') {
      history.push(routes.questionnaire)
    } else {
      localStorage.setItem(SETUP_HOME_KEY, 'true')
    }
  }, [isAuthenticated, getPreferredConsumerNetwork, history])

  return { handleSetupHomeRedirect, loading }
}

const useHandleCheckInRedirect = (pathname: string) => {
  const history = useHistory()
  const automatedCheckInEnabled = useIsAutomatedCheckInEnabledForScreen()

  const handleCheckInRedirect = useCallback(() => {
    const nextRoute = automatedCheckInEnabled
      ? routes.checkInAutomated
      : routes.checkIn

    if (pathname === nextRoute) {
      return
    }

    history.push(nextRoute)
  }, [automatedCheckInEnabled, pathname, history])

  return { handleCheckInRedirect }
}

const useHandlePageVisitAndRedirection = () => {
  const location = useLocation()
  // remove trailing slash if it exists
  const pathname = location.pathname.replace(/\/$/, '')
  const { handleSetupHomeRedirect, loading } = useHandleSetupHomeRedirect()
  const { handleCheckInRedirect } = useHandleCheckInRedirect(pathname)

  useEffect(() => {
    if (pathname === routes.home) {
      handleSetupHomeRedirect()
    }

    if (pathname === routes.checkIn) {
      handleCheckInRedirect()
    }
  }, [loading, pathname, handleSetupHomeRedirect, handleCheckInRedirect])

  return { loading }
}

export default useHandlePageVisitAndRedirection
