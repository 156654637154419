import { defineMessages } from 'react-intl'

export default defineMessages({
  greetingMorning: {
    id: 'WelcomeBanner.morning',
    defaultMessage: 'Good morning',
  },
  greetingAfternoon: {
    id: 'WelcomeBanner.afternoon',
    defaultMessage: 'Good afternoon',
  },
  greetingEvening: {
    id: 'WelcomeBanner.evening',
    defaultMessage: 'Good evening',
  },
  bookInMinutes: {
    id: 'WelcomeBanner.bookInMinutes',
    defaultMessage: `You could talk to a GP in just {time} {type, select,
        minutes {{time, plural,
          one {minute}
          other {minutes}}}
        other {{time, plural,
          one {hour}
          other {hours}}}}.`,
  },
  bookNow: {
    id: 'WelcomeBanner.bookNow',
    defaultMessage: 'Book now',
  },
  bookAppointment: {
    id: 'WelcomeBanner.bookAppointment',
    defaultMessage: 'Book Appointment',
  },
  weightManagementJourney: {
    id: 'WelcomeBanner.weightManagementJourney',
    defaultMessage: 'My Weight Management Journey',
  },
  currentMembership: {
    id: 'WelcomeBanner.currentMembership',
    defaultMessage: 'Current membership:',
  },
})
