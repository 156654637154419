const actionTypes = {
  PURCHASED_SUBSCRIPTION: 'PURCHASED_SUBSCRIPTION',
}

interface Subscription {
  price: string
  planId: string
}

const subscriptionPurchased = (subscription: Subscription) => ({
  type: actionTypes.PURCHASED_SUBSCRIPTION,
  payload: subscription,
})

const selectPurchasedSubscription = (state) => state.membership.subscription

const initialState = {
  subscription: null,
}

const membershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PURCHASED_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      }
    default:
      return state
  }
}

export { membershipReducer, subscriptionPurchased, selectPurchasedSubscription }
