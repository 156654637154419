import {
  UtiTestEligibilityQuestionnairePage,
  UtiTestIneligiblePage,
  UtiTestSetupActivation,
  UtiTestSetupConfirmationPage,
  UtiTestSetupHomePage,
} from '@/programmes/UrinaryTractInfection/index'
import { Procedure, ProgrammeConfig, RouteConfig } from '@/programmes/types'

const ROOT_PATH = '/uti-test'

interface UtiProgrammeConfig extends ProgrammeConfig {
  routes: {
    home: RouteConfig
    questionnaire: RouteConfig
    activate: RouteConfig
    confirmation: RouteConfig
    ineligible: RouteConfig
  }
}

const UTI_TEST: UtiProgrammeConfig = {
  rootPath: ROOT_PATH,
  productBoxPath: '/start/tests/uti',
  procedures: {
    setup: Procedure.SHEMED_UTI,
  },
  setupSteps: ['Get Ready', 'Test', 'Clinical Review', 'Treat'],
  routes: {
    home: {
      path: `${ROOT_PATH}/setup`,
      component: UtiTestSetupHomePage,
      fallback: UtiTestSetupHomePage,
    },
    questionnaire: {
      path: `${ROOT_PATH}/setup/questionnaire`,
      component: UtiTestEligibilityQuestionnairePage,
    },
    activate: {
      path: `${ROOT_PATH}/setup/activate`,
      component: UtiTestSetupActivation,
    },
    confirmation: {
      path: `${ROOT_PATH}/setup/confirmation`,
      component: UtiTestSetupConfirmationPage,
    },
    ineligible: {
      path: `${ROOT_PATH}/setup/ineligible`,
      component: UtiTestIneligiblePage,
    },
  },
}

export default UTI_TEST
