import { trackEvent } from '@babylon/tracking/gtag'
import { observeStore } from '@/redux/utils'
import {
  startedRegistrationMethod,
  completedRegistrationMethod,
} from './selectors'

const trackRegistrationStarted = (signupMethod) => {
  if (signupMethod) {
    trackEvent({
      category: 'signup',
      action: 'started signup',
      signupMethod,
    })
  }
}

const trackRegistrationCompleted = ({ signupMethod, promocode = null }) => {
  if (signupMethod) {
    trackEvent({
      category: 'signup',
      action: 'completed signup',
      signupMethod,
      promotion: promocode,
    })
  }
}

const track = (store) => {
  observeStore(store, startedRegistrationMethod, trackRegistrationStarted)
  observeStore(store, completedRegistrationMethod, trackRegistrationCompleted)
}

export default track
