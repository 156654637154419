import gql from 'graphql-tag'

export default gql`
  query getReferFriendUrl(
    $userId: ID
    $situation: String
    $userProvidedDetails: UserProvidedDetails
  ) {
    referFriend(
      patientId: $userId
      situation: $situation
      userProvidedDetails: $userProvidedDetails
    ) {
      referUrl
    }
  }
`
