import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames/bind'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import {
  AppointmentNew,
  Logout,
  Notification,
  Question,
  Chat,
} from '@babylon/icons'
import { LinkButton } from '@babylon/medkit'

import { TrackingActionType } from '@babylon/tracking/react'
import ExternalLink from '../../Link'
import { logout } from '../../Authentication/actions'

import messages from './Navigation.messages'
import styles from './Navigation.module.scss'
import {
  useBookAppointmentEnabled,
  useNotificationsEnabled,
  useCustomerSupportEnabled,
  useAuthenticatedSnowEntryPointEnabled,
} from '@/redux/selectors'
import useTracking from '@/tracking/useTracking'

const cx = classNames.bind(styles)

const enhance = compose(
  injectIntl,
  connect(null, (dispatch) => ({
    logout: () => dispatch(logout()),
  }))
)

const Navigation = ({
  mobileNavOpened,
  onMobileNavClick,
  logout,
  showBookingLink,
  unreadNotifications,
  className,
  intl,
}) => {
  const { pathname } = useLocation()
  const isBookAppointmentsEnabled = useBookAppointmentEnabled()
  const isNotificationsEnabled = useNotificationsEnabled()
  const isCustomerSupportEnabled = useCustomerSupportEnabled()

  // TODO: how can we disentangle ourselves from featureSwitches
  const showAppointments = showBookingLink && isBookAppointmentsEnabled
  const { trackEvent } = useTracking()
  /**
   * @type {React.MouseEventHandler}
   */
  const onBookAppointment = useCallback(
    (evt) => {
      trackEvent({
        screenTitle: 'header-component',
        eventAction: 'book-appointment',
        actionType: TrackingActionType.click,
      })
      onMobileNavClick(evt)
    },
    [trackEvent, onMobileNavClick]
  )

  const isServiceNowChatEnabled = useAuthenticatedSnowEntryPointEnabled()

  return (
    <nav
      data-testid="mobileNavigation"
      id="mainMenu" // for aria reference
      className={cx(
        mobileNavOpened ? 'opened' : null,
        styles.navigation,
        className
      )}
    >
      <ul
        className={cx(
          mobileNavOpened ? 'opened' : null,
          styles.navigation__links
        )}
      >
        {showAppointments && (
          <li className={styles.navigation__item}>
            <Link
              data-testid="navigationBookAppointment"
              to={{
                pathname: '/consultations/new',
                state: { referrer: pathname },
              }}
              className={cx(styles.itemAppointments)}
              onClick={onBookAppointment}
            >
              <AppointmentNew className={styles.navigation__icon} aria-hidden />
              {intl.formatMessage(messages.newAppointment)}
            </Link>
          </li>
        )}

        {isNotificationsEnabled && (
          <li className={styles.navigation__item}>
            <Link
              data-testid="navigationNotifications"
              to="/notifications"
              className={cx(styles.item)}
              onClick={onMobileNavClick}
            >
              <span>
                <Notification className={styles.navigation__icon} aria-hidden />
                {intl.formatMessage(messages.notifications)}
                {unreadNotifications && (
                  <span className={styles.navigation__itemAlert}>
                    {intl.formatMessage(messages.notificationsUnread)}
                  </span>
                )}
              </span>
            </Link>
          </li>
        )}

        {isCustomerSupportEnabled && (
          <li className={cx('hide-on-desktop', styles.navigation__item)}>
            <ExternalLink
              data-testid="navigationHelp"
              href="https://support.babylonhealth.com/cspbabylonsupport"
              target="_blank"
              className={cx(styles.itemHelp)}
              onClick={onMobileNavClick}
            >
              <Question className={styles.navigation__icon} aria-hidden />
              {intl.formatMessage(messages.help)}
            </ExternalLink>
          </li>
        )}
        {isServiceNowChatEnabled && (
          <li className={cx('hide-on-desktop', styles.navigation__item)}>
            <Link
              data-testid="navigationChatSupport"
              to="/chat-support"
              onClick={onMobileNavClick}
            >
              <Chat className={styles.navigation__icon} aria-hidden />
              {intl.formatMessage(messages.supportChat)}
            </Link>
          </li>
        )}
        <li className={cx('hide-on-desktop', styles.navigation__item)}>
          {/* TO-DO: find solution for accessibility */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <LinkButton
            isStandaloneLink
            data-testid="navigationLogout"
            onClick={logout}
          >
            <Logout className={styles.navigation__icon} aria-hidden />
            {intl.formatMessage(messages.logout)}
          </LinkButton>
        </li>
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  mobileNavOpened: PropTypes.bool,
  onMobileNavClick: PropTypes.func,
}

export default enhance(Navigation)
