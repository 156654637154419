import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'ConsultationErrorPage.heading',
    defaultMessage: 'We cannot connect you',
  },
  nextStepsTitle: {
    id: 'ConsultationErrorPage.nextStepsTitle',
    defaultMessage: 'The clinician will try to:',
  },
  nextStepsFirstBulletPoint: {
    id: 'ConsultationErrorPage.nextStepsFirstBulletPoint',
    defaultMessage: 'Send you another text message',
  },
  nextStepsSecondBulletPoint: {
    id: 'ConsultationErrorPage.nextStepsSecondBulletPoint',
    defaultMessage: 'Call you by phone',
  },
  problemPersistsTitle: {
    id: 'ConsultationErrorPage.problemPersistsTitle',
    defaultMessage: 'If you still have problems',
  },
  problemPersistsDescription: {
    id: 'ConsultationErrorPage.problemPersistsDescription',
    defaultMessage: 'Call us to reschedule your appointment.',
  },
})
