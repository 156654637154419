import React from 'react'

import { IMedkitComponent } from '..'
import { buttonTheme } from './theme'
import getButtonClassNames from './utils/getButtonClassNames'
import { ButtonVariant } from '.'

import { IButtonLinkProps } from './Button.types'

export const ButtonLink: IMedkitComponent<
  IButtonLinkProps,
  typeof buttonTheme
> = ({
  children,
  className,
  fullWidth = false,
  halfWidth = false,
  loadingLabel,
  variant = ButtonVariant.primary,
  ...props
}: IButtonLinkProps) => (
  <a
    className={getButtonClassNames({
      variant,
      fullWidth,
      halfWidth,
      className,
    })}
    {...props}
  >
    {children}
  </a>
)

ButtonLink.theme = buttonTheme

export default ButtonLink
