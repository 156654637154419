import { AppointmentMedium } from '@babylon/consumer-api/types'
import { RouteType } from '@/routes/config/routeConfig'
import { BookingState } from './redux/reducer'

const DEFAULT_ROOT_PATH = '/consultations/new'

export enum RouteId {
  AppointmentReason = 'appointmentReason',
  AppointmentReview = 'appointmentReview',
  Disclaimer = 'appointmentDisclaimer',
  NewAppointment = 'newAppointment',
  SelectServiceTypeCategory = 'selectServiceTypeCategory',
  RecommendedClinician = 'recommendedClinician',
  RecommendedServiceType = 'recommendedServiceType',
  SelectSlot = 'selectSlot',
  SelectClinician = 'selectClinician',
  SelectLocation = 'selectLocation',
  SelectCategory = 'selectCategory',
  SelectFormat = 'selectFormat',
  SelectPatient = 'selectPatient',
  SelectAppointmentType = 'selectAppointmentType',
  Prefill = 'prefill',
}

export type RoutesType = {
  [key in RouteId]: Omit<RouteType, 'id'>
}

const getRoutes = (prefix?: string): RoutesType => {
  const ROOT_PATH = prefix
    ? `/${prefix}${DEFAULT_ROOT_PATH}`
    : DEFAULT_ROOT_PATH

  return {
    [RouteId.NewAppointment]: {
      route: ROOT_PATH,
      getRedirectUrl: () => `${ROOT_PATH}/patient`,
      requiredFields: [],
      isSkipped: () => false,
      getUrl: () => ROOT_PATH,
    },
    [RouteId.SelectPatient]: {
      route: `${ROOT_PATH}/patient`,
      requiredFields: [],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/patient`,
    },
    [RouteId.SelectAppointmentType]: {
      route: `${ROOT_PATH}/appointment-type`,
      requiredFields: ['selectedPatient'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/appointment-type`,
    },
    [RouteId.AppointmentReason]: {
      route: `${ROOT_PATH}/reason`,
      requiredFields: ['selectedPatient'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/reason`,
    },
    [RouteId.SelectCategory]: {
      route: `${ROOT_PATH}/category`,
      requiredFields: ['selectedPatient'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/category`,
    },
    [RouteId.SelectServiceTypeCategory]: {
      route: `${ROOT_PATH}/service-type-category`,
      requiredFields: ['selectedPatient'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/service-type-category`,
    },
    [RouteId.Disclaimer]: {
      route: `${ROOT_PATH}/disclaimer`,
      requiredFields: ['selectedPatient', 'category'],
      isSkipped: (state) =>
        Boolean(state.category && state.category.key !== 'other'),
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/disclaimer`,
    },
    [RouteId.SelectClinician]: {
      route: `${ROOT_PATH}/clinician-type`,
      requiredFields: ['selectedPatient'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/clinician-type`,
    },
    [RouteId.RecommendedClinician]: {
      route: `${ROOT_PATH}/recommended-consultant`,
      requiredFields: ['selectedPatient', 'category'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/recommended-consultant`,
    },
    [RouteId.RecommendedServiceType]: {
      route: `${ROOT_PATH}/recommended-service-type`,
      requiredFields: ['selectedPatient', 'serviceTypeCategory'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/recommended-service-type`,
    },
    [RouteId.SelectSlot]: {
      route: `${ROOT_PATH}/slot`,
      requiredFields: ['selectedPatient', 'clinicianCriteria', 'patient_note'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/slot`,
    },
    [RouteId.SelectLocation]: {
      route: `${ROOT_PATH}/location`,
      requiredFields: ['selectedPatient', 'clinicianCriteria', 'medium'],
      isSkipped: (bookingState: BookingState) => {
        const result =
          !!bookingState.medium &&
          bookingState.medium !== AppointmentMedium.Physical

        return result
      },
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/location`,
    },
    [RouteId.SelectFormat]: {
      route: `${ROOT_PATH}/format`,
      requiredFields: ['selectedPatient', 'clinicianCriteria'],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/format`,
    },
    [RouteId.Prefill]: {
      route: `${ROOT_PATH}/prefill`,
      requiredFields: [],
      // should only be accessable via direct link
      isSkipped: () => true,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/prefill`,
    },
    [RouteId.AppointmentReview]: {
      route: `${ROOT_PATH}/review`,
      requiredFields: [
        'selectedPatient',
        'clinicianCriteria',
        'patient_note',
        'preferred_date_time',
        'medium',
      ],
      isSkipped: () => false,
      breadcrumb: { isExcluded: true },
      getUrl: () => `${ROOT_PATH}/review`,
    },
  }
}

const routeConfig = (routePrefix?: string): RouteType[] => {
  const routes = getRoutes(routePrefix)

  return Object.keys(routes).map((routeId) => ({
    id: routeId,
    ...routes[routeId],
  }))
}

const getRouteById = (id) => routeConfig().find((route) => route.id === id)

export {
  // exported for testing:
  getRouteById,
  // exported for consumption:
  getRoutes,
  routeConfig,
  DEFAULT_ROOT_PATH as ROOT_PATH,
}
