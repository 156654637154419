const assignDefinitionToTypes = (definition, types) => {
  const definitionMap = {}

  types.forEach((type) => {
    definitionMap[type] = definition
  })

  return definitionMap
}

export default assignDefinitionToTypes
