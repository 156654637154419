import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import InputBody from '../InputBody'
import Checkbox from '../Checkbox'
import FollowUpQuestion from '../FollowUpQuestion'
import {
  CheckBoxGroupValue,
  RadioGroupValue,
  Question,
  Answer,
} from '../../types'
import styles from './CheckboxGroup.module.scss'
import messages from './messages'
import { STEP_ELEMENT_ID_PREFIX } from '../../constants'

interface CheckboxGroupProps {
  id: string
  label?: string
  body?: string | Array<string>
  error?: boolean
  options: Array<{
    label: string
    value: any
    followUpQuestion?: Question
    exclusive?: boolean
  }>
  selectedOptions?: Array<CheckBoxGroupValue>
  onChange?: (selectedOptions: Array<CheckBoxGroupValue>) => void
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  id,
  label,
  body,
  error = false,
  options,
  selectedOptions,
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const [checkedValues, setCheckedValues] = useState<CheckBoxGroupValue[]>(
    selectedOptions || []
  )
  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    exclusive: boolean
  ) => {
    let newCheckedValues: CheckBoxGroupValue[]

    if (event.target.checked && exclusive) {
      newCheckedValues = [{ value: event.target.value }]
    } else if (event.target.checked && !exclusive) {
      const nonExclusiveValues = checkedValues.filter(
        (checkedValue) =>
          !options.find((option) => option.value === checkedValue.value)
            ?.exclusive
      )
      newCheckedValues = [...nonExclusiveValues, { value: event.target.value }]
    } else {
      newCheckedValues = checkedValues.filter(
        (checkedValue) => checkedValue.value !== event.target.value
      )
    }

    setCheckedValues(newCheckedValues)

    if (onChange) {
      onChange(newCheckedValues)
    }
  }
  const handleFollowUpQuestionOnChange = (
    value: string,
    followUpQuestionValue:
      | string
      | boolean
      | CheckBoxGroupValue[]
      | RadioGroupValue
  ) => {
    const updatedCheckedValues = checkedValues.map((checkedValue) =>
      checkedValue.value === value
        ? { value, followUpQuestionValue }
        : checkedValue
    )
    setCheckedValues(updatedCheckedValues)

    if (onChange) {
      onChange(updatedCheckedValues)
    }
  }

  const isChecked = (value: string) => {
    const result = checkedValues.find((checked) => checked.value === value)

    return !!result
  }

  const getFollowUpQuestionValue = (value: string) =>
    checkedValues.find((checked) => checked.value === value)
      ?.followUpQuestionValue

  return (
    <div key={label} className={styles.containerV1}>
      <label
        className={styles.titleV1}
        id={id ? `${STEP_ELEMENT_ID_PREFIX}-title-${id}` : undefined}
      >
        {label}
      </label>

      {body && <InputBody body={body} id={id} />}

      {options.map((option) => (
        <div
          key={`${label}-${option.value}`}
          className={styles.checkBoxContainerV1}
        >
          <Checkbox
            id={id}
            label={option.label}
            value={option.value}
            checked={isChecked(option.value)}
            onChange={(event) => handleOnChange(event, !!option.exclusive)}
          />

          {isChecked(option.value) && option.followUpQuestion && (
            <FollowUpQuestion
              option={option}
              value={getFollowUpQuestionValue(option.value) as Answer}
              error={error}
              onChange={handleFollowUpQuestionOnChange}
            />
          )}
        </div>
      ))}

      {error && checkedValues.length === 0 && (
        <span className={styles.errorMessageV1}>
          {formatMessage(messages.errorMessage)}
        </span>
      )}
    </div>
  )
}

export default CheckboxGroup
