import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import Template from '../Template'
import styles from '../index.module.scss'
import { v2Routes } from '../routes'
import messages from './messages'
import onboardingSteps from './steps'
import { ActionOrLogin } from '@/programmes/components/ActionOrLogin/ActionOrLogin'

const cx = classNames.bind(styles)

const HomePage = () => {
  const history = useHistory()
  const t = useFormatMessage()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.homePageTitle)}
      hasAppLayout={isAuthenticated}
      steps={onboardingSteps}
      activeStep={0}
    >
      <h2 className={cx('heading')}>{t(messages.homeHeading)}</h2>
      {isAuthenticated ? (
        <>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart1)}
          </p>
          <h6>{t(messages.homeDescriptionHeading2)}</h6>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart2)}
          </p>
          <h6>{t(messages.homeDescriptionHeading3)}</h6>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart3)}
          </p>
          <h4>{t(messages.homeDescriptionHeading4)}</h4>
          <h6>{t(messages.homeDescriptionSubheading1)}</h6>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart4Section1)}
          </p>
          <h6>{t(messages.homeDescriptionSubheading2)}</h6>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart4Section2)}
          </p>
          <div
            className={cx('tw-pb-2 tw-pt-2')}
            style={{ borderBottom: '1px solid #ddd' }}
          />
          <h4 className={cx('subheading tw-pb-2')}>
            {t(messages.homeDescriptionHeading5)}
          </h4>
          <p className={cx('description')}>
            {t(messages.homeDescriptionPart5)}
          </p>
        </>
      ) : (
        <p className={cx('description')}>
          {t(messages.homeDescriptionLoggedOut)}
        </p>
      )}
      <ActionOrLogin
        onActionClick={() => history.push({ pathname: v2Routes.setup })}
        onActionLabel={t(messages.homeCta2)}
      />
      <p className={cx('footnote')}>
        <FormattedMessage
          {...messages.homePrivacy}
          values={{
            a: (chunks) => (
              <a href="/privacy" target="blank">
                {chunks}
              </a>
            ),
          }}
        />{' '}
      </p>
    </Template>
  )
}

export default HomePage
