import { convertMetricWeightToImperial } from '@/programmes/utils/convertHeightAndWeight'

export const getFormatedWeight = (weight: number, isMetric: boolean) => {
  // the value can be negative for weight loss
  const absWeight = Math.abs(weight)

  if (isMetric) {
    return `${absWeight}kg`
  }

  const { stones, pounds } = convertMetricWeightToImperial(absWeight)

  if (Number(stones) > 0 && Number(pounds) > 0) {
    return `${stones}st ${pounds}lb`
  }

  if (Number(stones) > 0 && Number(pounds) === 0) {
    return `${stones}st`
  }

  return `${pounds}lb`
}

export const getFormatedWeightLoss = (
  weightLoss: number,
  isMetric: boolean
) => {
  const formatedWeightChange = getFormatedWeight(weightLoss, isMetric)
  const prefix = weightLoss > 0 ? '-' : '+'

  if (weightLoss === 0) {
    return formatedWeightChange
  }

  return `${prefix}${formatedWeightChange}`
}
