import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { registerWithParams } from '../actions'

export default function DirectRegisterPage({
  email,
  weightLoss,
}: {
  email?: string
  weightLoss?: boolean
}) {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const isBookingJourney =
      searchParams.has('service_type') &&
      searchParams.has('appointment_type') &&
      searchParams.has('time')
    const params = {
      ...(email !== undefined && { login_hint: email }),
      ...(weightLoss && { weight_loss: 'true' as const }),
      ...(isBookingJourney && { journey: 'booking' }),
    }
    registerWithParams(params)()
  }, [email, weightLoss, location])

  return <></>
}
