import { defineMessages } from 'react-intl'

export default defineMessages({
  enterDetailsPageTitle: {
    id: 'RequestAppointment.enterDetails.pageTitle',
    defaultMessage: 'What’s your phone number?',
  },
  enterDetailsPageSubTitle: {
    id: 'RequestAppointment.enterDetails.pageSubTitle',
    defaultMessage: 'So we can contact you for your appointment.',
  },
  enterDetailsPageCta: {
    id: 'RequestAppointment.enterDetails.cta',
    defaultMessage: 'Confirm',
  },
  bookAppointmentPageTitle: {
    id: 'RequestAppointment.bookAppointment.pageTitle',
    defaultMessage: 'Call to complete your booking',
  },
  bookAppointmentPageSubTitle: {
    id: 'RequestAppointment.bookAppointment.pageSubTitle',
    defaultMessage:
      'Our team will help find you the next available appointment.',
  },
  bookAppointmentCta: {
    id: 'RequestAppointment.bookAppointment.cta',
    defaultMessage: 'I’ve booked my appointment',
  },
  bookAppointmentCall: {
    id: 'RequestAppointment.bookAppointment.call',
    defaultMessage: 'Call {supportPhoneNumber}',
  },
  successPageTitle: {
    id: 'RequestAppointment.success.pageTitle',
    defaultMessage: 'What happens next',
  },
  successPageSubTitle: {
    id: 'RequestAppointment.success.pageSubTitle',
    defaultMessage:
      'We’ll send you a text message to confirm your appointment time.',
  },
  successPageSymptomsAdvice: {
    id: 'RequestAppointment.success.symptomsAdvice',
    defaultMessage: 'If your symptoms get any worse, call {999}.',
  },
  successPageAppointmentTimeTitle: {
    id: 'RequestAppointment.success.section.appointmentTime.title',
    defaultMessage: 'When it’s time for your appointment',
  },
  successPageWhatAppointmentTimeDescription: {
    id: 'RequestAppointment.success.section.appointmentTime.description',
    defaultMessage:
      'You’ll get a text message with a link to start your video appointment.',
  },
  successPageNeedsSectionTitle: {
    id: 'RequestAppointment.success.section.needs.title',
    defaultMessage: 'You’ll need:',
  },
  successPageNeedsSectionFirstBulletPoint: {
    id: 'RequestAppointment.success.section.needs.firstBulletPoint',
    defaultMessage: 'Have a good wifi or 4G connection',
  },
  successPageNeedsSectionSecondBulletPoint: {
    id: 'RequestAppointment.success.section.needs.secondBulletPoint',
    defaultMessage: 'Good wifi or 4G connection',
  },
})
