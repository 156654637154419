import React, { ReactNode, memo } from 'react'
import cn from 'classnames'

import { IMedkitComponent } from '..'
import { listTheme } from './theme'
import styles from './index.module.scss'

interface ListItemProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode | ReactNode[]
  className?: string
}

const ListItem: IMedkitComponent<ListItemProps, typeof listTheme> = ({
  children,
  className,
}: ListItemProps) => (
  <li className={cn(styles.List__item, className)}>{children}</li>
)

export default memo(ListItem)

ListItem.theme = listTheme
