import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { AlertFilled } from '@babylon/icons'
import { Alert, ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'
import styles from '../PaymentCompletionPage.module.scss'
import messages from '../messages'

interface Props {
  retry: () => void
}
export const Error: React.FC<Props> = ({ retry }) => {
  const translate = useFormatMessage()
  return (
    <div className="Content--narrow">
      <Text variant="h1" className={styles.errorTitle}>
        {translate(messages.errorTitle)}
      </Text>

      <Alert className={styles.errorAlert} icon={<AlertFilled />} type="info">
        {translate(messages.errorMessage)}
      </Alert>

      <ButtonSimple
        variant={ButtonVariant.secondary}
        type="button"
        onClick={() => retry()}
      >
        {translate(messages.errorTryAgain)}
      </ButtonSimple>
    </div>
  )
}
