import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useLocation } from 'react-router'
import appStyles from '@/App.module.scss'
import AppsInfo from '@/components/AppsInfo'
import Container from '@/components/Container'
import LandingFooter from '@/components/Footer/LandingFooter'
import LandingHeader from '@/components/LandingHeader'
import BABYLON_US_APP from '@/assets/images/babylon_us_ios_app.png'
import PageTitle from '@/components/PageTitle/PageTitle'
import messages from './messages'

const DownloadAppPage = () => {
  const translate = useFormatMessage()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const regionIsoCode = queryParams.get('region')

  return (
    <>
      <PageTitle title={translate(messages.pageTitle)} />
      <LandingHeader />
      <main className={appStyles.view}>
        <section className={appStyles.view__inner}>
          <Container variant="narrow">
            <AppsInfo
              region={regionIsoCode!}
              title={translate(messages.contentTitle)}
              content={translate(messages.contentMessage)}
              image={BABYLON_US_APP}
            />
          </Container>
        </section>
      </main>
      <LandingFooter />
    </>
  )
}

export default DownloadAppPage
