import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { injectIntl } from 'react-intl'
import { ArrowLeft, Close } from '@babylon/icons'
import { IS_SERVER } from '@/config'
import styles from './Modal.module.scss'
import messages from './Modal.messages'

const cx = classNames.bind(styles)

const modalRoot = document.getElementById('modal-root')
const appWrapper = document.getElementsByTagName('body')[0]

const enhance = injectIntl

const Modal = ({
  children,
  onClose,
  onBack,
  backButton,
  className,
  avoidClose,
  intl,
  ...rest
}) => (
  <Portal onClose={onClose} avoidClose={avoidClose} onBack={onBack}>
    <div
      id="modalOverlay"
      className={cx(styles.modalOverlay, { avoidClose })}
    />
    <div className={cx(styles.modal, className)} {...rest}>
      {!avoidClose && (
        <button
          className={styles.closeBtn}
          onClick={onClose}
          data-testid="modalCloseButton"
          type="button"
        >
          <Close
            title={intl.formatMessage(messages.close)}
            className={styles.closeBtn__icon}
          />
        </button>
      )}
      {backButton && (
        <button
          className={styles.backBtn}
          onClick={onBack}
          data-testid="modalBackButton"
          type="button"
        >
          <ArrowLeft
            title={intl.formatMessage(messages.back)}
            className={styles.closeBtn__icon}
          />
        </button>
      )}
      {children}
    </div>
  </Portal>
)

class Portal extends React.Component {
  constructor() {
    super()

    if (!IS_SERVER) {
      this.el = document.createElement('div')
      modalRoot.appendChild(this.el)
    }
  }

  componentDidMount() {
    if (!this.props.enableScroll) {
      appWrapper.classList.add('no-scroll')
    }

    this.overlay = document.getElementById('modalOverlay')

    if (this.overlay && !this.props.avoidClose) {
      this.overlay.addEventListener('click', this.props.onClose)
      document.addEventListener('keydown', this.keyPressed)
    }
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.enableScroll && !appWrapper.classList.contains('no-scroll')) {
      appWrapper.classList.add('no-scroll')
    }

    if (newProps.enableScroll && appWrapper.classList.contains('no-scroll')) {
      appWrapper.classList.remove('no-scroll')
    }
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)

    // a pretty specific case hence the long prop name
    if (!this.props.keepScrollDisabledOnUnmount) {
      appWrapper.classList.remove('no-scroll')
    }

    if (this.overlay) {
      this.overlay.removeEventListener('click', this.props.onClose)
      document.removeEventListener('keydown', this.keyPressed)
    }
  }

  keyPressed = ({ key }) => {
    const isTopModal = modalRoot.lastChild === this.el

    if (isTopModal && (key === 'Escape' || key === 'Esc')) {
      this.props.onClose()
    }
  }

  render() {
    return ReactDom.createPortal(this.props.children, this.el)
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
}

export default enhance(Modal)
export { Portal }
