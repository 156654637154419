import { defineMessages } from 'react-intl'

export default defineMessages({
  signIn: {
    id: 'LoginPage.signIn',
    defaultMessage: 'Welcome to eMed®',
  },
  or: {
    id: 'LoginPage.or',
    defaultMessage: 'Or',
  },
  email: {
    id: 'LoginPage.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'LoginPage.password',
    defaultMessage: 'Password',
  },
  back: {
    id: 'LoginPage.back',
    defaultMessage: 'Back',
  },
  welcomeSubtitle: {
    id: 'LoginPage.welcomeSubtitle',
    defaultMessage: 'Log in for 24/7 healthcare for you and your family',
  },
  forgotPassword: {
    id: 'LoginPage.forgotPassword',
    defaultMessage: 'Forgot Password?',
  },
  noAccount: {
    id: 'LoginPage.noAccount',
    defaultMessage: "Don't have an account?",
  },
  createAccount: {
    id: 'LoginPage.createAccount',
    defaultMessage: 'Create Account',
  },
  logIn: {
    id: 'LoginPage.logIn',
    defaultMessage: 'Log In',
  },
  emailValidation: {
    id: 'LoginPage.emailValidation',
    defaultMessage: 'Please provide a valid email address',
  },
  passwordValidation: {
    id: 'LoginPage.passwordValidation',
    defaultMessage: 'Please enter your password',
  },
  facebookLoginError: {
    id: 'RegisterPage.facebookLoginError',
    defaultMessage: 'Couldn‘t login with Facebook',
  },
  pageTitle: {
    id: 'LoginPage.pageTitle',
    defaultMessage: 'Log In',
  },
  invalidCredentials: {
    id: 'AuthenticationProvider.Login.invalidCredentials',
    defaultMessage: 'Invalid credentials',
  },
  userOffline: {
    id: 'AuthenticationProvider.Login.userOffline',
    defaultMessage:
      'You appear to be offline. Please check your network connection',
  },
  unexpectedError: {
    id: 'AuthenticationProvider.Login.unexpectedError',
    defaultMessage: 'An unexpected error occurred',
  },
})
