import React from 'react'
import { PhoneInput } from '@babylon/ui'

const onChange = (_onChange, namePrefix = '') => ({ value, countryCode }) => {
  _onChange({
    [`${namePrefix}phone_number`]: value,
    [`${namePrefix}phone_country_code`]: countryCode,
  })
}
export default (props) => {
  const { onChange: formOnChange, namePrefix, description, ...rest } = props

  let { phone_country_code, phone_number } = props
  if (namePrefix) {
    phone_country_code = props[`${namePrefix}phone_country_code`]
    phone_number = props[`${namePrefix}phone_number`]
  }

  return (
    <>
      <PhoneInput
        value={phone_number}
        countryCode={phone_country_code}
        onChange={onChange(formOnChange, namePrefix)}
        {...rest}
      />
      {description && <p>{description}</p>}
    </>
  )
}
