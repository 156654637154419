import React, { ReactNode } from 'react'
import classNames from 'classnames/bind'
import { ArrowRight } from '@babylon/icons'
import { Link, Text, Grid } from '@babylon/medkit'

import ListItem from '../ListItem'
import LoadingBlock from '../LoadingBlock'
import styles from './ArrowListItem.module.scss'

const cx = classNames.bind(styles)

interface Props {
  text?: string | ReactNode
  tip?: string
  icon?: ReactNode
  href?: string
  to?: string
  onClick?: () => void
  noArrow?: boolean
  isImportant?: boolean
  handle?: string
  className?: string
  wide?: boolean
  loading?: boolean
  blank?: boolean
  isOption?: boolean
  role?: string
  disabled?: boolean
}

const ArrowListItem: React.FC<Props> = ({
  text,
  tip,
  icon,
  href,
  to,
  onClick,
  noArrow,
  isImportant,
  handle,
  className,
  wide,
  loading,
  blank,
  isOption,
  role,
  disabled,
}) => {
  const content = loading ? (
    <div className={styles.loading}>
      <LoadingBlock className={styles['loading--square']} />
      <LoadingBlock className={styles['loading--text']} />
    </div>
  ) : (
    <Grid container barbell>
      <Grid item>
        {icon && (
          <figure className={styles.ArrowListItem__image}>{icon}</figure>
        )}
      </Grid>
      <Grid item>
        {text && (
          <div className={styles.ArrowListItemCopy}>
            {typeof text === 'string' ? (
              <Text
                style={{ color: 'inherit' }}
                key="text"
                variant="caption"
                tag="span"
              >
                {text}
              </Text>
            ) : (
              text
            )}
          </div>
        )}
      </Grid>
      <Grid item>
        {tip && (
          <div className={styles.ArrowListItemTip}>
            {typeof tip === 'string' ? (
              <Text
                tag="span"
                style={{ color: 'inherit' }}
                key="tip"
                variant="caption"
              >
                {tip}
              </Text>
            ) : (
              tip
            )}
          </div>
        )}
        <ArrowRight aria-hidden className={styles.ArrowListItem__decoration} />
      </Grid>
    </Grid>
  )

  const listItemProps = {
    tabIndex: 0,
    onClick,
    to,
    href,
    blank,
    role,
  }

  const actionDisabled = (to && href) || disabled

  return (
    <ListItem
      className={cx(
        styles.ArrowListItem,
        {
          'ArrowListItem--noArrow': noArrow,
          'ArrowListItem--important': isImportant,
          'ArrowListItem--wide': wide,
          'ArrowListItem--option': isOption,
        },
        actionDisabled && 'ArrowListItem--noArrow',
        className
      )}
      aria-busy={loading}
    >
      <Link data-testid={handle} {...listItemProps}>
        {content}
      </Link>
    </ListItem>
  )
}
/* eslint-enable */

export default ArrowListItem
