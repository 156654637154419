import React, { useRef } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Answer, Question, RadioGroupValue } from '../../types'
import RadioButton from '../RadioButton'
import QuestionBody from '../QuestionBody'
import styles from './RadioGroup.module.scss'
import messages from './messages'
import FollowUpQuestion from '../FollowUpQuestion'
import useRadioGroupFocusHandlers from './useRadioGroupFocusHandlers'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'
import Error from '@/programmes/components/FormV2/components/Error/Error'

interface Props {
  id: string
  label?: string
  header?: string
  body?: string | Array<string>
  footer?: string | Array<string>
  error?: boolean
  options: Array<{
    label: string
    value: any
    body?: string
    extraLabel?: string
    followUpQuestion?: Question
  }>
  selectedOption: RadioGroupValue
  onChange: (selectedOption: RadioGroupValue) => void
}

const RadioGroup: React.FC<Props> = ({
  id,
  label,
  header,
  body,
  footer,
  error = false,
  options,
  selectedOption = { value: '' },
  onChange,
}) => {
  const formatMessage = useFormatMessage()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  const {
    tabIndexableOptionIndex,
    listenerBuilder,
  } = useRadioGroupFocusHandlers({
    selectedValue: selectedOption.value,
    options,
    fieldsetRef,
    onChooseOptionIndex: (i) => onChange(options[i]),
  })

  return (
    <fieldset className={styles.container} ref={fieldsetRef}>
      {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
      {label && <QuestionTitle id={id} content={label} type="legend" />}

      {body && <QuestionBody header={header} body={body} id={id} />}

      {options.map((option, i) => {
        const listeners = listenerBuilder(i)

        return (
          <div
            key={`${label}-${option.value}`}
            className={styles.radioButtonContainer}
          >
            <RadioButton
              tabIndex={i === tabIndexableOptionIndex ? 0 : -1}
              id={id}
              key={`${label}-${option.value}`}
              label={option.label}
              value={option.value}
              body={option.body}
              checked={selectedOption && selectedOption.value === option.value}
              onChange={(value) => onChange({ value })}
              extraLabel={option.extraLabel}
              onFocus={listeners?.focusListener}
              onKeyDown={listeners?.keyDownListener}
            />

            {selectedOption &&
              selectedOption.value === option.value &&
              option.followUpQuestion && (
                <FollowUpQuestion
                  option={option}
                  value={selectedOption.followUpQuestionValue as Answer}
                  error={error}
                  onChange={(value, followUpQuestionValue) =>
                    onChange({ value, followUpQuestionValue })
                  }
                />
              )}
          </div>
        )
      })}

      {footer && <QuestionBody body={footer} isFooter />}

      {error && !selectedOption.value && (
        <Error>{formatMessage(messages.errorMessage)}</Error>
      )}
    </fieldset>
  )
}
export default RadioGroup
