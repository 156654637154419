import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { compose, withProps } from 'recompose'

import withPageTitle from '@/wrappers/withPageTitle'
import Notifications from '@/components/Notifications'

import messages from './NotificationsPage.messages'

const enhance = compose(
  injectIntl,
  withPageTitle(messages.pageTitle),
  withRouter,
  withProps(({ match }) => ({
    pageNumber: parseInt(match.params.pageNumber, 10) || 1,
  }))
)

const NotificationsPage = ({ pageNumber, pageTitle }) => (
  <div className="Content--narrow">
    <Notifications
      pageNumber={pageNumber}
      showItems={10}
      showPagination
      title={pageTitle}
    />
  </div>
)

NotificationsPage.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default enhance(NotificationsPage)
