import notificationTypes from '@babylon/notification-types'

const repeatPrescriptionNotificationTypes = [
  notificationTypes.REPEAT_MEDICATION_GP_REVIEW,
  notificationTypes.REPEAT_MEDICATION_PHARMACIST_REVIEW,
  notificationTypes.REPEAT_MEDICATION_READY,
  notificationTypes.REPEAT_MEDICATION_REORDER_NOW,
  notificationTypes.REPEAT_MEDICATION_REORDER_SOON,
  notificationTypes.REPEAT_MEDICATION_REVIEW_DATE_PASSED,
]

const trackingMessageNotificationTypes = [notificationTypes.CLINICIAN_LATENESS]

const trackingAppointmentIdNotificationTypes = [
  notificationTypes.CLINICIAN_LATENESS,
]

export {
  repeatPrescriptionNotificationTypes,
  trackingAppointmentIdNotificationTypes,
  trackingMessageNotificationTypes,
}
