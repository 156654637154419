import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import SectionTitle from './SectionTitle'
import messages from '../../messages'
import RagCard from '@/programmes/Wellness/Report/components/RagCard'

const VitalSignsSection = ({ indicators }) => {
  const t = useFormatMessage()

  return (
    <>
      <SectionTitle text={t(messages.dashboardVitalSignsSectionTitle)} />
      {!indicators?.length && (
        <div className="tw-items-start tw-w-full tw-text-caption">
          {t(messages.dashboardVitalSignsNoData)}
        </div>
      )}
      {indicators?.length > 0 && (
        <>
          <div className="flex tw-w-full tw-items-center tw-gap-2 tw-bg-gray-100 tw-px-4 tw-py-2 tw-mb-3 tw-rounded-md dark:tw-bg-gray-800">
            <p className="tw-text-base tw-font-medium tw-text-gray-700 dark:tw-text-gray-300">
              {t(messages.dashboardVitalSignsDisclaimer)}
            </p>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 md:tw-grid-cols-4">
            {indicators.map((indicator) => (
              <RagCard
                name={indicator.data.caption}
                value={indicator.data.value}
                unit={indicator.data.unit}
                rangeLabel=""
                interpretation={indicator.data.interpretation}
                rag={indicator.data.rag}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default VitalSignsSection
