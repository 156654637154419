import gql from 'graphql-tag'

export default gql`
  mutation updatePatient($id: Int!, $patient: ProfileInformation!) {
    updatePatient(id: $id, patient: $patient) {
      id
      address_first_line
      address_second_line
      address_third_line
      address_post_code
      avatar_image_path
      avatar_full_url
      date_of_birth
      email
      first_name
      last_name
      gender
      phone_country_code
      phone_number
      password
      height
      weight
      smoking_status
      insurance_membership_number
      insurance_company_id
      healthcare_identifier {
        public_identifier
      }
    }
  }
`
