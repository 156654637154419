export const ASK_A_AND_E_SQUARE = 'ask-a-and-e--square'
export const ASK_A_AND_E = 'ask-a-and-e'
export const BABYLON = 'babylon'
export const BABYLON_SQUARE = 'babylon--square'
export const BUPA = 'bupa'
export const COLUMBUS_DIRECT = 'columbus-direct'
export const GP_AT_HAND = 'gp-at-hand'
export const NHS_SERVICES = 'nhs-services'
export const NHS_UHB_ED = 'nhs-uhb-ed'
export const NHS_RWT_ED = 'nhs-rwt'
export const UNITED_HEALTHCARE = 'united-healthcare'
