import { captureException, showReportDialog } from '@sentry/browser'
import { isEnabled } from './info'

const logException = (...args) => {
  isEnabled() ? captureException(...args) : console.error(...args) // eslint-disable-line no-console
}

export const logExceptionWithReportDialog = (...args) => {
  const eventId = logException(...args)
  if (eventId) {
    showReportDialog({
      eventId: eventId,
    })
  }
}

export default logException
