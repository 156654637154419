export const WEGOVY_PRODUCT_ID = 'wegovy'
export const MOUNJARO_PRODUCT_ID = 'mounjaro'
export const OLD_ULTRA_PRODUCT_TYPE = 'ultra'
export const OLD_LITE_PRODUCT_TYPE = 'lite'
export const ULTRA_PRODUCT_TYPE = 'weight loss+'
export const LITE_PRODUCT_TYPE = 'weight loss'
export const OLD_RECOMMENDED_PRODUCT_TYPE = OLD_ULTRA_PRODUCT_TYPE
export const RECOMMENDED_PRODUCT_TYPE = ULTRA_PRODUCT_TYPE
export const WEGOVY_ULTRA_LOOKUP_KEY = 'wl-wegovy-ultra'
export const WEGOVY_LITE_LOOKUP_KEY = 'wl-wegovy-lite'
export const MOUNJARO_ULTRA_LOOKUP_KEY = 'wl-mounjaro-ultra'
export const MOUNJARO_LITE_LOOKUP_KEY = 'wl-mounjaro-lite'
export const WEIGHT_LOSS_PRODUCT_GROUP = 'weightLossMedication'
export const ADDON_PRODUCT_GROUP = 'addon'
