import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useSelector } from 'react-redux'
import Button from '@/programmes/components/Button'
import APP_MEDICATION from '@/assets/images/app_medication.jpg'
import Template from '../Template'
import styles from '../index.module.scss'
import messages from '../messages'
import Card from '@/programmes/components/Card'
import WeightLossReadInstructions from '@/assets/images/assessment.png'
import AppsInfo from '@/components/AppsInfo'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'

const cx = classNames.bind(styles)

const SetupConfirmation = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.setupConfirmationPageTitle)}
      hasAppLayout={isAuthenticated}
      steps={UTI_TEST.setupSteps}
      activeStep={2}
    >
      <h2>{t(messages.setupConfirmationPageHeading)}</h2>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.setupConfirmationPageCard1Title)}
          description={t(messages.setupConfirmationPageCard1Description)}
          image={WeightLossReadInstructions}
          imageDescription={t(
            messages.setupConfirmationPageCard1ImageDescription
          )}
        />
      </div>
      <div className={cx('apps-info')}>
        <AppsInfo
          title={t(messages.setupConfirmationPageCard2Title)}
          content={t(messages.setupConfirmationPageCard2Description)}
          image={APP_MEDICATION}
        />
      </div>
      <Button
        onClick={() => {
          history.push('/')
        }}
      >
        {t(messages.setupConfirmationPageCta)}
      </Button>
    </Template>
  )
}

export default SetupConfirmation
