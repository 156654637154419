import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { MEASUREMENT_UNIT, Weight } from '../../types'
import TextInput from '../TextInput'
import Button from '../Button'
import styles from './HeightAndWeight.module.scss'
import messages from './messages'
import InputBody from '@/programmes/components/Form/components/InputBody'
import {
  WEIGHT_VALID_RANGE_IMPERIAL,
  WEIGHT_VALID_RANGE_METRIC,
} from '../../constants'

interface Props {
  title?: string
  body?: string | Array<string>
  value?: Weight
  handleChange: (weight: Weight) => void
  error?: boolean
}

const WeightMeasurement: React.FC<Props> = ({
  title,
  body,
  handleChange,
  value,
  error,
}) => {
  const [weight, setWeight] = useState<Weight>(
    value || {
      unit: MEASUREMENT_UNIT.IMPERIAL,
      kg: '',
      stones: '',
      pounds: '',
    }
  )
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.measurementContainerV1}>
      <div
        className={title ? styles.headerVerticalV1 : styles.headerHorizontalV1}
      >
        <div className={styles.titleContainerV1}>
          <span className={styles.titleV1}>
            {title || formatMessage(messages.weight)}
          </span>
          {body && <InputBody body={body} />}
        </div>
        <div className={styles.buttonWrapperV1}>
          <Button
            type="secondary"
            onClick={() => {
              const newHeight = {
                ...weight,
                unit:
                  weight.unit === MEASUREMENT_UNIT.IMPERIAL
                    ? MEASUREMENT_UNIT.METRIC
                    : MEASUREMENT_UNIT.IMPERIAL,
              }
              setWeight(newHeight)
              handleChange(newHeight)
            }}
            title={
              weight.unit === MEASUREMENT_UNIT.IMPERIAL
                ? formatMessage(messages.switchToKg)
                : formatMessage(messages.switchToStLb)
            }
          />
        </div>
      </div>

      <div className={styles.inputContainerV1}>
        {weight.unit === MEASUREMENT_UNIT.IMPERIAL ? (
          <>
            <div
              key={formatMessage(messages.stones)}
              className={styles.textInputV1}
            >
              <TextInput
                label={formatMessage(messages.stones)}
                type="number"
                onChange={(event) => {
                  const newWeight = { ...weight, stones: event.target.value }
                  setWeight(newWeight)
                  handleChange(newWeight)
                }}
                value={weight.stones}
              />
            </div>
            <div className={styles.textInputV1}>
              <TextInput
                label={formatMessage(messages.pounds)}
                type="number"
                onChange={(event) => {
                  const newWeight = { ...weight, pounds: event.target.value }
                  setWeight(newWeight)
                  handleChange(newWeight)
                }}
                value={weight.pounds}
              />
            </div>
          </>
        ) : (
          <div
            key={formatMessage(messages.kilograms)}
            className={styles.textInputV1}
          >
            <TextInput
              label={formatMessage(messages.kilograms)}
              type="number"
              onChange={(event) => {
                const newWeight = { ...weight, kg: event.target.value }
                setWeight(newWeight)
                handleChange(newWeight)
              }}
              value={weight.kg}
            />
          </div>
        )}
      </div>
      {error && (
        <span className={styles.errorMessageV1}>
          {weight?.unit === MEASUREMENT_UNIT.IMPERIAL
            ? formatMessage(messages.weightImperialErrorMessage, {
                min: WEIGHT_VALID_RANGE_IMPERIAL.min,
                max: WEIGHT_VALID_RANGE_IMPERIAL.max,
              })
            : formatMessage(messages.weightMetricErrorMessage, {
                min: WEIGHT_VALID_RANGE_METRIC.min,
                max: WEIGHT_VALID_RANGE_METRIC.max,
              })}
        </span>
      )}
    </div>
  )
}

export default WeightMeasurement
