import React, { useState, useEffect, ChangeEvent } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { FormattedMessage } from 'react-intl'
import { Text } from '@babylon/medkit'
import PostcodeLookupMutation from '@/mutations/PostcodeLookup'
import Loading from '@/components/Loading'
import { getParsedErrorObject } from '@/utils'
import SearchInput from './SearchInput'
import AddressesListState from './AddressesListState'
import EmptyState from './EmptyState'
import ErrorState from './ErrorState'
import { UK_POSTCODE_REGEX } from '@/config/constants'
import { NewAddressFormat, LookupDataList } from '../../types'
import styles from './Modal.module.scss'
import messages from './Modal.messages'

interface Props {
  postcode?: string
  onAddressSelected: (address: NewAddressFormat) => void
  onAddManualAddress: (searchString: string) => void
  disableSearch?: boolean
}

const AddressModal: React.FC<Props> = ({
  postcode: addressPostcode,
  onAddressSelected,
  onAddManualAddress,
  disableSearch = false,
}) => {
  const [searchString, setSearchString] = useState(addressPostcode || '')
  const [
    postcodeLookupData,
    setPostcodeLookupData,
  ] = useState<LookupDataList | null>(null)
  const [postcodeError, setPostcodeError] = useState<Array<string> | any>([])
  const [networkError, setNetworkError] = useState(false)
  const [lookup, { loading }] = useMutation(PostcodeLookupMutation, {
    onCompleted({ postcodeLookup }) {
      setPostcodeLookupData(postcodeLookup)
    },
    onError(error) {
      if (error.networkError) {
        setNetworkError(true)
      } else {
        setNetworkError(false)
      }
      const errorsObject = getParsedErrorObject(error).errors
      const errors = errorsObject ? errorsObject.base : []
      setPostcodeError(errors)
    },
  })

  useEffect(() => {
    if (addressPostcode) {
      onLookup()
    }
  }, [addressPostcode]) // eslint-disable-line react-hooks/exhaustive-deps

  function onLookup() {
    setNetworkError(false)
    setPostcodeError([])

    if (searchString.match(UK_POSTCODE_REGEX)) {
      lookup({
        variables: { postcode: searchString },
      })
    } else {
      setPostcodeError([
        <FormattedMessage {...messages.modalErrorPostcodeInvalid} />,
      ])
    }
  }

  function onSubmit(event: ChangeEvent<HTMLFormElement>) {
    event && event.preventDefault && event.preventDefault()
    onLookup()
  }

  function onChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    setSearchString(event.target.value)
    setPostcodeLookupData(null)
  }

  function onSelectAddress(address: string) {
    if (postcodeLookupData) {
      onAddressSelected({
        first_line: address,
        postcode: postcodeLookupData.postcode,
        county: postcodeLookupData.county,
        town: postcodeLookupData.town,
      })
    }
  }

  return (
    <div className={styles.addressPicker__modal}>
      <Text variant="h4" className={styles.addressPicker__modal__heading}>
        <FormattedMessage {...messages.modalTitle} />
      </Text>
      <SearchInput
        onSubmit={onSubmit}
        searchString={searchString}
        onChangeSearch={onChangeSearch}
        disableSearch={disableSearch}
        postcodeError={postcodeError}
      />
      {loading && (
        <Loading inline className={styles.addressPicker__modal__statesBody} />
      )}
      {!postcodeLookupData && !loading && !networkError && (
        <EmptyState onLookup={onLookup} />
      )}
      {networkError && !loading && (
        <ErrorState
          onLookup={onLookup}
          onAddManualAddress={onAddManualAddress}
          searchString={searchString}
        />
      )}
      {postcodeLookupData && (
        <AddressesListState
          postcodeLookupData={postcodeLookupData}
          onSelectAddress={onSelectAddress}
          searchString={searchString}
          onAddManualAddress={onAddManualAddress}
          data-testid="manual-address-form-postcode"
        />
      )}
    </div>
  )
}

export default AddressModal
