import { defineMessages } from 'react-intl'

export default defineMessages({
  noNotifications: {
    id: 'Notifications.noNotifications',
    defaultMessage: 'No Notifications',
  },
  reminder: {
    id: 'Notifications.reminder',
    defaultMessage:
      'We’ll remind you of prescription and upcoming appointments',
  },
  paginationNext: {
    id: 'Notifications.paginationNext',
    defaultMessage: 'Next',
  },
  paginationNextAccessible: {
    id: 'Notifications.paginationNextAccessible',
    defaultMessage: 'Go to next page',
  },
  paginationBack: {
    id: 'Notifications.paginationBack',
    defaultMessage: 'Back',
  },
  paginationBackAccessible: {
    id: 'Notifications.paginationBackAccessible',
    defaultMessage: 'Go to previous page',
  },
  paginationLabel: {
    id: 'Notifications.paginationLabel',
    defaultMessage: '{currentPage} of {pageTotal}',
  },
})
