import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import SectionTitle from './SectionTitle'
import messages from '../../messages'
import { IndicatorDisplay } from '../types'
import { Indicator } from './Indicator'

const IndicatorsSection = ({
  indicators,
}: {
  indicators: IndicatorDisplay[]
}) => {
  const t = useFormatMessage()

  return (
    <>
      <SectionTitle text={t(messages.dashboardIndicatorsSectionTitle)} />
      {!indicators?.length && (
        <div className="tw-items-start tw-w-full tw-text-caption">
          {t(messages.dashboardIndicatorsNoData)}
        </div>
      )}
      {indicators?.length > 0 && (
        <div className="tw-grid tw-flex-col tw-items-center tw-w-full">
          {indicators.map(
            (indicator: IndicatorDisplay) =>
              indicator?.matchedDisplayRule && (
                <Indicator
                  data={indicator.data}
                  display={indicator.matchedDisplayRule.display}
                />
              )
          )}
        </div>
      )}
    </>
  )
}

export default IndicatorsSection
