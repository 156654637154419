import gql from 'graphql-tag'

export default gql`
  mutation applyCode(
    $code: String!
    $patient_id: Int!
    $redemption_type: String!
  ) {
    applyCode(
      code: $code
      patient_id: $patient_id
      redemption_type: $redemption_type
    ) {
      promotion {
        id
        description
        logo
      }
      membership {
        id
        description
      }
    }
  }
`
