import React, { useEffect, useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'

import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import LandingHeader from '../../LandingHeader'
import appStyles from '@/App.module.scss'
import PageTitle from '@/components/PageTitle/PageTitle'
import useTracking from '@/tracking/useTracking'
import LoginForm from './Form'
import messages from './messages'
import {
  registerWithParams as registerAction,
  loginWithParams as loginAction,
} from '../actions'
import Loading from '@/components/Loading'

const LOGIN_PAGE_SCREEN_TITLE = 'login-page'

const LoginPage = ({ showCreateAccount = true, stopRedirection }) => {
  const translate = useFormatMessage()
  const { trackView } = useTracking()
  const location = useLocation()
  const dispatch = useDispatch()

  const shouldSkipToRegister = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return (
      searchParams.get('skipSplashScreen') === 'true' ||
      searchParams.get('action') === 'register'
    )
  }, [location])
  const shouldSkipToLogin = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('action') === 'login'
  }, [location])

  // There are cases where most of the users won't have an account and so offering them the chance to login
  // may cause extra inertia
  // In those cases, we can skip the splash screen and go straight to the register page. If they do happen to have
  // an account the auth0 flow will detect it an offer them the chance to login
  useEffect(() => {
    if (shouldSkipToRegister) {
      dispatch(registerAction({}, true))
      return
    }
    if (shouldSkipToLogin) {
      dispatch(loginAction(undefined, {}, true))
      return
    }

    trackView({
      screenTitle: LOGIN_PAGE_SCREEN_TITLE,
    })
  }, [trackView, dispatch, shouldSkipToRegister, shouldSkipToLogin])

  if (shouldSkipToRegister || shouldSkipToLogin) {
    return <Loading />
  }

  return (
    <>
      <PageTitle title={translate(messages.pageTitle)} />
      <LandingHeader />
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <LoginForm
            showCreateAccount={showCreateAccount}
            stopRedirection={stopRedirection}
            showBackToMarketing
            showWelcomeImage
            subtitle={translate(messages.welcomeSubtitle)}
          />
        </div>
      </main>
    </>
  )
}

export default LoginPage
