import { AppointmentMedium } from '@babylon/consumer-api/types'
import { RouteType } from '@/routes/config/routeConfig'
import { BookingState } from './redux/reducer'

const GROUP_ROOT_PATH = '/book/:appointmentType'
const ROOT_PATH = `${GROUP_ROOT_PATH}/new`

export enum RouteId {
  NewAppointment = 'newAppointmentStreamlined',
  AppointmentPay = 'appointmentPay',
  AppointmentReview = 'appointmentReviewStreamlined',
  AppointmentBooked = 'appointmentBookedStreamlined',
  SelectClinician = 'selectClinicianStreamlined',
  SelectSlot = 'selectSlotStreamlined',
  SelectFormat = 'selectFormatStreamlined',
  SelectLocation = 'selectLocationStreamlined',
  SelectAppointmentType = 'selectAppointmentTypeStreamlined',
}

export type RoutesType = {
  [key in RouteId]: Omit<RouteType, 'id'>
}

export const getRootUrl = ({ appointmentType }) =>
  ROOT_PATH.replace(':appointmentType', appointmentType)

const routes: RoutesType = {
  [RouteId.NewAppointment]: {
    route: ROOT_PATH,
    getRedirectUrl: (args: { appointmentType: string }) =>
      `${getRootUrl(args)}/category`,
    requiredFields: [],
    isSkipped: () => false,
    getUrl: (args: { appointmentType: string }) => getRootUrl(args),
  },
  [RouteId.SelectAppointmentType]: {
    route: `${ROOT_PATH}/appointment-type`,
    requiredFields: ['selectedPatient'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    getUrl: (args: { appointmentType: string }) =>
      `${getRootUrl(args)}/appointment-type`,
  },
  [RouteId.SelectClinician]: {
    route: `${ROOT_PATH}/category`,
    requiredFields: [],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Welcome',
    getUrl: (args: { appointmentType: string }) =>
      `${getRootUrl(args)}/category`,
  },
  [RouteId.SelectFormat]: {
    route: `${ROOT_PATH}/format`,
    requiredFields: ['selectedPatient', 'clinicianCriteria'],
    isSkipped: () => false,
    stepLabel: 'Appointment type',
    breadcrumb: { isExcluded: true },
    getUrl: (args: { appointmentType: string }) => `${getRootUrl(args)}/format`,
  },
  [RouteId.SelectLocation]: {
    route: `${ROOT_PATH}/location`,
    requiredFields: ['selectedPatient', 'clinicianCriteria', 'medium'],
    stepLabel: 'Select a clinic',
    isSkipped: (bookingState: BookingState) => {
      const result =
        !!bookingState.medium &&
        bookingState.medium !== AppointmentMedium.Physical

      return result
    },
    breadcrumb: { isExcluded: true },
    getUrl: (args: { appointmentType: string }) =>
      `${getRootUrl(args)}/location`,
  },
  [RouteId.SelectSlot]: {
    route: `${ROOT_PATH}/slot`,
    requiredFields: ['selectedPatient', 'clinicianCriteria'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Choose a time',
    getUrl: (args: { appointmentType: string }) => `${getRootUrl(args)}/slot`,
  },
  [RouteId.AppointmentReview]: {
    route: `${ROOT_PATH}/review`,
    requiredFields: ['selectedPatient', 'preferred_date_time'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    stepLabel: 'Finish & pay',
    getUrl: (args: { appointmentType: string }) => `${getRootUrl(args)}/review`,
  },
  [RouteId.AppointmentPay]: {
    route: `${ROOT_PATH}/:appointmentId/payment/:planId?`,
    getUrl: ({ appointmentType, appointmentId, planId = '' }) =>
      `${getRootUrl({
        appointmentType,
      })}/${appointmentId}/payment/${planId}?`,
    breadcrumb: {
      isExcluded: true,
    },
    isSkipped: () => false,
  },
  [RouteId.AppointmentBooked]: {
    route: `${ROOT_PATH}/:appointmentId/success`,
    requiredFields: ['selectedPatient', 'preferred_date_time'],
    isSkipped: () => false,
    breadcrumb: { isExcluded: true },
    getUrl: ({
      appointmentId,
      ...rootArgs
    }: {
      appointmentId: string
      appointmentType: string
    }) => `${getRootUrl(rootArgs)}/${appointmentId}/success`,
  },
}

// The order of the elements in each array determines the order in which they appear in the booking flow

const routeConfig: RouteType[] = Object.keys(routes).map((routeId) => ({
  id: routeId,
  ...routes[routeId],
}))

const getRouteById = (id) => routeConfig.find((route) => route.id === id)

export {
  // exported for testing:
  getRouteById,
  // exported for consumption:
  routes,
  ROOT_PATH,
  routeConfig,
  GROUP_ROOT_PATH,
}
