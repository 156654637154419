/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames/bind'
import { Stepper } from '@babylon/medkit'
import { Spacer } from '@babylon/ui'
import PageTitle from '@/components/PageTitle/PageTitle'
import LandingHeader from '@/components/LandingHeader'
import appStyles from '@/App.module.scss'
import { useTrackingPageView } from '@/programmes/tracking'
import styles from './index.module.scss'
import Spinner from '@/programmes/components/Spinner'

const cx = classNames.bind(styles)

type Props = {
  title: string
  children: React.ReactNode
  hasAppLayout?: boolean
  steps?: string[]
  activeStep?: number
  loading?: boolean
}

const Template: React.FC<Props> = ({
  title,
  children,
  hasAppLayout = true,
  steps,
  activeStep = -1,
  loading,
}) => {
  useTrackingPageView()

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Spinner colour="#000000" />
      </div>
    )
  }

  if (hasAppLayout) {
    return (
      <>
        <PageTitle title={title} />
        <section className={cx('main-section')}>
          {steps && (
            <Spacer bottom={4}>
              <div className={cx('stepper')}>
                <Stepper steps={steps} activeStep={activeStep} />
              </div>
            </Spacer>
          )}
          {children}
        </section>
      </>
    )
  }

  return (
    <>
      <PageTitle title={title} />
      <LandingHeader />
      <main className={appStyles.view}>
        <div className={appStyles.view__inner}>
          <section className={cx('main-section')}>
            {steps && (
              <Spacer bottom={4}>
                <div className={cx('stepper')}>
                  <Stepper steps={steps} activeStep={activeStep} />
                </div>
              </Spacer>
            )}
            {children}
          </section>
        </div>
      </main>
    </>
  )
}

export default Template
