import React, { SFC } from 'react'
import classNames from 'classnames/bind'
import { Button } from '@babylon/medkit'

import styles from './Error.module.scss'
import messages from './Error.messages'

const cx = classNames.bind(styles)

interface Props {
  className?: string
  intl: any
  detail?: string
  icon?: React.ElementType<any>
  isNested?: boolean
  isRetrying?: boolean
  onRetry?: any
  retryMessage?: string
  title: string
  TitleElement: React.ElementType<any>
}

const Error: SFC<Props> = ({
  className,
  intl,
  detail,
  icon,
  isNested,
  isRetrying,
  onRetry,
  retryMessage,
  title,
  TitleElement,
}) => (
  <div
    className={cx('container', { 'container--inline': isNested }, className)}
  >
    {icon && <div className={cx('icon')}>{icon}</div>}
    {TitleElement && (
      <TitleElement className={cx('title')}>{title}</TitleElement>
    )}
    {detail && <p className={cx('detail')}>{detail}</p>}
    {onRetry && (
      <Button onClick={onRetry} loading={isRetrying}>
        {retryMessage || intl.formatMessage(messages.retry)}
      </Button>
    )}
  </div>
)

export default Error
