import gql from 'graphql-tag'
import axios from 'axios'
import uuid from 'uuid'
import { push } from 'connected-react-router'
import {
  login as loginRequest,
  register as registerRequest,
  logout as logoutRequest,
  getUser,
  getAuthToken,
} from '@babylon/auth0'
import Cookies from 'js-cookie'
import { getClient } from '@/config/apollo-client'
import { displayFlashError } from '@/redux/flash/actions'

import { LOADING, LOGIN_SUCCESS, LOGOUT_SUCCESS } from './actionTypes'
import { resetStore } from '@/redux/actions'
import {
  selectAppName,
  selectDefaultLanguage,
  selectEnvironment,
} from '@/redux/selectors'
import { updateProductConfig } from '@/redux/productConfigReducer/actions'
import UserQuery from '@/queries/Patient'
import WebViewMode from '@/utils/WebViewMode'

const MutationValidateUser = gql`
  mutation validateUser($patientId: ID!) {
    validateUser(patientId: $patientId)
  }
`

const QueryCurrentUser = gql`
  query patient {
    patient {
      id
    }
  }
`

const loading = ({ isLoading }) => ({
  type: LOADING,
  isLoading,
})

/**
 *
 * @param {{ userId: string, userUUID: string, authToken: string, email: string }} param0
 */
const loginSuccess = ({ userId, userUUID, authToken, email }) => (
  dispatch,
  getState
) => {
  const client = getClient()
  const state = getState()

  const productConfigUrl = selectEnvironment(state)?.PRODUCT_CONFIG_URL

  if (productConfigUrl) {
    axios
      .get(productConfigUrl, {
        headers: {
          'X-App-Name': selectAppName(state),
          'Accept-Language': selectDefaultLanguage(state),
          'babylon-request-id': uuid(),
          'X-Platform': 'web',
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(({ data: productConfig }) =>
        dispatch(updateProductConfig(productConfig))
      )
  }

  client.mutate({
    mutation: MutationValidateUser,
    variables: { patientId: userId },
  })

  return dispatch({
    type: LOGIN_SUCCESS,
    userId,
    userUUID,
    email,
    lastActiveTime: Date.now(),
  })
}

const UTAG_MAIN_COOKIE_NAME = 'utag_main'
const VISITOR_ID_KEY_NAME = 'v_id'
const TEALIUM_VISITOR_ID_SEARCH_PARAM = 'tealium_visitor_id'
const SESSION_ID_KEY_NAME = 'ses_id'
const TEALIUM_SESSION_ID_SEARCH_PARAM = 'tealium_session_id'

const CAMPAIGN_PARAMS_PREFIX = 'utm_'
const AUTH_0_AB_TESTING = 'ab_override_'

const MIAMI_THEME_QUERY_PARAM = 'enable_miami_theme'

/**
 *
 * @param {string} prefix
 * @returns {Record<string, string>}
 */
function getStartsWithQueryParams(prefix) {
  const { searchParams } = new URL(window.location.href)

  return Object.fromEntries(
    Array.from(searchParams.entries()).filter(([key]) => key.startsWith(prefix))
  )
}

/**
 *
 * @returns {Record<string, string | null>}
 */
function getAdditionalAuthQueryParams() {
  const utagCookie = Cookies.get(UTAG_MAIN_COOKIE_NAME)

  const commonQueryParams = {
    [MIAMI_THEME_QUERY_PARAM]: 'true',
    ...getStartsWithQueryParams(CAMPAIGN_PARAMS_PREFIX),
    ...getStartsWithQueryParams(AUTH_0_AB_TESTING),
  }

  if (!utagCookie) {
    return {
      [TEALIUM_SESSION_ID_SEARCH_PARAM]: null,
      [TEALIUM_VISITOR_ID_SEARCH_PARAM]: null,
      ...commonQueryParams,
    }
  }

  const pairs = utagCookie
    .split('$')
    .map((item) => item.split(':'))
    .filter((p) => p.length === 2)

  const visitorIdPair = pairs.find(([key]) => key === VISITOR_ID_KEY_NAME)
  const sessionIdPair = pairs.find(([key]) => key === SESSION_ID_KEY_NAME)
  // the session ID includes the expiration time e.g. 12345678;exp-session, but only the ID is needed
  // See: https://docs.tealium.com/platforms/javascript/api/cookie-functions/#expiry-options
  const [sessionId] = decodeURIComponent(sessionIdPair?.[1] ?? '').split(';')

  return {
    [TEALIUM_VISITOR_ID_SEARCH_PARAM]: visitorIdPair ? visitorIdPair[1] : null,
    [TEALIUM_SESSION_ID_SEARCH_PARAM]: sessionId || null,
    ...commonQueryParams,
  }
}

const login = (email) => async () => {
  loginRequest(email, getAdditionalAuthQueryParams())
}

const loginWithParams = (email, params) => async () => {
  loginRequest(email, { ...getAdditionalAuthQueryParams(), ...params })
}

const register = () => async () =>
  registerRequest(getAdditionalAuthQueryParams())

/**
 *
 * @param {{
 *  login_hint?: string
 *  weight_loss?: 'true'
 * } | undefined} params
 * @returns {() => Promise<void>}
 */
const registerWithParams = (params = {}) => () =>
  registerRequest({ ...getAdditionalAuthQueryParams(), ...params })

const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

const logout = ({ redirectPath, message } = {}) => (dispatch) => {
  // NOTE: order here is important; we first need to clear caches before redirecting

  dispatch(loading({ isLoading: true }))

  logoutRequest()

  dispatch(resetStore())

  dispatch(logoutSuccess())

  sessionStorage.clear()

  if (message) {
    dispatch(displayFlashError(message))
  }

  if (redirectPath) {
    // redirect
    window.location.replace(redirectPath)
  } else {
    dispatch(loading({ isLoading: false }))
  }
}

const setLoggedIn = (targetUrl) => async (dispatch) => {
  const auth0User = await getUser()
  const userUUID = auth0User['https://babylonhealth.com/user']
  const apolloClient = getClient()

  try {
    const { data } = await apolloClient.query({
      query: UserQuery,
      variables: { id: userUUID },
    })

    const authToken = await getAuthToken()
    const userId = data.patient.id

    dispatch(
      loginSuccess({
        userId,
        userUUID,
        authToken,
        email: data.patient.email,
      })
    )
  } catch {
    dispatch(logout())
    dispatch(loading({ isLoading: false }))
  } finally {
    if (targetUrl && targetUrl !== window.location.pathname) {
      dispatch(push(targetUrl))
    }
  }
}

const setLoggedInWebview = () => async (dispatch) => {
  dispatch(loading({ isLoading: true }))

  try {
    const client = getClient()

    const {
      data: {
        patient: { id: userId },
      },
    } = await client.query({ query: QueryCurrentUser })
    const authToken = WebViewMode.getToken()

    dispatch(loginSuccess({ userId, authToken }))
  } catch {
    dispatch(logout())
  } finally {
    dispatch(loading({ isLoading: false }))
  }
}

export {
  loading,
  login,
  loginWithParams,
  register,
  registerWithParams,
  loginSuccess,
  logout,
  setLoggedIn,
  setLoggedInWebview,
}
