import React from 'react'
import { render } from 'react-dom'
import Cookies from 'js-cookie'
import qs from 'qs'

const FLAG_COOKIE_NAME = 'babylon_feature_flags'

/**
 * get feature flags from query string and set a cookie. If we dont have
 * a query string look for any previously set cookie.
 *
 * render an alert that feature flags are applied to prevent confusion.
 */
export default function getFlags() {
  const queryParams = qs.parse(window.location.search.substring(1))
  const queryParamsFlags = queryParams[FLAG_COOKIE_NAME]
  const cookieFlags = Cookies.get(FLAG_COOKIE_NAME)
  const flags = getFlagsFromString(queryParamsFlags || cookieFlags || '')

  if (queryParamsFlags) {
    Cookies.set(FLAG_COOKIE_NAME, queryParamsFlags)
  }

  const shouldHideFlags = flags.find((flag) => flag.indexOf('hide_flags') > -1)

  if (flags.length && !shouldHideFlags && document.createElement) {
    renderAppliedFlags(flags)
  }

  return createFlagObject(flags)
}

export function createFlagObject(flags = []) {
  return flags.reduce(
    (map, flag) => Object.assign(map, { [flag.toLowerCase().trim()]: true }),
    {}
  )
}

const getFlagsFromString = (flags) => flags.split(',').filter((value) => value)

export const getFlagObjectFromString = (flagString) => {
  const flags = getFlagsFromString(flagString)

  return createFlagObject(flags)
}

export function flagSet(flag) {
  return getFlags()[flag] || false
}

function clearFeatureFlags() {
  // Get domain with the same logic as the chrome extension sets it
  const domain = window.location.hostname.split('.').slice(1).join('.')

  Cookies.remove(FLAG_COOKIE_NAME, {
    path: '/',
    domain,
  })

  const query = qs.parse(window.location.search.substring(1))
  delete query[FLAG_COOKIE_NAME]
  window.location.search = qs.stringify({ ...query })
}

function renderAppliedFlags(flags) {
  const root = document.createElement('div')
  document.documentElement.appendChild(root)

  const style = {
    padding: 12,
    color: 'white',
    backgroundColor: 'black',
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 5,
    fontFamily: 'sans-serif',
    fontSize: 14,
  }

  render(
    <div style={style}>
      Flags applied: {flags.join(',')}
      <button
        style={{ marginLeft: 12, backgroundColor: 'white', border: 0 }}
        onClick={clearFeatureFlags}
      >
        Clear
      </button>
    </div>,
    root
  )
}
