import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'exitView.title',
    defaultMessage: 'Sorry',
  },
  body: {
    id: 'exitView.body',
    defaultMessage: `Based on your answers, it looks like you don't qualify for the medicated weight loss programme at this time.

    You can still access our <u><a>https://www.emed.com/uk/weight-management/10-week-support-programme|habit formation activity pack</a></u> for long-term healthy weight control.

    If you still think you are eligible or would like to discuss your case with someone, <u><a>https://support.babylonhealth.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d|please contact our team here.</a></u>

    If you think you have made an error in your responses, please select below to review your answers.

    <strong>Warning:</strong> Giving inaccurate information could put your health at risk.`,
  },
})
