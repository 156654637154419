import {
  FORM_ERROR,
  RESET_FORM,
  RESET_SINGLE_FORM,
  REGISTER_FORM,
  RESET_POSTCODE_ERRORS,
} from './constants'
import { postcodeErrorKeys } from '@/config/constants'
import { RESET_STORE } from '../constants'
import { LOGOUT } from '@/components/Authentication/actionTypes'

const initialState = {}

/* TO DO: [Refactor] [CW-1344] Reduce complexity to align with new linting rules */
/* eslint-disable complexity */
const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOGOUT:
    case RESET_STORE:
    case RESET_FORM:
      return { ...initialState }
    case REGISTER_FORM:
      return {
        ...state,
        [action.name]: {},
      }
    case RESET_SINGLE_FORM:
      return {
        ...state,
        [action.name]: undefined,
      }
    case FORM_ERROR:
      return {
        ...state,
        [action.name]: {
          errors: action.errors,
        },
      }
    case RESET_POSTCODE_ERRORS:
      return {
        ...state,
        [action.name]: {
          errors: {
            ...(state[action.name] || {}).errors,
            ...postcodeErrorKeys.reduce((obj, key) => {
              if (((state[action.name] || {}).errors || {})[key]) {
                obj[key] = undefined
              }
              return obj
            }, {}),
          },
        },
      }
    default:
      return state
  }
}
/* eslint-enable */
export default reducer
