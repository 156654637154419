import { Appointment } from '@babylon/consumer-api/types'
import { trackEvent } from '@babylon/tracking/gtag'

const redactPhoneNumber = (phoneNumber: string) =>
  phoneNumber &&
  typeof phoneNumber === 'string' &&
  phoneNumber
    .split('')
    .map((n, i) => (i !== 0 && i < phoneNumber.length - 3 ? '*' : n))
    .join('')

type AppointmentTrackingDetails = Pick<
  Appointment,
  'time' | 'consultant' | 'patient'
> & { medium?: string | null; id?: string | number | null }

const trackAppointmentEvent = (
  action: string,
  appointment?: AppointmentTrackingDetails
) => {
  const appointmentDetails = appointment
    ? {
        ...(appointment.id && { appointmentID: appointment.id }),
        ...(appointment.time && { appointmentTime: appointment.time }),
        ...(appointment.medium && { consultationMethod: appointment.medium }),
        ...(appointment.consultant?.role && {
          consultantType: appointment.consultant.role,
        }),
        ...(appointment?.patient?.id && {
          appointmentPatientId: appointment.patient.id,
        }),
      }
    : {}

  trackEvent({
    category: 'appointments',
    action,
    ...appointmentDetails,
  })
}

export { redactPhoneNumber, trackAppointmentEvent }
