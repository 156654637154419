import React from 'react'
import {
  CheckBoxGroupValue,
  Answer,
  Question,
  QuestionOption,
  RadioGroupValue,
} from '../../types'
import TextArea from '../TextArea'
import RadioGroup from '../RadioGroup'
import CheckboxGroup from '../CheckboxGroup'
import styles from './FollowUpQuestion.module.scss'
import Checkbox from '@/programmes/components/Form/components/Checkbox'

interface Props {
  error: boolean
  option: {
    label: string
    value: string
    followUpQuestion?: Question
  }
  value: Answer
  onChange: (
    value: string,
    followUpQuestionValue:
      | string
      | boolean
      | CheckBoxGroupValue[]
      | RadioGroupValue
  ) => void
}

const renderFollowUpQuestion = (
  option: {
    label: string
    value: string
    followUpQuestion?: Question
  },
  value: Answer,
  error: boolean,
  onChange: (
    value: string,
    followUpQuestion: string | boolean | CheckBoxGroupValue[] | RadioGroupValue
  ) => void
) => {
  switch (option.followUpQuestion?.inputType?.component) {
    case 'textArea':
      return (
        <TextArea
          label={option.followUpQuestion.title}
          value={value as string}
          body={option.followUpQuestion.body}
          required={option.followUpQuestion.required}
          error={error}
          onChange={(event) => onChange(option.value, event.target.value)}
        />
      )
    case 'checkboxGroup':
      return (
        <CheckboxGroup
          id={option.label}
          label={option.followUpQuestion.title}
          error={error}
          options={option.followUpQuestion.options as QuestionOption[]}
          selectedOptions={value as CheckBoxGroupValue[]}
          onChange={(selectedOptions) => {
            onChange(option.value, selectedOptions)
          }}
        />
      )
    case 'radioGroup':
      return (
        <RadioGroup
          id={option.label}
          label={option.followUpQuestion.title}
          error={error}
          options={option.followUpQuestion.options as QuestionOption[]}
          selectedOption={value as RadioGroupValue}
          onChange={(selectedOption) => {
            onChange(option.value, selectedOption)
          }}
        />
      )
    case 'checkBox':
      return (
        <Checkbox
          id={option.label}
          label={option.followUpQuestion.title}
          error={error}
          checked={value as boolean}
          onChange={(event) => onChange(option.value, event.target.checked)}
        />
      )

    default:
      return null
  }
}

const FollowUpQuestion: React.FC<Props> = ({
  error,
  option,
  value,
  onChange,
}) => (
  <div
    className={styles.container}
    data-testid={`${option.followUpQuestion?.id}-follow-up-question`}
  >
    {renderFollowUpQuestion(option, value, error, onChange)}
  </div>
)

export default FollowUpQuestion
