interface ChatbotSlice {
  conversation: unknown
  initialQuestion: unknown
  isStarting: boolean
  isStarted: boolean
}

const initialState: ChatbotSlice = {
  conversation: null,
  initialQuestion: null,
  isStarting: false,
  isStarted: false,
}

const chatbotSlice = (state = initialState, action): ChatbotSlice => {
  switch (action.type) {
    case 'CHATBOT.START.PENDING':
      return {
        ...state,
        isStarted: false,
        isStarting: true,
      }
    case 'CHATBOT.START.SUCCESS':
      return {
        ...state,
        isStarted: true,
        isStarting: false,
        initialQuestion: action.initialQuestion,
      }
    case 'CHATBOT.START.FAILURE':
      return initialState
    case 'CHATBOT.RESET':
      return initialState
    default:
      return state
  }
}

export default chatbotSlice
