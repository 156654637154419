import { useContext, useCallback } from 'react'
import snakeCase from 'lodash/snakeCase'
import { useSelector } from 'react-redux'
import { TrackingContext } from '@babylon/tracking/react/contexts/trackingContext'
import {
  TrackingService,
  TrackProps,
  TrackingEventProps,
} from '@babylon/tracking/react/types'
import gql from 'graphql-tag'
import ApolloClient from 'apollo-client'
import { getClient } from '@/config/apollo-client'
import debugLog from '@/utils/debugLog'

enum TRACKING_EVENT_TYPE {
  event,
  view,
}

function snakeCasePayload(payload: TrackProps) {
  const result = {}

  for (const [key, value] of Object.entries(payload)) {
    const snakedKey = snakeCase(key)
    result[snakedKey] = value
  }

  return result
}

type WebAppTrackProps = TrackProps & { screenTitle: string }

const EXTERNAL_ID_QUERY = gql`
  query BrazeExternalId {
    externalId(externalServiceName: "braze")
  }
`

/**
 * Hook interfaces with the TealiumService and exposes wrapped versions of the trackEvent and trackView methods
 */
export default function useTracking() {
  const user = useSelector(
    (state: { auth: { user: { id: string; uuid: string; email: string } } }) =>
      state?.auth?.user
  )
  const service = useContext(TrackingContext) as TrackingService

  const trackFn = useCallback(
    async (trackingType: TRACKING_EVENT_TYPE, payload: WebAppTrackProps) => {
      const { moduleName, elementName, elementType, screenTitle } = payload
      const elementId = elementName && {
        element_id: `${moduleName}-${elementName}-${elementType}`,
      }

      const client = getClient() as ApolloClient<any> | undefined

      let braze_external_id: string | undefined

      if (user?.id && window.babylonConfig?.LOG_BRAZE_EXTERNAL_ID === 'true') {
        try {
          const result = await client?.query({
            query: EXTERNAL_ID_QUERY,
            errorPolicy: 'ignore',
            context: {
              skipFormError: true,
            },
          })
          braze_external_id = result?.data?.externalId
        } catch {
          // fail silently
          debugLog('Failed to get external id', 'warn')
        }
      }

      const formattedPayload = snakeCasePayload(payload)
      const props: TrackingEventProps = {
        ...(user?.uuid ? { uuid: user.uuid } : {}),
        ...(user?.id ? { patient_id: user.id } : {}),
        ...(user?.email ? { patient_email: user.email } : {}),
        screen_title: screenTitle,
        ...elementId,
        ...formattedPayload,
        braze_external_id,
      }

      switch (trackingType) {
        case TRACKING_EVENT_TYPE.view:
          service.trackView(props)

          break
        case TRACKING_EVENT_TYPE.event:
        default:
          service.trackEvent(props)

          break
      }
    },
    [service, user?.id, user?.uuid, user?.email]
  )

  const trackView = useCallback(
    (payload: WebAppTrackProps) => {
      trackFn(TRACKING_EVENT_TYPE.view, payload)
    },
    [trackFn]
  )

  const trackEvent = useCallback(
    (payload: WebAppTrackProps) => {
      trackFn(TRACKING_EVENT_TYPE.event, payload)
    },
    [trackFn]
  )

  return { trackView, trackEvent }
}
