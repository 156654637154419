import { useSelector } from 'react-redux'

const selectIncomingCall = (state) => state.videoCall.incomingCall
const selectInCall = (state) => state.videoCall.inCall

const useVideoCallState = () => [
  useSelector(selectIncomingCall),
  useSelector(selectInCall),
]

export { selectInCall, selectIncomingCall, useVideoCallState }
