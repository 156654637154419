import { defineMessages } from 'react-intl'

export default defineMessages({
  authError: {
    id: 'ServiceNowChatPage.authError',
    defaultMessage: 'Oops, something went wrong.',
  },
  chatLoading: {
    id: 'ServiceNowChatPage.chatLoading',
    defaultMessage: 'Connecting your chat...',
  },
  retryBtn: {
    id: 'ServiceNowChatPage.retryBtn',
    defaultMessage: 'Retry',
  },
  contactUsBody: {
    id: 'ServiceNowChatPage.contactUsBody',
    defaultMessage: 'Please try again or try a',
  },
  contactUsLink: {
    id: 'ServiceNowChatPage.contactUsLink',
    defaultMessage: 'different contact method',
  },
})
