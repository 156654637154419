// HOC DEPRECATED - PLEASE USE useSupportNumber.tsx instead

import gql from 'graphql-tag'
import { applySpec, path } from 'ramda'
import { graphql } from '@apollo/react-hoc'
import { compose } from 'recompose'

import withUserId from './withUserId'

const SupportNumberQuery = gql`
  query Patient($userId: ID!) {
    patient(id: $userId) {
      id # we will get this breaking error if we don't query the id: https://github.com/apollographql/apollo-client/issues/2510
      region {
        id # see id above
        support_number
      }
    }
  }
`

const parseSupportNumberQuery = applySpec({
  supportNumber: path([
    'supportNumberQuery',
    'patient',
    'region',
    'support_number',
  ]),
  isLoadingSupportNumber: path(['supportNumberQuery', 'loading']),
})

/**
 * @type {<T extends Record<string, any>(component: React.FC<T & { supportNumber?: string }) => React.FC<T>}
 */
const withSupportNumber = compose(
  withUserId,
  graphql(SupportNumberQuery, {
    name: 'supportNumberQuery',
    options: ({ currentUserId }) => ({
      variables: { userId: currentUserId },
    }),
    props: parseSupportNumberQuery,
  })
)

export {
  // exported for testing:
  parseSupportNumberQuery,
}

export default withSupportNumber
