/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  useModalActions,
  ButtonSimple,
  ThemeProvider,
  Text,
} from '@babylon/medkit'
import { useHistory } from 'react-router-dom'
import { useEmbeddedViewModeEnabled } from '@/redux/selectors'
import styles from '../VideoCall.module.scss'
import messages from '../VideoCall.messages'
import useMessagePoster, {
  AppointmentEventType,
} from '@/hooks/useMessagePoster'
import { PARTNERS_HOME_PATH } from '@/components/Appointments/constants'

const CallEndFeedbackModal = ({ closeFeedbackModal, appointmentId }) => {
  const intl = useIntl()
  const { openModal, closeModal, isModalOpen } = useModalActions()
  const [answered, setAnswered] = useState(false)
  const isEmbeddedViewModeEnabled = useEmbeddedViewModeEnabled()
  const history = useHistory()
  const postMessage = useMessagePoster({
    disabled: !isEmbeddedViewModeEnabled,
  })

  const accept = async () => {
    closeModal()
    closeFeedbackModal()
    setAnswered(true)

    postMessage({
      type: AppointmentEventType.ConsultationCallEnded,
      data: {
        appointmentId,
      },
    })

    const redirectPath = isEmbeddedViewModeEnabled ? PARTNERS_HOME_PATH : '/'
    history.push(redirectPath)
  }

  const decline = async () => {
    closeModal()
    closeFeedbackModal()
    setAnswered(true)
  }

  const CallEndModalContent = () => (
    <>
      <div className={styles.caller}>
        <Text
          id="modal-title"
          className={styles.caller__title}
          variant="body"
          tag="h1"
        >
          {intl.formatMessage(messages.callEndFeedbackModalMessage)}
        </Text>
      </div>
      {/* Two theme providers are needed for different colour buttons */}
      <div className={styles.caller__actions}>
        <ThemeProvider
          theme={{
            '--medkit-button-color-one': '#008535',
            '--medkit-button-color-two': 'transparent',
          }}
        >
          <ButtonSimple className={styles.caller__action} onClick={accept}>
            {intl.formatMessage(messages.callEndFeedbackModalAnswerYes)}
          </ButtonSimple>
        </ThemeProvider>
        <ThemeProvider
          theme={{
            '--medkit-button-color-one': '#d53232',
            '--medkit-button-color-two': 'transparent',
          }}
        >
          <ButtonSimple className={styles.caller__action} onClick={decline}>
            {intl.formatMessage(messages.callEndFeedbackModalAnswerNo)}
          </ButtonSimple>
        </ThemeProvider>
      </div>
    </>
  )

  useEffect(() => {
    if (!isModalOpen && !answered) {
      openModal(<CallEndModalContent />, {
        isDismissable: false,
        role: 'alertdialog',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answered, isModalOpen])

  return null
}

export default CallEndFeedbackModal
