import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import ActionWrapper from '../ActionWrapper'

import styles from './richLink.module.scss'

const RichLink = ({ action, extra, isExternal, summary, title, className }) => (
  <ActionWrapper
    className={cx(styles.richLink, className)}
    action={action}
    isExternal={isExternal}
  >
    <p className={styles.richLink__title}>{title}</p>
    {summary && <p className={styles.richLink__summary}>{summary}</p>}
    {extra && <p className={styles.richLink__extra}>{extra}</p>}
  </ActionWrapper>
)

RichLink.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  extra: PropTypes.string,
  isExternal: PropTypes.bool,
  summary: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default RichLink
