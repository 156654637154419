import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { useWeightLossRefillConfirmationInfo } from '@/redux/selectors'
import WeightLossWegovyImage from '@/assets/images/weight-loss_wegovy.jpg'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import messages from '../messages'

const cx = classNames.bind(styles)

const RefillConfirmationPage = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const { search } = useLocation()
  const announcement = useWeightLossRefillConfirmationInfo()

  const searchParams = new URLSearchParams(search)
  const isCheckIn = searchParams.get('check-in') !== 'false'

  return (
    <Template
      title={t(
        isCheckIn
          ? messages.refillCheckInConfirmationPageTitle
          : messages.refillConfirmationPageTitle
      )}
    >
      <h1>
        {t(
          isCheckIn
            ? messages.refillCheckInConfirmationPageHeading
            : messages.refillConfirmationPageHeading
        )}
      </h1>
      <p>
        {t(
          isCheckIn
            ? messages.checkInConfirmationPageDescription
            : messages.refillConfirmationPageDescription
        )}
      </p>
      {announcement && <Announcement content={announcement} />}
      <img
        className={cx('check-in-startup-confirmation-image')}
        src={WeightLossWegovyImage}
        alt={t(messages.setupNextStepsPageImageDescription)}
      />
      <Button
        onClick={() => {
          history.push('/')
        }}
      >
        {t(messages.setupConfirmationPageCta)}
      </Button>
    </Template>
  )
}

export default RefillConfirmationPage
