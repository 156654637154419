import React from 'react'

export interface IEnhancedSection {
  render: () => JSX.Element
}

export type EnhancedSchemaMap = Record<string, IEnhancedSection>

interface EnhancedSectionProps {
  currentStepId: string
  schema: EnhancedSchemaMap
}

export function createEnhancedSection<P>(
  Component: React.ComponentType<P>,
  props: P
): IEnhancedSection {
  return {
    render: () => <Component {...props} />,
  }
}

const EnhancedSection: React.FC<EnhancedSectionProps> = ({
  currentStepId,
  schema,
}) => {
  const sectionForId = schema[currentStepId]

  if (!sectionForId) {
    return null
  }

  return sectionForId.render()
}

export default EnhancedSection
