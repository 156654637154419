import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import QuestionBody from '@/programmes/components/FormV2/components/QuestionBody'
import style from './Slider.module.scss'
import QuestionTitle from '@/programmes/components/FormV2/components/QuestionTitle/QuestionTitle'

interface Settings {
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  markers?: boolean
  minLabel?: string
  maxLabel?: string
}

export interface SliderProps {
  id?: string
  label?: string
  body?: string | Array<string> | React.ReactNode
  settings?: Settings
  handleSliderValue: (value: string) => void
}

const getSliderAnswer = (id: string) => {
  const questionnaireData = sessionStorage.getItem('questionnaire-data')

  if (questionnaireData) {
    const parsedAnswers = JSON.parse(questionnaireData)

    return parsedAnswers.answers[id]
  }

  return undefined
}

function extractValue(input: string): number | null {
  const match = input.match(/^(\d+) out of/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

const Slider: React.FC<SliderProps> = ({
  label,
  body,
  id,
  settings,
  handleSliderValue,
}) => {
  const min = settings?.min || 1
  const max = settings?.max || 10
  const step = settings?.step || 1
  const defaultValue = settings?.defaultValue || 5
  const markers = settings?.markers
  const minLabel = settings?.minLabel
  const maxLabel = settings?.maxLabel

  const [inputValue, setInputValue] = useState<string>(defaultValue.toString())
  const [isFocused, setIsFocused] = React.useState(false)

  const percentage = ((Number(inputValue) - min) / (max - step)) * 100

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    if (id) {
      const answer = getSliderAnswer(id)
      if (answer) {
        const value = extractValue(answer)
        if (value) {
          setInputValue(value.toString())
        }
      }
    }
  }, [id])

  useEffect(() => {
    if (handleSliderValue && inputValue) {
      handleSliderValue(`${inputValue} out of ${max}`)
    }
  }, [handleSliderValue, inputValue, max])

  const onBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className={cn(style.container)}>
      {label && <QuestionTitle content={label} id={id} type="label" />}

      {body && <QuestionBody body={body} id={id} />}

      <div className={style.wrapper}>
        {(minLabel || maxLabel) && (
          <div className={style.customLabels}>
            {minLabel && <span className={style.minLabel}>{minLabel}</span>}
            {maxLabel && <span className={style.maxLabel}>{maxLabel}</span>}
          </div>
        )}

        <div className={style.sliderContainer}>
          <span className={style.track} />
          <span
            className={style.selected}
            style={{ width: `${percentage}%` }}
          />
          <input
            type="range"
            id={label}
            min={min}
            max={max}
            step={step}
            value={Number(inputValue)}
            onChange={handleOnChange}
            onFocus={() => setIsFocused(true)}
            onBlur={onBlur}
            className={cn(style.slider, {
              [style.focusedThumb]: isFocused,
            })}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={Number(inputValue)}
            style={{
              backgroundSize: `${
                ((Number(inputValue) - min) / (max - min)) * 100
              }% 100%`,
            }}
          />
          {markers && (
            <div data-testid="slider-markers" className={style.sliderMarkers}>
              {Array.from(
                { length: (max - min) / step + 1 },
                (_, i) => min + i * step
              ).map((marker) => (
                <span
                  key={marker}
                  className={cn(style.sliderMarker, {
                    [style.activeMarker]: marker === Number(inputValue),
                  })}
                  style={{ left: `${((marker - min) / (max - min)) * 100}%` }}
                >
                  {marker}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Slider
