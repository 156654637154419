import { defineMessages } from 'react-intl'

export default defineMessages({
  'babylon-logo': {
    id: 'App.babylon-logo',
    defaultMessage: 'Babylon Health logo',
  },
  'gp-at-hand-logo': {
    id: 'App.gp-at-hand-logo',
    defaultMessage: 'GP at Hand Logo',
  },
  'nhs-services-logo': {
    id: 'App.nhs-services-logo',
    defaultMessage: 'NHS Services Logo',
  },
  'nhs-uhb-ed-logo': {
    id: 'App.nhs-uhb-ed-logo',
    defaultMessage: 'NHS UHB Logo',
  },
  'babylon-brazil-einstein': {
    id: 'App.babylon-brazil-einstein-logo',
    defaultMessage: 'Albert Einstein logo',
  },
  'covideo-logo': {
    id: 'App.covideo-logo',
    defaultMessage: 'NHS Logo',
  },
  'ask-a-and-e-logo': {
    id: 'App.nhs-rwt-logo',
    defaultMessage: 'Ask A&E',
  },
  'columbus-direct-logo': {
    id: 'App.columbus-direct-logo',
    defaultMessage: 'Columbus Direct Logo',
  },
  'united-healthcare-logo': {
    id: 'App.united-healthcare-logo',
    defaultMessage: 'United Healthcare Logo',
  },
  'apl-health-logo': {
    id: 'App.apl-health-logo',
    defaultMessage: 'Apl Health Logo',
  },
  'bupa-logo': {
    id: 'App.bupa-logo',
    defaultMessage: 'Bupa Logo',
  },
})
