import { getStyleValues } from './style'

/*
  given a DOM element
  returns { w, h } where both are numbers
  representing the dimensions of the element (optionally including margin)
*/
const getDimensions = (el, { withMargin } = {}) => {
  if (!el) {
    return undefined
  }

  const { width, height } = el.getBoundingClientRect()
  const margins = withMargin
    ? getStyleValues(el, [
        { prop: 'margin-left', options: { asNumber: true } },
        { prop: 'margin-right', options: { asNumber: true } },
        { prop: 'margin-top', options: { asNumber: true } },
        { prop: 'margin-bottom', options: { asNumber: true } },
      ])
    : null

  return {
    w: margins
      ? width + margins['margin-left'] + margins['margin-right']
      : width,
    h: margins
      ? height + margins['margin-top'] + margins['margin-bottom']
      : height,
  }
}

/*
  returns { x, y } where both are numbers
  representing the maximum scroll position of the given element,
  or else the maximum scroll position of the page
*/
const getMaxScroll = (el) => ({
  x: el
    ? el.scrollWidth - el.clientWidth
    : document.body.scrollWidth - window.innerWidth,
  y: el
    ? el.scrollHeight - el.clientHeight
    : document.body.scrollHeight - window.innerHeight,
})

/*
  given a DOM element
  returns { x, y } where both are numbers
  representing the offset of the element relative to the page
*/
const getOffset = (el) => {
  if (!el) {
    return undefined
  }

  const { top, left } = el.getBoundingClientRect()
  const { x, y } = getScroll()

  return {
    x: left + x,
    y: top + y,
  }
}

/*
  returns { x, y } where both are numbers
  representing the scroll position of the given element,
  or else the scroll position of the page
*/
const getScroll = (el) => ({
  x: el
    ? el.scrollLeft
    : window.pageXOffset || document.documentElement.scrollLeft,
  y: el
    ? el.scrollTop
    : window.pageYOffset || document.documentElement.scrollTop,
})

/*
  returns { x, y } where both are numbers
  representing the page dimensions
*/
const getViewportDimensions = () => ({
  /* window.inner* includes scrollbars, documentElement.client* does not
  both options may have specific cross-browser oddities
  as such, we take the biggest value of either to get the best possible result
  see: http://ryanve.com/lab/dimensions */
  w: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  h: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
})

export {
  getDimensions,
  getMaxScroll,
  getOffset,
  getScroll,
  getViewportDimensions,
}
