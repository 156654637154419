import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import styles from './switch.module.scss'

const cx = classNames.bind(styles)

class Switch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: !!props.defaultValue,
    }

    if (
      props.checked !== null &&
      props.checked !== undefined &&
      !props.onChange &&
      !props.disabled
    ) {
      console.warn(
        'Switch Component: Careful! You are providing a `checked` value but no onChange handler which means the switch will not be updated'
      )
    }
  }

  toggleSwitch = () => {
    if (this.props.disabled) {
      return
    }
    if (this.props.onChange) {
      this.props.onChange(!this.state.checked)
    }
    if (this.props.checked === null || this.props.checked === undefined) {
      this.setState({ checked: !this.state.checked })
    }
  }

  render() {
    const { disabled, className } = this.props
    let checked = !!this.state.checked
    if (!(this.props.checked === null || this.props.checked === undefined)) {
      checked = this.props.checked
    }
    return (
      <div
        className={cx(styles.switchContainer, className, { checked, disabled })}
        onClick={this.toggleSwitch}
        role="switch"
        tabIndex={0}
        aria-checked={checked}
      >
        <div className={cx(styles.switch, { checked })} />
      </div>
    )
  }
}

Switch.propTypes = {
  /** controlled value for the switch */
  checked: PropTypes.bool,
  /** Initial value for the switch */
  defaultValue: PropTypes.bool,
  /** change handler */
  onChange: PropTypes.func,
  /** set true to disable component changes */
  disabled: PropTypes.bool,
  /** pass your own CSS styling */
  className: PropTypes.string,
}

Switch.defaultProps = {
  checked: null,
  defaultValue: null,
  onChange: null,
  disabled: false,
  className: null,
}

export default Switch
