import { defineMessages } from 'react-intl'

export default defineMessages({
  mediaPermissionsModalWarningTitle: {
    id: 'VideoCall.mediaPermissionsModal.warning.title',
    defaultMessage: 'You are about to join a video consultation',
  },
  mediaPermissionsModalWarningDescriptionFirst: {
    id: 'VideoCall.mediaPermissionsModal.warning.description.first',
    defaultMessage:
      'Allow access to camera and microphone. This is so the clinician can see and hear you.',
  },
  mediaPermissionsModalWarningDescriptionSecond: {
    id: 'VideoCall.mediaPermissionsModal.warning.description.second',
    defaultMessage: 'When asked, select {permissionsAction}.',
  },
  mediaPermissionsModalAllow: {
    id: 'VideoCall.mediaPermissionsModal.allow',
    defaultMessage: 'Allow',
  },
  mediaPermissionsModalDeclinedTitle: {
    id: 'VideoCall.mediaPermissionsModal.declined.title',
    defaultMessage: 'Allow access to camera and microphone',
  },
  mediaPermissionsModalDeclinedDescriptionFirst: {
    id: 'VideoCall.mediaPermissionsModal.declined.description.first',
    defaultMessage:
      'We need camera and microphone access so the clinician can see and hear you.',
  },
  mediaPermissionsModalDeclinedDescriptionSecond: {
    id: 'VideoCall.mediaPermissionsModal.declined.description.second',
    defaultMessage:
      'You will need to change the camera and microphone permissions in your browser.',
  },
  mediaPermissionsModalDeclinedDescriptionThird: {
    id: 'VideoCall.mediaPermissionsModal.declined.description.third',
    defaultMessage:
      'If you can’t to do this, the clinician will call you by phone instead.',
  },
  mediaPermissionsModalProblemsTitle: {
    id: 'VideoCall.mediaPermissionsModal.problems.title',
    defaultMessage: 'If you still have problems:',
  },
  mediaPermissionsModalProblemsDescription: {
    id: 'VideoCall.mediaPermissionsModal.problems.description',
    defaultMessage: 'Call us to reschedule your appointment.',
  },
  mediaPermissionsModalCameraMicrophoneNotActive: {
    id: 'VideoCall.mediaPermissionsModal.permissionsMessage.notActive',
    defaultMessage: 'Camera and microphone not active',
  },
  mediaPermissionsModalCameraMicrophoneBlocked: {
    id: 'VideoCall.mediaPermissionsModal.permissionsMessage.blocked',
    defaultMessage: 'Camera and Microphone are blocked',
  },
  videoControlsTurnOffMicrophone: {
    id: 'VideoCall.controls.turnOffMicrophone',
    defaultMessage: 'Turn off microphone',
  },
  videoControlsStopVideo: {
    id: 'VideoCall.controls.stopVideo',
    defaultMessage: 'Stop video',
  },
  videoControlsAltTagsEndConsultation: {
    id: 'VideoCall.controls.altTags.endConsultation',
    defaultMessage: 'End Consultation',
  },
  videoCallPageSeoTitle: {
    id: 'VideoCall.seo.title',
    defaultMessage: '{appTitle} video appointment',
  },
  incomingCallModalCalling: {
    id: 'IncomingCallModal.calling',
    defaultMessage: 'calling',
  },
  incomingCallModalAnswerCall: {
    id: 'IncomingCallModal.answerCall',
    defaultMessage: 'Accept',
  },
  incomingCallModalDeclineCall: {
    id: 'IncomingCallModal.declineCall',
    defaultMessage: 'Decline',
  },
  incomingCallModalConsultant: {
    id: 'IncomingCallModal.consultant',
    defaultMessage: 'Consultant',
  },
  incomingCallModalA11YHiddenAcceptOrDecline: {
    id: 'IncomingCallModal.accessibility.hidden.acceptOrDecline',
    defaultMessage: 'Accept or decline?',
  },
  videoCallTitle: {
    id: 'VideoCall.title',
    defaultMessage: 'Consultation Video Call',
  },
  callEndFeedbackModalMessage: {
    id: 'CallEndFeedbackModal.message',
    defaultMessage: 'Has your consultation ended?',
  },
  callEndFeedbackModalAnswerYes: {
    id: 'CallEndFeedbackModal.yes',
    defaultMessage: 'Yes',
  },
  callEndFeedbackModalAnswerNo: {
    id: 'CallEndFeedbackModal.no',
    defaultMessage: "No, I'll wait",
  },
})
