import gql from 'graphql-tag'

export default gql`
  fragment familyMembers on Query {
    familyMembers {
      id
      uuid
      first_name
      last_name
      minor
      date_of_birth
      email
      gender
      phone_number
      phone_country_code
      avatar_full_url
      queued_for_activation
      preferred_consumer_network_id
      preferred_consumer_network_uuid
      consumer_networks {
        id
        uuid
      }
    }
  }
`
