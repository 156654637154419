import notificationTypes from '@babylon/notification-types'

import { getRouteUrl as getBookingInvitationUrl } from '../../../Appointments/BookingInvitation/routes'
import { getRouteUrl as getRepeatPrescriptionUrl } from '../../../RepeatPrescriptions/routes'
import { getRouteById } from '@/routes/helpers'
import { repeatPrescriptionNotificationTypes } from '../../notificationTypeGroups'

/* TO DO: [Refactor] [CW-1336] Reduce complexity to align with new linting rules */
/* eslint-disable complexity */
const getActionUrl = ({ itemId, itemType, parentItemId, userId }) => {
  if (repeatPrescriptionNotificationTypes.includes(itemType)) {
    return getRepeatPrescriptionUrl('detail', { prescriptionId: itemId })
  }

  switch (itemType.toUpperCase()) {
    case notificationTypes.EXTENDED_APPOINTMENT_INVITE_CREATED:
    case notificationTypes.APPOINTMENT_INVITE_CREATED:
    case notificationTypes.APPOINTMENT_INVITE_DISMISSED:
      return getBookingInvitationUrl('bookBegin', { inviteId: itemId })
    case notificationTypes.UPCOMING_APPOINTMENT:
    case notificationTypes.APPOINTMENT_REMINDER:
    case notificationTypes.APPOINTMENT_REQUIRED_CHECK_IN_MISSED:
    case notificationTypes.NO_SHOW_APPOINTMENT:
    case notificationTypes.CLINICIAN_LATENESS:
      return `/clinical-records/appointments/${itemId}`
    case notificationTypes.CLINICIAN_LATENESS_FIRST_MATCHED:
    case notificationTypes.CLINICIAN_LATENESS_SECOND_MATCHED:
    case notificationTypes.CLINICIAN_LATENESS_FIRST_NOT_MATCHED:
    case notificationTypes.CLINICIAN_LATENESS_SECOND_NOT_MATCHED:
      return `/clinical-records/${itemId}/waiting-room`
    case notificationTypes.APPOINTMENT_CHECK_IN_REMINDER:
      return `/clinical-records/${itemId}/check-in`
    case notificationTypes.NEW_PRESCRIPTION:
    case notificationTypes.PRESCRIPTION_EXPIRY:
    case notificationTypes.PHARMACY_UNAVAILABLE:
    case notificationTypes.PRESCRIPTION_READY_FOR_PICKUP:
    case notificationTypes.DRUG_DELIVERY_CONFIRMED:
      return `/clinical-records/prescriptions/${itemId}`
    case notificationTypes.RATE_APPOINTMENT:
      return getRouteById('bookConsultation', 'consultationFeedback').getUrl({
        consultationId: itemId,
      })
    case notificationTypes.REDEMPTION_USED:
      return getRouteById('bookConsultation', 'consultationNew').getUrl()
    case notificationTypes.REDEMPTION_EXPIRY:
    case notificationTypes.MEMBERSHIP_REMINDER:
      return `/account/${userId}/membership/plans`
    case notificationTypes.POST_PAYMENT_PENDING:
      return `/consultations/${itemId}/payment`
    case notificationTypes.NEW_REFERRAL:
      return `/clinical-records/appointments/${itemId}/referrals`
    case notificationTypes.CHATBOT_RESPONSE:
      return getRouteById('clinicalRecords', 'conversationDetail').getUrl({
        userId,
        conversationId: parentItemId,
        elementId: itemId,
      })
    case notificationTypes.BLOOD_ANALYSIS_HEALTHY:
    case notificationTypes.BLOOD_ANALYSIS_APPOINTMENT:
      return '/feature-unavailable/?feature=monitor'
    case notificationTypes.ID_VERIFICATION_FAILED:
      return '/account/?idVerification=true'
    case notificationTypes.PRESCRIPTION_EXPIRED:
      return '/consultations/new'
    case notificationTypes.TEST_RESULTS_READY:
      return '/tests'
    default:
      return undefined
  }
}
/* eslint-enable */

export default getActionUrl
