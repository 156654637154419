import gql from 'graphql-tag'

export default gql`
  mutation updateGP($id: Int!, $formData: GPInformation!) {
    updateGP(id: $id, formData: $formData) {
      id
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
    }
  }
`
