import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'

import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT, VERSION } from '@/config'
import { selectAppName } from '@/redux/selectors'

import { healthMetricsQuery, questionnaireQuery } from './queries'
import { mapQuestionnaireData } from './questionnaireHelper'
import { MyHealthRootQuery } from '@/programmes/hooks/useWellnessReportMetrics/myHealthTypes'
import { ReportColors } from '@/programmes/Wellness/Report/types'

const metricConfig = {
  restingHeartRate: {
    enabled: true,
  },
  bloodPressure: {
    enabled: true,
  },
  bloodOxygenSaturation: {
    enabled: true,
  },
  heartRateVariability: {
    enabled: true,
  },
  weight: {
    enabled: true,
  },
  height: {
    enabled: true,
  },
  bodyMassIndex: {
    enabled: true,
  },
}

export type MetricConfig = {
  id: string
  data: {
    caption: string
    value: string
    unit: string
    rag?: ReportColors
    interpretation?: string
  }
}

const mapHealthMetricsData = (healthMetrics: MyHealthRootQuery) =>
  // eslint-disable-next-line no-underscore-dangle
  healthMetrics?._myhealth_healthStatus?.myHealthMetrics
    ?.filter(
      (metric) => metricConfig[metric?.id] && metric?.lastDatapoints?.[0]?.value
    )
    .map((metric) => {
      const metricValue = metric.lastDatapoints?.[0]?.value

      let stringValue = ''

      if (metricValue?.__typename === 'MyHealthBloodPressureValue') {
        stringValue = `${metricValue.systolic}/${metricValue.diastolic}`
      } else if (metricValue?.__typename === 'MyHealthNumericValue') {
        stringValue = `${metricValue.value.toFixed(
          metric.descriptor?.unitPrecision || 0
        )}`
      } else if (metricValue?.__typename === 'MyHealthNumericWithLabelValue') {
        stringValue = `${metricValue.label}`
      }

      return {
        id: metric.id,
        data: {
          caption: metric.name,
          value: stringValue,
          unit: metric?.descriptor?.displayUnit,
          rag: metric.rag && ReportColors[metric.rag!],
        },
      } as MetricConfig
    })

const useGetWellnessReportMetrics = () => {
  const appName = useSelector(selectAppName)
  const [healthMetricsData, setHealthMetricsData] = useState<MetricConfig[]>([])
  const [questionnaireData, setQuestionnaireData] = useState(null)
  const userUUID = useSelector((state: any) => state.auth?.user?.uuid)

  const {
    data: healthMetrics,
    loading: healthMetricsLoading,
    error: healthMetricsError,
  } = useQuery<MyHealthRootQuery>(healthMetricsQuery, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      member_uuid: userUUID,
      acceptLanguage: 'en-GB',
      appVersion: VERSION,
      timeZoneOffset: '+00:00',
      appName,
      metricIds: Object.keys(metricConfig).filter(
        (k) => metricConfig[k].enabled
      ),
    },
    fetchPolicy: 'no-cache', // Skip cache to avoid fragment matcher issues on inMemoryCache
  })

  const {
    data: questionnaire,
    loading: questionnaireLoading,
    error: questionnaireError,
  } = useQuery(questionnaireQuery, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      memberId: userUUID,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (healthMetrics) {
      setHealthMetricsData(mapHealthMetricsData(healthMetrics))
    }
  }, [healthMetrics])

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaireData(mapQuestionnaireData(questionnaire))
    }
  }, [questionnaire])

  return {
    loading: healthMetricsLoading || questionnaireLoading,
    data: {
      healthMetrics: healthMetricsData,
      questionnaire: questionnaireData,
    },
    error:
      healthMetricsError || questionnaireError
        ? {
            healthMetricsError,
            questionnaireError,
          }
        : null,
  }
}

export default useGetWellnessReportMetrics
