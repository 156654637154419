import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import {
  useWeightLossCheckInHomeInfo,
  useWeightLossNoProctorEnabled,
} from '@/redux/selectors'
import { login as loginAction } from '@/components/Authentication/actions'
import WeightLossReadInstructions from '@/assets/images/assessment.png'
import WeightLossSyringeImage from '@/assets/images/weight-loss_syringe.png'
import WeightLossScaleImage from '@/assets/images/weight-loss_scale.png'
import WeightLossHandsImage from '@/assets/images/weight-loss_hands.png'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import { procedures } from '../routes'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import messages from '../messages'
import Card from '@/programmes/components/Card'

const cx = classNames.bind(styles)

const CheckInStartupHomePage = () => {
  const t = useFormatMessage()
  const dispatch = useDispatch()
  const announcement = useWeightLossCheckInHomeInfo()
  const weightLossNoProctorEnabled = useWeightLossNoProctorEnabled()
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const proctorInviteUrl = data?.createProctorSessionInvite?.url

  if (proctorInviteUrl) {
    window.location.href = proctorInviteUrl
  }

  const handleNoProctorClick = () =>
    handleClick(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR)
  const handleProctorClick = () =>
    handleClick(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP)

  const handleClick = (procedureName) => {
    if (isAuthenticated) {
      return getProctorSessionInvite({
        variables: {
          input: {
            procedure: {
              identifier: { value: procedureName },
            },
          },
        },
      })
    }

    return dispatch(loginAction())
  }

  return (
    <Template
      title={t(messages.checkInStartupHomePageTitle)}
      hasAppLayout={isAuthenticated}
    >
      <h2>{t(messages.checkInStartupHomePageHeading)}</h2>
      <p>{t(messages.checkInStartupHomePageDescription)}</p>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.checkInStartupHomePageCard1Title)}
          description={t(messages.checkInStartupHomePageCard1Description)}
          image={WeightLossReadInstructions}
          imageDescription={t(
            messages.checkInStartupHomePageCard1ImageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard2Title)}
          description={t(messages.checkInStartupHomePageCard2Description)}
          image={WeightLossSyringeImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard12mageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard3Title)}
          description={t(messages.checkInStartupHomePageCard3Description)}
          image={WeightLossScaleImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard13mageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard4Title)}
          description={t(messages.checkInStartupHomePageCard4Description)}
          image={WeightLossHandsImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard14mageDescription
          )}
        />
      </div>

      {announcement && <Announcement content={announcement} />}

      <Button
        onClick={handleProctorClick}
        loading={loading || !!proctorInviteUrl}
      >
        {t(
          isAuthenticated
            ? messages.checkInStartupHomePageCta2
            : messages.checkInStartupHomePageCta1
        )}
      </Button>

      {isAuthenticated && weightLossNoProctorEnabled && (
        <Button
          tertiary
          onClick={handleNoProctorClick}
          loading={loading || !!proctorInviteUrl}
        >
          {t(messages.checkInStartupHomePageCta3)}
        </Button>
      )}
    </Template>
  )
}

export default CheckInStartupHomePage
