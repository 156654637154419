import React from 'react'
import { injectIntl } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { branch, compose, renderNothing, withProps } from 'recompose'
import qs from 'qs'

import AppsInfo from '@/components/AppsInfo'
import withPageTitle from '@/wrappers/withPageTitle'

import messageMap from './FeatureUnavailable.messages'

const enhance = compose(
  injectIntl,
  withProps(({ history, location: { search } }) => {
    const query = qs.parse(search.slice(1, search.length))
    const messages = messageMap[query.feature]

    // if no recognised feature specified in url query, return home
    return messages ? { messages } : history.replace('/')
  }),
  branch(({ messages }) => !messages, renderNothing),
  withPageTitle(({ intl, messages }) => intl.formatMessage(messages.pageTitle))
)

const ReferToApps = ({ messages, pageTitle }) => {
  const translate = useFormatMessage()

  return (
    <section className="Content--narrow">
      <h1 className="PageTitle">{pageTitle}</h1>
      <AppsInfo
        content={translate(messages.appInstructions)}
        title={translate(messages.appInstructionsTitle)}
      />
    </section>
  )
}

export default enhance(ReferToApps)
