import React, { useState, useEffect } from 'react'

type OnLoadCallback = (src: string) => void

type AssetLoader = () => Promise<string>

type Props = {
  load: AssetLoader
  alt: string
  [prop: string]: any
}

const loadAsset = async (
  load: AssetLoader,
  onLoad: OnLoadCallback
): Promise<void> => {
  const src = await load()
  onLoad(src)
}

const LazyImage = ({ load, alt, ...props }: Props) => {
  const [image, setImage] = useState('')

  useEffect(() => {
    loadAsset(load, (src: string) => {
      setImage(src)
    })
  }, [image]) // eslint-disable-line react-hooks/exhaustive-deps

  return image ? <img src={image} alt={alt} {...props} /> : null
}

export default LazyImage
