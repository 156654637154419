import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import classNames from 'classnames/bind'
import Template from '../Template'
import messages from '../messages'
import styles from '../index.module.scss'

const cx = classNames.bind(styles)

const SetupErrorIneligiblePage = () => {
  const t = useFormatMessage()

  return (
    <Template title={t(messages.setupErrorIneligiblePageHeading)}>
      <h2 className={cx('heading')}>
        {t(messages.setupErrorIneligiblePageHeading)}
      </h2>
      <p className={cx('description')}>
        {t(messages.setupErrorIneligiblePageDescription)}
      </p>
    </Template>
  )
}

export default SetupErrorIneligiblePage
