import {
  UPDATE_FORM_DATA,
  RESET_FORM_DATA,
  SET_FORM_STEP,
  RESET_FORM_STORE,
  ON_ADDRESS_PICKED,
  ON_NEW_ACCOUNT_LOGIN_INITIATED,
} from './constants'

export const updateFormState = (formData) => ({
  type: UPDATE_FORM_DATA,
  formData,
})

export const setFormStep = (step) => ({
  type: SET_FORM_STEP,
  step,
})

export const resetFormData = () => ({
  type: RESET_FORM_DATA,
})

export const resetFormStore = () => ({
  type: RESET_FORM_STORE,
})

export const onAddressPicked = (addressPicked) => ({
  type: ON_ADDRESS_PICKED,
  addressPicked,
})

export const onNewAccountLoginInitiated = (newAccountLoginInitiated) => ({
  type: ON_NEW_ACCOUNT_LOGIN_INITIATED,
  newAccountLoginInitiated,
})
