import { Interstitial, Question } from '../../components/Form/types'

const refillSteps: Array<Question | Interstitial> = [
  {
    id: '1d1e26fe-ca62-11ee-9111-8fe0b16b1cb9',
    type: 'question',
    title: "What's your current weight?",
    inputType: {
      component: 'weight',
    },
    next: '389f4804-ca62-11ee-b2d6-e7efb2aa9ead',
  },
  {
    id: '389f4804-ca62-11ee-b2d6-e7efb2aa9ead',
    type: 'question',
    required: true,
    title: 'Can you confirm the medication and dose you are currently taking?',
    inputType: {
      component: 'radioGroup',
    },
    options: [
      {
        label: 'Wegovy®',
        value: 'Wegovy®',
        followUpQuestion: {
          id: '62addafc-fc7d-4131-9e41-fe83824deb22',
          type: 'question',
          required: true,
          title: 'What dose of Wegovy® are you currently taking?',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            { label: '0.25mg', value: '0.25mg' },
            { label: '0.5mg', value: '0.5mg' },
            { label: '1mg', value: '1mg' },
            { label: '1.7mg', value: '1.7mg' },
            { label: '2.4mg', value: '2.4mg' },
          ],
        },
      },
      {
        label: 'Mounjaro®',
        value: 'Mounjaro®',
        followUpQuestion: {
          id: '282243ad-2314-4dad-bd93-974eb52fab8c',
          type: 'question',
          required: true,
          title: 'What dose of Mounjaro® are you currently taking?',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            { label: '2.5mg', value: '2.5mg' },
            { label: '5mg', value: '5mg' },
            { label: '1mg', value: '1mg' },
            { label: '7.5mg', value: '7.5mg' },
            { label: '10mg', value: '10mg' },
            { label: '12.5mg', value: '12.5mg' },
            { label: '15mg', value: '15mg' },
          ],
        },
      },
    ],
    next: '4d13dfac-ca62-11ee-a0e9-a3a11ad22333',
  },
  {
    id: '4d13dfac-ca62-11ee-a0e9-a3a11ad22333',
    type: 'question',
    title:
      'In the last 4 weeks, have you missed any doses of your weight loss medication, excluding doses taken late?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Yes',
        value: 'yes',
        followUpQuestion: {
          id: '45c331d4-b52b-498e-abe1-138e3cb4fb19',
          required: true,
          type: 'question',
          title: 'How many doses have you missed?',
          inputType: {
            component: 'textArea',
          },
        },
      },
      { label: 'No', value: 'no' },
    ],
    next: '5ef172ac-ca62-11ee-ac2c-b7b47218d852',
  },
  {
    id: '5ef172ac-ca62-11ee-ac2c-b7b47218d852',
    type: 'question',
    title:
      'Previously you have told us your weight management goals. Do you think you are on track to achieve them?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Yes', value: 'yes' },
      {
        label: 'No',
        value: 'no',
        followUpQuestion: {
          id: '9ed7bb4d-9d6d-4413-95d2-6f610eb1bad0',
          type: 'question',
          title:
            'What obstacles or challenges have you faced in reaching your weight management goals?',
          inputType: {
            component: 'textArea',
          },
          required: true,
        },
      },
    ],
    next: '9a3f417c-ca62-11ee-8a8a-63c9a53ed956',
  },
  {
    id: '9a3f417c-ca62-11ee-8a8a-63c9a53ed956',
    type: 'question',
    title: 'Are you currently experiencing any of the following side effects?',
    required: true,
    inputType: {
      component: 'checkboxGroup',
    },
    options: [
      {
        label: 'Nausea',
        value: 'Nausea',
        followUpQuestion: {
          id: '20412af6-0795-4cfb-afb2-80ceddd0b47f',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Vomiting',
        value: 'Vomiting',
        followUpQuestion: {
          id: '00e102f9-9622-4046-a471-5fc9b5c2c4d2',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Diarrhoea',
        value: 'Diarrhoea',
        followUpQuestion: {
          id: '217f2c05-5f20-4f0a-8c23-cb29241c492e',
          type: 'question',
          title:
            'Please rate the severity of any side effects you are experiencing:',
          required: true,
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Constipation',
        value: 'Constipation',
        followUpQuestion: {
          id: '55db3b18-6237-4714-846a-d2deeb31e4f7',
          type: 'question',
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          required: true,
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Abdominal pain',
        value: 'Abdominal pain',
        followUpQuestion: {
          id: 'c036e03c-fc51-4084-9dfe-2e9831cf8b6e',
          type: 'question',
          title:
            'Please rate the severity of any side effects you are experiencing:',
          required: true,
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Headache',
        value: 'Headache',
        followUpQuestion: {
          id: 'e315a11d-1ae4-4f77-bfb4-4a85e60a1d6e',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Dizziness',
        value: 'Dizziness',
        followUpQuestion: {
          id: '3836d485-8482-4a50-bfb7-07f31f49ed32',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Visual disturbances',
        value: 'Visual disturbances',
        followUpQuestion: {
          id: 'cd666e03-c30d-47a6-bf97-7f0ed1c77027',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label:
          'Increased heart rate, feeling your heart is racing at rest or experiencing palpitations ',
        value:
          'Increased heart rate, feeling your heart is racing at rest or experiencing palpitations ',
        followUpQuestion: {
          id: '0a60f3c7-59ec-4105-a226-c4b76e3e2b2e',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Flatulence / Bloatedness',
        value: 'Flatulence / Bloatedness',
        followUpQuestion: {
          id: '38e2a156-2111-46a0-8e46-be7673eda871',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Acid reflux',
        value: 'Acid reflux',
        followUpQuestion: {
          id: '17acfc8e-1e25-4ee4-8159-7178b487d567',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Hair loss',
        value: 'Hair loss',
        followUpQuestion: {
          id: 'fbd2ecd8-42e2-4b20-bd90-ee9bc6988904',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Rash',
        value: 'Rash',
        followUpQuestion: {
          id: '3ffe3dd0-6040-437f-8069-025d1c789cb1',
          type: 'question',
          required: true,
          title:
            'Please rate the severity of any side effects you are experiencing:',
          inputType: {
            component: 'radioGroup',
          },
          options: [
            {
              label: 'Mild side effects (easily manageable)',
              value: 'Mild side effects (easily manageable)',
            },
            {
              label: 'Moderate side effects (somewhat manageable)',
              value: 'Moderate side effects (somewhat manageable)',
            },
            {
              label: 'Severe side effects (challenging to manage)',
              value: 'Severe side effects (challenging to manage)',
            },
            {
              label: 'Extremely severe side effects (unmanageable)',
              value: 'Extremely severe side effects (unmanageable)',
            },
          ],
        },
      },
      {
        label: 'Other',
        value: 'Other',
        followUpQuestion: {
          id: '74d85dcc-44cb-4028-b236-d80da852153d',
          type: 'question',
          required: true,
          title: 'Please give more details',
          inputType: {
            component: 'textArea',
          },
        },
      },
      {
        label: 'Thoughts of suicide or harming yourself',
        value: 'Thoughts of suicide or harming yourself',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
        exclusive: true,
      },
    ],
    optionsRules: [
      {
        label: 'Thoughts of suicide or harming yourself',
        nextAction: 'c017e7dc-ca62-11ee-83ec-3f22d29fd631',
      },
      {
        label: 'None of the above',
        nextAction: 'db2b8d3a-ca62-11ee-84e2-0783964f2bf1',
      },
    ],
    next: '41620540-48d3-11ef-aea1-bf1323696937',
  },
  {
    id: 'c017e7dc-ca62-11ee-83ec-3f22d29fd631',
    type: 'interstitial',
    body: [
      "I'm really sorry to hear that you're feeling this way. It's important that you get the support you require as soon as possible. If you feel like you are in immediate danger of suicide please telephone 999. If you have been having thoughts of suicide or considering harming yourself one of our mental health practitioners will be able to offer further support.",
      'Please be aware that there are also many services available for you to access: ',
      'Samaritans: Helpline: (116 123 (free of charge from a landline or mobile) Email jo@samaritans.org) 24 hr helpline offering emotional support for people who are experiencing feelings of distress or despair, including those which may lead to suicide.',
      'The National Suicide Prevention Helpline : 0800 689 5652 - Offers supportive listening services to anyone throughout the UK with thoughts of suicide or thoughts of self-harm. They are open from 6:00pm to 3:30am for those aged 18 or over. Website: www.spuk.org.uk',
      'SupportLine Telephone Helpline: 01708 765200, - Provides emotional support and details of support groups, helplines, agencies and counsellors throughout the UK.',
      'Calm: (0800 585858) Campaign Against Living Miserably Help and support for young men aged 15-35 on issues which include depression and suicide.',
      'HopeLine UK: (0800 068 4141) for practical advice on suicide prevention.',
    ],
    next: '41620540-48d3-11ef-aea1-bf1323696937',
  },
  {
    id: '41620540-48d3-11ef-aea1-bf1323696937',
    type: 'question',
    title:
      'Do you feel you need a clinical review with one of our weight management clinicians before we continue your programme?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    next: 'db2b8d3a-ca62-11ee-84e2-0783964f2bf1',
  },
  {
    id: 'db2b8d3a-ca62-11ee-84e2-0783964f2bf1',
    type: 'question',
    title: 'Have you been rotating injection sites?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    next: 'f37d1cb4-ca62-11ee-8c5b-fb05ce35f45b',
  },
  {
    id: 'f37d1cb4-ca62-11ee-8c5b-fb05ce35f45b',
    type: 'question',
    title:
      'Are you experiencing any hard lumps, pain, itching, swelling or redness at your injection site that has not gone away after 1-2 days?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    next: 'b0f640b8-ca63-11ee-a770-c325b3ced225',
  },
  {
    id: 'b0f640b8-ca63-11ee-a770-c325b3ced225',
    type: 'question',
    title:
      'Have you been initiated on any new medications (prescribed or purchased over the counter) or supplements since your last prescription?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Yes',
        value: 'yes',
        followUpQuestion: {
          id: '10632854-ca64-11ee-935b-778cd1017bcd',
          type: 'question',
          inputType: {
            component: 'textArea',
          },
          required: true,
          title:
            'Please tell us the name, dose and frequency of your medications or supplements. Please give as much detail as you can, remembering to include both prescription and over the counter products.',
        },
      },
      { label: 'No', value: 'no' },
    ],
    next: 'c0dc69b2-ca63-11ee-abc5-877cd677ba5d',
  },
  {
    id: 'c0dc69b2-ca63-11ee-abc5-877cd677ba5d',
    type: 'question',
    title: 'Any new allergies?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Yes',
        value: 'yes',
        followUpQuestion: {
          id: '58b3d941-a562-4ca5-bfbd-8d1ce27f6879',
          type: 'question',
          title:
            'What are you allergic to? Please give as much detail as you can regarding your allergy',
          inputType: {
            component: 'textArea',
          },
          required: true,
        },
      },
      { label: 'No', value: 'no' },
    ],
    next: 'dd4e53c6-ca63-11ee-83f2-2743ce71ebdd',
  },
  {
    id: 'dd4e53c6-ca63-11ee-83f2-2743ce71ebdd',
    type: 'question',
    title:
      'Has anything changed in your medical history, have you had any new diagnosis or are you currently under investigation for any conditions since your last prescription?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      {
        label: 'Yes',
        value: 'yes',
        followUpQuestion: {
          id: '39d0f237-19a4-4fb0-a774-d98ef0503812',
          type: 'question',
          title: 'Please provide details of any changes?',
          inputType: {
            component: 'textArea',
          },
          required: true,
        },
      },
      { label: 'No', value: 'no' },
    ],
    next: 'f04b5fe0-48d6-11ef-a319-6f2bbbff43c7',
  },
  {
    id: 'f04b5fe0-48d6-11ef-a319-6f2bbbff43c7',
    type: 'question',
    title: 'How many doses have you taken from your current injection pen?',
    body:
      'Please note that your prescription may be rejected if you have more than two doses remaining',
    inputType: {
      component: 'textArea',
    },
    required: true,
    next: '3158bfd2-48d7-11ef-ad64-ef18bf34a60d',
  },
  {
    id: '3158bfd2-48d7-11ef-ad64-ef18bf34a60d',
    type: 'question',
    title: 'Is there anything else you would like to tell us?',
    inputType: {
      component: 'textArea',
    },
    required: false,
    next: 'e8599898-ca63-11ee-a968-cbfe0cb23c44',
  },
  {
    id: 'e8599898-ca63-11ee-a968-cbfe0cb23c44',
    required: true,
    type: 'question',
    title:
      'I confirm that all information given is accurate and to the best of my knowledge',
    inputType: {
      component: 'checkBox',
    },
    next: 'fdaa9f3a-ca63-11ee-8d71-875f80ef979b',
  },
  {
    id: 'fdaa9f3a-ca63-11ee-8d71-875f80ef979b',
    type: 'question',
    title: 'Just one more thing! Do you need a new sharps bin?',
    inputType: {
      component: 'radioGroup',
    },
    required: true,
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    next: 'complete-action',
  },
]

export default refillSteps
