/**
 * TODO: introduce a queue of alerts so this component can deal with multiple messages
 *
 * This component is being stuck to the top of the viewport via its styling,
 * which means it can only be rendered in a single instance in an application.
 *
 * This is very limiting and also could result in users unable to read a message
 * when a new message is being shown immediately after another one i.e. when props change.
 */

import React from 'react'
import styles from './index.module.scss'

interface Props {
  children: React.ReactChild
}

const FlashMessage = ({ children }: Props) => (
  <div className={styles.flashMessage}>{children}</div>
)

export default FlashMessage
