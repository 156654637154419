import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import Template from '../Template'
import eligibilitySteps from './eligibilitySteps'
import Form from '@/programmes/components/FormV2'
import messages from '../messages'
import useIsOutOfTheAgeRangeCheck from '@/programmes/WeightLoss/hooks/useIsOutOfTheAgeRangeCheck'
import UTI_TEST from '@/programmes/UrinaryTractInfection/config'

const SetupEligibilityQuestionnaire = () => {
  const t = useFormatMessage()
  const history = useHistory()
  const {
    loading: outOfTheAgeRangeCheckLoading,
    outOfTheAgeRange,
  } = useIsOutOfTheAgeRangeCheck()

  return (
    <Template
      title={t(messages.setupEligibilityQuestionnairePageTitle)}
      loading={outOfTheAgeRangeCheckLoading}
    >
      {!outOfTheAgeRange && !outOfTheAgeRangeCheckLoading && (
        <Form
          steps={eligibilitySteps}
          onSubmit={() => history.push(UTI_TEST.routes.activate.path)}
          onExit={() => history.push(UTI_TEST.routes.ineligible.path)}
        />
      )}

      {outOfTheAgeRange &&
        !outOfTheAgeRangeCheckLoading &&
        history.push(UTI_TEST.routes.ineligible.path)}
    </Template>
  )
}

export default SetupEligibilityQuestionnaire
