import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import styles from './Text.module.scss'

const Text = ({ children, type, ...props }) =>
  React.createElement(
    type,
    {
      ...props,
      type,
      className: classNames(styles.text, props.className),
    },
    children
  )

Text.propTypes = {
  /**
   * Children, typically a string but left as a node so you can include icons ect.
   */
  children: PropTypes.node,
  /**
   * Optional additional classnames
   */
  className: PropTypes.string,
  /**
   * The element or component being used to style
   */
  type: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

Text.defaultProps = {
  children: '',
  className: '',
  type: 'span',
}

export default Text
