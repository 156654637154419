import React from 'react'
import { Text } from '@babylon/web-platform-ui'
import styles from './Badge.module.scss'

const Badge = ({
  children,
  visible = true,
}: {
  children: string
  visible?: boolean
}) => (
  <span className={`${styles.badge} ${!visible ? styles.hiddenBadge : ''}`}>
    <Text as="span">{children}</Text>
  </span>
)
export default Badge
