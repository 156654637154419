import { always, find, ifElse, path, pipe, prop, propEq } from 'ramda'
import {
  account,
  appointments,
  bookConsultation,
  clinicalRecords,
  partnerClinicalRecords,
} from './config'

const getRouteById = (sectionId, childId) =>
  ifElse(
    always(childId),
    // if a childId was provided, find it within the section's children list
    pipe(path([sectionId, 'children']), find(propEq('id', childId))),
    // if no childId was provided, just return the top level section
    prop(sectionId)
  )({
    account,
    appointments: appointments(),
    bookConsultation,
    clinicalRecords,
    partnerClinicalRecords,
  })

export { getRouteById }
