import React, { FC, FormEvent } from 'react'
import {
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikHelpers,
  FormikProps,
  FormikConfig,
  FormikState,
} from 'formik'
import FormikEffect from './FormikEffect'
import { ButtonSimple } from '../../Button'
import styles from './index.module.scss'

// TODO: This is moved from consumer-api to Medkit to provide non-breaking change.
// The prop `genericFormErrorMessage` should be used to display translated
// generic form error message
const genericFormErrorMessageFallback = 'Please check all fields are correct'

const isFunction = (obj: any): obj is Function => typeof obj === 'function'

export const Form: FC<Props> = ({
  initialValues,
  onSubmit,
  submitButtonText,
  submitButtonLoadingText,
  loading,
  children,
  onChange,
  onValuesChange,
  className,
  validationSchema,
  genericFormErrorMessage = genericFormErrorMessageFallback,
  'data-testid': dataTestId,
  alwaysShowGenericError,
  validateOnBlur,
  validateOnChange,
}: Props) => {
  const submitHandler = (
    values: any,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setStatus, setFormikState } = formikHelpers

    setStatus({})

    return onSubmit(values, formikHelpers).catch((err: any) => {
      if (Array.isArray(err.graphQLErrors) && err.graphQLErrors[1]) {
        const { parsedError } = err.graphQLErrors[1]

        setFormikState((prevState: FormikState<FormikValues>) => ({
          ...prevState,
          submitCount: 0,
        }))

        const baseErrors = [
          ...parsedError.baseErrors,
          ...(parsedError.errors?.base || []),
        ]

        if (parsedError.genericFormError || alwaysShowGenericError) {
          baseErrors.push(genericFormErrorMessage)
        }

        setStatus({
          ...parsedError.errors,
          baseErrors: [...new Set(baseErrors)],
        })
      } else if (err instanceof Error) {
        setStatus({
          baseErrors: [err.message],
        })
      }
    })
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitHandler}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <FormikForm
          className={className}
          noValidate
          data-testid={dataTestId}
          onChange={
            onChange
              ? (e: FormEvent<any>) => {
                  const target = e.target as HTMLInputElement
                  const { setStatus, status = {}, values } = formikProps
                  const newValues = {
                    ...values,
                    [target.name]: target.value,
                  }

                  // Clear the status of the changed field and base errors
                  setStatus({
                    ...status,
                    [target.name]: [],
                    baseErrors: [],
                  })

                  onChange(e, { ...formikProps, values: newValues })
                }
              : undefined
          }
        >
          {onValuesChange && (
            <FormikEffect
              onChange={(
                previousValues?: FormikValues,
                values?: FormikValues,
                changedKeys?: string[]
              ) => {
                onValuesChange(previousValues, values, changedKeys)
              }}
            />
          )}
          {isFunction(children) ? children(formikProps) : children}

          {formikProps.status?.baseErrors?.map((err: string) => (
            <p className={styles.error} key={err} data-testid="form-error">
              {err}
            </p>
          ))}

          <ButtonSimple
            type="submit"
            fullWidth
            isLoading={formikProps.isSubmitting || loading}
            loadingLabel={submitButtonLoadingText}
            data-testid="form-submit-button"
          >
            {submitButtonText}
          </ButtonSimple>
        </FormikForm>
      )}
    </Formik>
  )
}

interface Props extends FormikConfig<FormikValues> {
  onChange?: (e: FormEvent<any>, formikProps: FormikProps<FormikValues>) => void
  /**
   * onSubmit must return a promise that throws an error on an invalid state
   * to allow to form to handle errors
   */
  onSubmit: (
    payload: any,
    formikHelpers: FormikHelpers<FormikValues>
  ) => Promise<any>
  onValuesChange?: (
    previousValues?: FormikValues,
    values?: FormikValues,
    changedKeys?: string[]
  ) => void
  submitButtonText: string
  submitButtonLoadingText?: string
  genericFormErrorMessage?: string
  className?: string
  loading?: boolean
  'data-testid'?: string
  // shows generic error by submit button if BE errors
  alwaysShowGenericError?: boolean
  validateOnBlur?: boolean
  validateOnChange?: boolean
}

Form.defaultProps = {}

export default Form
