import React from 'react'
import { compose, withHandlers, withProps, withState } from 'recompose'
import { graphql } from '@apollo/react-hoc'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'qs'

import NoticeModal from '@/components/Modal/NoticeModal'
import PrivacyPolicyDecision, { updateCache } from '../mutations'
import { displayFlashMessage } from '@/redux/flash/actions'
import withUserId from '@/wrappers/withUserId'
import withPrivacyNotices, {
  loginNotices as userNoticesQuery,
} from '@/wrappers/WithPrivacyNotices'
import { logout } from '../../Authentication/actions'

const enhance = compose(
  injectIntl,
  withUserId,
  withPrivacyNotices('login'),
  graphql(PrivacyPolicyDecision, {
    name: 'decideOnPolicy',
  }),
  withState('declinedOnce', 'onUserFirstDecline', false),
  withProps(({ privacyNotices = [] }) => {
    // try to find the terms & conditions first (which user can decline)
    const notice =
      privacyNotices.find((n) => n.declineButtonText) || privacyNotices[0]

    // RegisterPage adds a param to the url on successful registration, we check for
    // this in order to display the correct title for the user during the register flow.
    // See https://babylonpartners.atlassian.net/browse/CW-1106 for details
    const query = qs.parse(window.location.search.substring(1))

    const isRegisterFlow = !!query.register

    return { notice, isRegisterFlow }
  }),
  connect(
    () => ({}),
    (dispatch) => bindActionCreators({ displayFlashMessage, logout }, dispatch)
  ),
  withHandlers({
    acceptPolicy: (props) => () => {
      const { decideOnPolicy, notice, userId } = props

      return decideOnPolicy({
        variables: {
          noticeVersionId: notice.noticeVersionId,
          decision: true,
        },
        update: updateCache({
          noticeVersionId: notice.noticeVersionId,
          userId,
          query: userNoticesQuery,
          propertyName: 'privacy_notices',
        }),
      })
    },
    declinePolicy: ({
      decideOnPolicy,
      notice,
      declinedOnce,
      onUserFirstDecline,
      displayFlashMessage,
      logout,
    }) => () => {
      if (declinedOnce) {
        return decideOnPolicy({
          variables: {
            noticeVersionId: notice.noticeVersionId,
            decision: false,
          },
        }).then(() => logout())
      }

      displayFlashMessage(notice.errorText, 'info')
      onUserFirstDecline(true)

      return undefined
    },
  })
)

const PrivacyNotice = ({
  privacyAgreementNeeded,
  acceptPolicy,
  declinePolicy,
  notice,
  isRegisterFlow,
}) =>
  privacyAgreementNeeded && (
    <NoticeModal
      notice={notice}
      onAccept={acceptPolicy}
      onDecline={declinePolicy}
      newUser={isRegisterFlow}
    />
  )

export default enhance(PrivacyNotice)
