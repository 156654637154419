import React from 'react'
import { Button, Text, ButtonVariant } from '@babylon/medkit'
import { SearchError } from '@babylon/icons'
import { FormattedMessage } from 'react-intl'
import styles from './Modal.module.scss'
import messages from './Modal.messages'

interface Props {
  onLookup: () => void
  onAddManualAddress: (searchString: string) => void
  searchString: string
}

const ErrorState: React.FC<Props> = ({
  onLookup,
  onAddManualAddress,
  searchString,
}) => (
  <div className={styles.addressPicker__modal__statesBody}>
    <SearchError />
    <Text variant="body">
      <FormattedMessage {...messages.modalErrorPostcodeNotFound} />
    </Text>
    <div className={styles.addressPicker__modal__btnWrapper}>
      <Button type="submit" fullWidth onClick={onLookup}>
        Retry
      </Button>
      <Button
        variant={ButtonVariant.secondary}
        fullWidth
        onClick={() => onAddManualAddress(searchString)}
      >
        <FormattedMessage {...messages.modalCtaAddManually} />
      </Button>
    </div>
  </div>
)

export default ErrorState
