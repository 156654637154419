import React from 'react'
import cn from 'classnames'
import styles from './Checkbox.module.scss'

interface CheckboxProps {
  id: string
  label: string
  value?: string
  error?: boolean
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  value,
  error = false,
  checked = false,
  onChange,
}) => {
  const checkboxRef = React.useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    // forward the click to the checkbox input
    checkboxRef.current?.click()
  }

  return (
    <button
      className={cn(
        styles.containerV1,
        checked && styles.checkedV1,
        error && styles.errorV1
      )}
      role="checkbox"
      aria-checked={checked}
      onClick={handleClick}
    >
      <input
        ref={checkboxRef}
        type="checkbox"
        id={`${label}-${id}`}
        data-testid={`checkbox-${label}`}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkmarkV1}> </span>
      <span className={styles.titleV1}>{label}</span>
    </button>
  )
}

export default Checkbox
