import { MEASUREMENT_UNIT, Height, Weight } from '../components/Form/types'

export const convertImperialHeightToMetric = (
  heightFeet: number,
  heightInches: number
): number => (heightFeet * 12 + heightInches) * 2.54

export const convertImperialWeightToMetric = (
  weightStones: number,
  weightPounds: number
): number => (weightStones * 14 + weightPounds) * 0.453592

export const getHeightAndWeightAsMetric = (
  height?: Height,
  weight?: Weight
): { height: string | undefined; weight: string | undefined } => {
  let metricHeight = height?.cm
  let metricWeight = weight?.kg

  if (height && height.unit === MEASUREMENT_UNIT.IMPERIAL) {
    metricHeight = convertImperialHeightToMetric(
      parseInt(height.feet) || 0,
      parseInt(height.inches) || 0
    ).toFixed(0)
  }

  if (weight && weight.unit === MEASUREMENT_UNIT.IMPERIAL) {
    metricWeight = convertImperialWeightToMetric(
      parseInt(weight.stones) || 0,
      parseInt(weight.pounds) || 0
    ).toFixed(2)
  }

  return { height: metricHeight, weight: metricWeight }
}

export const convertMetricWeightToImperial = (weight: number): Weight => {
  const totalPounds = weight * 2.20462
  const stones = Math.floor(totalPounds / 14)
  const pounds = Math.round(totalPounds % 14)

  return {
    unit: MEASUREMENT_UNIT.IMPERIAL,
    kg: '',
    stones: stones.toString(),
    pounds: pounds.toString(),
  }
}

export const convertMetricWeightToPounds = (weight: number): number => {
  const weightInImperial = convertMetricWeightToImperial(weight)

  return Number(weightInImperial.stones) * 14 + Number(weightInImperial.pounds)
}

export const weightInMetric = (weight: Weight): number => {
  if (weight.unit === MEASUREMENT_UNIT.IMPERIAL) {
    return convertImperialWeightToMetric(
      Number(weight.stones),
      Number(weight.pounds)
    )
  }

  return Number(weight.kg)
}

export default getHeightAndWeightAsMetric
