const rPassword = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

interface PasswordValidation {
  requiredMessage: string
  lengthMessage: string
  formatMessage: string
  length: number
}

export default ({
  requiredMessage,
  lengthMessage,
  formatMessage,
  length,
}: PasswordValidation) => (value: string) => {
  if (!value) {
    return requiredMessage
  }

  if (value.length < length) {
    return lengthMessage
  }

  if (!rPassword.test(value)) {
    return formatMessage
  }

  return undefined
}
