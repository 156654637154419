/* eslint-disable react/prop-types */
import React from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link, LinkButton } from '@babylon/medkit'
import withFeatureSwitches from '@/wrappers/withFeatureSwitches'
import ReferAFriendModal from '../Modal/ReferAFriendModal'
import BabylonBranding from './BabylonBranding'
import PartnerBranding from './PartnerBranding'

import { showReferFriend, hideReferFriend } from '@/redux/referFriend/actions'

import messages from './Footer.messages'
import styles from './Footer.module.scss'
import {
  useMarketingEnabled,
  useFeedbackEnabled,
  useReferAFriendEnabled,
  usePrivacyPolicyEnabled,
  useRegulatoryEnabled,
  useFooterCookiesEnabled,
  useTermsAndConditionsEnabled,
  usePartnerLogo,
  useCookiePolicyLink,
  usePoweredByEnabled,
  useRegulatoryPolicyLink,
} from '@/redux/selectors'

const enhance = compose(
  injectIntl,
  withFeatureSwitches,
  connect(
    ({ referFriend }) => ({ referFriend }),
    (dispatch) =>
      bindActionCreators(
        {
          showReferFriend: () =>
            showReferFriend({ situation: 'raf_from_footer' }),
          hideReferFriend,
        },
        dispatch
      )
  )
)

const Footer = ({
  intl,
  featureSwitches: { refer_a_friend } = {},
  referFriend,
  showReferFriend,
  hideReferFriend,
}) => {
  const marketingEnabled = useMarketingEnabled()
  const feedbackEnabled = useFeedbackEnabled()
  const referAFriendEnabled = useReferAFriendEnabled()
  const privacyPolicyEnabled = usePrivacyPolicyEnabled()
  const termsAndConditionsEnabled = useTermsAndConditionsEnabled()
  const footerCookiesEnabled = useFooterCookiesEnabled()
  const partnerLogo = usePartnerLogo()
  const cookiePolicyLink = useCookiePolicyLink()
  const poweredByEnabled = usePoweredByEnabled()
  const regulatoryPolicyLink = useRegulatoryPolicyLink()
  const regulatoryEnabled = useRegulatoryEnabled() && regulatoryPolicyLink

  return (
    <footer className={styles.footer}>
      {partnerLogo && <PartnerBranding logo={partnerLogo} />}

      <nav
        className={styles.footerNavigation}
        role="navigation"
        aria-label="Footer"
      >
        {poweredByEnabled && (
          <span className={styles.footerNavigationItem}>
            <BabylonBranding />
          </span>
        )}

        {marketingEnabled && (
          <Link
            title={intl.formatMessage(messages.babylonWebsite)}
            className={styles.footerNavigationItem}
            href="https://www.emed.com/uk"
            target="_blank"
            rel="noopener"
          >
            {intl.formatMessage(messages.babylonWebsite)}
          </Link>
        )}

        {referAFriendEnabled && refer_a_friend && (
          <LinkButton
            onClick={showReferFriend}
            className={styles.footerNavigationItem}
            aria-label={intl.formatMessage(messages.refer)}
          >
            {intl.formatMessage(messages.refer)}
          </LinkButton>
        )}

        {privacyPolicyEnabled && (
          <Link
            className={styles.footerNavigationItem}
            to="/privacy"
            title={intl.formatMessage(messages.privacyPolicy)}
          >
            {intl.formatMessage(messages.privacyPolicy)}
          </Link>
        )}

        {termsAndConditionsEnabled && (
          <Link
            className={styles.footerNavigationItem}
            to="/terms"
            title={intl.formatMessage(messages.termsAndConditions)}
          >
            {intl.formatMessage(messages.termsAndConditions)}
          </Link>
        )}

        {footerCookiesEnabled && (
          <Link
            className={styles.footerNavigationItem}
            href={cookiePolicyLink}
            target="_blank"
            rel="noopener"
            title={intl.formatMessage(messages.cookies)}
          >
            {intl.formatMessage(messages.cookies)}
          </Link>
        )}

        {regulatoryEnabled && (
          <Link
            className={styles.footerNavigationItem}
            href={regulatoryPolicyLink}
            target="_blank"
            rel="noopener"
            title={intl.formatMessage(messages.regulatory)}
          >
            {intl.formatMessage(messages.regulatory)}
          </Link>
        )}

        {feedbackEnabled && (
          <Link
            className={styles.footerNavigationItem}
            to="/feedback"
            title={intl.formatMessage(messages.giveFeedback)}
          >
            {intl.formatMessage(messages.giveFeedback)}
          </Link>
        )}
      </nav>
      <p className={styles.copyright}>
        &copy; eMed {moment().format('YYYY')}.{' '}
        {intl.formatMessage(messages.allRightsReserved)}
      </p>
      {referFriend.show && (
        <ReferAFriendModal
          situation={referFriend.situation}
          waitUntilReady={referFriend.wait}
          onClose={hideReferFriend}
        />
      )}
    </footer>
  )
}

export default enhance(Footer)
