import { SHOW_REFER_FRIEND, HIDE_REFER_FRIEND } from './constants'

export const showReferFriend = ({
  situation,
  wait = false,
  userProvidedDetails,
}: ReferFriend) => ({
  type: SHOW_REFER_FRIEND,
  situation,
  wait,
  userProvidedDetails,
})

export const hideReferFriend = () => ({
  type: HIDE_REFER_FRIEND,
})

interface ReferFriend {
  situation: any
  wait?: boolean
  userProvidedDetails?: any
}
