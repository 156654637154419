// This is used for altering app config in cypress tests
import { UPDATE_PRODUCT_CONFIG, RESET_PRODUCT_CONFIG } from './constants'

const initialState = {
  bookingFlowNvlEnabled: true,
  remoteConfigLoaded: false,
  ...window.initialState,
}

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case RESET_PRODUCT_CONFIG:
      return {
        ...initialState,
      }
    case UPDATE_PRODUCT_CONFIG:
      return {
        ...state,
        ...payload,
        ...{ remoteConfigLoaded: true },
      }
    default:
      return state
  }
}
