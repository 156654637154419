import { useCallback } from 'react'
import gql from 'graphql-tag'
import { useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'

export const consumerNetworksQuery = gql`
  query Patient {
    patient {
      preferred_consumer_network_uuid
      consumer_networks {
        name
        uuid
      }
    }
  }
`
const useGetPreferredConsumerNetwork = () => {
  const [getConsumerNetworks, { data, loading }] = useLazyQuery(
    consumerNetworksQuery
  )
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const getPreferredConsumerNetwork = useCallback(() => {
    if (!isAuthenticated) {
      return undefined
    }

    getConsumerNetworks()
    const preferredConsumerNetwork = data?.patient?.consumer_networks.find(
      (cn) => cn.uuid === data.patient.preferred_consumer_network_uuid
    )

    return preferredConsumerNetwork
  }, [isAuthenticated, data, getConsumerNetworks])

  return { getPreferredConsumerNetwork, loading }
}

export default useGetPreferredConsumerNetwork
