import gql from 'graphql-tag'

export default gql`
  query ClinicalUserInformation($id: Int!) {
    clinicalUserInformation(id: $id) {
      id
      avatar_full_url
      date_of_birth
      email
      first_name
      last_name
      gender
      phone_country_code
      phone_number
      weight
      height
      smoking_status
      address_post_code
      address_first_line
      address_second_line
      address_third_line
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
      gp_full_address
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
      region {
        id
      }
    }
  }
`
