import { useEffect, useState } from 'react'
import { getAuthToken } from '@babylon/auth0'
import WebViewMode from '@/utils/WebViewMode'

// NOTE: this hook could be moved to a lib if we'd like to make wider use of it
const useAuthenticatedImage = (maybeSrc: string | null) => {
  const [base64Image, setBase64Image] = useState<string | null>(null)

  useEffect(() => {
    const setup = async (src) => {
      let bearerToken
      const webViewToken = WebViewMode.getToken()

      if (webViewToken) {
        bearerToken = webViewToken
      } else {
        bearerToken = await getAuthToken()
      }

      const res = await fetch(src, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      })

      if (!res.ok) {
        setBase64Image(null)
        return
      }

      const blob = await res.blob()
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setBase64Image(reader.result)
        }
      }
      reader.readAsDataURL(blob)
    }

    if (maybeSrc && !maybeSrc.includes('base64')) {
      setup(maybeSrc)
    } else {
      setBase64Image(maybeSrc)
    }
  }, [maybeSrc, setBase64Image])

  return base64Image
}

export default useAuthenticatedImage
