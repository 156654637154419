import gql from 'graphql-tag'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'
import { ROOT_PATH as weightLossRootPath } from '@/programmes/WeightLoss/routes'

export type CarePlan = {
  id: string
  title: string
  created: string
}

export const LATEST_CARE_PLAN_QUERY = gql`
  query latestCarePlan($patientId: ID!, $categoryFilter: [CodeFilterOption!]!) {
    patient(id: $patientId) {
      ... on Patient {
        id
        carePlans(
          first: 1
          _sort: [{ order: DESC, field: CREATED }]
          _filter: { category: { ONE_OF: $categoryFilter } }
        ) {
          nodes {
            id
            title
            created
          }
        }
      }
    }
  }
`

export enum CarePlanCategory {
  WeightLoss = 'weight-loss-management-care-plan',
  WellnessUK = 'wellness-care-plan-UK',
  WellnessUS = 'wellness-care-plan-US',
}

const useGetCategoryFilter = (pathName: string) =>
  pathName.includes(weightLossRootPath)
    ? [{ code: CarePlanCategory.WeightLoss }]
    : [
        { code: CarePlanCategory.WellnessUK },
        { code: CarePlanCategory.WellnessUS },
      ]

const useGetCarePlan = () => {
  const { pathname } = useLocation()
  const userUUID = useSelector((state: any) => state.auth?.user?.uuid)
  const { data, loading, error } = useQuery(LATEST_CARE_PLAN_QUERY, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      patientId: userUUID,
      categoryFilter: useGetCategoryFilter(pathname),
    },
  })

  return {
    data: data?.patient?.carePlans?.nodes?.[0] as CarePlan,
    loading,
    error,
  }
}

export default useGetCarePlan
