import React from 'react'
import { useSelector } from 'react-redux'
import { useFormatMessage } from '@babylon/intl'
import Template from '../Template'
import messages from '../messages'
import {
  ProgrammeCheckInConfig,
  ProgrammeCheckInStepLayoutType,
} from '@/programmes/ProgrammeCheckIn/types'
import ProgrammeCheckIn from '@/programmes/ProgrammeCheckIn'
import { routes } from '@/programmes/Wellness/routes'

const checkInConfig: ProgrammeCheckInConfig = {
  steps: [
    {
      title: messages.checkInAutomatedTitle,
      subtitle: messages.checkInAutomatedSubtitle,
      layoutType: ProgrammeCheckInStepLayoutType.WEIGHT,
    },
  ],
  exitRoute: routes.dashboard,
}

const CheckInAutomatedPage = () => {
  const t = useFormatMessage()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)

  return (
    <Template
      title={t(messages.checkInPageTitle)}
      hasAppLayout={isAuthenticated}
    >
      <ProgrammeCheckIn config={checkInConfig} />
    </Template>
  )
}

export default CheckInAutomatedPage
