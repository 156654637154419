import { find, propEq } from 'ramda'

const ROOT_PATH = '/repeat-prescriptions'

// e.g. 123e4567-e89b-12d3-a456-426655440000
const UUID_PATTERN =
  '[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}'

const routeConfig = [
  {
    id: 'listing',
    route: `${ROOT_PATH}/:userId([0-9]+)?`,
    getUrl: ({ pageUserId = '' } = {}) => `${ROOT_PATH}/${pageUserId}`,
  },
  {
    id: 'detail',
    route: `${ROOT_PATH}/:prescriptionId(${UUID_PATTERN})`,
    getUrl: ({ prescriptionId }) => `${ROOT_PATH}/${prescriptionId}`,
  },
]

const getRouteById = (childId) => find(propEq('id', childId), routeConfig)

const getRouteUrl = (childId, params) => getRouteById(childId).getUrl(params)

export {
  // exported for testing:
  getRouteById,
  // exported for consumption:
  ROOT_PATH,
  getRouteUrl,
  routeConfig,
}
