import { DataPoints, DataPointsValue } from '../types'

const getDataPoints = (
  dataPoints: DataPoints[] | undefined,
  answerValue: string | number,
  unit?: string
): DataPointsValue[] => {
  if (dataPoints && dataPoints.length > 0) {
    return dataPoints.map((dataPoint) => {
      if (dataPoint.type === 'predefined') {
        return {
          name: dataPoint.name,
          value: dataPoint.value,
        } as DataPointsValue
      } else {
        //input data point, requires information from answer
        return {
          name: dataPoint.name,
          value: answerValue,
          ...(unit && { unit: unit }),
        } as DataPointsValue
      }
    }) as DataPointsValue[]
  }

  return []
}

export default getDataPoints
