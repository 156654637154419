import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import TextInput from '../TextInput'
import messages from './messages'
import styles from './PulseInput.module.scss'

interface Props {
  label: string
  error?: boolean
  required?: boolean
  onChange: (pulse: string) => void
  value: string
}

const PulseBody = () => {
  const formatMessage = useFormatMessage()

  return (
    <>
      <span>{formatMessage(messages.linkMessage)} </span>
      <a
        href="https://www.nhs.uk/common-health-questions/accidents-first-aid-and-treatments/how-do-i-check-my-pulse/"
        target="_blank"
        rel="noreferrer"
      >
        {formatMessage(messages.nhsHowMessage)}
      </a>
    </>
  )
}
const PulseInput: React.FC<Props> = ({
  label,
  value,
  error = false,
  required = false,
  onChange,
}) => {
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.containerV1}>
      <div>
        <div className={styles.titleV1}>{label}</div>
        <PulseBody />
      </div>

      <div className={styles.textInputV1}>
        <TextInput
          label={formatMessage(messages.bpmLabel)}
          error={error}
          required={required}
          type="number"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </div>
  )
}
export default PulseInput
