import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import { Spacer } from '@babylon/ui'
import SheMedImage from '@/assets/images/shemed-app.png'
import { useWeightLossSetupHomeInfo } from '@/redux/selectors'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import messages from '../messages'
import { onboardingSteps } from './steps'

const cx = classNames.bind(styles)

const SetupSunsetPage = () => {
  const t = useFormatMessage()
  const announcement = useWeightLossSetupHomeInfo()
  const userId = useSelector((state: any) => state.auth?.user?.id)
  const isAuthenticated = userId !== undefined && userId !== null

  return (
    <Template
      title={t(messages.setupSunsetPageTitle)}
      hasAppLayout={isAuthenticated}
      steps={onboardingSteps}
    >
      <Spacer bottom={2}>
        <h2 className={cx('heading')}>{t(messages.setupSunsetPageTitle)}</h2>
      </Spacer>
      <p className={cx('description')}>
        {t(messages.setupSunsetPageDescription)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupSunsetPageDescription2)}
      </p>
      <p className={cx('description')}>
        {t(messages.setupSunsetPageDescription3)}
      </p>
      <Spacer top={2} bottom={2}>
        {announcement && <Announcement content={announcement} />}
        <div className={cx('cta cta-shemed')}>
          <a href="https://www.shemed.co.uk/?utm_source=emed-questionnaire">
            <Button shemed>{t(messages.setupSunsetPageCta1)}</Button>
          </a>
        </div>
      </Spacer>
      <img
        className={cx('home-image')}
        src={SheMedImage}
        alt={t(messages.setupHomePageImage)}
      />
    </Template>
  )
}

export default SetupSunsetPage
