import { useContext, useCallback } from 'react'
import { useLocation } from 'react-router'
import { useBabylonUser } from '@babylon/babylon-user'
import snakeCase from 'lodash/snakeCase'
import { TrackingContext } from '../contexts/trackingContext'
import { TrackingService, TrackProps, TrackingEventProps } from '../types'

enum TRACKING_EVENT_TYPE {
  event,
  view,
}

function snakeCasePayload(payload: TrackProps) {
  let result = {}

  for (const [key, value] of Object.entries(payload)) {
    const snakedKey = snakeCase(key)
    result[snakedKey] = value
  }

  return result
}

/**
 * Hook interfaces with the TealiumService and exposes wrapped versions of the trackEvent and trackView methods
 */
export function useTracking() {
  const user = useBabylonUser()
  const service = useContext(TrackingContext) as TrackingService
  const { pathname } = useLocation()

  const trackFn = useCallback(
    (trackingType: TRACKING_EVENT_TYPE, payload: TrackProps) => {
      const { moduleName, elementName, elementType, screenTitle } = payload
      const elementId = elementName && {
        element_id: `${moduleName}-${elementName}-${elementType}`,
      }

      const formattedPayload = snakeCasePayload(payload)

      const props: TrackingEventProps = {
        uuid: user.uuid,
        screen_title: screenTitle ?? pathname,
        ...elementId,
        ...formattedPayload,
      }

      switch (trackingType) {
        case TRACKING_EVENT_TYPE.view:
          service.trackView(props)
          break
        case TRACKING_EVENT_TYPE.event:
        default:
          service.trackEvent(props)
          break
      }
    },
    [service, user, pathname]
  )

  const trackEvent = useCallback(
    (payload: TrackProps) => {
      trackFn(TRACKING_EVENT_TYPE.event, payload)
    },
    [trackFn]
  )

  const trackView = useCallback(
    (payload: TrackProps) => {
      trackFn(TRACKING_EVENT_TYPE.view, payload)
    },
    [trackFn]
  )
  return { trackEvent, trackView }
}
