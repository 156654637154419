import React from 'react'
import { Text } from '@babylon/medkit'
import styles from '../Address.module.scss'
import { NewAddressFormat } from '../types'

interface Props {
  address: NewAddressFormat
}

const FormattedFullAddress: React.FC<Props> = ({
  address: { postcode, first_line, town, county },
}) => (
  <Text
    variant="body"
    className={styles.addressPicker__address}
    data-testid="full-address"
  >
    {first_line}
    <br />
    {town}, {county}
    <br />
    {postcode}
  </Text>
)

export default FormattedFullAddress
