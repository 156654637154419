import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const useChatbotConversation = () =>
  useSelector<any, unknown>((state) => state.homepageChatbot.conversation)

const useChatbotInitialQuestion = () =>
  useSelector<any, unknown>((state) => state.homepageChatbot.initialQuestion)

const useChatbotStarting = () =>
  useSelector<any, boolean>((state) => state.homepageChatbot.isStarting)

const useChatbotStarted = () =>
  useSelector<any, boolean>((state) => state.homepageChatbot.isStarted)

const chatbotStarRating = (state) => state.chatbot?.context.starRating

const selectHighChatbotStarRating = createSelector(
  [chatbotStarRating],
  (starRating) => starRating === 5
)

const useShowChatbotReferAFriend = ({ referAFriend }) =>
  useSelector<any, boolean>(
    (state) =>
      selectHighChatbotStarRating(state) &&
      referAFriend &&
      !!state.config.referAFriend
  )

export {
  useChatbotConversation,
  useChatbotInitialQuestion,
  useChatbotStarting,
  useChatbotStarted,
  useShowChatbotReferAFriend,
}
