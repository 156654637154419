import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import styles from './ExitView.module.scss'
import messages from './messages'

const ExitView = () => {
  const formatMessage = useFormatMessage()

  return (
    <div>
      <h1 className={styles.titleV1}>{formatMessage(messages.title)}</h1>

      <span className={styles.subTextV1}>
        {formatMessage(messages.needAssistance)}{' '}
      </span>
      <a
        href="https://support.babylonhealth.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d"
        target="_blank"
        rel="noreferrer"
        className={styles.subTextV1}
      >
        {formatMessage(messages.body)}
      </a>
    </div>
  )
}

export default ExitView
