import React from 'react'
import { Text } from '@babylon/medkit'
import styles from './QuestionTitle.module.scss'
import { STEP_ELEMENT_ID_PREFIX } from '@/programmes/components/FormV2/constants'

export type QuestionTitleType = 'header' | 'label' | 'legend'

export interface QuestionTitleProps {
  content: string
  type: QuestionTitleType
  id?: string
}

const QuestionTitle: React.FC<QuestionTitleProps> = ({ content, id, type }) => {
  switch (type) {
    case 'header':
      return (
        <Text
          variant="h1"
          className={styles.title}
          id={id ? `${STEP_ELEMENT_ID_PREFIX}-title-${id}` : undefined}
        >
          {content}
        </Text>
      )
    case 'label':
      return (
        <label
          className={styles.title}
          htmlFor={content}
          id={id ? `${STEP_ELEMENT_ID_PREFIX}-label-${id}` : undefined}
        >
          {content}
        </label>
      )
    case 'legend':
      return (
        <legend
          className={styles.title}
          id={id ? `${STEP_ELEMENT_ID_PREFIX}-title-${id}` : undefined}
        >
          {content}
        </legend>
      )
    default:
      return null
  }
}

export default QuestionTitle
