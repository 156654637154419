import React, { useEffect } from 'react'
import { useModalActions } from '@babylon/medkit'
import AddressesModal from './components/Modal'
import AddressForm from './components/AddressForm'
import PostcodeForm from './components/PostcodeForm'
import { isPostcodeLookupSupported } from './utils'
import { useComponentState } from './hooks'
import styles from './Address.module.scss'
import { OldAddressFormat, NewAddressFormat, Errors } from './types'

interface Props extends OldAddressFormat {
  onChange: (address: OldAddressFormat) => void
  disablePostcodeInput?: boolean
  regionId: number
  fieldErrors: Errors
  changePostcodeUrl?: string
  forceManualAddress?: boolean
  isGpAddress?: boolean
  onAddressPicked?: (value: boolean) => void
}

const initialState = {
  pickedAddress: '',
  manualAddress: '',
  userAddress: '',
  lastAddressEdited: {},
  manualAddressVisible: false,
  searchPostcode: '',
}

const Address: React.FC<Props> = ({
  address_post_code: postcode,
  address_first_line: first_line,
  address_second_line: town,
  address_third_line: county,
  onChange,
  disablePostcodeInput = false,
  regionId,
  fieldErrors,
  changePostcodeUrl,
  forceManualAddress,
  isGpAddress,
  onAddressPicked,
}) => {
  const isAddressLookupSupported = isPostcodeLookupSupported(regionId)
  const [componentState, updateComponentState] = useComponentState(initialState)
  const { closeModal, openModal } = useModalActions()
  const {
    pickedAddress,
    manualAddress,
    userAddress,
    lastAddressEdited,
    manualAddressVisible,
    searchPostcode,
  } = componentState

  const showManualAddressForm =
    !isAddressLookupSupported || manualAddressVisible
  const showPostcodeInput =
    showManualAddressForm || !isAddressLookupSupported || disablePostcodeInput

  const hasFullAddress =
    Boolean(postcode) && Boolean(first_line) && Boolean(town) && Boolean(county)

  useEffect(() => {
    updateComponentState({
      searchPostcode: postcode || '',
    })
  }, [postcode, disablePostcodeInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasFullAddress) {
      updateComponentState({
        userAddress: {
          postcode,
          first_line,
          town,
          county,
        },
        lastAddressEdited: {
          postcode,
          first_line,
          town,
          county,
        },
      })

      if (!isAddressLookupSupported) {
        updateComponentState({
          manualAddress: {
            postcode,
            first_line,
            town,
            county,
          },
        })
      }
    }
  }, [postcode, first_line, town, county, isAddressLookupSupported]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formattedAddress = showManualAddressForm
      ? formatAddress(
          manualAddress.postcode || postcode || searchPostcode,
          manualAddress.first_line,
          manualAddress.town,
          manualAddress.county
        )
      : formatAddress(
          lastAddressEdited.postcode || postcode,
          lastAddressEdited.first_line,
          lastAddressEdited.town,
          lastAddressEdited.county
        )
    onChange(formattedAddress)
  }, [lastAddressEdited, manualAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  const formatAddress = (
    postcode: string,
    first_line: string,
    town: string,
    county: string
  ) => ({
    address_first_line: first_line,
    address_second_line: town,
    address_third_line: county,
    address_post_code: postcode,
  })

  const onAddressSelected = (address: NewAddressFormat) => {
    updateComponentState({
      pickedAddress: address,
      manualAddressVisible: false,
      lastAddressEdited: address,
      searchPostcode: address.postcode,
    })
    closeModal()
    if (onAddressPicked) {
      onAddressPicked(true)
    }
  }

  const onManualAddressChange = (address: NewAddressFormat) => {
    updateComponentState({
      manualAddress: address,
      lastAddressEdited: address,
    })
  }

  const onAddManualAddress = (postcode: string) => {
    updateComponentState({
      manualAddressVisible: true,
      searchPostcode: postcode,
      lastAddressEdited: {},
    })
    closeModal()
    if (onAddressPicked) {
      onAddressPicked(false)
    }
  }

  const onOpenModal = () => {
    openModal(
      <AddressesModal
        postcode={searchPostcode}
        onAddressSelected={onAddressSelected}
        onAddManualAddress={onAddManualAddress}
        disableSearch={disablePostcodeInput}
        data-testid="address-picker-modal"
      />
    )
  }

  const onSearchPostcodeChange = (postcode: string) => {
    updateComponentState({ searchPostcode: postcode })
  }

  return (
    <div className={styles.addressPicker}>
      {isAddressLookupSupported && !forceManualAddress && (
        <PostcodeForm
          postcode={searchPostcode}
          address={pickedAddress || userAddress || manualAddress}
          disablePostcodeInput={disablePostcodeInput}
          showPostcodeInput={showPostcodeInput}
          showManualAddressForm={showManualAddressForm}
          searchPostcode={onSearchPostcodeChange}
          onOpenModal={onOpenModal}
          errors={fieldErrors}
          changePostcodeUrl={changePostcodeUrl}
          hasFullAddress={hasFullAddress}
        />
      )}
      {(showManualAddressForm ||
        !isAddressLookupSupported ||
        forceManualAddress) && (
        <AddressForm
          errors={fieldErrors}
          address={manualAddress}
          onAddressChange={onManualAddressChange}
          isAddressLookupSupported={isAddressLookupSupported}
          forceManualAddress={forceManualAddress}
          isGpAddress={isGpAddress}
        />
      )}
    </div>
  )
}

export default Address
