import { parse } from 'dotenv'
import 'url-polyfill'

// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
const REACT_APP_ENV_NAMES_WHITELIST = [
  'REACT_APP_ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL',
  'REACT_APP_BABYLON_AUTH_LEGACY_PORTAL_URL',
]

interface AdapterInterface {
  (config: any): any
}

interface EnvOverrides {
  local?: string
  dev?: string
  staging?: string
  preprod?: string
  prod?: string
}

const globalObj: any = global

const setLocalConf = (dotEnvConf: string) => {
  globalObj.envVars = parse(dotEnvConf)

  Object.keys(process.env)
    .filter((envName) => REACT_APP_ENV_NAMES_WHITELIST.includes(envName))
    .forEach((envName) => {
      const logicalEnvName = envName.replace('REACT_APP_', '')
      globalObj.envVars[logicalEnvName] = process.env[envName]
    })
}

const currentHost = () =>
  globalObj.location ? globalObj.location.hostname : ''

const isLocalEnv = (): boolean => currentHost().startsWith('localhost')

const isEnv = (env: string) => () =>
  !!currentHost().match(new RegExp(`\\.${env}\\.`))

const isDevEnv = isEnv('dev')
const isStagingEnv = isEnv('staging')
const isPreprodEnv = isEnv('preprod')

const localEnvUrl = (defaultUrl: string, envOverrides: EnvOverrides = {}) => {
  if (isLocalEnv()) {
    return envOverrides.local || defaultUrl
  }

  if (isDevEnv()) {
    return envOverrides.dev || defaultUrl
  }

  if (isStagingEnv()) {
    return envOverrides.staging || defaultUrl
  }

  if (isPreprodEnv()) {
    return envOverrides.preprod || defaultUrl
  }

  return envOverrides.prod || defaultUrl
}

const parseUrl = (urlStr: string): string => {
  if (urlStr === '') {
    return ''
  }

  const parsedUrl = new URL(urlStr)
  const { hostname } = parsedUrl
  parsedUrl.hostname = hostname.includes('.')
    ? hostname
    : [hostname, ...currentHost().split('.').slice(1)].join('.')

  return parsedUrl.toString()
}

// TODO: update the `README.md` once we've got rid of inline-defaults to reflect
// the new way of using this library, for example:
// `const ENV_FLAG = envVal('ENV_FLAG', parseBool)`
const envVar = (name: string, adapter: AdapterInterface = (x) => x): any => {
  // consumer facing apps use the global object babylonConfig, so we need to look for
  // environment variables there if the global envVars object does not exist.
  const value = adapter(
    (globalObj.envVars || globalObj.babylonConfig || {})[name]
  )

  return value
}

const warnDefault = (name: string, value: string) => {
  console.warn(
    `Default values are deprecated in babylon-env (via envUrl or envFlag). Please explicitly set '${name}' to '${value}'`
  )
}

const envUrl = (
  name: string,
  defaultUrl?: string,
  envOverrides?: EnvOverrides
): string | undefined => {
  const envVal = envVar(name)

  if (envVal) {
    return parseUrl(envVal)
  }

  if (defaultUrl !== undefined) {
    warnDefault(name, defaultUrl)

    return parseUrl(localEnvUrl(defaultUrl, envOverrides))
  }

  return undefined
}

const parseBool = (boolStr: 'true' | 'false'): boolean => {
  switch (boolStr) {
    case 'true': {
      return true
    }
    case 'false': {
      return false
    }
    default: {
      throw new Error(`Boolean expected but received '${boolStr}'`)
    }
  }
}

const envFlag = (name: string, defaultFlag?: any): any => {
  const envVal = envVar(name)

  if (envVal) {
    return parseBool(envVal)
  }

  if (defaultFlag !== undefined) {
    warnDefault(name, defaultFlag)

    return defaultFlag
  }

  return undefined
}

export {
  setLocalConf,
  isLocalEnv,
  isDevEnv,
  isStagingEnv,
  isPreprodEnv,
  parseUrl,
  envVar,
  envUrl,
  parseBool,
  envFlag,
}
