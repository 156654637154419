import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'
import {
  useTrackingInvalidDiscountCode,
  useTrackingPageRedirect,
} from '@/programmes/tracking'

export type Product = {
  id: string
  quantity: number
}

type CheckoutLinkParams = {
  productList: Product[]
  discountCodeId?: string
  successPath: string
  cancelPath: string
  clientReferenceId?: string
}

export const AWIN_COOKIE_NAME = 'awc'

export const checkOutSessionMutation = gql`
  mutation CreateCheckoutSession($input: CreateCheckoutInput!) {
    createCheckout(input: $input) {
      ... on Checkout {
        url
      }
      ... on Error {
        errorMessage
      }
    }
  }
`

const useGetCheckoutLink = ({
  productList,
  successPath,
  cancelPath,
  discountCodeId,
}: CheckoutLinkParams) => {
  const trackingPageRedirect = useTrackingPageRedirect()
  const trackingInvalidDiscountCode = useTrackingInvalidDiscountCode()

  const awinAwc = Cookies.get(AWIN_COOKIE_NAME)
  const [checkOutMutation, { loading }] = useMutation(checkOutSessionMutation, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: {
      input: {
        products: [...productList],
        currency: 'GBP',
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: `${window.location.origin}${cancelPath}`,
        discounts: discountCodeId ? [{ promotionCode: discountCodeId }] : null,
        clientReferenceId: window.Rewardful?.referral || null,
        awinAwc,
      },
    },
    onCompleted: (data) => {
      if (data.createCheckout.url) {
        trackingPageRedirect()
        window.location.href = data.createCheckout.url
      }
    },
    onError: (error) => {
      // this is returned when the discount code is invalid
      if (error.message.includes('422: Unprocessable Entity')) {
        // call the mutation again without a discount code
        checkOutMutation({
          variables: {
            input: {
              products: [...productList],
              currency: 'GBP',
              successUrl: `${window.location.origin}${successPath}`,
              cancelUrl: `${window.location.origin}${cancelPath}`,
              discounts: null,
              clientReferenceId: window.Rewardful?.referral || null,
              awinAwc,
            },
          },
        })
        trackingInvalidDiscountCode()
      }
    },
  })

  return { checkOutMutation, loading }
}

export default useGetCheckoutLink
