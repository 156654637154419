import gql from 'graphql-tag'

export default gql`
  query CheckoutSummaryQuery($lookupKeys: [String!]!, $promotionCode: String) {
    checkoutSummary(lookupKeys: $lookupKeys, promotionCode: $promotionCode) {
      ... on CheckoutSummary {
        subtotal
        discount
        discountedSubtotal
      }
    }
  }
`
