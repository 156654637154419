import React, { Suspense, lazy } from 'react'
import { ModalProvider } from '@babylon/medkit'

import Loading from '@/components/Loading'

const BookingInvitationRouter = lazy(() =>
  import(/* webpackChunkName: "f2f" */ './BookingInvitationRouter')
)

const BookingInvitationLoader = () => (
  <Suspense fallback={<Loading />}>
    <ModalProvider>
      <BookingInvitationRouter />
    </ModalProvider>
  </Suspense>
)

export default BookingInvitationLoader
