module.exports = {
  APPOINTMENT_COMPLETED: 'APPOINTMENT_COMPLETED',
  APPOINTMENT_CREDIT_RECEIVED: 'APPOINTMENT_CREDIT_RECEIVED',
  APPOINTMENT_CHECK_IN_REMINDER: 'APPOINTMENT_CHECK_IN_REMINDER',
  APPOINTMENT_FINISHED: 'APPOINTMENT_FINISHED',
  APPOINTMENT_INVITE_CREATED: 'APPOINTMENT_INVITE_CREATED',
  APPOINTMENT_INVITE_DISMISSED: 'APPOINTMENT_INVITE_DISMISSED',
  APPOINTMENT_REMINDER: 'APPOINTMENT_REMINDER',
  APPOINTMENT_REQUIRED_CHECK_IN_MISSED: 'APPOINTMENT_REQUIRED_CHECK_IN_MISSED',
  APPOINTMENT_START: 'APPOINTMENT_START',
  BLOOD_ANALYSIS_APPOINTMENT: 'BLOOD_ANALYSIS.APPOINTMENT',
  BLOOD_ANALYSIS_HEALTHY: 'BLOOD_ANALYSIS.HEALTHY',
  CHATBOT_RESPONSE: 'CHATBOT_RESPONSE',
  CLINICIAN_LATENESS: 'CLINICIAN_LATENESS',
  CLINICIAN_LATENESS_FIRST_MATCHED: 'CLINICIAN_LATENESS_FIRST_MATCHED',
  CLINICIAN_LATENESS_SECOND_MATCHED: 'CLINICIAN_LATENESS_SECOND_MATCHED',
  CLINICIAN_LATENESS_FIRST_NOT_MATCHED: 'CLINICIAN_LATENESS_FIRST_NOT_MATCHED',
  CLINICIAN_LATENESS_SECOND_NOT_MATCHED:
    'CLINICIAN_LATENESS_SECOND_NOT_MATCHED',
  DEMOGRAPHIC_STATUS_CHANGED: 'DEMOGRAPHIC_STATUS_CHANGED',
  DEMOGRAPHICS_STATUS_CHANGED: 'DEMOGRAPHICS_STATUS_CHANGED',
  DRUG_DELIVERY_CONFIRMED: 'DRUG_DELIVERY_CONFIRMED',
  EXTENDED_APPOINTMENT_INVITE_CREATED: 'EXTENDED_APPOINTMENT_INVITE_CREATED',
  FEEDBACK_MODAL: 'FEEDBACK_MODAL',
  HEALTHCHECK_RATING: 'HEALTHCHECK_RATING',
  ID_VERIFICATION_FAILED: 'ID_VERIFICATION_FAILED',
  ID_VERIFICATION_SUCCESS: 'ID_VERIFICATION_SUCCESS',
  MARKETING: 'MARKETING',
  MEMBERSHIP_REMINDER: 'MEMBERSHIP_REMINDER',
  NEW_PRESCRIPTION_AVAILABLE: 'NEW_PRESCRIPTION_AVAILABLE',
  NEW_PRESCRIPTION: 'NEW_PRESCRIPTION',
  NEW_REFERRAL: 'NEW_REFERRAL',
  NO_SHOW_APPOINTMENT: 'NO_SHOW_APPOINTMENT',
  NOTIFICATION_PLACEHOLDER: 'NOTIFICATION_PLACEHOLDER',
  PHARMACY_UNAVAILABLE: 'PHARMACY_UNAVAILABLE',
  POST_PAYMENT_PENDING: 'POST_PAYMENT_PENDING',
  PRESCRIPTION_EXPIRED: 'PRESCRIPTION_EXPIRED',
  PRESCRIPTION_EXPIRY: 'PRESCRIPTION_EXPIRY',
  PRESCRIPTION_READY_FOR_PICKUP: 'PRESCRIPTION_READY_FOR_PICKUP',
  PRESCRIPTION_VOIDED: 'PRESCRIPTION_VOIDED',
  RATE_APPOINTMENT: 'RATE_APPOINTMENT',
  REDEMPTION_EXPIRY: 'REDEMPTION_EXPIRY',
  REDEMPTION_USED: 'REDEMPTION_USED',
  REPEAT_MEDICATION_GP_REVIEW: 'REPEAT_MEDICATION_GP_REVIEW',
  REPEAT_MEDICATION_PHARMACIST_REVIEW: 'REPEAT_MEDICATION_PHARMACIST_REVIEW',
  REPEAT_MEDICATION_READY: 'REPEAT_MEDICATION_READY',
  REPEAT_MEDICATION_REORDER_NOW: 'REPEAT_MEDICATION_REORDER_NOW',
  REPEAT_MEDICATION_REORDER_SOON: 'REPEAT_MEDICATION_REORDER_SOON',
  REPEAT_MEDICATION_REVIEW_DATE_PASSED: 'REPEAT_MEDICATION_REVIEW_DATE_PASSED',
  SUGGEST_CONSULTATION: 'SUGGEST_CONSULTATION',
  TEST_RESULTS_READY: 'TESTS_AND_KITS.RESULTS_READY',
  UNQUEUE_PATIENT: 'UNQUEUE_PATIENT',
  UPCOMING_APPOINTMENT: 'UPCOMING_APPOINTMENT',
}
