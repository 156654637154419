import React, { cloneElement } from 'react'

import appStyles from '@/App.module.scss'
import withFeatureSwitches from '@/wrappers/withFeatureSwitches'

const ThemedLayout = ({
  children,
  featureSwitches,
  featureSwitchesLoading,
}) => (
  <main className={appStyles.view}>
    {cloneElement(children, { featureSwitches, featureSwitchesLoading })}
  </main>
)

export default withFeatureSwitches(ThemedLayout)
