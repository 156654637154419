import React from 'react'
import { compose, withProps, defaultProps } from 'recompose'
import classNames from 'classnames/bind'
import styles from './DonutChart.module.scss'

const generateSegmentProps = ({ segments, circumference, center }) => {
  // SVG starts at 3 o'clock, so subtrack 90 degrees to start at 12
  const angleOffset = -90
  const circleDegrees = 360

  let offset = angleOffset

  return segments.map(({ percent, color, ...rest }) => {
    const strokeLength = (percent / 100) * circumference
    const strokeOffset = circumference - strokeLength
    const transform = `rotate(${offset}, ${center}, ${center})`
    // move offset round for each segment
    offset += (percent / 100) * circleDegrees

    return {
      strokeOffset,
      transform,
      stroke: color,
      ...rest,
    }
  })
}

const cx = classNames.bind(styles)
const enhance = compose(
  defaultProps({
    radius: 16,
    viewBox: 42,
    fillColor: '#d2d3d4',
    segments: [],
    size: 300,
  }),
  withProps(({ segments, radius, viewBox, noFill, fillColor }) => {
    const total = segments.reduce((acc, { percent }) => (acc += percent), 0)

    if (total > 100) {
      console.warn('DonutChart segments add up to more that 100%')
    }

    // Fill in rest of circle allowing for minor rounding errors
    if (!noFill && total < 99.5) {
      segments.push({ percent: 100 - total, color: fillColor })
    }

    const circumference = 2 * Math.PI * radius
    const center = viewBox / 2

    const circles = generateSegmentProps({ segments, circumference, center })

    return {
      circumference,
      center,
      circles,
    }
  })
)

const DonutChart = ({
  center,
  radius,
  viewBox,
  circumference,
  circles,
  text,
  secondaryText,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewBox} ${viewBox}`}
    aria-hidden
  >
    {circles.map(({ strokeOffset, transform, ...circleProps }, i) => (
      <circle
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        strokeWidth="4"
        strokeDasharray={circumference}
        strokeDashoffset={strokeOffset}
        transform={transform}
        {...circleProps}
      />
    ))}

    {(text || secondaryText) && (
      <g>
        {text && (
          <text
            x="50%"
            y="50%"
            fill="#f45da9"
            className={cx(styles.primaryText, {
              'primaryText--withSecondary': !!secondaryText,
            })}
          >
            {text}
          </text>
        )}
        {secondaryText && (
          <text x="50%" y="50%" fill="#400099" className={styles.secondaryText}>
            {secondaryText}
          </text>
        )}
      </g>
    )}
  </svg>
)

export default enhance(DonutChart)
