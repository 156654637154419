import gql from 'graphql-tag'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'
import { PLATFORM_GATEWAY_GRAPHQL_ENDPOINT } from '@/config'

export interface LocalizedString {
  text: string
  __typename: string
}

export interface Coding {
  system: string
  display: LocalizedString
  __typename: string
}

export interface CodingConnection {
  nodes: Coding[]
  __typename: string
}

export interface CodeableConcept {
  codings: CodingConnection
  __typename: string
}

export interface Quantity {
  unit: string
  value: number
  __typename: string
}

export interface None {
  __typename: string
}

export interface ObservationReferenceRange {
  text: string
  high: Quantity | None
  low: Quantity | None
  __typename: string
}

export interface ObservationReferenceRangeConnection {
  nodes: ObservationReferenceRange[]
  __typename: string
}

export interface Observation {
  status: string
  code: CodeableConcept
  valueQuantity?: Quantity
  referenceRanges: ObservationReferenceRangeConnection
  __typename: string
}

export interface DiagnosticReportResultResultConnection {
  nodes: Observation[]
  __typename: string
}

export interface DiagnosticReport {
  id: string
  results: DiagnosticReportResultResultConnection
  __typename: string
}

export const CHECK_FOR_LAB_TEST_DATA = gql`
  query CheckForLabTestData($patientId: ID!) {
    patient(id: $patientId) {
      ... on Patient {
        diagnosticReports(first: 1, _sort: { field: DATE, order: DESC }) {
          nodes {
            id
            results(first: 100) {
              nodes {
                ... on Observation {
                  # "cancelled" means there was no value for some reason
                  status
                  code {
                    ... on CodeableConcept {
                      codings(first: 10) {
                        nodes {
                          # Use the display with system
                          # https://fhir.emed.health/CodeSystem/observation
                          system
                          display {
                            text
                          }
                        }
                      }
                    }
                  }
                  valueQuantity {
                    ... on Quantity {
                      unit
                      value
                    }
                    ... on None {
                      __typename
                    }
                  }
                  referenceRanges(first: 1) {
                    nodes {
                      text
                      high {
                        ... on Quantity {
                          value
                          unit
                        }
                      }
                      low {
                        ... on Quantity {
                          value
                          unit
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const useGetLabResults = () => {
  const userUUID = useSelector((state: any) => state.auth?.user?.uuid)
  const [labsData, setLabsData] = useState<DiagnosticReport>()

  const { data, loading, error } = useQuery(CHECK_FOR_LAB_TEST_DATA, {
    context: { uri: PLATFORM_GATEWAY_GRAPHQL_ENDPOINT },
    variables: { patientId: userUUID },
    fetchPolicy: 'no-cache', // Skip cache to avoid fragment matcher issues on inMemoryCache
  })

  useEffect(() => {
    if (data) {
      const d = data?.patient?.diagnosticReports?.nodes[0] as DiagnosticReport
      setLabsData(d)
    }
  }, [data])

  return {
    loading,
    data: labsData,
    error,
  }
}

export default useGetLabResults
