import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import messages from '../../messages'
import Button from '@/programmes/components/Button'
import steps from './injectionSteps'

type Props = {
  onStepComplete: () => void
  loading?: boolean
  success?: boolean
}

const SUPPORT_FORM_URL =
  'https://support.babylon-health.com/cspbabylonsupport?id=csp_sc_cat_item&table=sc_cat_item&sys_id=09cd304c8736e1101b157488dabb354d'

const InjectionInstructionsFull: React.FC<Props> = ({
  onStepComplete,
  loading,
  success,
}) => {
  const t = useFormatMessage()
  const [currentStep, setCurrentStep] = React.useState(0)
  const [needsSupport, setNeedsSupport] = React.useState(false)
  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      onStepComplete()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const previousStep = () => {
    if (currentStep === 0) {
      setCurrentStep(steps.length - 1)
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const linkToSupport = () => {
    window.location.href = SUPPORT_FORM_URL
  }

  const stepLayout = (
    <>
      {steps[currentStep].text.map((text) => (
        <p key={text}>{text}</p>
      ))}
      {steps[currentStep].video && (
        <video
          key={steps[currentStep].video}
          width="320"
          height="240"
          autoPlay
          loop
        >
          <track kind="captions" />
          <source src={steps[currentStep].video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!success && (
        <div>
          <Button onClick={nextStep} loading={loading}>
            {steps[currentStep].buttonText ||
              t(messages.injectionLayoutNextStep)}
          </Button>
          {steps[currentStep].buttonTextExit && (
            <Button secondary onClick={() => setNeedsSupport(true)}>
              {steps[currentStep].buttonTextExit}
            </Button>
          )}
          {currentStep > 0 && (
            <Button secondary onClick={previousStep}>
              {t(messages.injectionLayoutPreviousStep)}
            </Button>
          )}
        </div>
      )}
    </>
  )

  const supportLayout = (
    <>
      <p>{t(messages.injectionLayoutHavingIssues)}</p>
      <div>
        <Button onClick={linkToSupport}>
          {t(messages.injectionLayoutContactSupport)}
        </Button>
        <Button secondary onClick={() => setNeedsSupport(false)}>
          {t(messages.injectionLayoutBackFromSupport)}
        </Button>
      </div>
    </>
  )

  return needsSupport ? supportLayout : stepLayout
}

export default InjectionInstructionsFull
