import React, { useState } from 'react'
import { useHistory } from 'react-router'
import classNames from 'classnames/bind'
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import { useFormatMessage } from '@babylon/intl'
import { Card } from '@babylon/medkit'
import Loading from '@/components/Loading'
import useGetWeightMetrics from '@/programmes/hooks/useGetWeightMetrics'
import Button from '@/programmes/components/Form/components/Button'
import Template from '../Template'
import messages from '../messages'
import styles from '../index.module.scss'
import { RAG } from '../types'
import {
  getFormatedWeight,
  getFormatedWeightLoss,
} from '@/programmes/utils/formatWeight'

const CHART_MAX_WIDTH = 640
const cx = classNames.bind(styles)

const DashboardHomePage = () => {
  const t = useFormatMessage()
  const history = useHistory()

  const { data, loading } = useGetWeightMetrics()
  const [isMetric, setIsMetric] = useState(true)

  if (loading) {
    return <Loading />
  }

  if (!data) {
    return <h2>{t(messages.dashboardError)}</h2>
  }

  const {
    weightMetrics,
    currentWeight,
    previousWeekWeightLoss,
    totalWeightLost,
  } = data

  const getMinYAxisValue = () => {
    const minWeight = Math.min(
      ...weightMetrics.map((metric: any) => metric.weight)
    )

    // Subtract 10 from the min weight so the line doesn't touch the bottom of the chart
    return Math.min(currentWeight - 10, minWeight - 10)
  }

  const getMaxYAxisValue = () => {
    const maxWeight = Math.max(
      ...weightMetrics.map((metric: any) => metric.weight)
    )

    // Add 5 to the max weight so the line doesn't touch the top of the chart
    return Math.max(currentWeight + 5, maxWeight + 5)
  }

  const redirectToSubscriptionPage = () =>
    history.push(`/account/payments/subscription/weight-loss`)

  return (
    <Template title={t(messages.dashboardPageTitle)}>
      {weightMetrics ? (
        <>
          <h2 className={cx('heading', 'heading-container')}>
            <p>{t(messages.dashboardPageHeading)}</p>
            <Button
              type="secondary"
              onClick={() => setIsMetric((prevState) => !prevState)}
              title={t(
                isMetric ? messages.dashboardImperial : messages.dashboardMetric
              )}
            />
          </h2>
          <div className={cx('dashboard-header')}>
            <div>
              <h4
                className={cx('dashboard-header-item', {
                  green: totalWeightLost.rag === RAG.GREEN,
                  red: totalWeightLost.rag === RAG.RED,
                })}
              >
                {getFormatedWeightLoss(totalWeightLost.value, isMetric)}
              </h4>
              <p>
                {totalWeightLost.value > 0
                  ? t(messages.dashboardHeader1TotalLost)
                  : t(messages.dashboardHeader1Changed)}
              </p>
            </div>
            <div>
              <h2>{getFormatedWeight(currentWeight, isMetric)}</h2>
              <p>{t(messages.dashboardHeader2)}</p>
            </div>
            <div>
              <h4
                className={cx('dashboard-header-item', {
                  green: previousWeekWeightLoss.rag === RAG.GREEN,
                  red: previousWeekWeightLoss.rag === RAG.RED,
                })}
              >
                {getFormatedWeightLoss(previousWeekWeightLoss.value, isMetric)}
              </h4>
              <p>{t(messages.dashboardHeader3)}</p>
            </div>
          </div>
          {weightMetrics.length > 1 && (
            <Card data-testid="dashboard-chart">
              <VictoryChart
                theme={VictoryTheme.grayscale}
                padding={{
                  top: 0,
                  bottom: 24,
                  left: isMetric ? 50 : 70,
                  right: 0,
                }}
                domainPadding={20}
                width={CHART_MAX_WIDTH}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  fixLabelOverlap
                  style={{
                    axis: { stroke: 'none' },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  fixLabelOverlap
                  style={{
                    axis: { stroke: 'none' },
                    grid: {
                      stroke: '#ddd',
                      strokeWidth: 0.5,
                      strokeDasharray: '10,5',
                    },
                  }}
                  tickFormat={(x) => getFormatedWeight(x, isMetric)}
                  domain={{ y: [getMinYAxisValue(), getMaxYAxisValue()] }}
                />
                <VictoryLine
                  data={[...weightMetrics].reverse()}
                  x="dateFormated"
                  y="weight"
                  interpolation="monotoneX"
                  style={{ data: { strokeWidth: 5, stroke: '#3b68c0' } }}
                  labels={({ datum }) =>
                    `${datum.dateFormated} \n ${getFormatedWeight(
                      datum.weight,
                      isMetric
                    )}`
                  }
                  labelComponent={
                    <VictoryTooltip
                      cornerRadius={3}
                      flyoutStyle={{ fill: 'black', strokeWidth: 0 }}
                      style={{ fill: 'white' }}
                      pointerWidth={0}
                    />
                  }
                />
              </VictoryChart>
            </Card>
          )}
          <Card>
            <div data-testid="dashboard-checkins">
              <h4>{t(messages.dashboardCheckIns)}</h4>
              {weightMetrics.map((metric: any) => (
                <div className={cx('dashboard-check-in')} key={metric.dateISO}>
                  <div className={cx('dashboard-check-in-date')}>
                    <h6>
                      {new Date(metric.dateISO).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}
                    </h6>
                    <p>
                      {new Date(metric.dateISO).toLocaleDateString('en-GB', {
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </p>
                  </div>
                  <p className={cx('dashboard-check-in-weight')}>
                    {getFormatedWeight(metric.weight, isMetric)}
                  </p>
                  <div
                    className={cx('dashboard-check-in-weight-loss-container')}
                  >
                    <p
                      className={cx('dashboard-check-in-weight-loss', {
                        green: metric.bmi?.changeRag === RAG.GREEN,
                        red: metric.bmi?.changeRag === RAG.RED,
                      })}
                    >
                      {getFormatedWeightLoss(metric.weightLoss, isMetric)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </>
      ) : (
        <div>
          <h4>{t(messages.dashboardEmptyState)}</h4>
          <p>{t(messages.dashboardEmptyStateDescription)}</p>
        </div>
      )}
      <div className={cx('dashboard-manage-subscription-button-div')}>
        <Button
          title={t(messages.dashboardManageSubscriptionTitle)}
          onClick={redirectToSubscriptionPage}
        />
      </div>
    </Template>
  )
}

export default DashboardHomePage
