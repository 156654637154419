/* eslint-disable import/prefer-default-export */

/**
 * Track user id
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
 *
 */
import { CustomDimensionsMap } from './types'

export type UserTracker = (id: string, dimensionName?: string) => void

export default (
  gtag: Gtag.Gtag,
  GA_MEASUREMENT_ID: string,
  customMap?: CustomDimensionsMap
): UserTracker => (id, dimensionName) => {
  gtag('config', GA_MEASUREMENT_ID, {
    user_id: id,
    custom_map: customMap,
    // IMPORTANT: 'config' command always sends a 'page_view' event
    // unless 'send_page_view' is explicitly set to 'false'
    // this could result in multiple page view events
    send_page_view: false,
  })

  if (dimensionName) {
    gtag('set', { [dimensionName]: id })
  }
}
