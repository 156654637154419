import {
  allPass,
  always,
  append,
  contains,
  either,
  equals,
  evolve,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  lensIndex,
  join,
  last,
  map,
  over,
  pipe,
  prop,
} from 'ramda'

const locationOpenStates = {
  UNKNOWN: 'unknown',
  OPEN: 'open',
  CLOSED: 'closed',
  OPENS_LATER: 'opens_later',
}

const dayDictionary = {
  day1: 'Monday',
  day2: 'Tuesday',
  day3: 'Wednesday',
  day4: 'Thursday',
  day5: 'Friday',
  day6: 'Saturday',
  day7: 'Sunday',
}

const getDayLabel = (dayIndex) => dayDictionary[`day${dayIndex}`]

const formatDayRange = (days) =>
  days.length > 1 ? `${head(days)} - ${last(days)}` : days[0]

const formatHourRange = ({ start, end }) => `${start} - ${end}`

const formatCombinedHourRanges = (hourRanges) =>
  ifElse(
    either(isNil, isEmpty),
    always(''),
    pipe(map(formatHourRange), join(', '))
  )(hourRanges)

const groupOpeningHours = (openingHours) =>
  openingHours
    .reduce((acc, dayItem) => {
      const info = formatCombinedHourRanges(dayItem.hours)
      const lastGroup = last(acc)

      return allPass([
        identity,
        // if the last group includes the day before this one,
        pipe(prop('days'), contains(dayItem.day - 1)),
        // and the last group's opening info also matches this one,
        pipe(prop('info'), equals(info)),
      ])(lastGroup)
        ? // combine this with the matching entry
          over(lensIndex(-1), evolve({ days: append(dayItem.day) }), acc)
        : // add this as a new day entry
          append({ days: [dayItem.day], info }, acc)
    }, [])
    .map(
      evolve({
        days: pipe(map(getDayLabel), formatDayRange),
      })
    )

export {
  formatCombinedHourRanges,
  groupOpeningHours,
  locationOpenStates,
  // exported for testing:
  formatDayRange,
  formatHourRange,
}
